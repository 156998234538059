import React, { useState, useEffect, useContext } from "react";
import "../../../Shared/css/timelineGrid.scss";
import { ContentShowMode } from "../../../Shared/constants";
import "../index.scss";
import { SecurityMilestoneModalContext } from "../../";
import { Milestone } from "./milestone";
import { securityTimelineMilestone as milestoneProps } from "../../../../slice/securityTimeline";
import { OpenAllMilestoneContext } from "./productMilestone";

export const SingleProductMilestone = (milestones: {
  productMilestones: {
    productName: string;
    milestone: milestoneProps[];
  };
}) => {
  const securityMilestoneModalContext = useContext(
    SecurityMilestoneModalContext
  );

  const [openProductMilestone, setOpenProductMilestone] = useState(true);
  const openAllMilestone = React.useContext(OpenAllMilestoneContext);
  useEffect(
    () => setOpenProductMilestone(openAllMilestone.isOpen),
    [openAllMilestone.isOpen]
  );

  const isOpenA = openAllMilestone.isOpen;
  const isOpenS = openProductMilestone;

  let isHidden = openAllMilestone.isClick ? isOpenA : isOpenS;

  return (
    <>
      <li style={{ listStyle: "none" }}>
        <div
          className={
            securityMilestoneModalContext.contentShowMode ===
            ContentShowMode.Grid
              ? "d-flex product-title"
              : "d-flex justify-content-end flex-row-reverse product-title"
          }
          style={{
            height:
              securityMilestoneModalContext.contentShowMode ===
              ContentShowMode.Grid
                ? "27px"
                : "42px",
          }}
          onClick={(e: React.MouseEvent<HTMLElement>) => {
            e.preventDefault();
            openAllMilestone.setClick(() => false);
            setOpenProductMilestone(() => !openProductMilestone);
          }}
          onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>) => {
            if (event.keyCode === 13) {
              event.currentTarget.click();
            }
          }}
          tabIndex={0}
          role="button"
          aria-label={
            milestones.productMilestones.productName +
            (openProductMilestone ? " Expended" : "Collapsed")
          }
          aria-expanded={openProductMilestone ? "true" : "false"}
        >
          <div
            className="product-title-text"
            title={milestones.productMilestones.productName}
          >
            {securityMilestoneModalContext.contentShowMode ===
              ContentShowMode.Grid &&
            milestones.productMilestones.productName.length > 50
              ? milestones.productMilestones.productName.substring(0, 47) +
                "..."
              : milestones.productMilestones.productName}
          </div>
          <div className="collapse-icon">
            <i
              className={`${
                isHidden
                  ? "fa fa-angle-down  fa-2x"
                  : "fa fa-angle-right  fa-2x"
              }`}
              style={{ lineHeight: 0 }}
              onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>) => {
                if (event.keyCode === 13) {
                  openAllMilestone.setClick(() => false);
                  setOpenProductMilestone(() => !openAllMilestone);
                }
              }}
            ></i>
          </div>
        </div>
        <div className={`${isHidden ? "" : "d-none"}`} role="table">
          {securityMilestoneModalContext.contentShowMode ===
          ContentShowMode.Column ? (
            <div className="content-row-title d-flex" role="row">
              <div></div>
              <div role="columnheader">
                <strong>Milestone ID</strong>
              </div>
              <div role="columnheader">
                <strong>Public title</strong>
              </div>
              <div role="columnheader">
                <strong>Comment</strong>
              </div>
              <div role="columnheader">
                <strong>PMM</strong>
              </div>
              <div role="columnheader">
                <strong>PM</strong>
              </div>
              <div role="columnheader">
                <strong>Private date</strong>
              </div>
              <div role="columnheader">
                <strong>Public date</strong>
              </div>
              <div role="columnheader">
                <strong>GA date</strong>
              </div>
              <div role="columnheader">
                <strong>Milestone type</strong>
              </div>
              <div role="columnheader">
                <strong>Tier</strong>
              </div>
              <div role="columnheader">
                <strong>Edit</strong>
              </div>
            </div>
          ) : null}
          <Milestone milestones={milestones.productMilestones.milestone} />
        </div>
      </li>
    </>
  );
};
