import httpClient from "./base";
import { SecurityMilestone } from "../slice/securityMilestone";

const baseURL = "/SecurityMilestone";

const getSecurityMilestone = (securityMilestoneId: number) => {
  let url = `${baseURL}?id=${securityMilestoneId}&timelineType=SCI`;
  return httpClient.get(url);
};

const getMilestoneByMomentId = (id: number) => {
  var url = `${baseURL}/GetByMomentId?id=` + id;
  return httpClient.get(url);
};

const updateSecurityMilestone = (viewModel: SecurityMilestone) => {
  let url = `${baseURL}?timelineType=SCI`;
  return httpClient.put(url, viewModel);
};

const createSecurityMilestone = (viewModel: SecurityMilestone) => {
  let url = `${baseURL}?timelineType=SCI`;
  return httpClient.post(url, viewModel);
};

const deleteSecurityMilestone = (id: number) => {
  var url = `${baseURL}?id=${id}&timelineType=SCI`;
  return httpClient.del(url);
};

const getMilestoneMonths = () => {
  var url = `${baseURL}/GetMonths`;
  return httpClient.get(url);
};

const securityMilestoneService = {
  getSecurityMilestone,
  getMilestoneByMomentId,
  updateSecurityMilestone,
  createSecurityMilestone,
  deleteSecurityMilestone,
  getMilestoneMonths,
};

export default securityMilestoneService;
