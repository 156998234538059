import { AzureUpdate } from "../slice/azureupdate";
import httpClient from "./base";

const getAzureUpdate = (milestoneId: number) => {
  var url = "AzureUpdate?milestoneId=" + milestoneId;
  return httpClient.get(url);
};

const getAzureUpdateRelatedProductsOptions = () => {
  var url = "Product/GetProductIdAndNames";
  return httpClient.get(url);
};

const getAzureUpdateRelatedCategoriesOptions = () => {
  var url = "AzureUpdate/AzureUpdateRelatedCategories";
  return httpClient.get(url);
};

const getAzureUpdateEditorialActionsOptions = () => {
  var url = "AzureUpdate/AzureUpdateEditorialActions";
  return httpClient.get(url);
};

const getAzureUpdateStatusOptions = () => {
  var url = "AzureUpdate/AzureUpdateStatus";
  return httpClient.get(url);
};

const getAzureUpdateImageVersion = (id: number) => {
  var url = "AzureUpdate/versions?id=" + id;
  return httpClient.get(url);
};

const putAzureUpdate = (id: number, azureupdate: AzureUpdate) => {
  var url = "AzureUpdate";
  return httpClient.put(url, { ...azureupdate });
};

const postAzureUpdate = (AzureUpdate: AzureUpdate) => {
  const url = "AzureUpdate";
  return httpClient.post(url, { ...AzureUpdate });
};

const postUploadImage = (
  id: number,
  milestoneId: number,
  httpPostFile: File | null
) => {
  var url = "AzureUpdate/upload";
  var formData = new FormData();
  formData.append("id", id.toString());
  formData.append("milestoneId", milestoneId.toString());
  formData.append("AzureUpdateImage", httpPostFile as Blob);

  return httpClient.postWithForm(url, formData);
};

const azureupdateService = {
  getAzureUpdate,
  getAzureUpdateRelatedProductsOptions,
  getAzureUpdateRelatedCategoriesOptions,
  getAzureUpdateEditorialActionsOptions,
  getAzureUpdateStatusOptions,
  getAzureUpdateImageVersion,
  putAzureUpdate,
  postUploadImage,
  postAzureUpdate,
};

export default azureupdateService;
