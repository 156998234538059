import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import calendarService from "../services/calendar";
import milestoneService from "../services/milestone";
import { ModernWorkFeatureByTier } from "../slice/modernWorkFeature";
import { milestoneGroupByTier as securityGroupByTier } from "../slice/securityMilestone";
import modernWorkSecurityMSETSFeatureService from "../services/modernWorkFeature";
import bagFeatureService, {
  bagFeaturesGroupByTier,
} from "../services/bagFeature";
import bagMilestoneService, {
  bagMilestonesGroupByTier,
} from "../services/bagMilestone";
import securityMilestoneService from "../services/securityMilestone";
import { milestoneGroupByTier } from "../slice/milestone";
import { ErrorMessage } from "../components/Shared/messageBox";
import { TimelineType } from "../components/Shared/constants";
import { isVoid } from "../utils/utils";

export interface DateLookup {
  Date: string;
  Name: string;
}
export interface MilestoneTierGroup {
  Tier: string;
  MilestonesGroup: MilestoneGroup[];
}
export interface MilestoneGroup {
  Type: string;
  Milestones: MilestoneInCalendar[];
}
export interface MilestoneInCalendar {
  Name: string;
  Date: string;
  Id: string;
  Link: string;
  Type: string;
  Tier: string;
}

const initialState = {
  AzureUpdatesDeckDueDates: [],
  CalendarDates: [],
  CustomTrainDates: [],
  MicrosoftHolidays: [],
  Moments: [],
};

interface CalendarDate {
  AzureUpdatesDeckDueDates: string[];
  CalendarDates: DateLookup[];
  CustomTrainDates: string[];
  MicrosoftHolidays: DateLookup[];
  Moments: DateLookup[];
}

export const getCalendarDates = createAsyncThunk(
  "getCalendarDates",
  async (timelineType: TimelineType) => {
    return (await calendarService.getCalendarDates(
      timelineType
    )) as CalendarDate;
  }
);

export const getFeatureAndMilestoneByMomentId = createAsyncThunk(
  "getFeatureByMomentId",
  async (momentId: number) => {
    const groupResult = await Promise.all([
      milestoneService.getMilestoneByMomentId(momentId),
      modernWorkSecurityMSETSFeatureService.getFeatureByMomentId(momentId),
      securityMilestoneService.getMilestoneByMomentId(momentId),
      bagFeatureService.getBagFeaturesByMomentId(momentId),
      bagMilestoneService.getBagMilestonesByMomentId(momentId),
    ]).then((response) => {
      const [
        azureGroup,
        modernworkGroup,
        securityGroup,
        bagFeatureGroup,
        bagMilestoneGroup,
      ] = response as unknown[][];
      const azureData = azureGroup as milestoneGroupByTier[];
      const modernwork = modernworkGroup as ModernWorkFeatureByTier[];
      const security = securityGroup as securityGroupByTier[];
      const bagFeatures = bagFeatureGroup as bagFeaturesGroupByTier[];
      const bagMilestones = bagMilestoneGroup as bagMilestonesGroupByTier[];
      console.log(bagMilestones, bagFeatures, "Milestones features");
      let allFeatureGroup: MilestoneInCalendar[] = [];
      azureData.forEach((p) => {
        allFeatureGroup = allFeatureGroup.concat(
          p.milestones.map((s): MilestoneInCalendar => {
            return {
              Name: s.MilestoneName,
              Date: s.RoadmapStartDate?.toString() ?? "",
              Id: s.MilestoneId.toString(),
              Link: "/AzureTimeline?milestoneId=" + s.MilestoneId,
              Type: "Azure",
              Tier: p.tier,
            };
          })
        );
      });
      modernwork.forEach((p) => {
        allFeatureGroup = allFeatureGroup.concat(
          p.features.map((s): MilestoneInCalendar => {
            return {
              Name: !isVoid(s.MarketingTitle)
                ? s.MarketingTitle
                : !isVoid(s.PublicTitle)
                ? s.PublicTitle
                : !isVoid(s.Title)
                ? s.Title
                : "",
              Date: s.PublicDisclosureDate?.toString() ?? "",
              Id: s.Id.toString(),
              Link: "/ModernWorkTimeline?featureId=" + s.Id,
              Type: "ModernWork",
              Tier: p.tier,
            };
          })
        );
      });
      security.forEach((p) => {
        allFeatureGroup = allFeatureGroup.concat(
          p.milestones.map((s): MilestoneInCalendar => {
            return {
              Name: s.PublicTitle,
              Date: s.GeneralAvailabilityDate?.toString() ?? "",
              Id: s.SecurityMilestoneId.toString(),
              Link: "/securitytimeline?milestoneId=" + s.SecurityMilestoneId,
              Type: "Security",
              Tier: p.tier,
            };
          })
        );
      });
      bagFeatures.forEach((p) => {
        allFeatureGroup = allFeatureGroup.concat(
          p.bagFeatures.map((s): MilestoneInCalendar => {
            return {
              Name: s.FeatureName,
              Date: s.DisclosureDate?.toString() ?? "",
              Id: s.RawDataId.toString(),
              Link: s.Link,
              Type: "Bag",
              Tier: p.tier,
            };
          })
        );
      });
      bagMilestones.forEach((p) => {
        allFeatureGroup = allFeatureGroup.concat(
          p.bagMilestones.map((s): MilestoneInCalendar => {
            return {
              Name: s.MilestoneName,
              Date: s.StartDate?.toString() ?? "",
              Id: s.MilestoneId.toString(),
              Link: "",
              Type: "Bag",
              Tier: p.tier,
            };
          })
        );
      });

      const groupByTier = groupBy(allFeatureGroup, (p) => p.Tier);

      const result = Object.keys(groupByTier).map(
        (tier): MilestoneTierGroup => {
          const groupByType = groupBy(groupByTier[tier], (p) => p.Type);

          const featureGroup = Object.keys(groupByType).map(
            (type): MilestoneGroup => {
              return {
                Type: type,
                Milestones: groupByType[type].sort((a, b) =>
                  a.Name > b.Name ? 1 : a.Name === b.Name ? 0 : -1
                ),
              };
            }
          );

          return {
            Tier: tier,
            MilestonesGroup: featureGroup,
          };
        }
      );
      return result;
    });
    return { id: momentId, group: groupResult };
  }
);
const groupBy = <T, K extends keyof any>(list: T[], getKey: (item: T) => K) =>
  list.reduce((previous, currentItem) => {
    const group = getKey(currentItem);
    if (!previous[group]) previous[group] = [];
    previous[group].push(currentItem);
    return previous;
  }, {} as Record<K, T[]>);

export const getFeatureAndMilestoneByAllMomentId = createAsyncThunk(
  "getFeatureAndMilestoneByAllMomentId",
  async (momentId: number[], dispatch) => {
    momentId.forEach((item) => {
      dispatch.dispatch(getFeatureAndMilestoneByMomentId(item));
    });
  }
);

const calendarSlice = createSlice({
  name: "calendar",
  initialState: initialState,
  reducers: {},
  extraReducers: {
    [getCalendarDates.fulfilled.type]: (
      state: CalendarDate,
      action: {
        payload: CalendarDate;
        type: string;
      }
    ) => {
      state.AzureUpdatesDeckDueDates = action.payload.AzureUpdatesDeckDueDates;
      state.CalendarDates = action.payload.CalendarDates;
      state.CustomTrainDates = action.payload.CustomTrainDates;
      state.MicrosoftHolidays = action.payload.MicrosoftHolidays;
      state.Moments = action.payload.Moments;
    },
    [getCalendarDates.rejected.type]: (state, { error }) => {
      ErrorMessage.show(
        "There was an error getting calendar dates. Please refresh the page and try again. If the issue persists please contact the tool administrator."
      );
    },
  },
});

export const { reducer, actions } = calendarSlice;
