import httpClient from "./base";

const getMilestoneTiers = () => {
  var url = "/MilestoneTier";
  return httpClient.get(url);
};

const milestoneTierService = {
  getMilestoneTiers,
};

export default milestoneTierService;
