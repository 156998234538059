import { useCallback, useContext, useEffect, useRef, useState } from "react";
import "./index.scss";
import { CalendarContext, CalendarData } from "./../context";
import { getArrayByDates } from "./../utilities";
import WeekTable from "./weekTable";
import { getTitleArray } from "../../../utils/date";

const CMMCCalendar = (props: { isFirstDayMonday?: boolean }) => {
  const elementRef = useRef(null);
  const [size, setSize] = useState({ width: 0 });

  const onResize = useCallback(() => {
    if (elementRef.current) {
      const ele = elementRef.current as HTMLElement;
      setSize({ width: ele.offsetWidth });
    }
  }, []);
  sessionStorage.removeItem("Chat history");
  useEffect(() => {
    window.removeEventListener("resize", onResize, false);
    window.addEventListener("resize", onResize, false);

    onResize();
  }, []);

  const data: CalendarData = useContext(CalendarContext);
  const titleArray = getTitleArray(props.isFirstDayMonday);
  const array = getArrayByDates(
    data.currentDates.currentStartDate,
    data.currentDates.currentEndDate
  );

  const cellWidth = (size.width - 16) / 7;

  const weekTable = [];
  for (let i = 0; i < array.length; i++) {
    weekTable.push(
      <WeekTable
        weekIndex={i}
        data={array[i]}
        cellWidth={cellWidth}
        key={`wt-${i}`}
      />
    );
  }

  return (
    <div className="calendar-container" ref={elementRef}>
      <div className="calendar-wrapper">
        <table className="title-container">
          <tbody>
            <tr className="week-title-container">
              {titleArray.map((title, index) => {
                return (
                  <td
                    className="week-title"
                    key={`t-${index}`}
                    style={{ width: cellWidth }}
                  >
                    {title}
                  </td>
                );
              })}
            </tr>
          </tbody>
        </table>
        {weekTable.map((wt) => wt)}
      </div>
    </div>
  );
};

export default CMMCCalendar;
