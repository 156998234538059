import { useContext } from "react";
import { MyDataOfDay, createTdsForWeek } from "./../utilities";
import { CalendarContext } from "./../context";
import { useBoolean } from "@fluentui/react-hooks";
import * as CalendarUpdateReducer from "../../../slice/CalendarSlices/calendarUpdate";
import { useDispatch } from "react-redux";

const WeekTable = (props: {
  weekIndex: number;
  data: MyDataOfDay[];
  cellWidth: number;
}) => {
  const data = useContext(CalendarContext);
  const appDispatch = useDispatch();
  const [isOpenDetailPopup, { setTrue: showDetail, setFalse: hideDetail }] =
    useBoolean(false);
  const registeredEvents =
    data.registeredEvents
      ?.map((we) => {
        return { ...we };
      })
      ?.filter((e) => {
        let res =
          e.bicDateSelection.localeCompare("TBC - Month") !== 0 &&
          e.bicDateSelection.localeCompare("TBC - Quarter") !== 0;
        return res;
      }) ?? [];
  let rows = 0;
  let eventTables = [];
  if (registeredEvents && registeredEvents.length > 0) {
    createTdsForWeek(props.data, registeredEvents);
    rows = Math.max(...props.data.map((d) => d.assignedEvents?.length ?? 0));

    for (let i = 0; i < rows; i++) {
      eventTables.push(
        <tr key={`w${props.weekIndex}-eventrow${i}`}>
          {props.data
            .map((d, index) => {
              const tds = d.eventsTds;
              if (tds && tds.length > 0 && tds[i] && tds[i].startFromToday) {
                const td = tds[i];
                return (
                  <td
                    id={`${td.eventId}`}
                    className="day-eventcontainer"
                    key={`w${props.weekIndex}-container-${td.eventId}`}
                    style={{ width: props.cellWidth }}
                    colSpan={td.span}
                    onClick={(e) => {
                      if (!isOpenDetailPopup) {
                        e.preventDefault();
                        e.stopPropagation();
                        appDispatch(
                          CalendarUpdateReducer.actions.switchCurrentMoment(
                            td.eventId
                          )
                        );
                        appDispatch(
                          CalendarUpdateReducer.actions.changeEditPanelOpen(
                            false
                          )
                        );
                        appDispatch(
                          CalendarUpdateReducer.actions.changeDetailPanelOpen(
                            true
                          )
                        );
                      }
                    }}
                  >
                    <div
                      className="day-event"
                      key={`w${props.weekIndex}-${td.eventId}`}
                      style={{
                        backgroundColor: `${td.eventColor}`,
                        color: `${td.eventTextColor}`,
                      }}
                      title={td.eventTitle}
                    >
                      {td.eventTitle}
                    </div>
                  </td>
                );
              } else if (tds && tds[i] === undefined) {
                return (
                  <td
                    id={`w${props.weekIndex}-eventrow-empty${index}`}
                    key={`w${props.weekIndex}-eventrow-empty${index}`}
                    className="day-event-empty"
                    style={{ width: props.cellWidth }}
                    onClick={() => {}}
                  ></td>
                );
              } else if (
                tds &&
                tds.length > 0 &&
                tds[i] &&
                !tds[i].startFromToday
              ) {
                return undefined;
              } else {
                return undefined;
              }
            })
            .filter((tds) => tds !== undefined)}
        </tr>
      );
    }

    eventTables.push(
      <tr>
        {Array.of(0, 1, 2, 3, 4, 5, 6).map((d, index) => {
          return (
            <td className="day-event-empty" style={{ height: "auto" }}></td>
          );
        })}
      </tr>
    );
  }

  return (
    <>
      <table className="week-container" key={`w${props.weekIndex}-t`}>
        <tbody>
          <tr
            className="week-title-container"
            key={`w${props.weekIndex}-title`}
          >
            {props.data.map((day) => {
              return (
                <td
                  className="day-title"
                  key={`w${day.weekIndex}-d${day.dayIndex}`}
                  style={{
                    width: props.cellWidth,
                    color: "black",
                  }}
                >
                  <span>{day.displayTitle}</span>
                </td>
              );
            })}
          </tr>
          {eventTables.map((t) => {
            return t;
          })}
        </tbody>
      </table>
    </>
  );
};

export default WeekTable;
