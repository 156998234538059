import { isVoid } from "../../../utils/utils";

export namespace ConstantItems {
  interface FilterItemsProp {
    [key: string]: string;
  }

  interface DropDownOptions {
    key: string | number;
    text: string;
  }

  export const solutionArea: string[] = [
    "Business Applications (BA)",
    "Data & AI (DA)",
    "Digital & Application Innovation (DI)",
    "Infrastructure (IF)",
    "Modern Work (MW)",
    "Security (SE)",
    "GitHub (GH)",
    "Non-Solution Area Aligned (NS)",
    "Cross-Microsoft (XM)",
    "Social & religious holidays (SR)",
    "Moratorium (MO)",
  ];

  export const solutionAreaFilterItems: FilterItemsProp = {
    "Business Applications (BA)": "rgb(191, 73, 0)",
    "Data & AI (DA)": "rgb(168, 187, 228)",
    "Digital & Application Innovation (DI)": "rgb(168, 187, 228)",
    "Infrastructure (IF)": "rgb(168, 187, 228)",
    "Modern Work (MW)": "rgb(0, 132, 0)",
    "Security (SE)": "rgb(112, 48, 160)",
    "GitHub (GH)": "rgba(156, 133, 86, 0.6)",
    "Non-Solution Area Aligned (NS)": "rgb(255, 146, 72)",
    "Cross-Microsoft (XM)": "rgb(255, 185, 0)",
    "Social & religious holidays (SR)": "rgba(133, 133, 133, 0.31)",
    "Moratorium (MO)": "rgb(213, 220, 198)",
  };

  export const solutionAreaTextFilterItems: FilterItemsProp = {
    "Business Applications (BA)": "#FFF",
    "Data & AI (DA)": "#000",
    "Digital & Application Innovation (DI)": "#000",
    "Infrastructure (IF)": "#000",
    "Modern Work (MW)": "#FFF",
    "Security (SE)": "#FFF",
    "GitHub (GH)": "#000",
    "Non-Solution Area Aligned (NS)": "#000",
    "Cross-Microsoft (XM)": "#000",
    "Social & religious holidays (SR)": "#000",
    "Moratorium (MO)": "#000",
  };

  export const solutionAreaColor = (id: string) => {
    if (isVoid(id))
      return solutionAreaFilterItems["Business Applications (BA)"];
    if (id.indexOf(";") > -1) id = id.split(";")[0];
    if (Object.keys(solutionAreaFilterItems).indexOf(id) > -1)
      return solutionAreaFilterItems[id];
    else return solutionAreaFilterItems["Business Applications (BA)"];
  };

  export const solutionAreaTextColor = (id: string) => {
    if (isVoid(id))
      return solutionAreaTextFilterItems["Business Applications (BA)"];
    if (id.indexOf(";") > -1) id = id.split(";")[0];
    if (Object.keys(solutionAreaTextFilterItems).indexOf(id) > -1)
      return solutionAreaTextFilterItems[id];
    else return solutionAreaTextFilterItems["Business Applications (BA)"];
  };

  export const multipleCSA: string[] = ["Show multi-CSA moments"];

  export const productFamily: string[] = [
    "(BA) Dynamics 365",
    "(BA) Power Platform",
    "(DA) Data & AI",
    "(DA) Customer Success",
    "(DI) Digital & Application Innovation",
    "(DI) Customer Success",
    "(GH) GitHub AI",
    "(GH) GitHub CICD",
    "(GH) GitHub DevOps",
    "(GH) GitHub DevX",
    "(GH) GitHub Generic",
    "(GH) GitHub Security",
    "(IF) Infrastructure",
    "(IF) Customer Success",
    "(MW) Microsoft 365",
    "(MW) Microsoft 365 - Other",
    "(MW) Microsoft Copilot (Microsoft 365)",
    "(MW) Microsoft Intune",
    "(MW) Microsoft Teams",
    "(MW) Microsoft Viva",
    "(MW) Windows",
    "(NS) Education",
    "(NS) Era of AI",
    "(NS) Eval Center",
    "(NS) Financial Services",
    "(NS) Government",
    "(NS) Healthcare",
    "(NS) Industry Metaverse",
    "(NS) ISV to ISV",
    "(NS) Manufacturing",
    "(NS) Microsoft Cloud",
    "(NS) Microsoft Learn",
    "(NS) Microsoft Research",
    "(NS) Partner Channel Inclusion",
    "(NS) Partner Industry",
    "(NS) Partner Lifecycle",
    "(NS) Partner Red Carpet",
    "(NS) Partner Skilling",
    "(NS) Partner Surface",
    "(NS) Partner Transformation",
    "(NS) Retail",
    "(NS) Sustainability",
    "(NS) Thru Partner",
    "(SE) Compliance",
    "(SE) Identity",
    "(SE) Management",
    "(SE) Privacy",
    "(SE) Security",
    "Other",
  ];

  export const momentType: string[] = [
    "Product news",
    "Event (1st party)",
    "Event (3rd party)",
    "Campaigns",
    "Brand and Naming",
    "Skilling",
    "Other",
  ];

  export const prSupport: string[] = ["Yes", "No"];

  export const dateSelection: string[] = [
    "Specific Date",
    "Watch Day",
    "TBC - Month",
    "TBC - Quarter",
  ];

  export const tbcCategory: string[] = ["Campaigns", "Brand & Naming"];

  export const tbcYearSelection: DropDownOptions[] = [
    { key: 2022, text: "2022" },
    { key: 2023, text: "2023" },
    { key: 2024, text: "2024" },
    { key: 2025, text: "2025" },
    { key: 2026, text: "2026" },
    { key: 2027, text: "2027" },
    { key: 2028, text: "2028" },
    { key: 2029, text: "2029" },
    { key: 2030, text: "2030" },
  ];

  export const tbcMonthSelection: DropDownOptions[] = [
    { key: 1, text: "January" },
    { key: 2, text: "February" },
    { key: 3, text: "March" },
    { key: 4, text: "April" },
    { key: 5, text: "May" },
    { key: 6, text: "June" },
    { key: 7, text: "July" },
    { key: 8, text: "August" },
    { key: 9, text: "September" },
    { key: 10, text: "October" },
    { key: 11, text: "November" },
    { key: 12, text: "December" },
  ];

  export const tbcQuarterSelection: DropDownOptions[] = [
    { key: 1, text: "Q1 - July to September" },
    { key: 2, text: "Q2 - October to December" },
    { key: 3, text: "Q3 - January to March" },
    { key: 4, text: "Q4 - April to June" },
  ];

  export const tbcFYYearSelection: DropDownOptions[] = [
    { key: 2022, text: "FY22" },
    { key: 2023, text: "FY23" },
    { key: 2024, text: "FY24" },
    { key: 2025, text: "FY25" },
    { key: 2026, text: "FY26" },
  ];

  export const mainDisclosureChannels: string[] = [
    "Azure blog",
    "Dynamics 365 blog",
    "Dynamics",
    "Education blog",
    "Industry blog (FinServe, Government, Health, Manufacturing, Retail, Automotive, Media & TelCom)",
    "Microsft Tech Community",
    "Official Microsoft Blog",
    "Open Source blog",
    "Power Platform blog",
    "Quantum blog",
    "Security blog",
    "SQL Server blog",
    "Windows blog",
    "Windows Server blog",
  ];

  export const targetAudiences: string[] = [
    "BDM",
    "Citizen Dev",
    "Data scientists",
    "Developer",
    "IT professional",
    "ITDM",
    "ITI",
    "Other",
  ];
}
