import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { _LOADINGSTATE } from "../components/Shared/constants";
import securityAudienceService from "../services/securityAudience";
import { ErrorMessage } from "../components/Shared/messageBox";

export interface SecurityAudience {
  SecurityAudienceId: number;
  Name: string | null;
}

const initialState = [] as SecurityAudience[];

export const getSecurityAudience = createAsyncThunk(
  "getSecurityAudience",
  async () => await securityAudienceService.getSecurityAudience()
);

const securityAudienceSlice = createSlice({
  name: "securityAudienceService",
  initialState: initialState,
  reducers: {},
  extraReducers: {
    [getSecurityAudience.fulfilled.type]: (
      state: SecurityAudience[],
      action: PayloadAction<SecurityAudience[]>
    ) => {
      return action.payload;
    },
    [getSecurityAudience.rejected.type]: (state, { error }) => {
      ErrorMessage.show(
        "There was an error getting the security audience. Please refresh the page and try again. If the issue persists please contact the tool administrator."
      );
    },
  },
});

export const { reducer, actions } = securityAudienceSlice;
