import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { _LOADINGSTATE } from "../components/Shared/constants";
import securityConfidenceService from "../services/securityConfidence";
import { ErrorMessage } from "../components/Shared/messageBox";

export interface SecurityConfidence {
  SecurityConfidenceId: number;
  Name: string | null;
}

const initialState = [] as SecurityConfidence[];

export const getSecurityConfidence = createAsyncThunk(
  "getSecurityConfidence",
  async () => await securityConfidenceService.getSecurityConfidence()
);

const securityConfidenceSlice = createSlice({
  name: "securityConfidenceService",
  initialState: initialState,
  reducers: {},
  extraReducers: {
    [getSecurityConfidence.fulfilled.type]: (
      state: SecurityConfidence[],
      action: PayloadAction<SecurityConfidence[]>
    ) => {
      return action.payload;
    },
    [getSecurityConfidence.rejected.type]: (state, { error }) => {
      ErrorMessage.show(
        "There was an error getting the security confidence. Please refresh the page and try again. If the issue persists please contact the tool administrator."
      );
    },
  },
});

export const { reducer, actions } = securityConfidenceSlice;
