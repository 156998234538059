import httpClient from "./base";

const getGraphUsers = (
  filter: string,
  select: string,
  top: string,
  microsoftUser: boolean
) => {
  let url = `GraphProxy/users?startswith=${filter}&select=${select}&top=${top}&microsoftUser=${microsoftUser}`;
  return httpClient.get(url);
};

const getManager = (displayName: string, mail: string) => {
  let url = `GraphProxy/Manager?displayName=${displayName}&mail=${mail}`;
  return httpClient.get(url);
};

const graphProxyService = {
  getGraphUsers,
  getManager,
};

export default graphProxyService;
