import React, { useEffect } from "react";
import { InteractionType } from "@azure/msal-browser";
import {
  useMsal,
  useAccount,
  useMsalAuthentication,
  useIsAuthenticated,
} from "@azure/msal-react";
import { isVoid } from "./utils/utils";
import { _AUTHORIZATIONTYPE } from "./components/Shared/constants";
import authorizationService from "./services/authorization";
import moment from "moment";

const Login = (props: {
  accessToken: {
    token: string | undefined;
    accessType: string | undefined;
    expire: string | undefined;
  };
  setAccessToken: React.Dispatch<
    React.SetStateAction<{
      token: string | undefined;
      accessType: string | undefined;
      expire: string | undefined;
    }>
  >;
  setShowSpinner: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { instance, accounts, inProgress } = useMsal();
  const loginAccount = useAccount(accounts[0] || {});
  const loginAccountemail = loginAccount?.username || "";

  const loginAccountname = loginAccount?.name || "";
  const isAuthenticated = useIsAuthenticated();
  const fetchAccessTokenRequest = {
    scopes: ["api://ce291a6b-6cf3-4c09-bd45-1b46b1de40bd/access_as_user"],
  };
  const {} = useMsalAuthentication(
    InteractionType.Redirect,
    fetchAccessTokenRequest
  );
  useEffect(() => {
    if (inProgress === "none") {
      localStorage.setItem("LoginAccountEmail", loginAccountemail);
      localStorage.setItem("LoginAccountName", loginAccountname);
      if (isVoid(props.accessToken.token)) {
        if (isAuthenticated && loginAccount) {
          instance
            .acquireTokenSilent({
              ...fetchAccessTokenRequest,
              account: loginAccount,
            })
            .then((response) => {
              if (response) {
                localStorage.setItem("accessToken", response.accessToken);
                if (
                  !isVoid(props.accessToken.expire) &&
                  moment(props.accessToken.expire).toDate() > new Date()
                ) {
                  props.setAccessToken({
                    token: response.accessToken,
                    accessType: props.accessToken.accessType,
                    expire: props.accessToken.expire,
                  });
                  props.setShowSpinner(false);
                } else {
                  authorizationService.getAuthorization().then((data) => {
                    localStorage.setItem("accessType", data as string);
                    localStorage.setItem(
                      "expire",
                      moment(new Date()).add(10, "days").format("MM/DD/YYYY")
                    );
                    props.setAccessToken({
                      token: response.accessToken,
                      accessType: data as string,
                      expire: moment(new Date())
                        .add(10, "days")
                        .format("MM/DD/YYYY"),
                    });
                    props.setShowSpinner(false);
                  });
                }
              }
            })
            .catch((e) => {
              instance
                .acquireTokenPopup({
                  ...fetchAccessTokenRequest,
                  account: loginAccount,
                })
                .then((response) => {
                  instance
                    .acquireTokenSilent({
                      ...fetchAccessTokenRequest,
                      account: loginAccount,
                    })
                    .then((refreshResponse) => {
                      if (refreshResponse) {
                        authorizationService.getAuthorization().then((data) => {
                          localStorage.setItem(
                            "accessToken",
                            refreshResponse.accessToken
                          );
                          localStorage.setItem("accessType", data as string);
                          localStorage.setItem(
                            "expire",
                            moment(new Date())
                              .add(10, "days")
                              .format("MM/DD/YYYY")
                          );
                          props.setAccessToken({
                            token: refreshResponse.accessToken,
                            accessType: data as string,
                            expire: moment(new Date())
                              .add(10, "days")
                              .format("MM/DD/YYYY"),
                          });
                          props.setShowSpinner(false);
                        });
                      }
                    });
                });
            });
        }
      } else if (
        props.accessToken.accessType !== _AUTHORIZATIONTYPE.admin &&
        props.accessToken.accessType !== _AUTHORIZATIONTYPE.dri &&
        props.accessToken.accessType !== _AUTHORIZATIONTYPE.editable &&
        props.accessToken.accessType !== _AUTHORIZATIONTYPE.read_only
      ) {
        authorizationService.getAuthorization().then((data) => {
          localStorage.setItem("accessType", data as string);
          localStorage.setItem(
            "expire",
            moment(new Date()).add(10, "days").format("MM/DD/YYYY")
          );
          props.setAccessToken({
            token: props.accessToken.token,
            accessType: data as string,
            expire: moment(new Date()).add(10, "days").format("MM/DD/YYYY"),
          });
          props.setShowSpinner(false);
        });
      } else {
        authorizationService.checkAuthorization().then(() => {
          props.setShowSpinner(false);
        });
      }
    }
  }, [
    isAuthenticated,
    loginAccount,
    instance,
    inProgress,
    props.accessToken.token,
  ]);

  useEffect(() => {
    if (
      inProgress === "none" &&
      !isVoid(props.accessToken.token) &&
      isAuthenticated &&
      loginAccount
    ) {
      setInterval(async () => {
        instance
          .acquireTokenSilent({
            ...fetchAccessTokenRequest,
            account: loginAccount,
          })
          .then((response) => {
            if (response) {
              localStorage.setItem("accessToken", response.accessToken);
              props.setAccessToken({
                token: response.accessToken,
                accessType: props.accessToken.accessType,
                expire: props.accessToken.expire,
              });
            }
          });
      }, 50 * 60 * 1000);
    }
  }, [
    isAuthenticated,
    loginAccount,
    instance,
    inProgress,
    props.accessToken.token,
  ]);

  return <></>;
};

export default Login;
