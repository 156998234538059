import { useContext } from "react";
import "../../../Shared/css/timelineGrid.scss";
import "../index.scss";
import { securityTimelineMilestone } from "../../../../slice/securityTimeline";
import { SecurityMilestoneModalContext } from "../../";
import { truncateText, highlightSearch } from "../../../../utils/utils";
import moment from "moment";

const MilestoneSearchRow = (props: {
  milestone: securityTimelineMilestone;
}) => {
  const securityMilestoneModalContext = useContext(
    SecurityMilestoneModalContext
  );

  return (
    <>
      <div
        dangerouslySetInnerHTML={{
          __html: highlightSearch(
            securityMilestoneModalContext.searchHighlightPhrase,
            props.milestone.SecurityMilestoneId.toString()
          ),
        }}
      />
      <div
        title={props.milestone.PublicTitle}
        dangerouslySetInnerHTML={{
          __html: highlightSearch(
            securityMilestoneModalContext.searchHighlightPhrase,
            truncateText(props.milestone.PublicTitle, 65)
          ),
        }}
      />
      <div
        title={props.milestone.Comment}
        dangerouslySetInnerHTML={{
          __html: highlightSearch(
            securityMilestoneModalContext.searchHighlightPhrase,
            truncateText(props.milestone.Comment, 100)
          ),
        }}
      />
      <div
        title={props.milestone.PMM}
        dangerouslySetInnerHTML={{
          __html: highlightSearch(
            securityMilestoneModalContext.searchHighlightPhrase,
            truncateText(props.milestone.PMM, 69)
          ),
        }}
      />
      <div
        title={props.milestone.PM}
        dangerouslySetInnerHTML={{
          __html: highlightSearch(
            securityMilestoneModalContext.searchHighlightPhrase,
            truncateText(props.milestone.PM, 69)
          ),
        }}
      />
      <div>
        {props.milestone.PrivatePreviewDate === undefined ||
        props.milestone.PrivatePreviewDate === null
          ? ""
          : moment(new Date(props.milestone.PrivatePreviewDate))
              .utc()
              .format("MM/DD/YYYY")}
      </div>
      <div>
        {props.milestone.PublicPreviewDate === undefined ||
        props.milestone.PublicPreviewDate === null
          ? ""
          : moment(new Date(props.milestone.PublicPreviewDate))
              .utc()
              .format("MM/DD/YYYY")}
      </div>
      <div>
        {props.milestone.GeneralAvailabilityDate === undefined ||
        props.milestone.GeneralAvailabilityDate === null
          ? ""
          : moment(new Date(props.milestone.GeneralAvailabilityDate))
              .utc()
              .format("MM/DD/YYYY")}
      </div>
      <div title={props.milestone.SecurityMilestoneTypeName}>
        {props.milestone.SecurityMilestoneTypeName}
      </div>
      <div>
        {props.milestone.TierId === 1
          ? "Tier 1"
          : props.milestone.TierId === 2
          ? "Tier 2"
          : props.milestone.TierId === 3
          ? "Tier 3"
          : ""}
      </div>
    </>
  );
};

export default MilestoneSearchRow;
