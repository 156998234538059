import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { _LOADINGSTATE } from "../components/Shared/constants";
import productService from "../services/product";
import { ErrorMessage } from "../components/Shared/messageBox";
import { Product } from "./product";

export interface AzureProduct {
  tableHeader: Category[] | null;
  tableBody: TableBody[] | null;
  _isAzureProductLoading: _LOADINGSTATE;
}

export interface TableBody {
  categoryId: number;
  categoryName: string;
  product: Product;
}

export interface Conversation {
  ConversationId: number;
  Name: string;
  IsActive: boolean;
}

export interface Category {
  ACOMCategoryId: number;
  Name: string;
  IsActive: boolean;
  ConversationId: number;
  Conversation: Conversation;
}

const initialState: AzureProduct = {
  tableBody: null,
  tableHeader: null,
  _isAzureProductLoading: _LOADINGSTATE.pending,
};

export const getAzureProduct = createAsyncThunk("getAzureProduct", async () => {
  let product = (await productService.getAzureProduct()) as AzureProduct;
  return product;
});

const azureProductSlice = createSlice({
  name: "azureProduct",
  initialState: initialState,
  reducers: {},
  extraReducers: {
    [getAzureProduct.fulfilled.type]: (state, { payload }) => {
      return {
        ...payload,
        _isAzureProductLoading: _LOADINGSTATE.fullfilled,
      };
    },
    [getAzureProduct.rejected.type]: (state, { error }) => {
      ErrorMessage.show(
        "There was an error retrieving product mapping information. Please refresh the page and try again. If the issue persists please contact the tool administrator."
      );
      return {
        ...state,
        _isAzureProductLoading: _LOADINGSTATE.rejected,
      };
    },
    [getAzureProduct.pending.type]: (state: AzureProduct, { payload }) => {
      return {
        ...state,
        _isAzureProductLoading: _LOADINGSTATE.pending,
      };
    },
  },
});

export const { reducer, actions } = azureProductSlice;
