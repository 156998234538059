import httpClient from "./base";

const getTriageTag = () => {
  let url = "/TriageTags";
  return httpClient.get(url);
};

const postTriageTag = (name: string) => {
  let url = "/TriageTags";
  return httpClient.post(url, { Name: name });
};

const triageTagService = {
  getTriageTag,
  postTriageTag,
};

export default triageTagService;
