import { useEffect, useState } from "react";

export const isVoid = (value: unknown) =>
  value === undefined || value === null || value === "";

export const cleanObject = (object: Record<string, any>) => {
  const result = { ...object };
  Object.keys(result).forEach((key) => {
    let value = result[key];
    if (isVoid(value)) {
      delete result[key];
    }
  });

  return result;
};

export const useDebounce = (value: any, delay: number) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const timeout = setTimeout(() => setDebouncedValue(value), delay);
    return () => clearTimeout(timeout);
  }, [value, delay]);

  return debouncedValue;
};

export const toExcel = (filename: string, data: string) => {
  const uri = "data:application/vnd.ms-excel;base64,";
  const template =
    '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-mic' +
    'rosoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><meta cha' +
    'rset="UTF-8"><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:Exce' +
    "lWorksheet><x:Name>Worksheet</x:Name><x:WorksheetOptions><x:DisplayGridlines/>" +
    "</x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></" +
    "xml><![endif]--></head><body>{table}</body></html>";

  const fileData = template.replace("{table}", data);

  // If IE11
  //
  // Comment out temporarily due to msbuild error
  //
  //if (window.navigator.msSaveOrOpenBlob) {
  //  const blobObject = new Blob([fileData]);
  //  window.navigator.msSaveOrOpenBlob(blobObject, filename + `.xls`);
  //  return true;
  //}

  const element = window.document.createElement("a");
  element.href = uri + window.btoa(unescape(encodeURIComponent(fileData)));
  element.download = filename + `.xls`;
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);

  return true;
};

export const truncateText = (
  textToTruncate: string,
  truncateLength: number
): string => {
  let truncatedText = !isVoid(textToTruncate)
    ? textToTruncate.length > truncateLength
      ? textToTruncate.substring(0, truncateLength - 3) + "..."
      : textToTruncate
    : "";
  return truncatedText;
};

export const highlightSearch = (
  searchPhrase: string,
  valueToHighlight: string
): string => {
  if (
    !searchPhrase ||
    !valueToHighlight ||
    !valueToHighlight.toLowerCase().includes(searchPhrase.toLowerCase())
  ) {
    return valueToHighlight;
  }
  let esc = searchPhrase.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&");
  let regEx = new RegExp(esc, "ig"); // Case insensitive search

  let highlightedValue = valueToHighlight.replaceAll(
    regEx,
    "<mark class='highlight'>$&</mark>"
  );

  return highlightedValue;
};

export class FiscalInfoHelper {
  public static getFiscalInfo = (
    date: Date,
    baseMonth: number
  ): {
    year: number;
    quarter: number;
    month: number;
    fiscalYear: number;
    fiscalQuarter: number;
    fiscalMonth: number;
  } => {
    let year = date.getFullYear();
    let quarter = Math.ceil((date.getMonth() + 1) / 3);
    let zeroIndexMonth = date.getMonth();

    let fiscalYear = 0;
    let fiscalQuarter = 0;
    let fiscalMonth = 0;

    if (zeroIndexMonth + 1 < baseMonth) {
      fiscalYear = year;
      fiscalMonth = zeroIndexMonth + baseMonth;
    } else {
      fiscalYear = year + 1;
      fiscalMonth = zeroIndexMonth + 2 - baseMonth;
    }
    fiscalQuarter = Math.ceil(fiscalMonth / 3);
    return {
      year: year,
      quarter: quarter,
      month: zeroIndexMonth + 1,
      fiscalYear: fiscalYear,
      fiscalQuarter: fiscalQuarter,
      fiscalMonth: fiscalMonth,
    };
  };
}

export const speakText = (text: string) => {
  let el = document.createElement("div");
  let id = "speak-" + Date.now();
  el.setAttribute("id", id);
  el.setAttribute("aria-live", "polite");
  el.classList.add("visually-hidden");
  document.body.appendChild(el);

  window.setTimeout(function () {
    document.getElementById(id)!.innerHTML = text;
  }, 100);

  window.setTimeout(function () {
    const speakChild = document.getElementById(id);
    if (speakChild != null) {
      document.body.removeChild(speakChild);
    }
  }, 1000);
};
