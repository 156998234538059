import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import securityMilestoneDateHistoryService from "../services/securityMilestoneDateHistory";
import { _LOADINGSTATE } from "../components/Shared/constants";
import { ErrorMessage } from "../components/Shared/messageBox";

const initialOptionState = {
  securityMilestoneDateHistory: [],
  __isLoading: "",
};

export interface SecurityMilestoneDateHistory {
  Id: number;
  SecurityMilestoneId: number;
  PreAnnouncementDate?: string;
  PublicDisclosureDate?: string;
  PrivatePreviewDate?: string;
  PublicPreviewDate?: string;
  GADate?: string;
  ModifiedBy: string;
}

export const getSecurityMilestoneDateHistory = createAsyncThunk(
  "getSecurityMilestoneDateHistory",
  async (id: number, { dispatch, getState }) => {
    return (await securityMilestoneDateHistoryService.getSecurityMilestoneDateHistory(
      id
    )) as SecurityMilestoneDateHistory[];
  }
);

export const deleteSecurityMilestoneDateHistory = createAsyncThunk(
  "deleteSecurityMilestoneDateHistory",
  async (params: number[]) => {
    return await securityMilestoneDateHistoryService.deleteSecurityMilestoneDateHistory(
      params
    );
  }
);

const securityMilestoneDateHistorySlice = createSlice({
  name: "securityMilestoneDateHistory",
  initialState: initialOptionState,
  reducers: {},
  extraReducers: {
    [getSecurityMilestoneDateHistory.fulfilled.type]: (state, { payload }) => {
      state.securityMilestoneDateHistory = payload;
      state.__isLoading = _LOADINGSTATE.fullfilled;
    },
    [getSecurityMilestoneDateHistory.rejected.type]: (state, reason) => {
      if (reason.error.message === "Request failed with status code 404") {
        ErrorMessage.show(
          "The request is not recognized. Please refresh the page or restart your browser."
        );
      } else {
        ErrorMessage.show(
          "There was an error getting the Security milestone date history. Please refresh the page and try again. If the issue persists please contact the tool administrator."
        );
      }
    },
    [getSecurityMilestoneDateHistory.pending.type]: (state) => {
      state.__isLoading = _LOADINGSTATE.pending;
    },
  },
});

export const { actions, reducer } = securityMilestoneDateHistorySlice;
