import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { _LOADINGSTATE } from "../components/Shared/constants";
import updatePMMDueDateService from "../services/pmmDueDate";
import { ErrorMessage } from "../components/Shared/messageBox";

interface pmmDueDateState {
  dateInfo: string;
  isUpdating: boolean;
}

const initialState: pmmDueDateState = {
  dateInfo: "",
  isUpdating: false,
};

export const updatePMMDueDateInfo = createAsyncThunk(
  "updatePMMDueDateService",
  async (dateInfo: string) =>
    await updatePMMDueDateService.updatePMMDueDate(dateInfo)
);

export const getMMDueDateInfo = createAsyncThunk(
  "getPMMDueDateService",
  async (dateInfo: string) =>
    await updatePMMDueDateService.updatePMMDueDate(dateInfo)
);

const updatePMMDueDateInfoSlice = createSlice({
  name: "updatePMMDueDateService",
  initialState: initialState,
  reducers: {
    setDateInfo: (state, action: PayloadAction<string>) => {
      state.dateInfo = action.payload;
    },
  },
  extraReducers: {
    [updatePMMDueDateInfo.fulfilled.type]: (
      state: pmmDueDateState,
      action: PayloadAction<string>
    ) => {
      state.isUpdating = false;
    },
    [updatePMMDueDateInfo.pending.type]: (state: pmmDueDateState) => {
      state.isUpdating = true;
    },
    [updatePMMDueDateInfo.rejected.type]: (state, { error }) => {
      state.isUpdating = false;
      ErrorMessage.show(
        "There was an error updating PMM Due Date. Please refresh the page and try again. If the issue persists please contact the tool administrator."
      );
    },
  },
});

export const { reducer, actions } = updatePMMDueDateInfoSlice;
