import React, { useEffect, useState, useRef, MutableRefObject } from "react";
import moment from "moment";
import useDocumentTitle from "../../utils/useDocumentTitle";
import { ContentShowMode, ModalType, TimelineMode } from "../Shared/constants";
import { getFirstDayInTheMonth, getLastDayInTheMonth } from "../../utils/date";
import Header from "./Header";
import Filter from "./Filter";
import NavBar from "./NavBar";
import TimelineGrid from "./TimelineGrid";
import TimelineGridExcelDownload from "./TimelineGrid/timelineGridExcelDownload";
import SideBar from "../CopilotSidebar";
import PowerBIComponent from "../PowerBIComponent";

export interface SecurityMilestoneModalState {
  securityMilestoneId: number;
  groupDate?: string;
  modalType: ModalType;
  deleteMilestoneDateHistoryId: string;
}

export const SecurityMilestoneModalContext = React.createContext<{
  modalRef: React.RefObject<HTMLButtonElement> | null;
  securityMilestoneModalState: SecurityMilestoneModalState;
  setSecurityMilestoneModalStateHandler: (
    value: SecurityMilestoneModalState
  ) => void;
  deleteDialogRef: React.RefObject<HTMLButtonElement> | null;
  startDate: Date;
  appearStartDate: Date;
  appearEndDate: Date;
  setStartDate: (value: Date) => void;
  setAppearStartDate: (value: Date) => void;
  setAppearEndDate: (value: Date) => void;
  contentShowMode: ContentShowMode;
  setContentShowMode: (value: ContentShowMode) => void;
  searchPhrase: string;
  setSearchPhrase: (value: string) => void;
  timelineMode: TimelineMode;
  setTimelineMode: (value: TimelineMode) => void;
  searchHighlightPhrase: string;
  setSearchHighlightPhrase: (value: string) => void;
}>({
  modalRef: null,
  securityMilestoneModalState: {
    securityMilestoneId: 0,
    groupDate: undefined,
    modalType: ModalType.New,
    deleteMilestoneDateHistoryId: "",
  },
  setSecurityMilestoneModalStateHandler: (
    value: SecurityMilestoneModalState
  ) => {},
  deleteDialogRef: null,
  startDate: new Date(),
  appearStartDate: new Date(),
  appearEndDate: new Date(),
  setStartDate: (value: Date) => {},
  setAppearStartDate: (value: Date) => {},
  setAppearEndDate: (value: Date) => {},
  contentShowMode: ContentShowMode.Grid,
  setContentShowMode: (value: ContentShowMode) => {},
  searchPhrase: "",
  setSearchPhrase: (value: string) => {},
  timelineMode: TimelineMode.Timeline,
  setTimelineMode: (value: TimelineMode) => {},
  searchHighlightPhrase: "",
  setSearchHighlightPhrase: (value: string) => {},
});
type SecurityTimelineProps = {
  showCopilotComponent: boolean;
  showPowerBIComponent: boolean;
  PowerBIconfig: any;
};
const SecurityTimeline: React.FC<SecurityTimelineProps> = ({
  showCopilotComponent,
  showPowerBIComponent,
  PowerBIconfig,
}) => {
  const securityMilestoneDeleteDialogRef = useRef<HTMLButtonElement>(null);
  const securityMilestoneModalRef = useRef<HTMLButtonElement>(null);
  const [securityMilestoneModalState, setSecurityMilestoneModalState] =
    useState({
      securityMilestoneId: 0,
      groupDate: undefined,
      modalType: ModalType.New,
      deleteMilestoneDateHistoryId: "",
    } as SecurityMilestoneModalState);
  const [startDate, setStartDate] = useState(
    getFirstDayInTheMonth(moment(new Date()).format("MM/DD/YYYY"))
  );
  const [appearStartDate, setAppearStartDate] = useState(
    getFirstDayInTheMonth(moment(new Date()).format("MM/DD/YYYY"))
  );
  const [appearEndDate, setAppearEndDate] = useState(
    getLastDayInTheMonth(
      moment(new Date()).add(2, "months").format("MM/DD/YYYY")
    )
  );
  const [contentShowMode, setContentShowMode] = useState(ContentShowMode.Grid);
  const [searchPhrase, setSearchPhrase] = useState("");
  const [timelineMode, setTimelineMode] = useState(TimelineMode.Timeline);
  const [searchHighlightPhrase, setSearchHighlightPhrase] = useState("");

  const handleScroll = () => {
    let timelineNavbarStickyhead = document.querySelector(
      "#maincontent>.nav-container"
    );
    if (timelineNavbarStickyhead && timelineNavbarStickyhead !== null) {
      let top = timelineNavbarStickyhead.getBoundingClientRect().top;
      let left = timelineNavbarStickyhead.getBoundingClientRect().left;
      let stickyHead = document.querySelector(".container-navbar-stickyhead");
      if (stickyHead && stickyHead !== null) {
        if (top < 0) {
          if (stickyHead.className !== `container-navbar-stickyhead`) {
            stickyHead.className = `container-navbar-stickyhead`;
          }
        } else {
          if (stickyHead.className !== `container-navbar-stickyhead d-none`) {
            stickyHead.className = `container-navbar-stickyhead d-none`;
          }
        }
        if (stickyHead.getBoundingClientRect().left !== left) {
          (stickyHead as HTMLElement).style.left = left + "px";
        }
      }
    }
  };

  useEffect(() => {
    window.removeEventListener("scroll", handleScroll, false);
    window.addEventListener("scroll", handleScroll, false);
  }, []);

  useDocumentTitle(
    "Microsoft Security Timeline - Cloud Marketing moments calendar"
  );

  return (
    <SecurityMilestoneModalContext.Provider
      value={{
        modalRef: securityMilestoneModalRef,
        securityMilestoneModalState: securityMilestoneModalState,
        setSecurityMilestoneModalStateHandler: setSecurityMilestoneModalState,
        deleteDialogRef: securityMilestoneDeleteDialogRef,
        startDate: startDate,
        setStartDate: setStartDate,
        appearStartDate: appearStartDate,
        appearEndDate: appearEndDate,
        setAppearStartDate: setAppearStartDate,
        setAppearEndDate: setAppearEndDate,
        contentShowMode: contentShowMode,
        setContentShowMode: setContentShowMode,
        searchPhrase: searchPhrase,
        setSearchPhrase: setSearchPhrase,
        timelineMode: timelineMode,
        setTimelineMode: setTimelineMode,
        searchHighlightPhrase: searchHighlightPhrase,
        setSearchHighlightPhrase: setSearchHighlightPhrase,
      }}
    >
      <Header />
      <Filter />
      <div className="container-navbar-stickyhead d-none">
        <NavBar
          isStickyHead={true}
          showPowerBIComponent={showPowerBIComponent}
        />
      </div>
      <NavBar showPowerBIComponent={showPowerBIComponent} />
      {contentShowMode === ContentShowMode.Dashboard ? (
        <PowerBIComponent workspaceid={2} config={PowerBIconfig} />
      ) : (
        <TimelineGrid />
      )}
      <TimelineGridExcelDownload />
      {showCopilotComponent && <SideBar workspaceid={2} />}
      <TimelineGridExcelDownload />
    </SecurityMilestoneModalContext.Provider>
  );
};

export default SecurityTimeline;
