import React, { createRef, useContext, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  TextField,
  Breadcrumb,
  IBreadcrumbItem,
  ITextFieldStyles,
} from "@fluentui/react";
import { PrimaryButton } from "@fluentui/react/lib/Button";
import "../../Shared/css/header.scss";
import { breadcrumbStyles, buttonStyles } from "../../Shared/styleProps";
import { useAppDispatch } from "../../../store";
import * as azureTimelineReducer from "../../../slice/azureTimeline";
import * as productReducer from "../../../slice/product";
import { MilestoneModalContext } from "../index";
import ProductForm from "../../Product/productForm";
import ModalOverlay from "../../Shared/FormInput/modalOverlay";
import {
  ModalType,
  ContentShowMode,
  TimelineMode,
} from "../../Shared/constants";
import { inputFieldStyles } from "../../Shared/timelineFilterStyleProps";

export const inputStyles: Partial<ITextFieldStyles> = {
  ...inputFieldStyles,
  root: { width: 330 },
};

const Header = () => {
  const milestoneModalContext = useContext(MilestoneModalContext);
  const newProductButton = useRef<HTMLButtonElement>(null);
  const requestMarketingMomentLink = createRef<HTMLAnchorElement>();
  const requestMarketingWorkLink = createRef<HTMLAnchorElement>();
  const helpResourcesLink = createRef<HTMLAnchorElement>();
  const appDispatch = useAppDispatch();
  const [productFormSubmited, setProductFormSubmited] = useState(false);
  const [productFormChanged, setProductFormChanged] = useState(false);
  const confirmationShow = useRef<HTMLButtonElement>(null);

  const getData = (currentSearchPhrase: string) => {
    milestoneModalContext.setSearchHighlightPhrase(currentSearchPhrase.trim());
    if (!currentSearchPhrase || currentSearchPhrase.trim().length === 0) {
      milestoneModalContext.setTimelineMode(TimelineMode.Timeline);
      milestoneModalContext.setContentShowMode(ContentShowMode.Grid);
      appDispatch(azureTimelineReducer.initAzureTimelineDataSet());
    } else {
      milestoneModalContext.setTimelineMode(TimelineMode.Search);
      milestoneModalContext.setContentShowMode(ContentShowMode.Column);
      appDispatch(
        azureTimelineReducer.getSearchDataSet(currentSearchPhrase.trim())
      );
    }
  };

  const navigate = useNavigate();

  const itemsWithHref: IBreadcrumbItem[] = [
    {
      text: " < Cloud Marketing moments calendar",
      key: "f1",
      onClick: () => {
        navigate("/");
      },
    },
    { text: "Azure", key: "f2", href: "#!", isCurrentItem: true },
  ];

  return (
    <header className="main-header">
      <Breadcrumb
        role="heading"
        className="main-breadcrumb"
        items={itemsWithHref}
        maxDisplayedItems={3}
        ariaLabel="Breadcrumb"
        aria-level={2}
        overflowAriaLabel="More links"
        styles={breadcrumbStyles}
        dividerAs={() => {
          return <span> - </span>;
        }}
      />
      <h2 className="main-header-title">Azure</h2>
      <nav
        role="navigation"
        className="main-nav nav nav-pills"
        style={{ width: "1107px" }}
      >
        <ul className="noStyleList">
          <li className="nav-item">
            <PrimaryButton
              className="nav-link"
              onClick={() => {
                milestoneModalContext.setMilestoneModalStateHandler({
                  milestoneId: 0,
                  modalType: ModalType.New,
                });
                milestoneModalContext.modalRef?.current?.click();
              }}
            >
              <span className="icon">
                <i
                  className="fa fa-plus"
                  aria-hidden="true"
                  aria-label="New milestone"
                ></i>
              </span>
              New milestone
            </PrimaryButton>
          </li>
          <li className="nav-item">
            <a
              href="https://microsoft.sharepoint.com/teams/CloudMarketingMoments/SitePages/Cloud-Marketing-moments-calendar-DRIs.aspx"
              target="_blank"
              rel="noreferrer"
              className="d-none"
              ref={requestMarketingMomentLink}
              aria-label="Request a marketing moment"
            >
              Request a marketing moment
            </a>
            <PrimaryButton
              className="nav-link"
              onClick={() => {
                requestMarketingMomentLink.current?.click();
              }}
              role="link"
            >
              <span className="icon">
                <i
                  className="fa fa-file-text-o"
                  aria-hidden="true"
                  aria-label="Request a marketing moment"
                ></i>
              </span>
              Request a marketing moment
            </PrimaryButton>
          </li>
          <li className="nav-item">
            <PrimaryButton
              className="nav-link"
              onClick={() => {
                appDispatch(productReducer.actions.reset({}));
                newProductButton.current?.click();
              }}
            >
              <span className="icon">
                <i
                  className="fa fa-plus"
                  aria-hidden="true"
                  aria-label="New product"
                ></i>
              </span>
              New product
            </PrimaryButton>
            <ModalOverlay
              refs={newProductButton}
              title="Create a New Product"
              formSubmited={productFormSubmited}
              setFormSubmited={[setProductFormSubmited, setProductFormChanged]}
              confirmationShow={confirmationShow}
              formChanged={productFormChanged}
              formResetCallback={() => {
                appDispatch(productReducer.actions.reset({}));
              }}
            >
              <ProductForm
                refs={newProductButton}
                productFormSubmited={productFormSubmited}
                setProductFormSubmited={setProductFormSubmited}
                productFormChanged={productFormChanged}
                setProductFormChanged={setProductFormChanged}
                confirmationShow={confirmationShow}
              />
            </ModalOverlay>
          </li>
          <li className="nav-item">
            <a
              href="https://nam06.safelinks.protection.outlook.com/?url=https%3A%2F%2Fbadeintake.azurewebsites.net%2F&data=04%7C01%7Cv-ivanzhao%40microsoft.com%7Cdcbe9281b3254e5b6ad408d955dd37ab%7C72f988bf86f141af91ab2d7cd011db47%7C1%7C0%7C637635230898980204%7CUnknown%7CTWFpbGZsb3d8eyJWIjoiMC4wLjAwMDAiLCJQIjoiV2luMzIiLCJBTiI6Ik1haWwiLCJXVCI6Mn0%3D%7C1000&sdata=DUASbH61JvZSxiMmWAwX8gOB1QqfkyMrf%2BKeVG%2FS3lk%3D&reserved=0"
              target="_blank"
              rel="noreferrer"
              className="d-none"
              ref={requestMarketingWorkLink}
              aria-label="Request marketing work"
            >
              Request marketing work
            </a>
            <PrimaryButton
              className="nav-link"
              onClick={() => {
                requestMarketingWorkLink.current?.click();
              }}
              role="link"
            >
              <span className="icon">
                <i
                  className="fa fa-file-text-o"
                  aria-hidden="true"
                  aria-label="Request marketing work"
                ></i>
              </span>
              Request marketing work
            </PrimaryButton>
          </li>
          <li className="nav-item">
            <a
              href="https://microsoft.sharepoint.com/teams/CloudMarketingMoments/SitePages/Cloud-Marketing-moments-help.aspx"
              target="_blank"
              rel="noreferrer"
              className="d-none"
              ref={helpResourcesLink}
              aria-label="Help and resources"
            >
              Help and resources
            </a>
            <PrimaryButton
              className="nav-link"
              onClick={() => {
                helpResourcesLink.current?.click();
              }}
              role="link"
            >
              <span className="icon">
                <i
                  className="fa fa-question"
                  aria-hidden="true"
                  aria-label="Help and resources"
                ></i>
              </span>
              Help and resources
            </PrimaryButton>
          </li>
        </ul>
      </nav>
      <div role="search" className="search">
        <TextField
          placeholder="Search"
          styles={inputStyles}
          value={milestoneModalContext.searchPhrase}
          onChange={(event, item) => {
            milestoneModalContext.setSearchPhrase(item || "");
            if (item === undefined || item.trim().length === 0) {
              getData(item || "");
            }
          }}
          onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
            if (event.keyCode === 13) {
              getData(milestoneModalContext.searchPhrase);
            }
          }}
        />
        <PrimaryButton
          style={{ minWidth: 55, width: 55 }}
          ariaDescription="Search"
          ariaLabel="Search"
          text="Go"
          allowDisabledFocus
          onClick={() => {
            document.body.querySelector("#ariaLiveReader")!.innerHTML = "";
            getData(milestoneModalContext.searchPhrase);
          }}
          styles={buttonStyles}
        />
      </div>
    </header>
  );
};

export default Header;
