import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { _LOADINGSTATE } from "../components/Shared/constants";
import fieldBlockeReasonService from "../services/fieldBlockReason";
import { ErrorMessage } from "../components/Shared/messageBox";

interface fieldBlockerReasonState {
  isFieldBlockersLoading: string;
  fieldBlockerReasons: fieldBlockerReason[];
}

const initialState: fieldBlockerReasonState = {
  isFieldBlockersLoading: "",
  fieldBlockerReasons: [],
};

export interface fieldBlockerReason {
  FieldBlockerId: number;
  Reason: string;
}

export const getFieldBlockers = createAsyncThunk(
  "getFieldBlockers",
  async () => await fieldBlockeReasonService.getFieldBlockeReason()
);

const fieldBlockersSlice = createSlice({
  name: "fieldBlockers",
  initialState: initialState,
  reducers: {},
  extraReducers: {
    [getFieldBlockers.fulfilled.type]: (
      state: fieldBlockerReasonState,
      action: PayloadAction<fieldBlockerReason[] | string>
    ) => {
      if (typeof action.payload === "string") {
        state.fieldBlockerReasons = [];
      } else {
        state.fieldBlockerReasons = action.payload;
      }
      state.isFieldBlockersLoading = _LOADINGSTATE.fullfilled;
    },
    [getFieldBlockers.pending.type]: (state: fieldBlockerReasonState) => {
      state.isFieldBlockersLoading = _LOADINGSTATE.pending;
    },
    [getFieldBlockers.rejected.type]: (state, { error }) => {
      ErrorMessage.show(
        "There was an error getting the milestone tiers. Please refresh the page and try again. If the issue persists please contact the tool administrator."
      );
    },
  },
});

export const { reducer, actions } = fieldBlockersSlice;
