// Report.jsx (Child component)
import React from "react";
import { PowerBIEmbed } from "powerbi-client-react";
import "./Report.scss";
interface ReportProps {
  workspaceid: number;
  config: any; // Define the type of config according to your requirements
  accessToken: string;
  embedUrl: string;
}

const Report: React.FC<ReportProps> = ({
  workspaceid,
  config,
  accessToken,
  embedUrl,
}) => {
  // A separate function to render PowerBIEmbed component with consistent configurations
  const renderPowerBIEmbed = (id: string, embedUrl: string) => {
    if (embedUrl !== "") {
      return (
        accessToken && (
          <PowerBIEmbed
            embedConfig={{
              type: "report",
              tokenType: 0,
              id: id,
              accessToken: accessToken,
              embedUrl: embedUrl,
              settings: {
                filterPaneEnabled: false,
                navContentPaneEnabled: false,
              },
            }}
            cssClassName={"report-container"}
          />
        )
      );
    }
  };
  return (
    <>
      <div className="report-box">
        <div className="report-box-header">PMM Workspace Insights</div>

        <div className="report-box-body">
          {workspaceid === 1 &&
            renderPowerBIEmbed(config?.azureReportID, embedUrl)}
          {workspaceid === 2 &&
            renderPowerBIEmbed(config?.securityReportID, embedUrl)}
          {workspaceid === 3 &&
            renderPowerBIEmbed(config?.mwReportID, embedUrl)}
        </div>
      </div>
    </>
  );
};

export default Report;
