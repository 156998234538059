import { useSelector } from "react-redux";
import "../Shared/css/modal.scss";
import "./index.scss";
import { formatDate } from "../../utils/date";
import { isVoid } from "../../utils/utils";
import { RootState } from "../../store";
import * as modernWorkFeatureReducer from "../../slice/modernWorkFeature";

const DetailsFromMSETS = () => {
  const modernWorkFeature = useSelector<
    RootState,
    modernWorkFeatureReducer.ModernWorkFeature
  >((state) => state.modernWorkFeatureReducer.modernWorkFeature);
  var regex = new RegExp("<(S*?)[^>]*>.*?|<.*? />", "g");
  var publicDescriptionInnerText =
    modernWorkFeature.Publicdescription.replaceAll(regex, "").replaceAll(
      "&nbsp;",
      ""
    );
  var finalDescriptionInnerText = modernWorkFeature.FinalDescription.replaceAll(
    regex,
    ""
  ).replaceAll("&nbsp;", "");
  return (
    <div
      className="row feature-detail disabled"
      style={{ marginLeft: 0, marginRight: 0 }}
    >
      <div className="col-9">
        <div>
          <strong>Product:</strong> {modernWorkFeature.Product}
        </div>
        <div>
          <strong>PM:</strong>{" "}
          {modernWorkFeature.PMContact && modernWorkFeature.PMContactEmail
            ? modernWorkFeature.PMContact +
              " [" +
              modernWorkFeature.PMContactEmail +
              "]"
            : modernWorkFeature.PMContactEmail}
        </div>
        <div>
          <strong>Engineering title:</strong> {modernWorkFeature.Title}
        </div>
        <div>
          <strong>Public title:</strong>{" "}
          {isVoid(modernWorkFeature.PublicTitle.trim())
            ? modernWorkFeature.FinalTitle
            : modernWorkFeature.PublicTitle}
        </div>
        <div
          style={{
            WebkitLineClamp: 2,
            display: "-webkit-box",
            overflow: "hidden",
            WebkitBoxOrient: "vertical",
          }}
        >
          <strong style={{ marginRight: "4px" }}>Public description:</strong>
          {isVoid(publicDescriptionInnerText.trim())
            ? finalDescriptionInnerText
            : publicDescriptionInnerText}
        </div>
        <div>
          <strong>Platform:</strong> {modernWorkFeature.Platform}
        </div>
        <div>
          <strong>Cloud:</strong> {modernWorkFeature.CloudInstance}
        </div>
      </div>
      <div className="col-3">
        <div>
          <strong>Created:</strong> {formatDate(modernWorkFeature.Created)}
        </div>
        <div>
          <strong>Source ADO ID: </strong>
          {isVoid(modernWorkFeature.SourceADOID) ||
          modernWorkFeature.SourceADOID == "0"
            ? "N/A"
            : modernWorkFeature.SourceADOID}
        </div>
        <div>
          <strong>Message Center ID:</strong>{" "}
          {isVoid(modernWorkFeature.MessageCenterId)
            ? "N/A"
            : modernWorkFeature.MessageCenterId}
        </div>
        <div>
          <strong>CCH ID:</strong>{" "}
          <a
            href={`https://cch.azureedge.net/feature/${modernWorkFeature.CCHFeatureId}`}
            target="_blank"
            rel="noreferrer"
          >
            {modernWorkFeature.CCHFeatureId}
          </a>
        </div>
      </div>
    </div>
  );
};

export default DetailsFromMSETS;
