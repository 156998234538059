import * as React from "react";
import { CSSProperties, FunctionComponent, useState } from "react";
import Select, {
  OnChangeValue,
  StylesConfig,
  GroupBase,
  SelectInstance,
  ClearIndicatorProps,
  OptionsOrGroups,
} from "react-select";

type OptionType = {
  value: string;
  label: string;
};

interface Props {
  onChangeHandler: (value: any) => void;
  value: OnChangeValue<OptionType, true> | undefined;
  options: OptionsOrGroups<OptionType, GroupBase<OptionType>>;
  placeholder?: string;
  selectStyle?: StylesConfig<OptionType, boolean, GroupBase<OptionType>>;
  id: string;
  ariaLabel: string | undefined;
  disabled?: boolean;
  className?: string;
}

const SearchableMultiSelect = (props: Props) => {
  const [options, setOptions] = useState(props.options);
  React.useEffect(() => {
    setOptions(props.options);
  }, [props.options.length]);

  let select: SelectInstance<OptionType, true, GroupBase<OptionType>> | null;
  const CustomClearText: FunctionComponent = () => <strong>✖</strong>;
  const ClearIndicator = (props: ClearIndicatorProps<OptionType, true>) => {
    const {
      children = <CustomClearText />,
      getStyles,
      innerProps: { ref, ...restInnerProps },
    } = props;
    return (
      <div
        {...restInnerProps}
        ref={ref}
        style={getStyles("clearIndicator", props) as CSSProperties}
        onKeyDown={(e) => {
          if (e.keyCode === 13) {
            select?.clearValue();
            select?.focus();
          }
        }}
      >
        <div tabIndex={0} style={{ padding: "0px 5px" }}>
          {children}
        </div>
      </div>
    );
  };

  return (
    <Select
      ref={(ref) => {
        select = ref;
      }}
      aria-label={props.ariaLabel}
      placeholder={props.placeholder || "Enter name"}
      name={props.id}
      id={props.id}
      options={options}
      value={props.value}
      styles={props.selectStyle}
      onChange={props.onChangeHandler}
      onInputChange={(value) => {
        setOptions((prev) => {
          return props.options.filter(
            (v) =>
              v.label && v.label.toLowerCase().includes(value.toLowerCase())
          );
        });
      }}
      isSearchable={true}
      className="react-select-container"
      classNamePrefix="react-select"
      isMulti
      isClearable
      isDisabled={props.disabled}
      aria-hidden={false}
      components={{ ClearIndicator }}
      tabSelectsValue={false}
    ></Select>
  );
};

export default SearchableMultiSelect;
