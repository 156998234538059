import httpClient from "./base";

const baseURL = "/SecurityConfidence";
const getSecurityConfidence = () => {
  return httpClient.get(baseURL);
};

const SecurityConfidenceService = {
  getSecurityConfidence,
};

export default SecurityConfidenceService;
