import { Icon, TextField, TooltipHost } from "@fluentui/react";
import { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../store";
import { isVoid } from "../../utils/utils";
import * as securityMilestoneReducer from "../../slice/securityMilestone";

const MessagingMaterials = (props: { setFormChanged: Function }) => {
  const initialState = {
    Content: "",
    Url: "",
    ContentDuplicate: false,
    UrlDuplicate: false,
    UrlEmpty: true,
    ContentEmpty: true,
  };
  const [messagingMaterialState, setMessagingMaterialState] =
    useState(initialState);
  const milestone = useSelector<
    RootState,
    securityMilestoneReducer.SecurityMilestone
  >((state: RootState) => {
    return state.securityMilestoneReducer.SingleMilestone;
  });
  const appDispatch = useAppDispatch();
  const showSecondRow =
    milestone.MessagingMaterials.filter((m) => {
      return m.IsAdded || (!m.IsAdded && !m.IsDeleted);
    }).length > 0;
  const buttonClicked = () => {
    if (
      messagingMaterialState.UrlEmpty ||
      messagingMaterialState.ContentEmpty ||
      messagingMaterialState.Content.length > 2000 ||
      messagingMaterialState.Url.length > 2000 ||
      (messagingMaterialState.ContentDuplicate &&
        messagingMaterialState.UrlDuplicate)
    ) {
      return;
    }
    props.setFormChanged(true);
    const orig = milestone.MessagingMaterials.slice(0);
    orig.push({
      SecurityMilestoneId: 0,
      SecurityMilestoneMessagingMaterialId: 0,
      Content: messagingMaterialState.Content,
      Url: messagingMaterialState.Url,
      IsAdded: true,
      IsDeleted: false,
    } as securityMilestoneReducer.SecurityMilestoneMessagingMaterial);
    appDispatch(
      securityMilestoneReducer.actions.changeMessagingMaterials(orig)
    );
    appDispatch(
      securityMilestoneReducer.actions.changeIsMessagingMaterialsEdited(true)
    );
    setMessagingMaterialState(initialState);
  };

  const ref = useRef<HTMLButtonElement>(null);

  return (
    <>
      <div
        className="row"
        style={{ marginTop: "16px", marginBottom: "2px", lineHeight: "25px" }}
      >
        <strong>Messaging materials</strong>
      </div>{" "}
      <div className="row" style={{ marginBottom: "12px" }}>
        <div className="col">
          <TextField
            value={messagingMaterialState.Content}
            placeholder="Material title"
            errorMessage={
              messagingMaterialState.Content.length > 2000
                ? "Title exceeds 2000 characters"
                : milestone.MessagingMaterials.filter((m) => {
                    return (
                      m.Content == messagingMaterialState.Content &&
                      (m.IsAdded || (!m.IsAdded && !m.IsDeleted))
                    );
                  }).length > 0
                ? "Title existed."
                : undefined
            }
            onChange={(event, newValue) => {
              if (isVoid(newValue?.trim())) {
                setMessagingMaterialState((state) => {
                  return {
                    ...state,
                    Content: newValue ?? "",
                    ContentEmpty: true,
                  };
                });
              } else if (
                milestone.MessagingMaterials.filter((m) => {
                  return (
                    m.Content == newValue &&
                    (m.IsAdded || (!m.IsAdded && !m.IsDeleted))
                  );
                }).length > 0
              ) {
                setMessagingMaterialState((state) => {
                  return {
                    ...state,
                    Content: newValue ?? "",
                    ContentDuplicate: true,
                    ContentEmpty: false,
                  };
                });
              } else {
                setMessagingMaterialState((state) => {
                  return {
                    ...state,
                    Content: newValue ?? "",
                    ContentDuplicate: false,
                    ContentEmpty: false,
                  };
                });
              }
            }}
            onKeyDown={(e) => {
              if (e.code === "Enter") {
                ref.current?.click();
              }
            }}
          />
        </div>
        <div className="col">
          <TextField
            value={messagingMaterialState.Url}
            placeholder="Material url (https://aka.ms/mpf)"
            errorMessage={
              messagingMaterialState.Url.length > 2000
                ? "Url exceeds 2000 characters"
                : milestone.MessagingMaterials.filter((m) => {
                    return (
                      m.Url == messagingMaterialState.Url &&
                      (m.IsAdded || (!m.IsAdded && !m.IsDeleted))
                    );
                  }).length > 0
                ? "URL existed."
                : undefined
            }
            onKeyDown={(e) => {
              if (e.code === "Enter") {
                ref.current?.click();
              }
            }}
            onChange={(event, newValue) => {
              if (isVoid(newValue?.trim())) {
                setMessagingMaterialState((state) => {
                  return {
                    ...state,
                    Url: newValue ?? "",
                    UrlEmpty: true,
                  };
                });
              } else if (
                milestone.MessagingMaterials.filter((m) => {
                  return (
                    m.Url == newValue &&
                    (m.IsAdded || (!m.IsAdded && !m.IsDeleted))
                  );
                }).length > 0
              ) {
                setMessagingMaterialState((state) => {
                  return {
                    ...state,
                    Url: newValue ?? "",
                    UrlDuplicate: true,
                    UrlEmpty: false,
                  };
                });
              } else {
                setMessagingMaterialState((state) => {
                  return {
                    ...state,
                    Url: newValue ?? "",
                    UrlDuplicate: false,
                    UrlEmpty: false,
                  };
                });
              }
            }}
          />
        </div>
        <div
          className="col"
          style={{
            maxWidth: "34px",
            padding: "0px",
            marginLeft: "-12px",
            lineHeight: "28px",
            display:
              (!messagingMaterialState.ContentDuplicate ||
                !messagingMaterialState.UrlDuplicate) &&
              !messagingMaterialState.UrlEmpty &&
              !messagingMaterialState.ContentEmpty
                ? "block"
                : "none",
          }}
        >
          {
            <>
              <TooltipHost content="Add Material" id="AddMaterialtooltip">
                <button
                  style={{ backgroundColor: "transparent", border: "none" }}
                  aria-label="Add Material"
                  onClick={buttonClicked}
                  ref={ref}
                >
                  <Icon
                    iconName="CirclePlus"
                    style={{ color: "#0000ff" }}
                  ></Icon>
                </button>
              </TooltipHost>
            </>
          }
        </div>
      </div>
      {showSecondRow ? (
        <div className="row">
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            {milestone.MessagingMaterials.filter(
              (m) => m.IsAdded || (!m.IsAdded && !m.IsDeleted)
            ).map((m) => {
              return (
                <div style={{ minWidth: "33%" }}>
                  <a href={m.Url ?? undefined} title={m.Content ?? undefined}>
                    {m.Content}
                  </a>
                  <TooltipHost
                    content={"Delete Material " + m.Content}
                    id="DeleteMaterialtooltip"
                  >
                    <button
                      style={{
                        backgroundColor: "transparent",
                        border: "none",
                      }}
                      aria-label={"Delete Material " + m.Content}
                      onClick={(event) => {
                        props.setFormChanged(true);
                        let orig = milestone.MessagingMaterials.slice(0);
                        const index = orig.findIndex((o) => {
                          return o.Content === m.Content && o.Url === m.Url;
                        });
                        if (index >= 0) {
                          orig[index] = {
                            ...orig[index],
                            IsAdded: false,
                            IsDeleted: true,
                          };
                        }
                        appDispatch(
                          securityMilestoneReducer.actions.changeIsMessagingMaterialsEdited(
                            true
                          )
                        );
                        appDispatch(
                          securityMilestoneReducer.actions.changeMessagingMaterials(
                            orig
                          )
                        );
                      }}
                    >
                      <Icon iconName="Delete"></Icon> Delete
                    </button>
                  </TooltipHost>
                </div>
              );
            })}
          </div>
        </div>
      ) : null}
    </>
  );
};

export default MessagingMaterials;
