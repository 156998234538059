import { useCallback, useContext, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import ConfirmationDialog from "../../Shared/confirmationDialog";
import "../../Shared/css/timelineGrid.scss";
import "./index.scss";
import { Spinner, SpinnerSize } from "@fluentui/react";
import moment from "moment";
import {
  convertMonthStringToDate,
  getFirstDayInTheMonth,
  getLastDayInTheMonth,
} from "../../../utils/date";
import { RootState, useAppDispatch } from "../../../store";
import * as securityTimelineReducer from "../../../slice/securityTimeline";
import * as securityMilestoneReducer from "../../../slice/securityMilestone";
import { SuccessMessage } from "../../Shared/messageBox";

import {
  ContentShowMode,
  ModalType,
  TimelineMode,
  _AUTHORIZATIONTYPE,
  _LOADINGSTATE,
} from "../../Shared/constants";
import { SecurityMilestoneModalContext } from "..";
import RenderMoments from "./moments";
import RenderMonth from "./month";
import MovePre from "./movePrevious";
import MoveNext from "./moveNext";
import SecurityMilestoneModal from "../../SecurityMilestone/securityMilestoneModal";
import RenderMilestone from "./TimelineMilestone/index";
import { useLocation, useSearchParams } from "react-router-dom";
import { AppContext } from "../../../App";

const TimelineGrid = () => {
  const appContext = useContext(AppContext);
  const securityMilestoneModalContext = useContext(
    SecurityMilestoneModalContext
  );

  const showMonth = useMemo(() => {
    return securityMilestoneModalContext.contentShowMode ===
      ContentShowMode.Grid
      ? 3
      : 1;
  }, [securityMilestoneModalContext.contentShowMode]);

  const dataSource = useSelector(
    (state: RootState) => state.securityTimelineReducer.dataSet
  );

  const dataSet = useSelector(
    (state: RootState) => state.securityTimelineReducer.filterDataSet
  );

  const isMonthLoading = useSelector(
    (state: RootState) => state.securityTimelineReducer.isMonthLoading
  );

  const isLoadingSearchResult = useSelector(
    (state: RootState) => state.securityTimelineReducer.isLoadingSearchResult
  );

  const appDispatch = useAppDispatch();

  const location = useLocation();
  useEffect(() => {
    appDispatch(securityTimelineReducer.initSecurityTimelineDataSet());
  }, [appDispatch, location.pathname]);

  useEffect(() => {
    //Check to see if we are using search, otherwise load data normally
    if (securityMilestoneModalContext.timelineMode === TimelineMode.Search) {
      appDispatch(securityTimelineReducer.actions.setFilterDataSet(dataSource));
    } else {
      if (isMonthLoading === _LOADINGSTATE.fullfilled) {
        var startDate: moment.Moment | undefined;
        var endDate: moment.Moment | undefined;
        //Load all data when the length of data source less than showMonth
        if (dataSource.length > showMonth) {
          //Get the index of the first appear month in the data source
          const dataSourceIndex = dataSource.findIndex((d) => {
            return (
              moment(convertMonthStringToDate(d.month)) >=
              moment(
                getFirstDayInTheMonth(
                  moment(securityMilestoneModalContext.startDate).format(
                    "MM/DD/YYYY"
                  )
                )
              )
            );
          });
          appDispatch(
            securityTimelineReducer.actions.setFilterDataSet(
              dataSource.slice(dataSourceIndex, dataSourceIndex + showMonth)
            )
          );
          //Get the start and end index of appear months in the data source, and need
          //add 1 more month of the start month or end month
          var startIndex = dataSourceIndex - (dataSourceIndex > 1 ? 1 : 0);
          var endIndex =
            dataSourceIndex + showMonth < dataSource.length
              ? dataSourceIndex + showMonth
              : dataSource.length - 1;
          //Get the start and end month by start and end index
          for (var i = startIndex; i <= endIndex; i++) {
            if (dataSource[i].isLoading === "") {
              if (startDate === undefined) {
                startDate = moment(
                  convertMonthStringToDate(dataSource[i].month)
                );
              }
              endDate = moment(convertMonthStringToDate(dataSource[i].month));
            }
          }
        } else {
          appDispatch(
            securityTimelineReducer.actions.setFilterDataSet(dataSource)
          );
          startDate = moment(convertMonthStringToDate(dataSource[0].month));
          endDate = moment(
            convertMonthStringToDate(dataSource[dataSource.length - 1].month)
          );
        }
        if (startDate && endDate) {
          appDispatch(
            securityTimelineReducer.getDataSet(
              startDate.toDate(),
              getLastDayInTheMonth(endDate.format("MM/DD/YYYY"))
            )
          );
        }
      }
    }
  }, [
    appDispatch,
    dataSource,
    showMonth,
    securityMilestoneModalContext.startDate,
    securityMilestoneModalContext.timelineMode,
    isMonthLoading,
  ]);

  const [query] = useSearchParams();

  useEffect(() => {
    if (query.has("milestoneId") && appContext.accessType !== "") {
      if (
        appContext.accessType === _AUTHORIZATIONTYPE.admin ||
        appContext.accessType === _AUTHORIZATIONTYPE.dri ||
        appContext.accessType === _AUTHORIZATIONTYPE.editable
      ) {
        securityMilestoneModalContext.setSecurityMilestoneModalStateHandler({
          securityMilestoneId: Number(query.get("milestoneId")),
          modalType: ModalType.Edit,
          deleteMilestoneDateHistoryId: "",
        });
      } else {
        securityMilestoneModalContext.setSecurityMilestoneModalStateHandler({
          securityMilestoneId: Number(query.get("milestoneId")),
          modalType: ModalType.ReadOnly,
          deleteMilestoneDateHistoryId: "",
        });
      }
      securityMilestoneModalContext.modalRef?.current?.click();
    }
  }, [appContext.accessType]);

  const handleScroll = useCallback(() => {
    let gridContent = document.querySelector(".grid-content");
    if (gridContent && gridContent !== null) {
      let top = gridContent.getBoundingClientRect().top;
      let left = gridContent.getBoundingClientRect().left;
      let stickyHead = document.querySelector(".grid-content-stickyhead");
      if (stickyHead && stickyHead !== null) {
        if (top < 100) {
          if (
            stickyHead.className !==
            `grid-content ${
              "grid-container-" + dataSet.length
            } grid-content-stickyhead`
          ) {
            stickyHead.className = `grid-content ${
              "grid-container-" + dataSet.length
            } grid-content-stickyhead`;
          }
        } else {
          if (
            stickyHead.className !==
            `grid-content ${
              "grid-container-" + dataSet.length
            } grid-content-stickyhead d-none`
          ) {
            stickyHead.className = `grid-content ${
              "grid-container-" + dataSet.length
            } grid-content-stickyhead d-none`;
          }
        }
        if (stickyHead.getBoundingClientRect().left !== left) {
          (stickyHead as HTMLElement).style.left = left + "px";
        }
      }
    }
  }, [dataSet]);

  useEffect(() => {
    window.removeEventListener("scroll", handleScroll);
    window.addEventListener("scroll", handleScroll);
    handleScroll();
  }, [dataSet, handleScroll]);

  return (
    <div className="timeline-content-container securityTimeline-content-container">
      <SecurityMilestoneModal />
      <ConfirmationDialog
        refs={securityMilestoneModalContext.deleteDialogRef}
        onclickCallBack={() => {
          return appDispatch(
            securityMilestoneReducer.deleteMilestone(
              securityMilestoneModalContext.securityMilestoneModalState
                .securityMilestoneId
            )
          )
            .then(unwrapResult)
            .then(() => {
              SuccessMessage.show(
                "The milestone has been deleted successfully."
              );
              if (
                securityMilestoneModalContext.securityMilestoneModalState
                  .groupDate !== undefined
              ) {
                if (
                  dataSource.filter((data) => {
                    return (
                      moment(convertMonthStringToDate(data.month)).format(
                        "MM/YYYY"
                      ) ===
                      moment(
                        securityMilestoneModalContext
                          .securityMilestoneModalState.groupDate
                      ).format("MM/YYYY")
                    );
                  }).length > 0 &&
                  dataSource.filter((data) => {
                    return (
                      moment(convertMonthStringToDate(data.month)).format(
                        "MM/YYYY"
                      ) ===
                      moment(
                        securityMilestoneModalContext
                          .securityMilestoneModalState.groupDate
                      ).format("MM/YYYY")
                    );
                  })[0].isLoading !== ""
                ) {
                  appDispatch(
                    securityTimelineReducer.getMilestoneDataSet(
                      getFirstDayInTheMonth(
                        securityMilestoneModalContext
                          .securityMilestoneModalState.groupDate
                      ),
                      getLastDayInTheMonth(
                        securityMilestoneModalContext
                          .securityMilestoneModalState.groupDate
                      )
                    )
                  );
                }
              }
            })
            .catch();
        }}
        title="Deleting Milestone"
        subText="Are you sure you want to delete this milestone permanently?"
        confirmButtonString="Delete"
        cancelButtonString="Cancel"
      />
      <div
        className={`grid-content grid-container-${dataSet.length}`}
        role="tablist"
      >
        {isMonthLoading === _LOADINGSTATE.pending || isLoadingSearchResult ? (
          <div className={"grid-item column-span-all"}>
            <Spinner
              size={SpinnerSize.large}
              label="Loading content"
              ariaLive="polite"
              labelPosition="top"
            />
          </div>
        ) : (
          <>
            {securityMilestoneModalContext.timelineMode !==
            TimelineMode.Search ? (
              <>
                <div
                  className={`grid-content ${
                    "grid-container-" + dataSet.length
                  } grid-content-stickyhead d-none`}
                >
                  <MovePre />
                  <RenderMonth dataSet={dataSet} />
                  <MoveNext />
                </div>
                <MovePre />
                <RenderMonth dataSet={dataSet} />
                <MoveNext />
                <RenderMoments dataSet={dataSet} />
              </>
            ) : null}
            <RenderMilestone dataSet={dataSet} />
          </>
        )}
      </div>
    </div>
  );
};

export default TimelineGrid;
