import { savedDataFilter } from "../slice/savedDataFilters";
import httpClient from "./base";

const baseURL = "/SavedDataFilters";

const getSavedDataFilters = (filterType: string) => {
  var url = `${baseURL}?filterType=${filterType}`;
  return httpClient.get(url);
};

const saveSavedDataFilter = (savedDataFilter: savedDataFilter) => {
  return httpClient.post(baseURL, savedDataFilter);
};

const deleteSavedDataFilter = (id: number) => {
  var url = `${baseURL}?id=${id}`;
  return httpClient.del(url);
};

const savedDataFiltersService = {
  getSavedDataFilters,
  saveSavedDataFilter,
  deleteSavedDataFilter,
};

export default savedDataFiltersService;
