import { useContext } from "react";
import { Calendar, DateRangeType, DayOfWeek } from "@fluentui/react";
import { CalendarContext } from "./../context";
import {
  getFirstDayInTheMonth,
  getLastDayInTheMonth,
} from "../../../utils/date";

const MonthCalendar = (props: {
  isShowCalendar: boolean;
  hideCalendar: () => void;
}) => {
  const data = useContext(CalendarContext);

  const onSelectDate = (date: Date, dateRangeArray: Date[] | undefined) => {
    data.changeCurrentDates({
      currentStartDate: getFirstDayInTheMonth(date.toString()),
      currentEndDate: getLastDayInTheMonth(date.toString()),
    });
    props.hideCalendar();
  };

  return props.isShowCalendar === true ? (
    <Calendar
      dateRangeType={DateRangeType.Day}
      firstDayOfWeek={DayOfWeek.Sunday}
      highlightSelectedMonth={true}
      isDayPickerVisible={false}
      onSelectDate={onSelectDate}
      onDismiss={() => {
        props.hideCalendar();
      }}
      showGoToToday={true}
      value={data.currentDates.currentStartDate}
    />
  ) : null;
};

export default MonthCalendar;
