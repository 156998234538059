import * as React from "react";
import { useBoolean } from "@fluentui/react-hooks";
import { Dialog, DialogType, IModalProps, IModalStyles } from "@fluentui/react";

const ModalTooltip = (props: {
  refs: React.RefObject<HTMLButtonElement>;
  title: string;
  subText: string;
  children: JSX.Element;
}) => {
  const modalPropsStyles: Partial<IModalStyles> = {
    main: {
      width: "720px!important",
      minWidth: "720px!important",
      maxWidth: "720px!important",
    },
  };
  const modalProps: IModalProps = {
    isBlocking: true,
    styles: modalPropsStyles,
    dragOptions: undefined,
  };
  const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);

  const dialogContentProps = {
    type: DialogType.normal,
    title: props.title,
    subText: props.subText,
  };
  return (
    <>
      <button
        className="hide d-none"
        onClick={(event) => {
          event.preventDefault();
          toggleHideDialog();
          return false;
        }}
        ref={props.refs}
      ></button>
      <Dialog
        hidden={hideDialog}
        onDismiss={toggleHideDialog}
        dialogContentProps={dialogContentProps}
        modalProps={modalProps}
      >
        {props.children}
      </Dialog>
    </>
  );
};

export default ModalTooltip;
