import { TimelineType } from "../components/Shared/constants";
import httpClient from "./base";

const baseURL = "/SecurityTimeline";

const getSecurityTimelineMilestone = (startDate?: string, endDate?: string) => {
  var url = `${baseURL}?startDate=${
    startDate === undefined ? "" : startDate
  }&endDate=${endDate === undefined ? "" : endDate}&timelineType=${
    TimelineType.SCI
  }`;
  return httpClient.get(url);
};

const getSecurityTimelineExcelDownloadMilestone = (
  startDate?: string,
  endDate?: string
) => {
  var url = `${baseURL}/ExcelDownload?startDate=${
    startDate === undefined ? "" : startDate
  }&endDate=${endDate === undefined ? "" : endDate}&timelineType=${
    TimelineType.SCI
  }`;
  return httpClient.get(url);
};

const getFilters = () => {
  var url = `${baseURL}/Filters`;
  return httpClient.get(url);
};

const getSecurityTimelineMilestoneSearch = (searchPhrase?: string) => {
  var url = `${baseURL}/Search?searchPhrase=${
    searchPhrase === undefined ? "" : searchPhrase
  }`;
  return httpClient.get(url);
};

const getTimelineExcelDownloadSearchMilestone = (searchPhrase?: string) => {
  var url = `${baseURL}/SearchExcelDownload?searchPhrase=${
    searchPhrase === undefined ? "" : searchPhrase
  }`;
  return httpClient.get(url);
};

const securityTimelineService = {
  getSecurityTimelineMilestone,
  getSecurityTimelineExcelDownloadMilestone,
  getFilters,
  getSecurityTimelineMilestoneSearch,
  getTimelineExcelDownloadSearchMilestone,
};

export default securityTimelineService;
