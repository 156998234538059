export const splitStringToArray = (str: string) => {
  let array: string[] = [];
  if (str && str.trim().length > 0) {
    str.split(":").forEach((item1) => {
      item1 = item1.trim();
      if (item1 === null || item1.length == 0 || item1 === "") {
        return;
      }
      item1.split(";").forEach((item2) => {
        item2 = item2.trim();
        if (item2 === null || item2.length == 0 || item2 === "") {
          return;
        }
        item2.split(",").forEach((item3) => {
          item3 = item3.trim();
          if (item3 === null || item3.length == 0 || item3 === "") {
            return;
          }
          array.push(item3);
        });
      });
    });
  }
  return array;
};

export const getNamePart = (str: string) => {
  if (str) {
    str = str.trim();
    if (str.indexOf("[") > 0) {
      return str.substring(0, str.indexOf("[") - 1);
    }
    return str;
  }
  return "";
};

export const getEmailPart = (str: string) => {
  if (str) {
    str = str.trim();
    if (str.indexOf("[") > -1) {
      return str.substring(str.indexOf("[") + 1, str.length - 1);
    }
    return str;
  }
  return "";
};
