import React, { useState, useEffect, useContext } from "react";
import "../../../Shared/css/timelineGrid.scss";
import { ContentShowMode } from "../../../Shared/constants";
import "../index.scss";
import { ModernWorkFeatureModalContext } from "../../index";
import { Feature } from "./feature";
import { modernWorkTimelineFeature } from "../../../../slice/modernWorkTimeline";
import { OpenAllFeatureContext } from "./productFeature";

export const SingleProductFeature = (features: {
  productFeatures: {
    productName: string;
    feature: modernWorkTimelineFeature[];
  };
}) => {
  const modernWorkFeatureModalContext = useContext(
    ModernWorkFeatureModalContext
  );
  const [openProductFeature, setOpenProductFeature] = useState(true);
  const openAllFeature = React.useContext(OpenAllFeatureContext);
  useEffect(
    () => setOpenProductFeature(openAllFeature.isOpen),
    [openAllFeature.isOpen]
  );

  const isOpenA = openAllFeature.isOpen;
  const isOpenS = openProductFeature;

  const changeStateHandle = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    openAllFeature.setClick(() => false);
    setOpenProductFeature(() => !openProductFeature);
  };

  let isHidden = openAllFeature.isClick ? isOpenA : isOpenS;

  return (
    <>
      <li style={{ listStyle: "none" }}>
        <div
          className={
            modernWorkFeatureModalContext.contentShowMode ===
            ContentShowMode.Grid
              ? "d-flex product-title"
              : "d-flex justify-content-end flex-row-reverse product-title"
          }
          style={{
            height:
              modernWorkFeatureModalContext.contentShowMode ===
              ContentShowMode.Grid
                ? "27px"
                : "42px",
          }}
          onClick={changeStateHandle}
          onKeyDown={(e) => {
            if (e.keyCode === 13) {
              e.currentTarget.click();
            }
          }}
          tabIndex={0}
          role="button"
          aria-expanded={openProductFeature ? "true" : "false"}
        >
          <div
            className="product-title-text"
            aria-label={features.productFeatures.productName}
            id={"product-title" + features.productFeatures.productName}
          >
            {modernWorkFeatureModalContext.contentShowMode ===
              ContentShowMode.Grid &&
            features.productFeatures.productName &&
            features.productFeatures.productName.length > 52
              ? features.productFeatures.productName.substring(0, 49) + "..."
              : features.productFeatures.productName}
          </div>
          <div className="collapse-icon">
            <i
              className={`${
                isHidden
                  ? "fa fa-angle-down  fa-2x"
                  : "fa fa-angle-right  fa-2x"
              }`}
              aria-expanded={isHidden}
              role="button"
              title={features.productFeatures.productName}
              onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>) => {
                if (event.keyCode === 13) {
                  openAllFeature.setClick(() => false);
                  setOpenProductFeature(() => !openProductFeature);
                }
              }}
            ></i>
          </div>
        </div>
        <div className={`${isHidden ? "" : "d-none"}`}>
          {modernWorkFeatureModalContext.contentShowMode ===
          ContentShowMode.Column ? (
            <div className="content-row-title d-flex">
              <div></div>
              <div>
                <strong>CCH ID</strong>
              </div>
              <div>
                <strong>Roadmap ID</strong>
              </div>
              <div>
                <strong>Feature title</strong>
              </div>
              <div>
                <strong>Feature tier</strong>
              </div>
              <div>
                <strong>Preview date</strong>
              </div>
              <div>
                <strong>GA date</strong>
              </div>
              <div>
                <strong>Planned production date</strong>
              </div>
              <div>
                <strong>Public disclosure date</strong>
              </div>
              <div>
                <strong>Marketing moment</strong>
              </div>
              <div>
                <strong>PMM</strong>
              </div>
              <div>
                <strong>Planning</strong>
              </div>
            </div>
          ) : null}
          <Feature features={features.productFeatures.feature} />
        </div>
      </li>
    </>
  );
};
