import moment from "moment";
import { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import { useUrlQueryParam } from "../../../utils/url";
import { calendarFilterList, filter } from "../../../slice/savedDataFilters";
import { isVoid } from "../../../utils/utils";
import { CalendarMarketingMoment } from "../../../slice/CalendarSlices/calendarUpdate";

const ContentRow = (props: { moment: CalendarMarketingMoment }) => {
  //const appDispatch = useDispatch();
  //if (!props.moment.MilestoneTierGroups) {
  //  appDispatch(getFeatureByMomentId(props.moment.Id ?? 0));
  //}
  const tier1Group = props.moment.MilestoneTierGroups?.find(
    (p) => p.Tier === "1"
  );
  const momentTier1Content: JSX.Element[] = [];
  if (tier1Group != null) {
    const tier1Content = tier1Group.MilestonesGroup.map((p) => {
      p.Milestones.map((d) =>
        momentTier1Content.push(<a href={d.Link}> {d.Name}</a>)
      );
      //return momentContent;
    });
  }
  const tier2Group = props.moment.MilestoneTierGroups?.find(
    (p) => p.Tier === "2"
  );
  const momentTier2Content: JSX.Element[] = [];
  if (tier2Group != null) {
    const tier1Content = tier2Group.MilestonesGroup.map((p) => {
      p.Milestones.map((d) =>
        momentTier2Content.push(<a href={d.Link}> {d.Name}</a>)
      );
      //return momentContent;
    });
  }
  const tier3Group = props.moment.MilestoneTierGroups?.find(
    (p) => p.Tier === "3"
  );
  const momentTier3Content: JSX.Element[] = [];
  if (tier3Group != null) {
    const tier1Content = tier3Group.MilestonesGroup.map((p) => {
      p.Milestones.map((d) =>
        momentTier3Content.push(<a href={d.Link}> {d.Name}</a>)
      );
      //return momentContent;
    });
  }
  let startDate: string = "";
  let endDate: string = "";
  if (props.moment.StartDate && props.moment.StartDate !== "") {
    if (props.moment.DateSelection === "Specific Date") {
      startDate = moment(new Date(props.moment.StartDate))
        .utc()
        .format("YYYY-MM-DD");
    } else if (props.moment.DateSelection === "TBC - Month") {
      startDate = moment(new Date(props.moment.StartDate)).utc().format("MMMM");
    }
  }
  if (props.moment.EndDate && props.moment.EndDate !== "") {
    if (props.moment.DateSelection === "Specific Date") {
      endDate = moment(new Date(props.moment.EndDate))
        .utc()
        .format("YYYY-MM-DD");
    } else if (props.moment.DateSelection === "TBC - Month") {
      endDate = moment(new Date(props.moment.EndDate)).utc().format("MMMM");
    } else if (props.moment.DateSelection === "Watch Day") {
      endDate = "";
    }
  }
  return (
    <tr>
      <td>{props.moment.Title || ""}</td>
      <td>{props.moment.DateSelection || ""}</td>
      <td>{startDate}</td>
      <td>{endDate}</td>
      <td>{props.moment.SolutionArea.join(",")}</td>
      <td>{props.moment.Description || ""}</td>
      <td>{props.moment.GeoLocation || ""}</td>
      <td>{props.moment.Contacts?.join(",") || ""}</td>
      <td>{props.moment.Id}</td>
      <td>{props.moment.TargetAudience?.join(",") || ""}</td>
      <td>{momentTier1Content}</td>
      <td>{momentTier2Content}</td>
      <td>{momentTier3Content}</td>
      <td>{props.moment.MomentType?.join(",") || ""}</td>
      <td>{props.moment.ProductFamily?.join(",") || ""}</td>
      <td>{props.moment.MainDisclosureChannel?.join(",")}</td>
      <td>{/*{""} props.moment.TBC Category*/}</td>
      <td>{/*{""} props.moment.Priority item*/}</td>
      <td>{/*{""} props.moment.Barrier to success*/}</td>
      <td>{props.moment.NewsZone}</td>
      <td>{props.moment.PriorityItem}</td>
    </tr>
  );
};

const CalendarExcelDownload = () => {
  const momentList = useSelector(
    (state: RootState) => state.calendarUpdateReducer.momentList
  );
  const excelDownloading = useSelector(
    (state: RootState) => state.calendarUpdateReducer.excelDownloading
  );
  const [param, setParam] = useUrlQueryParam(
    calendarFilterList.map((f: filter) => {
      return f.filterName;
    })
  );
  useEffect(() => {}, [excelDownloading]);

  const filterEvents: CalendarMarketingMoment[] = useMemo(() => {
    let items: CalendarMarketingMoment[] = [];
    momentList.forEach((moment) => {
      if (!isVoid(param.SolutionArea)) {
        if (
          decodeURIComponent(param.SolutionArea)
            .split(";|;")
            .filter((item) => item !== "" && moment.SolutionArea.includes(item))
            .length === 0
        ) {
          return true;
        }
      }
      if (!isVoid(param.MultipleCSA)) {
        if (
          decodeURIComponent(param.MultipleCSA)
            .split(";|;")
            .filter(
              (item) =>
                item !== "" && moment.MultipleCSA.split(";").includes(item)
            ).length === 0
        ) {
          return true;
        }
      }
      if (!isVoid(param.ProductFamily)) {
        if (
          decodeURIComponent(param.ProductFamily)
            .split(";|;")
            .filter(
              (item) => item !== "" && moment.ProductFamily.includes(item)
            ).length === 0
        ) {
          return true;
        }
      }
      if (!isVoid(param.MomentType)) {
        if (
          decodeURIComponent(param.MomentType)
            .split(";|;")
            .filter((item) => item !== "" && moment.MomentType.includes(item))
            .length === 0
        ) {
          return true;
        }
      }
      items.push(moment);
    });
    return items;
  }, [momentList, param, excelDownloading]);
  return (
    <div className="d-none">
      <table id="calendar-table-excel-download">
        <tbody>
          <tr>
            <td>
              <strong>Title</strong>
            </td>
            <td>
              <strong>Date selection</strong>
            </td>
            <td>
              <strong>Start date</strong>
            </td>
            <td>
              <strong>End date</strong>
            </td>
            <td>
              <strong>Solution area</strong>
            </td>
            <td>
              <strong>Description</strong>
            </td>
            <td>
              <strong>Geo Location</strong>
            </td>
            <td>
              <strong>Contact(s)</strong>
            </td>
            <td>
              <strong>Moment/Feature ID</strong>
            </td>
            <td>
              <strong>Target audience</strong>
            </td>
            <td>
              <strong>Tier 1 Disclosures</strong>
            </td>
            <td>
              <strong>Tier 2 Disclosures</strong>
            </td>
            <td>
              <strong>Tier 3 Disclosures</strong>
            </td>
            <td>
              <strong>Moment type</strong>
            </td>
            <td>
              <strong>Product family</strong>
            </td>
            <td>
              <strong>Main disclosure channel</strong>
            </td>
            <td>
              <strong>TBC Category</strong>
            </td>
            <td>
              <strong>Priority item</strong>
            </td>
            <td>
              <strong>Barriers to success</strong>
            </td>
            <td>
              <strong>News zone</strong>
            </td>
            <td>
              <strong>News zone content</strong>
            </td>
          </tr>
          {filterEvents.map((moment) => {
            return <ContentRow moment={moment} />;
          })}
        </tbody>
      </table>
    </div>
  );
};
export default CalendarExcelDownload;
