import React, { useState } from "react";
import "../../../Shared/css/timelineGrid.scss";
import "../index.scss";
import { featureGroupByProps } from "../../../../slice/modernWorkTimeline";
import { SingleProductFeature } from "./singleProductFeature";
import { CollapseAll } from "./collapseAll";

const method: React.Dispatch<React.SetStateAction<boolean>> = () => {};

const openAllFeatureDefault = {
  isOpen: true,
  isClick: false,
  setClick: method,
};

export const OpenAllFeatureContext = React.createContext(openAllFeatureDefault);

export const ProductFeature = (features: {
  productFeatures: featureGroupByProps;
}) => {
  const [openTdFeature, setOpenTdFeature] = useState(true);
  const [clickTdFeature, setClickTdFeature] = useState(true);

  const valueParam = {
    isOpen: openTdFeature,
    isClick: clickTdFeature,
    setClick: setClickTdFeature,
  };

  return (
    <>
      <CollapseAll
        month={features.productFeatures.month}
        openTdFeature={openTdFeature}
        setOpenTdFeature={setOpenTdFeature}
        clickTdFeature={clickTdFeature}
        setClickTdFeature={setClickTdFeature}
      />
      <ul className="modernWorkTimeline-noStyleList">
        {features.productFeatures && features.productFeatures !== null ? (
          features.productFeatures.features.map((pfeature) => {
            return (
              <OpenAllFeatureContext.Provider
                value={valueParam}
                key={pfeature.productName}
              >
                <SingleProductFeature productFeatures={pfeature} />
              </OpenAllFeatureContext.Provider>
            );
          })
        ) : (
          <></>
        )}
      </ul>
    </>
  );
};
