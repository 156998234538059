import React, { useContext, useMemo } from "react";
import { Spinner, SpinnerSize } from "@fluentui/react";
import "../../../Shared/css/timelineGrid.scss";
import "../index.scss";
import { useUrlQueryParam } from "../../../../utils/url";
import { TimelineMode, _LOADINGSTATE } from "../../../Shared/constants";
import { ProductMilestone } from "./productMilestone";
import {
  azureTimelineMilestone,
  DataSetProps,
} from "../../../../slice/azureTimeline";
import {
  filter,
  milestoneFilterList,
} from "../../../../slice/savedDataFilters";
import { initialMilestoneState, Milestone } from "../../../../slice/milestone";
import { MilestoneModalContext } from "../..";

const RenderMilestone = (props: {
  dataSet: Omit<DataSetProps, "moments">[];
}) => {
  const [param] = useUrlQueryParam(
    milestoneFilterList.map((f: filter) => {
      return f.filterName;
    })
  );
  const milestoneModalContext = useContext(MilestoneModalContext);

  let resultText = "";
  let searchResult = 0;
  const milestoneDataSet = useMemo(() => {
    let datas: DataSetProps[] = [];
    props.dataSet.forEach((dataSet: Omit<DataSetProps, "moments">) => {
      let filterMilestones: {
        productName: string;
        conversationsId: string;
        conversationsName: string;
        milestone: azureTimelineMilestone[];
        milestoneExcelDownload: Milestone[];
      }[] = [];

      dataSet.milestone.milestones.forEach(
        (milestone: {
          productName: string;
          conversationsId: string;
          conversationsName: string;
          milestone: azureTimelineMilestone[];
        }) => {
          if (param.ConversationId) {
            if (
              decodeURIComponent(param.ConversationId)
                .split(";|;")
                .filter((item) => item !== "")
                .filter((c) => {
                  return milestone.conversationsId.split(";|;").indexOf(c) > -1;
                }).length === 0
            ) {
              return true;
            }
          }

          if (param.ProductName) {
            if (
              decodeURIComponent(param.ProductName)
                .split(";|;")
                .filter((item) => item !== "")
                .indexOf(milestone.productName) === -1
            ) {
              return true;
            }
          }

          let milestones: azureTimelineMilestone[] = [];
          milestone.milestone.forEach((milestone: azureTimelineMilestone) => {
            if (param.ContactName) {
              if (
                decodeURIComponent(param.ContactName) !== milestone.ContactName
              ) {
                return true;
              }
            }
            if (param.MarketingLeadName) {
              if (
                decodeURIComponent(param.MarketingLeadName) !==
                milestone.MarketingLeadName
              ) {
                return true;
              }
            }
            if (param.CreatedBy) {
              if (
                decodeURIComponent(param.CreatedBy)
                  .split(";|;")
                  .filter((item) => item !== "")
                  .indexOf(milestone.CreatedBy) === -1
              ) {
                return true;
              }
            }
            if (param.ModifiedBy) {
              if (
                decodeURIComponent(param.ModifiedBy)
                  .split(";|;")
                  .filter((item) => item !== "")
                  .indexOf(milestone.ModifiedBy) === -1
              ) {
                return true;
              }
            }
            if (param.TierId) {
              if (
                decodeURIComponent(param.TierId)
                  .split(";|;")
                  .filter((item) => item !== "")
                  .indexOf(milestone.TierId.toString()) === -1
              ) {
                return true;
              }
            }
            if (param.MilestoneTypeId) {
              if (
                decodeURIComponent(param.MilestoneTypeId)
                  .split(";|;")
                  .filter((item) => item !== "")
                  .indexOf(milestone.MilestoneTypeId.toString()) === -1
              ) {
                return true;
              }
            }
            if (param.MomentId) {
              if (
                milestone.ModernWorkSecurityEventCalendarMomentsId === null ||
                milestone.ModernWorkSecurityEventCalendarMomentsId ===
                  undefined ||
                decodeURIComponent(param.MomentId)
                  .split(";|;")
                  .filter((item) => item !== "")
                  .indexOf(
                    milestone.ModernWorkSecurityEventCalendarMomentsId.toString()
                  ) === -1
              ) {
                return true;
              }
            }
            if (param.FilterMilestoneId) {
              if (
                Number(decodeURIComponent(param.FilterMilestoneId)) !==
                milestone.MilestoneId
              ) {
                return true;
              }
            }
            if (param.Status) {
              if (
                decodeURIComponent(param.Status)
                  .split(";|;")
                  .filter((item) => item !== "")
                  .indexOf(milestone.Status.toString()) === -1
              ) {
                return true;
              }
            }
            milestones.push(milestone);
          });

          if (milestones.length > 0) {
            searchResult += milestones.length;
            filterMilestones.push({
              productName: milestone.productName,
              conversationsId: milestone.conversationsId,
              conversationsName: milestone.conversationsName,
              milestone: milestones,
              milestoneExcelDownload: [initialMilestoneState],
            });
          }
        }
      );

      let milestoneGroup = {
        month: dataSet.milestone.month,
        milestones: filterMilestones,
      };
      datas.push({
        month: dataSet.month,
        moments: { month: "", moment: [] },
        milestone: milestoneGroup,
        isLoading: dataSet.isLoading,
      });
    });

    if (milestoneModalContext.timelineMode == TimelineMode.Search) {
      if (searchResult === 0) {
        resultText = "no result available";
      } else if (searchResult === 1) {
        resultText = "1 result available";
      } else resultText = searchResult + " results available";

      document.body.querySelector("#ariaLiveReader")!.innerHTML = resultText;
    }
    return datas;
  }, [props.dataSet, param]);

  const milestoneDom = milestoneDataSet.map((item, index) => {
    return item.month !== "" ? (
      <div
        className={`grid-item grid-item-milestone ${
          index !== 0 ? "milestone-border-left" : ""
        }`}
        data-milestonenexmonth={item.month}
        key={item.month + "_milestone"}
      >
        {item.isLoading === _LOADINGSTATE.fullfilled ? (
          <ProductMilestone productMilestones={item.milestone} />
        ) : (
          <Spinner
            size={SpinnerSize.large}
            label="Loading content"
            ariaLive="polite"
            labelPosition="top"
          />
        )}
      </div>
    ) : null;
  });

  return <>{milestoneDom}</>;
};

export default RenderMilestone;
