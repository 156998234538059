import { useContext, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { Icon } from "@fluentui/react";
import { _LOADINGSTATE } from "../Shared/constants";
import "../Shared/css/modal.scss";
import "./index.scss";
import * as modernWorkFeatureHistoryReducer from "../../slice/modernWorkFeatureHistory";
import { RootState, useAppDispatch } from "../../store";
import { formatDate } from "../../utils/date";
import { ModernWorkFeatureModalContext } from "../ModernWorkTimeline";
import { isVoid } from "../../utils/utils";

export const DetailsHistory = (props: {
  appearErrorInfo: boolean;
  disabled: boolean;
  setDetailFormChanged: Function;
}) => {
  const appDispatch = useAppDispatch();

  const modernWorkFeatureModalContext = useContext(
    ModernWorkFeatureModalContext
  );

  useEffect(() => {
    if (
      modernWorkFeatureModalContext.modernWorkFeatureModalState.cchFeatureId > 0
    ) {
      appDispatch(
        modernWorkFeatureHistoryReducer.getModernWorkFeatureHistory(
          modernWorkFeatureModalContext.modernWorkFeatureModalState.cchFeatureId
        )
      );
    }
  }, [modernWorkFeatureModalContext.modernWorkFeatureModalState.cchFeatureId]);

  const modernWorkFeatureHistory = useSelector<
    RootState,
    modernWorkFeatureHistoryReducer.ModernWorkFeatureHistory[]
  >((state) => state.modernWorkFeatureHistoryReducer.modernWorkFeatureHistory);

  const appearModernWorkFeatureHistory = useMemo(() => {
    return modernWorkFeatureHistory.length > 0
      ? modernWorkFeatureHistory
          .filter(
            (h) =>
              modernWorkFeatureModalContext.modernWorkFeatureModalState.deleteFeatureHistoryId
                .split(",")
                .filter((i) => i != "")
                .map((i) => parseInt(i))
                .indexOf(h.id) == -1
          )
          .slice(0, 5)
      : null;
  }, [
    modernWorkFeatureHistory,
    modernWorkFeatureModalContext.modernWorkFeatureModalState
      .deleteFeatureHistoryId,
  ]);

  return (
    <div className="feature-detail-history">
      <div className="row" style={{ marginLeft: 0, marginRight: 0 }}>
        <div className="col-2 disabled" style={{ width: "20%" }}>
          <div>
            <strong>Previous disclosure date</strong>
          </div>
        </div>

        <div className="col-2 disabled" style={{ width: "19%" }}>
          <div>
            <strong>Previous Preview Date</strong>
          </div>
        </div>
        <div className="col-2 disabled" style={{ width: "15%" }}>
          <div>
            <strong>Previous GA Date</strong>
          </div>
        </div>
        <div className="col-2 disabled">
          <div>
            <strong>Previous feature tier</strong>
          </div>
        </div>
        <div className="col-3 disabled" style={{ width: "21%" }}>
          <div>
            <strong>Previous moment alignment</strong>
          </div>
        </div>
        <div className="col-1 disabled" style={{ width: "5%" }}>
          {" "}
        </div>
      </div>
      {appearModernWorkFeatureHistory != null &&
      appearModernWorkFeatureHistory.length > 0 ? (
        appearModernWorkFeatureHistory.map((item) => {
          return (
            <div className="row" style={{ marginLeft: 0, marginRight: 0 }}>
              <div className="col-2 disabled" style={{ width: "20%" }}>
                <div>
                  {formatDate(item.publicDisclosureDate?.toUTCString())}
                </div>
              </div>
              <div className="col-2 disabled" style={{ width: "19%" }}>
                <div>{formatDate(item.previewDate?.toUTCString())}</div>
              </div>
              <div className="col-2 disabled" style={{ width: "15%" }}>
                <div>{formatDate(item.gADate?.toUTCString())}</div>
              </div>
              <div className="col-2 disabled">
                <div>{isVoid(item.tier) ? "" : "Tier " + item.tier}</div>
              </div>
              <div className="col-3 disabled" style={{ width: "21%" }}>
                <div>{item.alignedMoment}</div>
              </div>
              <div className="col-1 disabled" style={{ width: "5%" }}>
                {!props.disabled ? (
                  <>
                    <button
                      disabled={props.appearErrorInfo}
                      style={{
                        backgroundColor: "transparent",
                        border: "none",
                      }}
                      onClick={(event) => {
                        event.preventDefault();
                        modernWorkFeatureModalContext.setModernWorkFeatureStateHandler(
                          {
                            ...modernWorkFeatureModalContext.modernWorkFeatureModalState,
                            deleteFeatureHistoryId:
                              modernWorkFeatureModalContext
                                .modernWorkFeatureModalState
                                .deleteFeatureHistoryId +
                              item.id.toString() +
                              ",",
                          }
                        );

                        props.setDetailFormChanged(true);
                      }}
                    >
                      <Icon iconName="Delete"></Icon>
                    </button>
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>
          );
        })
      ) : (
        <div className="row" style={{ marginLeft: 0, marginRight: 0 }}>
          <div className="col-2 disabled">
            <div>N/A</div>
          </div>
          <div className="col-2 disabled">
            <div>N/A</div>
          </div>
          <div className="col-2 disabled">
            <div>N/A</div>
          </div>
          <div className="col-2 disabled">
            <div>N/A</div>
          </div>
          <div className="col-3 disabled">
            <div>N/A</div>
          </div>
          <div className="col-1 disabled"> </div>
        </div>
      )}
    </div>
  );
};
