import { useState, useEffect, createRef, useMemo } from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { OnChangeValue } from "react-select";
import {
  Checkbox,
  Dropdown,
  TextField,
  PrimaryButton,
  Spinner,
  SpinnerSize,
  IDropdownOption,
} from "@fluentui/react";
import striptags from "striptags";
import "../../Shared/css/timelineFilter.scss";
import { useUrlQueryParam } from "../../../utils/url";
import { isVoid, speakText } from "../../../utils/utils";
import { SuccessMessage, ErrorMessage } from "../../Shared/messageBox";
import ConfirmationDialog from "../../Shared/confirmationDialog";
import GraphUsersAutoComplete from "../../Shared/FormInput/graphUsersAutoComplete";
import SearchableMultiSelect from "../../Shared/FormInput/searchableMultiSelect";
import { TimelineType, _LOADINGSTATE } from "../../Shared/constants";
import { buttonStyles, OptionType } from "../../Shared/styleProps";
import { RootState, useAppDispatch } from "../../../store";
import * as modernWorkFeatureTimelineReducer from "../../../slice/modernWorkTimeline";
import {
  savedDataFilter,
  getSavedDataFilters,
  saveSavedDataFilter,
  deleteSavedDataFilter,
  filter,
  featureFilterList,
} from "../../../slice/savedDataFilters";
import {
  dropdownStyles,
  labelStyles,
  inputFieldStyles,
  graphUsersAutoCompleteStyles,
  filterStyles,
} from "../../Shared/timelineFilterStyleProps";
import { modernWorkSecurityEventCalendarMomentOption } from "../../../slice/modernWorkSecurityEventCalendarMoment";

const statuOptions: [string, string][] = [
  ["No action", "4"],
  ["Action needed", "5"],
];

const Filter = () => {
  const appDispatch = useAppDispatch();
  const [filterControlMode, setFilterControlMode] = useState("left");
  const [showFilter, setShowFilter] = useState(true);
  const [showFilterList, setShowFilterList] = useState(false);
  const [deleteFilterId, setDeleteFilterId] = useState(0);
  const [currentFilterName, setCurrentFilterName] = useState("");
  const [selectedFilterName, setSelectedFilterName] = useState(
    "Select from your saved views"
  );
  const [errorMessage, setErrorMessage] = useState("");
  const [showShare, setShowShare] = useState(false);
  const [showLinkCopied, setShowLinkCopied] = useState(false);

  const mSETSFeaturesFilterProducts = useSelector(
    (state: RootState) =>
      state.modernWorkTimelineReducer.modernWorkTimelineFilter.Product
  );

  const mSETSFeaturesFilterPublicRoadmapStatus = useSelector(
    (state: RootState) =>
      state.modernWorkTimelineReducer.modernWorkTimelineFilter
        .PublicRoadmapStatus
  );

  const modernWorkFeatureProductAreas = useSelector<
    RootState,
    modernWorkFeatureTimelineReducer.ModernWorkFeatureProductAreaGroupedOption[]
  >(
    (state) =>
      state.modernWorkTimelineReducer.modernWorkTimelineFilter.ProductAreas
  );

  const mSETSFeaturesFilterCloudInstance = useSelector(
    (state: RootState) =>
      state.modernWorkTimelineReducer.modernWorkTimelineFilter.CloudInstance
  );

  const customMSETSFeaturesFilterCloudInstance = useMemo(() => {
    if (
      mSETSFeaturesFilterCloudInstance &&
      mSETSFeaturesFilterCloudInstance.length > 0
    ) {
      let cloudInstances = [...mSETSFeaturesFilterCloudInstance];
      cloudInstances.unshift({ Id: -1, Text: "None" });
      return cloudInstances;
    } else {
      return mSETSFeaturesFilterCloudInstance;
    }
  }, [mSETSFeaturesFilterCloudInstance]);

  const filterTriageTag = useSelector(
    (state: RootState) =>
      state.modernWorkTimelineReducer.modernWorkTimelineFilter.TriageTag
  );

  const milestoneTiers = useSelector(
    (state: RootState) =>
      state.modernWorkTimelineReducer.modernWorkTimelineFilter.MilestoneTier
  );

  const milestoneTiersOptions = milestoneTiers.map((a) => {
    return {
      key: a.TierId,
      text: "Tier " + a.Name,
      isSelected: false,
    };
  });
  milestoneTiersOptions.push({
    key: 4,
    text: "Non-marketable",
    isSelected: false,
  });

  const modernWorkSecurityEventCalendarMomentList = useSelector(
    (state: RootState) =>
      state.modernWorkTimelineReducer.modernWorkTimelineFilter.Moments
  );
  const isFilterLoading = useSelector(
    (state: RootState) => state.modernWorkTimelineReducer.isFilterLoading
  );

  const [isSavedDataFiltersLoading, setIsSavedDataFiltersLoading] = useState(
    _LOADINGSTATE.pending
  );
  const [isSavedDataFiltersSaving, setIsSavedDataFiltersSaving] = useState(
    _LOADINGSTATE.fullfilled
  );
  const [dataFilterList, setDataFilterList] = useState<savedDataFilter[]>([]);
  const [param, setParam] = useUrlQueryParam(
    featureFilterList.map((f: filter) => {
      return f.filterName;
    })
  );
  const [searchParams, setSearchParam] = useSearchParams();

  const saveHandler = () => {
    if (!currentFilterName || currentFilterName.trim().length === 0) {
      setErrorMessage("Input Filter Name please.");
      document.getElementById("txtFilterName")?.focus();

      return;
    } else {
      setErrorMessage("");
    }
    setIsSavedDataFiltersSaving(_LOADINGSTATE.pending);
    let filterData: filter[] = [];
    featureFilterList.forEach((filter: filter) => {
      if (filter.filterName in param && param[filter.filterName] !== "") {
        if (filter.filterType === "multiselect") {
          filterData.push({
            filterName: filter.filterName,
            filterType: filter.filterType,
            filterValue: decodeURIComponent(param[filter.filterName]).split(
              ";|;"
            ),
          });
        } else {
          filterData.push({
            filterName: filter.filterName,
            filterType: filter.filterType,
            filterValue: decodeURIComponent(param[filter.filterName]),
          });
        }
      }
    });
    appDispatch(
      saveSavedDataFilter({
        Name: currentFilterName,
        FilterData: JSON.stringify(filterData),
        FilterType: "ModernWorkTimelineFilter",
      })
    )
      .then((response) => {
        let newSavedDataFilter = response.payload as savedDataFilter;
        let newSavedDataFilterList = dataFilterList.filter(() => {
          return true;
        });
        if (
          newSavedDataFilterList
            .map((s: savedDataFilter) => {
              return s.SavedDataFilterId;
            })
            .indexOf(newSavedDataFilter.SavedDataFilterId) > -1
        ) {
          newSavedDataFilterList = newSavedDataFilterList.filter(
            (dataFilter) => {
              return (
                dataFilter.SavedDataFilterId !==
                newSavedDataFilter.SavedDataFilterId
              );
            }
          );
          SuccessMessage.show("Saved view updated!", 200, -100);
        } else {
          SuccessMessage.show("Saved view added!", 200, -100);
        }
        newSavedDataFilterList.unshift(newSavedDataFilter);
        setDataFilterList(newSavedDataFilterList);
      })
      .catch((error) => {
        ErrorMessage.show(error);
      })
      .finally(() => {
        setIsSavedDataFiltersSaving(_LOADINGSTATE.fullfilled);
      });
  };

  const deleteHandler = (key: number | undefined) => {
    if (key) {
      appDispatch(deleteSavedDataFilter(key))
        .then(() => {
          let newSavedDataFilterList = dataFilterList;

          SuccessMessage.show("Delete Filter success!");

          newSavedDataFilterList = newSavedDataFilterList.filter(
            (dataFilter) => {
              return dataFilter.SavedDataFilterId !== key;
            }
          );

          dataFilterList.every(function (savedDataFilter: savedDataFilter) {
            if (savedDataFilter.SavedDataFilterId === key) {
              if (savedDataFilter.Name === selectedFilterName) {
                setSelectedFilterName("Select one or more options");
                let query = searchParams;
                let filters = JSON.parse(savedDataFilter.FilterData);
                filters.forEach((filter: filter) => {
                  query.delete(filter.filterName);
                });
                setSearchParam(query);
              }
              return false;
            }
            return true;
          });

          setDataFilterList(newSavedDataFilterList);
        })
        .catch((error) => {
          ErrorMessage.show(error);
        });
    }
  };

  const updateUrl = (key: number | undefined) => {
    dataFilterList.every(function (savedDataFilter: savedDataFilter) {
      if (savedDataFilter.SavedDataFilterId === key) {
        let query = searchParams;
        featureFilterList.forEach((filter: filter) => {
          query.delete(filter.filterName);
        });
        let filters = JSON.parse(savedDataFilter.FilterData);
        filters.forEach((filter: filter) => {
          if (filter.filterValue) {
            query.append(
              filter.filterName,
              encodeURIComponent(
                filter.filterValue instanceof Array
                  ? filter.filterValue.join(";|;")
                  : filter.filterValue
              )
            );
          }
        });
        setSearchParam(query);
        return false;
      }
      return true;
    });
  };

  const deleteFilterDomref = createRef<HTMLButtonElement>();

  const deleteFilterHandle = (
    e: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>
  ) => {
    e.preventDefault();
    setDeleteFilterId(
      parseInt(e.currentTarget.getAttribute("data-filter-id") || "0")
    );

    deleteFilterDomref.current?.click();
  };

  const getSizes = () => {
    const browerWidth = document.documentElement.clientWidth;
    const filterSectionWidth =
      document.getElementsByClassName("filter-container")[0].clientWidth;
    let filterControlWidth =
      document.getElementById("divFilterControl")?.clientWidth;
    if (filterControlWidth !== undefined) {
      if (browerWidth - filterSectionWidth < filterControlWidth * 2) {
        setFilterControlMode("top");
      } else {
        setFilterControlMode("left");
      }
    }
  };

  useEffect(() => {
    window.removeEventListener("resize", getSizes, false);
    window.addEventListener("resize", getSizes, false);

    getSizes();
    appDispatch(
      modernWorkFeatureTimelineReducer.getModernWorkTimelineFilters(
        TimelineType.MW
      )
    );

    appDispatch(getSavedDataFilters("ModernWorkTimelineFilter")).then(
      (response) => {
        if (typeof response.payload === "string") {
          setDataFilterList([]);
        } else {
          setDataFilterList(response.payload as savedDataFilter[]);
        }
        setIsSavedDataFiltersLoading(_LOADINGSTATE.fullfilled);
      }
    );
  }, [appDispatch]);

  return (
    <div className="timeline-filter-container">
      {filterControlMode === "top" ? (
        <div className="row filter-container">
          <div className="position-relative col pb-10">
            <div
              id="divFilterControl"
              className="filters-lg-text-container text-right position-absolute d-flex d-ab"
              role="button"
              tabIndex={0}
              aria-label="Filters expand to see list of filters"
              aria-expanded={showFilter ? "true" : "false"}
              onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>) => {
                if (event.keyCode === 13) {
                  setShowFilter(() => {
                    return !showFilter;
                  });
                }
              }}
              onClick={() => {
                setShowFilter(() => {
                  return !showFilter;
                });
              }}
            >
              <span className="filters-icon">
                {" "}
                <i
                  className={`${
                    showFilter
                      ? "fa fa-angle-down fa-2x"
                      : "fa fa-angle-right fa-2x"
                  }`}
                ></i>
              </span>
              <span className="filters-text">Filters:</span>
            </div>
          </div>
        </div>
      ) : null}
      <div className="row filter-container">
        <div className="row">
          <div className="position-relative section-wrap col">
            {filterControlMode === "left" ? (
              <div
                id="divFilterControl"
                role="button"
                tabIndex={0}
                className="filters-text-container text-right position-absolute d-flex d-ab"
                aria-label="Filters expand to see list of filters"
                aria-expanded={showFilter ? "true" : "false"}
                onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>) => {
                  if (event.keyCode === 13) {
                    setShowFilter(() => {
                      return !showFilter;
                    });
                  }
                }}
                onClick={() => {
                  setShowFilter(() => {
                    return !showFilter;
                  });
                }}
              >
                <span className="filters-icon">
                  {" "}
                  <i
                    className={`${
                      showFilter
                        ? "fa fa-angle-down fa-2x"
                        : "fa fa-angle-right fa-2x"
                    }`}
                  ></i>
                </span>
                <span className="filters-text">Filters:</span>
              </div>
            ) : null}
            <div>
              <h3>
                <span
                  className="filters-lookupname"
                  role="heading"
                  aria-level={3}
                >
                  People
                </span>
              </h3>
              <div className={`${showFilter ? "" : "d-none"}`}>
                <label
                  className="ms-Label ms-Dropdown-label"
                  style={labelStyles}
                >
                  PMM
                </label>
                <GraphUsersAutoComplete
                  selectStyle={graphUsersAutoCompleteStyles}
                  selectedKey={decodeURIComponent(param.PMGContact)}
                  onChangeHandler={(value) => {
                    if (!isVoid(value) && !isVoid(value?.value)) {
                      var email = "",
                        regexEmail = "\\[(.+?)\\]",
                        matchStr = value?.value.match(regexEmail);
                      if (isVoid(matchStr)) {
                        return;
                      } else {
                        email = matchStr[1];
                      }
                      var userNameLength =
                        value?.value.length - email.length - 3;
                      var userName = value?.value.substring(0, userNameLength);
                      setParam({
                        ...param,
                        PMGContact: encodeURIComponent(userName),
                      });
                    } else {
                      let query = searchParams;
                      if (query.has("PMGContact")) {
                        query.delete("PMGContact");
                      }
                      setSearchParam(query);
                    }
                  }}
                  ariaLabel="Product marketing manager"
                  microsoftUser={true}
                  id="filter_PMGContact_Name"
                ></GraphUsersAutoComplete>
              </div>
              <div className={`${showFilter ? "" : "d-none"}`}>
                <div>
                  <label
                    className="ms-Label ms-Dropdown-label"
                    style={labelStyles}
                  >
                    PM
                  </label>
                  <GraphUsersAutoComplete
                    selectStyle={graphUsersAutoCompleteStyles}
                    selectedKey={decodeURIComponent(param.PMContact)}
                    onChangeHandler={(value) => {
                      if (!isVoid(value) && !isVoid(value?.value)) {
                        var email = "",
                          regexEmail = "\\[(.+?)\\]",
                          matchStr = value?.value.match(regexEmail);
                        if (isVoid(matchStr)) {
                          return;
                        } else {
                          email = matchStr[1];
                        }
                        var userNameLength =
                          value?.value.length - email.length - 3;
                        var userName = value?.value.substring(
                          0,
                          userNameLength
                        );
                        setParam({
                          ...param,
                          PMContact: encodeURIComponent(userName),
                        });
                      } else {
                        let query = searchParams;
                        if (query.has("PMContact")) {
                          query.delete("PMContact");
                        }
                        setSearchParam(query);
                      }
                    }}
                    ariaLabel="PM"
                    microsoftUser={true}
                    id="filter_PMContact_Name"
                  ></GraphUsersAutoComplete>
                </div>
              </div>
            </div>
          </div>
          <div className="position-relative col">
            <div>
              <h3>
                <span
                  className="filters-lookupname"
                  role="heading"
                  aria-level={3}
                >
                  Feature
                </span>
              </h3>
              <div className={`${showFilter ? "" : "d-none"}`}>
                {isFilterLoading === _LOADINGSTATE.pending ? (
                  <>
                    <div style={{ marginTop: 8 }}></div>
                    <Spinner
                      size={SpinnerSize.large}
                      label="Product(s)"
                      ariaLive="assertive"
                      labelPosition="top"
                    />
                  </>
                ) : (
                  <>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <label style={labelStyles}>Product(s)</label>
                      <Checkbox
                        checked={decodeURIComponent(param.Action) === "And"}
                        styles={{ checkbox: { marginLeft: "20px" } }}
                        onChange={(value, checked) => {
                          if (checked != undefined) {
                            setParam({
                              ...param,
                              Action: checked ? "And" : "Or",
                            });
                          }
                        }}
                      ></Checkbox>
                      <label
                        style={{
                          fontSize: "12px",
                          lineHeight: "15px",
                        }}
                      >
                        *Only show features with{" "}
                        <span
                          style={{
                            fontWeight: "bold",
                            textDecorationLine: "underline",
                          }}
                        >
                          {" "}
                          all
                        </span>{" "}
                        products chosen
                      </label>
                    </div>
                    <SearchableMultiSelect
                      ariaLabel={"Product"}
                      placeholder={"Select one or more options"}
                      id={"ModernWorkTimelineFilter_Product"}
                      options={mSETSFeaturesFilterProducts.map(
                        (mSETSFeaturesFilterProducts: {
                          Id: number;
                          Text: string;
                        }) => {
                          return {
                            value: mSETSFeaturesFilterProducts.Id.toString(),
                            label: mSETSFeaturesFilterProducts.Text,
                          };
                        }
                      )}
                      value={
                        mSETSFeaturesFilterProducts
                          .filter((product: { Id: number; Text: string }) => {
                            return (
                              decodeURIComponent(param.Product)
                                .split(";|;")
                                .indexOf(product.Text) > -1
                            );
                          })
                          .map((product: { Id: number; Text: string }) => {
                            return {
                              value: product.Id.toString(),
                              label: product.Text,
                            };
                          }) || []
                      }
                      selectStyle={filterStyles}
                      onChangeHandler={(
                        value: OnChangeValue<OptionType, true>
                      ) => {
                        if (value) {
                          setParam({
                            ...param,
                            Product: encodeURIComponent(
                              value
                                .map((v: OptionType) => {
                                  return v.label;
                                })
                                .join(";|;")
                            ),
                          });
                        }
                      }}
                    />
                  </>
                )}
              </div>
            </div>
            <div className={`${showFilter ? "" : "d-none"}`}>
              {isFilterLoading === _LOADINGSTATE.pending ? (
                <>
                  <div style={{ marginTop: 8 }}></div>
                  <Spinner
                    size={SpinnerSize.large}
                    label="Product area"
                    ariaLive="assertive"
                    labelPosition="top"
                  />
                </>
              ) : (
                <>
                  <label style={labelStyles}>Product area</label>
                  <SearchableMultiSelect
                    ariaLabel={"Product area"}
                    placeholder={"Select one or more options"}
                    id={"ModernWorkTimelineFilter_ProductArea"}
                    options={modernWorkFeatureProductAreas}
                    value={
                      modernWorkFeatureProductAreas
                        .flatMap((a) => a.options)
                        .filter(
                          (
                            product: modernWorkFeatureTimelineReducer.ModernWorkFeatureProductAreaOption
                          ) => {
                            return (
                              decodeURIComponent(param.ProductAreas)
                                .split(";|;")
                                .indexOf(product.value) > -1
                            );
                          }
                        ) || []
                    }
                    selectStyle={filterStyles}
                    onChangeHandler={(
                      value: OnChangeValue<OptionType, true>
                    ) => {
                      if (value) {
                        setParam({
                          ...param,
                          ProductAreas: encodeURIComponent(
                            value.map((v) => v.value).join(";|;")
                          ),
                        });
                      }
                    }}
                  />
                </>
              )}
            </div>
          </div>
          <div className="col">
            <div>
              <h3>
                <span className="filters-lookupname">
                  <label></label>
                </span>
              </h3>
              <div
                className={`${showFilter ? "" : "d-none"}`}
                style={{ padding: "1px 0 0 0" }}
              >
                <TextField
                  label="Public roadmap ID"
                  value={param.RoadmapID}
                  placeholder="Enter ID"
                  styles={inputFieldStyles}
                  onChange={(event, item) => {
                    if (item) {
                      item = item.replace(/[^\d]/g, "");
                    }
                    if (item && item !== "") {
                      setParam({
                        ...param,
                        RoadmapID: encodeURIComponent(item),
                      });
                    } else {
                      let query = searchParams;
                      if (query.has("RoadmapID")) {
                        query.delete("RoadmapID");
                      }
                      setSearchParam(query);
                    }
                  }}
                />
              </div>
              <div className={`${showFilter ? "" : "d-none"}`}>
                <TextField
                  label="Source ADO ID"
                  value={param.SourceADOID}
                  placeholder="Enter ID"
                  styles={inputFieldStyles}
                  onChange={(event, item) => {
                    if (item) {
                      item = item.replace(/[^\d]/g, "");
                    }
                    if (item && item !== "") {
                      setParam({
                        ...param,
                        SourceADOID: encodeURIComponent(item),
                      });
                    } else {
                      let query = searchParams;
                      if (query.has("SourceADOID")) {
                        query.delete("SourceADOID");
                      }
                      setSearchParam(query);
                    }
                  }}
                />
              </div>
            </div>
          </div>
          <div className="col section-wrap">
            <div>
              <h3>
                <span className="filters-lookupname">
                  <label></label>
                </span>
              </h3>
              <div className={`${showFilter ? "" : "d-none"}`}>
                {isFilterLoading === _LOADINGSTATE.pending ? (
                  <>
                    <div style={{ marginTop: 8 }}></div>
                    <Spinner
                      size={SpinnerSize.large}
                      label="Cloud(s)"
                      ariaLive="assertive"
                      labelPosition="top"
                    />
                  </>
                ) : (
                  <Dropdown
                    placeholder="Select one or more options"
                    label="Cloud(s)"
                    defaultSelectedKeys={
                      customMSETSFeaturesFilterCloudInstance
                        .filter(
                          (cloudInstance: { Id: number; Text: string }) => {
                            return (
                              decodeURIComponent(param.CloudInstance)
                                .split(";|;")
                                .indexOf(cloudInstance.Text) > -1
                            );
                          }
                        )
                        .map((cloudInstance: { Id: number; Text: string }) => {
                          return cloudInstance.Id;
                        }) || []
                    }
                    multiSelect={true}
                    options={customMSETSFeaturesFilterCloudInstance.map(
                      (c: { Id: number; Text: string }) => {
                        return { key: c.Id, text: c.Text };
                      }
                    )}
                    onChange={(event, item) => {
                      if (item) {
                        let updateClouds = decodeURIComponent(
                          param.CloudInstance
                        )
                          .split(";|;")
                          .filter(
                            (cloudInstance: string) =>
                              cloudInstance !== item.text
                          );
                        if (item.selected === true) {
                          updateClouds.push(item.text);
                        }
                        setParam({
                          ...param,
                          CloudInstance: encodeURIComponent(
                            updateClouds.join(";|;")
                          ),
                        });
                      }
                    }}
                    styles={dropdownStyles}
                    calloutProps={{ doNotLayer: true }}
                  />
                )}
              </div>
              <div className={`${showFilter ? "" : "d-none"}`}>
                {isFilterLoading === _LOADINGSTATE.pending ? (
                  <>
                    <div style={{ marginTop: 8 }}></div>
                    <Spinner
                      size={SpinnerSize.large}
                      label="Publication status on PR"
                      ariaLive="assertive"
                      labelPosition="top"
                    />
                  </>
                ) : (
                  <Dropdown
                    placeholder="Select one or more options"
                    label="Publication status on PR"
                    defaultSelectedKeys={
                      mSETSFeaturesFilterPublicRoadmapStatus
                        .filter((status: { Id: number; Text: string }) => {
                          return (
                            decodeURIComponent(param.PublicRoadmapStatus)
                              .split(";|;")
                              .indexOf(status.Text) > -1
                          );
                        })
                        .map((status: { Id: number; Text: string }) => {
                          return status.Id;
                        }) || []
                    }
                    multiSelect={true}
                    options={mSETSFeaturesFilterPublicRoadmapStatus.map(
                      (status: { Id: number; Text: string }) => {
                        return {
                          key: status.Id,
                          text: status.Text,
                        };
                      }
                    )}
                    onChange={(event, item) => {
                      if (item) {
                        let publicRoadmapStatus = decodeURIComponent(
                          param.PublicRoadmapStatus
                        )
                          .split(";|;")
                          .filter(
                            (publicRoadmapStatus: string) =>
                              publicRoadmapStatus !== item.text
                          );
                        if (item.selected === true) {
                          publicRoadmapStatus.push(item.text);
                        }
                        setParam({
                          ...param,
                          PublicRoadmapStatus: encodeURIComponent(
                            publicRoadmapStatus.join(";|;")
                          ),
                        });
                      }
                    }}
                    styles={dropdownStyles}
                    calloutProps={{ doNotLayer: true }}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="col">
            <h3>
              <span
                className="filters-lookupname"
                role="heading"
                aria-level={3}
              >
                Marketing planning
              </span>
            </h3>
            <div className={`${showFilter ? "" : "d-none"}`}>
              {isFilterLoading === _LOADINGSTATE.pending ? (
                <>
                  <div style={{ marginTop: 8 }}></div>
                  <Spinner
                    size={SpinnerSize.large}
                    label="Feature tiers"
                    ariaLive="assertive"
                    labelPosition="top"
                  />
                </>
              ) : (
                <>
                  {" "}
                  <label style={{ marginTop: "5px", marginBottom: "5px" }}>
                    Feature tiers
                  </label>{" "}
                  <a
                    className="text-decoration-none"
                    href="https://aka.ms/cmtieringframework"
                    target="_blank"
                    style={{ color: "#005ba2" }}
                  >
                    (guidelines)
                  </a>
                  <Dropdown
                    placeholder="Select one or more options"
                    defaultSelectedKeys={
                      decodeURIComponent(param.TierId)
                        .split(";|;")
                        .filter((tierId: string) => {
                          return (
                            milestoneTiersOptions
                              .map((tier) => {
                                return tier.key.toString();
                              })
                              .indexOf(tierId) > -1
                          );
                        })
                        .map((tierId: string) => {
                          return parseInt(tierId);
                        }) || []
                    }
                    multiSelect={true}
                    options={milestoneTiersOptions}
                    onChange={(event, item) => {
                      if (item) {
                        let updatedMilestoneTier = decodeURIComponent(
                          param.TierId
                        )
                          .split(";|;")
                          .filter(
                            (milestoneTierId: string) =>
                              milestoneTierId !== item.key.toString()
                          );
                        if (item.selected === true) {
                          updatedMilestoneTier.push(item.key.toString());
                        }
                        setParam({
                          ...param,
                          TierId: encodeURIComponent(
                            updatedMilestoneTier.join(";|;")
                          ),
                        });
                      }
                    }}
                    ariaLabel="Feature tiers"
                    styles={dropdownStyles}
                    calloutProps={{ doNotLayer: true }}
                  />
                </>
              )}
            </div>
            <div className={`${showFilter ? "" : "d-none"}`}>
              {isFilterLoading === _LOADINGSTATE.pending ? (
                <>
                  <div style={{ marginTop: 8 }}></div>
                  <Spinner
                    size={SpinnerSize.large}
                    label="Marketing moment alignment"
                    ariaLive="assertive"
                    labelPosition="top"
                  />
                </>
              ) : (
                <Dropdown
                  placeholder="Select one or more options"
                  label="Marketing moment alignment"
                  defaultSelectedKeys={
                    decodeURIComponent(param.MomentId)
                      .split(";|;")
                      .filter((momentId: string) => {
                        return (
                          modernWorkSecurityEventCalendarMomentList
                            .map(
                              (
                                moment: modernWorkSecurityEventCalendarMomentOption
                              ) => {
                                return moment.Id.toString();
                              }
                            )
                            .indexOf(momentId) > -1
                        );
                      })
                      .map((momentId: string) => {
                        return parseInt(momentId);
                      }) || []
                  }
                  multiSelect={true}
                  options={modernWorkSecurityEventCalendarMomentList.map(
                    (m: modernWorkSecurityEventCalendarMomentOption) => {
                      return {
                        key: m.Id,
                        text: striptags(m.DisplayTextWithYearAndTBD),
                        data: m.DisplayTextWithYearAndTBD,
                      };
                    }
                  )}
                  onRenderOption={(option) => {
                    return option !== undefined ? (
                      <span
                        className="ms-Dropdown-optionText"
                        style={{
                          overflow: `hidden`,
                          whiteSpace: `nowrap`,
                          textOverflow: `ellipsis`,
                          maxWidth: `100%`,
                          overflowWrap: `break-word`,
                          margin: 1,
                        }}
                        title={option.text}
                        dangerouslySetInnerHTML={{
                          __html: option.data,
                        }}
                      ></span>
                    ) : null;
                  }}
                  onChange={(event, item) => {
                    if (item) {
                      let updatedMoment = decodeURIComponent(param.MomentId)
                        .split(";|;")
                        .filter(
                          (momentId: string) => momentId !== item.key.toString()
                        );
                      if (item.selected === true) {
                        updatedMoment.push(item.key.toString());
                      }
                      setParam({
                        ...param,
                        MomentId: encodeURIComponent(updatedMoment.join(";|;")),
                      });
                    }
                  }}
                  styles={dropdownStyles}
                  calloutProps={{ calloutMaxHeight: 400, doNotLayer: true }}
                />
              )}
            </div>
          </div>
          <div className="col">
            <h3>
              <span className="filters-lookupname">
                <label></label>
              </span>
            </h3>
            <div className={`${showFilter ? "" : "d-none"}`}>
              {isFilterLoading === _LOADINGSTATE.pending ? (
                <>
                  <div style={{ marginTop: 8 }}></div>
                  <Spinner
                    size={SpinnerSize.large}
                    label="Marketing tags"
                    ariaLive="assertive"
                    labelPosition="top"
                  />
                </>
              ) : (
                <Dropdown
                  placeholder="Select one or more options"
                  label="Marketing tags"
                  defaultSelectedKeys={
                    decodeURIComponent(param.TriageTagId)
                      .split(";|;")
                      .filter((triageTagId: string) => {
                        return (
                          filterTriageTag
                            .map((triageTags: { Id: number; Text: string }) => {
                              return triageTags.Id.toString();
                            })
                            .indexOf(triageTagId) > -1
                        );
                      })
                      .map((triageTagId: string) => {
                        return parseInt(triageTagId);
                      }) || []
                  }
                  multiSelect={true}
                  options={filterTriageTag.map((t) => {
                    return { key: t.Id, text: t.Text };
                  })}
                  onChange={(event, item) => {
                    if (item) {
                      let updatedTriageTagIds = decodeURIComponent(
                        param.TriageTagId
                      )
                        .split(";|;")
                        .filter(
                          (triageTags: string) =>
                            triageTags !== item.key.toString()
                        );
                      if (item.selected === true) {
                        updatedTriageTagIds.push(item.key.toString());
                      }
                      setParam({
                        ...param,
                        TriageTagId: encodeURIComponent(
                          updatedTriageTagIds.join(";|;")
                        ),
                      });
                    }
                  }}
                  styles={dropdownStyles}
                  calloutProps={{ doNotLayer: true }}
                />
              )}
            </div>
            <div className={`${showFilter ? "" : "d-none"}`}>
              <Dropdown
                placeholder="Select one or more options"
                label="Planning"
                defaultSelectedKeys={
                  decodeURIComponent(param.Planning)
                    .split(";|;")
                    .filter((status: string) => {
                      return (
                        statuOptions
                          .map((statu: [string, string]) => {
                            return statu[1].toString();
                          })
                          .indexOf(status) > -1
                      );
                    })
                    .map((statu: string) => {
                      return statu;
                    }) || []
                }
                multiSelect={true}
                options={statuOptions.map((s) => {
                  return { key: s[1], text: s[0] } as IDropdownOption;
                })}
                onChange={(event, item) => {
                  if (item) {
                    let updatedFeaturePlannings = decodeURIComponent(
                      param.Planning
                    )
                      .split(";|;")
                      .filter(
                        (updatedFeaturePlanning: string) =>
                          updatedFeaturePlanning !== item.key.toString()
                      );
                    if (item.selected === true) {
                      updatedFeaturePlannings.push(item.key.toString());
                    }
                    setParam({
                      ...param,
                      Planning: encodeURIComponent(
                        updatedFeaturePlannings.join(";|;")
                      ),
                    });
                  }
                }}
                styles={dropdownStyles}
                calloutProps={{ doNotLayer: true }}
              />
            </div>
          </div>
        </div>
        <div className="row filters-bordertop">
          <div className="col">
            <div style={{ marginTop: "25px", textAlign: "right" }}>
              <h3>
                <span
                  className="filters-lookupname"
                  role="heading"
                  aria-level={3}
                >
                  Saved views
                </span>
              </h3>
            </div>
          </div>
          <div className="col">
            <div className={`${showFilter ? "" : "d-none"}`}>
              <div className="d-flex" style={{ width: 248 }}>
                <TextField
                  id="txtFilterName"
                  label="Name and save this view"
                  value={currentFilterName}
                  placeholder="Enter name"
                  styles={inputFieldStyles}
                  onChange={(event, item) => {
                    setErrorMessage("");
                    setCurrentFilterName(item || "");
                  }}
                  aria-required={true}
                  required={true}
                  errorMessage={errorMessage}
                />
                <PrimaryButton
                  style={{ marginTop: 30, minWidth: 55, width: 55 }}
                  disabled={isSavedDataFiltersSaving === _LOADINGSTATE.pending}
                  ariaDescription="Save filters"
                  text="Save"
                  allowDisabledFocus
                  onClick={saveHandler}
                  styles={buttonStyles}
                />
              </div>
            </div>
          </div>
          <div className="col">
            <div
              className={`${showFilter ? "" : "d-none"}`}
              style={{ width: 250 }}
            >
              {isSavedDataFiltersLoading === _LOADINGSTATE.pending ? (
                <>
                  <div style={{ marginTop: 8 }}></div>
                  <Spinner
                    size={SpinnerSize.large}
                    label="Saved views"
                    ariaLive="assertive"
                    labelPosition="top"
                  />
                </>
              ) : (
                <>
                  <Dropdown
                    placeholder={selectedFilterName}
                    label="Saved views"
                    aria-expanded={showFilterList}
                    onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => {
                      if (e.keyCode === 13) {
                        setShowFilterList(!showFilterList);
                        e.stopPropagation();
                        document.body.onclick = (event) => {
                          let target = event.target as HTMLElement | null;
                          let needReturn = false;
                          while (target) {
                            if (
                              target.className &&
                              typeof target.className.indexOf === "function" &&
                              target.className.indexOf("filter-list") > -1
                            ) {
                              needReturn = true;
                              break;
                            }
                            target = target.parentElement;
                          }
                          if (needReturn) {
                            return;
                          }
                          setShowFilterList(false);
                        };
                      } else if (e.keyCode === 27) {
                        setShowFilterList(false);
                      }
                    }}
                    onClick={(e) => {
                      setShowFilterList(!showFilterList);
                      e.stopPropagation();
                      document.body.onclick = (event) => {
                        let target = event.target as HTMLElement | null;
                        let needReturn = false;
                        while (target) {
                          if (
                            target.className &&
                            typeof target.className.indexOf === "function" &&
                            target.className.indexOf("filter-list") > -1
                          ) {
                            needReturn = true;
                            break;
                          }
                          target = target.parentElement;
                        }
                        if (needReturn) {
                          return;
                        }
                        setShowFilterList(false);
                      };
                    }}
                    options={[]}
                    styles={dropdownStyles}
                    calloutProps={{ doNotLayer: false }}
                  />
                  <div style={{ maxHeight: 0 }}>
                    <div
                      role="menu"
                      className="filter-list"
                      //aria-multiselectable="false"
                      style={{
                        visibility: showFilterList ? "visible" : "hidden",
                      }}
                    >
                      {dataFilterList.map((filter: savedDataFilter, index) => (
                        <div
                          role="menuitem"
                          //aria-posinset={index + 1}
                          //aria-setsize={dataFilterList.length}
                          aria-current={selectedFilterName === filter.Name}
                          tabIndex={0}
                          onClick={(e) => {
                            setSelectedFilterName(filter.Name);
                            setCurrentFilterName(filter.Name);
                            updateUrl(filter.SavedDataFilterId);
                            e.currentTarget.ariaChecked = "true";
                          }}
                          className={
                            selectedFilterName === filter.Name
                              ? "d-flex filter-list-item active"
                              : "d-flex filter-list-item"
                          }
                          onKeyDown={(
                            event: React.KeyboardEvent<HTMLDivElement>
                          ) => {
                            if (event.keyCode === 13) {
                              setSelectedFilterName(filter.Name);
                              setCurrentFilterName(filter.Name);
                              updateUrl(filter.SavedDataFilterId);
                              event.currentTarget.ariaChecked = "true";
                            } else if (event.keyCode === 27) {
                              setShowFilterList(false);
                            }
                          }}
                        >
                          <div
                            style={{ width: 204, overflow: "auto" }}
                            key={filter.Name}
                            tabIndex={0}
                            aria-label={filter.Name}
                          >
                            {filter.Name}
                          </div>
                          <div
                            style={{ width: 30 }}
                            key={filter.SavedDataFilterId}
                          >
                            <i
                              className="ms-Icon ms-Icon--Delete"
                              onClick={deleteFilterHandle}
                              data-filter-id={filter.SavedDataFilterId}
                              tabIndex={0}
                              aria-label="Delete"
                              role="button"
                              onKeyDown={(
                                event: React.KeyboardEvent<HTMLDivElement>
                              ) => {
                                if (event.keyCode === 13) {
                                  deleteFilterHandle(event);
                                } else if (event.keyCode === 27) {
                                  setShowFilterList(false);
                                }
                              }}
                            ></i>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <ConfirmationDialog
                    refs={deleteFilterDomref}
                    onclickCallBack={() => {
                      return new Promise((resolve, reject) => {
                        if (deleteFilterId !== 0) {
                          deleteHandler(deleteFilterId);
                          setDeleteFilterId(0);
                        }
                      });
                    }}
                    title="Deleting Filter"
                    subText="Are you sure you want to delete this filter permanently?"
                    confirmButtonString="Delete"
                    cancelButtonString="Cancel"
                  />
                </>
              )}
            </div>
          </div>
          <div className="col">
            <div className={`${showFilter ? "share-this-view" : "d-none"}`}>
              <div style={{ width: 100 }}>
                <label
                  className="ms-Label"
                  style={{ marginTop: 5, marginBottom: 5 }}
                >
                  Share this view
                </label>
                <div
                  style={{ textAlign: "center" }}
                  role="link"
                  tabIndex={0}
                  onClick={() => {
                    setShowLinkCopied(false);
                    setShowShare(true);
                  }}
                >
                  <i
                    style={{ fontSize: 26, cursor: "pointer" }}
                    data-icon-name="Share"
                    role="presentation"
                    aria-hidden="true"
                    className="ms-Icon root-48"
                  >
                    
                  </i>
                </div>
                {showShare ? (
                  <div className="share-link-view">
                    {showLinkCopied ? (
                      <>
                        <i
                          className="ms-Icon ms-Icon--Completed completedView"
                          title="Completed"
                          aria-hidden="true"
                        ></i>
                        <div className="copyLinkText">Link Copied</div>
                      </>
                    ) : null}
                    <i
                      className="ms-Icon ms-Icon--ChromeClose font-size-16 closeShareView"
                      title="Close"
                      aria-label="Close share view"
                      aria-hidden="true"
                      onClick={() => {
                        setShowLinkCopied(false);
                        setShowShare(false);
                      }}
                    />
                    <div className="link-wrap additional-pl15">
                      <div>
                        <label
                          className="control-label"
                          style={{ marginTop: 5, marginBottom: 5 }}
                        >
                          The view share link
                        </label>
                      </div>
                      <div>
                        <input
                          className="linkInputBox"
                          type="text"
                          aria-label="Share view link"
                          value={window.location.href}
                        />
                        <button
                          className="btn btn-sm btn-primary copyButton"
                          tabIndex={0}
                          onClick={() => {
                            navigator.clipboard.writeText(window.location.href);
                            setShowLinkCopied(true);
                          }}
                        >
                          Copy
                        </button>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          <div className="col">
            <div className={`${showFilter ? "text-start" : "d-none"}`}>
              <PrimaryButton
                style={{ marginTop: 30 }}
                text="Clear filters"
                allowDisabledFocus
                onClick={() => {
                  setSelectedFilterName("Select from your saved views");
                  setCurrentFilterName("");
                  let query = searchParams;
                  featureFilterList.forEach((filter: filter) => {
                    query.delete(filter.filterName);
                  });
                  setSearchParam(query);
                  milestoneTiersOptions.forEach((tier) => {
                    tier.isSelected = false;
                  });
                  speakText("Cleared applied filters");
                }}
                styles={buttonStyles}
              />
            </div>
          </div>
          <div className="col-4">
            <div style={{ marginTop: "10px" }}>
              <label
                style={{
                  marginTop: "5px",
                  marginBottom: "5px",
                }}
              >
                {" "}
              </label>
              <Checkbox
                label="New to roadmap"
                defaultChecked={
                  !isVoid(param.OnlyShowWithNewToRoadmap) &&
                  param.OnlyShowWithNewToRoadmap.toLowerCase() === "true"
                }
                checked={
                  !isVoid(param.OnlyShowWithNewToRoadmap) &&
                  param.OnlyShowWithNewToRoadmap.toLowerCase() === "true"
                }
                onChange={(
                  ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
                  isChecked?: boolean
                ) => {
                  if (!isChecked) {
                    let query = searchParams;
                    if (query.has("OnlyShowWithNewToRoadmap")) {
                      query.delete("OnlyShowWithNewToRoadmap");
                    }
                    setSearchParam(query);
                  } else {
                    setParam({
                      ...param,
                      OnlyShowWithNewToRoadmap: isChecked,
                    });
                  }
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Filter;
