import modernWorkSecurityMSETSFeatureProductService from "../services/modernWorkSecurityMSETSFeatureProduct";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { _LOADINGSTATE, TimelineType } from "../components/Shared/constants";
import { ErrorMessage } from "../components/Shared/messageBox";

export interface initialSateProps {
  mSETSFeaturesFilterProducts: {
    isLoading: string;
    FilterComponentData: { Id: number; Product: string }[];
  };
}

const initialState: initialSateProps = {
  mSETSFeaturesFilterProducts: {
    isLoading: _LOADINGSTATE.pending,
    FilterComponentData: [{ Id: 0, Product: "" }],
  },
};

export const getModernWorkTimelineFilterProducts = createAsyncThunk(
  "getModernWorkTimelineFilterProducts",
  async (timelineType: TimelineType) =>
    await modernWorkSecurityMSETSFeatureProductService.getTimelineMSETSFeatureFilterProducts(
      timelineType.toString()
    )
);

const modernWorkSecurityMSETSFeatureProductSlice = createSlice({
  name: "modernWorkSecurityMSETSFeatureProduct",
  initialState: initialState,
  reducers: {},
  extraReducers: {
    [getModernWorkTimelineFilterProducts.fulfilled.type]: (
      state: initialSateProps,
      action: {
        payload: { Id: number; Product: string }[];
        type: string;
      }
    ) => {
      state.mSETSFeaturesFilterProducts.FilterComponentData = action.payload;
      state.mSETSFeaturesFilterProducts.isLoading = _LOADINGSTATE.fullfilled;
    },
    [getModernWorkTimelineFilterProducts.rejected.type]: (state, { error }) => {
      ErrorMessage.show(
        "There was an error in getting the MSETS features filter products. Please refresh the page and try again. If the issue persists please contact the tool administrator."
      );
    },
    [getModernWorkTimelineFilterProducts.pending.type]: (state) => {
      state.mSETSFeaturesFilterProducts.isLoading = _LOADINGSTATE.pending;
    },
  },
});

export const { reducer, actions } = modernWorkSecurityMSETSFeatureProductSlice;
