import React, { useContext } from "react";
import {
  ContextualMenu,
  DirectionalHint,
  IContextualMenuItem,
} from "@fluentui/react/lib/ContextualMenu";
import "../../../Shared/css/timelineGrid.scss";
import "../index.scss";
import { TooltipHost, ITooltipHostStyles } from "@fluentui/react/lib/Tooltip";
import { useId } from "@fluentui/react-hooks";
import {
  ModalType,
  TimelineMode,
  _AUTHORIZATIONTYPE,
} from "../../../Shared/constants";
import { SecurityMilestoneUtil } from "../../../Milestone/milestoneCommon";
import { securityTimelineMilestone } from "../../../../slice/securityTimeline";
import { SecurityMilestoneModalContext } from "../..";
import { truncateText } from "../../../../utils/utils";
import MilestoneSearchRow from "./milestoneSearchRow";
import moment from "moment";
import { AppContext } from "../../../../App";

const calloutProps = { gapSpace: 0 };
const hostStyles: Partial<ITooltipHostStyles> = {
  root: { display: "inline-block" },
};

const MilestoneRow = (props: { milestone: securityTimelineMilestone }) => {
  const appContext = useContext(AppContext);
  const securityMilestoneModalContext = useContext(
    SecurityMilestoneModalContext
  );
  const menuItems: IContextualMenuItem[] = [
    {
      key: "edit",
      text: "Edit",
      onClick: () => {
        securityMilestoneModalContext.setSecurityMilestoneModalStateHandler({
          securityMilestoneId: props.milestone.SecurityMilestoneId,
          groupDate: props.milestone.GroupDate,
          modalType: ModalType.Edit,
          deleteMilestoneDateHistoryId: "",
        });
        securityMilestoneModalContext.modalRef?.current?.click();
      },
    },
    {
      key: "copy",
      text: "Copy",
      onClick: () => {
        securityMilestoneModalContext.setSecurityMilestoneModalStateHandler({
          securityMilestoneId: props.milestone.SecurityMilestoneId,
          groupDate: props.milestone.GroupDate,
          modalType: ModalType.Copy,
          deleteMilestoneDateHistoryId: "",
        });
        securityMilestoneModalContext.modalRef?.current?.click();
      },
    },
    {
      key: "delete",
      text: "Delete",
      onClick: () => {
        securityMilestoneModalContext.setSecurityMilestoneModalStateHandler({
          securityMilestoneId: props.milestone.SecurityMilestoneId,
          groupDate: props.milestone.GroupDate,
          modalType: ModalType.Delete,
          deleteMilestoneDateHistoryId: "",
        });
        securityMilestoneModalContext.deleteDialogRef?.current?.click();
      },
    },
  ];

  const linkRef = React.useRef(null);
  const [showContextualMenu, setShowContextualMenu] = React.useState(false);
  const onShowContextualMenu = React.useCallback(
    (ev: React.MouseEvent<HTMLElement>) => {
      ev.stopPropagation();
      ev.preventDefault(); // don't navigate
      setShowContextualMenu(true);
    },
    []
  );
  const onHideContextualMenu = React.useCallback(
    () => setShowContextualMenu(false),
    []
  );

  const tooltipId = useId("milestoneCardTooltip");

  return (
    <TooltipHost
      content={props.milestone.PublicTitle}
      id={tooltipId}
      calloutProps={calloutProps}
      styles={hostStyles}
      directionalHint={DirectionalHint.bottomAutoEdge}
    >
      {Math.abs(
        moment(new Date()).diff(moment(props.milestone.CreatedDate)) /
          1000 /
          3600 /
          24
      ) < 1 ? (
        <i
          className={"ms-Icon ms-Icon--Glimmer"}
          tabIndex={0}
          title="This is a new item"
          aria-hidden="true"
        />
      ) : (
        <div className="spacer" />
      )}
      <div
        aria-describedby={tooltipId}
        className="content-row-widget"
        key={props.milestone.SecurityMilestoneId}
        role="row"
        onClick={(e) => {
          if (
            appContext.accessType === _AUTHORIZATIONTYPE.admin ||
            appContext.accessType === _AUTHORIZATIONTYPE.dri ||
            appContext.accessType === _AUTHORIZATIONTYPE.editable
          ) {
            securityMilestoneModalContext.setSecurityMilestoneModalStateHandler(
              {
                securityMilestoneId: props.milestone.SecurityMilestoneId,
                groupDate: props.milestone.GroupDate,
                modalType: ModalType.Edit,
                deleteMilestoneDateHistoryId: "",
              }
            );
          } else {
            securityMilestoneModalContext.setSecurityMilestoneModalStateHandler(
              {
                securityMilestoneId: props.milestone.SecurityMilestoneId,
                modalType: ModalType.ReadOnly,
                deleteMilestoneDateHistoryId: "",
              }
            );
          }
          securityMilestoneModalContext.modalRef?.current?.click();
        }}
        onKeyDown={(e) => {
          if (e.keyCode === 13) {
            e.currentTarget.click();
          }
        }}
      >
        <div
          className="content-row d-flex"
          aria-label={"Milestone title:" + props.milestone.PublicTitle}
          tabIndex={0}
        >
          <div
            className={`content-card-type ${SecurityMilestoneUtil.Colors.milestone(
              props.milestone.SecurityMilestoneTypeId
            )}`}
          ></div>
          {securityMilestoneModalContext.timelineMode ===
          TimelineMode.Search ? (
            <MilestoneSearchRow
              milestone={props.milestone}
              key={props.milestone.SecurityMilestoneId}
            />
          ) : (
            <>
              <div role="cell" aria-label="Security Milestone Id">
                {props.milestone.SecurityMilestoneId}
              </div>
              <div
                role="cell"
                title={"Security Milestone Id " + props.milestone.PublicTitle}
              >
                {truncateText(props.milestone.PublicTitle, 65)}
              </div>
              <div role="cell" title={props.milestone.Comment}>
                {truncateText(props.milestone.Comment, 50)}
              </div>
              <div role="cell" title={props.milestone.PMM}>
                {truncateText(props.milestone.PMM, 69)}
              </div>
              <div role="cell" title={props.milestone.PM}>
                {truncateText(props.milestone.PM, 69)}
              </div>
              <div role="cell">
                {props.milestone.PrivatePreviewDate === undefined ||
                props.milestone.PrivatePreviewDate === null
                  ? ""
                  : moment(new Date(props.milestone.PrivatePreviewDate))
                      .utc()
                      .format("MM/DD/YYYY")}
              </div>
              <div role="cell">
                {props.milestone.PublicPreviewDate === undefined ||
                props.milestone.PublicPreviewDate === null
                  ? ""
                  : moment(new Date(props.milestone.PublicPreviewDate))
                      .utc()
                      .format("MM/DD/YYYY")}
              </div>
              <div role="cell">
                {props.milestone.GeneralAvailabilityDate === undefined ||
                props.milestone.GeneralAvailabilityDate === null
                  ? ""
                  : moment(new Date(props.milestone.GeneralAvailabilityDate))
                      .utc()
                      .format("MM/DD/YYYY")}
              </div>
              <div
                role="cell"
                title={props.milestone.SecurityMilestoneTypeName}
              >
                {props.milestone.SecurityMilestoneTypeName}
              </div>
              <div role="cell">
                {props.milestone.TierId === 1
                  ? "Tier 1"
                  : props.milestone.TierId === 2
                  ? "Tier 2"
                  : props.milestone.TierId === 3
                  ? "Tier 3"
                  : ""}
              </div>
            </>
          )}
          <div className="edit-icon d-flex justify-content-between">
            <span
              ref={linkRef}
              onClick={onShowContextualMenu}
              onKeyDown={(e) => {
                if (e.keyCode === 13) {
                  e.currentTarget.click();
                }
              }}
              role="button"
              aria-label="More options"
              tabIndex={0}
              className={
                appContext.accessType === _AUTHORIZATIONTYPE.admin ||
                appContext.accessType === _AUTHORIZATIONTYPE.dri ||
                appContext.accessType === _AUTHORIZATIONTYPE.editable
                  ? ""
                  : "d-none"
              }
            >
              <i
                data-icon-name="Edit"
                aria-hidden="true"
                className="ms-Icon root-48"
              >
                
              </i>
            </span>
          </div>
          {showContextualMenu ? (
            <ContextualMenu
              items={menuItems}
              hidden={false}
              target={linkRef}
              onItemClick={onHideContextualMenu}
              onDismiss={onHideContextualMenu}
            />
          ) : null}
        </div>
      </div>
    </TooltipHost>
  );
};

export default MilestoneRow;
