import { useCallback, useContext, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { useLocation, useSearchParams } from "react-router-dom";
import { unwrapResult } from "@reduxjs/toolkit";
import { Spinner, SpinnerSize } from "@fluentui/react";
import moment from "moment";
import {
  convertMonthStringToDate,
  getFirstDayInTheMonth,
  getLastDayInTheMonth,
} from "../../../utils/date";
import "../../Shared/css/timelineGrid.scss";
import "./index.scss";
import ConfirmationDialog from "../../Shared/confirmationDialog";
import { SuccessMessage } from "../../Shared/messageBox";
import {
  ModalType,
  _AUTHORIZATIONTYPE,
  ContentShowMode,
  _LOADINGSTATE,
  TimelineMode,
} from "../../Shared/constants";
import { RootState, useAppDispatch } from "../../../store";
import * as azureTimelineReducer from "../../../slice/azureTimeline";
import * as milestoneReducer from "../../../slice/milestone";
import { MilestoneModalContext } from "..";
import RenderMoments from "./moments";
import RenderMonth from "./month";
import MovePre from "./movePrevious";
import MoveNext from "./moveNext";
import RenderMilestone from "./TimelineMilestone/index";
import RenderProducts from "./products";
import { MilestoneModal } from "../../Milestone/MilestoneModal/index";
import { AppContext } from "../../../App";

const TimelineGrid = () => {
  const appContext = useContext(AppContext);
  const milestoneModalContext = useContext(MilestoneModalContext);
  const showMonth = useMemo(() => {
    return milestoneModalContext.contentShowMode === ContentShowMode.Grid
      ? 3
      : 1;
  }, [milestoneModalContext.contentShowMode]);
  const dataSource = useSelector(
    (state: RootState) => state.azureTimelineReducer.dataSet
  );
  const dataSet = useSelector(
    (state: RootState) => state.azureTimelineReducer.filterDataSet
  );

  const isMonthLoading = useSelector(
    (state: RootState) => state.azureTimelineReducer.isMonthLoading
  );
  const isLoadingSearchResult = useSelector(
    (state: RootState) => state.azureTimelineReducer.isLoadingSearchResult
  );

  const appDispatch = useAppDispatch();

  const location = useLocation();
  useEffect(() => {
    appDispatch(azureTimelineReducer.initAzureTimelineDataSet());
  }, [appDispatch, location.pathname]);

  useEffect(() => {
    //Check to see if we are using search, otherwise load data normally
    if (milestoneModalContext.timelineMode === TimelineMode.Search) {
      appDispatch(azureTimelineReducer.actions.setFilterDataSet(dataSource));
    } else if (milestoneModalContext.timelineMode === TimelineMode.TrainDate) {
      if (isMonthLoading === _LOADINGSTATE.fullfilled) {
        if (dataSource.length > showMonth) {
          const dataSourceIndex = dataSource.findIndex((d) => {
            return (
              moment(convertMonthStringToDate(d.month)) >=
              moment(
                getFirstDayInTheMonth(
                  moment(milestoneModalContext.startDate).format("MM/DD/YYYY")
                )
              )
            );
          });
          appDispatch(
            azureTimelineReducer.actions.setFilterDataSet(
              dataSource.slice(dataSourceIndex, dataSourceIndex + showMonth)
            )
          );
        } else {
          appDispatch(
            azureTimelineReducer.actions.setFilterDataSet(dataSource)
          );
        }
      }
    } else {
      if (isMonthLoading === _LOADINGSTATE.fullfilled) {
        var startDate: moment.Moment | undefined;
        var endDate: moment.Moment | undefined;
        //Load all data when the length of data source less than showMonth
        if (dataSource.length > showMonth) {
          //Get the index of the first appear month in the data source
          const dataSourceIndex = dataSource.findIndex((d) => {
            return (
              moment(convertMonthStringToDate(d.month)) >=
              moment(
                getFirstDayInTheMonth(
                  moment(milestoneModalContext.startDate).format("MM/DD/YYYY")
                )
              )
            );
          });
          appDispatch(
            azureTimelineReducer.actions.setFilterDataSet(
              dataSource.slice(dataSourceIndex, dataSourceIndex + showMonth)
            )
          );
          //Get the start and end index of appear months in the data source, and need
          //add 1 more month of the start month or end month
          var startIndex = dataSourceIndex - (dataSourceIndex > 1 ? 1 : 0);
          var endIndex =
            dataSourceIndex + showMonth < dataSource.length
              ? dataSourceIndex + showMonth
              : dataSource.length - 1;
          //Get the start and end month by start and end index
          for (var i = startIndex; i <= endIndex; i++) {
            if (dataSource[i] && dataSource[i].isLoading === "") {
              if (startDate === undefined) {
                startDate = moment(
                  convertMonthStringToDate(dataSource[i].month)
                );
              }
              endDate = moment(convertMonthStringToDate(dataSource[i].month));
            }
          }
        } else {
          appDispatch(
            azureTimelineReducer.actions.setFilterDataSet(dataSource)
          );
          startDate = moment(convertMonthStringToDate(dataSource[0].month));
          endDate = moment(
            convertMonthStringToDate(dataSource[dataSource.length - 1].month)
          );
        }
        if (startDate && endDate) {
          appDispatch(
            azureTimelineReducer.getDataSet(
              startDate.toDate(),
              getLastDayInTheMonth(endDate.format("MM/DD/YYYY"))
            )
          );
        }
      }
    }
  }, [
    appDispatch,
    dataSource,
    showMonth,
    milestoneModalContext.startDate,
    milestoneModalContext.timelineMode,
    isMonthLoading,
  ]);

  const [query] = useSearchParams();

  useEffect(() => {
    if (query.has("milestoneId") && appContext.accessType !== "") {
      if (
        appContext.accessType === _AUTHORIZATIONTYPE.admin ||
        appContext.accessType === _AUTHORIZATIONTYPE.dri ||
        appContext.accessType === _AUTHORIZATIONTYPE.editable
      ) {
        milestoneModalContext.setMilestoneModalStateHandler({
          milestoneId: Number(query.get("milestoneId")),
          modalType: ModalType.Edit,
        });
      } else {
        milestoneModalContext.setMilestoneModalStateHandler({
          milestoneId: Number(query.get("milestoneId")),
          modalType: ModalType.ReadOnly,
        });
      }
      milestoneModalContext.modalRef?.current?.click();
    }
  }, [appContext.accessType]);

  const handleScroll = useCallback(() => {
    let gridContent = document.querySelector(".grid-content");
    if (gridContent && gridContent !== null) {
      let top = gridContent.getBoundingClientRect().top;
      let left = gridContent.getBoundingClientRect().left;
      let stickyHead = document.querySelector(".grid-content-stickyhead");
      if (stickyHead && stickyHead !== null) {
        if (top < 100) {
          if (
            stickyHead.className !==
            `grid-content ${
              "grid-container-" + dataSet.length
            } grid-content-stickyhead`
          ) {
            stickyHead.className = `grid-content ${
              "grid-container-" + dataSet.length
            } grid-content-stickyhead`;
          }
        } else {
          if (
            stickyHead.className !==
            `grid-content ${
              "grid-container-" + dataSet.length
            } grid-content-stickyhead d-none`
          ) {
            stickyHead.className = `grid-content ${
              "grid-container-" + dataSet.length
            } grid-content-stickyhead d-none`;
          }
        }
        if (stickyHead.getBoundingClientRect().left !== left) {
          (stickyHead as HTMLElement).style.left = left + "px";
        }
      }
    }
  }, [dataSet]);

  useEffect(() => {
    window.removeEventListener("scroll", handleScroll);
    window.addEventListener("scroll", handleScroll);
    handleScroll();
  }, [dataSet, handleScroll]);

  return (
    <div className="timeline-content-container azureTimeline-content-container">
      <MilestoneModal />
      <ConfirmationDialog
        refs={milestoneModalContext.deleteDialogRef}
        onclickCallBack={() => {
          return appDispatch(
            milestoneReducer.deleteMilestone(
              milestoneModalContext.milestoneModalState.milestoneId
            )
          )
            .then(unwrapResult)
            .then(() => {
              SuccessMessage.show(
                "The milestone has been deleted successfully."
              );
              if (
                milestoneModalContext.milestoneModalState
                  .disclosureStartDate !== undefined
              ) {
                if (
                  dataSource.filter((data) => {
                    return (
                      moment(convertMonthStringToDate(data.month)).format(
                        "MM/YYYY"
                      ) ===
                      moment(
                        milestoneModalContext.milestoneModalState
                          .disclosureStartDate
                      ).format("MM/YYYY")
                    );
                  }).length > 0 &&
                  dataSource.filter((data) => {
                    return (
                      moment(convertMonthStringToDate(data.month)).format(
                        "MM/YYYY"
                      ) ===
                      moment(
                        milestoneModalContext.milestoneModalState
                          .disclosureStartDate
                      ).format("MM/YYYY")
                    );
                  })[0].isLoading !== ""
                ) {
                  appDispatch(
                    azureTimelineReducer.getMilestoneDataSet(
                      getFirstDayInTheMonth(
                        milestoneModalContext.milestoneModalState
                          .disclosureStartDate
                      ),
                      getLastDayInTheMonth(
                        milestoneModalContext.milestoneModalState
                          .disclosureStartDate
                      )
                    )
                  );
                }
              }
            })
            .catch();
        }}
        title="Deleting Milestone"
        subText="Are you sure you want to delete this milestone permanently?"
        confirmButtonString="Delete"
        cancelButtonString="Cancel"
      />
      {milestoneModalContext.timelineMode === TimelineMode.Timeline ||
      milestoneModalContext.timelineMode === TimelineMode.TrainDate ||
      milestoneModalContext.timelineMode === TimelineMode.Search ? (
        <div
          className={`grid-content ${"grid-container-" + dataSet.length}`}
          role="tablist"
        >
          {isMonthLoading === _LOADINGSTATE.pending || isLoadingSearchResult ? (
            <div className="grid-item column-span-all">
              <Spinner
                size={SpinnerSize.large}
                label="Loading content"
                ariaLive="polite"
                labelPosition="top"
              />
            </div>
          ) : (
            <>
              {milestoneModalContext.timelineMode === TimelineMode.Timeline ||
              milestoneModalContext.timelineMode === TimelineMode.TrainDate ? (
                <>
                  <div
                    className={`grid-content ${
                      "grid-container-" + dataSet.length
                    } grid-content-stickyhead d-none`}
                  >
                    <MovePre />
                    <RenderMonth dataSet={dataSet} />
                    <MoveNext />
                  </div>
                  <MovePre />
                  <RenderMonth dataSet={dataSet} />
                  <MoveNext />
                  <RenderMoments dataSet={dataSet} />
                </>
              ) : null}
              <RenderMilestone dataSet={dataSet} />
            </>
          )}
        </div>
      ) : (
        <div className={`grid-content grid-container-1`}>
          <RenderProducts />
        </div>
      )}
    </div>
  );
};

export default TimelineGrid;
