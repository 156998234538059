import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { _LOADINGSTATE } from "../components/Shared/constants";
import { ErrorMessage } from "../components/Shared/messageBox";
import graphProxyService from "../services/graphProxy";
import securityMilestoneService from "../services/securityMilestone";
import { SecurityConfidence } from "../slice/securityConfidence";
import { modernWorkSecurityEventCalendarMoment } from "../slice/modernWorkSecurityEventCalendarMoment";
import { fieldBlockerReason } from "./fieldBlockReason";

export interface SecurityMilestone {
  SecurityMilestoneId: number;
  PublicTitle: string;
  TierId: number;
  ProductServiceId: number | null;
  PMM: string | null;
  PM: string | null;
  NewOrChanging: string | null;
  CustomerBenefits: string | null;
  AnnouncementGroup: string | null;
  SecurityMilestoneTypeId: number;
  SecurityMilestoneTypeName: string | null;
  SecurityConfidenceId: number | null;
  SecurityConfidence: SecurityConfidence | null;
  ModernWorkSecurityEventCalendarMomentsId: number | null;
  ModernWorkSecurityEventCalendarMoments: modernWorkSecurityEventCalendarMoment | null;
  PublicDisclosureDate: string | null;
  PrivatePreviewDate: string | null;
  PublicPreviewDate: string | null;
  GeneralAvailabilityDate: string | null;
  Comment: string | null;
  CreatedDate: string | null;
  CreatedBy: string | null;
  ModifiedDate: string | null;
  ModifiedBy: string | null;
  IsMessagingMaterialEdited: boolean;
  MessagingMaterials: SecurityMilestoneMessagingMaterial[];
  IsSecurityMilestoneAudienceEdited: boolean;
  SecurityMilestoneAudiences: SecurityMilestoneAudience[];
  ProductServiceName: string;
  ProductCategory: string;
  ProductFamily: string;
  SolutionPlay: string;
  MarketingTheme: string;
  SCIGroup: string | null;
  MessagingMaterialsJson: string;
  SecurityMilestoneAudiencesJson: string;
  MarketingLead: string;
  GroupDate: string | null;
  FiscalYear: string;
  FiscalQuarter: string;
  FiscalMonth: string;
  ModernWorkSecurityMSETSFeaturesId: number | null;
  HasBlocker: boolean;
  IsBlockerReasonEdited: boolean;
  BlockerReasonAmount: number | null;
  BlockerReason: SecurityMilestoneFieldBlockerReason[] | null;
  __isFormValid: boolean;
  __isLoading: string;
}

export interface milestoneGroupByTier {
  tier: string;
  milestones: SecurityMilestone[];
}

interface initialSateProps {
  SingleMilestone: SecurityMilestone;
  isMilestoneMonthsLoading: string;
  milestoneMonths: string[];
}

export const initialMilestoneState: SecurityMilestone = {
  SecurityMilestoneId: 0,
  PublicTitle: "",
  TierId: 0,
  ProductServiceId: null,
  PMM: null,
  PM: null,
  NewOrChanging: null,
  CustomerBenefits: null,
  AnnouncementGroup: null,
  SecurityMilestoneTypeId: 0,
  SecurityMilestoneTypeName: null,
  SecurityConfidenceId: null,
  SecurityConfidence: null,
  ModernWorkSecurityEventCalendarMomentsId: null,
  ModernWorkSecurityEventCalendarMoments: null,
  PublicDisclosureDate: null,
  PrivatePreviewDate: null,
  PublicPreviewDate: null,
  GeneralAvailabilityDate: null,
  Comment: null,
  CreatedDate: null,
  CreatedBy: null,
  ModifiedDate: null,
  ModifiedBy: null,
  IsMessagingMaterialEdited: false,
  MessagingMaterials: [] as SecurityMilestoneMessagingMaterial[],
  MessagingMaterialsJson: "",
  IsSecurityMilestoneAudienceEdited: false,
  SecurityMilestoneAudiences: [] as SecurityMilestoneAudience[],
  SecurityMilestoneAudiencesJson: "",
  ProductServiceName: "",
  ProductCategory: "",
  ProductFamily: "",
  SolutionPlay: "",
  MarketingTheme: "",
  SCIGroup: "",
  MarketingLead: "",
  GroupDate: null,
  FiscalYear: "",
  FiscalQuarter: "",
  FiscalMonth: "",
  ModernWorkSecurityMSETSFeaturesId: null,
  HasBlocker: false,
  BlockerReason: [],
  BlockerReasonAmount: null,
  IsBlockerReasonEdited: false,
  __isFormValid: false,
  __isLoading: "",
};

export const initialState: initialSateProps = {
  SingleMilestone: initialMilestoneState,
  isMilestoneMonthsLoading: _LOADINGSTATE.pending,
  milestoneMonths: [],
};

let timer: number;
export const debounceChange = (action: any, delay: number) => {
  return (dispatch: any) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      dispatch(action);
    }, delay) as unknown as number;
  };
};

export interface SecurityMilestoneMessagingMaterial {
  SecurityMilestoneMessagingMaterialId: number;
  SecurityMilestoneId: number;
  Url: string | null;
  Content: string | null;
  IsDeleted: boolean;
  IsAdded: boolean;
}

export interface SecurityMilestoneAudience {
  SecurityAudienceId: number;
  Name: string | null;
  IsDeleted: boolean;
  IsAdded: boolean;
}

export interface SecurityMilestoneFieldBlockerReason {
  FieldBlockerId: number;
  FieldBlocker: fieldBlockerReason | null;
}

export const getSecurityMilestone = createAsyncThunk(
  "getSecurityMilestone",
  async (securityMilestoneId: number) => {
    let securityMilestone =
      (await securityMilestoneService.getSecurityMilestone(
        securityMilestoneId
      )) as SecurityMilestone;
    return securityMilestone;
  }
);

export const getMilestoneByMomentId = createAsyncThunk(
  "getMilestoneByMomentId",
  async (id: number, { dispatch, getState }) => {
    return await securityMilestoneService.getMilestoneByMomentId(id);
  }
);

export const updateSecurityMilestone = createAsyncThunk(
  "updateSecurityMilestone",
  async (
    params: { Id: number; viewModel: SecurityMilestone },
    { dispatch, getState }
  ) => {
    return (await securityMilestoneService.updateSecurityMilestone(
      params.viewModel
    )) as SecurityMilestone;
  }
);

export const createSecurityMilestone = createAsyncThunk(
  "createSecurityMilestone",
  async (viewModel: SecurityMilestone, { dispatch, getState }) => {
    return await securityMilestoneService.createSecurityMilestone(viewModel);
  }
);

export const getManager = createAsyncThunk(
  "getManager",
  async (params: { displayName: string; mail: string }) => {
    return (await graphProxyService.getManager(
      params.displayName,
      params.mail
    )) as string;
  }
);

export const deleteMilestone = createAsyncThunk(
  "deleteMilestone",
  async (params: number) => {
    return await securityMilestoneService.deleteSecurityMilestone(params);
  }
);

const securityMilestoneSlice = createSlice({
  name: "securityMilestone",
  initialState: initialState,
  reducers: {
    reset: (state: initialSateProps) => {
      state.SingleMilestone = initialState.SingleMilestone;
    },
    changePublicTitle: (
      state: initialSateProps,
      action: { payload: string | undefined; type: string }
    ) => {
      state.SingleMilestone.PublicTitle = action.payload ?? "";
    },
    changeTier: (
      state: initialSateProps,
      action: { payload: number; type: string }
    ) => {
      state.SingleMilestone.TierId = action.payload;
    },
    changeProductService: (
      state: initialSateProps,
      action: { payload: { id: number; data: any }; type: string }
    ) => {
      state.SingleMilestone.ProductServiceId = action.payload.id;
      state.SingleMilestone.ProductCategory =
        action.payload.data.ProductCategory;
      state.SingleMilestone.ProductFamily = action.payload.data.ProductFamily;
      state.SingleMilestone.SolutionPlay = action.payload.data.SolutionPlay;
      state.SingleMilestone.MarketingTheme = action.payload.data.MarketingTheme;
    },
    changePMM: (
      state: initialSateProps,
      action: { payload: string; type: string }
    ) => {
      state.SingleMilestone.PMM = action.payload;
    },
    changeSCIGroup: (
      state: initialSateProps,
      action: { payload: string; type: string }
    ) => {
      state.SingleMilestone.SCIGroup = action.payload;
    },
    changePM: (
      state: initialSateProps,
      action: { payload: string; type: string }
    ) => {
      state.SingleMilestone.PM = action.payload;
    },
    changeAudiences: (
      state: initialSateProps,
      action: { payload: SecurityMilestoneAudience[]; type: string }
    ) => {
      state.SingleMilestone.SecurityMilestoneAudiences = action.payload;
      if (state.SingleMilestone.SecurityMilestoneAudiences.length > 0) {
        state.SingleMilestone.SecurityMilestoneAudiencesJson = JSON.stringify(
          state.SingleMilestone.SecurityMilestoneAudiences
        );
      }
    },
    changeNewOrChanging: (
      state: initialSateProps,
      action: { payload: string; type: string }
    ) => {
      state.SingleMilestone.NewOrChanging = action.payload;
    },
    changeCustomerBenefits: (
      state: initialSateProps,
      action: { payload: string; type: string }
    ) => {
      state.SingleMilestone.CustomerBenefits = action.payload;
    },
    changeAnnouncementGroup: (
      state: initialSateProps,
      action: { payload: string; type: string }
    ) => {
      state.SingleMilestone.AnnouncementGroup = action.payload;
    },
    changeMilestoneType: (
      state: initialSateProps,
      action: { payload: number; type: string }
    ) => {
      state.SingleMilestone.SecurityMilestoneTypeId = action.payload;
    },
    changeSecurityConfidence: (
      state: initialSateProps,
      action: { payload: number; type: string }
    ) => {
      state.SingleMilestone.SecurityConfidenceId = action.payload;
    },
    changeMoment: (
      state: initialSateProps,
      action: { payload: number | null; type: string }
    ) => {
      state.SingleMilestone.ModernWorkSecurityEventCalendarMomentsId =
        action.payload;
    },
    changePublicDisclosureDate: (
      state: initialSateProps,
      action: { payload: string | null; type: string }
    ) => {
      state.SingleMilestone.PublicDisclosureDate = action.payload;
    },
    changePrivatePreviewDate: (
      state: initialSateProps,
      action: { payload: string | null; type: string }
    ) => {
      state.SingleMilestone.PrivatePreviewDate = action.payload;
    },
    changePublicPreviewDate: (
      state: initialSateProps,
      action: { payload: string | null; type: string }
    ) => {
      state.SingleMilestone.PublicPreviewDate = action.payload;
    },
    changeGeneralAvailabilityDate: (
      state: initialSateProps,
      action: { payload: string | null; type: string }
    ) => {
      state.SingleMilestone.GeneralAvailabilityDate = action.payload;
    },
    changeComment: (
      state: initialSateProps,
      action: { payload: string; type: string }
    ) => {
      state.SingleMilestone.Comment = action.payload;
    },
    changeGroupDate: (
      state: initialSateProps,
      action: { payload: string | null; type: string }
    ) => {
      state.SingleMilestone.GroupDate = action.payload;
    },
    changeIsSecurityAudienceEdited: (
      state: initialSateProps,
      action: { payload: boolean; type: string }
    ) => {
      state.SingleMilestone.IsSecurityMilestoneAudienceEdited = action.payload;
    },
    changeIsFormValid: (
      state: initialSateProps,
      action: { payload: boolean; type: string }
    ) => {
      state.SingleMilestone.__isFormValid = action.payload;
    },
    changeHashBlockerFields: (
      state: initialSateProps,
      action: { payload: boolean; type: string }
    ) => {
      state.SingleMilestone.HasBlocker = action.payload;
      if (action.payload === false) {
        state.SingleMilestone.BlockerReason = [];
      }
    },
    changeIsMessagingMaterialsEdited: (
      state: initialSateProps,
      action: { payload: boolean; type: string }
    ) => {
      state.SingleMilestone.IsMessagingMaterialEdited = action.payload;
    },
    changeMessagingMaterials: (
      state: initialSateProps,
      action: { payload: SecurityMilestoneMessagingMaterial[]; type: string }
    ) => {
      state.SingleMilestone.MessagingMaterials = action.payload;
      if (state.SingleMilestone.MessagingMaterials.length > 0) {
        state.SingleMilestone.MessagingMaterialsJson = JSON.stringify(
          state.SingleMilestone.MessagingMaterials
        );
      }
    },
    setFieldBlockerReason: (
      state: initialSateProps,
      action: {
        payload: { fieldBlockerId: number; isAdd: boolean } | null;
        type: string;
      }
    ) => {
      if (action.payload === null) {
        state.SingleMilestone.BlockerReason = [];
      } else {
        if (state.SingleMilestone.BlockerReason === null) {
          state.SingleMilestone.BlockerReason = [];
        }
        if (action.payload.isAdd) {
          state.SingleMilestone.BlockerReason?.push({
            FieldBlockerId: action.payload.fieldBlockerId,
            FieldBlocker: null,
          });
        } else {
          if (state.SingleMilestone.BlockerReason !== null) {
            var deleted = state.SingleMilestone.BlockerReason.filter(
              (item) => item.FieldBlockerId !== action.payload?.fieldBlockerId
            );
            state.SingleMilestone.BlockerReason = deleted;
            //let reasonIndex = state.SingleMilestone.BlockerReason.findIndex(item => item.FieldBlocker?.FieldBlockerId !== action.payload?.fieldBlockerId);
            //state.SingleMilestone.BlockerReason?.splice(reasonIndex, 1);
          }
          //state.SingleMilestone.BlockerReason = state.SingleMilestone.BlockerReason.filter(item => item.FieldBlocker?.FieldBlockerId !== action.payload?.fieldBlockerId)
        }
      }
      state.SingleMilestone.IsBlockerReasonEdited = true;
    },
    editFieldBlockerAmount: (
      state: initialSateProps,
      action: { payload: { amount: number | null }; type: string }
    ) => {
      state.SingleMilestone.BlockerReasonAmount = action.payload.amount;
      state.SingleMilestone.IsBlockerReasonEdited = true;
    },
    changeLoading: (
      state: initialSateProps,
      action: { payload: string; type: string }
    ) => {
      state.SingleMilestone.__isLoading = action.payload;
    },
  },
  extraReducers: {
    [getSecurityMilestone.pending.type]: (state: initialSateProps) => {
      state.SingleMilestone.__isLoading = _LOADINGSTATE.pending;
    },
    [getSecurityMilestone.fulfilled.type]: (
      state: initialSateProps,
      action: PayloadAction<SecurityMilestone>
    ) => {
      if (action.payload.SecurityMilestoneId === null) {
        ErrorMessage.show(
          "Please email <a href='mailto:cmroadmap@microsoft.com'>cmroadmap@microsoft.com</a> for archived data"
        );
        throw new Error();
      } else {
        state.SingleMilestone.AnnouncementGroup =
          action.payload.AnnouncementGroup;
        state.SingleMilestone.Comment = action.payload.Comment;
        state.SingleMilestone.CreatedBy = action.payload.CreatedBy;
        state.SingleMilestone.CreatedDate = action.payload.CreatedDate;
        state.SingleMilestone.CustomerBenefits =
          action.payload.CustomerBenefits;
        state.SingleMilestone.GeneralAvailabilityDate =
          action.payload.GeneralAvailabilityDate;
        state.SingleMilestone.IsMessagingMaterialEdited = false;
        state.SingleMilestone.MessagingMaterials =
          action.payload.MessagingMaterials;
        state.SingleMilestone.ModernWorkSecurityEventCalendarMomentsId =
          action.payload.ModernWorkSecurityEventCalendarMomentsId;
        state.SingleMilestone.ModifiedBy = action.payload.ModifiedBy;
        state.SingleMilestone.ModifiedDate = action.payload.ModifiedDate;
        state.SingleMilestone.NewOrChanging = action.payload.NewOrChanging;
        state.SingleMilestone.PM = action.payload.PM;
        state.SingleMilestone.PMM = action.payload.PMM;
        state.SingleMilestone.PublicDisclosureDate =
          action.payload.PublicDisclosureDate;
        state.SingleMilestone.PrivatePreviewDate =
          action.payload.PrivatePreviewDate;
        state.SingleMilestone.ProductServiceId =
          action.payload.ProductServiceId;
        state.SingleMilestone.PublicPreviewDate =
          action.payload.PublicPreviewDate;
        state.SingleMilestone.PublicTitle = action.payload.PublicTitle;
        state.SingleMilestone.IsSecurityMilestoneAudienceEdited = false;
        state.SingleMilestone.SecurityMilestoneAudiences =
          action.payload.SecurityMilestoneAudiences;
        state.SingleMilestone.ProductServiceName =
          action.payload.ProductServiceName;
        state.SingleMilestone.ProductCategory = action.payload.ProductCategory;
        state.SingleMilestone.ProductFamily = action.payload.ProductFamily;
        state.SingleMilestone.SolutionPlay = action.payload.SolutionPlay;
        state.SingleMilestone.MarketingTheme = action.payload.MarketingTheme;
        state.SingleMilestone.SCIGroup = action.payload.SCIGroup;
        state.SingleMilestone.SecurityMilestoneId =
          action.payload.SecurityMilestoneId;
        state.SingleMilestone.SecurityConfidenceId =
          action.payload.SecurityConfidenceId;
        state.SingleMilestone.SecurityMilestoneTypeId =
          action.payload.SecurityMilestoneTypeId;
        state.SingleMilestone.TierId = action.payload.TierId;
        state.SingleMilestone.BlockerReason = action.payload.BlockerReason;
        if (
          action.payload.BlockerReason != null &&
          action.payload.BlockerReason.length !== 0
        )
          state.SingleMilestone.HasBlocker = true;
        state.SingleMilestone.BlockerReasonAmount = state.SingleMilestone
          .HasBlocker
          ? action.payload.BlockerReasonAmount
          : null;
        state.SingleMilestone.GroupDate = action.payload.GroupDate;
        state.SingleMilestone.ModernWorkSecurityMSETSFeaturesId =
          action.payload.ModernWorkSecurityMSETSFeaturesId;
      }
      state.SingleMilestone.__isLoading = _LOADINGSTATE.fullfilled;
    },
    [getSecurityMilestone.rejected.type]: (state, reason) => {
      if (reason.error.message === "Request failed with status code 404") {
        ErrorMessage.show(
          "The request is not recognized. Please refresh the page or restart your browser."
        );
      } else {
        ErrorMessage.show(
          "There was an error getting the milestone. Please refresh the page and try again. If the issue persists please contact the tool administrator."
        );
      }
    },

    [updateSecurityMilestone.fulfilled.type]: (
      state: initialSateProps,
      { payload }
    ) => {
      state.SingleMilestone.__isLoading = _LOADINGSTATE.fullfilled;
    },
    [updateSecurityMilestone.pending.type]: (state: initialSateProps) => {
      state.SingleMilestone.__isLoading = _LOADINGSTATE.pending;
    },
    [updateSecurityMilestone.rejected.type]: (state, { error }) => {
      state.SingleMilestone.__isLoading = _LOADINGSTATE.rejected;
      ErrorMessage.show(
        "There was an error updating the milestone. Please refresh the page and try again. If the issue persists please contact the tool administrator."
      );
    },
    [createSecurityMilestone.fulfilled.type]: (state: initialSateProps) => {
      state.SingleMilestone.__isLoading = _LOADINGSTATE.fullfilled;
    },
    [createSecurityMilestone.pending.type]: (state: initialSateProps) => {
      state.SingleMilestone.__isLoading = _LOADINGSTATE.pending;
    },
    [createSecurityMilestone.rejected.type]: (state, { error }) => {
      state.SingleMilestone.__isLoading = _LOADINGSTATE.rejected;
      ErrorMessage.show(
        "There was an error posting the milestone. Please refresh the page and try again. If the issue persists please contact the tool administrator."
      );
    },
    [getManager.fulfilled.type]: (state: initialSateProps, { payload }) => {
      state.SingleMilestone.MarketingLead = payload;
    },
    [getManager.rejected.type]: (state: initialSateProps) => {
      ErrorMessage.show(
        "There was an error getting the Marketing Lead. Please refresh the page and try again. If the issue persists please contact the tool administrator."
      );
    },
    [getManager.pending.type]: (state) => {},
  },
});

export const { reducer, actions } = securityMilestoneSlice;
