import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import moment from "moment";
import { isVoid } from "../utils/utils";
import {
  convertMonthStringToDate,
  getFirstDayInTheMonth,
  getLastDayInTheMonth,
  getMonthList,
} from "../utils/date";
import { _LOADINGSTATE } from "../components/Shared/constants";
import timelineService from "../services/timeline";
import customTrainDateService from "../services/customTrainDate";
import securityTimelineService from "../services/securityTimeline";
import securityMilestoneService from "../services/securityMilestone";
import modernWorkSecurityEventCalendarMomentService from "../services/modernWorkSecurityEventCalendarMoment";
import { SecurityMilestoneType } from "../slice/securityMilestoneType";
import { SecurityMilestone } from "../slice/securityMilestone";
import { ErrorMessage } from "../components/Shared/messageBox";
import {
  initTimelineTrainDatesState,
  MomentsGroupByProps,
  timelineTrainDates,
} from "./timeline";
import { modernWorkSecurityEventCalendarMomentOption } from "./modernWorkSecurityEventCalendarMoment";

export interface milestoneTier {
  TierId: number;
  Name: string;
}

export interface Option {
  Id: number;
  Text: string;
}

export interface SecurityTimelineFilterOption {
  ProductService: Option[];
  ProductCategory: string[];
  SolutionPlay: string[];
  ProductFamily: string[];
  SecurityAudience: Option[];
  MilestoneTier: milestoneTier[];
  SecurityMilestoneType: SecurityMilestoneType[];
  Moments: modernWorkSecurityEventCalendarMomentOption[];
  FiscalYear: number[];
  FiscalQuarter: number[];
  FiscalMonth: string[];
}

const securityTimelineFilterOptionInit: SecurityTimelineFilterOption = {
  ProductService: [],
  ProductCategory: [],
  SolutionPlay: [],
  ProductFamily: [],
  SecurityAudience: [],
  MilestoneTier: [],
  SecurityMilestoneType: [],
  Moments: [],
  FiscalYear: [],
  FiscalQuarter: [],
  FiscalMonth: [],
};

export interface SecurityMilestoneAudience {
  SecurityAudienceId: number;
  Name: string;
  IsDeleted: boolean;
  IsAdded: boolean;
}

export interface securityTimelineMilestone {
  SecurityMilestoneId: number;
  PublicTitle: string;
  TierId: number;
  ProductServiceId: number;
  PMM: string;
  PM: string;
  NewOrChanging: string;
  CustomerBenefits: string;
  AnnouncementGroup: string;
  SecurityMilestoneTypeId: number;
  SecurityMilestoneTypeName: string;
  SecurityConfidenceId?: number;
  ModernWorkSecurityEventCalendarMomentsId?: number;
  PublicDisclosureDate: string;
  PrivatePreviewDate: string;
  PublicPreviewDate: string;
  GeneralAvailabilityDate: string;
  Comment: string;
  CreatedDate: string;
  CreatedBy: string;
  ModifiedDate: string;
  ModifiedBy: string;
  SecurityMilestoneAudiences: SecurityMilestoneAudience[];
  ProductServiceName: string;
  ProductCategory: string;
  ProductFamily: string;
  SolutionPlay: string;
  MarketingTheme: string;
  SCIGroup: string;
  MessagingMaterialsJson: string;
  SecurityMilestoneAudiencesJson: string;
  IsMomentEdited: boolean;
  MarketingLead: string;
  FiscalYear: string;
  FiscalQuarter: string;
  FiscalMonth: string;
  GroupDate: string;
}

export interface SecurityMilestoneGroupByProps {
  month: string;
  milestones: {
    productName: string;
    milestone: securityTimelineMilestone[];
    milestoneExcelDownload: SecurityMilestone[];
  }[];
}

export interface DataSetProps {
  month: string;
  moments: MomentsGroupByProps;
  milestone: SecurityMilestoneGroupByProps;
  isLoading: string;
}

export const initDataSetState: DataSetProps[] = [];

interface initialSateProps {
  dataSet: DataSetProps[];
  filterDataSet: DataSetProps[];
  excelDownloadDataSet: {
    productName: string;
    milestoneExcelDownload: SecurityMilestone[];
  }[];
  isMonthLoading: string;
  isLoadingSearchResult: boolean;
  isLoadingExportExcelMilestone: boolean;
  isLoadedExportExcelMilestone: boolean;
  isExportingExcelMilestone: boolean;
  timelineTrainDate: timelineTrainDates;
  securityTimelineFilterOption: SecurityTimelineFilterOption;
  isFilterLoading: string;
}

const initialState: initialSateProps = {
  dataSet: initDataSetState,
  filterDataSet: initDataSetState,
  excelDownloadDataSet: [],
  isMonthLoading: _LOADINGSTATE.pending,
  isLoadingSearchResult: false,
  isLoadingExportExcelMilestone: false,
  isLoadedExportExcelMilestone: false,
  isExportingExcelMilestone: false,
  timelineTrainDate: initTimelineTrainDatesState,
  securityTimelineFilterOption: securityTimelineFilterOptionInit,
  isFilterLoading: _LOADINGSTATE.pending,
};

export const initSecurityTimelineDataSet = createAsyncThunk(
  "initSecurityTimelineDataSet",
  async () =>
    (await Promise.all([
      securityMilestoneService.getMilestoneMonths(),
      modernWorkSecurityEventCalendarMomentService.getModernWorkSecurityEventCalendarMomentMonths(
        "SCI"
      ),
    ]).then((response) => {
      const milestoneMonths = response[0] as string[];
      const modernWorkSecurityEventCalendarMomentMonths =
        response[1] as string[];
      const months = Array.from(
        new Set(
          milestoneMonths.concat(modernWorkSecurityEventCalendarMomentMonths)
        )
      );
      months.sort();
      return months;
    })) as string[]
);

export const getDataSet = (startDate?: Date, endDate?: Date) => {
  return async (dispatch: any) => {
    dispatch(
      securityTimelineSlice.actions.setDataSetIsLoading({
        startDate: getFirstDayInTheMonth(
          moment(startDate).format("MM/DD/YYYY")
        ),
        endDate: getLastDayInTheMonth(moment(endDate).format("MM/DD/YYYY")),
      })
    );
    await Promise.all([
      timelineService.getMoments(
        startDate === undefined ? "" : moment(startDate).format("MM/DD/YYYY"),
        endDate === undefined ? "" : moment(endDate).format("MM/DD/YYYY"),
        "SCI"
      ),
      securityTimelineService.getSecurityTimelineMilestone(
        startDate === undefined ? "" : moment(startDate).format("MM/DD/YYYY"),
        endDate === undefined ? "" : moment(endDate).format("MM/DD/YYYY")
      ),
    ])
      .then((response) => {
        const momenstListPayload = response[0] as MomentsGroupByProps[];
        const milestoneListPayload =
          response[1] as SecurityMilestoneGroupByProps[];
        dispatch(
          securityTimelineSlice.actions.setDataSet({
            moment: momenstListPayload,
            milestone: milestoneListPayload,
          })
        );
        dispatch(
          securityTimelineSlice.actions.setDataSetIsLoaded({
            startDate: getFirstDayInTheMonth(
              moment(startDate).format("MM/DD/YYYY")
            ),
            endDate: getLastDayInTheMonth(moment(endDate).format("MM/DD/YYYY")),
          })
        );
      })
      .catch((error: any) => {
        ErrorMessage.show(
          "There was an error getting milestones. Please refresh the page and try again. If the issue persists please contact the tool administrator."
        );
      });
  };
};

export const getMilestoneDataSet = (startDate?: Date, endDate?: Date) => {
  return async (dispatch: any) => {
    securityTimelineService
      .getSecurityTimelineMilestone(
        startDate === undefined ? "" : moment(startDate).format("MM/DD/YYYY"),
        endDate === undefined ? "" : moment(endDate).format("MM/DD/YYYY")
      )
      .then((response) => {
        const milestoneListPayload =
          response as SecurityMilestoneGroupByProps[];
        dispatch(
          securityTimelineSlice.actions.setMilestoneDataSet({
            milestone: milestoneListPayload,
          })
        );
      })
      .catch((error: any) => {
        ErrorMessage.show(
          "There was an error getting milestones. Please refresh the page and try again. If the issue persists please contact the tool administrator."
        );
      });
  };
};

export const getExcelDownloadMilestoneDataSet = (
  startDate?: Date,
  endDate?: Date
) => {
  return async (dispatch: any) => {
    const excelDownloadMilestoneListPayload = (await securityTimelineService
      .getSecurityTimelineExcelDownloadMilestone(
        startDate === undefined ? "" : moment(startDate).format("MM/DD/YYYY"),
        endDate === undefined ? "" : moment(endDate).format("MM/DD/YYYY")
      )
      .catch((error: any) => {
        ErrorMessage.show(
          "There was an error download milestones. Please refresh the page and try again. If the issue persists please contact the tool administrator."
        );
      })) as {
      productName: string;
      milestoneExcelDownload: SecurityMilestone[];
    }[];

    dispatch(
      securityTimelineSlice.actions.setExcelDownloadMilestoneDataSet({
        milestone: excelDownloadMilestoneListPayload,
      })
    );
  };
};

export const getSecurityTimelineFilters = createAsyncThunk(
  "getSecurityTimelineFilters",
  async () =>
    (await securityTimelineService.getFilters()) as SecurityTimelineFilterOption
);

export const getSearchDataSet = (searchPhrase?: string) => {
  return async (dispatch: any) => {
    dispatch(securityTimelineSlice.actions.setIsLoadingSearchResult(true));

    if (
      searchPhrase !== undefined &&
      searchPhrase !== null &&
      searchPhrase.trim() !== ""
    ) {
      securityTimelineService
        .getSecurityTimelineMilestoneSearch(searchPhrase)
        .then((response) => {
          const milestoneListPayload =
            response as SecurityMilestoneGroupByProps[];

          if (milestoneListPayload.length > 0) {
            const months = milestoneListPayload.map((x) => x.month);

            dispatch(
              securityTimelineSlice.actions.setMonth({ dateList: months })
            );
            dispatch(
              securityTimelineSlice.actions.setDataSet({
                moment: [],
                milestone: milestoneListPayload,
              })
            );

            let date = milestoneListPayload[0].month;

            dispatch(
              securityTimelineSlice.actions.setDataSetIsLoaded({
                startDate: moment(date).toDate(),
                endDate: moment(date).toDate(),
              })
            );
          } else {
            dispatch(securityTimelineSlice.actions.setMonth({ dateList: [] }));
            dispatch(
              securityTimelineSlice.actions.setDataSet({
                moment: [],
                milestone: [],
              })
            );
          }
          dispatch(
            securityTimelineSlice.actions.setIsLoadingSearchResult(false)
          );
        });
    }
  };
};

export const getExcelDownloadSearchMilestoneDataSet = (
  searchPhrase?: string
) => {
  return async (dispatch: any) => {
    const excelDownloadMilestoneListPayload =
      (await securityTimelineService.getTimelineExcelDownloadSearchMilestone(
        searchPhrase
      )) as {
        productName: string;
        milestoneExcelDownload: SecurityMilestone[];
      }[];

    dispatch(
      securityTimelineSlice.actions.setExcelDownloadMilestoneDataSet({
        milestone: excelDownloadMilestoneListPayload,
      })
    );
  };
};

const securityTimelineSlice = createSlice({
  name: "securityTimeline",
  initialState: initialState,
  reducers: {
    setMonth: (
      state: initialSateProps,
      action: {
        payload: { dateList: string[] };
        type: string;
      }
    ) => {
      let dataSet: DataSetProps[] = [];
      action.payload.dateList.map((item, index) => {
        const data = {
          month: item,
          moments: { month: item, moment: [] },
          milestone: { month: item, milestones: [] },
          isLoading: "",
        };
        dataSet.push(data);
      });
      state.dataSet = dataSet;
    },
    setDataSet: (
      state: initialSateProps,
      action: {
        payload: {
          moment: MomentsGroupByProps[];
          milestone: SecurityMilestoneGroupByProps[];
        };
        type: string;
      }
    ) => {
      let dataSet: DataSetProps[] = state.dataSet;

      dataSet.forEach((ele) => {
        const currentMoment = action.payload.moment.find(
          (m) => m.month === ele.month
        );
        if (!isVoid(currentMoment)) {
          ele.moments = currentMoment as MomentsGroupByProps;
        }

        const currentMilestone = action.payload.milestone.find(
          (m) => m.month === ele.month
        );
        if (!isVoid(currentMilestone)) {
          ele.milestone = currentMilestone as SecurityMilestoneGroupByProps;
        }
      });

      state.dataSet = dataSet;
    },
    setDataSetIsLoading: (
      state: initialSateProps,
      action: {
        payload: {
          startDate: Date;
          endDate: Date;
        };
        type: string;
      }
    ) => {
      let dataSet: DataSetProps[] = state.dataSet;
      dataSet.forEach((ele) => {
        if (
          moment(convertMonthStringToDate(ele.month)).utc() >=
            moment(action.payload.startDate).utc() &&
          moment(convertMonthStringToDate(ele.month)).utc() <=
            moment(action.payload.endDate).utc()
        ) {
          ele.isLoading = _LOADINGSTATE.pending;
        }
      });
      state.dataSet = dataSet;
    },
    setDataSetIsLoaded: (
      state: initialSateProps,
      action: {
        payload: {
          startDate: Date;
          endDate: Date;
        };
        type: string;
      }
    ) => {
      let dataSet: DataSetProps[] = state.dataSet;
      dataSet.forEach((ele) => {
        if (
          moment(convertMonthStringToDate(ele.month)).utc() >=
            moment(action.payload.startDate).utc() &&
          moment(convertMonthStringToDate(ele.month)).utc() <=
            moment(action.payload.endDate).utc()
        ) {
          ele.isLoading = _LOADINGSTATE.fullfilled;
        }
      });
      state.dataSet = dataSet;
    },
    setMilestoneDataSet: (
      state: initialSateProps,
      action: {
        payload: {
          milestone: SecurityMilestoneGroupByProps[];
        };
        type: string;
      }
    ) => {
      let dataSet: DataSetProps[] = state.dataSet;

      dataSet.forEach((ele) => {
        const currentMilestone = action.payload.milestone.find(
          (m) => m.month === ele.month
        );
        if (!isVoid(currentMilestone)) {
          ele.milestone = currentMilestone as SecurityMilestoneGroupByProps;
        }
      });

      state.dataSet = dataSet;
    },
    setExcelDownloadMilestoneDataSet: (
      state: initialSateProps,
      action: {
        payload: {
          milestone: {
            productName: string;
            milestoneExcelDownload: SecurityMilestone[];
          }[];
        };
        type: string;
      }
    ) => {
      state.excelDownloadDataSet = action.payload.milestone;
      state.isLoadingExportExcelMilestone = false;
    },
    setFilterDataSet: (
      state: initialSateProps,
      action: { payload: DataSetProps[]; type: string }
    ) => {
      state.filterDataSet = action.payload;
    },
    setIsLoadingSearchResult: (
      state: initialSateProps,
      action: { payload: boolean; type: string }
    ) => {
      state.isLoadingSearchResult = action.payload;
    },
    setIsLoadingExportExcelMilestone: (
      state: initialSateProps,
      action: { payload: boolean; type: string }
    ) => {
      state.isLoadingExportExcelMilestone = action.payload;
    },
    setIsLoadedExportExcelMilestone: (
      state: initialSateProps,
      action: { payload: boolean; type: string }
    ) => {
      state.isLoadedExportExcelMilestone = action.payload;
    },
    setIsExportingExcelMilestone: (
      state: initialSateProps,
      action: { payload: boolean; type: string }
    ) => {
      state.isExportingExcelMilestone = action.payload;
    },
    setTrainDataSet: (
      state: initialSateProps,
      action: { payload: { startDate: Date; endDate: Date }; type: string }
    ) => {
      const dates = getMonthList(
        action.payload.startDate,
        action.payload.endDate
      );
      let dataSet: DataSetProps[] = [];
      dates.map((item, index) => {
        const data = {
          month: item,
          moments: { month: item, moment: [] },
          milestone: { month: item, milestones: [] },
          isLoading: "",
        };
        dataSet.push(data);
      });
      state.dataSet = dataSet;
    },
  },
  extraReducers: {
    [initSecurityTimelineDataSet.fulfilled.type]: (
      state: initialSateProps,
      action: { payload: string[]; type: string }
    ) => {
      let dataSet: DataSetProps[] = [];
      action.payload.map((item, index) => {
        const data = {
          month: item,
          moments: { month: item, moment: [] },
          milestone: { month: item, milestones: [] },
          isLoading: "",
        };
        dataSet.push(data);
      });
      state.dataSet = dataSet;
      state.isMonthLoading = _LOADINGSTATE.fullfilled;
    },
    [initSecurityTimelineDataSet.rejected.type]: (state: initialSateProps) => {
      ErrorMessage.show(
        "There was an error getting timeline months. Please refresh the page and try again. If the issue persists please contact the tool administrator."
      );
    },
    [initSecurityTimelineDataSet.pending.type]: (state: initialSateProps) => {
      state.isMonthLoading = _LOADINGSTATE.pending;
    },
    [getSecurityTimelineFilters.fulfilled.type]: (
      state: initialSateProps,
      action: { payload: SecurityTimelineFilterOption; type: string }
    ) => {
      state.securityTimelineFilterOption = action.payload;
      state.isFilterLoading = _LOADINGSTATE.fullfilled;
    },
    [getSecurityTimelineFilters.rejected.type]: (state: initialSateProps) => {
      ErrorMessage.show(
        "There was an error getting timeline options. Please refresh the page and try again. If the issue persists please contact the tool administrator."
      );
    },
    [getSecurityTimelineFilters.pending.type]: (state: initialSateProps) => {
      state.isFilterLoading = _LOADINGSTATE.pending;
    },
  },
});

export const { reducer, actions } = securityTimelineSlice;
