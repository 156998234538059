import React from "react";
import "../../../Shared/css/timelineGrid.scss";
import "../index.scss";

export const CollapseAll = (state: {
  month: string;
  openTdMilestone: boolean;
  setOpenTdMilestone: React.Dispatch<React.SetStateAction<boolean>>;
  clickTdMilestone: boolean;
  setClickTdMilestone: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const changeStateHandle = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    state.setOpenTdMilestone(() => !state.openTdMilestone);
    state.setClickTdMilestone(() => true);
  };

  return (
    <>
      <div
        className="collapse-all"
        onClick={changeStateHandle}
        onKeyDown={(e) => {
          if (e.keyCode === 13) {
            e.currentTarget.click();
          }
        }}
      >
        <div
          className="product-title"
          role="status"
          aria-label={"Product milestones for " + state.month}
          aria-expanded={state.openTdMilestone ? "true" : "false"}
        >
          Product milestones
        </div>
        <div className="product-title">
          <a
            href={void 0}
            tabIndex={0}
            role="button"
            className="collapse-all-text"
            aria-label={
              "Product milestones for " +
              state.month +
              (state.openTdMilestone ? " Collapse all" : " Expand all")
            }
            aria-expanded={state.openTdMilestone ? "true" : "false"}
          >
            {state.openTdMilestone ? "Collapse all" : "Expand all"}
          </a>
        </div>
      </div>
      <div className="bottom-line"></div>
    </>
  );
};
