import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import azureupdatedeckService from "../services/azureupdatedeck";
import { _LOADINGSTATE } from "../components/Shared/constants";
import { ErrorMessage } from "../components/Shared/messageBox";
import { modernWorkSecurityEventCalendarMoment } from "./modernWorkSecurityEventCalendarMoment";

export interface AzureUpdateDeck {
  AzureUpdatesDeckSubmissionId: number;
  MilestoneId: number;
  PublicDisclosureDate: string;
  Details: string;
  TierId: number;
  CustomEventDateId: number | null;
  PMM: string;
  MarketingLead: string;
  EngineeringContactName: string;
  IsEmbargoed: boolean;
  DateToIncludeInDeck: string;
  Status: number;
  LastUpdatedBy: string;
  LastUpdatedTime: string | null;
  UploadPPTInProductCheckedTime: string | null;
  UploadPPTInProduct: boolean;
  SubmissionDueDate: string;
  MilestoneTypeId: number;
  Title: string;
  ProductName: string;
  MilestoneTypeName: string;
  AlignedToEvent: string;
  StatusStr: string;
  AzurePowerPoint: File | null;
  AzurePowerPointStatus: number;
  WhatsChanged: string;
  MilestonePublicDisclosureDate: string;
  OrgOwnerIds: number[];
  Conversations: number[];
  DeckUploads: AzureUpdatesDeckUpload[];
  StatusNextSteps: AzureUpdatesDeckStatusNextStep[];
  IsStatusNextStepsDirty: boolean;
  HasPPTUpdated: boolean;
  HasPPTUploaded: boolean;
  __isFormValid: boolean;
  __Versions: AzurePowerPointVersion[];
  __isLoading: string;
  azureupdatedeckStatusOptions: LookupOption[];
  azureUpdateDueDates: { Date: string }[];
  ModernWorkSecurityEventCalendarMoments: modernWorkSecurityEventCalendarMoment | null;
}

export interface LookupOption {
  key: string | number;
  text: string;
}

export interface AzureUpdatesDeckStatusNextStep {
  Id: number;
  AzureUpdatesDeckSubmissionId: number;
  Date: string;
  Notes: string;
  ToDo: string;
  Initial: string;
}

export interface AzurePowerPointVersion {
  UploadedBy: string;
  UploadedDate: string;
  Filename: string;
  LinkedFilename: string;
  Status: AzurePowerPointStatus;
  WhatsChanged: string | null;
  ContainerName: string;
  UploadedInMilestone: boolean;
}

export interface AzureUpdatesDeckUpload {
  AzureUpdatesDeckUploadId: number;
  AzureUpdatesDeckSubmissionId: number;
  ProductId: number | null;
  UploadedBy: string;
  UploadedDate: string;
  Filename: string;
  Revision: string;
  BlobUrl: string;
  Status: number;
  WhatsChanged: string;
}

export enum AzurePowerPointStatus {
  NA = 0,
  New = 1,
  Updated = 2,
}

const initialState: AzureUpdateDeck = {
  AzureUpdatesDeckSubmissionId: 0,
  MilestoneId: 0,
  PublicDisclosureDate: "",
  Details: "",
  TierId: 0,
  CustomEventDateId: null,
  PMM: "",
  MarketingLead: "",
  EngineeringContactName: "",
  IsEmbargoed: false,
  DateToIncludeInDeck: "",
  Status: 0,
  LastUpdatedBy: "",
  LastUpdatedTime: null,
  UploadPPTInProductCheckedTime: null,
  UploadPPTInProduct: false,
  SubmissionDueDate: "",
  MilestoneTypeId: 0,
  Title: "",
  ProductName: "",
  MilestoneTypeName: "",
  AlignedToEvent: "",
  StatusStr: "",
  AzurePowerPoint: null,
  AzurePowerPointStatus: 1,
  WhatsChanged: "",
  MilestonePublicDisclosureDate: "",
  OrgOwnerIds: [],
  Conversations: [],
  DeckUploads: [],
  StatusNextSteps: [],
  IsStatusNextStepsDirty: false,
  HasPPTUpdated: false,
  HasPPTUploaded: false,
  __isFormValid: false,
  __Versions: [],
  __isLoading: "",
  azureupdatedeckStatusOptions: [],
  azureUpdateDueDates: [],
  ModernWorkSecurityEventCalendarMoments: null,
};

interface StatusOption {
  AzureUpdatesDeckStatusId: string;
  Name: string;
}

export const getAzureUpdateDeckDueDates = createAsyncThunk(
  "getAzureUpdateDeckDueDates",
  async () => {
    return (await azureupdatedeckService.getAzureUpdateDeckDueDates()) as string[];
  }
);

export const getAzureUpdateDeckStatusOptions = createAsyncThunk(
  "getAzureUpdateDeckStatusOptions",
  async () => {
    return (await azureupdatedeckService.getDeckStatus()) as StatusOption[];
  }
);

export const getAzureUpdateDeck = createAsyncThunk(
  "getAzureUpdateDeck",
  async (milestoneid: number, { dispatch, getState }) => {
    return (await azureupdatedeckService.getAzureUpdateDeck(
      milestoneid
    )) as AzureUpdateDeck;
  }
);

export const getVersions = createAsyncThunk(
  "getVersions",
  async (azureupdatedeckId: number, { dispatch }) => {
    const payload = (await azureupdatedeckService.getVersions(
      azureupdatedeckId
    )) as AzurePowerPointVersion[];
    return payload;
  }
);

export const putAzureUpdateDeck = createAsyncThunk(
  "putAzureUpdateDeck",
  async (params: any, { dispatch, getState }) => {
    return await azureupdatedeckService.putAzureUpdateDeck(
      params.id,
      params.state
    );
  }
);

export const postAzureUpdateDeck = createAsyncThunk(
  "postAzureUpdateDeck",
  async (params: any) => {
    return await azureupdatedeckService.postAzureUpdateDeck(params);
  }
);

export const postPowerpoint = createAsyncThunk(
  "postAzureUpdateDeckDeckPowerpoint",
  async (params: any, { dispatch, getState }) => {
    return (await azureupdatedeckService.postPowerpoint(
      params.id,
      params.status,
      params.file,
      params.whatschanged
    )) as AzureUpdatesDeckUpload;
  }
);

const azureupdatedeckSlice = createSlice({
  name: "AzureUpdateDeck",
  initialState: initialState,
  reducers: {
    reset: (state: AzureUpdateDeck) => {
      state = initialState;
    },
    changeAzurePowerPointStatus: (
      state: AzureUpdateDeck,
      action: { payload: number; type: string }
    ) => {
      state.AzurePowerPointStatus = action.payload;
    },
    changeId: (
      state: AzureUpdateDeck,
      action: { payload: number; type: string }
    ) => {
      state.AzureUpdatesDeckSubmissionId = action.payload;
    },
    changePublicationDate: (
      state: AzureUpdateDeck,
      action: { payload: string; type: string }
    ) => {
      state.PublicDisclosureDate = action.payload;
    },
    changePMM: (
      state: AzureUpdateDeck,
      action: { payload: string; type: string }
    ) => {
      state.PMM = action.payload;
    },
    changeMarketingLead: (
      state: AzureUpdateDeck,
      action: { payload: string; type: string }
    ) => {
      state.MarketingLead = action.payload;
    },
    changeEngineeringContact: (
      state: AzureUpdateDeck,
      action: { payload: string; type: string }
    ) => {
      state.EngineeringContactName = action.payload;
    },
    changeMomentId: (
      state: AzureUpdateDeck,
      action: { payload: number; type: string }
    ) => {
      if (state.ModernWorkSecurityEventCalendarMoments) {
        state.ModernWorkSecurityEventCalendarMoments.Id = action.payload;
      } else {
        state.ModernWorkSecurityEventCalendarMoments = {
          Id: 0,
          Title: "",
          StartDate: "",
          EndDate: "",
          ProductFamily: "",
          MomentType: "",
          Location: "",
          BICContact: "",
          CategoryName: "",
          Description: "",
          CMRDID: null,
          DisplayText: "",
          DisplayTextWithYear: "",
          DisplayTextWithYearAndTBD: "",
          BIC_DateSelection: "",
          MultipleCSA: "",
          TargetAudience: "",
          MainDisclosureChannel: "",
          NewsZone: "",
          NewsZoneContent: "",
        };
        state.ModernWorkSecurityEventCalendarMoments.Id = action.payload;
      }
    },
    changeTierId: (
      state: AzureUpdateDeck,
      action: { payload: number; type: string }
    ) => {
      state.TierId = action.payload;
    },
    changeCustomEventDateId: (
      state: AzureUpdateDeck,
      action: { payload: number; type: string }
    ) => {
      state.CustomEventDateId = action.payload;
    },
    changeDetails: (
      state: AzureUpdateDeck,
      action: { payload: string; type: string }
    ) => {
      state.Details = action.payload;
    },
    changeIsEmbargoed: (
      state: AzureUpdateDeck,
      action: { payload: boolean; type: string }
    ) => {
      state.IsEmbargoed = action.payload;
    },
    changeDateToIncludeInDeck: (
      state: AzureUpdateDeck,
      action: { payload: string; type: string }
    ) => {
      state.DateToIncludeInDeck = action.payload;
    },
    changeNextStepData: (
      state: AzureUpdateDeck,
      action: { payload: AzureUpdatesDeckStatusNextStep[]; type: string }
    ) => {
      state.StatusStr = JSON.stringify(action.payload);
      state.IsStatusNextStepsDirty = true;
    },
    changeIsFormValid: (
      state: AzureUpdateDeck,
      action: { type: string; payload: boolean }
    ) => {
      state.__isFormValid = action.payload;
    },
    changeUploadPPTInProduct: (
      state: AzureUpdateDeck,
      action: { type: string; payload: boolean }
    ) => {
      state.UploadPPTInProduct = action.payload;
      state.HasPPTUpdated = action.payload;
    },
    changeFile: (
      state: AzureUpdateDeck,
      action: { type: string; payload: File }
    ) => {
      state.AzurePowerPoint = action.payload;
    },
    changeWhatsChanged: (
      state: AzureUpdateDeck,
      action: { type: string; payload: string }
    ) => {
      state.WhatsChanged = action.payload;
    },
    changeLoading: (
      state: AzureUpdateDeck,
      action: { payload: string; type: string }
    ) => {
      state.__isLoading = action.payload;
    },
    resetVersions: (state: AzureUpdateDeck) => {
      state.__Versions = [];
    },
  },
  extraReducers: {
    [getAzureUpdateDeck.fulfilled.type]: (state, { payload }) => {
      state.AzureUpdatesDeckSubmissionId = payload.AzureUpdatesDeckSubmissionId;
      state.MilestoneId = payload.MilestoneId;
      state.PublicDisclosureDate = payload.PublicDisclosureDate;
      state.Details = payload.Details;
      state.TierId = payload.TierId;
      state.CustomEventDateId = payload.CustomEventDateId;
      state.PMM = payload.PMM;
      state.MarketingLead = payload.MarketingLead;
      state.EngineeringContactName = payload.EngineeringContactName;
      state.IsEmbargoed = payload.IsEmbargoed;
      state.DateToIncludeInDeck = payload.DateToIncludeInDeck;
      state.Status = payload.Status;
      state.LastUpdatedBy = payload.LastUpdatedBy;
      state.LastUpdatedTime = payload.LastUpdatedTime;
      state.UploadPPTInProductCheckedTime =
        payload.UploadPPTInProductCheckedTime;
      state.UploadPPTInProduct = payload.UploadPPTInProduct;
      state.SubmissionDueDate = payload.SubmissionDueDate;
      state.MilestoneTypeId = payload.MilestoneTypeId;
      state.Title = payload.Title;
      state.ProductName = payload.ProductName;
      state.MilestoneTypeName = payload.MilestoneTypeName;
      state.AlignedToEvent = payload.AlignedToEvent;
      state.StatusStr = payload.StatusStr;
      state.AzurePowerPoint = payload.AzurePowerPoint;
      state.AzurePowerPointStatus = 1;
      state.WhatsChanged = payload.WhatsChanged;
      state.MilestonePublicDisclosureDate =
        payload.MilestonePublicDisclosureDate;
      state.OrgOwnerIds = payload.OrgOwnerIds;
      state.Conversations = payload.Conversations;
      state.DeckUploads = payload.DeckUploads;
      state.StatusNextSteps = payload.StatusNextSteps;
      state.IsStatusNextStepsDirty = payload.IsStatusNextStepsDirty;
      state.HasPPTUpdated = payload.HasPPTUpdated;
      state.HasPPTUploaded = payload.HasPPTUploaded;
      state.__isLoading = _LOADINGSTATE.fullfilled;
      state.azureUpdateDueDates = [];
      state.ModernWorkSecurityEventCalendarMoments =
        payload.ModernWorkSecurityEventCalendarMoments;
    },
    [getAzureUpdateDeck.rejected.type]: (state, { error }) => {
      state.__isLoading = _LOADINGSTATE.rejected;
      ErrorMessage.show(
        "There was an error getting the Azure Update Deck. Please refresh the page and try again. If the issue persists please contact the tool administrator."
      );
    },
    [getAzureUpdateDeck.pending.type]: (state) => {
      state.__isLoading = _LOADINGSTATE.pending;
    },
    [getVersions.fulfilled.type]: (state, { payload }) => {
      state.__Versions = payload;
    },
    [getVersions.rejected.type]: (state, { error }) => {
      ErrorMessage.show(
        "There was an error getting the Azure Update Deck versions. Please refresh the page and try again. If the issue persists please contact the tool administrator."
      );
    },
    [getVersions.pending.type]: (state) => {},
    [putAzureUpdateDeck.fulfilled.type]: (state, { payload }) => {
      state.__isLoading = _LOADINGSTATE.fullfilled;
    },
    [putAzureUpdateDeck.rejected.type]: (state, { error }) => {
      state.__isLoading = _LOADINGSTATE.rejected;
      ErrorMessage.show(
        "There was an error saving the Azure Update Deck. Please refresh the page and try again. If the issue persists please contact the tool administrator."
      );
    },
    [putAzureUpdateDeck.pending.type]: (state) => {
      state.__isLoading = _LOADINGSTATE.pending;
    },
    [postPowerpoint.fulfilled.type]: (state, { payload }) => {
      state.__isLoading = _LOADINGSTATE.fullfilled;
    },
    [postPowerpoint.rejected.type]: (state, { error }) => {
      state.__isLoading = _LOADINGSTATE.rejected;
      ErrorMessage.show(
        "There was an error posting the Azure Update Deck PowerPoint. Please refresh the page and try again. If the issue persists please contact the tool administrator."
      );
    },
    [postPowerpoint.pending.type]: (state) => {
      state.__isLoading = _LOADINGSTATE.pending;
    },
    [getAzureUpdateDeckStatusOptions.fulfilled.type]: (state, { payload }) => {
      state.azureupdatedeckStatusOptions = payload.map((o: StatusOption) => {
        return { key: +o.AzureUpdatesDeckStatusId, text: o.Name };
      });
    },
    [getAzureUpdateDeckStatusOptions.rejected.type]: (state, { error }) => {
      ErrorMessage.show(
        "There was an error getting the Azure Update Deck status options. Please refresh the page and try again. If the issue persists please contact the tool administrator."
      );
    },
    [getAzureUpdateDeckStatusOptions.pending.type]: (state) => {},
    [postAzureUpdateDeck.fulfilled.type]: (state, { payload }) => {
      state.AzureUpdatesDeckSubmissionId = payload.id;
      state.__isLoading = _LOADINGSTATE.fullfilled;
    },
    [postAzureUpdateDeck.pending.type]: (state) => {
      state.__isLoading = _LOADINGSTATE.pending;
    },
    [postAzureUpdateDeck.rejected.type]: (state, { error }) => {
      state.__isLoading = _LOADINGSTATE.rejected;
      ErrorMessage.show(
        "There was an error posting the Azure Update Deck. Please refresh the page and try again. If the issue persists please contact the tool administrator."
      );
    },
    [getAzureUpdateDeckDueDates.fulfilled.type]: (
      state,
      action: { payload: { Date: string }[]; type: string }
    ) => {
      state.azureUpdateDueDates = action.payload;
    },
    [getAzureUpdateDeckDueDates.rejected.type]: (state, { error }) => {
      state.__isLoading = _LOADINGSTATE.rejected;
      ErrorMessage.show(
        "There was an error getting the Azure Update Deck due dates. Please refresh the page and try again. If the issue persists please contact the tool administrator."
      );
    },
  },
});

export const { reducer, actions } = azureupdatedeckSlice;
