import { RequestAccess } from "../slice/requestAccess";
import httpClient from "./base";

const baseURL = "/RequestAccess";

const requestAccess = (requestAccess: RequestAccess) => {
  return httpClient.post(baseURL, { ...requestAccess });
};

const requestAccessService = {
  requestAccess,
};

export default requestAccessService;
