import { KeyCodes } from "@fluentui/react";
import React from "react";
import "../../../Shared/css/timelineGrid.scss";
import "../index.scss";

export const CollapseAll = (state: {
  month: string;
  openTdFeature: boolean;
  setOpenTdFeature: React.Dispatch<React.SetStateAction<boolean>>;
  clickTdFeature: boolean;
  setClickTdFeature: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const changeStateHandle = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    state.setOpenTdFeature(() => !state.openTdFeature);
    state.setClickTdFeature(() => true);
  };

  return (
    <>
      <div
        className="collapse-all"
        onClick={changeStateHandle}
        onKeyDown={(e) => {
          if (e.keyCode == KeyCodes.enter) e.currentTarget.click();
        }}
      >
        <div
          className="product-title"
          tabIndex={0}
          role="button"
          aria-label={"Product features for " + state.month}
          aria-expanded={state.openTdFeature ? "true" : "false"}
        >
          Product features
        </div>
        <div className="product-title">
          <a
            href={void 0}
            className="collapse-all-text"
            tabIndex={0}
            role="button"
            aria-label={
              "Product features for " +
              state.month +
              (state.openTdFeature ? " Collapse all" : " Expand all")
            }
            aria-expanded={state.openTdFeature ? "true" : "false"}
          >
            {state.openTdFeature ? "Collapse all" : "Expand all"}
          </a>
        </div>
      </div>
      <div className="bottom-line"></div>
    </>
  );
};
