import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import audienceService from "../services/audience";
import { _LOADINGSTATE } from "../components/Shared/constants";
import { ErrorMessage } from "../components/Shared/messageBox";

const initialOptionState = {
  audiences: [],
  __isLoading: "",
};

export interface Audience {
  AudienceId: string;
  Name: string;
}

export const getAudiences = createAsyncThunk("getAudiences", async () => {
  return (await audienceService.getAudiences()) as Audience[];
});

const audienceSlice = createSlice({
  name: "audience",
  initialState: initialOptionState,
  reducers: {},
  extraReducers: {
    [getAudiences.fulfilled.type]: (state, { payload }) => {
      state.audiences = payload;
      state.__isLoading = _LOADINGSTATE.fullfilled;
    },
    [getAudiences.rejected.type]: (state, { error }) => {
      ErrorMessage.show(
        "There was an error getting the audiences. Please refresh the page and try again. If the issue persists please contact the tool administrator."
      );
    },
    [getAudiences.pending.type]: (state) => {
      state.__isLoading = _LOADINGSTATE.pending;
    },
  },
});

export const { actions, reducer } = audienceSlice;
