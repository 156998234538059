import { AzureUpdateDeck } from "../slice/azureupdatedeck";
import httpClient from "./base";

const getAzureUpdateDeck = (milestoneid: number) => {
  var url = "AzureUpdatesDeck?milestoneid=" + milestoneid;
  return httpClient.get(url);
};

const getVersions = (azureupdatedeckId: number) => {
  var url = "AzureUpdatesDeck/Versions/" + azureupdatedeckId;
  return httpClient.get(url);
};

const putAzureUpdateDeck = (
  azureupdatedeckId: number,
  state: AzureUpdateDeck
) => {
  var url = "AzureUpdatesDeck?id=" + azureupdatedeckId;
  return httpClient.put(url, state);
};

const postAzureUpdateDeck = (state: AzureUpdateDeck) => {
  var url = "AzureUpdatesDeck";
  return httpClient.post(url, state);
};

const getDeckStatus = () => {
  let url = "AzureUpdatesDeck/DeckStatus";
  return httpClient.get(url);
};

const getAzureUpdateDeckDueDates = () => {
  let url = "AzureUpdatesDeck/AzureUpdatesDeckDueDates";
  return httpClient.get(url);
};

const postPowerpoint = (
  id: number,
  azurePowerPointStatus: number,
  httpPostedFile: File,
  WhatsChanged: string
) => {
  var url = "AzureUpdatesDeck/Upload";
  var formData = new FormData();
  formData.append("id", id.toString());
  formData.append("azurePowerPointStatus", azurePowerPointStatus.toString());
  formData.append("httpPostedFile", httpPostedFile as Blob);
  formData.append("WhatsChanged", WhatsChanged);
  return httpClient.postWithForm(url, formData);
};

const azureupdatedeckService = {
  getAzureUpdateDeck,
  getVersions,
  putAzureUpdateDeck,
  postPowerpoint,
  postAzureUpdateDeck,
  getDeckStatus,
  getAzureUpdateDeckDueDates,
};

export default azureupdatedeckService;
