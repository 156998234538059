import * as React from "react";
import DatePickerWithCustomStyles from "../../Shared/FormInput/datepickerWithCustomStyles";
import { isVoid } from "../../../utils/utils";
import { TimelineType } from "../constants";

const DatePickerWithLabelAndError = (props: {
  showMonthOnly?: boolean;
  minDate?: Date;
  considerFirstTrainOfMonth?: boolean;
  label: string;
  id: string;
  value: Date | undefined;
  onSelectDate: (date: Date | undefined | null) => void;
  error: string;
  onFormatDate?: (date?: Date | undefined) => string;
  disabled?: boolean;
  describedby?: string;
  timelineType?: TimelineType;
  blockErrorLabel?: boolean;
}) => {
  return (
    <>
      <label
        className="modal-detail-label"
        aria-describedby={"PublicdisclosureTooltip"}
      >
        {props.label}
      </label>
      <DatePickerWithCustomStyles
        describedby={props.describedby}
        minDate={props.minDate}
        considerFirstTrainOfMonth={props.considerFirstTrainOfMonth || false}
        placeholder={"Select a date..."}
        ariaLabel={
          isVoid(props.label)
            ? props.label
            : props.label.trim().endsWith("*")
            ? props.label
                .trim()
                .substring(0, props.label.trim().length - 1)
                .trim() + ", required field"
            : props.label.trim()
        }
        id={props.id}
        formatDate={props.onFormatDate}
        value={props.value}
        onChangeHandler={props.onSelectDate}
        disabled={props.disabled || false}
        showMonthOnly={props.showMonthOnly}
        timelineType={props.timelineType}
      />
      <span
        className="modal-detail-error"
        style={{ display: props.blockErrorLabel ? "block" : undefined }}
      >
        {props.error}
      </span>
    </>
  );
};

export default DatePickerWithLabelAndError;
