import { useState } from "react";
import { Dropdown } from "@fluentui/react";
import { OnChangeValue } from "react-select";
import "./index.scss";
import { useUrlQueryParam } from "../../../utils/url";
import { ConstantItems } from "./common";
import SearchableMultiSelect from "../../Shared/FormInput/searchableMultiSelect";
import { OptionType } from "../../Shared/styleProps";
import {
  dropdownStyles,
  labelStyles,
  filterStyles,
} from "../../Shared/timelineFilterStyleProps";
import { calendarFilterList, filter } from "../../../slice/savedDataFilters";

const Filter = () => {
  const [param, setParam] = useUrlQueryParam(
    calendarFilterList.map((f: filter) => {
      return f.filterName;
    })
  );

  const [showFilter, setShowFilter] = useState(true);

  return (
    <div className="timeline-filter-container calander-timeline-filter-container">
      <div className="row filter-container">
        <div className="position-relative col-2 pb-10">
          <div
            id="divFilterControl"
            role="button"
            tabIndex={0}
            className="filters-lg-text-container text-right position-absolute d-flex d-ab"
            aria-label="Filters expand to see list of filters"
            aria-expanded={showFilter ? "true" : "false"}
            onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>) => {
              if (event.keyCode === 13) {
                setShowFilter(() => {
                  return !showFilter;
                });
              }
            }}
            onClick={() => {
              setShowFilter(() => {
                return !showFilter;
              });
            }}
          >
            <span className="filters-icon">
              {" "}
              <i
                className={`${
                  showFilter
                    ? "fa fa-angle-down fa-2x"
                    : "fa fa-angle-right fa-2x"
                }`}
              ></i>
            </span>
            <span className="filters-text">Filters:</span>
          </div>
        </div>
      </div>
      <div className="row filter-container">
        <div className="position-relative filter-item">
          <div className={`${showFilter ? "" : "d-none"}`}>
            <Dropdown
              placeholder="Select one or more options"
              label="Solution area"
              defaultSelectedKeys={
                decodeURIComponent(param.SolutionArea)
                  .split(";|;")
                  .filter((solutionArea: string) => {
                    return (
                      ConstantItems.solutionArea
                        .map((solutionAreaItem) => {
                          return solutionAreaItem;
                        })
                        .indexOf(solutionArea) > -1
                    );
                  }) || []
              }
              multiSelect={true}
              options={ConstantItems.solutionArea.map((solutionArea) => {
                return {
                  key: solutionArea,
                  text: solutionArea,
                };
              })}
              onChange={(event, item) => {
                if (item) {
                  let updatedSolutionAreas = decodeURIComponent(
                    param.SolutionArea
                  )
                    .split(";|;")
                    .filter(
                      (solutionArea: string) =>
                        solutionArea !== item.key.toString()
                    );
                  if (item.selected === true) {
                    updatedSolutionAreas.push(item.key.toString());
                  }
                  setParam({
                    ...param,
                    SolutionArea: encodeURIComponent(
                      updatedSolutionAreas.join(";|;")
                    ),
                  });
                }
              }}
              required={false}
              styles={dropdownStyles}
              calloutProps={{ doNotLayer: true }}
            />
          </div>
        </div>
        <div className="position-relative filter-item">
          <div className={`${showFilter ? "" : "d-none"}`}>
            <Dropdown
              placeholder="Select one or more options"
              label="Multiple CSA"
              defaultSelectedKeys={
                decodeURIComponent(param.MultipleCSA)
                  .split(";|;")
                  .filter((csa: string) => {
                    return (
                      ConstantItems.multipleCSA
                        .map((csaItem) => {
                          return csaItem;
                        })
                        .indexOf(csa) > -1
                    );
                  }) || []
              }
              multiSelect={true}
              options={ConstantItems.multipleCSA.map((csa) => {
                return {
                  key: csa,
                  text: csa,
                };
              })}
              onChange={(event, item) => {
                if (item) {
                  let updatedMultipleCSAs = decodeURIComponent(
                    param.MultipleCSA
                  )
                    .split(";|;")
                    .filter((csa: string) => csa !== item.key.toString());
                  if (item.selected === true) {
                    updatedMultipleCSAs.push(item.key.toString());
                  }
                  setParam({
                    ...param,
                    MultipleCSA: encodeURIComponent(
                      updatedMultipleCSAs.join(";|;")
                    ),
                  });
                }
              }}
              required={false}
              styles={dropdownStyles}
              calloutProps={{ doNotLayer: true }}
            />
          </div>
        </div>
        <div className="position-relative filter-item">
          <div className={`${showFilter ? "" : "d-none"}`}>
            <label style={labelStyles}>Product family</label>
            <SearchableMultiSelect
              ariaLabel={"Product family"}
              placeholder={"Select one or more options"}
              id={"AzureTimelineFilter_ProductFamily"}
              options={ConstantItems.productFamily.map((productFamily) => {
                return {
                  value: productFamily,
                  label: productFamily,
                };
              })}
              value={
                ConstantItems.productFamily
                  .filter((productFamily: string) => {
                    return (
                      decodeURIComponent(param.ProductFamily)
                        .split(";|;")
                        .indexOf(productFamily) > -1
                    );
                  })
                  .map((productFamily: string) => {
                    return {
                      value: productFamily,
                      label: productFamily,
                    };
                  }) || []
              }
              selectStyle={filterStyles}
              onChangeHandler={(value: OnChangeValue<OptionType, true>) => {
                if (value) {
                  setParam({
                    ...param,
                    ProductFamily: encodeURIComponent(
                      value
                        .map((v: OptionType) => {
                          return v.label;
                        })
                        .join(";|;")
                    ),
                  });
                }
              }}
            />
          </div>
        </div>
        <div className="position-relative filter-item">
          <div className={`${showFilter ? "" : "d-none"}`}>
            <Dropdown
              placeholder="Select one or more options"
              label="Moment type"
              defaultSelectedKeys={
                decodeURIComponent(param.MomentType)
                  .split(";|;")
                  .filter((momentType: string) => {
                    return (
                      ConstantItems.momentType
                        .map((momentTypeItem) => {
                          return momentTypeItem;
                        })
                        .indexOf(momentType) > -1
                    );
                  }) || []
              }
              multiSelect={true}
              options={ConstantItems.momentType.map((momentType) => {
                return {
                  key: momentType,
                  text: momentType,
                };
              })}
              onChange={(event, item) => {
                if (item) {
                  let updatedMomentTypes = decodeURIComponent(param.MomentType)
                    .split(";|;")
                    .filter(
                      (momentType: string) => momentType !== item.key.toString()
                    );
                  if (item.selected === true) {
                    updatedMomentTypes.push(item.key.toString());
                  }
                  setParam({
                    ...param,
                    MomentType: encodeURIComponent(
                      updatedMomentTypes.join(";|;")
                    ),
                  });
                }
              }}
              required={false}
              styles={dropdownStyles}
              calloutProps={{ doNotLayer: true }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Filter;
