import httpClient from "./base";

const updatePMMDueDate = (dateInfo: string) => {
  var url = "/PMMDueDateInfo/ChangeDueDateInfo";
  return httpClient.post(url, dateInfo);
};

const getPMMDueDate = () => {
  var url = "/PMMDueDateInfo/GetDueDateInfo";
  return httpClient.get(url);
};

const pmmDueDateService = {
  updatePMMDueDate,
  getPMMDueDate,
};

export default pmmDueDateService;
