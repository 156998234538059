import {
  getNextDay,
  getWeekDayIndex,
  getWeekFirstDayNumber,
  getWeekLastDayNumber,
  getWeekSpanByDates,
} from "../../utils/date";
import { CalendarEvent } from "./Calendar/calendarEvent";

interface DataOfDay {
  displayTitle: string;
  weekIndex: number;
  dayIndex: number;
}

interface EventTd {
  positionIndex: number;
  span: number;
  eventId: number;
  eventTitle: string;
  startFromToday: boolean;
  eventColor: string;
  eventTextColor: string;
}

class MyDataOfDay implements DataOfDay {
  displayTitle: string;
  weekIndex: number;
  dayIndex: number;
  assignedEvents: CalendarEvent[] | undefined;
  eventsTds: EventTd[] | undefined;
  currentDate: Date;
  isFirstDayMonday: boolean;

  constructor(
    currentDate: Date,
    weekIndex: number,
    dayIndex: number,
    formatDisplayTitle?: (date: Date) => string,
    isFirstDayMonday: boolean = false
  ) {
    this.displayTitle = formatDisplayTitle
      ? formatDisplayTitle(currentDate)
      : `${currentDate.getDate()}`;
    this.weekIndex = weekIndex;
    this.dayIndex = dayIndex;
    this.currentDate = currentDate;
    this.isFirstDayMonday = isFirstDayMonday;
  }

  generateEventTds(maxEventNumberOfCurrentWeek: number) {
    const result = new Array<EventTd>(maxEventNumberOfCurrentWeek);
    if (this.assignedEvents && this.assignedEvents.length > 0) {
      const copy = this.assignedEvents.map((e) => {
        return { ...e };
      });

      const eventsNotStartOnCurrentDay = copy.filter((e) => {
        return e.originalIndex !== -1;
      });
      for (let i = 0; i < eventsNotStartOnCurrentDay.length; i++) {
        const eventNotStartOnCurrentDay = eventsNotStartOnCurrentDay[i];

        result[eventNotStartOnCurrentDay.originalIndex] = {} as EventTd;
        result[eventNotStartOnCurrentDay.originalIndex].positionIndex =
          eventNotStartOnCurrentDay.originalIndex;
        result[eventNotStartOnCurrentDay.originalIndex].span = 0;
        result[eventNotStartOnCurrentDay.originalIndex].eventId =
          eventNotStartOnCurrentDay.Id;
        result[eventNotStartOnCurrentDay.originalIndex].eventTitle =
          eventNotStartOnCurrentDay.title;
        result[eventNotStartOnCurrentDay.originalIndex].startFromToday = false;
        result[eventNotStartOnCurrentDay.originalIndex].eventColor =
          eventNotStartOnCurrentDay.color;
        result[eventNotStartOnCurrentDay.originalIndex].eventTextColor =
          eventNotStartOnCurrentDay.textColor;
      }

      const eventsFromPastWeek = copy.filter((e) => {
        return (
          e.startDate < this.currentDate &&
          getWeekDayIndex(this.currentDate.getDay(), this.isFirstDayMonday) ===
            0
        );
      });
      for (let j = 0; j < result.length; j++) {
        if (!result[j]) {
          const eventFromPastWeek = eventsFromPastWeek.pop();
          if (eventFromPastWeek) {
            result[j] = {} as EventTd;
            result[j].positionIndex = j;
            result[j].span = eventFromPastWeek.currentWeekSpan;
            result[j].eventId = eventFromPastWeek.Id;
            result[j].eventTitle = eventFromPastWeek.title;
            result[j].startFromToday = true;
            result[j].eventColor = eventFromPastWeek.color;
            result[j].eventTextColor = eventFromPastWeek.textColor;
            this.assignedEvents.filter((e) => {
              return e.Id === eventFromPastWeek.Id;
            })[0].originalIndex = j;
          }
        }
      }
      const normalEventsStartOnCurrentDay = copy.filter((e) => {
        return (
          isOnSameDay(e.startDate, this.currentDate) && e.originalIndex === -1
        );
      });
      for (let j = 0; j < result.length; j++) {
        if (!result[j]) {
          const normalEventStartOnCurrentDay =
            normalEventsStartOnCurrentDay.pop();
          if (normalEventStartOnCurrentDay) {
            result[j] = {} as EventTd;
            result[j].positionIndex = j;
            result[j].span = normalEventStartOnCurrentDay.currentWeekSpan;
            result[j].eventId = normalEventStartOnCurrentDay.Id;
            result[j].eventTitle = normalEventStartOnCurrentDay.title;
            result[j].startFromToday = true;
            result[j].eventColor = normalEventStartOnCurrentDay.color;
            result[j].eventTextColor = normalEventStartOnCurrentDay.textColor;
            this.assignedEvents.filter((e) => {
              return e.Id === normalEventStartOnCurrentDay.Id;
            })[0].originalIndex = j;
          }
        }
      }
    }

    return result;
  }
}

const createTdsForWeek = (
  data: MyDataOfDay[],
  registeredEvents: CalendarEvent[]
) => {
  const weekStartDate = data[0].currentDate;
  const weekEndDate = data[6].currentDate;
  const weekEvents = registeredEvents.filter((e) => {
    return (
      ((e.startDate > weekStartDate ||
        isOnSameDay(e.startDate, weekStartDate)) &&
        (e.startDate < weekEndDate || isOnSameDay(e.startDate, weekEndDate))) ||
      ((e.endDate > weekStartDate || isOnSameDay(e.endDate, weekStartDate)) &&
        (e.endDate < weekEndDate || isOnSameDay(e.endDate, weekEndDate))) ||
      (e.startDate < weekStartDate && e.endDate > weekEndDate)
    );
  });

  const copy = weekEvents;
  for (let i = 0; i < copy.length; i++) {
    const event = copy[i];
    event.totalDaySpan = calculateDaySpan(event.startDate, event.endDate);

    let start = weekStartDate;
    let end = weekEndDate;
    if (
      (event.startDate < weekEndDate ||
        isOnSameDay(event.startDate, weekEndDate)) &&
      (event.startDate > weekStartDate ||
        isOnSameDay(event.startDate, weekStartDate))
    ) {
      start = event.startDate;
    }
    if (
      (event.endDate < weekEndDate ||
        isOnSameDay(event.endDate, weekEndDate)) &&
      (event.endDate > weekStartDate ||
        isOnSameDay(event.endDate, weekStartDate))
    ) {
      end = event.endDate;
    }
    event.currentWeekSpan = calculateDaySpan(start, end);
  }

  for (let j = 0; j < data.length; j++) {
    const day = data[j];
    const eventsOfDay = copy.filter((e) => {
      return (
        (day.currentDate > e.startDate ||
          isOnSameDay(day.currentDate, e.startDate)) &&
        (day.currentDate < e.endDate || isOnSameDay(day.currentDate, e.endDate))
      );
    });
    day.assignedEvents = eventsOfDay;
    day.eventsTds = day.generateEventTds(copy.length);
  }
};

const calculateDaySpan = (startDate: Date, endDate: Date): number => {
  const oneDay = 24 * 60 * 60 * 1000;
  const start = new Date(
    startDate.getFullYear(),
    startDate.getMonth(),
    startDate.getDate()
  );
  const end = new Date(
    endDate.getFullYear(),
    endDate.getMonth(),
    endDate.getDate()
  );
  return Math.round(Math.abs(end.getTime() - start.getTime()) / oneDay) + 1;
};

function getArrayByDates(
  startDate: Date,
  endDate: Date,
  isFirstDayMonday: boolean = false
): MyDataOfDay[][] {
  const weekSpan = getWeekSpanByDates(startDate, endDate);
  const array = Array.from(new Array(weekSpan), () => new Array(7));

  let currentWeekIndex = 0;
  for (let day = startDate; day <= endDate; day = getNextDay(day)) {
    const currentDayNumber = day.getDay();
    const currentDayIndex = getWeekDayIndex(currentDayNumber, isFirstDayMonday);
    array[currentWeekIndex][currentDayIndex] = new MyDataOfDay(
      day,
      currentWeekIndex,
      currentDayIndex
    );
    if (currentDayNumber === getWeekLastDayNumber(isFirstDayMonday)) {
      currentWeekIndex++;
    }
  }

  // Add Beginning Blank Date
  if (startDate.getDay() !== getWeekFirstDayNumber(isFirstDayMonday)) {
    const beginningDayNumber = getNextDay(startDate, true).getDay();
    const beginningDayIndex = getWeekDayIndex(
      beginningDayNumber,
      isFirstDayMonday
    );
    let addedDateBegin = getNextDay(startDate, true);
    for (let index = beginningDayIndex; index >= 0; index--) {
      array[0][index] = new MyDataOfDay(addedDateBegin, 0, index);
      addedDateBegin = getNextDay(addedDateBegin, true);
    }
  }

  // Add Ending Blank Date
  if (endDate.getDay() !== getWeekLastDayNumber(isFirstDayMonday)) {
    const endingDayNumber = getNextDay(endDate).getDay();
    const endingDayIndex = getWeekDayIndex(endingDayNumber, isFirstDayMonday);
    let addedDateEnd = getNextDay(endDate);
    for (let index = endingDayIndex; index <= 6; index++) {
      array[array.length - 1][index] = new MyDataOfDay(
        addedDateEnd,
        array.length - 1,
        index
      );
      addedDateEnd = getNextDay(addedDateEnd);
    }
  }

  return array;
}

const isOnSameDay = (date1: Date, date2: Date): boolean => {
  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  );
};

const createCalendarEvent = (
  start: string,
  end: string,
  id: number,
  title: string,
  color: string,
  textColor: string,
  bicDateSelection: string
): CalendarEvent => {
  const startDate = new Date(start);
  const endDate = new Date(end);
  if (startDate <= endDate) {
    return {
      startDate: startDate,
      endDate: endDate,
      Id: id,
      totalDaySpan: 0,
      currentWeekSpan: 0,
      originalIndex: -1,
      title: title,
      color: color,
      textColor: textColor,
      bicDateSelection: bicDateSelection,
    };
  } else {
    return createCalendarEvent(
      "2023-06-02",
      "2023-06-02",
      0,
      "ERROR",
      "000000",
      "000000",
      "Specific Date"
    );
  }
};

export {
  getArrayByDates,
  MyDataOfDay,
  createTdsForWeek,
  createCalendarEvent,
  isOnSameDay,
};
