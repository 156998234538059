import {
  IBreadcrumbStyles,
  IButtonStyles,
  IDatePickerStrings,
  IDropdownStyles,
  IPivotStyles,
  IStyle,
  ITextFieldStyles,
} from "@fluentui/react";
import { CSSProperties } from "react";
import { GroupBase, StylesConfig } from "react-select";

export const buttonStyles: IButtonStyles = {
  root: { backgroundColor: "#0078d4", borderColor: "#0078d4", height: 32 },
};

export const fieldProps: IStyle = {
  font: "normal normal normal 13px/21px Segoe UI",
  textAlign: "left",
  letterSpacing: "0px",
  color: "#333333",
  opacity: 1,
};

export const labelStyles: CSSProperties = {
  font: "normal normal bold 14px/25px Segoe UI",
  textAlign: "left",
  letterSpacing: "0px",
  color: "#333333",
  opacity: 1,
  marginTop: "16px",
  marginBottom: "2px",
  padding: "0px",
};

export const labelPropsWithoutMargin: IStyle = {
  font: "normal normal bold 14px/25px Segoe UI",
  textAlign: "left",
  letterSpacing: "0px",
  color: "#333333",
  opacity: 1,
  marginTop: "0px",
  marginBottom: "0px",
  padding: "0px",
};
export const IconButtonStyte: IButtonStyles = {};
export const labelProps: IStyle = {
  font: "normal normal bold 14px/25px Segoe UI",
  textAlign: "left",
  letterSpacing: "0px",
  color: "#333333",
  opacity: 1,
  marginTop: "16px",
  marginBottom: "2px",
  padding: "0px",
};

export const titleProps: IStyle = {
  fontSize: "21px",
  fontFamily: "Segoe UI",
  fontWeight: "bold",
};

export const subtitleProps: IStyle = {
  fontSize: "18px",
  fontFamily: "Segoe UI",
};

export const pivotStylesProps: IPivotStyles = {
  root: {},
  link: {
    backgroundColor: "rgb(244,244,244)",
    height: "32px",
  },
  linkIsSelected: {},
  linkContent: {},
  linkInMenu: {},
  text: {
    verticalAlign: "baseline",
    lineHeight: "16px",
    fontSize: "14px",
  },
  count: {},
  icon: {},
  overflowMenuButton: {},
};

export const textfieldStylesProps: ITextFieldStyles = {
  wrapper: {
    selectors: {
      [".ms-Label"]: labelProps,
      [".ms-TextField-field"]: {
        height: "32px",
        lineHeight: "21px",
      },
    },
  },
  errorMessage: {},
  revealButton: {},
  revealIcon: {},
  revealSpan: {},
  description: {},
  prefix: {},
  suffix: {},
  icon: {},
  fieldGroup: {
    height: "32px",
  },
  root: {
    minWidth: "70%",
  },
  field: fieldProps,
  subComponentStyles: {
    label: {
      root: {
        ...labelProps,
      },
    },
  },
};

export const textareaStylesProps: ITextFieldStyles = {
  wrapper: {
    selectors: {
      [".ms-Label"]: labelProps,
    },
  },
  errorMessage: {},
  revealButton: {},
  revealIcon: {},
  revealSpan: {},
  description: {},
  prefix: {},
  suffix: {},
  icon: {},
  fieldGroup: {},
  root: {},
  field: fieldProps,
  subComponentStyles: {
    label: {
      root: {
        ...labelProps,
      },
    },
  },
};

export const singleLineTextareaStylesProps: ITextFieldStyles = {
  wrapper: {
    selectors: {
      [".ms-Label"]: labelProps,
    },
  },
  errorMessage: {},
  revealButton: {},
  revealIcon: {},
  revealSpan: {},
  description: {},
  prefix: {},
  suffix: {},
  icon: {},
  fieldGroup: { resize: "vertical", overflow: "hidden" },
  root: {},
  field: fieldProps,
  subComponentStyles: {
    label: {
      root: {
        ...labelProps,
      },
    },
  },
};

export const dropdownStylesProps: Partial<IDropdownStyles> = {
  root: {},
  label: {},
  dropdown: fieldProps,
  title: {
    height: "32px",
    lineHeight: "32px",
  },
  caretDownWrapper: {
    height: "32px",
    lineHeight: "32px",
  },
  caretDown: {},
  errorMessage: {},
  dropdownItem: {
    height: "auto",
    lineHeight: "auto",
    maxWidth: `100%`,
    overflow: "hidden",
    overflowWrap: "normal",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",

    "&:hover": {
      background: "#2684FF !important",
      color: "#FFFFFF !important",
      ".ms-Checkbox-checkbox": {
        borderColor: "white",
      },
      ".ms-Checkbox-checkmark": {
        color: "#FFFFFF",
      },
      overflow: "visible !important",
      overflowWrap: "break-word !important",
      whiteSpace: "normal !important",
      textOverflow: "inherit !important",
    },
  },
  dropdownItemSelected: {
    background: "#2684FF !important",
    color: "#FFFFFF !important",
    "&:hover": {
      background: "#2684FF !important",
      color: "#FFFFFF !important",
    },
    border: "solid",
    borderColor: "white",
    borderWidth: "thin",
    overflow: "visible",
    overflowWrap: "break-word !important",
    whiteSpace: "normal !important",
    textOverflow: "inherit !important",
  },
  dropdownItemSelectedAndDisabled: {},
  dropdownItemDisabled: {},
  dropdownDivider: {},
  dropdownItemHidden: {},
  dropdownOptionText: {},
  dropdownItemHeader: {},
  panel: {},
  callout: {},
  subComponentStyles: {
    label: {
      root: labelProps,
    },
    panel: {
      root: {},
    },
    multiSelectItem: {
      root: {},
    },
  },
};

export const dropdownStylePropsWithoutLabelMargin: Partial<IDropdownStyles> = {
  ...dropdownStylesProps,
  title: {
    height: "32px",
    lineHeight: "32px",
    border: "1px solid rgb(96, 94, 92)",
  },
  dropdown: {
    width: 231.25,
  },
  subComponentStyles: {
    label: {
      root: labelPropsWithoutMargin,
    },
    panel: {
      root: {},
    },
    multiSelectItem: {
      root: {},
    },
  },
};

export type OptionType = {
  value: string;
  label: string;
  group?: string;
};

export const reactselectStyleProps = {
  container: (provided: any, state: any) => ({
    ...provided,
    fontSize: "14px",
    fontWeight: 400,
  }),
  valueContainer: (provided: any, state: any) => ({
    ...provided,
    position: "static",
    height: "30px",
    lineHeight: "18px",
  }),
  singleValue: (provided: any, state: any) => ({
    ...provided,
    width: "100%",
    "@media(max-width: 1080px)": {
      width: "100%",
    },
  }),
  indicatorsContainer: (provided: any, state: any) => ({
    ...provided,
    height: "30px",
  }),
  indicatorSeparator: (provided: any, state: any) => ({
    ...provided,
    height: "16px",
  }),
  control: (provided: any, state: any) => ({
    ...provided,
    borderColor: state.isFocused ? "rgb(0, 120, 212)" : "rgb(96, 94, 92)",
    borderRadius: 0,
    borderWidth: "1px",
    boxShadow: "none",
    color: "rgb(50, 49, 48)",
    lineHeight: "18px",
    minHeight: "0px",
  }),
  menu: (provided: any, state: any) => ({
    ...provided,
    borderColor: "rgb(96, 94, 92)",
    borderRadius: "0px 0px 2px 2px",
    boxShadow:
      "rgba(0, 0, 0, 0.13) 0px 3.2px 7.2px 0px, rgba(0, 0, 0, 0.11) 0px 0.6px 1.8px 0px",
    color: "rgb(50, 49, 48)",
    margin: 0,
    zIndex: 1000,
  }),
  placeholder: (provided: any, state: any) => ({
    ...provided,
    color: "rgb(96, 94, 92)",
  }),
  clearIndicator: (provided: any, state: any) => ({
    ...provided,
    color: "rgb(0,0,0)",
  }),

  //  @media screen and(-ms - high - contrast: active), screen and(forced - colors: active)
  //.react-select__option--is-focused
  option: (provided: any, state: any) => ({
    ...provided,
    backgroundColor:
      state.isSelected || state.isFocused ? "#0078D4" : undefined,
    outline: "none",
    position: "relative",
    fontFamily:
      '"Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif',
    WebkitFontSmoothing: "antialiased",
    fontSize: "14px",
    fontWeight: "400",
    boxSizing: "border-box",
    border: "1px solid transparent",
    textDecoration: "none",
    textAlign: "left",
    cursor: "pointer",
    padding: "0px 8px",
    borderRadius: "0px",
    color: state.isFocused || state.isSelected ? "#FFFFFF" : "#333333",
    alignItems: "center",
    width: "100%",
    minHeight: "36px",
    lineHeight: "32px",
    overflow: state.isFocused || state.isSelected ? "visible" : "hidden",
    overflowWrap: state.isFocused || state.isSelected ? "break-word" : "normal",
    whiteSpace: state.isFocused || state.isSelected ? "normal" : "nowrap",
    textOverflow: state.isFocused || state.isSelected ? "inherit" : "ellipsis",
    maxWidth: "100%",
    userSelect: "none",
  }),
  noOptionsMessage: (provided: any, state: any) => ({
    ...provided,
    color: "#6E6E6E",
  }),
  dropdownIndicator: (provided: any, state: any) => ({
    ...provided,
    color: "#959494",
  }),
};

export const reactselectSingleStyleProps: StylesConfig<OptionType, false> = {
  container: (provided: any, state: any) => ({
    ...provided,
    fontSize: "14px",
    fontWeight: 400,
  }),
  valueContainer: (provided: any, state: any) => ({
    ...provided,
    position: "static",
    height: "30px",
    lineHeight: "21px",
  }),
  singleValue: (provided: any, state: any) => ({
    ...provided,
    width: "100%",
  }),
  indicatorsContainer: (provided: any, state: any) => ({
    ...provided,
    height: "30px",
  }),
  indicatorSeparator: (provided: any, state: any) => ({
    ...provided,
    height: "16px",
  }),
  control: (provided: any, state: any) => ({
    ...provided,
    borderColor: state.isFocused ? "rgb(0, 120, 212)" : "rgb(96, 94, 92)",
    borderRadius: 0,
    borderWidth: state.isFocused ? "2px" : "1px",
    boxShadow: "none",
    color: "rgb(50, 49, 48)",
    lineHeight: "21px",
    minHeight: "0px",
  }),
  menu: (provided: any, state: any) => ({
    ...provided,
    borderColor: "rgb(96, 94, 92)",
    borderRadius: "0px 0px 2px 2px",
    boxShadow:
      "rgba(0, 0, 0, 0.13) 0px 3.2px 7.2px 0px, rgba(0, 0, 0, 0.11) 0px 0.6px 1.8px 0px",
    color: "rgb(50, 49, 48)",
    margin: 0,
    zIndex: 1000,
  }),
  placeholder: (provided: any, state: any) => ({
    ...provided,
    color: "rgb(96, 94, 92)",
  }),
  clearIndicator: (provided: any, state: any) => ({
    ...provided,
    color: "rgb(0,0,0)",
  }),

  //  @media screen and(-ms - high - contrast: active), screen and(forced - colors: active)
  //.react-select__option--is-focused
  option: (styles: any, { isFocused, isSelected }) => ({
    ...styles,
    backgroundColor: isSelected ? "#2684FF" : isFocused ? "#2684FF" : undefined,
    outline: "none",
    position: "relative",
    fontFamily:
      '"Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif',
    WebkitFontSmoothing: "antialiased",
    fontSize: "14px",
    fontWeight: "400",
    boxSizing: "border-box",
    border: "1px solid transparent",
    display: "flex",
    textDecoration: "none",
    textAlign: "left",
    cursor: "pointer",
    padding: "0px 8px",
    borderRadius: "0px",
    height: "32px",
    color:
      isFocused && !isSelected ? "#FFFFFF" : isSelected ? "#FFFFFF" : "#333333",
    alignItems: "center",
    width: "100%",
    minHeight: "36px",
    lineHeight: "32px",
    overflowWrap: "break-word",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    maxWidth: "100%",
    userSelect: "none",
  }),
  noOptionsMessage: (provided: any, state: any) => ({
    ...provided,
    color: "#6E6D6D",
  }),
  dropdownIndicator: (provided: any, state: any) => ({
    ...provided,
    color: "#959494",
  }),
};

export const reactselectSingleErrorStyleProps: StylesConfig<OptionType, false> =
  {
    container: (provided: any, state: any) => ({
      ...provided,
      fontSize: "14px",
      fontWeight: 400,
    }),
    valueContainer: (provided: any, state: any) => ({
      ...provided,
      position: "static",
      height: "30px",
      lineHeight: "21px",
    }),
    singleValue: (provided: any, state: any) => ({
      ...provided,
      width: "100%",
    }),
    indicatorsContainer: (provided: any, state: any) => ({
      ...provided,
      height: "30px",
    }),
    indicatorSeparator: (provided: any, state: any) => ({
      ...provided,
      height: "16px",
    }),
    control: (provided: any, state: any) => ({
      ...provided,
      borderRadius: 0,
      borderWidth: state.isFocused ? "2px" : "1px",
      boxShadow: "none",
      color: "rgb(50, 49, 48)",
      lineHeight: "21px",
      minHeight: "0px",
      borderColor: "rgb(164, 38, 44)",
    }),
    menu: (provided: any, state: any) => ({
      ...provided,
      borderColor: "rgb(96, 94, 92)",
      borderRadius: "0px 0px 2px 2px",
      boxShadow:
        "rgba(0, 0, 0, 0.13) 0px 3.2px 7.2px 0px, rgba(0, 0, 0, 0.11) 0px 0.6px 1.8px 0px",
      color: "rgb(50, 49, 48)",
      margin: 0,
      zIndex: 1000,
    }),
    placeholder: (provided: any, state: any) => ({
      ...provided,
      color: "rgb(96, 94, 92)",
    }),
    clearIndicator: (provided: any, state: any) => ({
      ...provided,
      color: "rgb(0,0,0)",
    }),

    //  @media screen and(-ms - high - contrast: active), screen and(forced - colors: active)
    //.react-select__option--is-focused
    option: (styles: any, { isFocused, isSelected }) => ({
      ...styles,
      backgroundColor: isSelected
        ? "#2684FF"
        : isFocused
        ? "#2684FF"
        : undefined,
      outline: "none",
      position: "relative",
      fontFamily:
        '"Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif',
      WebkitFontSmoothing: "antialiased",
      fontSize: "14px",
      fontWeight: "400",
      boxSizing: "border-box",
      border: "1px solid transparent",
      display: "flex",
      textDecoration: "none",
      textAlign: "left",
      cursor: "pointer",
      padding: "0px 8px",
      borderRadius: "0px",
      height: "32px",
      color:
        isFocused && !isSelected
          ? "#FFFFFF"
          : isSelected
          ? "#FFFFFF"
          : "#333333",
      alignItems: "center",
      width: "100%",
      minHeight: "36px",
      lineHeight: "32px",
      overflowWrap: "break-word",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      maxWidth: "100%",
      userSelect: "none",
    }),
    noOptionsMessage: (provided: any, state: any) => ({
      ...provided,
      color: "#6E6D6D",
    }),
    dropdownIndicator: (provided: any, state: any) => ({
      ...provided,
      color: "#959494",
    }),
  };

export const reactselectMultiStyleProps: StylesConfig<OptionType, true> = {
  container: (provided: any, state: any) => ({
    ...provided,
    fontSize: "14px",
    fontWeight: 400,
  }),
  valueContainer: (provided: any, state: any) => ({
    ...provided,
    lineHeight: 1,
    height: "auto",
  }),
  singleValue: (provided: any, state: any) => ({
    ...provided,
    width: "100%",
    "@media(max-width: 1080px)": {
      width: "100%",
    },
  }),
  indicatorsContainer: (provided: any, state: any) => ({
    ...provided,
    height: "30px",
  }),
  indicatorSeparator: (provided: any, state: any) => ({
    ...provided,
    height: "16px",
  }),
  control: (provided: any, state: any) => ({
    ...provided,
    borderColor: state.isFocused ? "rgb(0, 120, 212)" : "rgb(96, 94, 92)",
    borderRadius: 0,
    borderWidth: state.isFocused ? "2px" : "1px",
    boxShadow: "none",
    color: "rgb(50, 49, 48)",
    lineHeight: "21px",
    minHeight: "0px",
  }),
  menu: (provided: any, state: any) => ({
    ...provided,
    borderColor: "rgb(96, 94, 92)",
    borderRadius: "0px 0px 2px 2px",
    boxShadow:
      "rgba(0, 0, 0, 0.13) 0px 3.2px 7.2px 0px, rgba(0, 0, 0, 0.11) 0px 0.6px 1.8px 0px",
    color: "rgb(50, 49, 48)",
    margin: 0,
    zIndex: 1000,
  }),
  placeholder: (provided: any, state: any) => ({
    ...provided,
    color: "rgb(96, 94, 92)",
  }),
  clearIndicator: (provided: any, state: any) => ({
    ...provided,
    color: "rgb(0,0,0)",
  }),

  //  @media screen and(-ms - high - contrast: active), screen and(forced - colors: active)
  //.react-select__option--is-focused
  option: (styles: any, state: any) => ({
    ...styles,
    backgroundColor: state.isSelected
      ? "#2684FF"
      : state.isFocused
      ? "#DEEBFF"
      : undefined,
    outline: "none",
    position: "relative",
    fontFamily:
      '"Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif',
    WebkitFontSmoothing: "antialiased",
    fontSize: "14px",
    fontWeight: "400",
    boxSizing: "border-box",
    border: "1px solid transparent",
    display: "flex",
    textDecoration: "none",
    textAlign: "left",
    cursor: "pointer",
    padding: "0px 8px",
    borderRadius: "0px",
    height: "32px",
    alignItems: "center",
    width: "100%",
    minHeight: "36px",
    lineHeight: "32px",
    overflowWrap: "break-word",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    maxWidth: "100%",
    userSelect: "none",
  }),
  noOptionsMessage: (provided: any, state: any) => ({
    ...provided,
    color: "#6E6D6D",
  }),
  dropdownIndicator: (provided: any, state: any) => ({
    ...provided,
    color: "#959494",
  }),
};

export const reactselectSearchableStyleProps: StylesConfig<
  OptionType,
  boolean,
  GroupBase<OptionType>
> = {
  container: (provided: any, state: any) => ({
    ...provided,
    fontSize: "14px",
    fontWeight: 400,
  }),
  valueContainer: (provided: any, state: any) => ({
    ...provided,
    position: "static",
    height: "30px",
    lineHeight: "21px",
  }),
  indicatorsContainer: (provided: any, state: any) => ({
    ...provided,
    height: "30px",
  }),
  indicatorSeparator: (provided: any, state: any) => ({
    ...provided,
    height: "16px",
  }),
  control: (provided: any, state: any) => ({
    ...provided,
    borderRadius: 0,
    borderWidth: state.isFocused ? "2px" : "1px",
    boxShadow: "none",
    color: "rgb(50, 49, 48)",
    height: "36px",
    lineHeight: "21px",
    minHeight: "36px",
    borderColor: state.isFocused ? "rgb(0, 120, 212)" : "rgb(96, 94, 92)",
  }),
  menu: (provided: any, state: any) => ({
    ...provided,
    borderColor: "rgb(96, 94, 92)",
    borderRadius: "0px 0px 2px 2px",
    boxShadow:
      "rgba(0, 0, 0, 0.13) 0px 3.2px 7.2px 0px, rgba(0, 0, 0, 0.11) 0px 0.6px 1.8px 0px",
    color: "rgb(50, 49, 48)",
    margin: 0,
    zIndex: 1000,
  }),
  placeholder: (provided: any, state: any) => ({
    ...provided,
    color: "rgb(96, 94, 92)",
  }),
};

export const reactselectSearchableErrorStyleProps: StylesConfig<
  OptionType,
  boolean,
  GroupBase<OptionType>
> = {
  container: (provided: any, state: any) => ({
    ...provided,
    fontSize: "14px",
    fontWeight: 400,
  }),
  valueContainer: (provided: any, state: any) => ({
    ...provided,
    position: "static",
    height: "30px",
    lineHeight: "21px",
  }),
  indicatorsContainer: (provided: any, state: any) => ({
    ...provided,
    height: "30px",
  }),
  indicatorSeparator: (provided: any, state: any) => ({
    ...provided,
    height: "16px",
  }),
  control: (provided: any, state: any) => ({
    ...provided,
    borderRadius: 0,
    borderWidth: state.isFocused ? "2px" : "1px",
    boxShadow: "none",
    color: "rgb(50, 49, 48)",
    height: "36px",
    lineHeight: "21px",
    minHeight: "36px",
    borderColor: "rgb(164, 38, 44)",
  }),
  menu: (provided: any, state: any) => ({
    ...provided,
    borderColor: "rgb(96, 94, 92)",
    borderRadius: "0px 0px 2px 2px",
    boxShadow:
      "rgba(0, 0, 0, 0.13) 0px 3.2px 7.2px 0px, rgba(0, 0, 0, 0.11) 0px 0.6px 1.8px 0px",
    color: "rgb(50, 49, 48)",
    margin: 0,
    zIndex: 1000,
  }),
  placeholder: (provided: any, state: any) => ({
    ...provided,
    color: "rgb(96, 94, 92)",
  }),
};

export const datepickerStrings: IDatePickerStrings = {
  months: [],

  shortMonths: [],
  days: [],
  shortDays: [],

  goToToday: "",
  prevMonthAriaLabel: "",
  nextMonthAriaLabel: "",
  prevYearAriaLabel: "",
  nextYearAriaLabel: "",
  closeButtonAriaLabel: "",
  monthPickerHeaderAriaLabel: "",
  yearPickerHeaderAriaLabel: "",
  isRequiredErrorMessage: "Valid start date is required",
};

export const breadcrumbStyles: Partial<IBreadcrumbStyles> = {
  root: {
    color: `#000`,
    marginTop: 0,
    padding: "9px 0px 3px 22px",
  },
  itemLink: {
    fontSize: "13px",
    color: `#000`,
    textDecoration: "underline",
    selectors: {
      ":hover": {
        backgroundColor: `none`,
        color: `#000`,
        textDecoration: "underline",
      },
      ":focus": {
        backgroundColor: `none`,
        color: `#000`,
        textDecoration: "underline",
      },
      ":hover:focus": {
        backgroundColor: `none`,
        color: `#000`,
        textDecoration: "underline",
      },
    },
  },
};
