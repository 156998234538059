import { useContext } from "react";
import "../index.scss";
import { ContentShowMode } from "../../../Shared/constants";
import { ModernWorkFeatureModalContext } from "../../index";
import { modernWorkTimelineFeature } from "../../../../slice/modernWorkTimeline";
import FeatureCard from "./featureCard";
import FeatureRow from "./featureRow";

export const Feature = (productFeatures: {
  features: modernWorkTimelineFeature[];
}) => {
  const modernWorkFeatureModalContext = useContext(
    ModernWorkFeatureModalContext
  );
  const productMilestoneDom = productFeatures.features.map((item) => {
    return modernWorkFeatureModalContext.contentShowMode ===
      ContentShowMode.Grid ? (
      <FeatureCard feature={item} />
    ) : (
      <FeatureRow feature={item} />
    );
  });

  return (
    <div className="d-flex flex-wrap">
      <ul className="modernWorkTimeline-noStyleList">{productMilestoneDom}</ul>
    </div>
  );
};
