import httpClient from "./base";

const getFieldBlockeReason = () => {
  var url = "/FieldBlocker";
  return httpClient.get(url);
};

const fieldBlockeReasonService = {
  getFieldBlockeReason,
};

export default fieldBlockeReasonService;
