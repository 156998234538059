import React, { useState, useEffect, useContext } from "react";
import "../../../Shared/css/timelineGrid.scss";
import { ContentShowMode } from "../../../Shared/constants";
import "../index.scss";
import { MilestoneModalContext } from "../..";
import { Milestone } from "./milestone";
import { azureTimelineMilestone as milestoneProps } from "../../../../slice/azureTimeline";
import { OpenAllMilestoneContext } from "./productMilestone";

export const SingleProductMilestone = (milestones: {
  productMilestones: {
    productName: string;
    conversationsId: string;
    conversationsName: string;
    milestone: milestoneProps[];
  };
}) => {
  const milestoneModalContext = useContext(MilestoneModalContext);
  const [openProductMilestone, setOpenProductMilestone] = useState(true);
  const openAllMilestone = React.useContext(OpenAllMilestoneContext);
  useEffect(
    () => setOpenProductMilestone(openAllMilestone.isOpen),
    [openAllMilestone.isOpen]
  );

  const isOpenA = openAllMilestone.isOpen;
  const isOpenS = openProductMilestone;

  let isHidden = openAllMilestone.isClick ? isOpenA : isOpenS;

  return (
    <>
      <li style={{ listStyle: "none" }}>
        <div
          className={
            milestoneModalContext.contentShowMode === ContentShowMode.Grid
              ? "d-flex product-title"
              : "d-flex justify-content-end flex-row-reverse product-title"
          }
          style={{
            height:
              milestoneModalContext.contentShowMode === ContentShowMode.Grid
                ? "27px"
                : "42px",
          }}
          onClick={(e: React.MouseEvent<HTMLElement>) => {
            e.preventDefault();
            openAllMilestone.setClick(() => false);
            setOpenProductMilestone(() => !openProductMilestone);
          }}
          onKeyDown={(e) => {
            if (e.keyCode === 13) {
              e.currentTarget.click();
            }
          }}
          tabIndex={0}
          role="button"
          aria-expanded={openProductMilestone ? "true" : "false"}
        >
          <div
            className="product-title-text"
            title={milestones.productMilestones.productName}
            role="button"
            aria-label={milestones.productMilestones.productName}
          >
            {milestoneModalContext.contentShowMode === ContentShowMode.Grid &&
            milestones.productMilestones.productName.length > 50
              ? milestones.productMilestones.productName.substring(0, 47) +
                "..."
              : milestones.productMilestones.productName}
          </div>
          <div className="collapse-icon">
            <i
              className={`${
                isHidden
                  ? "fa fa-angle-down  fa-2x"
                  : "fa fa-angle-right  fa-2x"
              }`}
              style={{ lineHeight: 0 }}
              aria-label={isHidden ? "Expand" : "Collapse"}
              role="button"
              onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>) => {
                if (event.keyCode === 13) {
                  openAllMilestone.setClick(() => false);
                  setOpenProductMilestone(() => !openProductMilestone);
                }
                if (event.keyCode === 9) {
                  if (isOpenA || isOpenS) {
                    let nextProduct =
                      event.currentTarget?.parentNode?.parentNode?.nextSibling
                        ?.nextSibling;
                    if (nextProduct !== null) {
                      if (
                        (nextProduct as HTMLElement).className.indexOf(
                          "product-title-text"
                        ) > -1
                      ) {
                        (
                          (nextProduct as HTMLElement).getElementsByClassName(
                            ".product-title-text"
                          )[0] as HTMLElement
                        ).focus();
                      }
                    }
                  }
                }
              }}
            ></i>
          </div>
        </div>
        <div className={`${isHidden ? "" : "d-none"}`}>
          {milestoneModalContext.contentShowMode === ContentShowMode.Column ? (
            <div className="content-row-title d-flex">
              <div></div>
              <div>
                <strong>CSA</strong>
              </div>
              <div>
                <strong>Milestone ID</strong>
              </div>
              <div>
                <strong>Milestone name</strong>
              </div>
              <div>
                <strong>Benefit description</strong>
              </div>
              <div>
                <strong>Train date</strong>
              </div>
              <div>
                <strong>Public disclosure date</strong>
              </div>
              <div>
                <strong>Tier</strong>
              </div>
              <div>
                <strong>Milestone type</strong>
              </div>
              <div>
                <strong>PMM</strong>
              </div>
              <div>
                <strong>Marketing lead</strong>
              </div>
              <div>
                <strong>Aligned moment</strong>
              </div>
              <div>
                <strong>Edit</strong>
              </div>
            </div>
          ) : null}
          <Milestone
            CSA={milestones.productMilestones.conversationsName}
            milestones={milestones.productMilestones.milestone}
          />
        </div>
      </li>
    </>
  );
};
