import React from "react";
import {
  Calendar,
  ICalendarDayProps,
  defaultCalendarStrings,
  DateRangeType,
  ICalendarMonthProps,
  IRawStyle,
} from "@fluentui/react";
import moment from "moment";
import { DateLookup } from "../../slice/calendar";
import "./css/timelineFilter.scss";
import { TimelineType } from "./constants";
import { isFirstTrainOfMonth } from "../../utils/date";
const CustomeDateCalendar = (props: {
  isDayPickerVisible?: boolean;
  minDate?: Date;
  considerFirstTrainOfMonth: boolean;
  clickHandel: (value: Date) => void;
  isShowCalendar: boolean;
  hideCalendar: () => void;
  customTrainDates: string[];
  moments: DateLookup[];
  microsoftHoliday: DateLookup[];
  azureUpdateDeckDueDates: string[];
  calendarDates: DateLookup[];
  value?: Date;
  timelineType?: TimelineType;
}) => {
  const toDay = moment();
  const calendarDayProps: Partial<ICalendarDayProps> = {
    customDayCellRef: (element, date, classNames) => {
      let title = "";
      if (element) {
        let flageTitle = "";
        props.moments.forEach((item, index) => {
          if (item.Date && item.Date === moment(date).format("MM/DD/YYYY")) {
            title += "\r\n" + item.Name;
            flageTitle += "Event";
          }
        });
        props.microsoftHoliday.forEach((item, index) => {
          if (item.Date && item.Date === moment(date).format("MM/DD/YYYY")) {
            title += "\r\n" + item.Name;
            flageTitle += "Holiday";
          }
        });
        if (
          props.timelineType === undefined ||
          (props.timelineType && props.timelineType === TimelineType.Azure)
        ) {
          props.customTrainDates.forEach((item, index) => {
            if (item && item === moment(date).format("MM/DD/YYYY")) {
              title += "\r\n" + "Train date";
              flageTitle += "Train";
            }
          });
          props.calendarDates.forEach((item, index) => {
            if (item.Date && item.Date === moment(date).format("MM/DD/YYYY")) {
              title += "\r\n" + item.Name;
              flageTitle += "Calendar";
            }
          });
          props.azureUpdateDeckDueDates.forEach((item, index) => {
            if (item && item === moment(date).format("MM/DD/YYYY")) {
              title += "\r\n" + "Azure updates deck publication date";
              flageTitle += "AzureUpdateDeck";
            }
          });
        }

        if (
          flageTitle.indexOf("Event") !== -1 ||
          flageTitle.indexOf("Train") !== -1
        ) {
          element.style.fontWeight = "bold";
          (element.children[0] as HTMLButtonElement).style.color = "#966464";
        } else if (
          flageTitle.indexOf("Holiday") !== -1 ||
          flageTitle.indexOf("AzureUpdateDeck") !== -1
        ) {
          element.style.fontWeight = "bold";
          (element.children[0] as HTMLButtonElement).style.color = "#888";
        }
        element.title = title;

        if (
          props.considerFirstTrainOfMonth &&
          !isFirstTrainOfMonth(date, props.customTrainDates)
        ) {
          classNames.dayOutsideBounds &&
            element.classList.add(classNames.dayOutsideBounds);
          (element.children[0] as HTMLButtonElement).disabled = true;
        }
        const momentDate = moment(date.toISOString());
        if (
          momentDate.dayOfYear() === toDay.dayOfYear() &&
          momentDate.year() === toDay.year()
        ) {
          element.setAttribute(
            "aria-label",
            `${momentDate.format("D, MMMM, yyyy")}, Today`
          );
        }
        element.classList.add("customDatePickerOnHighContrast");
      }
    },
  };
  const hoverStyle: IRawStyle = {
    background: "#0078D4",
    color: "white",
  };
  const calendarMonthProps: Partial<ICalendarMonthProps> = {
    styles: {
      selected: { background: "#0078D4", color: "white" },
      itemButton: {
        "&:hover": hoverStyle,
      },
    },
    className: "customMonthPicker",
  };
  return props.isShowCalendar && props.isShowCalendar === true ? (
    <Calendar
      minDate={props.minDate}
      showMonthPickerAsOverlay={true}
      highlightSelectedMonth={true}
      onSelectDate={(date, dateList) => {
        props.clickHandel(date);
        if (props.hideCalendar) {
          props.hideCalendar();
        }
      }}
      dateRangeType={
        props.isDayPickerVisible ? DateRangeType.Day : DateRangeType.Month
      }
      isMonthPickerVisible={true}
      highlightCurrentMonth={true}
      isDayPickerVisible={props.isDayPickerVisible}
      calendarDayProps={calendarDayProps}
      calendarMonthProps={calendarMonthProps}
      showGoToToday={true}
      strings={defaultCalendarStrings}
      value={props.value}
      showCloseButton={true}
      onDismiss={() => {
        props.hideCalendar();
      }}
    />
  ) : null;
};

export default CustomeDateCalendar;
