import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { _LOADINGSTATE } from "../components/Shared/constants";
import azureupdateService from "../services/azureupdate";
import { ErrorMessage } from "../components/Shared/messageBox";

export interface AzureUpdate {
  AzureUpdateId: number;
  Title: string;
  ProposedPublicationDate?: Date;
  TargetedAvailabilityDate?: Date;
  MilestoneId: number;
  AzureUpdateStatusId?: number;
  EditorialActionId?: number;
  Description: string;
  Excerpt: string;
  ModifiedDate?: Date;
  ModifiedBy: string;
  EngineeringOwner: string;
  Tags: string;
  AllowEditPastDueDate: boolean;
  CTAData: string;
  IsCtaChanged: boolean;
  StatusNextSteps: string;
  IsStatusNextStepsChanged: boolean;
  AzureUpdateModifiedDate?: Date;
  AzureUpdateModifiedBy: string;
  SelectedAzureUpdateRelatedProducts: number[] | null;
  SelectedAzureUpdateCategories: number[] | null;
  IsContentDueDatePassed: boolean;
  IsAzureUpdateCommentChanged: boolean;
  IsCategoriesChanged: boolean;
  IsRelatedProductChanged: boolean;
  CanEditAzureUpdateAtAnyTime: boolean;
  HasCommentHistory: boolean;
  AzureUpdatesProductId: number;
  AzureUpdatesMilestoneId: number;
  AzureUpdatesContainerId?: number;
  CTAs: CTA[];
  AzureUpdateRelatedCategories: AzureUpdateRelatedCategory[];
  AzureUpdateRelatedProducts: AzureUpdateRelatedProduct[];
  AzureUpdateStatusNextSteps: AzureUpdateStatusNextStep[];
  AzureUpdateCommentHistory: AzureUpdateCommentHistory[];
  __isFormValid: boolean;
  HttpPostFile: File | null;
  AzureUpdateImageVersion: AzureUpdateImageVersion | null;
  __isLoading: string;
  azureupdateRelatedCategoriesOptions: LookupOption[];
  azureupdateEditorialActionsOptions: LookupOption[];
  azureupdateStatusOptions: LookupOption[];
}

export interface LookupOption {
  key: string | number;
  text: string;
}

export interface AzureUpdateImageVersion {
  UploadedBy: string;
  UploadedDate: string;
  Filename: string;
  LinkedFilename: string;
}
export interface AzureUpdateRelatedProduct {
  AzureUpdateId: number;
  ProductId: number;
}

export interface EditorialAction {
  EditorialActionId: number;
  Name: string;
}

export interface AzureUpdateStatus {
  AzureUpdateStatusId: number;
  Name: string;
}

export interface CTA {
  CTAId: number;
  PublicRoadmapId?: number;
  AzureUpdateId?: number;
  Name: string;
  Url: string;
}

export interface AzureUpdateRelatedCategory {
  //
  AzureUpdateId: number;
  AzureUpdateCategoryId: number;
}

export interface AzureUpdateStatusNextStep {
  Id: number;
  AzureUpdateId: number;
  Date: string;
  Notes: string;
  ToDo: string;
  Initial: string;
  BrandingNamingId?: number | null;
  BrandingCouncilId?: number | null;
}

interface AzureUpdateCommentHistory {
  AzureUPdateCommentHistoryId: number;
  AzureUpdateId: number;
  Comment: string;
  ModifiedDate?: Date;
  ModifiedBy: string;
  Revision: number;
}

const initialState: AzureUpdate = {
  AzureUpdateId: 0,
  Title: "",
  ProposedPublicationDate: undefined,
  TargetedAvailabilityDate: undefined,
  MilestoneId: 0,
  AzureUpdateStatusId: 0,
  EditorialActionId: 0,
  Description: "",
  Excerpt: "",
  ModifiedDate: undefined,
  ModifiedBy: "",
  EngineeringOwner: "",
  Tags: "",
  AllowEditPastDueDate: false,
  CTAData: "",
  IsCtaChanged: false,
  StatusNextSteps: "",
  IsStatusNextStepsChanged: false,
  AzureUpdateModifiedDate: undefined,
  AzureUpdateModifiedBy: "",
  SelectedAzureUpdateRelatedProducts: [],
  SelectedAzureUpdateCategories: [],
  IsContentDueDatePassed: false,
  IsAzureUpdateCommentChanged: false,
  IsCategoriesChanged: false,
  IsRelatedProductChanged: false,
  CanEditAzureUpdateAtAnyTime: false,
  HasCommentHistory: false,
  AzureUpdatesProductId: 0,
  AzureUpdatesMilestoneId: 0,
  AzureUpdatesContainerId: 0,
  CTAs: [],
  AzureUpdateRelatedCategories: [],
  AzureUpdateRelatedProducts: [],
  AzureUpdateStatusNextSteps: [],
  AzureUpdateCommentHistory: [],
  __isFormValid: false,
  HttpPostFile: null,
  AzureUpdateImageVersion: null,
  __isLoading: "",
  azureupdateRelatedCategoriesOptions: [],
  azureupdateEditorialActionsOptions: [],
  azureupdateStatusOptions: [],
};

interface EditorialActionOption {
  EditorialActionId: number;
  Name: string;
}

interface ProductOption {
  ProductId: number;
  ProductName: string;
}

interface CategoryOption {
  AzureUpdateCategoryId: number;
  Name: string;
}

interface StatusOption {
  AzureUpdateStatusId: number;
  Name: string;
}

let timer: number;
export const debounceChange = (action: any, delay: number) => {
  return (dispatch: any) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      dispatch(action);
    }, delay) as unknown as number;
  };
};

export const getAzureUpdateRelatedCategoriesOptions = createAsyncThunk(
  "getAzureUpdateRelatedCategoriesOptions",
  async () => {
    return (await azureupdateService.getAzureUpdateRelatedCategoriesOptions()) as CategoryOption[];
  }
);

export const getAzureUpdateRelatedProductsOptions = createAsyncThunk(
  "getAzureUpdateRelatedProductsOptions",
  async () => {
    return (await azureupdateService.getAzureUpdateRelatedProductsOptions()) as ProductOption[];
  }
);

export const getAzureUpdateEditorialActionsOptions = createAsyncThunk(
  "getAzureUpdateEditorialActionsOptions",
  async () => {
    return (await azureupdateService.getAzureUpdateEditorialActionsOptions()) as EditorialActionOption[];
  }
);

export const getAzureUpdateStatusOptions = createAsyncThunk(
  "getAzureUpdateStatusOptions",
  async () => {
    return (await azureupdateService.getAzureUpdateStatusOptions()) as StatusOption[];
  }
);

export const getAzureUpdate = createAsyncThunk(
  "getAzureUpdate",
  async (milestoneId: number, { dispatch, getState }) => {
    return (await azureupdateService.getAzureUpdate(
      milestoneId
    )) as AzureUpdate;
  }
);

export const getAzureUpdateImageVersion = createAsyncThunk(
  "getAzureUpdateImageVersion",
  async (AzureUpdateId: number) => {
    return (await azureupdateService.getAzureUpdateImageVersion(
      AzureUpdateId
    )) as AzureUpdateImageVersion;
  }
);

export const putAzureUpdate = createAsyncThunk(
  "putAzureUpdate",
  async (params: { AzureUpdateId: number; AzureUpdate: AzureUpdate }) => {
    return (await azureupdateService.putAzureUpdate(
      params.AzureUpdateId,
      params.AzureUpdate
    )) as any;
  }
);

export const postAzureUpdate = createAsyncThunk(
  "postAzureUpdate",
  async (AzureUpdate: AzureUpdate) => {
    return await azureupdateService.postAzureUpdate(AzureUpdate);
  }
);

export const postAzureUpdateImage = createAsyncThunk(
  "postAzureUpdateImage",
  async (params: { id: number; milestoneId: number; file: File }) => {
    return await azureupdateService.postUploadImage(
      params.id,
      params.milestoneId,
      params.file
    );
  }
);

const azureupdateSlice = createSlice({
  name: "azureupdate",
  initialState: initialState,
  reducers: {
    reset: (state: AzureUpdate) => {
      state.AzureUpdateId = 0;
      state.Title = "";
      state.ProposedPublicationDate = undefined;
      state.TargetedAvailabilityDate = undefined;
      state.MilestoneId = 0;
      state.AzureUpdateStatusId = 0;
      state.EditorialActionId = 0;
      state.Description = "";
      state.Excerpt = "";
      state.ModifiedDate = undefined;
      state.ModifiedBy = "";
      state.EngineeringOwner = "";
      state.Tags = "";
      state.AllowEditPastDueDate = false;
      state.CTAData = "";
      state.IsCtaChanged = false;
      state.StatusNextSteps = "";
      state.IsStatusNextStepsChanged = false;
      state.AzureUpdateModifiedDate = undefined;
      state.AzureUpdateModifiedBy = "";
      state.SelectedAzureUpdateRelatedProducts = [];
      state.SelectedAzureUpdateCategories = [];
      state.IsContentDueDatePassed = false;
      state.IsAzureUpdateCommentChanged = false;
      state.IsCategoriesChanged = false;
      state.IsRelatedProductChanged = false;
      state.CanEditAzureUpdateAtAnyTime = false;
      state.HasCommentHistory = false;
      state.AzureUpdatesProductId = 0;
      state.AzureUpdatesMilestoneId = 0;
      state.AzureUpdatesContainerId = 0;
      state.CTAs = [];
      state.AzureUpdateRelatedCategories = [];
      state.AzureUpdateRelatedProducts = [];
      state.AzureUpdateStatusNextSteps = [];
      state.AzureUpdateCommentHistory = [];
      state.__isFormValid = false;
      state.HttpPostFile = null;
      state.AzureUpdateImageVersion = null;
      state.__isLoading = "";
      state.azureupdateRelatedCategoriesOptions = [];
      state.azureupdateEditorialActionsOptions = [];
      state.azureupdateStatusOptions = [];
    },
    changeIds: (state: AzureUpdate, action: { payload: any; type: string }) => {
      state.AzureUpdateId = action.payload.id;
      state.MilestoneId = action.payload.milestoneId;
    },
    changeDescription: (
      state: AzureUpdate,
      action: { payload: string; type: string }
    ) => {
      state.Description = action.payload;
      state.IsAzureUpdateCommentChanged = true;
    },
    changeExcerpt: (
      state: AzureUpdate,
      action: { payload: string; type: string }
    ) => {
      state.Excerpt = action.payload;
    },
    changeCTAData: (
      state: AzureUpdate,
      action: { payload: string; type: string }
    ) => {
      state.CTAData = action.payload;
      state.IsCtaChanged = true;
    },
    changeNextStepData: (
      state: AzureUpdate,
      action: { payload: string; type: string }
    ) => {
      state.StatusNextSteps = action.payload;
      state.IsStatusNextStepsChanged = true;
    },
    changeEditorialAction: (
      state: AzureUpdate,
      action: { payload: number; type: string }
    ) => {
      state.EditorialActionId = action.payload;
    },
    changeAzureUpdateStatus: (
      state: AzureUpdate,
      action: { payload: number; type: string }
    ) => {
      state.AzureUpdateStatusId = action.payload;
    },
    changeIsCategoriesChanged: (
      state: AzureUpdate,
      action: { payload: boolean; type: string }
    ) => {
      state.IsCategoriesChanged = action.payload;
    },
    changeSelectedAzureUpdateCategories: (
      state: AzureUpdate,
      action: { payload: number[]; type: string }
    ) => {
      state.SelectedAzureUpdateCategories = action.payload.slice(0);
      state.AzureUpdateRelatedCategories = action.payload.map((key) => {
        return {
          AzureUpdateId: state.AzureUpdateId,
          AzureUpdateCategoryId: key,
        };
      });
    },
    changeIsRelatedProductChanged: (
      state: AzureUpdate,
      action: { payload: boolean; type: string }
    ) => {
      state.IsRelatedProductChanged = action.payload;
    },
    changeSelectedAzureUpdateRelatedProducts: (
      state: AzureUpdate,
      action: { payload: number[]; type: string }
    ) => {
      state.SelectedAzureUpdateRelatedProducts = action.payload.slice(0);
      state.AzureUpdateRelatedProducts = action.payload.map((key) => {
        return { AzureUpdateId: state.AzureUpdateId, ProductId: key };
      });
    },
    changeEngineeringOwner: (
      state: AzureUpdate,
      action: { payload: string; type: string }
    ) => {
      state.EngineeringOwner = action.payload;
    },
    changeTags: (
      state: AzureUpdate,
      action: { payload: string; type: string }
    ) => {
      state.Tags = action.payload;
    },
    changeIsFormValid: (
      state: AzureUpdate,
      action: { payload: boolean; type: string }
    ) => {
      state.__isFormValid = action.payload;
    },
    changeTitle: (
      state: AzureUpdate,
      action: { payload: string; type: string }
    ) => {
      state.Title = action.payload;
    },
    changeLoading: (
      state: AzureUpdate,
      action: { payload: string; type: string }
    ) => {
      state.__isLoading = action.payload;
    },
  },
  extraReducers: {
    [getAzureUpdate.fulfilled.type]: (state, { payload }) => {
      state.IsCtaChanged = false;
      state.IsStatusNextStepsChanged = false;
      state.IsContentDueDatePassed = payload.IsContentDueDatePassed;
      state.IsContentDueDatePassed = false;
      state.IsAzureUpdateCommentChanged = false;
      state.IsCategoriesChanged = false;
      state.IsRelatedProductChanged = false;
      state.CanEditAzureUpdateAtAnyTime = payload.CanEditAzureUpdateAtAnyTime;
      state.HasCommentHistory = payload.HasCommentHistory;
      state.AzureUpdatesProductId = payload.AzureUpdatesProductId;
      state.AzureUpdatesMilestoneId = payload.AzureUpdatesMilestoneId;
      state.AzureUpdatesContainerId = payload.AzureUpdatesContainerId;
      state.AzureUpdateId = payload.AzureUpdateId;
      state.Title = payload.Title;
      state.ProposedPublicationDate = payload.ProposedPublicationDate;
      state.TargetedAvailabilityDate = payload.TargetedAvailabilityDate;
      state.MilestoneId = payload.MilestoneId;
      state.Description = payload.Description;
      state.Excerpt = payload.Excerpt;
      state.ModifiedBy = payload.ModifiedBy;
      state.ModifiedDate = payload.ModifiedDate;
      state.EngineeringOwner = payload.EngineeringOwner;
      state.Tags = payload.Tags;
      state.AllowEditPastDueDate = payload.AllowEditPastDueDate;
      state.EditorialActionId = payload.EditorialActionId;
      state.AzureUpdateStatusId = payload.AzureUpdateStatusId;
      state.CTAs = payload.CTAs;
      state.AzureUpdateRelatedCategories = payload.AzureUpdateRelatedCategories;
      state.AzureUpdateRelatedProducts = payload.AzureUpdateRelatedProducts;
      state.AzureUpdateStatusNextSteps = payload.AzureUpdateStatusNextSteps;
      state.AzureUpdateCommentHistory = payload.AzureUpdateCommentHistory;
      state.__isFormValid = false;
      state.SelectedAzureUpdateCategories =
        payload.AzureUpdateRelatedCategories.map((c: any) => {
          return c.AzureUpdateCategoryId;
        });
      state.SelectedAzureUpdateRelatedProducts =
        payload.AzureUpdateRelatedProducts.map((p: any) => {
          return p.ProductId;
        });
      state.CTAData = JSON.stringify(payload.CTAs);
      state.__isLoading = _LOADINGSTATE.fullfilled;
    },
    [getAzureUpdate.rejected.type]: (state, { error }) => {
      state.__isLoading = _LOADINGSTATE.rejected;
      ErrorMessage.show(
        "There was an error getting the Azure Update. Please refresh the page and try again. If the issue persists please contact the tool administrator."
      );
    },
    [getAzureUpdate.pending.type]: (state) => {
      state.__isLoading = _LOADINGSTATE.pending;
    },
    [getAzureUpdateImageVersion.fulfilled.type]: (state, { payload }) => {
      state.AzureUpdateImageVersion = payload;
    },
    [getAzureUpdateImageVersion.rejected.type]: (state, { error }) => {
      ErrorMessage.show(
        "There was an error getting the Azure Update image version. Please refresh the page and try again. If the issue persists please contact the tool administrator."
      );
    },
    [getAzureUpdateImageVersion.pending.type]: (state) => {},
    [putAzureUpdate.fulfilled.type]: (state, { payload }) => {
      state.AzureUpdateImageVersion = payload;
      state.__isLoading = _LOADINGSTATE.fullfilled;
    },
    [putAzureUpdate.rejected.type]: (state, { error }) => {
      ErrorMessage.show(
        "There was an error saving the Azure Update. Please refresh the page and try again. If the issue persists please contact the tool administrator."
      );
    },
    [putAzureUpdate.pending.type]: (state) => {
      state.__isLoading = _LOADINGSTATE.pending;
    },
    [postAzureUpdateImage.fulfilled.type]: (state, { payload }) => {
      state.AzureUpdateImageVersion = payload;
      state.__isLoading = _LOADINGSTATE.fullfilled;
    },
    [postAzureUpdateImage.rejected.type]: (state, { error }) => {
      state.__isLoading = _LOADINGSTATE.rejected;
      ErrorMessage.show(
        "There was an error posting the Azure Update image. Please refresh the page and try again. If the issue persists please contact the tool administrator."
      );
    },
    [postAzureUpdateImage.pending.type]: (state) => {
      state.__isLoading = _LOADINGSTATE.pending;
    },
    [getAzureUpdateRelatedCategoriesOptions.fulfilled.type]: (
      state,
      { payload }
    ) => {
      state.azureupdateRelatedCategoriesOptions = payload.map(
        (o: CategoryOption) => {
          return { key: o.AzureUpdateCategoryId.toString(), text: o.Name };
        }
      );
    },
    [getAzureUpdateRelatedCategoriesOptions.rejected.type]: (
      state,
      { error }
    ) => {
      ErrorMessage.show(
        "There was an error getting the Azure Update related categories options. Please refresh the page and try again. If the issue persists please contact the tool administrator."
      );
    },
    [getAzureUpdateRelatedCategoriesOptions.pending.type]: (state) => {},
    [getAzureUpdateEditorialActionsOptions.fulfilled.type]: (
      state,
      { payload }
    ) => {
      state.azureupdateEditorialActionsOptions = payload.map(
        (o: EditorialActionOption) => {
          return { key: o.EditorialActionId, text: o.Name };
        }
      );
    },
    [getAzureUpdateEditorialActionsOptions.rejected.type]: (
      state,
      { error }
    ) => {
      ErrorMessage.show(
        "There was an error getting the Azure Update editorial actions options. Please refresh the page and try again. If the issue persists please contact the tool administrator."
      );
    },
    [getAzureUpdateEditorialActionsOptions.pending.type]: (state) => {},
    [getAzureUpdateStatusOptions.fulfilled.type]: (state, { payload }) => {
      state.azureupdateStatusOptions = payload.map((o: StatusOption) => {
        return { key: o.AzureUpdateStatusId, text: o.Name };
      });
    },
    [getAzureUpdateStatusOptions.rejected.type]: (state, { error }) => {
      ErrorMessage.show(
        "There was an error getting the Azure Update status options. Please refresh the page and try again. If the issue persists please contact the tool administrator."
      );
    },
    [getAzureUpdateStatusOptions.pending.type]: (state) => {},
    [postAzureUpdate.fulfilled.type]: (state, { payload }) => {
      state.AzureUpdateId = payload.id;
      state.__isLoading = _LOADINGSTATE.fullfilled;
    },
    [postAzureUpdate.pending.type]: (state) => {
      state.__isLoading = _LOADINGSTATE.pending;
    },
  },
});

export const { reducer, actions } = azureupdateSlice;
