import { createRef, useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { OnChangeValue } from "react-select";
import striptags from "striptags";
import "../../Shared/css/timelineFilter.scss";
import {
  Checkbox,
  Dropdown,
  IDropdownOption,
  PrimaryButton,
  Spinner,
  SpinnerSize,
  TextField,
} from "@fluentui/react";
import { SuccessMessage, ErrorMessage } from "../../Shared/messageBox";
import ConfirmationDialog from "../../Shared/confirmationDialog";
import GraphUsersAutoComplete from "../../Shared/FormInput/graphUsersAutoComplete";
import SearchableMultiSelect from "../../Shared/FormInput/searchableMultiSelect";
import { TimelineMode, _LOADINGSTATE } from "../../Shared/constants";
import { buttonStyles, OptionType } from "../../Shared/styleProps";
import {
  dropdownStyles,
  labelStyles,
  inputFieldStyles,
  graphUsersAutoCompleteStyles,
  filterStyles,
} from "../../Shared/timelineFilterStyleProps";
import { useUrlQueryParam } from "../../../utils/url";
import { isVoid, speakText } from "../../../utils/utils";
import { RootState, useAppDispatch } from "../../../store";
import { Conversation } from "../../../slice/conversation";
import { Product } from "../../../slice/product";
import { milestoneTier } from "../../../slice/milestoneTier";
import { milestoneType } from "../../../slice/milestoneType";
import {
  savedDataFilter,
  getSavedDataFilters,
  saveSavedDataFilter,
  deleteSavedDataFilter,
  filter,
  milestoneFilterList,
} from "../../../slice/savedDataFilters";
import * as azureTimelineReducer from "../../../slice/azureTimeline";
import { MilestoneModalContext } from "../index";
import { modernWorkSecurityEventCalendarMomentOption } from "../../../slice/modernWorkSecurityEventCalendarMoment";

const Filter = () => {
  const milestoneModalContext = useContext(MilestoneModalContext);
  const statuOptions: IDropdownOption[] = [
    { key: "4", text: "No action" },
    { key: "5", text: "Action needed" },
  ];

  const [filterControlMode, setFilterControlMode] = useState("left");
  const [showFilter, setShowFilter] = useState(true);
  const [showFilterList, setShowFilterList] = useState(false);
  const [deleteFilterId, setDeleteFilterId] = useState(0);

  const [currentFilterName, setCurrentFilterName] = useState("");
  const [selectedFilterName, setSelectedFilterName] = useState(
    "Select from your saved views"
  );
  const [errorMessage, setErrorMessage] = useState("");
  const [preTimelineMode, setPreTimelineMode] = useState(
    milestoneModalContext.timelineMode
  );
  const [showShare, setShowShare] = useState(false);
  const [showLinkCopied, setShowLinkCopied] = useState(false);

  const conversations = useSelector(
    (state: RootState) =>
      state.azureTimelineReducer.azureTimelineFilterOption.Conversations
  );
  const productIdAndNames = useSelector(
    (state: RootState) =>
      state.azureTimelineReducer.azureTimelineFilterOption.ProductIdAndNames
  );
  const milestoneCreators = useSelector(
    (state: RootState) =>
      state.azureTimelineReducer.azureTimelineFilterOption.MilestoneCreators
  );
  const milestoneLastUpdateBy = useSelector(
    (state: RootState) =>
      state.azureTimelineReducer.azureTimelineFilterOption.MilestoneLastUpdateBy
  );
  const milestoneTiers = useSelector(
    (state: RootState) =>
      state.azureTimelineReducer.azureTimelineFilterOption.MilestoneTiers
  );
  const milestoneTypes = useSelector(
    (state: RootState) =>
      state.azureTimelineReducer.azureTimelineFilterOption.MilestoneTypes
  );
  const modernWorkSecurityEventCalendarMomentList = useSelector(
    (state: RootState) =>
      state.azureTimelineReducer.azureTimelineFilterOption.Moments
  );
  const isFilterLoading = useSelector(
    (state: RootState) => state.azureTimelineReducer.isFilterLoading
  );
  const [isSavedDataFiltersLoading, setIsSavedDataFiltersLoading] = useState(
    _LOADINGSTATE.pending
  );
  const [isSavedDataFiltersSaving, setIsSavedDataFiltersSaving] = useState(
    _LOADINGSTATE.fullfilled
  );
  const [dataFilterList, setDataFilterList] = useState<savedDataFilter[]>([]);
  const [param, setParam] = useUrlQueryParam(
    milestoneFilterList.map((f: filter) => {
      return f.filterName;
    })
  );
  const [searchParams, setSearchParam] = useSearchParams();

  const appDispatch = useAppDispatch();

  const saveHandler = () => {
    if (!currentFilterName || currentFilterName.trim().length === 0) {
      setErrorMessage("Input Filter Name please.");
      document.getElementById("txtFilterName")?.focus();
      return;
    } else {
      setErrorMessage("");
    }
    setIsSavedDataFiltersSaving(_LOADINGSTATE.pending);
    let filterData: filter[] = [];
    milestoneFilterList.forEach((filter: filter) => {
      if (filter.filterName in param && param[filter.filterName] !== "") {
        if (filter.filterType === "multiselect") {
          filterData.push({
            filterName: filter.filterName,
            filterType: filter.filterType,
            filterValue: decodeURIComponent(param[filter.filterName]).split(
              ";|;"
            ),
          });
        } else {
          filterData.push({
            filterName: filter.filterName,
            filterType: filter.filterType,
            filterValue: decodeURIComponent(param[filter.filterName]),
          });
        }
      }
    });
    appDispatch(
      saveSavedDataFilter({
        Name: currentFilterName,
        FilterData: JSON.stringify(filterData),
        FilterType: "AzureTimelineFilter",
      })
    )
      .then((response) => {
        let newSavedDataFilter = response.payload as savedDataFilter;
        let newSavedDataFilterList = dataFilterList.filter(() => {
          return true;
        });
        if (
          newSavedDataFilterList
            .map((s: savedDataFilter) => {
              return s.SavedDataFilterId;
            })
            .indexOf(newSavedDataFilter.SavedDataFilterId) > -1
        ) {
          newSavedDataFilterList = newSavedDataFilterList.filter(
            (dataFilter) => {
              return (
                dataFilter.SavedDataFilterId !==
                newSavedDataFilter.SavedDataFilterId
              );
            }
          );
          SuccessMessage.show("Saved view updated!", 200, -100);
        } else {
          SuccessMessage.show("Saved view added!", 200, -100);
        }
        newSavedDataFilterList.unshift(newSavedDataFilter);
        setDataFilterList(newSavedDataFilterList);
      })
      .catch((error) => {
        ErrorMessage.show(error);
      })
      .finally(() => {
        setIsSavedDataFiltersSaving(_LOADINGSTATE.fullfilled);
      });
  };

  const deleteHandler = (key: number | undefined) => {
    if (key) {
      appDispatch(deleteSavedDataFilter(key))
        .then(() => {
          let newSavedDataFilterList = dataFilterList;

          SuccessMessage.show("Delete Filter success!");

          newSavedDataFilterList = newSavedDataFilterList.filter(
            (dataFilter) => {
              return dataFilter.SavedDataFilterId !== key;
            }
          );

          dataFilterList.every(function (savedDataFilter: savedDataFilter) {
            if (savedDataFilter.SavedDataFilterId === key) {
              if (savedDataFilter.Name === selectedFilterName) {
                setSelectedFilterName("Select one or more options");
                let query = searchParams;
                let filters = JSON.parse(savedDataFilter.FilterData);
                filters.forEach((filter: filter) => {
                  query.delete(filter.filterName);
                });
                setSearchParam(query);
              }
              return false;
            }
            return true;
          });

          setDataFilterList(newSavedDataFilterList);
        })
        .catch((error) => {
          ErrorMessage.show(error);
        });
    }
  };

  const updateUrl = (key: number | undefined) => {
    dataFilterList.every(function (savedDataFilter: savedDataFilter) {
      if (savedDataFilter.SavedDataFilterId === key) {
        let query = searchParams;
        milestoneFilterList.forEach((filter: filter) => {
          query.delete(filter.filterName);
        });
        let filters = JSON.parse(savedDataFilter.FilterData);
        filters.forEach((filter: filter) => {
          if (filter.filterValue) {
            query.append(
              filter.filterName,
              encodeURIComponent(
                filter.filterValue instanceof Array
                  ? filter.filterValue.join(";|;")
                  : filter.filterValue
              )
            );
          }
        });
        setSearchParam(query);
        return false;
      }
      return true;
    });
  };

  const deleteFilterDomref = createRef<HTMLButtonElement>();

  const deleteFilterHandle = (
    e: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>
  ) => {
    e.preventDefault();
    setDeleteFilterId(
      parseInt(e.currentTarget.getAttribute("data-filter-id") || "0")
    );

    deleteFilterDomref.current?.click();
  };

  const getSizes = () => {
    if (
      document.getElementsByClassName("filter-container") == null ||
      document.getElementsByClassName("filter-container").length === 0
    ) {
      return;
    }
    const browerWidth = document.documentElement.clientWidth;
    const filterSectionWidth =
      document.getElementsByClassName("filter-container")[0].clientWidth;
    let filterControlWidth =
      document.getElementById("divFilterControl")?.clientWidth;
    if (filterControlWidth !== undefined) {
      if (browerWidth - filterSectionWidth < filterControlWidth * 2) {
        setFilterControlMode("top");
      } else {
        setFilterControlMode("left");
      }
    }
  };

  useEffect(() => {
    window.removeEventListener("resize", getSizes, false);
    window.addEventListener("resize", getSizes, false);

    getSizes();
    appDispatch(azureTimelineReducer.getAzureTimelineFilters());
    appDispatch(getSavedDataFilters("AzureTimelineFilter")).then((response) => {
      if (typeof response.payload === "string") {
        setDataFilterList([]);
      } else {
        setDataFilterList(response.payload as savedDataFilter[]);
      }
      setIsSavedDataFiltersLoading(_LOADINGSTATE.fullfilled);
    });
  }, [appDispatch]);

  return (
    <div className="timeline-filter-container">
      {filterControlMode === "top" ? (
        <div className="row filter-container">
          <div className="position-relative col-2 pb-10">
            <div
              id="divFilterControl"
              role="button"
              tabIndex={0}
              className="filters-lg-text-container text-right position-absolute d-flex d-ab"
              aria-label="Filters expand to see list of filters"
              aria-expanded={showFilter ? "true" : "false"}
              onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>) => {
                if (event.keyCode === 13) {
                  setShowFilter(() => {
                    return !showFilter;
                  });
                }
              }}
              onClick={() => {
                setShowFilter(() => {
                  return !showFilter;
                });
              }}
            >
              <span className="filters-icon">
                {" "}
                <i
                  className={`${
                    showFilter
                      ? "fa fa-angle-down fa-2x"
                      : "fa fa-angle-right fa-2x"
                  }`}
                ></i>
              </span>
              <span className="filters-text">Filters:</span>
            </div>
          </div>
        </div>
      ) : null}
      <div className="row filter-container">
        <div className="section-wrap position-relative col-2">
          {filterControlMode === "left" ? (
            <div
              id="divFilterControl"
              className="filters-text-container text-right position-absolute d-flex d-ab"
              role="button"
              tabIndex={0}
              aria-label="Filters expand to see list of filters"
              aria-expanded={showFilter ? "true" : "false"}
              onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>) => {
                if (event.keyCode === 13) {
                  setShowFilter(() => {
                    return !showFilter;
                  });
                }
              }}
              onClick={() => {
                setShowFilter(() => {
                  return !showFilter;
                });
              }}
            >
              <span className="filters-icon">
                {" "}
                <i
                  className={`${
                    showFilter
                      ? "fa fa-angle-down fa-2x"
                      : "fa fa-angle-right fa-2x"
                  }`}
                ></i>
              </span>
              <span className="filters-text">Filters:</span>
            </div>
          ) : null}
          <div>
            <h3>
              <span className="filters-lookupname">Product</span>
            </h3>
            <div className={`${showFilter ? "" : "d-none"}`}>
              {isFilterLoading === _LOADINGSTATE.pending ? (
                <>
                  <div style={{ marginTop: 8 }}></div>
                  <Spinner
                    size={SpinnerSize.large}
                    label="CSA"
                    ariaLive="assertive"
                    labelPosition="top"
                  />
                </>
              ) : (
                <Dropdown
                  placeholder="Select one or more options"
                  label="CSA"
                  defaultSelectedKeys={
                    decodeURIComponent(param.ConversationId)
                      .split(";|;")
                      .filter((conversationId: string) => {
                        return (
                          conversations
                            .map((conversation: Conversation) => {
                              return conversation.ConversationId.toString();
                            })
                            .indexOf(conversationId) > -1
                        );
                      })
                      .map((conversationId: string) => {
                        return parseInt(conversationId);
                      }) || []
                  }
                  multiSelect={true}
                  options={conversations.map((conversation: Conversation) => {
                    return {
                      key: conversation.ConversationId,
                      text: conversation.Name,
                    };
                  })}
                  onChange={(event, item) => {
                    if (item) {
                      let updatedConversationIds = decodeURIComponent(
                        param.ConversationId
                      )
                        .split(";|;")
                        .filter(
                          (conversationId: string) =>
                            conversationId !== item.key.toString()
                        );
                      if (item.selected === true) {
                        updatedConversationIds.push(item.key.toString());
                      }
                      setParam({
                        ...param,
                        ConversationId: encodeURIComponent(
                          updatedConversationIds.join(";|;")
                        ),
                      });
                    }
                  }}
                  required={false}
                  styles={dropdownStyles}
                  calloutProps={{ doNotLayer: true }}
                />
              )}
            </div>
          </div>
          <div className={`${showFilter ? "" : "d-none"}`}>
            {isFilterLoading === _LOADINGSTATE.pending ? (
              <>
                <div style={{ marginTop: 8 }}></div>
                <Spinner
                  size={SpinnerSize.large}
                  label="Product"
                  ariaLive="assertive"
                  labelPosition="top"
                />
              </>
            ) : (
              <>
                <label style={labelStyles}>Product</label>
                <SearchableMultiSelect
                  ariaLabel={"Product"}
                  placeholder={"Select one or more options"}
                  id={"AzureTimelineFilter_Product"}
                  options={productIdAndNames.map((c: Product) => {
                    return {
                      value: c.ProductId.toString(),
                      label: c.ProductName,
                    };
                  })}
                  value={
                    productIdAndNames
                      .filter((product: Product) => {
                        return (
                          decodeURIComponent(param.ProductName)
                            .split(";|;")
                            .indexOf(product.ProductName) > -1
                        );
                      })
                      .map((product: Product) => {
                        return {
                          value: product.ProductId.toString(),
                          label: product.ProductName,
                        };
                      }) || []
                  }
                  selectStyle={filterStyles}
                  onChangeHandler={(value: OnChangeValue<OptionType, true>) => {
                    if (value) {
                      setParam({
                        ...param,
                        ProductName: encodeURIComponent(
                          value
                            .map((v: OptionType) => {
                              return v.label;
                            })
                            .join(";|;")
                        ),
                      });
                    }
                  }}
                />
              </>
            )}
          </div>
          <div className={`${showFilter ? "" : "d-none"}`}>
            <div style={{ marginTop: "10px" }}>
              <label
                style={{
                  marginTop: "5px",
                  marginBottom: "5px",
                }}
              >
                {" "}
              </label>
              <Checkbox
                label="Show products without milestones"
                defaultChecked={false}
                checked={
                  milestoneModalContext.timelineMode ===
                  TimelineMode.ProductsList
                }
                onChange={(
                  ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
                  isChecked?: boolean
                ) => {
                  if (isChecked) {
                    setPreTimelineMode(milestoneModalContext.timelineMode);
                    milestoneModalContext.setTimelineMode(
                      TimelineMode.ProductsList
                    );
                  } else {
                    milestoneModalContext.setTimelineMode(preTimelineMode);
                  }
                }}
              />
            </div>
          </div>
        </div>
        <div className="col-2">
          <h3>
            <span className="filters-lookupname">People</span>
          </h3>
          <div className={`${showFilter ? "" : "d-none"}`}>
            <label className="ms-Label ms-Dropdown-label" style={labelStyles}>
              Product marketing manager
            </label>
            <GraphUsersAutoComplete
              selectStyle={graphUsersAutoCompleteStyles}
              selectedKey={
                param.ContactName === ""
                  ? undefined
                  : decodeURIComponent(param.ContactName)
              }
              onChangeHandler={(value) => {
                if (!isVoid(value) && !isVoid(value?.value)) {
                  var email = "",
                    regexEmail = "\\[(.+?)\\]",
                    matchStr = value?.value.match(regexEmail);
                  if (isVoid(matchStr)) {
                    return;
                  } else {
                    email = matchStr[1];
                  }
                  var userNameLength = value?.value.length - email.length - 3;
                  var userName = value?.value.substring(0, userNameLength);
                  setParam({
                    ...param,
                    ContactName: encodeURIComponent(userName),
                  });
                } else {
                  let query = searchParams;
                  if (query.has("ContactName")) {
                    query.delete("ContactName");
                  }
                  setSearchParam(query);
                }
              }}
              microsoftUser={true}
              ariaLabel="Product marketing manager"
              id="filter_MarketingLead_Name"
            ></GraphUsersAutoComplete>
          </div>
          <div className={`${showFilter ? "" : "d-none"}`}>
            <div>
              <label className="ms-Label ms-Dropdown-label" style={labelStyles}>
                Marketing team lead
              </label>
              <GraphUsersAutoComplete
                selectStyle={graphUsersAutoCompleteStyles}
                selectedKey={
                  param.MarketingLeadName === ""
                    ? undefined
                    : decodeURIComponent(param.MarketingLeadName)
                }
                onChangeHandler={(value) => {
                  if (!isVoid(value) && !isVoid(value?.value)) {
                    var email = "",
                      regexEmail = "\\[(.+?)\\]",
                      matchStr = value?.value.match(regexEmail);
                    if (isVoid(matchStr)) {
                      return;
                    } else {
                      email = matchStr[1];
                    }
                    var userNameLength = value?.value.length - email.length - 3;
                    var userName = value?.value.substring(0, userNameLength);
                    setParam({
                      ...param,
                      MarketingLeadName: encodeURIComponent(userName),
                    });
                  } else {
                    let query = searchParams;
                    if (query.has("MarketingLeadName")) {
                      query.delete("MarketingLeadName");
                    }
                    setSearchParam(query);
                  }
                }}
                microsoftUser={true}
                ariaLabel="Marketing team lead"
                id="filter_Contact_Name"
              ></GraphUsersAutoComplete>
            </div>
          </div>
        </div>
        <div className="section-wrap col-2">
          <h3>
            <span className="filters-lookupname">
              <label></label>
            </span>
          </h3>
          <div className={`${showFilter ? "" : "d-none"}`}>
            {isFilterLoading === _LOADINGSTATE.pending ? (
              <>
                <div style={{ marginTop: 8 }}></div>
                <Spinner
                  size={SpinnerSize.large}
                  label="Milestone creator"
                  ariaLive="assertive"
                  labelPosition="top"
                />
              </>
            ) : (
              <>
                <label style={labelStyles}>Milestone creator</label>
                <SearchableMultiSelect
                  ariaLabel={"Milestone creator"}
                  placeholder={"Select one or more options"}
                  id={"AzureTimelineFilter_MilestoneCreator"}
                  options={milestoneCreators.map((c: string) => {
                    return { value: c, label: c };
                  })}
                  value={
                    milestoneCreators
                      .filter((creator: string) => {
                        return (
                          decodeURIComponent(param.CreatedBy)
                            .split(";|;")
                            .indexOf(creator) > -1
                        );
                      })
                      .map((creator: string) => {
                        return { value: creator, label: creator };
                      }) || []
                  }
                  selectStyle={filterStyles}
                  onChangeHandler={(value: OnChangeValue<OptionType, true>) => {
                    if (value) {
                      setParam({
                        ...param,
                        CreatedBy: encodeURIComponent(
                          value
                            .map((v: OptionType) => {
                              return v.label;
                            })
                            .join(";|;")
                        ),
                      });
                    }
                  }}
                />
              </>
            )}
          </div>
          <div className={`${showFilter ? "" : "d-none"}`}>
            {isFilterLoading === _LOADINGSTATE.pending ? (
              <>
                <div style={{ marginTop: 8 }}></div>
                <Spinner
                  size={SpinnerSize.large}
                  label="Last updated by"
                  ariaLive="assertive"
                  labelPosition="top"
                />
              </>
            ) : (
              <>
                <label style={labelStyles}>Last updated by</label>
                <SearchableMultiSelect
                  ariaLabel={"Last updated by"}
                  placeholder={"Select one or more options"}
                  id={"AzureTimelineFilter_LastUpdatedBy"}
                  options={milestoneLastUpdateBy.map((c: string) => {
                    return {
                      value: c,
                      label: c,
                    };
                  })}
                  value={
                    milestoneLastUpdateBy
                      .filter((c: string) => {
                        return (
                          decodeURIComponent(param.ModifiedBy)
                            .split(";|;")
                            .indexOf(c) > -1
                        );
                      })
                      .map((c: string) => {
                        return {
                          value: c,
                          label: c,
                        };
                      }) || []
                  }
                  selectStyle={filterStyles}
                  onChangeHandler={(value: OnChangeValue<OptionType, true>) => {
                    if (value) {
                      setParam({
                        ...param,
                        ModifiedBy: encodeURIComponent(
                          value
                            .map((v: OptionType) => {
                              return v.label;
                            })
                            .join(";|;")
                        ),
                      });
                    }
                  }}
                />
              </>
            )}
          </div>
        </div>
        <div className="col-2">
          <h3>
            <span className="filters-lookupname">Milestone properties</span>
          </h3>
          <div className={`${showFilter ? "" : "d-none"}`}>
            {isFilterLoading === _LOADINGSTATE.pending ? (
              <>
                <div style={{ marginTop: 8 }}></div>
                <Spinner
                  size={SpinnerSize.large}
                  label="Milestone tier"
                  ariaLive="assertive"
                  labelPosition="top"
                />
              </>
            ) : (
              <Dropdown
                placeholder="Select one or more options"
                label="Milestone tier"
                defaultSelectedKeys={
                  decodeURIComponent(param.TierId)
                    .split(";|;")
                    .filter((tierId: string) => {
                      return (
                        milestoneTiers
                          .map((tier: milestoneTier) => {
                            return tier.TierId.toString();
                          })
                          .indexOf(tierId) > -1
                      );
                    })
                    .map((tierId: string) => {
                      return parseInt(tierId);
                    }) || []
                }
                multiSelect={true}
                options={milestoneTiers.map((tier: milestoneTier) => {
                  return { key: tier.TierId, text: "Tier " + tier.Name };
                })}
                onChange={(event, item) => {
                  if (item) {
                    let updatedMilestoneTier = decodeURIComponent(param.TierId)
                      .split(";|;")
                      .filter(
                        (milestoneTierId: string) =>
                          milestoneTierId !== item.key.toString()
                      );
                    if (item.selected === true) {
                      updatedMilestoneTier.push(item.key.toString());
                    }
                    setParam({
                      ...param,
                      TierId: encodeURIComponent(
                        updatedMilestoneTier.join(";|;")
                      ),
                    });
                  }
                }}
                styles={dropdownStyles}
                calloutProps={{
                  doNotLayer: true,
                }}
              />
            )}
          </div>
          <div className={`${showFilter ? "" : "d-none"}`}>
            {isFilterLoading === _LOADINGSTATE.pending ? (
              <>
                <div style={{ marginTop: 8 }}></div>
                <Spinner
                  size={SpinnerSize.large}
                  label="Marketing moment alignment"
                  ariaLive="assertive"
                  labelPosition="top"
                />
              </>
            ) : (
              <Dropdown
                placeholder="Select one or more options"
                label="Marketing moment alignment"
                defaultSelectedKeys={
                  decodeURIComponent(param.MomentId)
                    .split(";|;")
                    .filter((momentId: string) => {
                      return (
                        modernWorkSecurityEventCalendarMomentList
                          .map(
                            (
                              moment: modernWorkSecurityEventCalendarMomentOption
                            ) => {
                              return moment.Id.toString();
                            }
                          )
                          .indexOf(momentId) > -1
                      );
                    })
                    .map((momentId: string) => {
                      return parseInt(momentId);
                    }) || []
                }
                multiSelect={true}
                options={modernWorkSecurityEventCalendarMomentList.map(
                  (m: modernWorkSecurityEventCalendarMomentOption) => {
                    return {
                      key: m.Id,
                      text: striptags(m.DisplayTextWithYearAndTBD),
                      data: m.DisplayTextWithYearAndTBD,
                    };
                  }
                )}
                onRenderOption={(option) => {
                  return option !== undefined ? (
                    <span
                      className="ms-Dropdown-optionText"
                      style={{
                        overflow: `hidden`,
                        whiteSpace: `nowrap`,
                        textOverflow: `ellipsis`,
                        maxWidth: `100%`,
                        overflowWrap: `break-word`,
                        margin: 1,
                      }}
                      title={option.text}
                      dangerouslySetInnerHTML={{
                        __html: option.data,
                      }}
                    ></span>
                  ) : null;
                }}
                onChange={(event, item) => {
                  if (item) {
                    let updatedMoment = decodeURIComponent(param.MomentId)
                      .split(";|;")
                      .filter(
                        (momentId: string) => momentId !== item.key.toString()
                      );
                    if (item.selected === true) {
                      updatedMoment.push(item.key.toString());
                    }
                    setParam({
                      ...param,
                      MomentId: encodeURIComponent(updatedMoment.join(";|;")),
                    });
                  }
                }}
                styles={dropdownStyles}
                calloutProps={{ calloutMaxHeight: 400, doNotLayer: true }}
              />
            )}
          </div>
          <div className={`${showFilter ? "" : "d-none"}`}>
            <Dropdown
              placeholder="Select one or more options"
              label="Planning"
              defaultSelectedKeys={
                decodeURIComponent(param.Status)
                  .split(";|;")
                  .filter((status: string) => {
                    return (
                      statuOptions
                        .map((statu: IDropdownOption) => {
                          return statu.key.toString();
                        })
                        .indexOf(status) > -1
                    );
                  })
                  .map((statu: string) => {
                    return statu;
                  }) || []
              }
              multiSelect={true}
              options={statuOptions}
              onChange={(event, item) => {
                if (item) {
                  let updatedMilestoneStatu = decodeURIComponent(param.Status)
                    .split(";|;")
                    .filter(
                      (milestoneStatuId: string) =>
                        milestoneStatuId !== item.key.toString()
                    );
                  if (item.selected === true) {
                    updatedMilestoneStatu.push(item.key.toString());
                  }
                  setParam({
                    ...param,
                    Status: encodeURIComponent(
                      updatedMilestoneStatu.join(";|;")
                    ),
                  });
                }
              }}
              styles={dropdownStyles}
              calloutProps={{ doNotLayer: true }}
            />
          </div>
        </div>
        <div className="section-wrap col-2">
          <h3>
            <span className="filters-lookupname">
              <label></label>
            </span>
          </h3>
          <div className={`${showFilter ? "" : "d-none"}`}>
            {isFilterLoading === _LOADINGSTATE.pending ? (
              <>
                <div style={{ marginTop: 8 }}></div>
                <Spinner
                  size={SpinnerSize.large}
                  label="Milestone type"
                  ariaLive="assertive"
                  labelPosition="top"
                />
              </>
            ) : (
              <Dropdown
                placeholder="Select one or more options"
                label="Milestone type"
                defaultSelectedKeys={
                  decodeURIComponent(param.MilestoneTypeId)
                    .split(";|;")
                    .filter((milestoneTypeId: string) => {
                      return (
                        milestoneTypes
                          .map((milestoneType: milestoneType) => {
                            return milestoneType.MilestoneTypeId.toString();
                          })
                          .indexOf(milestoneTypeId) > -1
                      );
                    })
                    .map((milestoneTypeId: string) => {
                      return parseInt(milestoneTypeId);
                    }) || []
                }
                multiSelect={true}
                options={milestoneTypes.map((c: milestoneType) => {
                  return { key: c.MilestoneTypeId, text: c.Name };
                })}
                onChange={(event, item) => {
                  if (item) {
                    let updatedMilestoneType = decodeURIComponent(
                      param.MilestoneTypeId
                    )
                      .split(";|;")
                      .filter(
                        (milestoneTypeId: string) =>
                          milestoneTypeId !== item.key.toString()
                      );
                    if (item.selected === true) {
                      updatedMilestoneType.push(item.key.toString());
                    }
                    setParam({
                      ...param,
                      MilestoneTypeId: encodeURIComponent(
                        updatedMilestoneType.join(";|;")
                      ),
                    });
                  }
                }}
                styles={dropdownStyles}
                calloutProps={{ doNotLayer: true }}
              />
            )}
          </div>
          <div className={`${showFilter ? "" : "d-none"}`}>
            <div>
              <TextField
                label="Milestone ID"
                placeholder="Enter milestone ID"
                value={param.FilterMilestoneId}
                onChange={(event, item) => {
                  if (item) {
                    item = item.replace(/[^\d]/g, "");
                  }
                  if (item && item !== "") {
                    setParam({
                      ...param,
                      FilterMilestoneId: encodeURIComponent(item),
                    });
                  } else {
                    let query = searchParams;
                    if (query.has("FilterMilestoneId")) {
                      query.delete("FilterMilestoneId");
                    }
                    setSearchParam(query);
                  }
                }}
                styles={inputFieldStyles}
              />
            </div>
          </div>
        </div>
        <div className="col-2">
          <h3>
            <span className="filters-lookupname">Saved views</span>
          </h3>
          <div className={`${showFilter ? "" : "d-none"}`}>
            <div className="d-flex" style={{ width: 250 }}>
              <TextField
                id="txtFilterName"
                label="Name and save this view"
                value={currentFilterName}
                placeholder="Enter name"
                styles={inputFieldStyles}
                onChange={(event, item) => {
                  setErrorMessage("");
                  setCurrentFilterName(item || "");
                }}
                required={true}
                errorMessage={errorMessage}
              />
              <PrimaryButton
                style={{ marginTop: 30, minWidth: 55, width: 55 }}
                disabled={isSavedDataFiltersSaving === _LOADINGSTATE.pending}
                ariaDescription="Save filters"
                text="Save"
                allowDisabledFocus
                onClick={saveHandler}
                styles={buttonStyles}
              />
            </div>
          </div>
          <div
            className={`${showFilter ? "" : "d-none"}`}
            style={{ width: 250 }}
          >
            {isSavedDataFiltersLoading === _LOADINGSTATE.pending ? (
              <>
                <div style={{ marginTop: 8 }}></div>
                <Spinner
                  size={SpinnerSize.large}
                  label="Saved views"
                  ariaLive="assertive"
                  labelPosition="top"
                />
              </>
            ) : (
              <>
                <Dropdown
                  placeholder={selectedFilterName}
                  label="Saved views"
                  onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => {
                    if (e.keyCode === 13) {
                      setShowFilterList(!showFilterList);
                      e.stopPropagation();
                      document.body.onclick = (event) => {
                        let target = event.target as HTMLElement | null;
                        let needReturn = false;
                        while (target) {
                          if (
                            target.className &&
                            typeof target.className.indexOf === "function" &&
                            target.className.indexOf("filter-list") > -1
                          ) {
                            needReturn = true;
                            break;
                          }
                          target = target.parentElement;
                        }
                        if (needReturn) {
                          return;
                        }
                        setShowFilterList(false);
                      };
                    } else if (e.keyCode === 27) {
                      setShowFilterList(false);
                    }
                  }}
                  onClick={(e) => {
                    setShowFilterList(!showFilterList);
                    e.stopPropagation();
                    document.body.onclick = (event) => {
                      let target = event.target as HTMLElement | null;
                      let needReturn = false;
                      while (target) {
                        if (
                          target.className &&
                          typeof target.className.indexOf === "function" &&
                          target.className.indexOf("filter-list") > -1
                        ) {
                          needReturn = true;
                          break;
                        }
                        target = target.parentElement;
                      }
                      if (needReturn) {
                        return;
                      }
                      setShowFilterList(false);
                    };
                  }}
                  options={[]}
                  styles={dropdownStyles}
                />
                <div style={{ maxHeight: 0 }}>
                  <div
                    role="menu"
                    className="filter-list"
                    style={{
                      visibility: showFilterList ? "visible" : "hidden",
                    }}
                  >
                    {dataFilterList.map((filter: savedDataFilter) => (
                      <div
                        role="menuitem"
                        className={
                          selectedFilterName === filter.Name
                            ? "d-flex filter-list-item active"
                            : "d-flex filter-list-item"
                        }
                        aria-current={selectedFilterName === filter.Name}
                      >
                        <div
                          style={{ width: 204, overflow: "auto" }}
                          key={filter.Name}
                          onClick={() => {
                            setSelectedFilterName(filter.Name);
                            setCurrentFilterName(filter.Name);
                            updateUrl(filter.SavedDataFilterId);
                          }}
                          tabIndex={0}
                          aria-label={filter.Name}
                          onKeyDown={(
                            event: React.KeyboardEvent<HTMLDivElement>
                          ) => {
                            if (event.keyCode === 13) {
                              setSelectedFilterName(filter.Name);
                              setCurrentFilterName(filter.Name);
                              updateUrl(filter.SavedDataFilterId);
                            } else if (event.keyCode === 27) {
                              setShowFilterList(false);
                            }
                          }}
                        >
                          {filter.Name}
                        </div>
                        <div
                          style={{ width: 30 }}
                          key={filter.SavedDataFilterId}
                        >
                          <i
                            className="ms-Icon ms-Icon--Delete"
                            onClick={deleteFilterHandle}
                            data-filter-id={filter.SavedDataFilterId}
                            tabIndex={0}
                            aria-label="Delete"
                            role="button"
                            onKeyDown={(
                              event: React.KeyboardEvent<HTMLDivElement>
                            ) => {
                              if (event.keyCode === 13) {
                                deleteFilterHandle(event);
                              } else if (event.keyCode === 27) {
                                setShowFilterList(false);
                              }
                            }}
                          ></i>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <ConfirmationDialog
                  refs={deleteFilterDomref}
                  onclickCallBack={() => {
                    return new Promise((resolve, reject) => {
                      if (deleteFilterId !== 0) {
                        deleteHandler(deleteFilterId);
                        setDeleteFilterId(0);
                      }
                    });
                  }}
                  title="Deleting Filter"
                  subText="Are you sure you want to delete this filter permanently?"
                  confirmButtonString="Delete"
                  cancelButtonString="Cancel"
                />
              </>
            )}
          </div>
          <div style={{ display: "flex" }}>
            <div
              className={`${
                showFilter ? "share-this-view col-6 azure-security" : "d-none"
              }`}
            >
              <div style={{ width: 100 }}>
                <label
                  className="ms-Label"
                  style={{ marginTop: 5, marginBottom: 5 }}
                >
                  Share this view
                </label>
                <div
                  style={{ textAlign: "center" }}
                  role="link"
                  tabIndex={0}
                  onClick={() => {
                    setShowLinkCopied(false);
                    setShowShare(true);
                  }}
                >
                  <i
                    style={{ fontSize: 26, cursor: "pointer" }}
                    data-icon-name="Share"
                    role="presentation"
                    aria-hidden="true"
                    className="ms-Icon root-48"
                  >
                    
                  </i>
                </div>
              </div>
              {showShare ? (
                <div className="share-link-view">
                  {showLinkCopied ? (
                    <>
                      <i
                        className="ms-Icon ms-Icon--Completed completedView"
                        title="Completed"
                        aria-hidden="true"
                      ></i>
                      <div className="copyLinkText">Link Copied</div>
                    </>
                  ) : null}
                  <i
                    className="ms-Icon ms-Icon--ChromeClose font-size-16 closeShareView"
                    title="Close"
                    aria-label="Close share view"
                    aria-hidden="true"
                    onClick={() => {
                      setShowLinkCopied(false);
                      setShowShare(false);
                    }}
                  />
                  <div className="link-wrap additional-pl15">
                    <div>
                      <label
                        className="control-label"
                        style={{ marginTop: 5, marginBottom: 5 }}
                      >
                        The view share link
                      </label>
                    </div>
                    <div>
                      <input
                        className="linkInputBox"
                        type="text"
                        aria-label="Share view link"
                        value={window.location.href}
                      />
                      <button
                        className="btn btn-sm btn-primary copyButton"
                        tabIndex={0}
                        onClick={() => {
                          navigator.clipboard.writeText(window.location.href);
                          setShowLinkCopied(true);
                        }}
                      >
                        Copy
                      </button>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
            <div
              className={`${
                showFilter ? "col-6 text-end" : "col-6 d-none text-end"
              }`}
            >
              <PrimaryButton
                ariaDescription="Clear filters"
                ariaLabel="Clear filters"
                text="Clear filters"
                allowDisabledFocus
                onClick={() => {
                  setSelectedFilterName("Select from your saved views");
                  setCurrentFilterName("");
                  let query = searchParams;
                  milestoneFilterList.forEach((filter: filter) => {
                    query.delete(filter.filterName);
                  });
                  setSearchParam(query);
                  speakText("Cleared applied filters");
                }}
                styles={buttonStyles}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Filter;
