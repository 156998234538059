import React, { useContext, useMemo } from "react";
import { Spinner, SpinnerSize } from "@fluentui/react";
import moment from "moment";
import "../../../Shared/css/timelineGrid.scss";
import "../index.scss";
import { useUrlQueryParam } from "../../../../utils/url";
import { TimelineMode, _LOADINGSTATE } from "../../../Shared/constants";
import { splitStringToArray } from "../../../../utils/string";
import { ProductMilestone } from "./productMilestone";

import {
  DataSetProps,
  securityTimelineMilestone,
} from "../../../../slice/securityTimeline";
import {
  filter,
  securityMilestoneFilterList,
} from "../../../../slice/savedDataFilters";
import {
  initialMilestoneState,
  SecurityMilestone,
} from "../../../../slice/securityMilestone";
import { SecurityMilestoneModalContext } from "../../../SecurityTimeline";

const RenderMilestone = (props: {
  dataSet: Omit<DataSetProps, "moments">[];
}) => {
  const [param] = useUrlQueryParam(
    securityMilestoneFilterList.map((f: filter) => {
      return f.filterName;
    })
  );
  const securityMilestoneModalContext = useContext(
    SecurityMilestoneModalContext
  );

  let searchResult = 0;
  let resultText = "";
  const milestoneDataSets = useMemo(() => {
    let datas: DataSetProps[] = [];
    props.dataSet.forEach((dataSet: Omit<DataSetProps, "moments">) => {
      let filterMilestones: {
        productName: string;
        milestone: securityTimelineMilestone[];
        milestoneExcelDownload: SecurityMilestone[];
      }[] = [];

      if (dataSet.milestone && dataSet.milestone !== null) {
        dataSet.milestone.milestones.forEach(
          (milestone: {
            productName: string;
            milestone: securityTimelineMilestone[];
          }) => {
            let milestones: securityTimelineMilestone[] = [];
            milestone.milestone.forEach(
              (milestone: securityTimelineMilestone) => {
                if (param.ProductServices) {
                  if (
                    decodeURIComponent(param.ProductServices)
                      .split(";|;")
                      .filter((item) => item !== "")
                      .filter(
                        (item) =>
                          splitStringToArray(
                            milestone.ProductServiceName
                          ).indexOf(item) !== -1
                      ).length === 0
                  ) {
                    return true;
                  }
                }

                if (param.ProductCategory) {
                  if (
                    decodeURIComponent(param.ProductCategory)
                      .split(";|;")
                      .filter((item) => item !== "")
                      .filter(
                        (item) =>
                          splitStringToArray(milestone.ProductCategory).indexOf(
                            item
                          ) !== -1
                      ).length === 0
                  ) {
                    return true;
                  }
                }

                if (param.SolutionPlay) {
                  if (
                    decodeURIComponent(param.SolutionPlay)
                      .split(";|;")
                      .filter((item) => item !== "")
                      .filter(
                        (item) =>
                          splitStringToArray(milestone.SolutionPlay).indexOf(
                            item
                          ) !== -1
                      ).length === 0
                  ) {
                    return true;
                  }
                }

                if (param.ProductFamily) {
                  if (
                    decodeURIComponent(param.ProductFamily)
                      .split(";|;")
                      .filter((item) => item !== "")
                      .filter(
                        (item) =>
                          splitStringToArray(milestone.ProductFamily).indexOf(
                            item
                          ) !== -1
                      ).length === 0
                  ) {
                    return true;
                  }
                }

                if (param.PMM) {
                  if (decodeURIComponent(param.PMM) !== milestone.PMM) {
                    return true;
                  }
                }

                if (param.SecurityAudience) {
                  if (
                    milestone.SecurityMilestoneAudiences === undefined ||
                    milestone.SecurityMilestoneAudiences === null ||
                    decodeURIComponent(param.SecurityAudience)
                      .split(";|;")
                      .filter((item) => item !== "")
                      .filter(
                        (item) =>
                          milestone.SecurityMilestoneAudiences.findIndex(
                            (a) => a.SecurityAudienceId.toString() === item
                          ) !== -1
                      ).length === 0
                  ) {
                    return true;
                  }
                }

                if (param.TierId) {
                  if (
                    milestone.TierId === undefined ||
                    milestone.TierId === null ||
                    decodeURIComponent(param.TierId)
                      .split(";|;")
                      .filter((item) => item !== "")
                      .indexOf(milestone.TierId.toString()) === -1
                  ) {
                    return true;
                  }
                }

                if (param.SecurityMilestoneTypeId) {
                  if (
                    milestone.SecurityMilestoneTypeId === undefined ||
                    milestone.SecurityMilestoneTypeId === null ||
                    decodeURIComponent(param.SecurityMilestoneTypeId)
                      .split(";|;")
                      .filter((item) => item !== "")
                      .indexOf(milestone.SecurityMilestoneTypeId.toString()) ===
                      -1
                  ) {
                    return true;
                  }
                }

                if (param.MomentId) {
                  if (
                    milestone.ModernWorkSecurityEventCalendarMomentsId ===
                      undefined ||
                    milestone.ModernWorkSecurityEventCalendarMomentsId ===
                      null ||
                    decodeURIComponent(param.MomentId)
                      .split(";|;")
                      .filter((item) => item !== "")
                      .indexOf(
                        milestone.ModernWorkSecurityEventCalendarMomentsId.toString()
                      ) === -1
                  ) {
                    return true;
                  }
                }

                if (param.FiscalYear) {
                  if (
                    milestone.FiscalYear === undefined ||
                    milestone.FiscalYear === null ||
                    decodeURIComponent(param.FiscalYear)
                      .split(";|;")
                      .filter((item) => item !== "")
                      .indexOf(milestone.FiscalYear.toString()) === -1
                  ) {
                    return true;
                  }
                }

                if (param.FiscalQuarter) {
                  if (
                    milestone.FiscalQuarter === undefined ||
                    milestone.FiscalQuarter === null ||
                    decodeURIComponent(param.FiscalQuarter)
                      .split(";|;")
                      .filter((item) => item !== "")
                      .indexOf(milestone.FiscalQuarter.toString()) === -1
                  ) {
                    return true;
                  }
                }

                if (param.FiscalMonth) {
                  if (
                    milestone.FiscalMonth === undefined ||
                    milestone.FiscalMonth === null ||
                    decodeURIComponent(param.FiscalMonth)
                      .split(";|;")
                      .filter((item) => item !== "")
                      .indexOf(milestone.FiscalMonth.toString()) === -1
                  ) {
                    return true;
                  }
                }
                milestones.push(milestone);
              }
            );

            if (param.SortByDate) {
              const sortCondition = decodeURIComponent(param.SortByDate)
                .split(";|;")
                .filter((item) => item !== "");
              sortCondition.forEach((item) => {
                switch (item) {
                  case "PublicDisclosureDate":
                    milestones.sort((a, b) => {
                      return moment(a.PrivatePreviewDate) >
                        moment(b.PrivatePreviewDate)
                        ? -1
                        : moment(a.PrivatePreviewDate) <
                          moment(b.PrivatePreviewDate)
                        ? 1
                        : 0;
                    });
                    break;
                  case "PrivatePreviewDate":
                    milestones.sort((a, b) => {
                      return moment(a.PrivatePreviewDate) >
                        moment(b.PrivatePreviewDate)
                        ? -1
                        : moment(a.PrivatePreviewDate) <
                          moment(b.PrivatePreviewDate)
                        ? 1
                        : 0;
                    });
                    break;
                  case "PublicPreviewDate":
                    milestones.sort((a, b) => {
                      return moment(a.PublicPreviewDate) >
                        moment(b.PublicPreviewDate)
                        ? -1
                        : moment(a.PublicPreviewDate) <
                          moment(b.PublicPreviewDate)
                        ? 1
                        : 0;
                    });
                    break;
                  case "GeneralAvailabilityDate":
                    milestones.sort((a, b) => {
                      return moment(a.GeneralAvailabilityDate) >
                        moment(b.GeneralAvailabilityDate)
                        ? -1
                        : moment(a.GeneralAvailabilityDate) <
                          moment(b.GeneralAvailabilityDate)
                        ? 1
                        : 0;
                    });
                    break;
                  default:
                    break;
                }
              });
            }

            if (milestones.length > 0) {
              searchResult += milestones.length;
              filterMilestones.push({
                productName: milestone.productName,
                milestone: milestones,
                milestoneExcelDownload: [initialMilestoneState],
              });
            }
          }
        );
      }

      let milestoneGroup = {
        month: dataSet.month,
        milestones: filterMilestones,
      };

      datas.push({
        month: dataSet.month,
        moments: { month: "", moment: [] },
        milestone: milestoneGroup,
        isLoading: dataSet.isLoading,
      });
    });

    if (securityMilestoneModalContext.timelineMode == TimelineMode.Search) {
      if (searchResult === 0) {
        resultText = "no result available";
      } else if (searchResult === 1) {
        resultText = "1 result available";
      } else resultText = searchResult + " results available";

      document.body.querySelector("#ariaLiveReader")!.innerHTML = resultText;
    }

    return datas;
  }, [props.dataSet, param]);

  const milestoneDom = milestoneDataSets.map((item, index) => {
    return item.month !== "" ? (
      <div
        data-featurenexmonth={item.month}
        className={`grid-item grid-item-milestone ${
          index !== 0 ? "milestone-border-left" : ""
        }`}
        key={item.month + "_milestone"}
      >
        {item.isLoading === _LOADINGSTATE.fullfilled ? (
          <ProductMilestone productMilestones={item.milestone} />
        ) : (
          <Spinner
            size={SpinnerSize.large}
            label="Loading content"
            ariaLive="polite"
            labelPosition="top"
          />
        )}
      </div>
    ) : null;
  });
  return <>{milestoneDom}</>;
};

export default RenderMilestone;
