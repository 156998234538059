import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { _LOADINGSTATE } from "../components/Shared/constants";
import milestoneTierService from "../services/milestoneTier";
import { ErrorMessage } from "../components/Shared/messageBox";

interface milestoneTierState {
  isMilestoneTiersLoading: string;
  milestoneTiers: milestoneTier[];
}

const initialState: milestoneTierState = {
  isMilestoneTiersLoading: "",
  milestoneTiers: [],
};

export interface milestoneTier {
  TierId: number;
  Name: string;
}

export const getMilestoneTiers = createAsyncThunk(
  "getMilestoneTiers",
  async () => await milestoneTierService.getMilestoneTiers()
);

const milestoneTierSlice = createSlice({
  name: "milestoneTier",
  initialState: initialState,
  reducers: {},
  extraReducers: {
    [getMilestoneTiers.fulfilled.type]: (
      state: milestoneTierState,
      action: PayloadAction<milestoneTier[] | string>
    ) => {
      if (typeof action.payload === "string") {
        state.milestoneTiers = [];
      } else {
        state.milestoneTiers = action.payload;
      }
      state.isMilestoneTiersLoading = _LOADINGSTATE.fullfilled;
    },
    [getMilestoneTiers.pending.type]: (state: milestoneTierState) => {
      state.isMilestoneTiersLoading = _LOADINGSTATE.pending;
    },
    [getMilestoneTiers.rejected.type]: (state, { error }) => {
      ErrorMessage.show(
        "There was an error getting the milestone tiers. Please refresh the page and try again. If the issue persists please contact the tool administrator."
      );
    },
  },
});

export const { reducer, actions } = milestoneTierSlice;
