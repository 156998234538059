import React from "react";
import { useSelector } from "react-redux";
import {
  DatePicker,
  DayOfWeek,
  defaultDatePickerStrings,
} from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";
import moment from "moment";
import CustomeDateCalendar from "../../Shared/customeDateCalendar";
import { RootState } from "../../../store";
import { TimelineType } from "../constants";

const DatePickerWithCustomStyles = (props: {
  showMonthOnly?: boolean;
  minDate?: Date;
  considerFirstTrainOfMonth?: boolean;
  value: Date | undefined;
  formatDate?: (date?: Date) => string;
  className?: string;
  firstDayOfWeek?: DayOfWeek;
  placeholder?: string;
  ariaLabel: string;
  onChangeHandler: (date: Date | null | undefined) => void;
  disabled?: boolean;
  onKeyDownHandler?: (event: React.KeyboardEvent<Element>) => void;
  onParseDateFromStringHandler?: (dateStr: string) => Date | null;
  describedby?: string;
  id?: string;
  timelineType?: TimelineType;
}) => {
  const customTrainDates = useSelector(
    (state: RootState) => state.calendarReducer.CustomTrainDates
  );
  const microsoftHoliday = useSelector(
    (state: RootState) => state.calendarReducer.MicrosoftHolidays
  );
  const moments = useSelector(
    (state: RootState) => state.calendarReducer.Moments
  );
  const azureUpdateDeckDueDates = useSelector(
    (state: RootState) => state.calendarReducer.AzureUpdatesDeckDueDates
  );
  const calendarDates = useSelector(
    (state: RootState) => state.calendarReducer.CalendarDates
  );
  const onFormatDate = (date?: Date): string => {
    return !date ? "" : moment(date).format("MM/DD/YYYY");
  };
  const [
    isShowDateCalendar,
    { setTrue: showDateCalendar, setFalse: hideDateCalendar },
  ] = useBoolean(true);
  return (
    <DatePicker
      aria-describedby={props.describedby}
      parseDateFromString={props.onParseDateFromStringHandler}
      maxDate={new Date("12/31/9999")}
      minDate={props.minDate}
      allowTextInput={true}
      value={props.value}
      formatDate={props.formatDate ? props.formatDate : onFormatDate}
      className={props.className}
      firstDayOfWeek={
        props.firstDayOfWeek ? props.firstDayOfWeek : DayOfWeek.Sunday
      }
      styles={{ textField: { minWidth: "125px" } }}
      id={props.id}
      placeholder={props.placeholder ? props.placeholder : "Select a date..."}
      ariaLabel={props.ariaLabel ? props.ariaLabel : "Select a date..."}
      onMouseEnter={showDateCalendar}
      onKeyUp={showDateCalendar}
      disabled={props.disabled ? props.disabled : false}
      onKeyDown={props.onKeyDownHandler}
      strings={defaultDatePickerStrings}
      onSelectDate={(date: Date | null | undefined) => {
        props.onChangeHandler(date);
      }}
      calendarAs={() => (
        <CustomeDateCalendar
          isDayPickerVisible={props.showMonthOnly ? false : true}
          minDate={props.minDate}
          considerFirstTrainOfMonth={props.considerFirstTrainOfMonth || false}
          clickHandel={props.onChangeHandler}
          isShowCalendar={isShowDateCalendar}
          hideCalendar={hideDateCalendar}
          customTrainDates={customTrainDates}
          moments={moments}
          microsoftHoliday={microsoftHoliday}
          azureUpdateDeckDueDates={azureUpdateDeckDueDates}
          calendarDates={calendarDates}
          value={props.value}
          timelineType={props.timelineType}
        />
      )}
    />
  );
};

export default DatePickerWithCustomStyles;
