import httpClient from "./base";

const baseURL = "/SecurityPMM";
const getSecurityPMM = () => {
  return httpClient.get(baseURL);
};

const SecurityPMMService = {
  getSecurityPMM,
};

export default SecurityPMMService;
