import { useContext } from "react";
import "../../../Shared/css/timelineGrid.scss";
import "../index.scss";
import { DirectionalHint } from "@fluentui/react";
import { TooltipHost, ITooltipHostStyles } from "@fluentui/react/lib/Tooltip";
import { useId } from "@fluentui/react-hooks";
import moment from "moment";
import { formatCYDate, formatDate } from "../../../../utils/date";
import { isVoid, truncateText } from "../../../../utils/utils";
import { ModalType } from "../../../Shared/constants";
import { MilestoneStatusIcon } from "../../../AzureTimeline/NavBar";
import { modernWorkTimelineFeature } from "../../../../slice/modernWorkTimeline";
import { FeatureUtil } from "../../../ModernWorkFeature/featureCommon";
import { ModernWorkFeatureModalContext } from "../../index";

const calloutProps = { gapSpace: 0 };
const hostStyles: Partial<ITooltipHostStyles> = {
  root: { display: "inline-block" },
};

const FeatureCard = (props: {
  feature: modernWorkTimelineFeature;
  showWithPlannedProductionDates?: boolean;
}) => {
  const modernWorkFeatureModalContext = useContext(
    ModernWorkFeatureModalContext
  )!;

  const tooltipId = useId("featureCardTooltip");

  const featureTitle = !isVoid(props.feature.MarketingTitle)
    ? props.feature.MarketingTitle
    : !isVoid(props.feature.PublicTitle)
    ? props.feature.PublicTitle
    : !isVoid(props.feature.Title)
    ? props.feature.Title
    : "";

  return (
    <li style={{ listStyle: "none", float: "left" }}>
      <TooltipHost
        content={props.feature.PublicRoadmapStatus}
        id={tooltipId}
        calloutProps={calloutProps}
        styles={hostStyles}
        directionalHint={DirectionalHint.bottomAutoEdge}
      >
        {Math.abs(
          moment(new Date()).diff(moment(props.feature.Created)) /
            1000 /
            3600 /
            24 /
            30
        ) < 1 ? (
          <i
            className={"ms-Icon ms-Icon--Glimmer"}
            tabIndex={0}
            title="This is a new item"
          />
        ) : (
          <div className="spacer" />
        )}
        <div
          aria-describedby={tooltipId}
          className="grid-content-card-widget"
          key={props.feature.Id}
          role={"button"}
          onClick={(e) => {
            modernWorkFeatureModalContext.setModernWorkFeatureStateHandler({
              featureTitle: featureTitle,
              featureId: props.feature.Id,
              cchFeatureId: props.feature.CCHFeatureId,
              featureDisclosureDate: null,
              featurePublicDisclosureDate: null,
              deleteFeatureHistoryId: "",
              modalType: ModalType.Edit,
            });
            modernWorkFeatureModalContext.modalRef?.current?.click();
          }}
          onKeyDown={(e) => {
            if (e.keyCode === 13) {
              e.currentTarget.click();
            }
          }}
        >
          <div
            className="content-card"
            aria-label={
              "Feature title:" +
              featureTitle +
              " Feature status:" +
              props.feature.PublicRoadmapStatus
            }
            tabIndex={0}
          >
            <div>
              <span title={featureTitle}>
                <strong>
                  {truncateText(featureTitle, 52)}
                  {` `}
                  {props.feature.Tier === "1"
                    ? "(T1)"
                    : props.feature.Tier === "2"
                    ? "(T2)"
                    : props.feature.Tier === "3"
                    ? "(T3)"
                    : ""}
                </strong>
              </span>
            </div>
            <div title={props.feature.PMGContact}>
              PMM:{` `}
              {truncateText(props.feature.PMGContact, 23)}
            </div>
            {
              <>
                <div title={formatCYDate(props.feature.PreviewDate)}>
                  Preview:{` `}
                  {formatCYDate(props.feature.PreviewDate)}
                </div>
                <div title={formatCYDate(props.feature.GADate)}>
                  GA:{` `}
                  {formatCYDate(props.feature.GADate)}
                </div>
                <div title={formatDate(props.feature.PlannedProductionDate)}>
                  Planned production:{` `}
                  {formatDate(props.feature.PlannedProductionDate)}
                </div>
                <div
                  title={formatDate(props.feature.DisclosureDate)}
                  style={{ display: "flex" }}
                >
                  <div>
                    Disclosure:{` `}
                    {formatDate(props.feature.DisclosureDate)}
                  </div>
                </div>
              </>
            }
            <div className="edit-icon d-flex justify-content-between">
              <span className="d-flex align-items-end">
                {
                  MilestoneStatusIcon[
                    props.feature.TierId && props.feature.TierId !== 0 ? 4 : 5
                  ]
                }
              </span>
            </div>
          </div>
          <div
            className={`content-card-type ${FeatureUtil.Colors.feature(
              props.feature.PublicRoadmapStatus
            )}`}
          ></div>
        </div>
      </TooltipHost>
    </li>
  );
};

export default FeatureCard;
