import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { _LOADINGSTATE } from "../components/Shared/constants";
import graphProxyService from "../services/graphProxy";
import { ErrorMessage } from "../components/Shared/messageBox";

export interface graphState {
  graphUsers: GraphUser[];
  __isLoading: string;
}

const initialGraphState: graphState = {
  graphUsers: [],
  __isLoading: _LOADINGSTATE.fullfilled,
};

export interface GraphUser {
  DisplayName: string | null;
  Mail: string | null;
}

export const getGraphUsers = createAsyncThunk(
  "getGraphUsers",
  async (params: {
    filter: string;
    select: string;
    top: string;
    microsoftUser: boolean;
  }) => {
    if (params.filter.length < 2) {
      return [];
    }
    return (await graphProxyService.getGraphUsers(
      params.filter,
      params.select,
      params.top,
      params.microsoftUser
    )) as GraphUser[];
  }
);

const graphSlice = createSlice({
  name: "graphUsers",
  initialState: initialGraphState,
  reducers: {},
  extraReducers: {
    [getGraphUsers.fulfilled.type]: (state, { payload }) => {
      state.graphUsers = payload;
      state.__isLoading = _LOADINGSTATE.fullfilled;
    },
    [getGraphUsers.pending.type]: (state) => {
      state.__isLoading = _LOADINGSTATE.pending;
    },
    [getGraphUsers.rejected.type]: (state, { error }) => {
      ErrorMessage.show(
        "There was an error in getting the graph users. Please refresh the page and try again. If the issue persists please contact the tool administrator."
      );
    },
  },
});

export const { actions, reducer } = graphSlice;
