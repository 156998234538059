import { TimelineType } from "../components/Shared/constants";
import httpClient from "./base";

const baseURL = "/ModernWorkFeatureHistory";

const getModernWorkFeatureHistory = (id: number) => {
  let url = `${baseURL}?id=${id}&timelineType=${TimelineType.MW}`;
  return httpClient.get(url);
};

const deleteModernWorkFeatureHistory = (
  modernWorkFeatureHistoryIds: number[]
) => {
  let url = `${baseURL}?modernWorkFeatureHistoryIds=${encodeURIComponent(
    modernWorkFeatureHistoryIds.join(",")
  )}&timelineType=${TimelineType.MW}`;
  return httpClient.del(url);
};

const modernWorkFeatureHistoryService = {
  getModernWorkFeatureHistory,
  deleteModernWorkFeatureHistory,
};

export default modernWorkFeatureHistoryService;
