import httpClient from "./base";
import { TrainContent } from "../slice/traincontent";

const baseURL = "TrainContent";

const getTrainContent = (milestoneId: number) => {
  var url = `${baseURL}?milestoneId=` + milestoneId;
  return httpClient.get(url);
};

const postTrainContent = (params: TrainContent) => {
  var url = `${baseURL}`;
  return httpClient.post(url, params);
};

const putTrainContent = (params: TrainContent) => {
  var url = `${baseURL}`;
  return httpClient.put(url, params);
};

const traincontentService = {
  getTrainContent,
  postTrainContent,
  putTrainContent,
};

export default traincontentService;
