import httpClient from "./base";

const getAudiences = () => {
  var url = "Audience";
  return httpClient.get(url);
};

const audienceService = {
  getAudiences,
};

export default audienceService;
