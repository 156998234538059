import { useEffect, useState, useMemo } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { RootState, useAppDispatch } from "../../store";
import * as azureProductReducer from "../../slice/azureProduct";
import { Spinner, SpinnerSize } from "@fluentui/react";
import "./azureProducts.scss";
import Tooltip from "../Shared/FormInput/tooltip";
import { toExcel } from "../../utils/utils";
import {
  convertDateTimeToFormatedDateString,
  formatDate,
  formatDateTimeWithOutUtc,
} from "../../utils/date";
import useDocumentTitle from "../../utils/useDocumentTitle";

const AzureProduct = () => {
  const pendingColor = "#FFCD5A";

  const [isExportingExcel, setIsExportingExcel] = useState(false);
  const appDispatch = useAppDispatch();

  const location = useLocation();
  useEffect(() => {
    appDispatch(azureProductReducer.getAzureProduct());
  }, [appDispatch, location.pathname]);

  const { tableHeader, tableBody } = useSelector(
    (state: RootState) => state.azureProductReducer
  );

  const conversationMapping = useMemo(() => {
    if (tableHeader !== null) {
      let conversations = tableHeader.map((t) => {
        return t.Conversation.Name;
      });
      let distinctConversations = conversations.filter(
        (item, index) => conversations.indexOf(item) === index
      );
      let mappingResult = new Array(distinctConversations.length) as {
        name: string;
        colspan: number;
      }[];
      for (let i = 0; i < distinctConversations.length; i++) {
        let name = distinctConversations[i];
        let colspan = tableHeader.filter(
          (item) => item.Conversation.Name === name
        ).length;
        mappingResult.push({ name, colspan });
      }
      return mappingResult;
    } else {
      return null;
    }
  }, [tableHeader]);

  const trs = useMemo(() => {
    if (tableHeader !== null && tableBody !== null) {
      const lengthArray = [];
      for (let i = 0; i < tableHeader.length; i++) {
        lengthArray.push(
          tableBody.filter((b) => {
            return b.categoryName === tableHeader[i].Name;
          }).length ?? 0
        );
      }
      const maxLength = Math.max(...lengthArray);
      const restTrs = [];

      for (let i = 1; i < maxLength; i++) {
        const tds = [];
        const restTds =
          tableHeader &&
          tableHeader.map((cat) => {
            return (
              <td
                style={
                  tableBody &&
                  tableBody.filter((b) => {
                    return b.categoryId === cat.ACOMCategoryId;
                  })[i]?.product.ProductState === 0
                    ? {
                        backgroundColor: pendingColor,
                      }
                    : {}
                }
              >
                {" "}
                {tableBody &&
                  (tableBody.filter((b) => {
                    return b.categoryId === cat.ACOMCategoryId;
                  })[i] === undefined
                    ? ""
                    : tableBody.filter((b) => {
                        return b.categoryId === cat.ACOMCategoryId;
                      })[i].product.ProductName)}
              </td>
            );
          });
        restTds && tds.push(...restTds);
        restTrs.push(tds);
      }
      return restTrs;
    }
    return null;
  }, [tableHeader, tableBody]);

  const pendingCount = useMemo(() => {
    if (tableBody !== null) {
      const pendingProducts = tableBody
        .filter((b) => {
          return b.product.ProductState === 0;
        })
        .map((b) => b.product.ProductName);
      const distinctPendingProducts = pendingProducts.filter(
        (p, index) => pendingProducts.indexOf(p) === index
      );
      return distinctPendingProducts.length;
    }
    return 0;
  }, [tableBody]);

  const handleDownload = () => {
    if (!isExportingExcel) {
      setIsExportingExcel(true);
      const table = document.getElementById(
        "azureProduct-table-excel-download"
      )?.outerHTML;
      const result = table || "";
      if (result !== "") {
        toExcel(
          `azure-products-excel-${convertDateTimeToFormatedDateString(
            new Date()
          )}`,
          result
        );
      }
      setIsExportingExcel(false);
    }
  };

  useDocumentTitle("Cloud Marketing moments calendar product map");

  return (
    <div className="azureProduct">
      {tableHeader === null ? (
        <div className="azureProduct-center">
          <Spinner size={SpinnerSize.large} ariaLive="polite"></Spinner>
        </div>
      ) : (
        <>
          {" "}
          <div className="azureProduct-header">
            <div className="section-wrap text-center AzureProduct-export">
              <div>
                <strong>Export to:</strong>
              </div>
              <div>
                <a
                  href="#!"
                  aria-label="Excel, Export to Excel"
                  onClick={handleDownload}
                  role="button"
                >
                  <span style={{ lineHeight: "32px" }}>Excel </span>
                  {isExportingExcel ? (
                    <Spinner size={SpinnerSize.small} ariaLive="polite" />
                  ) : null}
                </a>
              </div>
            </div>
            <div className="azureProduct-count">
              <div>Pending products: </div>
              <div
                style={{
                  fontSize: "25px",
                  color: pendingColor,
                  fontWeight: "bold",
                }}
              >
                {pendingCount}
              </div>
            </div>
          </div>
          <div className="azureProduct-tableContainer">
            <table className="azureProduct-table">
              <thead>
                <tr>
                  <th>CSA</th>
                  {conversationMapping &&
                    conversationMapping.map((m) => {
                      return (
                        <th
                          className="azureProduct-th-conversation"
                          colSpan={m.colspan}
                        >
                          {m.name}
                        </th>
                      );
                    })}
                </tr>
                <tr>
                  <th>Category</th>
                  {tableHeader.map((cat) => {
                    return (
                      <th className="azureProduct-th-category">{cat.Name}</th>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th className="azureProduct-th-product-header">Products</th>
                  {tableHeader.map((cat) => {
                    return (
                      <th className="azureProduct-th-product-list">
                        {tableBody &&
                          tableBody
                            .filter((item) => {
                              return item.categoryId === cat.ACOMCategoryId;
                            })
                            .map((p) => {
                              return (
                                <Tooltip
                                  dynamicContent={
                                    "<strong>Product Id:</strong> " +
                                    p.product.ProductId +
                                    "<br />" +
                                    "<strong>Product Name:</strong> " +
                                    p.product.ProductName +
                                    "<br />" +
                                    "<strong>Created By:</strong> " +
                                    p.product.CreatedBy +
                                    "<br />" +
                                    "<strong>Created Date:</strong> " +
                                    formatDateTimeWithOutUtc(
                                      p.product.CreatedDate
                                    ) +
                                    "<br />" +
                                    "<strong>Product Created Date:</strong> " +
                                    formatDate(p.product.ProductCreatedDate) +
                                    "<br />" +
                                    "<strong>Modified By:</strong> " +
                                    p.product.ModifiedBy +
                                    "<br />" +
                                    "<strong>Modified Date:</strong> " +
                                    formatDateTimeWithOutUtc(
                                      p.product.ModifiedDate
                                    )
                                  }
                                >
                                  <li
                                    style={
                                      p.product.ProductState === 0
                                        ? { backgroundColor: pendingColor }
                                        : {}
                                    }
                                  >
                                    {p.product.ProductName}
                                  </li>
                                </Tooltip>
                              );
                            })}
                      </th>
                    );
                  })}
                </tr>
              </tbody>
            </table>
            <table
              id="azureProduct-table-excel-download"
              className="azureProduct-table"
              style={{ display: "none" }}
            >
              <thead>
                <tr>
                  <th>CSA</th>
                  {conversationMapping &&
                    conversationMapping.map((m) => {
                      return (
                        <th
                          className="azureProduct-th-conversation"
                          colSpan={m.colspan}
                        >
                          {m.name}
                        </th>
                      );
                    })}
                </tr>
                <tr>
                  <th>Category</th>
                  {tableHeader.map((cat) => {
                    return (
                      <th className="azureProduct-th-category">{cat.Name}</th>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th
                    className="azureProduct-th-product-header"
                    rowSpan={(trs?.length ?? 0) + 1}
                  >
                    Products
                  </th>
                  {tableHeader.map((cat) => {
                    return (
                      <td
                        style={
                          tableBody &&
                          tableBody.filter((b) => {
                            return b.categoryId === cat.ACOMCategoryId;
                          })[0]?.product.ProductState === 0
                            ? {
                                backgroundColor: pendingColor,
                              }
                            : {}
                        }
                      >
                        {" "}
                        {tableBody &&
                          (tableBody.filter((b) => {
                            return b.categoryId === cat.ACOMCategoryId;
                          })[0] === undefined
                            ? ""
                            : tableBody.filter((b) => {
                                return b.categoryId === cat.ACOMCategoryId;
                              })[0].product.ProductName)}
                      </td>
                    );
                  })}
                </tr>
                {trs &&
                  trs.map((row) => {
                    return <tr>{row.map((d) => d)}</tr>;
                  })}
              </tbody>
            </table>
          </div>
        </>
      )}
    </div>
  );
};

export default AzureProduct;
