import moment from "moment";
import "./index.scss";

const Footer = () => {
  return (
    <footer>
      <div>
        <ul>
          <li>
            <a href="https://go.microsoft.com/fwlink/?LinkId=521839">Privacy</a>
          </li>
          <li>
            <a href="https://go.microsoft.com/fwlink/?linkid=2259814">
              Consumer Health Privacy
            </a>
          </li>
          <li>
            <a href="https://go.microsoft.com/fwlink/?LinkID=206977">
              Terms of use
            </a>
          </li>
          <li>© Microsoft {moment(new Date()).utc().format("YYYY")}</li>
        </ul>
      </div>
    </footer>
  );
};

export default Footer;
