import { RefObject } from "@fluentui/react";
import React, { Dispatch } from "react";
import { CalendarEvent } from "./Calendar/calendarEvent";

interface CalendarData {
  currentDates: { currentStartDate: Date; currentEndDate: Date };
  changeCurrentDates: Dispatch<
    React.SetStateAction<{ currentStartDate: Date; currentEndDate: Date }>
  >;
  registeredEvents: CalendarEvent[] | undefined;
  currentSelectedEventId: number | null;
  getModernWorkSecurityEventCalendarMomentList: () => void;
  OpenDetail: (a: boolean) => void;
  isOpen: boolean;
  modalRef: React.RefObject<HTMLButtonElement> | null;
}

const CalendarContext = React.createContext({} as CalendarData);

export { CalendarContext };
export type { CalendarData };
