import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Breadcrumb,
  Dropdown,
  IBreadcrumbItem,
  Spinner,
  SpinnerSize,
} from "@fluentui/react";
import {
  transferToUtcDate,
  convertDateTimeToFormatedDateString,
} from "../../utils/date";
import { PrimaryButton } from "@fluentui/react/lib/Button";
import "./pptExport.scss";
import moment from "moment";
import { breadcrumbStyles, dropdownStylesProps } from "../Shared/styleProps";
import httpClient from "../../services/base";
import { SuccessMessage } from "../Shared/messageBox";
import DatePickerWithLabelAndError from "../Shared/FormInput/datepickerWithLabelAndError";
import useDocumentTitle from "../../utils/useDocumentTitle";

const PPTExport = () => {
  useDocumentTitle(
    "Cloud Marketing moments calendar export tool - Cloud Marketing moments calendar"
  );
  const [disabledFlag, setDisabledFlag] = useState(false);
  const solutionAreaColor: { [key: string]: string } = {
    "Business Applications": "C00002",
    "Data & AI": "005AA1",
    "Digital & Application Innovation": "005AA1",
    Infrastructure: "005AA1",
    "Microsoft Cloud & Industry": "BF4901",
    "Modern Work": "008300",
    Security: "6F31A0",
  };
  const getPayloadByInputs = (
    exportType: string,
    startDate: Date | undefined,
    endDate: Date | undefined,
    SA?: string,
    PF?: string[]
  ) => {
    switch (exportType) {
      case "MomentbySolutionArea":
        if (!startDate || !endDate || !SA || !PF) {
          return undefined;
        }
        return {
          solutionAreaMapping: [
            {
              solutionAreaName: SA,

              solutionAreaOrder: 0,

              solutionAreaColor: solutionAreaColor[SA],
            },
          ],

          requestReportType: "MomentbySolutionArea",

          selectedSolutionArea: SA,

          selectedProductFamily: PF.join(","),

          selectedStartDate: convertDateTimeToFormatedDateString(startDate),

          selectedEndDate: convertDateTimeToFormatedDateString(endDate),
        };
        break;
      case "TopCloudMarketingMoment":
        if (!startDate || !endDate) {
          return undefined;
        }
        return {
          solutionAreaMapping: [
            {
              solutionAreaName: "Azure",
              solutionAreaOrder: "0",
              solutionAreaColor: "005AA1",
            },
            {
              solutionAreaName: "Dynamic 365",
              solutionAreaOrder: "1",
              solutionAreaColor: "C00000",
            },
            {
              solutionAreaName: "Power Platform",
              solutionAreaOrder: "2",
              solutionAreaColor: "FF0D0D",
            },
            {
              solutionAreaName: "Industry",
              solutionAreaOrder: "3",
              solutionAreaColor: "FF6600",
            },
            {
              solutionAreaName: "Microsoft 365",
              solutionAreaOrder: "4",
              solutionAreaColor: "44A602",
            },
            {
              solutionAreaName: "Teams",
              solutionAreaOrder: "5",
              solutionAreaColor: "009900",
            },
            {
              solutionAreaName: "Security",
              solutionAreaOrder: "6",
              solutionAreaColor: "7030A0",
            },
          ],
          requestReportType: "TopCloudMarketingMoment",
          selectedSolutionArea: "",
          selectedProductFamily: "",
          selectedStartDate: convertDateTimeToFormatedDateString(startDate),
          selectedEndDate: convertDateTimeToFormatedDateString(endDate),
        };
        break;
      case "PRSupportedMoment":
        if (!startDate || !endDate) {
          return undefined;
        }
        return {
          solutionAreaMapping: [
            {
              solutionAreaName: "Azure",
              solutionAreaOrder: "0",
              solutionAreaColor: "005AA1",
            },
            {
              solutionAreaName: "Dynamic 365",
              solutionAreaOrder: "1",
              solutionAreaColor: "C00000",
            },
            {
              solutionAreaName: "Power Platform",
              solutionAreaOrder: "2",
              solutionAreaColor: "FF0D0D",
            },
            {
              solutionAreaName: "Industry",
              solutionAreaOrder: "3",
              solutionAreaColor: "FF6600",
            },
            {
              solutionAreaName: "Microsoft 365",
              solutionAreaOrder: "4",
              solutionAreaColor: "44A602",
            },
            {
              solutionAreaName: "Teams",
              solutionAreaOrder: "5",
              solutionAreaColor: "009900",
            },
            {
              solutionAreaName: "Security",
              solutionAreaOrder: "6",
              solutionAreaColor: "7030A0",
            },
          ],
          requestReportType: "PRSupportedMoment",
          selectedSolutionArea: "",
          selectedProductFamily: "",
          selectedStartDate: convertDateTimeToFormatedDateString(startDate),
          selectedEndDate: convertDateTimeToFormatedDateString(endDate),
        };
        break;
      case "NewsZoneMoment":
        if (!startDate || !endDate) {
          return undefined;
        }
        return {
          solutionAreaMapping: [
            {
              solutionAreaName: "Azure",
              solutionAreaOrder: "0",
              solutionAreaColor: "005AA1",
            },
            {
              solutionAreaName: "BizApps",
              solutionAreaOrder: "1",
              solutionAreaColor: "C00000",
            },
            {
              solutionAreaName: "Industry",
              solutionAreaOrder: "2",
              solutionAreaColor: "FF6600",
            },
            {
              solutionAreaName: "Modern Work",
              solutionAreaOrder: "3",
              solutionAreaColor: "44A602",
            },
            {
              solutionAreaName: "Security",
              solutionAreaOrder: "4",
              solutionAreaColor: "7030A0",
            },
          ],
          requestReportType: "NewsZoneMoment",
          selectedSolutionArea: "",
          selectedProductFamily: "",
          selectedStartDate: convertDateTimeToFormatedDateString(startDate),
          selectedEndDate: convertDateTimeToFormatedDateString(endDate),
        };
        break;
      default:
        return undefined;
        break;
    }
  };
  const solutionAreaProductFamily: { [key: string]: string[] } = {
    "Business Applications": [
      "(BA) Dynamics 365;Dynamics 365",
      "(BA) Power Platform;Power Platform",
    ],
    "Data & AI": [
      "(DA) Data & AI;Data & AI",
      "(DA) Customer Success;Customer Success",
    ],
    "Digital & Application Innovation": [
      "(DI) Digital & Application Innovation;Digital & Application Innovation",
      "(DI) Customer Success;Customer Success",
    ],
    Infrastructure: [
      "(IF) Infrastructure;Infrastructure",
      "(IF) Customer Success;Customer Success",
    ],
    "Microsoft Cloud & Industry": [
      "(MI) Cloud for Financial Services;Cloud for Financial Services",
      "(MI) Cloud for Healthcare;Cloud for Healthcare",
      "(MI) Cloud for Manufacturing;Cloud for Manufacturing",
      "(MI) Cloud for Nonprofit;Cloud for Nonprofit",
      "(MI) Cloud for Retail;Cloud for Retail",
      "(MI) Cloud for Sustainability;Cloud for Sustainability",
    ],

    "Modern Work": [
      "Microsoft Copilot (Microsoft 365);Microsoft Copilot (Microsoft 365)",
      "(MW) Microsoft Teams;Microsoft Teams",
      "(MW) Microsoft 365 apps;Microsoft 365 apps",
      "(MW) Microsoft Viva;Viva",
      "(MW) Windows;Windows",
      "Microsoft Intune;Microsoft Intune",
      "Microsoft 365 - Other;Microsoft 365 - Other",
    ],
    Security: [
      "(SE) Compliance;Compliance",
      "(SE) Identity;Identity",
      "(SE) Management;Management",
      "(SE) Privacy;Privacy",
      "(SE) Security;Security",
    ],
  };

  const minDate = new Date(2021, 0, 1);
  const today = new Date();
  const currentDate = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate()
  );
  const currentDatePlusThreeMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() + 3,
    currentDate.getDate() - 1
  );
  const [momentsSAState, setMomentsSAState] = useState({
    startDate: currentDate,
    endDate: currentDatePlusThreeMonth,
    SA: undefined,
    PFOptions: [],
    PF: [],
  } as {
    startDate: Date | undefined;
    endDate: Date | undefined;
    SA: string | undefined;
    PFOptions: string[];
    PF: string[];
  });
  const [MWQuarterState, setMWQuarterState] = useState({
    startDate: currentDate,
    endDate: currentDatePlusThreeMonth,
    PF: [],
    PFOptions: [
      "Microsoft Copilot (Microsoft 365);Microsoft Copilot (Microsoft 365)",
      "(MW) Microsoft Teams;Microsoft Teams",
      "(MW) Microsoft 365 apps;Microsoft 365 apps",
      "(MW) Microsoft Viva;Viva",
      "(MW) Windows;Windows",
      "Microsoft Intune;Microsoft Intune",
      "Microsoft 365 - Other;Microsoft 365 - Other",
    ],
  } as { startDate: Date | undefined; endDate: Date | undefined; PF: string[]; PFOptions: string[] });
  const [MWState, setMWState] = useState({
    startDate: currentDate,
    endDate: currentDatePlusThreeMonth,
    template: undefined,
  } as { startDate: Date | undefined; endDate: Date | undefined; template: number | undefined });

  const [NZMomentState, setNZMomentState] = useState({
    startDate: currentDate,
    endDate: currentDatePlusThreeMonth,
  } as { startDate: Date | undefined; endDate: Date | undefined });
  const [CMMomentsState, setCMMomentsState] = useState({
    startDate: currentDate,
    endDate: currentDatePlusThreeMonth,
  } as { startDate: Date | undefined; endDate: Date | undefined });
  const [PRMomentsState, setPRMomentsState] = useState({
    startDate: currentDate,
    endDate: currentDatePlusThreeMonth,
  } as { startDate: Date | undefined; endDate: Date | undefined });
  const [spinnerFlag, setSpinnerFlag] = useState({
    momentsSA: false,
    cmmoments: false,
    prmoments: false,
    nzmoments: false,
    mw: false,
    MWQuarter: false,
  });

  const navigate = useNavigate();

  const itemsWithHref: IBreadcrumbItem[] = [
    {
      text: " < Cloud Marketing moments calendar",
      key: "f1",
      onClick: () => {
        navigate("/");
      },
    },
    {
      text: "Export tool",
      key: "f2",
      href: "#!",
      isCurrentItem: true,
    },
  ];

  return (
    <>
      <header className="main-header">
        <Breadcrumb
          className="main-breadcrumb"
          items={itemsWithHref}
          maxDisplayedItems={3}
          ariaLabel="Breadcrumb"
          overflowAriaLabel="More links"
          styles={breadcrumbStyles}
          dividerAs={() => {
            return <span> - </span>;
          }}
        />
        <h1 className="main-header-title">Export tool</h1>
      </header>
      <div className="pptExport-header-container">
        <div className="main-header-title fw-bold">
          Cloud Marketing moments calendar export tool
        </div>
      </div>
      <div className="pptExport-container">
        <div className="template-container">
          <h4 className="template-title">By solution area</h4>
          <p className="template-description">Top moments and disclosures</p>
          <div className="template-input-container">
            <div className="template-datepicker-container">
              <div>
                <DatePickerWithLabelAndError
                  label="Start date"
                  minDate={minDate}
                  value={momentsSAState.startDate}
                  onSelectDate={(date: Date | null | undefined) => {
                    if (
                      date &&
                      momentsSAState.endDate &&
                      date <= momentsSAState.endDate
                    ) {
                      setMomentsSAState((state) => {
                        return {
                          ...state,
                          startDate: date,
                        };
                      });
                    } else {
                      setMomentsSAState((state) => {
                        return {
                          ...state,
                          startDate: currentDate,
                        };
                      });
                    }
                  }}
                  onFormatDate={(date?: Date): string => {
                    return !date ? "" : moment(date).format("MM/DD/YYYY");
                  }}
                  id="momentsSA_startDate"
                  error={
                    momentsSAState.startDate ? "" : "Start date is required"
                  }
                />
              </div>
              <div>
                <DatePickerWithLabelAndError
                  label="End date"
                  minDate={momentsSAState.startDate}
                  value={momentsSAState.endDate}
                  onSelectDate={(date: Date | null | undefined) => {
                    if (
                      date &&
                      momentsSAState.startDate &&
                      date >= momentsSAState.startDate
                    ) {
                      setMomentsSAState((state) => {
                        return {
                          ...state,
                          endDate: date,
                        };
                      });
                    } else {
                      setMomentsSAState((state) => {
                        return {
                          ...state,
                          endDate: undefined,
                        };
                      });
                    }
                  }}
                  onFormatDate={(date?: Date): string => {
                    return !date ? "" : moment(date).format("MM/DD/YYYY");
                  }}
                  id="momentsSA_endDate"
                  error={momentsSAState.endDate ? "" : "End date is required"}
                />
              </div>
            </div>
            <div className="solution-area-container">
              <div className="grow-2">
                <Dropdown
                  placeholder="Select a solution area"
                  styles={dropdownStylesProps}
                  options={Object.keys(solutionAreaProductFamily).map((k) => {
                    return { key: k, text: k };
                  })}
                  selectedKey={momentsSAState.SA}
                  required
                  onChange={(event, option) => {
                    if (option) {
                      setMomentsSAState((state) => {
                        return {
                          ...state,
                          SA: option.key as string,
                          PFOptions:
                            solutionAreaProductFamily[option.key as string],
                          PF: [],
                        };
                      });
                    }
                  }}
                />
              </div>
              <div className="grow-3">
                <Dropdown
                  placeholder="Select a product family"
                  multiSelect
                  required
                  styles={dropdownStylesProps}
                  options={
                    momentsSAState.PFOptions &&
                    momentsSAState.PFOptions.length > 0
                      ? momentsSAState.PFOptions.map((k) => {
                          let kv = k.split(";");
                          return { key: kv[0], text: kv[1] };
                        })
                      : []
                  }
                  selectedKeys={momentsSAState.PF}
                  onChange={(event, option) => {
                    if (option) {
                      const selectedKeys = option?.selected
                        ? [...momentsSAState.PF, option.key as string]
                        : momentsSAState.PF.filter(
                            (key) => key !== option?.key
                          );
                      setMomentsSAState((state) => {
                        return { ...momentsSAState, PF: selectedKeys };
                      });
                    }
                  }}
                />
              </div>
            </div>
            <div>
              <PrimaryButton
                style={{ width: "150px" }}
                disabled={
                  !(
                    momentsSAState.startDate &&
                    momentsSAState.endDate &&
                    (momentsSAState.SA ? true : false) &&
                    momentsSAState.startDate < momentsSAState.endDate &&
                    momentsSAState.PF.length > 0
                  ) || disabledFlag
                }
                onClick={(event) => {
                  event.preventDefault();
                  setDisabledFlag(true);
                  setSpinnerFlag((state) => {
                    return { ...state, momentsSA: true };
                  });
                  const data = getPayloadByInputs(
                    "MomentbySolutionArea",
                    momentsSAState.startDate,
                    momentsSAState.endDate,
                    momentsSAState.SA,
                    momentsSAState.PF
                  );
                  if (!data) {
                    return;
                  }
                  httpClient
                    .postBlob("PPTExport/Export", data)
                    .then((response: any) => {
                      if (response && response.size > 0) {
                        const aTag = document.createElement("a");
                        aTag.download = `MomentsbySolutionArea_${convertDateTimeToFormatedDateString(
                          transferToUtcDate(new Date())
                        )}_${new Date()
                          .getTime()
                          .toString()
                          .substring(6)}.pptx`;
                        aTag.href = URL.createObjectURL(response);
                        aTag.click();
                        URL.revokeObjectURL(aTag.href);
                        aTag.remove();
                      } else {
                        SuccessMessage.show(
                          "There is no valid data to export."
                        );
                      }
                      setDisabledFlag(false);
                      setSpinnerFlag((state) => {
                        return { ...state, momentsSA: false };
                      });
                    });
                }}
              >
                Export
              </PrimaryButton>
              {spinnerFlag.momentsSA ? (
                <Spinner
                  size={SpinnerSize.large}
                  style={{
                    display: "inline",
                    marginLeft: "10px",
                    position: "absolute",
                  }}
                />
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className="momentssa-template-sample"></div>
        </div>
        <div className="template-container">
          <h4 className="template-title">All solution areas</h4>
          <p className="template-description">Top moments and disclosures</p>
          <div className="template-input-container">
            <div className="template-datepicker-container">
              <div>
                <DatePickerWithLabelAndError
                  label="Start month"
                  minDate={minDate}
                  value={CMMomentsState.startDate}
                  onSelectDate={(date: Date | null | undefined) => {
                    if (
                      date &&
                      CMMomentsState.endDate &&
                      date <= CMMomentsState.endDate
                    ) {
                      setCMMomentsState((state) => {
                        return {
                          ...state,
                          startDate: new Date(
                            date.getFullYear(),
                            date.getMonth(),
                            1
                          ),
                        };
                      });
                    } else {
                      setCMMomentsState((state) => {
                        return {
                          ...state,
                          startDate: new Date(
                            currentDate.getFullYear(),
                            currentDate.getMonth(),
                            1
                          ),
                        };
                      });
                    }
                  }}
                  onFormatDate={(date?: Date): string => {
                    return !date ? "" : moment(date).format("MMM YYYY");
                  }}
                  id="cmmoments_startDate"
                  error={
                    CMMomentsState.startDate ? "" : "Start month is required"
                  }
                  showMonthOnly={true}
                />
              </div>
              <div>
                <DatePickerWithLabelAndError
                  label="End month"
                  minDate={CMMomentsState.startDate}
                  value={CMMomentsState.endDate}
                  onSelectDate={(date: Date | null | undefined) => {
                    if (
                      date &&
                      CMMomentsState.startDate &&
                      date >= CMMomentsState.startDate
                    ) {
                      setCMMomentsState((state) => {
                        return {
                          ...state,
                          endDate: new Date(
                            date.getFullYear(),
                            date.getMonth() + 1,
                            0
                          ),
                        };
                      });
                    } else {
                      setCMMomentsState((state) => {
                        return {
                          ...state,
                          endDate: undefined,
                        };
                      });
                    }
                  }}
                  onFormatDate={(date?: Date): string => {
                    return !date ? "" : moment(date).format("MMM YYYY");
                  }}
                  id="cmmoments_endDate"
                  error={CMMomentsState.endDate ? "" : "End month is required"}
                  showMonthOnly={true}
                />
              </div>
            </div>
            <div>
              <PrimaryButton
                style={{ width: "150px" }}
                disabled={
                  !(
                    CMMomentsState.startDate &&
                    CMMomentsState.endDate &&
                    CMMomentsState.startDate < CMMomentsState.endDate
                  ) || disabledFlag
                }
                onClick={(event) => {
                  event.preventDefault();
                  setDisabledFlag(true);
                  setSpinnerFlag((state) => {
                    return { ...state, cmmoments: true };
                  });
                  const data = getPayloadByInputs(
                    "TopCloudMarketingMoment",
                    CMMomentsState.startDate,
                    CMMomentsState.endDate
                  );
                  if (!data) {
                    return;
                  }
                  httpClient
                    .postBlob("PPTExport/Export", data)
                    .then((response: any) => {
                      if (response && response.size > 0) {
                        const aTag = document.createElement("a");
                        aTag.download = `TopCloudMarketingMoments_${convertDateTimeToFormatedDateString(
                          transferToUtcDate(new Date())
                        )}_${new Date()
                          .getTime()
                          .toString()
                          .substring(6)}.pptx`;
                        aTag.href = URL.createObjectURL(response);
                        aTag.click();
                        URL.revokeObjectURL(aTag.href);
                        aTag.remove();
                      } else {
                        SuccessMessage.show(
                          "There is no valid data to export."
                        );
                      }
                      setDisabledFlag(false);
                      setSpinnerFlag((state) => {
                        return { ...state, cmmoments: false };
                      });
                    });
                }}
              >
                Export
              </PrimaryButton>
              {spinnerFlag.cmmoments ? (
                <Spinner
                  size={SpinnerSize.large}
                  style={{
                    display: "inline",
                    marginLeft: "10px",
                    position: "absolute",
                  }}
                />
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className="cmmoments-template-sample"></div>
        </div>
        <div className="template-container">
          <h4 className="template-title">PR supported moments</h4>
          <p className="template-description">Moments with PR support</p>
          <div className="template-input-container">
            <div className="template-datepicker-container">
              <div>
                <DatePickerWithLabelAndError
                  label="Start month"
                  minDate={minDate}
                  value={PRMomentsState.startDate}
                  onSelectDate={(date: Date | null | undefined) => {
                    if (
                      date &&
                      PRMomentsState.endDate &&
                      date <= PRMomentsState.endDate
                    ) {
                      setPRMomentsState((state) => {
                        return {
                          ...state,
                          startDate: new Date(
                            date.getFullYear(),
                            date.getMonth(),
                            1
                          ),
                        };
                      });
                    } else {
                      setPRMomentsState((state) => {
                        return {
                          ...state,
                          startDate: new Date(
                            currentDate.getFullYear(),
                            currentDate.getMonth(),
                            1
                          ),
                        };
                      });
                    }
                  }}
                  onFormatDate={(date?: Date): string => {
                    return !date ? "" : moment(date).format("MMM YYYY");
                  }}
                  id="prmoments_startDate"
                  error={
                    PRMomentsState.startDate ? "" : "Start month is required"
                  }
                  showMonthOnly={true}
                />
              </div>
              <div>
                <DatePickerWithLabelAndError
                  label="End month"
                  minDate={PRMomentsState.startDate}
                  value={PRMomentsState.endDate}
                  onSelectDate={(date: Date | null | undefined) => {
                    if (
                      date &&
                      PRMomentsState.startDate &&
                      date >= PRMomentsState.startDate
                    ) {
                      setPRMomentsState((state) => {
                        return {
                          ...state,
                          endDate: new Date(
                            date.getFullYear(),
                            date.getMonth() + 1,
                            0
                          ),
                        };
                      });
                    } else {
                      setPRMomentsState((state) => {
                        return { ...state, endDate: undefined };
                      });
                    }
                  }}
                  onFormatDate={(date?: Date): string => {
                    return !date ? "" : moment(date).format("MMM YYYY");
                  }}
                  id="prmoments_endDate"
                  error={PRMomentsState.endDate ? "" : "End month is required"}
                  showMonthOnly={true}
                />
              </div>
            </div>
            <div>
              <PrimaryButton
                style={{ width: "150px" }}
                disabled={
                  !(
                    PRMomentsState.startDate &&
                    PRMomentsState.endDate &&
                    PRMomentsState.startDate < PRMomentsState.endDate
                  ) || disabledFlag
                }
                onClick={(event) => {
                  event.preventDefault();
                  setDisabledFlag(true);
                  setSpinnerFlag((state) => {
                    return { ...state, prmoments: true };
                  });
                  const data = getPayloadByInputs(
                    "PRSupportedMoment",
                    PRMomentsState.startDate,
                    PRMomentsState.endDate
                  );
                  if (!data) {
                    return;
                  }
                  httpClient
                    .postBlob("PPTExport/Export", data)
                    .then((response: any) => {
                      if (response && response.size > 0) {
                        const aTag = document.createElement("a");
                        aTag.download = `PRSupportedMoments_${convertDateTimeToFormatedDateString(
                          transferToUtcDate(new Date())
                        )}_${new Date()
                          .getTime()
                          .toString()
                          .substring(6)}.pptx`;
                        aTag.href = URL.createObjectURL(response);
                        aTag.click();
                        URL.revokeObjectURL(aTag.href);
                        aTag.remove();
                      } else {
                        SuccessMessage.show(
                          "There is no valid data to export."
                        );
                      }
                      setDisabledFlag(false);
                      setSpinnerFlag((state) => {
                        return { ...state, prmoments: false };
                      });
                    });
                }}
              >
                Export
              </PrimaryButton>
              {spinnerFlag.prmoments ? (
                <Spinner
                  size={SpinnerSize.large}
                  style={{
                    display: "inline",
                    marginLeft: "10px",
                    position: "absolute",
                  }}
                />
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className="prmoments-template-sample"></div>
        </div>
        <div className="template-container">
          <h4 className="template-title">Cloud Marketing News Zone</h4>
          <p className="template-description">Top moments and disclosures</p>
          <div className="template-input-container">
            <div className="template-datepicker-container">
              <div>
                <DatePickerWithLabelAndError
                  label="Start month"
                  minDate={minDate}
                  value={NZMomentState.startDate}
                  onSelectDate={(date: Date | null | undefined) => {
                    if (
                      date &&
                      NZMomentState.endDate &&
                      date <= NZMomentState.endDate
                    ) {
                      setNZMomentState((state) => {
                        return {
                          ...state,
                          startDate: new Date(
                            date.getFullYear(),
                            date.getMonth(),
                            1
                          ),
                        };
                      });
                    } else {
                      setNZMomentState((state) => {
                        return {
                          ...state,
                          startDate: new Date(
                            currentDate.getFullYear(),
                            currentDate.getMonth(),
                            1
                          ),
                        };
                      });
                    }
                  }}
                  onFormatDate={(date?: Date): string => {
                    return !date ? "" : moment(date).format("MMM YYYY");
                  }}
                  id="nzmoments_startDate"
                  error={
                    NZMomentState.startDate ? "" : "Start month is required"
                  }
                  showMonthOnly={true}
                />
              </div>
              <div>
                <DatePickerWithLabelAndError
                  label="End month"
                  minDate={NZMomentState.startDate}
                  value={NZMomentState.endDate}
                  onSelectDate={(date: Date | null | undefined) => {
                    if (
                      date &&
                      NZMomentState.startDate &&
                      date >= NZMomentState.startDate
                    ) {
                      setNZMomentState((state) => {
                        return {
                          ...state,
                          endDate: new Date(
                            date.getFullYear(),
                            date.getMonth() + 1,
                            0
                          ),
                        };
                      });
                    } else {
                      setNZMomentState((state) => {
                        return {
                          ...state,
                          endDate: undefined,
                        };
                      });
                    }
                  }}
                  onFormatDate={(date?: Date): string => {
                    return !date ? "" : moment(date).format("MMM YYYY");
                  }}
                  id="nzmoments_endDate"
                  error={NZMomentState.endDate ? "" : "End month is required"}
                  showMonthOnly={true}
                />
              </div>
            </div>
            <div>
              <PrimaryButton
                style={{ width: "150px" }}
                disabled={
                  !(
                    NZMomentState.startDate &&
                    NZMomentState.endDate &&
                    NZMomentState.startDate < NZMomentState.endDate
                  ) || disabledFlag
                }
                onClick={(event) => {
                  event.preventDefault();
                  setDisabledFlag(true);
                  setSpinnerFlag((state) => {
                    return { ...state, nzmoments: true };
                  });
                  const data = getPayloadByInputs(
                    "NewsZoneMoment",
                    NZMomentState.startDate,
                    NZMomentState.endDate
                  );
                  if (!data) {
                    return;
                  }
                  httpClient
                    .postBlob("PPTExport/Export", data)
                    .then((response: any) => {
                      if (response && response.size > 0) {
                        const aTag = document.createElement("a");
                        aTag.download = `NewsZoneMoments_${convertDateTimeToFormatedDateString(
                          transferToUtcDate(new Date())
                        )}_${new Date()
                          .getTime()
                          .toString()
                          .substring(6)}.pptx`;
                        aTag.href = URL.createObjectURL(response);
                        aTag.click();
                        URL.revokeObjectURL(aTag.href);
                        aTag.remove();
                      } else {
                        SuccessMessage.show(
                          "There is no valid data to export."
                        );
                      }
                      setDisabledFlag(false);
                      setSpinnerFlag((state) => {
                        return { ...state, nzmoments: false };
                      });
                    });
                }}
              >
                Export
              </PrimaryButton>
              {spinnerFlag.nzmoments ? (
                <Spinner
                  size={SpinnerSize.large}
                  style={{
                    display: "inline",
                    marginLeft: "10px",
                    position: "absolute",
                  }}
                />
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className="nzmoments-template-sample"></div>
        </div>
        <div className="template-container">
          <h4 className="template-title">Modern Work CSA</h4>
          <p className="template-description">Top tier features</p>
          <div className="template-input-container">
            <div className="template-datepicker-container">
              <div>
                <DatePickerWithLabelAndError
                  label="Start date"
                  minDate={minDate}
                  value={MWState.startDate}
                  onSelectDate={(date: Date | null | undefined) => {
                    if (date && MWState.endDate && date <= MWState.endDate) {
                      setMWState((state) => {
                        return {
                          ...state,
                          startDate: date,
                        };
                      });
                    } else {
                      setMWState((state) => {
                        return {
                          ...state,
                          startDate: currentDate,
                        };
                      });
                    }
                  }}
                  id="mw_startDate"
                  error={MWState.startDate ? "" : "Start month is required"}
                />
              </div>
              <div>
                <DatePickerWithLabelAndError
                  label="End date"
                  minDate={MWState.startDate}
                  value={MWState.endDate}
                  onSelectDate={(date: Date | null | undefined) => {
                    if (
                      date &&
                      MWState.startDate &&
                      date >= MWState.startDate
                    ) {
                      setMWState((state) => {
                        return {
                          ...state,
                          endDate: date,
                        };
                      });
                    } else {
                      setMWState((state) => {
                        return {
                          ...state,
                          endDate: undefined,
                        };
                      });
                    }
                  }}
                  id="mw_endDate"
                  error={MWState.endDate ? "" : "End month is required"}
                />
              </div>
            </div>
            <Dropdown
              placeholder="Select a template"
              required
              style={{ minWidth: "250px" }}
              options={[
                { key: 0, text: "Teams Top Tier Features" },
                { key: 1, text: "Modern Work Top Tier Features" },
              ]}
              selectedKey={MWState.template}
              onChange={(event, option) => {
                if (option) {
                  const template = option.key as number;
                  setMWState((state) => {
                    return { ...state, template: template };
                  });
                }
              }}
            />
            <div>
              <PrimaryButton
                style={{ width: "150px" }}
                disabled={
                  !(
                    MWState.startDate &&
                    MWState.template !== undefined &&
                    MWState.template >= 0
                  ) || disabledFlag
                }
                onClick={(event) => {
                  event.preventDefault();
                  setDisabledFlag(true);
                  setSpinnerFlag((state) => {
                    return { ...state, mw: true };
                  });
                  const data = {
                    startDate: convertDateTimeToFormatedDateString(
                      MWState.startDate || new Date()
                    ),
                    endDate: convertDateTimeToFormatedDateString(
                      MWState.endDate || new Date()
                    ),
                    template: MWState.template,
                  };
                  httpClient
                    .postBlob("PPTExport/ModernWork", data)
                    .then((response: any) => {
                      if (response && response.size > 0) {
                        const aTag = document.createElement("a");
                        aTag.download =
                          data.template == 0
                            ? `TeamsTopTierFeatures_${convertDateTimeToFormatedDateString(
                                transferToUtcDate(new Date())
                              )}_${new Date()
                                .getTime()
                                .toString()
                                .substring(6)}.pptx`
                            : `ModernWorkTopTierFeatures_${convertDateTimeToFormatedDateString(
                                transferToUtcDate(new Date())
                              )}_${new Date()
                                .getTime()
                                .toString()
                                .substring(6)}.pptx`;
                        aTag.href = URL.createObjectURL(response);
                        aTag.click();
                        URL.revokeObjectURL(aTag.href);
                        aTag.remove();
                      } else {
                        SuccessMessage.show(
                          "There is no valid data to export."
                        );
                      }
                      setDisabledFlag(false);
                      setSpinnerFlag((state) => {
                        return { ...state, mw: false };
                      });
                    });
                }}
              >
                Export
              </PrimaryButton>
              {spinnerFlag.mw ? (
                <Spinner
                  size={SpinnerSize.large}
                  style={{
                    display: "inline",
                    marginLeft: "10px",
                    position: "absolute",
                  }}
                />
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className="mw-template-sample"></div>
        </div>
        <div className="template-container">
          <h4 className="template-title">Modern Work CSA</h4>
          <p className="template-description">Top moments and disclosures</p>
          <div className="template-input-container">
            <div className="template-datepicker-container">
              <div>
                <DatePickerWithLabelAndError
                  label="Start date"
                  minDate={minDate}
                  value={MWQuarterState.startDate}
                  onSelectDate={(date: Date | null | undefined) => {
                    if (
                      date &&
                      MWQuarterState.endDate &&
                      date <= MWQuarterState.endDate
                    ) {
                      setMWQuarterState((state) => {
                        return {
                          ...state,
                          startDate: date,
                        };
                      });
                    } else {
                      setMWQuarterState((state) => {
                        return {
                          ...state,
                          startDate: currentDate,
                        };
                      });
                    }
                  }}
                  onFormatDate={(date?: Date): string => {
                    return !date ? "" : moment(date).format("MM/DD/YYYY");
                  }}
                  id="MWQuarter_startDate"
                  error={
                    MWQuarterState.startDate ? "" : "Start date is required"
                  }
                />
              </div>
              <div>
                <DatePickerWithLabelAndError
                  label="End date"
                  minDate={MWQuarterState.startDate}
                  value={MWQuarterState.endDate}
                  onSelectDate={(date: Date | null | undefined) => {
                    if (
                      date &&
                      MWQuarterState.startDate &&
                      date >= MWQuarterState.startDate
                    ) {
                      setMWQuarterState((state) => {
                        return {
                          ...state,
                          endDate: date,
                        };
                      });
                    } else {
                      setMWQuarterState((state) => {
                        return {
                          ...state,
                          endDate: undefined,
                        };
                      });
                    }
                  }}
                  onFormatDate={(date?: Date): string => {
                    return !date ? "" : moment(date).format("MM/DD/YYYY");
                  }}
                  id="MWQuarter_endDate"
                  error={MWQuarterState.endDate ? "" : "End date is required"}
                />
              </div>
            </div>
            <div className="solution-area-container">
              <div className="grow-2">
                <Dropdown
                  placeholder="Select a solution area"
                  styles={dropdownStylesProps}
                  options={[{ key: "Modern Work", text: "Modern Work" }]}
                  selectedKey={"Modern Work"}
                  required
                />
              </div>
              <div className="grow-3">
                <Dropdown
                  placeholder="Select a product family"
                  multiSelect
                  required
                  styles={dropdownStylesProps}
                  options={MWQuarterState.PFOptions.map((k) => {
                    let kv = k.split(";");
                    return { key: kv[0], text: kv[1] };
                  })}
                  selectedKeys={MWQuarterState.PF}
                  onChange={(event, option) => {
                    if (option) {
                      const selectedKeys = option?.selected
                        ? [...MWQuarterState.PF, option.key as string]
                        : MWQuarterState.PF.filter(
                            (key) => key !== option?.key
                          );
                      setMWQuarterState((state) => {
                        return { ...state, PF: selectedKeys };
                      });
                    }
                  }}
                />
              </div>
            </div>
            <div>
              <PrimaryButton
                style={{ width: "150px" }}
                disabled={
                  !(
                    MWQuarterState.startDate &&
                    MWQuarterState.endDate &&
                    MWQuarterState.startDate < MWQuarterState.endDate &&
                    MWQuarterState.PF.length > 0
                  ) || disabledFlag
                }
                onClick={(event) => {
                  event.preventDefault();
                  setDisabledFlag(true);
                  setSpinnerFlag((state) => {
                    return { ...state, MWQuarter: true };
                  });
                  const data = {
                    selectedProductFamily: MWQuarterState.PF.join(","),
                    selectedStartDate: convertDateTimeToFormatedDateString(
                      MWQuarterState.startDate ?? new Date()
                    ),
                    selectedEndDate: convertDateTimeToFormatedDateString(
                      MWQuarterState.endDate ?? new Date()
                    ),
                  };
                  if (!data) {
                    return;
                  }
                  httpClient
                    .postBlob("PPTExport/ModernWorkQuarter", data)
                    .then((response: any) => {
                      if (response && response.size > 0) {
                        const aTag = document.createElement("a");
                        aTag.download = `ModernWorkTopMomentsDisclosures_${convertDateTimeToFormatedDateString(
                          transferToUtcDate(new Date())
                        )}_${new Date()
                          .getTime()
                          .toString()
                          .substring(6)}.pptx`;
                        aTag.href = URL.createObjectURL(response);
                        aTag.click();
                        URL.revokeObjectURL(aTag.href);
                        aTag.remove();
                      } else {
                        SuccessMessage.show(
                          "There is no valid data to export."
                        );
                      }
                      setDisabledFlag(false);
                      setSpinnerFlag((state) => {
                        return { ...state, MWQuarter: false };
                      });
                    });
                }}
              >
                Export
              </PrimaryButton>
              {spinnerFlag.MWQuarter ? (
                <Spinner
                  size={SpinnerSize.large}
                  style={{
                    display: "inline",
                    marginLeft: "10px",
                    position: "absolute",
                  }}
                />
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className="mwq-template-sample"></div>
        </div>
      </div>
    </>
  );
};

export default PPTExport;
