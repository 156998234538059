import httpClient from "./base";

const baseURL = "/SecurityMilestoneType";
const getSecurityMilestoneType = () => {
  return httpClient.get(baseURL);
};

const SecurityMilestoneTypeService = {
  getSecurityMilestoneType,
};

export default SecurityMilestoneTypeService;
