import * as React from "react";
import { useBoolean } from "@fluentui/react-hooks";
import {
  Dialog,
  DialogFooter,
  PrimaryButton,
  DefaultButton,
  DialogType,
} from "@fluentui/react";

const modalPropsStyles = { main: { maxWidth: 450 } };

const ConfirmationDialog = (props: {
  refs: React.RefObject<HTMLButtonElement> | null;
  onclickCallBack?: () => Promise<void>;
  confirmButtonString: string;
  cancelButtonString: string;
  title: string;
  subText: string;
}) => {
  const modalProps = {
    isBlocking: true,
    styles: modalPropsStyles,
    dragOptions: undefined,
  };
  const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);

  const dialogContentProps = {
    type: DialogType.normal,
    title: props.title,
    subText: props.subText,
    titleProps: { "aria-level": 2 },
  };
  return (
    <>
      <button
        className="hide d-none"
        onClick={toggleHideDialog}
        ref={props.refs}
      ></button>
      <Dialog
        hidden={hideDialog}
        onDismiss={toggleHideDialog}
        dialogContentProps={dialogContentProps}
        modalProps={modalProps}
      >
        <DialogFooter>
          <PrimaryButton
            onClick={() => {
              if (props.onclickCallBack) {
                props.onclickCallBack();
              }
              toggleHideDialog();
            }}
            text={props.confirmButtonString}
          />
          <DefaultButton
            onClick={() => {
              toggleHideDialog();
            }}
            text={props.cancelButtonString}
          />
        </DialogFooter>
      </Dialog>
    </>
  );
};

export default ConfirmationDialog;
