import httpClient from "./base";
import { Milestone } from "../slice/milestone";

const baseURL = "/Milestone";

const getMilestone = (id: number) => {
  var url = `${baseURL}?id=` + id;
  return httpClient.get(url);
};

const getMilestoneByMomentId = (id: number) => {
  var url = `${baseURL}/GetByMomentId?id=` + id;
  console.log("Get MileStone by Moment Id");
  return httpClient.get(url);
};

const saveMilestone = (milestone: Milestone) => {
  return httpClient.post(baseURL, { ...milestone });
};

const updateMilestone = (milestone: Milestone) => {
  return httpClient.put(baseURL, { ...milestone });
};

const deleteMilestone = (id: number) => {
  var url = `${baseURL}?id=${id}`;
  return httpClient.del(url);
};

const moveMilestone = (id: number, productId: number) => {
  var url = `${baseURL}?id=${id}&productId=${productId}`;
  return httpClient.patch(url, {});
};

const getMilestoneMonths = () => {
  var url = `${baseURL}/GetMonths`;
  return httpClient.get(url);
};

const updateMilestoneStatus = (milestone: {
  MilestoneId: number;
  RoadmapMilestoneId: number;
  DisclosureMilestoneId: number;
  Status: number;
}) => {
  var url = `${baseURL}/Status`;
  return httpClient.put(url, { ...milestone });
};

const updateMilestoneNotes = (milestone: {
  MilestoneId: number;
  RoadmapMilestoneId: number;
  DisclosureMilestoneId: number;
  Notes: string;
}) => {
  var url = `${baseURL}/Notes`;
  return httpClient.put(url, { ...milestone });
};

const milestoneService = {
  getMilestone,
  getMilestoneByMomentId,
  saveMilestone,
  updateMilestone,
  deleteMilestone,
  moveMilestone,
  getMilestoneMonths,
  updateMilestoneStatus,
  updateMilestoneNotes,
};

export default milestoneService;
