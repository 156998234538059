import { useContext } from "react";
import "../../../Shared/css/timelineGrid.scss";
import "../index.scss";
import { ContentShowMode } from "../../../Shared/constants";
import { azureTimelineMilestone as milestoneProps } from "../../../../slice/azureTimeline";
import { MilestoneModalContext } from "../..";
import MilestoneCard from "./milestoneCard";
import MilestoneRow from "./milestoneRow";

export const Milestone = (productMilestone: {
  CSA: string;
  milestones: milestoneProps[];
}) => {
  const milestoneModalContext = useContext(MilestoneModalContext);
  const productMilestoneDom = productMilestone.milestones.map((item) => {
    return milestoneModalContext.contentShowMode === ContentShowMode.Grid ? (
      <MilestoneCard milestone={item} key={item.MilestoneId} />
    ) : (
      <MilestoneRow
        CSA={productMilestone.CSA}
        milestone={item}
        key={item.MilestoneId}
      />
    );
  });

  return <div className="d-flex flex-wrap"> {productMilestoneDom} </div>;
};
