import { TimelineType } from "../components/Shared/constants";
import { CalendarMarketingMoment } from "../slice/CalendarSlices/calendarUpdate";
import httpClient from "./base";

const getModernWorkSecurityEventCalendarMomentById = (id: number) => {
  let url = "ModernWorkSecurityEventCalendarMoment?id=" + id;
  return httpClient.get(url);
};

const getModernWorkSecurityEventCalendarMomentListByTimelineType = (
  timelineType: TimelineType,
  date?: string
) => {
  let url =
    "ModernWorkSecurityEventCalendarMoment/MomentsByTimelineType?timelineType=" +
    timelineType.toString() +
    "&date=" +
    (date !== undefined ? date : "");
  return httpClient.get(url);
};

const getModernWorkSecurityEventCalendarMomentList = (
  startDate: string,
  endDate: string
) => {
  let url =
    "ModernWorkSecurityEventCalendarMoment/Moments?startDate=" +
    startDate +
    "&endDate=" +
    endDate;
  return httpClient.get(url);
};

const getModernWorkSecurityEventCalendarMomentMonths = (
  timelineType: string
) => {
  let url =
    "ModernWorkSecurityEventCalendarMoment/GetMonths?timelineType=" +
    timelineType;
  return httpClient.get(url);
};

const addNewMoment = (canlendarModel: CalendarMarketingMoment) => {
  let url = "";
  url = "ModernWorkSecurityEventCalendarMoment/AddNewMoment";
  return httpClient.post(url, canlendarModel);
};

const putMoment = (canlendarModel: CalendarMarketingMoment) => {
  let url = "";
  url = "ModernWorkSecurityEventCalendarMoment/PutMoment";
  return httpClient.put(url, canlendarModel);
};

const deleteMoment = (momentId: number) => {
  let url = "";
  url =
    "ModernWorkSecurityEventCalendarMoment/deleteMoment?momentId=" + momentId;
  return httpClient.del(url);
};

const getMomentById = (momentId: number) => {
  let url = "";
  url =
    "ModernWorkSecurityEventCalendarMoment/getMomentById?momentId=" + momentId;
  return httpClient.get(url);
};

const getAzureFeatureByMomentId = (momentId: number) => {
  let url = "";
  url = "Milestone/GetByMomentId?id=" + momentId;
  return httpClient.get(url);
};
const getModernWorkFeatureByMomentId = (momentId: number) => {
  let url = "";
  url = "ModernWorkFeature/GetByMomentId?id=" + momentId;
  return httpClient.get(url);
};
const getSecurityFeatureByMomentId = (momentId: number) => {
  let url = "";
  url = "SecurityMilestone/GetByMomentId?id=" + momentId;
  return httpClient.get(url);
};

const modernWorkSecurityEventCalendarMomentService = {
  getModernWorkSecurityEventCalendarMomentById,
  getModernWorkSecurityEventCalendarMomentListByTimelineType,
  getModernWorkSecurityEventCalendarMomentList,
  getModernWorkSecurityEventCalendarMomentMonths,
  addNewMoment,
  getMomentById,
  putMoment,
  getSecurityFeatureByMomentId,
  getModernWorkFeatureByMomentId,
  getAzureFeatureByMomentId,
  deleteMoment,
};

export default modernWorkSecurityEventCalendarMomentService;
