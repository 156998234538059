import { useContext } from "react";
import "./index.scss";
import { isOnSameDay } from "./../utilities";
import { CalendarContext } from "./../context";
import { useDispatch } from "react-redux";
import * as CalendarUpdateReducer from "../../../slice/CalendarSlices/calendarUpdate";
const EventList = () => {
  const { currentDates, registeredEvents } = useContext(CalendarContext);
  const monthEvents =
    registeredEvents?.filter((e) => {
      return (
        (((e.startDate > currentDates.currentStartDate ||
          isOnSameDay(e.startDate, currentDates.currentStartDate)) &&
          e.startDate < currentDates.currentEndDate) ||
          ((e.endDate > currentDates.currentStartDate ||
            isOnSameDay(e.endDate, currentDates.currentStartDate)) &&
            e.endDate < currentDates.currentEndDate) ||
          (e.startDate < currentDates.currentStartDate &&
            e.endDate > currentDates.currentEndDate)) &&
        (e.bicDateSelection === "TBC - Month" ||
          e.bicDateSelection === "TBC - Quarter")
      );
    }) ?? [];
  const appDispatch = useDispatch();
  return (
    <div className="calendar-eventlist-container">
      <h2 className="calendar-eventlist-header">Campaigns and TBC</h2>
      <div className="calendar-eventlist-wrapper">
        <h3 className="calendar-eventlist-month">
          {currentDates.currentStartDate!.toLocaleString("default", {
            month: "long",
          })}
        </h3>
        <h4 className="calendar-eventlist-title">Unconfirmed Moments</h4>
        <ul>
          {monthEvents.map((e) => {
            return (
              <li key={`${e.Id}-container`}>
                <a
                  key={`${e.Id}-tilte`}
                  href="#"
                  onClick={() => {
                    appDispatch(
                      CalendarUpdateReducer.actions.switchCurrentMoment(e.Id)
                    );
                    appDispatch(
                      CalendarUpdateReducer.actions.changeEditPanelOpen(false)
                    );
                    appDispatch(
                      CalendarUpdateReducer.actions.changeDetailPanelOpen(true)
                    );
                  }}
                >
                  {e.title}
                </a>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default EventList;
