import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import modernWorkFeatureHistoryService from "../services/modernWorkFeatureHistory";
import { _LOADINGSTATE } from "../components/Shared/constants";
import { ErrorMessage } from "../components/Shared/messageBox";

const initialOptionState = {
  modernWorkFeatureHistory: [],
  __isLoading: "",
};

interface modernWorkHistoryState {
  __isLoading: string;
  modernWorkFeatureHistory: ModernWorkFeatureHistory[];
}

export interface ModernWorkFeatureHistory {
  id: number;
  modernWorkFeatureId: number;
  publicDisclosureDate: Date;
  gADate: Date | null;
  previewDate: Date | null;
  plannedProductionDate: Date | null;
  tier: string;
  alignedMoment: string;
  modifiedTime: Date | null;
}

export interface ModernWorkFeatureHistoryPayload {
  Id: number;
  ModernWorkFeatureId: number;
  PublicDisclosureDate?: string;
  GADate?: string;
  PreviewDate?: string;
  PlannedProductionDate?: string;
  Tier: string;
  AlignedMoment: string;
  ModifiedTime?: string;
}

export const getModernWorkFeatureHistory = createAsyncThunk(
  "getModernWorkFeatureHistory",
  async (id: number, { dispatch, getState }) => {
    return (await modernWorkFeatureHistoryService.getModernWorkFeatureHistory(
      id
    )) as ModernWorkFeatureHistory[];
  }
);

export const deleteModernWorkFeatureHistory = createAsyncThunk(
  "deleteModernWorkFeatureHistory",
  async (params: number[]) => {
    return await modernWorkFeatureHistoryService.deleteModernWorkFeatureHistory(
      params
    );
  }
);

const modernWorkFeatureHistorySlice = createSlice({
  name: "modernWorkFeatureHistory",
  initialState: initialOptionState,
  reducers: {},
  extraReducers: {
    [getModernWorkFeatureHistory.fulfilled.type]: (
      state: modernWorkHistoryState,
      action: PayloadAction<ModernWorkFeatureHistoryPayload[]>
    ) => {
      state.modernWorkFeatureHistory = action.payload.map((p) => {
        let history: ModernWorkFeatureHistory = {
          id: p.Id,
          modernWorkFeatureId: p.ModernWorkFeatureId,
          tier: p.Tier,
          alignedMoment: p.AlignedMoment,
          gADate: convertDateTostring(p.GADate),
          previewDate: convertDateTostring(p.PreviewDate),
          plannedProductionDate: convertDateTostring(p.PlannedProductionDate),
          publicDisclosureDate: new Date(p.PublicDisclosureDate ?? ""),
          modifiedTime: convertDateTostring(p.ModifiedTime),
        };
        return history;
      });
      state.__isLoading = _LOADINGSTATE.fullfilled;
    },
    [getModernWorkFeatureHistory.rejected.type]: (state, reason) => {
      if (reason.error.message === "Request failed with status code 404") {
        ErrorMessage.show(
          "The request is not recognized. Please refresh the page or restart your browser."
        );
      } else {
        ErrorMessage.show(
          "There was an error getting the Modern Work feature history. Please refresh the page and try again. If the issue persists please contact the tool administrator."
        );
      }
    },
    [getModernWorkFeatureHistory.pending.type]: (state) => {
      state.__isLoading = _LOADINGSTATE.pending;
    },
  },
});

const convertDateTostring = (date: string | undefined) => {
  if (date !== null) {
    return new Date(date as string);
  } else return null;
};

export const { actions, reducer } = modernWorkFeatureHistorySlice;
