// src/App.js
import React, { useState, useEffect } from "react";
import "./index.scss";
import Report from "./Report/Report";
import {
  PublicClientApplication,
  InteractionRequiredAuthError,
} from "@azure/msal-browser";

type PowerBIComponentProps = {
  workspaceid: number; // or string, depending on what type workspaceid is
  config: any;
};

// Configuration object for Microsoft Authentication Library (MSAL) setup

const url = window.location.href;
let clientId = "a8a5ac1b-73a9-4d79-bb45-49eb979465b5"; // Default APP ID

if (url.includes("uat") || url.includes("test") || url.includes("localhost")) {
  clientId = "a8a5ac1b-73a9-4d79-bb45-49eb979465b5"; // UAT, TEST, or Local APP ID
}

const msalConfig = {
  // Authentication configuration
  auth: {
    // Application client ID registered in Azure Active Directory
    clientId: clientId,
    // Authority URL for authentication endpoint
    authority:
      "https://login.microsoftonline.com/72f988bf-86f1-41af-91ab-2d7cd011db47",
    // Redirect URI after successful authentication
    redirectUri: window.location.origin,
  },
  // Cache configuration for token storage
  cache: {
    // Location to store authentication cache (localStorage or sessionStorage)
    cacheLocation: "localStorage",
    // Indicates whether to store authentication state in a cookie
    storeAuthStateInCookie: false,
  },
};

// Request object specifying the scopes needed for authentication
const loginRequest = {
  // Scopes required for accessing Power BI API for reading reports
  scopes: ["https://analysis.windows.net/powerbi/api/Report.Read.All"],
};

function PowerBIComponent({ workspaceid, config }: PowerBIComponentProps) {
  const pca = new PublicClientApplication(msalConfig);
  const [accessToken, setAccessToken] = useState<string | null>(null);
  const [embedUrl, setEmbedUrl] = useState<string | null>(null);

  useEffect(() => {
    const authenticateAndFetchEmbedUrl = async () => {
      try {
        const accounts = pca.getAllAccounts();

        if (accounts.length > 0) {
          const response = await pca.acquireTokenSilent({
            ...loginRequest,
            account: accounts[0],
          });

          setAccessToken(response.accessToken);
        } else {
          const loginResponse = await pca.loginPopup(loginRequest);
          setAccessToken(loginResponse.accessToken);
        }
      } catch (error) {
        handleAuthenticationError(error);
      }
    };

    authenticateAndFetchEmbedUrl();
  }, []);

  // Extracted the authentication error handling logic into a separate function
  const handleAuthenticationError = (error: any) => {
    if (error instanceof InteractionRequiredAuthError) {
      // If an interaction is required, use acquireTokenPopup to interactively acquire the token
      pca
        .acquireTokenPopup(loginRequest)
        .then((response) => {
          setAccessToken(response.accessToken);
        })
        .catch((err) => console.error("Error acquiring token:", err));
    } else {
      // Log the authentication error
      console.error("Authentication error:", error);
    }
  };

  const getEmbedUrl = async (workspaceid: number) => {
    try {
      let reportId;
      // Determine the reportId based on the workspaceid using a switch statement
      switch (workspaceid) {
        case 1:
          reportId = config?.azureReportID;
          break;
        case 2:
          reportId = config?.securityReportID;
          break;
        case 3:
          reportId = config?.mwReportID;
          break;
        default:
          // Log an error for invalid workspace ID
          console.error("Invalid workspace ID:", workspaceid);
          return;
      }

      const response = await fetch(
        `https://api.powerbi.com/v1.0/myorg/groups/${config?.workspaceGroupID}/reports/${reportId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
          method: "GET",
        }
      );

      if (response.ok) {
        // Parse and set the embedUrl if the fetch operation is successful
        const body = await response.json();
        setEmbedUrl(body?.embedUrl);
      } else {
        // Log an error if the fetch operation is not successful
        console.error(`Error ${response.status}: ${response.statusText}`);
      }
    } catch (error) {
      // Log an error for any unexpected errors during the fetch operation
      console.error("Error fetching embed URL:", error);
    }
  };

  useEffect(() => {
    if (accessToken) {
      getEmbedUrl(workspaceid);
    }
  }, [accessToken, workspaceid]);

  return (
    <>
      <div className="parent">
        <div className="child">
          <Report
            workspaceid={workspaceid}
            config={config}
            accessToken={accessToken || ""}
            embedUrl={embedUrl ?? ""}
          />
        </div>
      </div>
    </>
  );
}

export default PowerBIComponent;
