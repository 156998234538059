import httpClient from "./base";

const getConversation = () => {
  var url = "Conversation";
  return httpClient.get(url);
};

const conversationService = {
  getConversation,
};

export default conversationService;
