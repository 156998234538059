import httpClient from "./base";

const getAuthorization = () => {
  const url = "Authorization";
  return httpClient.get(url);
};

const checkAuthorization = () => {
  const url = "Authorization/Check";
  return httpClient.get(url);
};

const authorizationService = {
  getAuthorization,
  checkAuthorization,
};

export default authorizationService;
