import React, { useState, useEffect, createRef } from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { OnChangeValue } from "react-select";
import {
  Dropdown,
  TextField,
  PrimaryButton,
  Spinner,
  SpinnerSize,
} from "@fluentui/react";
import striptags from "striptags";
import "../../Shared/css/timelineFilter.scss";
import { useUrlQueryParam } from "../../../utils/url";
import { isVoid, speakText } from "../../../utils/utils";
import { SuccessMessage, ErrorMessage } from "../../Shared/messageBox";
import ConfirmationDialog from "../../Shared/confirmationDialog";
import GraphUsersAutoComplete from "../../Shared/FormInput/graphUsersAutoComplete";
import SearchableMultiSelect from "../../Shared/FormInput/searchableMultiSelect";
import { _LOADINGSTATE } from "../../Shared/constants";
import { buttonStyles, OptionType } from "../../Shared/styleProps";
import { RootState, useAppDispatch } from "../../../store";
import { milestoneTier } from "../../../slice/milestoneTier";
import { SecurityMilestoneType } from "../../../slice/securityMilestoneType";
import * as securityTimelineReducer from "../../../slice/securityTimeline";
import {
  savedDataFilter,
  getSavedDataFilters,
  saveSavedDataFilter,
  deleteSavedDataFilter,
  filter,
  securityMilestoneFilterList,
} from "../../../slice/savedDataFilters";
import {
  dropdownStyles,
  labelStyles,
  inputFieldStyles,
  graphUsersAutoCompleteStyles,
  filterStyles,
} from "../../Shared/timelineFilterStyleProps";
import { modernWorkSecurityEventCalendarMomentOption } from "../../../slice/modernWorkSecurityEventCalendarMoment";

const Filter = () => {
  const appDispatch = useAppDispatch();
  const [filterControlMode, setFilterControlMode] = useState("left");
  const [showFilter, setShowFilter] = useState(true);
  const [showFilterList, setShowFilterList] = useState(false);
  const [deleteFilterId, setDeleteFilterId] = useState(0);

  const [currentFilterName, setCurrentFilterName] = useState("");
  const [selectedFilterName, setSelectedFilterName] = useState(
    "Select from your saved views"
  );
  const [errorMessage, setErrorMessage] = useState("");
  const [showShare, setShowShare] = useState(false);
  const [showLinkCopied, setShowLinkCopied] = useState(false);

  const productServices = useSelector(
    (state: RootState) =>
      state.securityTimelineReducer.securityTimelineFilterOption.ProductService
  );

  const productCategories = useSelector(
    (state: RootState) =>
      state.securityTimelineReducer.securityTimelineFilterOption.ProductCategory
  );

  const productFamilies = useSelector(
    (state: RootState) =>
      state.securityTimelineReducer.securityTimelineFilterOption.ProductFamily
  );

  const solutionPlaies = useSelector(
    (state: RootState) =>
      state.securityTimelineReducer.securityTimelineFilterOption.SolutionPlay
  );

  const milestoneTiers = useSelector(
    (state: RootState) =>
      state.securityTimelineReducer.securityTimelineFilterOption.MilestoneTier
  );

  const securityMilestoneTypes = useSelector(
    (state: RootState) =>
      state.securityTimelineReducer.securityTimelineFilterOption
        .SecurityMilestoneType
  );

  const fiscalYears = useSelector(
    (state: RootState) =>
      state.securityTimelineReducer.securityTimelineFilterOption.FiscalYear
  );

  const fiscalQuarters = useSelector(
    (state: RootState) =>
      state.securityTimelineReducer.securityTimelineFilterOption.FiscalQuarter
  );

  const fiscalMonths = useSelector(
    (state: RootState) =>
      state.securityTimelineReducer.securityTimelineFilterOption.FiscalMonth
  );

  const moments = useSelector(
    (state: RootState) =>
      state.securityTimelineReducer.securityTimelineFilterOption.Moments
  );

  const securityAudiences = useSelector(
    (state: RootState) =>
      state.securityTimelineReducer.securityTimelineFilterOption
        .SecurityAudience
  );

  const isFilterLoading = useSelector(
    (state: RootState) => state.securityTimelineReducer.isFilterLoading
  );

  const [isSavedDataFiltersLoading, setIsSavedDataFiltersLoading] = useState(
    _LOADINGSTATE.pending
  );
  const [isSavedDataFiltersSaving, setIsSavedDataFiltersSaving] = useState(
    _LOADINGSTATE.fullfilled
  );
  const [dataFilterList, setDataFilterList] = useState<savedDataFilter[]>([]);
  const [param, setParam] = useUrlQueryParam(
    securityMilestoneFilterList.map((f: filter) => {
      return f.filterName;
    })
  );

  const [searchParams, setSearchParam] = useSearchParams();

  const saveHandler = () => {
    if (!currentFilterName || currentFilterName.trim().length === 0) {
      setErrorMessage("Input Filter Name please.");
      document.getElementById("txtFilterName")?.focus();
      return;
    } else {
      setErrorMessage("");
    }
    setIsSavedDataFiltersSaving(_LOADINGSTATE.pending);
    let filterData: filter[] = [];
    securityMilestoneFilterList.forEach((filter: filter) => {
      if (filter.filterName in param && param[filter.filterName] !== "") {
        if (filter.filterType === "multiselect") {
          filterData.push({
            filterName: filter.filterName,
            filterType: filter.filterType,
            filterValue: decodeURIComponent(param[filter.filterName]).split(
              ";|;"
            ),
          });
        } else {
          filterData.push({
            filterName: filter.filterName,
            filterType: filter.filterType,
            filterValue: decodeURIComponent(param[filter.filterName]),
          });
        }
      }
    });
    appDispatch(
      saveSavedDataFilter({
        Name: currentFilterName,
        FilterData: JSON.stringify(filterData),
        FilterType: "SecurityTimelineFilter",
      })
    )
      .then((response) => {
        let newSavedDataFilter = response.payload as savedDataFilter;
        let newSavedDataFilterList = dataFilterList.filter(() => {
          return true;
        });
        if (
          newSavedDataFilterList
            .map((s: savedDataFilter) => {
              return s.SavedDataFilterId;
            })
            .indexOf(newSavedDataFilter.SavedDataFilterId) > -1
        ) {
          newSavedDataFilterList = newSavedDataFilterList.filter(
            (dataFilter) => {
              return (
                dataFilter.SavedDataFilterId !==
                newSavedDataFilter.SavedDataFilterId
              );
            }
          );
          SuccessMessage.show("Saved view updated!", 200, -100);
        } else {
          SuccessMessage.show("Saved view added!", 200, -100);
        }
        newSavedDataFilterList.unshift(newSavedDataFilter);
        setDataFilterList(newSavedDataFilterList);
      })
      .catch((error) => {
        ErrorMessage.show(error);
      })
      .finally(() => {
        setIsSavedDataFiltersSaving(_LOADINGSTATE.fullfilled);
      });
  };

  const deleteHandler = (key: number | undefined) => {
    if (key) {
      appDispatch(deleteSavedDataFilter(key))
        .then(() => {
          let newSavedDataFilterList = dataFilterList;

          SuccessMessage.show("Delete Filter success!");

          newSavedDataFilterList = newSavedDataFilterList.filter(
            (dataFilter) => {
              return dataFilter.SavedDataFilterId !== key;
            }
          );

          dataFilterList.every(function (savedDataFilter: savedDataFilter) {
            if (savedDataFilter.SavedDataFilterId === key) {
              if (savedDataFilter.Name === selectedFilterName) {
                setSelectedFilterName("Select one or more options");
                let query = searchParams;
                let filters = JSON.parse(savedDataFilter.FilterData);
                filters.forEach((filter: filter) => {
                  query.delete(filter.filterName);
                });
                setSearchParam(query);
              }
              return false;
            }
            return true;
          });

          setDataFilterList(newSavedDataFilterList);
        })
        .catch((error) => {
          ErrorMessage.show(error);
        });
    }
  };

  const updateUrl = (key: number | undefined) => {
    dataFilterList.every(function (savedDataFilter: savedDataFilter) {
      if (savedDataFilter.SavedDataFilterId === key) {
        let query = searchParams;
        securityMilestoneFilterList.forEach((filter: filter) => {
          query.delete(filter.filterName);
        });
        let filters = JSON.parse(savedDataFilter.FilterData);
        filters.forEach((filter: filter) => {
          if (filter.filterValue) {
            query.append(
              filter.filterName,
              encodeURIComponent(
                filter.filterValue instanceof Array
                  ? filter.filterValue.join(";|;")
                  : filter.filterValue
              )
            );
          }
        });
        setSearchParam(query);
        return false;
      }
      return true;
    });
  };

  const deleteFilterDomref = createRef<HTMLButtonElement>();

  const deleteFilterHandle = (
    e: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>
  ) => {
    e.preventDefault();
    setDeleteFilterId(
      parseInt(e.currentTarget.getAttribute("data-filter-id") || "0")
    );

    deleteFilterDomref.current?.click();
  };

  const getSizes = () => {
    const browerWidth = document.documentElement.clientWidth;
    const filterSectionWidth =
      document.getElementsByClassName("filter-container")[0].clientWidth;
    let filterControlWidth =
      document.getElementById("divFilterControl")?.clientWidth;
    if (filterControlWidth !== undefined) {
      if (browerWidth - filterSectionWidth < filterControlWidth * 2) {
        setFilterControlMode("top");
      } else {
        setFilterControlMode("left");
      }
    }
  };

  useEffect(() => {
    window.removeEventListener("resize", getSizes, false);
    window.addEventListener("resize", getSizes, false);

    getSizes();
    appDispatch(securityTimelineReducer.getSecurityTimelineFilters());

    appDispatch(getSavedDataFilters("SecurityTimelineFilter")).then(
      (response) => {
        if (typeof response.payload === "string") {
          setDataFilterList([]);
        } else {
          setDataFilterList(response.payload as savedDataFilter[]);
        }
        setIsSavedDataFiltersLoading(_LOADINGSTATE.fullfilled);
      }
    );
  }, [appDispatch]);

  return (
    <div className="timeline-filter-container">
      {filterControlMode === "top" ? (
        <div className="row filter-container">
          <div className="position-relative col pb-10">
            <div
              id="divFilterControl"
              role="button"
              tabIndex={0}
              className="filters-lg-text-container text-right position-absolute d-flex d-ab"
              aria-label="Filters expand to see list of filters"
              aria-expanded={showFilter ? "true" : "false"}
              onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>) => {
                if (event.keyCode === 13) {
                  setShowFilter(() => {
                    return !showFilter;
                  });
                }
              }}
              onClick={() => {
                setShowFilter(() => {
                  return !showFilter;
                });
              }}
            >
              <span className="filters-icon">
                {" "}
                <i
                  className={`${
                    showFilter
                      ? "fa fa-angle-down fa-2x"
                      : "fa fa-angle-right fa-2x"
                  }`}
                ></i>
              </span>
              <span className="filters-text">Filters:</span>
            </div>
          </div>
        </div>
      ) : null}
      <div className="row filter-container">
        <div className="row">
          <div className="position-relative col">
            {filterControlMode === "left" ? (
              <div
                id="divFilterControl"
                className="filters-text-container text-right position-absolute d-flex d-ab"
                role="button"
                tabIndex={0}
                aria-label="Filters expand to see list of filters"
                aria-expanded={showFilter ? "true" : "false"}
                onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>) => {
                  if (event.keyCode === 13) {
                    setShowFilter(() => {
                      return !showFilter;
                    });
                  }
                }}
                onClick={() => {
                  setShowFilter(() => {
                    return !showFilter;
                  });
                }}
              >
                <span className="filters-icon">
                  {" "}
                  <i
                    className={`${
                      showFilter
                        ? "fa fa-angle-down fa-2x"
                        : "fa fa-angle-right fa-2x"
                    }`}
                  ></i>
                </span>
                <span className="filters-text">Filters:</span>
              </div>
            ) : null}
            <div>
              <h3>
                <span
                  className="filters-lookupname"
                  role="heading"
                  aria-level={3}
                >
                  Product
                </span>
              </h3>
              <div className={`${showFilter ? "" : "d-none"}`}>
                {isFilterLoading === _LOADINGSTATE.pending ? (
                  <>
                    <div style={{ marginTop: 8 }}></div>
                    <Spinner
                      size={SpinnerSize.large}
                      label="Product/Service"
                      ariaLive="assertive"
                      labelPosition="top"
                    />
                  </>
                ) : (
                  <>
                    <label style={labelStyles}>Product/Service</label>
                    <SearchableMultiSelect
                      ariaLabel={"Product/Service"}
                      placeholder={"Select one or more options"}
                      id={"SCITimelineFilter_ProductServices"}
                      options={productServices.map(
                        (productService: { Id: number; Text: string }) => {
                          return {
                            value: productService.Id.toString(),
                            label: productService.Text,
                          };
                        }
                      )}
                      value={
                        productServices
                          .filter(
                            (productService: { Id: number; Text: string }) => {
                              return (
                                decodeURIComponent(param.ProductServices)
                                  .split(";|;")
                                  .indexOf(productService.Text) > -1
                              );
                            }
                          )
                          .map(
                            (productService: { Id: number; Text: string }) => {
                              return {
                                value: productService.Id.toString(),
                                label: productService.Text,
                              };
                            }
                          ) || []
                      }
                      selectStyle={filterStyles}
                      onChangeHandler={(
                        value: OnChangeValue<OptionType, true>
                      ) => {
                        if (value) {
                          setParam({
                            ...param,
                            ProductServices: encodeURIComponent(
                              value
                                .map((v: OptionType) => {
                                  return v.label;
                                })
                                .join(";|;")
                            ),
                          });
                        }
                      }}
                    />
                  </>
                )}
              </div>
              <div className={`${showFilter ? "" : "d-none"}`}>
                {isFilterLoading === _LOADINGSTATE.pending ? (
                  <>
                    <div style={{ marginTop: 8 }}></div>
                    <Spinner
                      size={SpinnerSize.large}
                      label="Product family"
                      ariaLive="assertive"
                      labelPosition="top"
                    />
                  </>
                ) : (
                  <>
                    <label style={labelStyles}>Product family</label>
                    <SearchableMultiSelect
                      ariaLabel={"Product family"}
                      placeholder={"Select one or more options"}
                      id={"SCITimelineFilter_ProductCategory"}
                      options={productCategories.map((c: string) => {
                        return { value: c, label: c };
                      })}
                      value={
                        productCategories
                          .filter((c: string) => {
                            return (
                              decodeURIComponent(param.ProductCategory)
                                .split(";|;")
                                .indexOf(c) > -1
                            );
                          })
                          .map((c: string) => {
                            return {
                              value: c,
                              label: c,
                            };
                          }) || []
                      }
                      selectStyle={filterStyles}
                      onChangeHandler={(
                        value: OnChangeValue<OptionType, true>
                      ) => {
                        if (value) {
                          setParam({
                            ...param,
                            ProductCategory: encodeURIComponent(
                              value
                                .map((v: OptionType) => {
                                  return v.label;
                                })
                                .join(";|;")
                            ),
                          });
                        }
                      }}
                    />
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="position-relative col section-wrap">
            <h3>
              <span className="filters-lookupname">
                <label></label>
              </span>
            </h3>
            <div className={`${showFilter ? "" : "d-none"}`}>
              {isFilterLoading === _LOADINGSTATE.pending ? (
                <>
                  <div style={{ marginTop: 8 }}></div>
                  <Spinner
                    size={SpinnerSize.large}
                    label="Solution play"
                    ariaLive="assertive"
                    labelPosition="top"
                  />
                </>
              ) : (
                <>
                  <label style={labelStyles}>Solution play</label>
                  <SearchableMultiSelect
                    ariaLabel={"Solution play"}
                    placeholder={"Select one or more options"}
                    id={"SCITimelineFilter_SolutionPlay"}
                    options={solutionPlaies.map((c: string) => {
                      return {
                        value: c,
                        label: c,
                      };
                    })}
                    value={
                      solutionPlaies
                        .filter((c: string) => {
                          return (
                            decodeURIComponent(param.SolutionPlay)
                              .split(";|;")
                              .indexOf(c) > -1
                          );
                        })
                        .map((c: string) => {
                          return {
                            value: c,
                            label: c,
                          };
                        }) || []
                    }
                    selectStyle={filterStyles}
                    onChangeHandler={(
                      value: OnChangeValue<OptionType, true>
                    ) => {
                      if (value) {
                        setParam({
                          ...param,
                          SolutionPlay: encodeURIComponent(
                            value
                              .map((v: OptionType) => {
                                return v.label;
                              })
                              .join(";|;")
                          ),
                        });
                      }
                    }}
                  />
                </>
              )}
            </div>
            <div className={`${showFilter ? "" : "d-none"}`}>
              {isFilterLoading === _LOADINGSTATE.pending ? (
                <>
                  <div style={{ marginTop: 8 }}></div>
                  <Spinner
                    size={SpinnerSize.large}
                    label="Product group"
                    ariaLive="assertive"
                    labelPosition="top"
                  />
                </>
              ) : (
                <>
                  <label style={labelStyles}>Product group</label>
                  <SearchableMultiSelect
                    ariaLabel={"Product group"}
                    placeholder={"Select one or more options"}
                    id={"SCITimelineFilter_ProductFamily"}
                    options={productFamilies.map((c: string) => {
                      return {
                        value: c,
                        label: c,
                      };
                    })}
                    value={
                      productFamilies
                        .filter((c: string) => {
                          return (
                            decodeURIComponent(param.ProductFamily)
                              .split(";|;")
                              .indexOf(c) > -1
                          );
                        })
                        .map((c: string) => {
                          return {
                            value: c,
                            label: c,
                          };
                        }) || []
                    }
                    selectStyle={filterStyles}
                    onChangeHandler={(
                      value: OnChangeValue<OptionType, true>
                    ) => {
                      if (value) {
                        setParam({
                          ...param,
                          ProductFamily: encodeURIComponent(
                            value
                              .map((v: OptionType) => {
                                return v.label;
                              })
                              .join(";|;")
                          ),
                        });
                      }
                    }}
                  />
                </>
              )}
            </div>
          </div>
          <div className="col section-wrap">
            <h3>
              <span
                className="filters-lookupname"
                role="heading"
                aria-level={3}
              >
                People
              </span>
            </h3>
            <div className={`${showFilter ? "" : "d-none"}`}>
              <label className="ms-Label ms-Dropdown-label" style={labelStyles}>
                Product marketing manager
              </label>
              <GraphUsersAutoComplete
                selectStyle={graphUsersAutoCompleteStyles}
                selectedKey={decodeURIComponent(param.PMM)}
                onChangeHandler={(value) => {
                  if (!isVoid(value) && !isVoid(value?.value)) {
                    var email = "",
                      regexEmail = "\\[(.+?)\\]",
                      matchStr = value?.value.match(regexEmail);
                    if (isVoid(matchStr)) {
                      return;
                    } else {
                      email = matchStr[1];
                    }
                    var userNameLength = value?.value.length - email.length - 3;
                    var userName = value?.value.substring(0, userNameLength);
                    setParam({
                      ...param,
                      PMM: encodeURIComponent(userName),
                    });
                  } else {
                    let query = searchParams;
                    if (query.has("PMM")) {
                      query.delete("PMM");
                    }
                    setSearchParam(query);
                  }
                }}
                ariaLabel="Product marketing manager"
                microsoftUser={true}
                id="filter_PMM"
              ></GraphUsersAutoComplete>
            </div>
            <div className={`${showFilter ? "" : "d-none"}`}>
              {isFilterLoading === _LOADINGSTATE.pending ? (
                <>
                  <div style={{ marginTop: 8 }}></div>
                  <Spinner
                    size={SpinnerSize.large}
                    label="Target audience"
                    ariaLive="assertive"
                    labelPosition="top"
                  />
                </>
              ) : (
                <>
                  <label style={labelStyles}>Target audience</label>
                  <SearchableMultiSelect
                    ariaLabel={"Target audience"}
                    placeholder={"Select one or more options"}
                    id={"SCITimelineFilter_ProductServices"}
                    options={securityAudiences.map(
                      (securityAudience: { Id: number; Text: string }) => {
                        return {
                          value: securityAudience.Id.toString(),
                          label: securityAudience.Text,
                        };
                      }
                    )}
                    value={
                      securityAudiences
                        .filter(
                          (securityAudience: { Id: number; Text: string }) => {
                            return (
                              decodeURIComponent(param.SecurityAudience)
                                .split(";|;")
                                .indexOf(securityAudience.Id.toString()) > -1
                            );
                          }
                        )
                        .map(
                          (securityAudience: { Id: number; Text: string }) => {
                            return {
                              value: securityAudience.Id.toString(),
                              label: securityAudience.Text,
                            };
                          }
                        ) || []
                    }
                    selectStyle={filterStyles}
                    onChangeHandler={(
                      value: OnChangeValue<OptionType, true>
                    ) => {
                      if (value) {
                        setParam({
                          ...param,
                          SecurityAudience: encodeURIComponent(
                            value
                              .map((v: OptionType) => {
                                return v.value;
                              })
                              .join(";|;")
                          ),
                        });
                      }
                    }}
                  />
                </>
              )}
            </div>
          </div>
          <div className="col">
            <h3>
              <span
                className="filters-lookupname"
                role="heading"
                aria-level={3}
              >
                Milestone properties
              </span>
            </h3>
            <div className={`${showFilter ? "" : "d-none"}`}>
              {isFilterLoading === _LOADINGSTATE.pending ? (
                <>
                  <div style={{ marginTop: 8 }}></div>
                  <Spinner
                    size={SpinnerSize.large}
                    label="Milestone tier"
                    ariaLive="assertive"
                    labelPosition="top"
                  />
                </>
              ) : (
                <>
                  <label style={{ marginTop: "5px", marginBottom: "5px" }}>
                    Milestone tier
                  </label>{" "}
                  <a
                    href="https://aka.ms/cmtieringframework"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: "#005ba2" }}
                  >
                    (guidelines)
                  </a>
                  <Dropdown
                    placeholder="Select one or more options"
                    defaultSelectedKeys={
                      decodeURIComponent(param.TierId)
                        .split(";|;")
                        .filter((tierId: string) => {
                          return (
                            milestoneTiers
                              .map((tier: milestoneTier) => {
                                return tier.TierId.toString();
                              })
                              .indexOf(tierId) > -1
                          );
                        })
                        .map((tierId: string) => {
                          return parseInt(tierId);
                        }) || []
                    }
                    multiSelect={true}
                    options={milestoneTiers.map((tier: milestoneTier) => {
                      return { key: tier.TierId, text: "Tier " + tier.Name };
                    })}
                    onChange={(event, item) => {
                      if (item) {
                        let updatedMilestoneTier = decodeURIComponent(
                          param.TierId
                        )
                          .split(";|;")
                          .filter(
                            (milestoneTierId: string) =>
                              milestoneTierId !== item.key.toString()
                          );
                        if (item.selected === true) {
                          updatedMilestoneTier.push(item.key.toString());
                        }
                        setParam({
                          ...param,
                          TierId: encodeURIComponent(
                            updatedMilestoneTier.join(";|;")
                          ),
                        });
                      }
                    }}
                    ariaLabel="Milestone tier"
                    styles={dropdownStyles}
                    calloutProps={{ doNotLayer: true }}
                  />
                </>
              )}
            </div>
            <div className={`${showFilter ? "" : "d-none"}`}>
              {isFilterLoading === _LOADINGSTATE.pending ? (
                <>
                  <div style={{ marginTop: 8 }}></div>
                  <Spinner
                    size={SpinnerSize.large}
                    label="Milestone type"
                    ariaLive="assertive"
                    labelPosition="top"
                  />
                </>
              ) : (
                <>
                  <label style={{ marginTop: "5px", marginBottom: "5px" }}>
                    Milestone type
                  </label>
                  <Dropdown
                    placeholder="Select one or more options"
                    defaultSelectedKeys={
                      decodeURIComponent(param.SecurityMilestoneTypeId)
                        .split(";|;")
                        .filter((typeId: string) => {
                          return (
                            securityMilestoneTypes
                              .map((type: SecurityMilestoneType) => {
                                return type.SecurityMilestoneTypeId.toString();
                              })
                              .indexOf(typeId) > -1
                          );
                        })
                        .map((typeId: string) => {
                          return parseInt(typeId);
                        }) || []
                    }
                    multiSelect={true}
                    options={securityMilestoneTypes.map(
                      (type: SecurityMilestoneType) => {
                        return {
                          key: type.SecurityMilestoneTypeId,
                          text: "" + type.Name,
                        };
                      }
                    )}
                    onChange={(event, item) => {
                      if (item) {
                        let updatedMilestoneType = decodeURIComponent(
                          param.SecurityMilestoneTypeId
                        )
                          .split(";|;")
                          .filter(
                            (typeId: string) => typeId !== item.key.toString()
                          );
                        if (item.selected === true) {
                          updatedMilestoneType.push(item.key.toString());
                        }
                        setParam({
                          ...param,
                          SecurityMilestoneTypeId: encodeURIComponent(
                            updatedMilestoneType.join(";|;")
                          ),
                        });
                      }
                    }}
                    ariaLabel="Milestone type"
                    styles={dropdownStyles}
                    calloutProps={{ doNotLayer: true }}
                  />
                </>
              )}
            </div>
            <div className={`${showFilter ? "" : "d-none"}`}>
              {isFilterLoading === _LOADINGSTATE.pending ? (
                <>
                  <div style={{ marginTop: 8 }}></div>
                  <Spinner
                    size={SpinnerSize.large}
                    label="Marketing moment alignment"
                    ariaLive="assertive"
                    labelPosition="top"
                  />
                </>
              ) : (
                <>
                  <label style={{ marginTop: "5px", marginBottom: "5px" }}>
                    Marketing moment alignment
                  </label>
                  <Dropdown
                    placeholder="Select one or more options"
                    defaultSelectedKeys={
                      decodeURIComponent(param.MomentId)
                        .split(";|;")
                        .filter((momentId: string) => {
                          return (
                            moments
                              .map(
                                (
                                  moment: modernWorkSecurityEventCalendarMomentOption
                                ) => {
                                  return moment.Id.toString();
                                }
                              )
                              .indexOf(momentId) > -1
                          );
                        })
                        .map((momentId: string) => {
                          return parseInt(momentId);
                        }) || []
                    }
                    multiSelect={true}
                    options={moments.map(
                      (moment: modernWorkSecurityEventCalendarMomentOption) => {
                        return {
                          key: moment.Id,
                          text: striptags(moment.DisplayTextWithYearAndTBD),
                          data: moment.DisplayTextWithYearAndTBD,
                        };
                      }
                    )}
                    onChange={(event, item) => {
                      if (item) {
                        let updatedMoment = decodeURIComponent(param.MomentId)
                          .split(";|;")
                          .filter(
                            (milestoneTierId: string) =>
                              milestoneTierId !== item.key.toString()
                          );
                        if (item.selected === true) {
                          updatedMoment.push(item.key.toString());
                        }
                        setParam({
                          ...param,
                          MomentId: encodeURIComponent(
                            updatedMoment.join(";|;")
                          ),
                        });
                      }
                    }}
                    ariaLabel="Marketing moment alignment"
                    styles={{
                      ...dropdownStyles,
                      callout: {
                        height: "288px",
                      },
                    }}
                    calloutProps={{ doNotLayer: true }}
                  />
                </>
              )}
            </div>
          </div>
          <div className="col section-wrap">
            <h3>
              <span className="filters-lookupname">
                <label></label>
              </span>
            </h3>
            <div className={`${showFilter ? "" : "d-none"}`}>
              {isFilterLoading === _LOADINGSTATE.pending ? (
                <>
                  <div style={{ marginTop: 8 }}></div>
                  <Spinner
                    size={SpinnerSize.large}
                    label="Milestone fiscal year"
                    ariaLive="assertive"
                    labelPosition="top"
                  />
                </>
              ) : (
                <>
                  <label style={{ marginTop: "5px", marginBottom: "5px" }}>
                    Milestone fiscal year
                  </label>
                  <Dropdown
                    placeholder="Select one or more options"
                    defaultSelectedKeys={
                      decodeURIComponent(param.FiscalYear)
                        .split(";|;")
                        .filter((fiscalYear: string) => {
                          return (
                            fiscalYears
                              .map((fiscalYear: number) => {
                                return fiscalYear.toString();
                              })
                              .indexOf(fiscalYear) > -1
                          );
                        })
                        .map((fiscalYear: string) => {
                          return parseInt(fiscalYear);
                        }) || []
                    }
                    multiSelect={true}
                    options={fiscalYears.map((fiscalYear: number) => {
                      return { key: fiscalYear, text: "FY" + fiscalYear };
                    })}
                    onChange={(event, item) => {
                      if (item) {
                        let updatedFiscalYear = decodeURIComponent(
                          param.FiscalYear
                        )
                          .split(";|;")
                          .filter(
                            (FiscalYear: string) =>
                              FiscalYear !== item.key.toString()
                          );
                        if (item.selected === true) {
                          updatedFiscalYear.push(item.key.toString());
                        }
                        setParam({
                          ...param,
                          FiscalYear: encodeURIComponent(
                            updatedFiscalYear.join(";|;")
                          ),
                        });
                      }
                    }}
                    ariaLabel="Milestone fiscal year"
                    styles={dropdownStyles}
                    calloutProps={{ doNotLayer: true }}
                  />
                </>
              )}
            </div>
            <div className={`${showFilter ? "" : "d-none"}`}>
              {isFilterLoading === _LOADINGSTATE.pending ? (
                <>
                  <div style={{ marginTop: 8 }}></div>
                  <Spinner
                    size={SpinnerSize.large}
                    label="Milestone fiscal quarter"
                    ariaLive="assertive"
                    labelPosition="top"
                  />
                </>
              ) : (
                <>
                  <label style={{ marginTop: "5px", marginBottom: "5px" }}>
                    Milestone fiscal quarter
                  </label>
                  <Dropdown
                    placeholder="Select one or more options"
                    defaultSelectedKeys={
                      decodeURIComponent(param.FiscalQuarter)
                        .split(";|;")
                        .filter((fiscalQuarter: string) => {
                          return (
                            fiscalQuarters
                              .map((fiscalQuarter: number) => {
                                return fiscalQuarter.toString();
                              })
                              .indexOf(fiscalQuarter) > -1
                          );
                        })
                        .map((fiscalQuarter: string) => {
                          return parseInt(fiscalQuarter);
                        }) || []
                    }
                    multiSelect={true}
                    options={fiscalQuarters.map((fiscalQuarter: number) => {
                      return { key: fiscalQuarter, text: "Q" + fiscalQuarter };
                    })}
                    onChange={(event, item) => {
                      if (item) {
                        let updatedFiscalQuarter = decodeURIComponent(
                          param.FiscalQuarter
                        )
                          .split(";|;")
                          .filter(
                            (fiscalQuarter: string) =>
                              fiscalQuarter !== item.key.toString()
                          );
                        if (item.selected === true) {
                          updatedFiscalQuarter.push(item.key.toString());
                        }
                        setParam({
                          ...param,
                          FiscalQuarter: encodeURIComponent(
                            updatedFiscalQuarter.join(";|;")
                          ),
                        });
                      }
                    }}
                    ariaLabel="Milestone fiscal quarter"
                    styles={dropdownStyles}
                    calloutProps={{ doNotLayer: true }}
                  />
                </>
              )}
            </div>
            <div className={`${showFilter ? "" : "d-none"}`}>
              {isFilterLoading === _LOADINGSTATE.pending ? (
                <>
                  <div style={{ marginTop: 8 }}></div>
                  <Spinner
                    size={SpinnerSize.large}
                    label="Marketing fiscal month"
                    ariaLive="assertive"
                    labelPosition="top"
                  />
                </>
              ) : (
                <>
                  <label style={{ marginTop: "5px", marginBottom: "5px" }}>
                    Marketing fiscal month
                  </label>
                  <Dropdown
                    placeholder="Select one or more options"
                    defaultSelectedKeys={
                      decodeURIComponent(param.FiscalMonth)
                        .split(";|;")
                        .filter((fiscalMonth: string) => {
                          return (
                            fiscalMonths
                              .map((fiscalMonth: string) => {
                                return fiscalMonth;
                              })
                              .indexOf(fiscalMonth) > -1
                          );
                        })
                        .map((fiscalMonth: string) => {
                          return fiscalMonth;
                        }) || []
                    }
                    multiSelect={true}
                    options={fiscalMonths.map((fiscalMonth: string) => {
                      return { key: fiscalMonth, text: fiscalMonth };
                    })}
                    onChange={(event, item) => {
                      if (item) {
                        let updatedFiscalMonth = decodeURIComponent(
                          param.FiscalMonth
                        )
                          .split(";|;")
                          .filter(
                            (fiscalMonth: string) =>
                              fiscalMonth !== item.key.toString()
                          );
                        if (item.selected === true) {
                          updatedFiscalMonth.push(item.key.toString());
                        }
                        setParam({
                          ...param,
                          FiscalMonth: encodeURIComponent(
                            updatedFiscalMonth.join(";|;")
                          ),
                        });
                      }
                    }}
                    ariaLabel="Marketing fiscal month"
                    styles={dropdownStyles}
                    calloutProps={{ doNotLayer: true }}
                  />
                </>
              )}
            </div>
          </div>
          <div className="col">
            <h3>
              <span
                className="filters-lookupname"
                role="heading"
                aria-level={3}
              >
                Saved views
              </span>
            </h3>
            <div className={`${showFilter ? "" : "d-none"}`}>
              <div className="d-flex" style={{ width: 248 }}>
                <TextField
                  id="txtFilterName"
                  label="Name and save this view"
                  value={currentFilterName}
                  placeholder="Enter name"
                  styles={inputFieldStyles}
                  onChange={(event, item) => {
                    setErrorMessage("");
                    setCurrentFilterName(item || "");
                  }}
                  required={true}
                  aria-required={true}
                  errorMessage={errorMessage}
                />
                <PrimaryButton
                  style={{ marginTop: 30, minWidth: 55, width: 55 }}
                  disabled={isSavedDataFiltersSaving === _LOADINGSTATE.pending}
                  ariaDescription="Save filters"
                  text="Save"
                  allowDisabledFocus
                  onClick={saveHandler}
                  styles={buttonStyles}
                />
              </div>
            </div>
            <div
              className={`${showFilter ? "" : "d-none"}`}
              style={{ width: 250 }}
            >
              {isSavedDataFiltersLoading === _LOADINGSTATE.pending ? (
                <>
                  <div style={{ marginTop: 8 }}></div>
                  <Spinner
                    size={SpinnerSize.large}
                    label="Saved views"
                    ariaLive="assertive"
                    labelPosition="top"
                  />
                </>
              ) : (
                <>
                  <Dropdown
                    placeholder={selectedFilterName}
                    label="Saved views"
                    onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => {
                      if (e.keyCode === 13) {
                        setShowFilterList(!showFilterList);
                        e.stopPropagation();
                        document.body.onclick = (event) => {
                          let target = event.target as HTMLElement | null;
                          let needReturn = false;
                          while (target) {
                            if (
                              target.className &&
                              typeof target.className.indexOf === "function" &&
                              target.className.indexOf("filter-list") > -1
                            ) {
                              needReturn = true;
                              break;
                            }
                            target = target.parentElement;
                          }
                          if (needReturn) {
                            return;
                          }
                          setShowFilterList(false);
                        };
                      } else if (e.keyCode === 27) {
                        setShowFilterList(false);
                      }
                    }}
                    onClick={(e) => {
                      setShowFilterList(!showFilterList);
                      e.stopPropagation();
                      document.body.onclick = (event) => {
                        let target = event.target as HTMLElement | null;
                        let needReturn = false;
                        while (target) {
                          if (
                            target.className &&
                            typeof target.className.indexOf === "function" &&
                            target.className.indexOf("filter-list") > -1
                          ) {
                            needReturn = true;
                            break;
                          }
                          target = target.parentElement;
                        }
                        if (needReturn) {
                          return;
                        }
                        setShowFilterList(false);
                      };
                    }}
                    options={[]}
                    styles={dropdownStyles}
                    calloutProps={{ doNotLayer: true }}
                  />
                  <div style={{ maxHeight: 0 }}>
                    <div
                      role="menu"
                      className="filter-list"
                      style={{
                        visibility: showFilterList ? "visible" : "hidden",
                      }}
                    >
                      {dataFilterList.map((filter: savedDataFilter) => (
                        <div
                          role="menuitem"
                          className={
                            selectedFilterName === filter.Name
                              ? "d-flex filter-list-item active"
                              : "d-flex filter-list-item"
                          }
                        >
                          <div
                            style={{ width: 204, overflow: "auto" }}
                            key={filter.Name}
                            onClick={() => {
                              setSelectedFilterName(filter.Name);
                              setCurrentFilterName(filter.Name);
                              updateUrl(filter.SavedDataFilterId);
                            }}
                            tabIndex={0}
                            aria-label={
                              filter.Name +
                              (selectedFilterName === filter.Name
                                ? " is selected"
                                : "")
                            }
                            onKeyDown={(
                              event: React.KeyboardEvent<HTMLDivElement>
                            ) => {
                              if (event.keyCode === 13) {
                                setSelectedFilterName(filter.Name);
                                setCurrentFilterName(filter.Name);
                                updateUrl(filter.SavedDataFilterId);
                              } else if (event.keyCode === 27) {
                                setShowFilterList(false);
                              }
                            }}
                          >
                            {filter.Name}
                          </div>
                          <div
                            style={{ width: 30 }}
                            key={filter.SavedDataFilterId}
                          >
                            <i
                              className="ms-Icon ms-Icon--Delete"
                              onClick={deleteFilterHandle}
                              data-filter-id={filter.SavedDataFilterId}
                              tabIndex={0}
                              aria-label={"Delete" + filter.Name}
                              role="button"
                              onKeyDown={(
                                event: React.KeyboardEvent<HTMLDivElement>
                              ) => {
                                if (event.keyCode === 13) {
                                  deleteFilterHandle(event);
                                } else if (event.keyCode === 27) {
                                  setShowFilterList(false);
                                }
                              }}
                            ></i>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <ConfirmationDialog
                    refs={deleteFilterDomref}
                    onclickCallBack={() => {
                      return new Promise((resolve, reject) => {
                        if (deleteFilterId !== 0) {
                          deleteHandler(deleteFilterId);
                          setDeleteFilterId(0);
                        }
                      });
                    }}
                    title="Deleting Filter"
                    subText="Are you sure you want to delete this filter permanently?"
                    confirmButtonString="Delete"
                    cancelButtonString="Cancel"
                  />
                </>
              )}
            </div>
            <div style={{ display: "flex" }}>
              <div
                className={`${
                  showFilter ? "share-this-view col-6 azure-security" : "d-none"
                }`}
              >
                <div style={{ width: 100 }}>
                  <label
                    className="ms-Label"
                    style={{ marginTop: 5, marginBottom: 5 }}
                  >
                    Share this view
                  </label>
                  <div
                    style={{ textAlign: "center" }}
                    role="link"
                    tabIndex={0}
                    onClick={() => {
                      setShowLinkCopied(false);
                      setShowShare(true);
                    }}
                  >
                    <i
                      style={{ fontSize: 26, cursor: "pointer" }}
                      data-icon-name="Share"
                      role="presentation"
                      aria-hidden="true"
                      className="ms-Icon root-48"
                    >
                      
                    </i>
                  </div>
                </div>
                {showShare ? (
                  <div className="share-link-view">
                    {showLinkCopied ? (
                      <>
                        <i
                          className="ms-Icon ms-Icon--Completed completedView"
                          title="Completed"
                          aria-hidden="true"
                        ></i>
                        <div className="copyLinkText">Link Copied</div>
                      </>
                    ) : null}
                    <i
                      className="ms-Icon ms-Icon--ChromeClose font-size-16 closeShareView"
                      title="Close"
                      aria-label="Close share view"
                      aria-hidden="true"
                      onClick={() => {
                        setShowLinkCopied(false);
                        setShowShare(false);
                      }}
                    />
                    <div className="link-wrap additional-pl15">
                      <div>
                        <label
                          className="control-label"
                          style={{ marginTop: 5, marginBottom: 5 }}
                        >
                          The view share link
                        </label>
                      </div>
                      <div>
                        <input
                          className="linkInputBox"
                          type="text"
                          aria-label="Share view link"
                          value={window.location.href}
                        />
                        <button
                          className="btn btn-sm btn-primary copyButton"
                          tabIndex={0}
                          onClick={() => {
                            navigator.clipboard.writeText(window.location.href);
                            setShowLinkCopied(true);
                          }}
                        >
                          Copy
                        </button>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
              <div
                className={`${
                  showFilter ? "col-6 text-end" : "col-6 d-none text-end"
                }`}
              >
                <PrimaryButton
                  ariaDescription="Clear filters"
                  ariaLabel="Clear filters"
                  text="Clear filters"
                  allowDisabledFocus
                  onClick={() => {
                    setSelectedFilterName("Select from your saved views");
                    setCurrentFilterName("");
                    let query = searchParams;
                    securityMilestoneFilterList.forEach((filter: filter) => {
                      query.delete(filter.filterName);
                    });
                    setSearchParam(query);
                    speakText("Cleared applied filters");
                  }}
                  styles={buttonStyles}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Filter;
