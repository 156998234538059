import httpClient from "./base";
import { SecurityProductService } from "../slice/securityProductService";

const baseURL = "/SecurityProductService";
const getSecurityProductService = () => {
  return httpClient.get(baseURL);
};

const SecurityProductServiceService = {
  getSecurityProductService,
};

export default SecurityProductServiceService;
