import { useContext } from "react";
import moment from "moment";
import striptags from "striptags";
import "../../../Shared/css/timelineGrid.scss";
import "../index.scss";
import { _AUTHORIZATIONTYPE } from "../../../Shared/constants";
import { azureTimelineMilestone } from "../../../../slice/azureTimeline";
import { MilestoneModalContext } from "../..";
import { truncateText, highlightSearch } from "../../../../utils/utils";

const MilestoneSearchRow = (props: {
  CSA: string;
  milestone: azureTimelineMilestone;
}) => {
  const milestoneModalContext = useContext(MilestoneModalContext);

  return (
    <>
      <div title={props.CSA}>{props.CSA}</div>
      <div
        dangerouslySetInnerHTML={{
          __html: highlightSearch(
            milestoneModalContext.searchHighlightPhrase,
            props.milestone.MilestoneId.toString()
          ),
        }}
      />
      <div
        title={props.milestone.MilestoneName}
        dangerouslySetInnerHTML={{
          __html: highlightSearch(
            milestoneModalContext.searchHighlightPhrase,
            truncateText(props.milestone.MilestoneName, 65)
          ),
        }}
      />
      <div
        title={striptags(props.milestone.MilestoneDescription)}
        dangerouslySetInnerHTML={{
          __html: highlightSearch(
            milestoneModalContext.searchHighlightPhrase,
            truncateText(striptags(props.milestone.MilestoneDescription), 100)
          ),
        }}
      />
      <div
        dangerouslySetInnerHTML={{
          __html:
            props.milestone.RoadmapStartDate === undefined
              ? ""
              : highlightSearch(
                  milestoneModalContext.searchHighlightPhrase,
                  moment(new Date(props.milestone.RoadmapStartDate))
                    .utc()
                    .format("MM/DD/YYYY")
                ),
        }}
      />
      <div
        dangerouslySetInnerHTML={{
          __html:
            props.milestone.DisclosureStartDate === undefined
              ? ""
              : highlightSearch(
                  milestoneModalContext.searchHighlightPhrase,
                  moment(new Date(props.milestone.DisclosureStartDate))
                    .utc()
                    .format("MM/DD/YYYY")
                ),
        }}
      />
      <div>
        {props.milestone.Tier === "1"
          ? "Tier 1"
          : props.milestone.Tier === "2"
          ? "Tier 2"
          : "Tier 3"}
      </div>
      <div
        dangerouslySetInnerHTML={{
          __html: highlightSearch(
            milestoneModalContext.searchHighlightPhrase,
            props.milestone.MilestoneTypeName
          ),
        }}
      />

      <div
        title={props.milestone.ContactName}
        dangerouslySetInnerHTML={{
          __html: highlightSearch(
            milestoneModalContext.searchHighlightPhrase,
            truncateText(props.milestone.ContactName, 50)
          ),
        }}
      />
      <div
        title={props.milestone.MarketingLeadName}
        dangerouslySetInnerHTML={{
          __html: highlightSearch(
            milestoneModalContext.searchHighlightPhrase,
            truncateText(props.milestone.MarketingLeadName, 50)
          ),
        }}
      />
      <div
        dangerouslySetInnerHTML={{
          __html: highlightSearch(
            milestoneModalContext.searchHighlightPhrase,
            props.milestone.AlignedMomentName || ""
          ),
        }}
      />
    </>
  );
};

export default MilestoneSearchRow;
