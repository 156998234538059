import React, { useState } from "react";
import "../../../Shared/css/timelineGrid.scss";
import "../index.scss";
import { milestoneGroupByProps } from "../../../../slice/azureTimeline";
import { SingleProductMilestone } from "./singleProductMilestone";
import { CollapseAll } from "./collapseAll";

const method: React.Dispatch<React.SetStateAction<boolean>> = () => {};

const openAllMilestoneDefault = {
  isOpen: true,
  isClick: false,
  setClick: method,
};

export const OpenAllMilestoneContext = React.createContext(
  openAllMilestoneDefault
);

export const ProductMilestone = (milestones: {
  productMilestones: milestoneGroupByProps;
}) => {
  const [openTdMilestone, setOpenTdMilestone] = useState(true);
  const [clickTdMilestone, setClickTdMilestone] = useState(true);

  const valueParam = {
    isOpen: openTdMilestone,
    isClick: clickTdMilestone,
    setClick: setClickTdMilestone,
  };

  return (
    <>
      <CollapseAll
        month={milestones.productMilestones.month}
        openTdMilestone={openTdMilestone}
        setOpenTdMilestone={setOpenTdMilestone}
        clickTdMilestone={clickTdMilestone}
        setClickTdMilestone={setClickTdMilestone}
      />
      <ul className="modernWorkTimeline-noStyleList">
        {milestones.productMilestones.milestones.map((pmilestone) => {
          return (
            <OpenAllMilestoneContext.Provider
              value={valueParam}
              key={pmilestone.productName}
            >
              <SingleProductMilestone productMilestones={pmilestone} />
            </OpenAllMilestoneContext.Provider>
          );
        })}
      </ul>
    </>
  );
};
