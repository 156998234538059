import { DirectionalHint, Icon, TooltipHost } from "@fluentui/react";
import "./graphUsersAutoCompleteWithTeamsIcon.scss";
import { isVoid } from "../../../utils/utils";
import { reactselectStyleProps } from "../styleProps";
import GraphUsersAutoComplete from "./graphUsersAutoComplete";

interface Props {
  onChangeHandler: (value: any) => void;
  error: string;
  value: string | undefined;
  label: string;
  id: string;
  disabled: boolean;
  microsoftUser?: boolean;
}

const GraphUsersAutoCompleteWithTeamsIcon = (props: Props) => {
  let email: string;
  let name: string;
  if (props.value && props.value.indexOf("[") < props.value.indexOf("]")) {
    const emailIndex = props.value.indexOf("[");
    const emailEnd = props.value.indexOf("]");
    email = props.value.substring(emailIndex + 1, emailEnd);
    name = props.value.substring(0, emailIndex).trim();
  } else {
    email = props.value || "";
    name = props.value || "";
  }
  return (
    <div>
      <label className="modal-detail-label" htmlFor={props.id}>
        {props.label}
      </label>
      <div className="d-flex justify-content-between">
        <div className="flex-fill">
          <GraphUsersAutoComplete
            selectStyle={{
              ...reactselectStyleProps,
              control: (provided: any, state: any) => ({
                ...provided,
                borderColor: props.value
                  ? props.value.length > 0
                    ? "rgb(96, 94, 92)"
                    : "rgb(164, 38, 44)"
                  : props.error
                  ? "rgb(164, 38, 44)"
                  : "rgb(96, 94, 92)",
                borderRadius: 0,
                boxShadow: "none",
                height: 32,
                minHeight: 0,
                width: "97%",
                ":hover": {
                  borderColor: props.value
                    ? props.value.length > 0
                      ? "rgb(96, 94, 92) !important"
                      : "rgb(164, 38, 44) !important"
                    : props.error
                    ? "rgb(164, 38, 44) !important"
                    : "rgb(96, 94, 92) !important",
                },
                background: state.isDisabled ? "#f2f2f2" : "rgb(255,255,255)",
                color: state.isDisabled ? "#999999" : "rgb(51, 51, 51)",
              }),
            }}
            selectedKey={props.value}
            onChangeHandler={props.onChangeHandler}
            microsoftUser={props.microsoftUser}
            ariaLabel={
              isVoid(props.label)
                ? props.label
                : props.label.trim().endsWith("*")
                ? props.label
                    .trim()
                    .substring(0, props.label.trim().length - 1)
                    .trim() + ", required field"
                : props.label.trim()
            }
            id={props.id}
            disabled={props.disabled}
          />
        </div>
        <a
          href={`im:${email}`}
          style={{ textDecoration: "none" }}
          aria-label={`Chat with ${
            isVoid(props.label)
              ? props.label
              : props.label.trim().endsWith("*")
              ? props.label
                  .trim()
                  .substring(0, props.label.trim().length - 1)
                  .trim()
              : props.label.trim()
          } ${name} Microsoft Teams icon clickable`}
          tabIndex={0}
        >
          <TooltipHost
            content="Teams Chat"
            directionalHint={DirectionalHint.topCenter}
          >
            <Icon
              iconName="TeamsLogo"
              tabIndex={-1}
              className="modal-form-float-right"
              ariaLabel="Teams chat"
            ></Icon>
          </TooltipHost>
        </a>
      </div>
      <span className="modal-detail-error">
        {props.value
          ? props.value.length > 0
            ? ""
            : props.error
          : props.error}
      </span>
    </div>
  );
};

export default GraphUsersAutoCompleteWithTeamsIcon;
