import React, { createRef, useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  TextField,
  PrimaryButton,
  Breadcrumb,
  IBreadcrumbItem,
  ITextFieldStyles,
} from "@fluentui/react";
import { breadcrumbStyles, buttonStyles } from "../../Shared/styleProps";
import "../../Shared/css/header.scss";
import {
  ContentShowMode,
  TimelineMode,
  ModalType,
} from "../../Shared/constants";
import { inputFieldStyles } from "../../Shared/timelineFilterStyleProps";
import { useAppDispatch } from "../../../store";
import { SecurityMilestoneModalContext } from "../index";
import * as securityTimelineReducer from "../../../slice/securityTimeline";

export const inputStyles: Partial<ITextFieldStyles> = {
  ...inputFieldStyles,
  root: { width: 365 },
};

const Header = () => {
  const securityMilestoneModalContext = useContext(
    SecurityMilestoneModalContext
  );

  const appDispatch = useAppDispatch();

  const requestMarketingWorkLink = createRef<HTMLAnchorElement>();
  const requestMarketingMomentLink = createRef<HTMLAnchorElement>();
  const helpResourcesLink = createRef<HTMLAnchorElement>();

  const getData = (currentSearchPhrase: string) => {
    securityMilestoneModalContext.setSearchHighlightPhrase(
      currentSearchPhrase.trim()
    );
    if (!currentSearchPhrase || currentSearchPhrase.trim().length === 0) {
      securityMilestoneModalContext.setTimelineMode(TimelineMode.Timeline);
      securityMilestoneModalContext.setContentShowMode(ContentShowMode.Grid);
      appDispatch(securityTimelineReducer.initSecurityTimelineDataSet());
    } else {
      securityMilestoneModalContext.setTimelineMode(TimelineMode.Search);
      securityMilestoneModalContext.setContentShowMode(ContentShowMode.Column);
      appDispatch(
        securityTimelineReducer.getSearchDataSet(currentSearchPhrase.trim())
      );
    }
  };

  const navigate = useNavigate();

  const itemsWithHref: IBreadcrumbItem[] = [
    {
      text: " < Cloud Marketing moments calendar",
      key: "f1",
      onClick: () => {
        navigate("/");
      },
    },
    { text: "Microsoft Security", key: "f2", href: "#!", isCurrentItem: true },
  ];

  return (
    <header className="main-header">
      <Breadcrumb
        role="heading"
        className="main-breadcrumb"
        items={itemsWithHref}
        maxDisplayedItems={3}
        aria-level={2}
        ariaLabel="Breadcrumb"
        overflowAriaLabel="More links"
        styles={breadcrumbStyles}
        dividerAs={() => {
          return <span> - </span>;
        }}
      />
      <h2 className="main-header-title">Microsoft Security</h2>
      <nav
        role="navigation"
        className="main-nav nav nav-pills"
        style={{ width: "1125px" }}
      >
        <ul className="noStyleList">
          <li className="nav-item">
            <PrimaryButton
              id="btnNewMilestone"
              className="nav-link"
              onClick={() => {
                securityMilestoneModalContext.setSecurityMilestoneModalStateHandler(
                  {
                    securityMilestoneId: 0,
                    modalType: ModalType.New,
                    deleteMilestoneDateHistoryId: "",
                  }
                );
                securityMilestoneModalContext.modalRef?.current?.click();
              }}
              role="link"
            >
              <span className="icon">
                <i
                  className="fa fa-plus"
                  aria-hidden="true"
                  aria-label="New milestone"
                ></i>
              </span>
              New milestone
            </PrimaryButton>
          </li>
          <li className="nav-item">
            <a
              href="https://microsoft.sharepoint.com/teams/CloudMarketingMoments/SitePages/Cloud-Marketing-moments-calendar-DRIs.aspx"
              target="_blank"
              rel="noreferrer"
              className="d-none"
              ref={requestMarketingMomentLink}
              aria-label="Request a marketing moment"
            >
              Request a marketing moment
            </a>
            <PrimaryButton
              className="nav-link"
              onClick={() => {
                requestMarketingMomentLink.current?.click();
              }}
              role="link"
            >
              <span className="icon">
                <i
                  className="fa fa-file-text-o"
                  aria-hidden="true"
                  aria-label="Request a marketing moment"
                ></i>
              </span>
              Request a marketing moment
            </PrimaryButton>
          </li>
          <li className="nav-item">
            <a
              href="https://badeintake.azurewebsites.net/"
              target="_blank"
              className="d-none"
              ref={requestMarketingWorkLink}
              rel="noreferrer"
              aria-label=" Request marketing work"
            >
              Request marketing work
            </a>
            <PrimaryButton
              className="nav-link"
              onClick={() => {
                requestMarketingWorkLink.current?.click();
              }}
              role="link"
            >
              <span className="icon">
                <i
                  className="fa fa-file-text-o"
                  aria-hidden="true"
                  aria-label=" Request marketing work"
                ></i>
              </span>
              Request marketing work
            </PrimaryButton>
          </li>
          <li className="nav-item">
            <a
              href="https://microsoft.sharepoint.com/teams/CloudMarketingMoments/SitePages/Cloud-Marketing-moments-help.aspx"
              target="_blank"
              rel="noreferrer"
              className="d-none"
              ref={helpResourcesLink}
              aria-label="Help and resources"
            >
              Help and resources
            </a>
            <PrimaryButton
              className="nav-link"
              onClick={() => {
                helpResourcesLink.current?.click();
              }}
              role="link"
            >
              <span className="icon">
                <i
                  className="fa fa-question"
                  aria-hidden="true"
                  aria-label="Help and resources"
                ></i>
              </span>
              Help and resources
            </PrimaryButton>
          </li>
        </ul>
      </nav>
      <div role="search" className="search" style={{ width: "405px" }}>
        <TextField
          placeholder="Search"
          value={securityMilestoneModalContext.searchPhrase}
          styles={inputStyles}
          onChange={(event, item) => {
            securityMilestoneModalContext.setSearchPhrase(item || "");
            if (item === undefined || item.trim().length === 0) {
              getData(item || "");
            }
          }}
          onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
            if (event.keyCode === 13) {
              getData(securityMilestoneModalContext.searchPhrase);
            }
          }}
        />
        <PrimaryButton
          style={{ minWidth: 55, width: 55 }}
          ariaDescription="Search"
          ariaLabel="Search"
          text="Go"
          allowDisabledFocus
          onClick={() => {
            document.body.querySelector("#ariaLiveReader")!.innerHTML = "";
            getData(securityMilestoneModalContext.searchPhrase);
          }}
          styles={buttonStyles}
        />
      </div>
    </header>
  );
};

export default Header;
