import React, { useState } from "react";
import { Link } from "@fluentui/react";
import { useBoolean, useId } from "@fluentui/react-hooks";
import striptags from "striptags";
import "./index.scss";
import { _LOADINGSTATE } from "../../Shared/constants";
import { DataSetProps } from "../../../slice/azureTimeline";
import MomentTooltip from "./momentTooltip";

const RenderMoments = (customEventDateAndMomentsList: {
  dataSet: Omit<DataSetProps, "milestone">[];
}) => {
  return (
    <>
      {customEventDateAndMomentsList.dataSet.map((item, index) => {
        return item.month !== "" ? (
          <div
            className={`grid-item item-moment ${
              index !== 0 ? "moment-border-left" : ""
            }`}
            key={"moments_" + item.month}
          >
            {item.isLoading === _LOADINGSTATE.fullfilled ? (
              <>
                <div
                  className="moment-title-flex"
                  onClick={(e) => {
                    let collapseLink = document.querySelector(
                      ".moment-title-collapse-all-text[data-month='" +
                        item.month +
                        "']"
                    ) as HTMLLinkElement;
                    if (collapseLink) {
                      let momentTitleCollapsed =
                        collapseLink.innerText === "Collapse all";
                      collapseLink.innerText = momentTitleCollapsed
                        ? "Expand all"
                        : "Collapse all";
                      collapseLink.ariaLabel =
                        "Marketing moments for " +
                        item.month +
                        (momentTitleCollapsed
                          ? " Expand all"
                          : " Collapse all");
                      collapseLink.ariaExpanded = momentTitleCollapsed
                        ? "false"
                        : "true";
                      let momentDiv = document.querySelector(
                        ".moment-flex[data-month='" + item.month + "']"
                      ) as HTMLDivElement;
                      if (momentDiv) {
                        if (momentTitleCollapsed) {
                          momentDiv.classList.add("d-none");
                        } else {
                          momentDiv.classList.remove("d-none");
                        }
                      }
                    }
                  }}
                  onKeyDown={(e) => {
                    if (e.keyCode === 13) {
                      e.currentTarget.click();
                    }
                  }}
                >
                  <div className="moment-title">
                    <span
                      className="timeline-customeventdateAndmoments-title"
                      role="button"
                      aria-label={"Marketing moments for " + item.month}
                    >
                      Marketing moments
                    </span>
                  </div>
                  <div>
                    <a
                      href={void 0}
                      className="moment-title-collapse-all-text"
                      tabIndex={0}
                      role="button"
                      aria-label={
                        "Marketing moments for " + item.month + "Collapse all"
                      }
                      aria-expanded="true"
                      data-month={item.month}
                    >
                      Collapse all
                    </a>
                  </div>
                </div>
                <div data-month={item.month} className="moment-flex">
                  {item.moments.moment
                    .map((moment, i) => {
                      return {
                        id: moment.Id,
                        text: striptags(moment.DisplayText),
                      };
                    })
                    .map((moment, i) => {
                      return (
                        <div
                          className="item-customeventdateAndmoments"
                          key={"moments_" + i}
                        >
                          <RenderMomentTooltip
                            id={moment.id}
                            text={moment.text}
                          ></RenderMomentTooltip>
                        </div>
                      );
                    })}
                </div>
              </>
            ) : null}
          </div>
        ) : null;
      })}
    </>
  );
};

const RenderMomentTooltip = (moment: { id: number; text: string }) => {
  const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] =
    useBoolean(false);
  const buttonId = useId("callout-button");

  return (
    <>
      <Link
        id={buttonId}
        onClick={toggleIsCalloutVisible}
        style={{ color: "#000" }}
      >
        {moment.text}
      </Link>
      {isCalloutVisible && (
        <MomentTooltip
          id={moment.id}
          target={buttonId}
          dismiss={toggleIsCalloutVisible}
        ></MomentTooltip>
      )}
    </>
  );
};

export default RenderMoments;
