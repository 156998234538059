import httpClient from "./base";
const baseURL = "CustomTrainDate";

const getCustomTrainDateList = () => {
  var url = `${baseURL}`;
  return httpClient.get(url);
};

const customTrainService = {
  getCustomTrainDateList,
};

export default customTrainService;
