import React, { useContext, useEffect, useState } from "react";
import moment from "moment";
import { Callout, Icon, Spinner, SpinnerSize } from "@fluentui/react";
import { isVoid } from "../../../utils/utils";
import { ModalType, TimelineType } from "../../Shared/constants";
import { useAppDispatch } from "../../../store";
import {
  modernWorkSecurityEventCalendarMoment,
  getModernWorkSecurityEventCalendarMomentById,
} from "../../../slice/modernWorkSecurityEventCalendarMoment";
import Accordion from "../../Shared/accordion";
import {
  getFeatureByMomentId,
  ModernWorkFeatureByTier,
} from "../../../slice/modernWorkFeature";
import { ModernWorkFeatureModalContext } from "../index";

const MomentTooltip = (tooltip: {
  id: number;
  target: string;
  dismiss: () => void;
}) => {
  const modernWorkFeatureModalContext = useContext(
    ModernWorkFeatureModalContext
  );

  const appDispatch = useAppDispatch();
  const [
    modernWorkSecurityEventCalendarMomentInfo,
    setModernWorkSecurityEventCalendarMomentInfo,
  ] = useState<modernWorkSecurityEventCalendarMoment>();
  const [
    modernWorkSecurityMSETSFeatureByTierInfo,
    setModernWorkSecurityMSETSFeatureByTierInfo,
  ] = useState<ModernWorkFeatureByTier[]>();

  useEffect(() => {
    if (modernWorkSecurityEventCalendarMomentInfo === undefined) {
      appDispatch(
        getModernWorkSecurityEventCalendarMomentById(tooltip.id)
      ).then((response) => {
        setModernWorkSecurityEventCalendarMomentInfo(
          response.payload as modernWorkSecurityEventCalendarMoment
        );
      });
    } else if (modernWorkSecurityMSETSFeatureByTierInfo === undefined) {
      if (
        modernWorkSecurityEventCalendarMomentInfo.Id !== null &&
        modernWorkSecurityEventCalendarMomentInfo.Id !== undefined
      ) {
        appDispatch(
          getFeatureByMomentId(modernWorkSecurityEventCalendarMomentInfo.Id)
        ).then((response) => {
          setModernWorkSecurityMSETSFeatureByTierInfo(
            response.payload as ModernWorkFeatureByTier[]
          );
        });
      }
    }
  }, [modernWorkSecurityEventCalendarMomentInfo]);

  return (
    <Callout
      style={{ maxHeight: 800, width: 500, padding: "20px 24px" }}
      target={`#${tooltip.target}`}
      onDismiss={tooltip.dismiss}
      role="dialog"
      aria-live="assertive"
      doNotLayer={true}
    >
      {modernWorkSecurityEventCalendarMomentInfo === undefined ? (
        <Spinner size={SpinnerSize.small} ariaLive="polite" />
      ) : (
        <div style={{ lineHeight: "32px" }} tabIndex={0}>
          <div>
            {moment(
              new Date(modernWorkSecurityEventCalendarMomentInfo.StartDate)
            )
              .utc()
              .format("MM/DD/YYYY") ===
            moment(new Date(modernWorkSecurityEventCalendarMomentInfo.EndDate))
              .utc()
              .format("MM/DD/YYYY")
              ? moment(
                  new Date(modernWorkSecurityEventCalendarMomentInfo.StartDate)
                )
                  .utc()
                  .format("MM/DD/YYYY")
              : moment(
                  new Date(modernWorkSecurityEventCalendarMomentInfo.StartDate)
                )
                  .utc()
                  .format("MM/DD/YYYY") +
                " - " +
                moment(
                  new Date(modernWorkSecurityEventCalendarMomentInfo.EndDate)
                )
                  .utc()
                  .format("MM/DD/YYYY")}
          </div>
          <div>{modernWorkSecurityEventCalendarMomentInfo.Title}</div>
          <div>
            <Icon iconName="POISolid"></Icon>{" "}
            {modernWorkSecurityEventCalendarMomentInfo.Location}
          </div>
          <Accordion title="Features" isDefaultOpen={true}>
            {modernWorkSecurityMSETSFeatureByTierInfo === undefined ? (
              <Spinner size={SpinnerSize.small} ariaLive="polite" />
            ) : (
              modernWorkSecurityMSETSFeatureByTierInfo?.map(
                (ModernWorkFeatureByTier: ModernWorkFeatureByTier) => {
                  return (
                    <>
                      <div tabIndex={0}>
                        <strong>
                          {"Tier " + ModernWorkFeatureByTier.tier}
                        </strong>
                      </div>
                      <ul>
                        {ModernWorkFeatureByTier.features.map((feature) => {
                          const featureTitle = !isVoid(feature.MarketingTitle)
                            ? feature.MarketingTitle
                            : !isVoid(feature.PublicTitle)
                            ? feature.PublicTitle
                            : !isVoid(feature.Title)
                            ? feature.Title
                            : "";
                          return (
                            <li>
                              <a
                                href={
                                  window.location.origin +
                                  "/ModernWorkTimeline?featureId=" +
                                  feature.Id
                                }
                                onClick={(e) => {
                                  e.preventDefault();
                                  modernWorkFeatureModalContext.setModernWorkFeatureStateHandler(
                                    {
                                      featureTitle: featureTitle,
                                      featureId: feature.Id,
                                      cchFeatureId: feature.CCHFeatureId,
                                      featureDisclosureDate: null,
                                      featurePublicDisclosureDate: null,
                                      deleteFeatureHistoryId: "",
                                      modalType: ModalType.Edit,
                                    }
                                  );
                                  modernWorkFeatureModalContext.modalRef?.current?.click();
                                }}
                                title={featureTitle}
                              >
                                {featureTitle}
                              </a>
                            </li>
                          );
                        })}
                      </ul>
                    </>
                  );
                }
              )
            )}
          </Accordion>
        </div>
      )}
    </Callout>
  );
};

export default MomentTooltip;
