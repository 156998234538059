import httpClient from "./base";

const baseURL = "/BagMilestone";

const getBagMilestonesByMomentId = (id: number) => {
  var url = `${baseURL}/GetBagMilestonesByMomentId?id=` + id;
  console.log("Get Bag by Moment Id");
  return httpClient.get(url);
};
export interface BagMilestones {
  MilestoneId: string;
  MilestoneName: string;
  StartDate: string;
  SolutionArea: string;
  TierId: string;
}

export interface bagMilestonesGroupByTier {
  tier: string;
  bagMilestones: BagMilestones[];
}
const bagMilestonesService = {
  getBagMilestonesByMomentId,
};

export default bagMilestonesService;
