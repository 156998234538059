import { useContext, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { Icon } from "@fluentui/react";
import { _LOADINGSTATE } from "../Shared/constants";
import "../Shared/css/modal.scss";
import * as securityMilestoneDateHistoryReducer from "../../slice/securityMilestoneDateHistory";
import { RootState, useAppDispatch } from "../../store";
import { formatDate } from "../../utils/date";
import { SecurityMilestoneModalContext } from "../SecurityTimeline";
import Accordion from "../Shared/accordion";

export const DetailsHistory = (props: {
  appearErrorInfo: boolean;
  disabled: boolean;
}) => {
  const appDispatch = useAppDispatch();

  const securityMilestoneModalContext = useContext(
    SecurityMilestoneModalContext
  );

  useEffect(() => {
    if (
      securityMilestoneModalContext.securityMilestoneModalState
        .securityMilestoneId > 0
    ) {
      appDispatch(
        securityMilestoneDateHistoryReducer.getSecurityMilestoneDateHistory(
          securityMilestoneModalContext.securityMilestoneModalState
            .securityMilestoneId
        )
      );
    }
  }, [
    securityMilestoneModalContext.securityMilestoneModalState
      .securityMilestoneId,
  ]);

  const securityMilestoneDateHistory = useSelector<
    RootState,
    securityMilestoneDateHistoryReducer.SecurityMilestoneDateHistory[]
  >(
    (state) =>
      state.securityMilestoneDateHistoryReducer.securityMilestoneDateHistory
  );

  const showSecurityMilestoneDateHistory = useMemo(() => {
    return securityMilestoneDateHistory.length > 0
      ? securityMilestoneDateHistory
          .filter(
            (h) =>
              securityMilestoneModalContext.securityMilestoneModalState.deleteMilestoneDateHistoryId
                .split(",")
                .filter((i) => i != "")
                .map((i) => parseInt(i))
                .indexOf(h.Id) == -1
          )
          .slice(0, 5)
      : null;
  }, [
    securityMilestoneDateHistory,
    securityMilestoneModalContext.securityMilestoneModalState
      .deleteMilestoneDateHistoryId,
  ]);

  return showSecurityMilestoneDateHistory != null &&
    showSecurityMilestoneDateHistory.length > 0 ? (
    <Accordion isDefaultOpen={true} title={"Marketing activities"}>
      <div className="feature-detail-history security-dates">
        <div className="row" style={{ marginLeft: 0, marginRight: 0 }}>
          <div className="date-history disabled">
            <div>
              <strong>Previous public disclosure</strong>
            </div>
          </div>
          <div className="date-history disabled">
            <div>
              <strong>Previous private preview</strong>
            </div>
          </div>
          <div className="date-history disabled">
            <div>
              <strong>Previous public preview</strong>
            </div>
          </div>
          <div className="col-2 disabled">
            <div>
              <strong>Previous GA</strong>
            </div>
          </div>
          <div className="col-2 disabled">
            <div>
              <strong>Updated by</strong>
            </div>
          </div>
          <div className="col-1 disabled"> </div>
        </div>
        {showSecurityMilestoneDateHistory.map((item) => {
          return (
            <div className="row" style={{ marginLeft: 0, marginRight: 0 }}>
              <div className="date-history disabled">
                <div>{formatDate(item.PublicDisclosureDate)}</div>
              </div>
              <div className="date-history disabled">
                <div>{formatDate(item.PrivatePreviewDate)}</div>
              </div>
              <div className="date-history disabled">
                <div>{formatDate(item.PublicPreviewDate)}</div>
              </div>
              <div className="col-2 disabled">
                <div>{formatDate(item.GADate)}</div>
              </div>
              <div className="col-2 disabled">
                <div>{item.ModifiedBy}</div>
              </div>
              <div className="col-1 disabled">
                {!props.disabled ? (
                  <>
                    <button
                      disabled={props.appearErrorInfo}
                      style={{
                        backgroundColor: "transparent",
                        border: "none",
                      }}
                      onClick={(event) => {
                        event.preventDefault();
                        securityMilestoneModalContext.setSecurityMilestoneModalStateHandler(
                          {
                            ...securityMilestoneModalContext.securityMilestoneModalState,
                            deleteMilestoneDateHistoryId:
                              securityMilestoneModalContext
                                .securityMilestoneModalState
                                .deleteMilestoneDateHistoryId +
                              item.Id.toString() +
                              ",",
                          }
                        );
                      }}
                    >
                      <Icon iconName="Delete"></Icon>
                    </button>
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>
          );
        })}
      </div>
    </Accordion>
  ) : null;
};
