import React, { Component } from "react";
import { ErrorMessage } from "./components/Shared/messageBox";

interface IProps {}

interface IState {
  hasError: boolean;
}

export default class ErrorBoundary extends Component<IProps, IState> {
  public constructor(props: IProps) {
    super(props);
    this.setState({ hasError: false });
  }

  componentDidCatch(error: any, info: any) {
    this.setState({ hasError: true });
    ErrorMessage.show(error.message);
  }

  render(): any {
    if (
      this.state &&
      this.state !== null &&
      this.state.hasError &&
      this.state.hasError !== null &&
      this.state.hasError === true
    ) {
      return null;
    }

    return this.props.children;
  }
}
