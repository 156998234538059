import React, { useMemo, useEffect } from "react";
import moment from "moment";
import "../../Shared/css/timelineGrid.scss";
import "./index.scss";
import { useSelector } from "react-redux";
import { useUrlQueryParam } from "../../../utils/url";
import { RootState, useAppDispatch } from "../../../store";
import { MomentsGroupByProps } from "../../../slice/timeline";
import * as securityTimelineReducer from "../../../slice/securityTimeline";
import {
  filter,
  securityMilestoneFilterList,
} from "../../../slice/savedDataFilters";
import {
  SecurityMilestone,
  SecurityMilestoneMessagingMaterial,
} from "../../../slice/securityMilestone";

export interface DataSetProps {
  month: string;
  moments: MomentsGroupByProps;
  milestone: securityTimelineReducer.SecurityMilestoneGroupByProps;
}

const backgroundColor = Object.freeze({
  header: { backgroundColor: "rgb(255, 242, 204)" },
  confidenceLow1: { backgroundColor: "rgb(255, 0, 0)" },
  confidenceMedium2: { backgroundColor: "rgb(255, 255, 0)" },
  confidenceHigh3: { backgroundColor: "rgb(0, 176, 80)" },
});

const Content = () => {
  const appDispatch = useAppDispatch();
  const dataSource = useSelector(
    (state: RootState) => state.securityTimelineReducer.excelDownloadDataSet
  );
  const [param] = useUrlQueryParam(
    securityMilestoneFilterList.map((f: filter) => {
      return f.filterName;
    })
  );

  const isLoadingExportExcelSecurityMilestone = useSelector(
    (state: RootState) =>
      state.securityTimelineReducer.isLoadingExportExcelMilestone
  );

  const isLoadedExportExcelSecurityMilestone = useSelector(
    (state: RootState) =>
      state.securityTimelineReducer.isLoadedExportExcelMilestone
  );

  const isExportingExcelSecurityMilestone = useSelector(
    (state: RootState) =>
      state.securityTimelineReducer.isExportingExcelMilestone
  );

  const milestoneDataSet: {
    productName: string;
    milestoneExcelDownload: SecurityMilestone;
  }[] = useMemo(() => {
    let filterMilestones: {
      productName: string;
      milestoneExcelDownload: SecurityMilestone;
    }[] = [];
    dataSource.forEach(
      (product: {
        productName: string;
        milestoneExcelDownload: SecurityMilestone[];
      }) => {
        product.milestoneExcelDownload?.forEach(
          (milestone: SecurityMilestone) => {
            if (param.ProductServices) {
              if (
                decodeURIComponent(param.ProductServices)
                  .split(";|;")
                  .filter((item) => item !== "")
                  .indexOf(milestone.ProductServiceName) === -1
              ) {
                return true;
              }
            }

            if (param.ProductCategory) {
              if (
                decodeURIComponent(param.ProductCategory)
                  .split(";|;")
                  .filter((item) => item !== "")
                  .indexOf(milestone.ProductCategory) === -1
              ) {
                return true;
              }
            }

            if (param.SolutionPlay) {
              if (
                decodeURIComponent(param.SolutionPlay)
                  .split(";|;")
                  .filter((item) => item !== "")
                  .indexOf(milestone.SolutionPlay) === -1
              ) {
                return true;
              }
            }

            if (param.ProductFamily) {
              if (
                decodeURIComponent(param.ProductFamily)
                  .split(";|;")
                  .filter((item) => item !== "")
                  .indexOf(milestone.ProductFamily) === -1
              ) {
                return true;
              }
            }

            if (param.PMM) {
              if (decodeURIComponent(param.PMM) !== milestone.PMM) {
                return true;
              }
            }

            if (param.SecurityAudience) {
              if (
                milestone.SecurityMilestoneAudiences === undefined ||
                milestone.SecurityMilestoneAudiences === null ||
                decodeURIComponent(param.SecurityAudience)
                  .split(";|;")
                  .filter((item) => item !== "")
                  .filter(
                    (item) =>
                      milestone.SecurityMilestoneAudiences.findIndex(
                        (a) => a.SecurityAudienceId.toString() === item
                      ) !== -1
                  ).length === 0
              ) {
                return true;
              }
            }

            if (param.TierId) {
              if (
                decodeURIComponent(param.TierId)
                  .split(";|;")
                  .filter((item) => item !== "")
                  .indexOf(milestone.TierId.toString()) === -1
              ) {
                return true;
              }
            }

            if (param.SecurityMilestoneTypeId) {
              if (
                decodeURIComponent(param.SecurityMilestoneTypeId)
                  .split(";|;")
                  .filter((item) => item !== "")
                  .indexOf(milestone.SecurityMilestoneTypeId.toString()) === -1
              ) {
                return true;
              }
            }

            if (param.MomentId) {
              if (
                milestone.ModernWorkSecurityEventCalendarMomentsId === null ||
                milestone.ModernWorkSecurityEventCalendarMomentsId ===
                  undefined ||
                decodeURIComponent(param.MomentId)
                  .split(";|;")
                  .filter((item) => item !== "")
                  .indexOf(
                    milestone.ModernWorkSecurityEventCalendarMomentsId.toString()
                  ) === -1
              ) {
                return true;
              }
            }

            if (param.FiscalYear) {
              if (
                decodeURIComponent(param.FiscalYear)
                  .split(";|;")
                  .filter((item) => item !== "")
                  .indexOf(milestone.FiscalYear.toString()) === -1
              ) {
                return true;
              }
            }

            if (param.FiscalQuarter) {
              if (
                decodeURIComponent(param.FiscalQuarter)
                  .split(";|;")
                  .filter((item) => item !== "")
                  .indexOf(milestone.FiscalQuarter.toString()) === -1
              ) {
                return true;
              }
            }

            if (param.FiscalMonth) {
              if (
                decodeURIComponent(param.FiscalMonth)
                  .split(";|;")
                  .filter((item) => item !== "")
                  .indexOf(milestone.FiscalMonth.toString()) === -1
              ) {
                return true;
              }
            }

            filterMilestones.push({
              productName: product.productName,
              milestoneExcelDownload: milestone,
            });
          }
        );
      }
    );

    return filterMilestones;
  }, [dataSource, param]);

  useEffect(() => {
    if (isExportingExcelSecurityMilestone) {
      if (
        isLoadingExportExcelSecurityMilestone === false &&
        isLoadedExportExcelSecurityMilestone === false
      ) {
        appDispatch(
          securityTimelineReducer.actions.setIsLoadedExportExcelMilestone(true)
        );
      }
    }
  }, [
    appDispatch,
    milestoneDataSet,
    isLoadingExportExcelSecurityMilestone,
    isLoadedExportExcelSecurityMilestone,
    isExportingExcelSecurityMilestone,
  ]);
  return (
    <div className="d-none">
      <table id="timeline-table-excel-download">
        <tbody>
          <tr>
            <td style={backgroundColor.header}>
              <strong>Milestone ID</strong>
            </td>
            <td style={backgroundColor.header}>
              <strong>Public title</strong>
            </td>
            <td style={backgroundColor.header}>
              <strong>Product/Service</strong>
            </td>
            <td style={backgroundColor.header}>
              <strong>What's new or changing?</strong>
            </td>
            <td style={backgroundColor.header}>
              <strong>Customer benefits</strong>
            </td>
            <td style={backgroundColor.header}>
              <strong>Solution play</strong>
            </td>
            <td style={backgroundColor.header}>
              <strong>Milestone moment alignment</strong>
            </td>
            <td style={backgroundColor.header}>
              <strong>Public preview date</strong>
            </td>
            <td style={backgroundColor.header}>
              <strong>General availability date</strong>
            </td>
            <td style={backgroundColor.header}>
              <strong>Confidence</strong>
            </td>
            <td style={backgroundColor.header}>
              <strong>Comments</strong>
            </td>
            <td style={backgroundColor.header}>
              <strong>Field blockers</strong>
            </td>
            <td style={backgroundColor.header}>
              <strong>FY revenue at risk</strong>
            </td>
            <td>
              <strong>Milestone tier</strong>
            </td>
            <td>
              <strong>Milestone type</strong>
            </td>
            <td>
              <strong>Milestone fiscal year</strong>
            </td>
            <td>
              <strong>Milestone fiscal quarter</strong>
            </td>
            <td>
              <strong>Milestone fiscal month</strong>
            </td>
            <td>
              <strong>Product category</strong>
            </td>
            <td>
              <strong>Product family</strong>
            </td>
            <td>
              <strong>Marketing theme</strong>
            </td>
            <td>
              <strong>Public disclosure date</strong>
            </td>
            <td>
              <strong>Private preview date</strong>
            </td>
            <td>
              <strong>Announcement group</strong>
            </td>
            <td>
              <strong>Messaging materials title</strong>
            </td>
            <td>
              <strong>Messaging materials link</strong>
            </td>
            <td>
              <strong>Target audience</strong>
            </td>
          </tr>
          {milestoneDataSet.map(
            (milestone: {
              productName: string;
              milestoneExcelDownload: SecurityMilestone;
            }) => {
              return (
                <tr>
                  <td>
                    {milestone.milestoneExcelDownload.SecurityMilestoneId}
                  </td>
                  <td>{milestone.milestoneExcelDownload.PublicTitle}</td>
                  <td>{milestone.milestoneExcelDownload.ProductServiceName}</td>

                  <td>{milestone.milestoneExcelDownload.NewOrChanging}</td>
                  <td>{milestone.milestoneExcelDownload.CustomerBenefits}</td>
                  <td>{milestone.milestoneExcelDownload.SolutionPlay}</td>
                  <td>
                    {
                      milestone.milestoneExcelDownload
                        .ModernWorkSecurityEventCalendarMoments?.Title
                    }
                  </td>
                  <td>
                    {milestone.milestoneExcelDownload.PublicPreviewDate
                      ? moment(
                          new Date(
                            milestone.milestoneExcelDownload.PublicPreviewDate
                          )
                        )
                          .utc()
                          .format("MM/DD/YYYY")
                      : ""}
                  </td>
                  <td>
                    {milestone.milestoneExcelDownload.GeneralAvailabilityDate
                      ? moment(
                          new Date(
                            milestone.milestoneExcelDownload.GeneralAvailabilityDate
                          )
                        )
                          .utc()
                          .format("MM/DD/YYYY")
                      : ""}
                  </td>
                  <td
                    style={
                      milestone.milestoneExcelDownload.SecurityConfidenceId ===
                      1
                        ? backgroundColor.confidenceLow1
                        : milestone.milestoneExcelDownload
                            .SecurityConfidenceId === 3
                        ? backgroundColor.confidenceHigh3
                        : backgroundColor.confidenceMedium2
                    }
                  >
                    {milestone.milestoneExcelDownload.SecurityConfidence?.Name}
                  </td>
                  <td>{milestone.milestoneExcelDownload.Comment}</td>
                  <td>
                    {milestone.milestoneExcelDownload.BlockerReason &&
                      milestone.milestoneExcelDownload.BlockerReason.map(
                        (r) => r?.FieldBlocker?.Reason ?? ""
                      ).join(";")}
                  </td>
                  <td>
                    {milestone.milestoneExcelDownload.BlockerReasonAmount}
                  </td>
                  <td>
                    {milestone.milestoneExcelDownload.TierId === 1
                      ? "Tier 1"
                      : milestone.milestoneExcelDownload.TierId === 2
                      ? "Tier 2"
                      : milestone.milestoneExcelDownload.TierId === 3
                      ? "Tier 3"
                      : ""}
                  </td>
                  <td>
                    {milestone.milestoneExcelDownload.SecurityMilestoneTypeName}
                  </td>

                  <td>{"FY" + milestone.milestoneExcelDownload.FiscalYear}</td>
                  <td>
                    {"Q" + milestone.milestoneExcelDownload.FiscalQuarter}
                  </td>
                  <td>{milestone.milestoneExcelDownload.FiscalMonth}</td>
                  <td>{milestone.milestoneExcelDownload.ProductCategory}</td>
                  <td>{milestone.milestoneExcelDownload.ProductFamily}</td>

                  <td>{milestone.milestoneExcelDownload.MarketingTheme}</td>
                  <td>
                    {milestone.milestoneExcelDownload.PublicDisclosureDate
                      ? moment(
                          new Date(
                            milestone.milestoneExcelDownload.PublicDisclosureDate
                          )
                        )
                          .utc()
                          .format("MM/DD/YYYY")
                      : ""}
                  </td>
                  <td>
                    {milestone.milestoneExcelDownload.PrivatePreviewDate
                      ? moment(
                          new Date(
                            milestone.milestoneExcelDownload.PrivatePreviewDate
                          )
                        )
                          .utc()
                          .format("MM/DD/YYYY")
                      : ""}
                  </td>
                  <td>{milestone.milestoneExcelDownload.AnnouncementGroup}</td>
                  <td>
                    {milestone.milestoneExcelDownload.MessagingMaterials.map(
                      (m: SecurityMilestoneMessagingMaterial) => {
                        return m.Content + ";";
                      }
                    )}
                  </td>
                  <td>
                    {milestone.milestoneExcelDownload.MessagingMaterials.map(
                      (m: SecurityMilestoneMessagingMaterial) => {
                        return m.Url + ";";
                      }
                    )}
                  </td>
                  <td>
                    {milestone.milestoneExcelDownload.SecurityMilestoneAudiences?.map(
                      (a) => a.Name
                    )?.join(";") ?? ""}
                  </td>
                </tr>
              );
            }
          )}
        </tbody>
      </table>
    </div>
  );
};

export default Content;
