import React from "react";
import moment from "moment";
import "../../Shared/css/timelineGrid.scss";
import "./index.scss";
import { convertMonthStringToDate } from "../../../utils/date";
import { DataSetProps } from "../../../slice/securityTimeline";

const RenderMonth = (monthListParam: {
  dataSet: Omit<DataSetProps, "moments" | "feature">[];
}) => {
  return (
    <>
      {monthListParam.dataSet.map((item, index) => {
        return item.month !== "" ? (
          <div
            key={item.month}
            className={`grid-item item-month ${
              index !== 0 ? "border-left" : ""
            }`}
            aria-label={moment(convertMonthStringToDate(item.month)).format(
              "MMMM yyyy"
            )}
            role="tab"
          >
            {moment(convertMonthStringToDate(item.month)).format("MMMM yyyy")}
          </div>
        ) : null;
      })}
    </>
  );
};

export default RenderMonth;
