export namespace MilestoneUtil {
  interface ColorProp {
    [key: string]: string;
  }

  export namespace Colors {
    const milestoneClassNames: ColorProp = {
      // expect milestoneId comes in as index 1 - 7
      m0: "",
      m1: "legend-item-priprev",
      m2: "legend-item-pubprev",
      m3: "legend-item-rtm",
      m4: "legend-item-ga",
      m5: "legend-item-eol",
      m6: "legend-item-ga-retired",
      m9: "legend-item-disclosure",
      m10: "legend-item-preret",
    };

    const milestoneColors: ColorProp = {
      // expect milestoneId comes in as index 1 - 7
      m0: "",
      m1: "#fef000",
      m2: "#9bf00b",
      m3: "#50E6FF",
      m4: "#20b585",
      m5: "#50e6ff",
      m6: "#d59dff",
      m9: "#ff9349",
      m10: "#8620b5",
    };

    export const milestone = (id: number) => {
      if (Object.keys(milestoneClassNames).indexOf("m" + id) > -1) {
        let index = `m${id}`;
        return milestoneClassNames[index];
      } else return milestoneClassNames["m0"];
    };

    export const milestoneColor = (id: string) => {
      if (Object.keys(milestoneColors).indexOf("m" + id) > -1) {
        let index = `m${id}`;
        return milestoneColors[index];
      } else return milestoneColors["m0"];
    };
  }
}

export namespace SecurityMilestoneUtil {
  interface ColorProp {
    [key: string]: string;
  }

  export namespace Colors {
    const milestoneClassNames: ColorProp = {
      m0: "",
      m1: "legend-item-public-disclosure",
      m2: "legend-item-priprev",
      m3: "legend-item-pubprev",
      m5: "legend-item-ga",
    };

    const milestoneColors: ColorProp = {
      // expect milestoneId comes in as index 1 - 7
      m0: "",
      m1: "#fef000",
      m2: "#9bf00b",
      m3: "#50E6FF",
      m5: "#50e6ff",
    };

    export const milestone = (id: number) => {
      if (Object.keys(milestoneClassNames).indexOf("m" + id) > -1) {
        let index = `m${id}`;
        return milestoneClassNames[index];
      } else return milestoneClassNames["m0"];
    };

    export const milestoneColor = (id: string) => {
      if (Object.keys(milestoneColors).indexOf("m" + id) > -1) {
        let index = `m${id}`;
        return milestoneColors[index];
      } else return milestoneColors["m0"];
    };
  }
}
