import moment from "moment-timezone";

export const isVoidDateTime = (value?: string) =>
  value === undefined ||
  value === null ||
  value === "" ||
  moment(new Date(value)).utc().format("MM/DD/YYYY") === "01/01/1900";

export const getMonthList = (startDate: Date, endDate: Date) => {
  let dateArray = [];
  let currentDate = moment(startDate);
  const stopDate = moment(endDate);
  while (currentDate <= stopDate) {
    dateArray.push(currentDate.format("YYYY/MM"));
    currentDate = currentDate.add(1, "days");
  }

  let months = Array.from(new Set(dateArray));
  return months;
};

export const formatDate = (rawDate?: string | null): string => {
  if (
    rawDate === undefined ||
    rawDate === null ||
    rawDate.length === 0 ||
    rawDate === "Invalid Date" ||
    moment(new Date(rawDate)).utc().format("MM/DD/YYYY") === "01/01/1900" ||
    moment(new Date(rawDate)).utc().format("MM/DD/YYYY") === "01/01/0001"
  ) {
    return "";
  }
  return moment(new Date(rawDate)).utc().format("MM/DD/YYYY");
};

export const formatDateTimeWithOutUtc = (rawDate?: string): string => {
  if (
    rawDate === undefined ||
    rawDate === null ||
    rawDate.length === 0 ||
    moment(new Date(rawDate)).utc().format("MM/DD/YYYY") === "01/01/1900" ||
    moment(new Date(rawDate)).utc().format("MM/DD/YYYY") === "01/01/0001"
  ) {
    return "";
  }
  return moment(new Date(rawDate)).format("MM/DD/YYYY HH:mm");
};

export const formatCYDate = (rawDate?: string): string => {
  if (
    rawDate === undefined ||
    rawDate === null ||
    rawDate.length === 0 ||
    moment(new Date(rawDate)).utc().format("MM/DD/YYYY") === "01/01/1900" ||
    moment(new Date(rawDate)).utc().format("MM/DD/YYYY") === "01/01/0001"
  ) {
    return "";
  }
  return (
    moment(new Date(rawDate)).utc().format("MMM") +
    " CY" +
    moment(new Date(rawDate)).utc().format("YY")
  );
};

export const transferToUtcDate = (rawDate: Date): Date => {
  return moment(rawDate)
    .add(-moment.tz(rawDate, moment.tz.guess(true)).utcOffset(), "minutes")
    .toDate();
};

export const convertMonthStringToDate = (month: string): Date => {
  return new Date(
    Number(month.substring(0, 4)),
    Number(month.substring(5)) - 1,
    1
  );
};

export const convertDateTimeToFormatedDateString = (
  rawDateTime: Date
): string => {
  return moment(rawDateTime).format("YYYY-MM-DD");
};

export const getFirstDayInTheMonth = (rawDate: string): Date => {
  return moment(moment(rawDate).format("MM/01/YYYY")).toDate();
};

export const getLastDayInTheMonth = (rawDate: string): Date => {
  return moment(moment(rawDate).format("MM/01/YYYY"))
    .add(1, "months")
    .subtract(1, "days")
    .toDate();
};

export const getNextMonthDate = (
  currentDate: Date,
  toPrevious: Boolean = false
): Date => {
  return moment(currentDate)
    .add(toPrevious ? -1 : 1, "months")
    .toDate();
};

export const getWeekFirstDayNumber = (
  firstDayIsMonday: boolean = false
): number => {
  return firstDayIsMonday ? 1 : 0;
};

export const getWeekLastDayNumber = (
  firstDayIsMonday: boolean = false
): number => {
  return firstDayIsMonday ? 0 : 6;
};

//Return week day
export const getWeekDayIndex = (
  dayNumber: number,
  isFirstDayMonday: boolean = false
) => {
  return isFirstDayMonday ? (dayNumber === 0 ? 6 : dayNumber - 1) : dayNumber;
};

export const getWeekSpanByDates = (
  startDate: Date,
  endDate: Date,
  isFirstDayMonday: boolean = false
): number => {
  let span = 1;
  for (let day = startDate; day <= endDate; day = getNextDay(day)) {
    const dayNumber = day.getDay();
    if (
      dayNumber === getWeekLastDayNumber(isFirstDayMonday) &&
      getNextDay(day) <= endDate
    ) {
      span++;
    }
  }
  return span;
};

export const getNextDay = (
  currentDate: Date,
  toPrevious: boolean = false
): Date => {
  return moment(currentDate)
    .add(toPrevious ? -1 : 1, "days")
    .toDate();
};

export const getTitleArray = (isFirstDayMonday: boolean = false) => {
  return isFirstDayMonday
    ? ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"]
    : ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
};

export const isFirstTrainOfMonth = (
  currentDate: Date,
  customTrainDates: string[]
): boolean => {
  let currentMonthTrains = customTrainDates
    .filter((t) => {
      return (
        t &&
        moment(t, "MM/DD/YYYY").format("MM/YYYY") ===
          moment(currentDate).format("MM/YYYY")
      );
    })
    .map((t) => {
      return t;
    });

  let sorted = currentMonthTrains.sort((a, b) => {
    return (
      new Date(moment(a, "MM/DD/YYYY").format("MM/DD/YYYY")).getTime() -
      new Date(moment(b, "MM/DD/YYYY").format("MM/DD/YYYY")).getTime()
    );
  });

  if (
    sorted.findIndex((ct) => {
      return (
        moment(ct, "MM/DD/YYYY").format("MM/DD/YYYY") ===
        moment(currentDate).format("MM/DD/YYYY")
      );
    }) === 0
  ) {
    return true;
  } else {
    return false;
  }
};
