export enum _LOADINGSTATE {
  rejected = "REJECTED",
  pending = "PENDING",
  fullfilled = "FULLFILLED",
}

export const _AUTHORIZATIONTYPE = Object.freeze({
  admin: "ADMIN",
  dri: "DRI",
  editable: "EDITABLE",
  read_only: "READ_ONLY",
  access_denied: "ACCESS_DENIED",
});

export enum ModalType {
  New = 0,
  Edit = 1,
  Copy = 2,
  Delete = 3,
  ReadOnly = 4,
}

export enum TimelineType {
  Azure = "Azure",
  MW = "MW",
  SCI = "SCI",
}

export enum ContentShowMode {
  Grid = 0,
  Column = 1,
  Dashboard = 2,
}

export enum TimelineMode {
  Timeline = 0,
  Search = 1,
  TrainDate = 2,
  ProductsList = 3,
}

export const MilestoneTypeId = Object.freeze({
  Private_Preview: 1,
  Public_Preview: 2,
  GA: 4,
  Retired: 5,
  GA_Retired: 6,
  Preview_Retired: 10,
  Disclosure: 9,
});

export const SecurityMilestoneTypeId = Object.freeze({
  Public_Disclosure_Date: 1,
  Private_Preview: 2,
  Public_Preview: 3,
  Rebrand: 4,
  General_Availability: 5,
  NDA: 6,
  Leadership_Only: 7,
});

export const milestoneFormBorderTop = "3px solid rgb(242, 242, 242)";
