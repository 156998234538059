import { useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { SecurityMilestoneModalContext } from "../SecurityTimeline";
import * as securityMilestoneReducer from "../../slice/securityMilestone";
import {
  DefaultButton,
  Spinner,
  SpinnerSize,
  TextField,
} from "@fluentui/react";
import LastModifiedBy from "../Shared/lastModifiedBy";
import { RootState, useAppDispatch } from "../../store";
import { textareaStylesProps } from "../Shared/styleProps";
import { isVoid } from "../../utils/utils";
import moment from "moment";
import {
  milestoneFormBorderTop,
  ModalType,
  _LOADINGSTATE,
  _AUTHORIZATIONTYPE,
  SecurityMilestoneTypeId,
} from "../Shared/constants";
import { SuccessMessage } from "../Shared/messageBox";
import MessagingMaterials from "./messagingMaterials";
import { AppContext } from "../../App";
import * as securityMilestoneDateHistoryReducer from "../../slice/securityMilestoneDateHistory";

const MoreInformation = (props: {
  disabled: boolean;
  appearErrorInfo: boolean;
  setAppearErrorInfo: Function;
  setFormChanged: Function;
  milestoneFormSubmited: boolean;
  setMilestoneFormSubmited: Function;
  FormChange: boolean;
}) => {
  const appContext = useContext(AppContext);
  const milestone = useSelector<
    RootState,
    securityMilestoneReducer.SecurityMilestone
  >((state: RootState) => {
    return state.securityMilestoneReducer.SingleMilestone;
  });
  const appDispatch = useAppDispatch();
  const dispatch = useDispatch();
  const considerSubmitted = (condition: boolean) => {
    //we want error message to show after user click save edits button
    return props.appearErrorInfo && condition;
  };
  const securityMilestoneModalContext = useContext(
    SecurityMilestoneModalContext
  );

  const readOnly =
    appContext.accessType !== _AUTHORIZATIONTYPE.admin &&
    appContext.accessType !== _AUTHORIZATIONTYPE.dri &&
    appContext.accessType !== _AUTHORIZATIONTYPE.editable;

  return (
    <>
      <div
        style={{
          borderTop: milestoneFormBorderTop,
          marginTop: "20px",
        }}
        className="modal-detail-header"
      >
        More information
      </div>
      <div className="row">
        <div className="col-6">
          <TextField
            label="Comments"
            value={milestone.Comment ?? undefined}
            errorMessage={
              considerSubmitted((milestone.Comment?.length ?? 0) > 2000)
                ? "Comments exceeds 2000 characters"
                : undefined
            }
            onChange={(event, newValue) => {
              dispatch(
                securityMilestoneReducer.actions.changeComment(newValue || "")
              );
              props.setFormChanged(true);
            }}
            styles={textareaStylesProps}
            disabled={props.disabled}
          />
        </div>
        <div className="col-6">
          <MessagingMaterials setFormChanged={props.setFormChanged} />
        </div>
      </div>
      <div className="row">
        <div className="my-4">
          <LastModifiedBy
            modifiedBy={
              isVoid(milestone.ModifiedBy)
                ? undefined
                : milestone.ModifiedBy ?? ""
            }
            modifiedAt={
              milestone.ModifiedDate
                ? moment(new Date(milestone.ModifiedDate)).format(
                    "MM/DD/YYYY h:mm:ss a"
                  )
                : ""
            }
          />
          <DefaultButton
            disabled={
              milestone.__isLoading === _LOADINGSTATE.pending || readOnly
            }
            text="Save"
            ariaDescription="Save"
            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
              event.preventDefault();
              props.setAppearErrorInfo(true);

              if (milestone.__isFormValid) {
                if (
                  securityMilestoneModalContext.securityMilestoneModalState
                    .modalType === ModalType.New ||
                  securityMilestoneModalContext.securityMilestoneModalState
                    .modalType === ModalType.Copy
                ) {
                  appDispatch(
                    securityMilestoneReducer.createSecurityMilestone(milestone)
                  )
                    .then(unwrapResult)
                    .then(() => {
                      props.setMilestoneFormSubmited(true);
                      switch (milestone.SecurityMilestoneTypeId) {
                        case 1:
                          dispatch(
                            securityMilestoneReducer.actions.changeGroupDate(
                              milestone.PublicDisclosureDate || ""
                            )
                          );
                          break;
                        case 2:
                          dispatch(
                            securityMilestoneReducer.actions.changeGroupDate(
                              milestone.PrivatePreviewDate || ""
                            )
                          );
                          break;
                        case 3:
                          dispatch(
                            securityMilestoneReducer.actions.changeGroupDate(
                              milestone.PublicPreviewDate || ""
                            )
                          );
                          break;
                        case 5:
                          dispatch(
                            securityMilestoneReducer.actions.changeGroupDate(
                              milestone.GeneralAvailabilityDate || ""
                            )
                          );
                          break;
                        default:
                          dispatch(
                            securityMilestoneReducer.actions.changeGroupDate(
                              new Date().toDateString() || ""
                            )
                          );
                          break;
                      }
                      SuccessMessage.show(
                        "The security milestone content has been saved successfully."
                      );
                      props.setFormChanged(false);
                      props.setAppearErrorInfo(false);
                      securityMilestoneModalContext.modalRef?.current?.click();
                    });
                } else if (
                  securityMilestoneModalContext.securityMilestoneModalState
                    .deleteMilestoneDateHistoryId != ""
                ) {
                  appDispatch(
                    securityMilestoneDateHistoryReducer.deleteSecurityMilestoneDateHistory(
                      securityMilestoneModalContext.securityMilestoneModalState.deleteMilestoneDateHistoryId
                        .split(",")
                        .filter((id) => {
                          return id != "";
                        })
                        .map((id) => {
                          return parseInt(id);
                        })
                    )
                  )
                    .then(() => {
                      if (
                        props.FormChange &&
                        securityMilestoneModalContext
                          .securityMilestoneModalState.modalType ===
                          ModalType.Edit
                      ) {
                        appDispatch(
                          securityMilestoneReducer.updateSecurityMilestone({
                            Id: milestone.SecurityMilestoneId,
                            viewModel: milestone,
                          })
                        )
                          .then(unwrapResult)
                          .then(() => {
                            props.setMilestoneFormSubmited(true);
                            switch (milestone.SecurityMilestoneTypeId) {
                              case 1:
                                dispatch(
                                  securityMilestoneReducer.actions.changeGroupDate(
                                    milestone.PublicDisclosureDate || ""
                                  )
                                );
                                break;
                              case 2:
                                dispatch(
                                  securityMilestoneReducer.actions.changeGroupDate(
                                    milestone.PrivatePreviewDate || ""
                                  )
                                );
                                break;
                              case 3:
                                dispatch(
                                  securityMilestoneReducer.actions.changeGroupDate(
                                    milestone.PublicPreviewDate || ""
                                  )
                                );
                                break;
                              case 5:
                                dispatch(
                                  securityMilestoneReducer.actions.changeGroupDate(
                                    milestone.GeneralAvailabilityDate || ""
                                  )
                                );
                                break;
                              default:
                                dispatch(
                                  securityMilestoneReducer.actions.changeGroupDate(
                                    new Date().toDateString() || ""
                                  )
                                );
                                break;
                            }
                            SuccessMessage.show(
                              "The milestone content has been saved successfully."
                            );
                            props.setFormChanged(false);
                            props.setAppearErrorInfo(false);
                          })
                          .catch(() => {
                            props.setAppearErrorInfo(false);
                          });
                      } else {
                        props.setAppearErrorInfo(false);

                        props.setMilestoneFormSubmited(true);
                        switch (milestone.SecurityMilestoneTypeId) {
                          case 1:
                            dispatch(
                              securityMilestoneReducer.actions.changeGroupDate(
                                milestone.PublicDisclosureDate || ""
                              )
                            );
                            break;
                          case 2:
                            dispatch(
                              securityMilestoneReducer.actions.changeGroupDate(
                                milestone.PrivatePreviewDate || ""
                              )
                            );
                            break;
                          case 3:
                            dispatch(
                              securityMilestoneReducer.actions.changeGroupDate(
                                milestone.PublicPreviewDate || ""
                              )
                            );
                            break;
                          case 5:
                            dispatch(
                              securityMilestoneReducer.actions.changeGroupDate(
                                milestone.GeneralAvailabilityDate || ""
                              )
                            );
                            break;
                          default:
                            dispatch(
                              securityMilestoneReducer.actions.changeGroupDate(
                                new Date().toDateString() || ""
                              )
                            );
                            break;
                        }
                        SuccessMessage.show(
                          "The milestone content has been saved successfully."
                        );
                        props.setFormChanged(false);
                        props.setAppearErrorInfo(false);
                      }
                    })
                    .catch(() => {
                      props.setAppearErrorInfo(false);
                    });
                } else if (
                  props.FormChange &&
                  securityMilestoneModalContext.securityMilestoneModalState
                    .modalType === ModalType.Edit
                ) {
                  appDispatch(
                    securityMilestoneReducer.updateSecurityMilestone({
                      Id: milestone.SecurityMilestoneId,
                      viewModel: milestone,
                    })
                  )
                    .then(unwrapResult)
                    .then(() => {
                      props.setMilestoneFormSubmited(true);
                      switch (milestone.SecurityMilestoneTypeId) {
                        case 1:
                          dispatch(
                            securityMilestoneReducer.actions.changeGroupDate(
                              milestone.PublicDisclosureDate || ""
                            )
                          );
                          break;
                        case 2:
                          dispatch(
                            securityMilestoneReducer.actions.changeGroupDate(
                              milestone.PrivatePreviewDate || ""
                            )
                          );
                          break;
                        case 3:
                          dispatch(
                            securityMilestoneReducer.actions.changeGroupDate(
                              milestone.PublicPreviewDate || ""
                            )
                          );
                          break;
                        case 5:
                          dispatch(
                            securityMilestoneReducer.actions.changeGroupDate(
                              milestone.GeneralAvailabilityDate || ""
                            )
                          );
                          break;
                        default:
                          dispatch(
                            securityMilestoneReducer.actions.changeGroupDate(
                              new Date().toDateString() || ""
                            )
                          );
                          break;
                      }
                      SuccessMessage.show(
                        "The security milestone content has been saved successfully."
                      );
                      props.setFormChanged(false);
                      props.setAppearErrorInfo(false);
                    });
                }
              } else {
                if (milestone.CustomerBenefits === "") {
                  document.getElementById("customerBenefitsField")?.focus();
                } else if (milestone.NewOrChanging === "") {
                  document.getElementById("newOrChangingField")?.focus();
                } else if (milestone.ProductServiceId === null) {
                  document.getElementById("productServiceField")?.focus();
                } else if (milestone.PMM === "") {
                  document
                    .getElementById("securityProductServiceForm_PMM")
                    ?.focus();
                } else if (
                  milestone.SecurityMilestoneAudiences === null ||
                  milestone.SecurityMilestoneAudiences.length === 0
                ) {
                  document
                    .getElementById("SecurityTimeline_Targetaudience")
                    ?.focus();
                } else if (milestone.TierId === null) {
                  document.getElementById("tierField")?.focus();
                } else if (milestone.PublicTitle === "") {
                  document.getElementById("publicTitleField")?.focus();
                } else if (milestone.SecurityConfidenceId === null) {
                  document.getElementById("confidenceField")?.focus();
                } else if (
                  milestone.PrivatePreviewDate === null &&
                  milestone.SecurityMilestoneTypeId ===
                    SecurityMilestoneTypeId.Private_Preview
                ) {
                  document.getElementById("Private_preview_date")?.focus();
                } else if (
                  milestone.PublicPreviewDate === null &&
                  milestone.SecurityMilestoneTypeId ===
                    SecurityMilestoneTypeId.Public_Preview
                ) {
                  document.getElementById("Public_preview_date")?.focus();
                } else if (
                  milestone.PublicDisclosureDate === null &&
                  milestone.SecurityMilestoneTypeId ===
                    SecurityMilestoneTypeId.Public_Disclosure_Date
                ) {
                  document.getElementById("Public_disclosure_date")?.focus();
                } else if (
                  milestone.GeneralAvailabilityDate === null &&
                  milestone.SecurityMilestoneTypeId ===
                    SecurityMilestoneTypeId.General_Availability
                ) {
                  document.getElementById("General_availability_date")?.focus();
                } else if (
                  milestone.HasBlocker &&
                  (milestone.BlockerReason == null ||
                    milestone.BlockerReason?.length === 0)
                ) {
                  document.getElementById("ReasonsDropdown")?.focus();
                } else if (
                  milestone.HasBlocker &&
                  milestone.BlockerReasonAmount == null
                ) {
                  document.getElementById("riskField")?.focus();
                }
              }
              event.stopPropagation();
            }}
          />
          <span
            style={{
              display: "inline-block",
              width: 30,
              height: 19,
            }}
          >
            {milestone.__isLoading === _LOADINGSTATE.pending ? (
              <Spinner
                size={SpinnerSize.large}
                style={{
                  display: "inline",
                  marginLeft: "10px",
                  position: "absolute",
                }}
              />
            ) : (
              <></>
            )}
          </span>
          <span className="modal-detail-error mx-4">
            Be sure to click Save before leaving this window.
          </span>
          <p className="fw-bold mt-1">
            Submit marketing channel requests (i.e., blog, social, digital
            event, web updates, paid media, etc.) using the{" "}
            <a
              href="https://aka.ms/BADEIntake"
              target="_blank"
              rel="noopener noreferrer"
            >
              marketing work intake
            </a>{" "}
            tool.
          </p>
        </div>
      </div>
    </>
  );
};
export default MoreInformation;
