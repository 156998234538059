//@ts-ignore
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { IStyle } from "@fluentui/react";
import { CSSProperties, ReactNode } from "react";

const TextEditor = (props: {
  id: string;
  label: ReactNode;
  value: string;
  onChangeHandler: (event: any, editor: any) => void;
  error?: string;
  editor: any;
  disabled: boolean;
  config?: any;
  onBlurHandler: (event: any, editor: any) => void;
  labelStyle?: CSSProperties;
}) => {
  return (
    <div>
      <label
        className="modal-detail-label"
        htmlFor={props.id}
        style={props.labelStyle}
      >
        {props.label}
      </label>
      <CKEditor
        editor={props.editor}
        data={props.value ?? ""}
        onChange={props.onChangeHandler}
        id={props.id}
        name={props.id}
        disabled={props.disabled}
        config={props.config}
        onBlur={props.onBlurHandler}
      />
      <span className="modal-detail-error">{props.error}</span>
    </div>
  );
};

export default TextEditor;
