import httpClient from "./base";

const getMilestoneTypes = () => {
  var url = "/MilestoneType";
  return httpClient.get(url);
};

const milestoneTypeService = {
  getMilestoneTypes,
};

export default milestoneTypeService;
