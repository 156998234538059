import { createRef } from "react";
import { Link } from "react-router-dom";
import { PrimaryButton } from "@fluentui/react/lib/Button";
import "./index.scss";
import { buttonStyles } from "../../Shared/styleProps";

const Header = () => {
  const pptExport = createRef<HTMLAnchorElement>();
  const roadmapDRIsLink = createRef<HTMLAnchorElement>();
  const marketingSupportRequestsLink = createRef<HTMLAnchorElement>();

  return (
    <header className="main-header calander-main-header">
      <h2
        className="main-header-title"
        style={{
          padding: "12px 0px",
          width: "88%",
          display: "flex",
          float: "left",
        }}
      >
        Welcome to the Cloud Marketing moments calendar
      </h2>
      <a
        className="helpLink"
        href="https://microsoft.sharepoint.com/teams/CloudMarketingMoments/SitePages/Cloud-Marketing-moments-help.aspx"
        target="_blank"
        rel="noreferrer"
      >
        Help and resources
      </a>
      <nav
        role="navigation"
        className="main-nav nav nav-pills"
        style={{ width: "1134px" }}
      >
        <ul className="noStyleList">
          <li className="nav-item">
            <Link
              className="nav-link"
              style={{
                width: 172,
                height: 75,
                textAlign: "center",
              }}
              to="/AzureTimeline"
            >
              <span
                style={{
                  fontSize: "16px",
                  fontWeight: 500,
                  lineHeight: "57px",
                }}
              >
                Azure
              </span>
            </Link>
          </li>
          <li className="nav-item">
            <Link
              className="nav-link"
              style={{
                width: 172,
                height: 75,
                textAlign: "center",
              }}
              to="/SecurityTimeline"
            >
              <span
                style={{
                  fontSize: "16px",
                  fontWeight: 500,
                  lineHeight: "57px",
                }}
              >
                Microsoft Security
              </span>
            </Link>
          </li>
          <li className="nav-item">
            <Link
              className="nav-link"
              style={{
                width: 172,
                height: 75,
                textAlign: "center",
              }}
              to="/ModernWorkTimeline"
            >
              <span
                style={{
                  fontSize: "16px",
                  fontWeight: 500,
                  lineHeight: "57px",
                }}
              >
                Modern Work
              </span>
            </Link>
          </li>
        </ul>
      </nav>
      <nav role="navigation" className="right-nav nav nav-pills">
        <a
          href="/PPTExport"
          target="_blank"
          rel="noreferrer"
          className="d-none"
          ref={pptExport}
          aria-label="PPT Export"
        >
          PPT&nbsp;Export
        </a>
        <PrimaryButton
          text="PPT Export"
          allowDisabledFocus
          onClick={() => {
            pptExport.current?.click();
          }}
          className="ml-10"
          styles={buttonStyles}
        />
        <a
          href="http://aka.ms/RoadmapDRI"
          target="_blank"
          rel="noreferrer"
          className="d-none"
          ref={roadmapDRIsLink}
          aria-label="Roadmap DRIs"
        >
          Roadmap&nbsp;DRIs
        </a>
        <PrimaryButton
          text="Roadmap DRIs"
          allowDisabledFocus
          onClick={() => {
            roadmapDRIsLink.current?.click();
          }}
          className="ml-10"
          styles={buttonStyles}
        />
        <a
          href="https://aka.ms/badeintake"
          target="_blank"
          rel="noreferrer"
          className="d-none"
          ref={marketingSupportRequestsLink}
          aria-label="Marketing support requests"
        >
          Marketing support requests
        </a>
        <PrimaryButton
          text="Marketing support requests"
          allowDisabledFocus
          onClick={() => {
            marketingSupportRequestsLink.current?.click();
          }}
          className="ml-10"
          styles={buttonStyles}
        />
      </nav>
    </header>
  );
};

export default Header;
