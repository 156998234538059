import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useUrlQueryParam } from "../../../utils/url";
import { isVoid, toExcel } from "../../../utils/utils";
import "../../Shared/css/timelineNavBar.scss";
import "./index.scss";
import powerbiicon from "./PowerBIIcon.png";
import {
  DirectionalHint,
  Dropdown,
  mergeStyleSets,
  PrimaryButton,
  Spinner,
  SpinnerSize,
  TooltipHost,
} from "@fluentui/react";
import {
  ContentShowMode,
  TimelineMode,
  TimelineType,
  _LOADINGSTATE,
} from "../../Shared/constants";
import { buttonStyles, dropdownStylesProps } from "../../Shared/styleProps";
import DatePickerWithCustomStyles from "../../Shared/FormInput/datepickerWithCustomStyles";
import { RootState, useAppDispatch } from "../../../store";
import * as securityTimelineReducer from "../../../slice/securityTimeline";
import * as calendarReducer from "../../../slice/calendar";
import { filter, featureFilterList } from "../../../slice/savedDataFilters";
import { SecurityMilestoneModalContext } from "..";

const sortByDates: { dateId: string; dateTitle: string }[] = [
  {
    dateId: "PublicDisclosureDate",
    dateTitle: "Public disclosure date",
  },
  {
    dateId: "PrivatePreviewDate",
    dateTitle: "Private preview date",
  },
  {
    dateId: "PublicPreviewDate",
    dateTitle: "Public preview date",
  },
  {
    dateId: "GeneralAvailabilityDate",
    dateTitle: "General availability date",
  },
];
const styles = mergeStyleSets({
  root: {
    maxWidth: 300,
    selectors: {
      "> *": { marginBottom: 15 },
      "> table, th, td": { border: 0, backgroundColor: "white" },
    },
  },
  button: { marginRight: 10 },
  input: { width: 120 },
});

interface Props {
  isStickyHead?: boolean | undefined;
  showPowerBIComponent?: boolean | false;
}

const FeatureNav = (props: Props) => {
  const [param, setParam] = useUrlQueryParam(
    featureFilterList.map((f: filter) => {
      return f.filterName;
    })
  );

  const securityMilestoneModalContext = useContext(
    SecurityMilestoneModalContext
  );

  const appDispatch = useAppDispatch();
  const customTrainDates = useSelector(
    (state: RootState) => state.calendarReducer.CustomTrainDates
  );
  const microsoftHoliday = useSelector(
    (state: RootState) => state.calendarReducer.MicrosoftHolidays
  );
  const moments = useSelector(
    (state: RootState) => state.calendarReducer.Moments
  );
  const azureUpdateDeckDueDates = useSelector(
    (state: RootState) => state.calendarReducer.AzureUpdatesDeckDueDates
  );
  const calendarDates = useSelector(
    (state: RootState) => state.calendarReducer.CalendarDates
  );

  useEffect(() => {
    if (props.isStickyHead === false || props.isStickyHead === undefined) {
      if (
        customTrainDates.length === 0 ||
        microsoftHoliday.length === 0 ||
        moments.length === 0 ||
        azureUpdateDeckDueDates.length === 0 ||
        calendarDates.length == 0
      ) {
        appDispatch(calendarReducer.getCalendarDates(TimelineType.SCI));
      }
    }
  }, [appDispatch, props.isStickyHead]);

  const endMsg = isVoid(securityMilestoneModalContext.appearEndDate)
    ? "end date is required"
    : securityMilestoneModalContext.appearStartDate >
      securityMilestoneModalContext.appearEndDate
    ? "End date must be after Start date for proper display"
    : "";

  const isMonthLoading = useSelector(
    (state: RootState) => state.securityTimelineReducer.isMonthLoading
  );

  const getData = () => {
    if (isMonthLoading === _LOADINGSTATE.pending) {
      return;
    }
    if (isVoid(securityMilestoneModalContext.appearStartDate)) {
      return;
    }
    if (isVoid(securityMilestoneModalContext.appearEndDate)) {
      return;
    }
    if (
      securityMilestoneModalContext.appearStartDate >
      securityMilestoneModalContext.appearEndDate
    ) {
      return;
    }
    if (securityMilestoneModalContext.timelineMode === TimelineMode.Search) {
      securityMilestoneModalContext.setContentShowMode(ContentShowMode.Grid);
      securityMilestoneModalContext.setSearchPhrase("");
      securityMilestoneModalContext.setTimelineMode(TimelineMode.Timeline);
      appDispatch(securityTimelineReducer.initSecurityTimelineDataSet()).then(
        () => {
          securityMilestoneModalContext.setStartDate(
            securityMilestoneModalContext.appearStartDate
          );
        }
      );
    } else {
      securityMilestoneModalContext.setStartDate(
        securityMilestoneModalContext.appearStartDate
      );
    }
  };

  const isLoadingExportExcelFeature = useSelector(
    (state: RootState) =>
      state.securityTimelineReducer.isLoadingExportExcelMilestone
  );

  const isLoadedExportExcelMilestone = useSelector(
    (state: RootState) =>
      state.securityTimelineReducer.isLoadedExportExcelMilestone
  );

  const isExportingExcelFeature = useSelector(
    (state: RootState) =>
      state.securityTimelineReducer.isExportingExcelMilestone
  );

  const handleDownload = () => {
    if (isExportingExcelFeature === true) {
      return false;
    }
    if (securityMilestoneModalContext.timelineMode == TimelineMode.Search) {
      appDispatch(
        securityTimelineReducer.actions.setIsExportingExcelMilestone(true)
      );

      if (isLoadedExportExcelMilestone) {
        excelDownloadData();
      } else {
        appDispatch(
          securityTimelineReducer.actions.setIsLoadingExportExcelMilestone(true)
        );
        appDispatch(
          securityTimelineReducer.getExcelDownloadSearchMilestoneDataSet(
            securityMilestoneModalContext.searchPhrase
          )
        );
      }
    } else {
      if (isVoid(securityMilestoneModalContext.appearStartDate)) {
        return false;
      }
      if (isVoid(securityMilestoneModalContext.appearEndDate)) {
        return false;
      }
      if (
        securityMilestoneModalContext.appearStartDate >
        securityMilestoneModalContext.appearEndDate
      ) {
        return false;
      }
      appDispatch(
        securityTimelineReducer.actions.setIsExportingExcelMilestone(true)
      );

      if (isLoadedExportExcelMilestone) {
        excelDownloadData();
      } else {
        appDispatch(
          securityTimelineReducer.actions.setIsLoadingExportExcelMilestone(true)
        );
        appDispatch(
          securityTimelineReducer.getExcelDownloadMilestoneDataSet(
            securityMilestoneModalContext.appearStartDate,
            securityMilestoneModalContext.appearEndDate
          )
        );
      }
    }
    return false;
  };

  const excelDownloadData = () => {
    const table =
      document.getElementById("timeline-table-excel-download")?.outerHTML || "";
    if (table !== "") {
      toExcel(`microsoftsecurity-timeline-excel`, table);
    }

    appDispatch(
      securityTimelineReducer.actions.setIsExportingExcelMilestone(false)
    );
  };

  useEffect(() => {
    if (
      (props.isStickyHead === false || props.isStickyHead === undefined) &&
      isExportingExcelFeature === true &&
      isLoadingExportExcelFeature === false &&
      isLoadedExportExcelMilestone === true
    ) {
      excelDownloadData();
    }
  }, [
    isExportingExcelFeature,
    isLoadingExportExcelFeature,
    isLoadedExportExcelMilestone,
  ]);

  useEffect(() => {
    appDispatch(
      securityTimelineReducer.actions.setIsLoadedExportExcelMilestone(false)
    );
  }, [
    securityMilestoneModalContext.appearStartDate,
    securityMilestoneModalContext.appearEndDate,
    securityMilestoneModalContext.searchPhrase,
  ]);

  return (
    <div className="nav-container securityTimeline-nav-container">
      <div className="row container-navbar">
        <div className="col-2 section-wrap">
          <div>
            <strong>Select a date to sort by</strong>
          </div>
          <Dropdown
            placeholder="Select one option"
            options={sortByDates.map(
              (item: { dateId: string; dateTitle: string }) => {
                return {
                  key: item.dateId,
                  text: item.dateTitle,
                };
              }
            )}
            defaultSelectedKey={param.SortByDate}
            styles={dropdownStylesProps}
            onChange={(event, item) => {
              if (item) {
                setParam({
                  ...param,
                  SortByDate: encodeURIComponent(item.key),
                });
              }
            }}
            ariaLabel="Select a date to sort by"
            calloutProps={{ doNotLayer: true }}
          />
        </div>
        <div className="col-3 d-flex section-wrap">
          <div className="w-40">
            <div>
              <strong>Start date</strong>
            </div>
            <div className="startDatePicker">
              <DatePickerWithCustomStyles
                value={securityMilestoneModalContext.appearStartDate}
                className={styles.input}
                placeholder="Select a date..."
                ariaLabel="Select a start date"
                onChangeHandler={(date: Date | null | undefined) => {
                  if (date && date != null) {
                    securityMilestoneModalContext.setAppearStartDate(date);
                  }
                }}
                timelineType={TimelineType.SCI}
              />
            </div>
            <span className="date-error">
              {!isVoid(securityMilestoneModalContext.appearStartDate)
                ? ""
                : "begin date is required"}
            </span>
          </div>
          <div>
            <div>
              <label></label>
            </div>
            <div>&nbsp; - &nbsp;</div>
          </div>
          <div className="w-40">
            <div>
              <strong>End date</strong>
            </div>
            <div className="endDatePicker">
              <DatePickerWithCustomStyles
                value={securityMilestoneModalContext.appearEndDate}
                className={styles.input}
                placeholder="Select a date..."
                ariaLabel="Select a end date"
                onChangeHandler={(date: Date | null | undefined) => {
                  if (date && date != null) {
                    securityMilestoneModalContext.setAppearEndDate(date);
                  }
                }}
                timelineType={TimelineType.SCI}
              />
            </div>
            <span
              style={{ fontSize: "13px", position: "absolute" }}
              className="date-error"
            >
              {endMsg}
            </span>
          </div>
          <div>
            <div>
              <label></label>
            </div>
            &nbsp;&nbsp;
            <PrimaryButton
              text="Go"
              allowDisabledFocus
              style={{ minWidth: "50px" }}
              onClick={getData}
              styles={buttonStyles}
            />
          </div>
        </div>
        <div className="col-1 section-wrap text-center export-to">
          <div>
            <strong>Export to:</strong>
          </div>
          <div>
            <a
              href="#!"
              aria-label="Excel,Export to Excel"
              onClick={handleDownload}
              role="button"
            >
              <span style={{ lineHeight: "32px" }}>Excel </span>
              {isExportingExcelFeature ? (
                <Spinner size={SpinnerSize.small} ariaLive="polite" />
              ) : null}
            </a>
          </div>
        </div>
        <div className="col section-wrap">
          <div>
            {" "}
            <div>
              <strong>Milestone type key:</strong>
            </div>
          </div>
          <div className="milestone-legends vertical-align mt-2">
            <div className="row-align">
              <div
                className="legend-item legend-item-public-disclosure"
                style={{ marginLeft: 0 }}
              ></div>
              <div className="legend-item-title" title="Public disclosure date">
                Public disclosure date
              </div>
              <div className="legend-item legend-item-priprev"></div>
              <div className="legend-item-title" title="Private Preview">
                Private Preview
              </div>
              <div className="legend-item legend-item-pubprev"></div>
              <div className="legend-item-title" title="Public Preview">
                Public Preview
              </div>
              <div className="legend-item legend-item-ga"></div>
              <div className="legend-item-title" title="GA">
                GA
              </div>
            </div>
          </div>
        </div>
        {/*<div className="col">*/}
        {/*    <div>*/}
        {/*        {" "}*/}
        {/*        <div>*/}
        {/*            <strong>Publication status on the public roadmap:</strong>*/}
        {/*        </div>*/}
        {/*    </div>*/}
        {/*    <div*/}
        {/*        className="feature-legends vertical-align mt-2"*/}
        {/*        id="milistoneKey"*/}
        {/*    >*/}
        {/*        <div className="legend-item legend-item-published"></div>*/}
        {/*        <div className="legend-item-title" title="Published">*/}
        {/*            Published*/}
        {/*        </div>*/}
        {/*        <div className="legend-item legend-item-notpublished"></div>*/}
        {/*        <div className="legend-item-title" title="Not published">*/}
        {/*            Not published*/}
        {/*        </div>*/}
        {/*    </div>*/}
        {/*</div>*/}
        <div
          className="col-1"
          style={{ display: "flex", alignItems: "center", marginRight: "70px" }}
        >
          {securityMilestoneModalContext.timelineMode ? (
            ""
          ) : (
            <TooltipHost
              content="Grid Mode"
              directionalHint={DirectionalHint.topCenter}
              styles={{ root: { display: "inline-block" } }}
            >
              <i
                className="ms-Icon ms-Icon--GridViewMedium"
                style={
                  securityMilestoneModalContext.contentShowMode ===
                  ContentShowMode.Grid
                    ? {
                        borderStyle: "solid",
                        borderWidth: "medium",
                        cursor: "pointer",
                        fontSize: "35px",
                      }
                    : { cursor: "pointer", fontSize: "35px" }
                }
                onClick={() => {
                  securityMilestoneModalContext.setContentShowMode(
                    ContentShowMode.Grid
                  );
                }}
                alt-text="Grid Mode"
                aria-label="Grid Mode"
                role="button"
                aria-pressed={
                  securityMilestoneModalContext.contentShowMode ===
                  ContentShowMode.Grid
                }
                tabIndex={0}
                onKeyDown={(e) => {
                  if (e.keyCode === 13) {
                    e.currentTarget.click();
                  }
                }}
              ></i>
            </TooltipHost>
          )}
          <TooltipHost
            content="Column Mode"
            directionalHint={DirectionalHint.topCenter}
            styles={{ root: { display: "inline-block" } }}
          >
            <i
              className="ms-Icon ms-Icon--TripleColumn"
              style={
                securityMilestoneModalContext.contentShowMode ===
                ContentShowMode.Column
                  ? {
                      borderStyle: "solid",
                      borderWidth: "medium",
                      cursor: "pointer",
                      fontSize: "35px",
                      marginLeft: "12px",
                      transform: "rotate(90deg)",
                    }
                  : {
                      cursor: "pointer",
                      fontSize: "35px",
                      margin: "0 0 0 25px",
                      transform: "rotate(90deg)",
                    }
              }
              onClick={() => {
                securityMilestoneModalContext.setContentShowMode(
                  ContentShowMode.Column
                );
              }}
              alt-text="Column Mode"
              aria-label="Column Mode"
              role="button"
              aria-pressed={
                securityMilestoneModalContext.contentShowMode ===
                ContentShowMode.Column
              }
              tabIndex={0}
              onKeyDown={(e) => {
                if (e.keyCode === 13) {
                  e.currentTarget.click();
                }
              }}
            ></i>
          </TooltipHost>
          {props.showPowerBIComponent && (
            <TooltipHost
              content="Dashboard Mode"
              directionalHint={DirectionalHint.topCenter}
              styles={{ root: { display: "inline-block" } }}
            >
              <img
                src={powerbiicon}
                style={
                  securityMilestoneModalContext.contentShowMode ===
                  ContentShowMode.Dashboard
                    ? {
                        fontSize: "35px",
                        cursor: "pointer",
                        borderStyle: "solid",
                        borderWidth: "medium",
                        marginLeft: "24px",
                        height: "35px",
                        width: "35px",
                      }
                    : {
                        fontSize: "35px",
                        cursor: "pointer",
                        marginLeft: "20px",
                        height: "35px",
                        width: "35px",
                        border: "solid",
                        color: "#D5D4D3",
                        borderWidth: "2px",
                        borderRadius: "6px",
                        padding: "2px",
                      }
                }
                onClick={() => {
                  securityMilestoneModalContext.setContentShowMode(
                    ContentShowMode.Dashboard
                  );
                }}
                alt-text="Dashboard Mode"
                aria-label="Dashboard Mode"
                role="button"
                aria-pressed={
                  securityMilestoneModalContext.contentShowMode ===
                  ContentShowMode.Dashboard
                }
                tabIndex={0}
                onKeyDown={(e) => {
                  if (e.keyCode === 13) {
                    e.currentTarget.click();
                  }
                }}
              />
            </TooltipHost>
          )}
        </div>
      </div>
    </div>
  );
};

export default FeatureNav;
