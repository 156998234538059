import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { _LOADINGSTATE } from "../components/Shared/constants";
import securityProductServiceService from "../services/securityProductService";
import { ErrorMessage } from "../components/Shared/messageBox";

export interface SecurityProductService {
  SecurityProductServiceId: number;
  ProductServiceName: string | null;
  ProductCategory: string | null;
  ProductFamily: string | null;
  SolutionPlay: string | null;
  MarketingTheme: string | null;
}

const initialState = [] as SecurityProductService[];

export const getSecurityProductService = createAsyncThunk(
  "getSecurityProductService",
  async () => await securityProductServiceService.getSecurityProductService()
);

const securityProductServiceSlice = createSlice({
  name: "securityProductService",
  initialState: initialState,
  reducers: {},
  extraReducers: {
    [getSecurityProductService.fulfilled.type]: (
      state: SecurityProductService[],
      action: PayloadAction<SecurityProductService[]>
    ) => {
      return action.payload;
    },
    [getSecurityProductService.rejected.type]: (state, { error }) => {
      ErrorMessage.show(
        "There was an error getting the security product service. Please refresh the page and try again. If the issue persists please contact the tool administrator."
      );
    },
  },
});

export const { reducer, actions } = securityProductServiceSlice;
