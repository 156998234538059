export interface Moments {
  Id: number;
  DisplayText: string;
}

export interface MomentsGroupByProps {
  month: string;
  moment: Moments[];
}

export interface timelineTrainDates {
  TrainDate0: string;
  TrainDate0ContentDue: string;
  TrainDate1: string;
  TrainDate1ContentDue: string;
  TrainDate2: string;
  TrainDate2ContentDue: string;
  TrainDate3: string;
  TrainDate3ContentDue: string;
}

export const initTimelineTrainDatesState: timelineTrainDates = {
  TrainDate0: "",
  TrainDate0ContentDue: "",
  TrainDate1: "",
  TrainDate1ContentDue: "",
  TrainDate2: "",
  TrainDate2ContentDue: "",
  TrainDate3: "",
  TrainDate3ContentDue: "",
};
