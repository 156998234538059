import React, { useEffect, useContext, useState, useMemo, useRef } from "react";
import { useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import Select from "react-select";
import { useSearchParams } from "react-router-dom";
import {
  Checkbox,
  Dropdown,
  IDropdownOption,
  IDropdownStyles,
  Spinner,
  SpinnerSize,
  TextField,
  DefaultButton,
} from "@fluentui/react";
//@ts-ignore
import ClassicEditor from "ckeditor5-custom-build/build/ckeditor";
import moment from "moment";
import { isVoid } from "../../../utils/utils";
import { transferToUtcDate } from "../../../utils/date";
import "./index.scss";
import "../../Shared/css/modal.scss";
import {
  dropdownStylesProps,
  reactselectSearchableErrorStyleProps,
  reactselectSearchableStyleProps,
  textfieldStylesProps,
} from "../../Shared/styleProps";
import {
  milestoneFormBorderTop,
  TimelineType,
  ModalType,
  MilestoneTypeId,
  _AUTHORIZATIONTYPE,
  _LOADINGSTATE,
} from "../../Shared/constants";
import TextEditor from "../../Shared/FormInput/textEditor";
import GraphUsersAutoCompleteWithTeamsIcon from "../../Shared/FormInput/graphUsersAutoCompleteWithTeamsIcon";
import Tooltip from "../../Shared/FormInput/tooltip";
import { SuccessMessage } from "../../Shared/messageBox";
import { RootState, useAppDispatch } from "../../../store";
import * as milestoneReducer from "../../../slice/milestone";
import * as audienceReducer from "../../../slice/audience";
import * as productReducer from "../../../slice/product";
import * as tooltipReducer from "../../../slice/tooltip";
import * as azureUpdateDeckReducer from "../../../slice/azureupdatedeck";
import { getMilestoneTiers } from "../../../slice/milestoneTier";
import { getMilestoneTypes } from "../../../slice/milestoneType";
import { Milestone } from "../../../slice/milestone";
import { getCustomEventDateByMilestoneId } from "../../../slice/customEventDate";
import {
  getModernWorkSecurityEventCalendarMomentById,
  getModernWorkSecurityEventCalendarMomentListForDetail,
  modernWorkSecurityEventCalendarMoment,
} from "../../../slice/modernWorkSecurityEventCalendarMoment";
import MilestoneTypeRelated from "./milestoneTypeRelated";
import { MilestoneModalContext } from "../../AzureTimeline";
import { AppContext } from "../../../App";
import ModalTooltip from "../../Shared/FormInput/modalTooltip";
import LastModifiedBy from "../../Shared/lastModifiedBy";

export const dropdownStyles: Partial<IDropdownStyles> = {
  ...dropdownStylesProps,
  dropdown: {
    width: 332,
  },
};

const MilestoneForm = (props: {
  milestoneFormSubmited: boolean;
  setMilestoneFormSubmited: Function;
  milestoneFormChanged: any;
  setMilestoneFormChanged: Function;
}) => {
  const appContext = useContext(AppContext);
  const milestoneModalContext = useContext(MilestoneModalContext);
  const appDispatch = useAppDispatch();
  const milestone = useSelector<RootState, milestoneReducer.Milestone>(
    (state: RootState) => {
      return state.milestoneReducer.SingleMilestone;
    }
  );

  useEffect(() => {
    if (milestone.__isLoading === _LOADINGSTATE.fullfilled) {
      appDispatch(
        getModernWorkSecurityEventCalendarMomentListForDetail({
          timelineType: TimelineType.Azure,
          date: milestone.DisclosureStartDate
            ? transferToUtcDate(new Date(milestone.DisclosureStartDate))
            : new Date(moment(new Date()).format("MM/DD/YYYY")),
        })
      );
    }
  }, [milestone.__isLoading, milestone.DisclosureStartDate]);

  const product = useSelector<RootState, productReducer.Product | null>(
    (state) => state.productReducer.singleProduct
  );
  useEffect(() => {
    if (
      milestoneModalContext.milestoneModalState.modalType === ModalType.New &&
      product
    ) {
      appDispatch(
        milestoneReducer.actions.changeSelectedAudiences(
          JSON.stringify(product.SelectedAudiences)
        )
      );
    }
  }, [product]);
  const productIdAdnNames = useSelector<RootState, productReducer.Product[]>(
    (state) => state.productReducer.productIdAndNames
  );
  const audiences = useSelector<RootState, audienceReducer.Audience[]>(
    (state) => state.audienceReducer.audiences
  );
  const audienceOptions = audiences.map((a) => {
    return { key: a.AudienceId, text: a.Name };
  });
  const { modernWorkSecurityEventCalendarMomentListForDetail } = useSelector(
    (state: RootState) => state.modernWorkSecurityEventCalendarMomentReducer
  );
  // Get Moment if current milestone's selected moment is not in the list
  useEffect(() => {
    if (
      milestone.ModernWorkSecurityEventCalendarMomentsId &&
      modernWorkSecurityEventCalendarMomentListForDetail.findIndex((l) => {
        return l.Id === milestone.ModernWorkSecurityEventCalendarMomentsId;
      }) === -1
    ) {
      appDispatch(
        getModernWorkSecurityEventCalendarMomentById(
          milestone.ModernWorkSecurityEventCalendarMomentsId
        )
      );
    }
  }, [modernWorkSecurityEventCalendarMomentListForDetail]);
  const modernWorkSecurityEventCalendarMomentForDetail = useSelector<
    RootState,
    modernWorkSecurityEventCalendarMoment | null
  >(
    (state: RootState) =>
      state.modernWorkSecurityEventCalendarMomentReducer
        .modernWorkSecurityEventCalendarMoment
  );

  const { customEventDate, customEventDates } = useSelector(
    (state: RootState) => state.customEventDateReducer
  );

  const { milestoneTiers, isMilestoneTiersLoading } = useSelector(
    (state: RootState) => state.milestoneTierReducer
  );
  const { milestoneTypes, isMilestoneTypesLoading } = useSelector(
    (state: RootState) => state.milestoneTypeReducer
  );
  const [productName, setProductName] = useState("");
  const productInitValues = useMemo(() => {
    let values = [];
    if (productName === "") {
      values = productIdAdnNames.map((p) => {
        return { value: p.ProductId.toString(), label: p.ProductName };
      });
    } else {
      values = productIdAdnNames
        .filter((p) => {
          return p.ProductName.toLowerCase().includes(
            productName.toLowerCase()
          );
        })
        .map((p) => {
          return {
            value: p.ProductId.toString(),
            label: p.ProductName,
          };
        });
    }
    return values;
  }, [productName, productIdAdnNames]);
  /*const [productSelected, setProductSelected] = useState();*/

  const tiersInitValues = milestoneTiers.map((t) => {
    return { key: t.TierId, text: "Tier " + t.Name };
  });
  const milestoneTypesInitValues = milestoneTypes.map((t) => {
    return { key: t.MilestoneTypeId, text: t.Name };
  });
  let selectedMomentId = 0;
  let momentInitValues: { key: number; text: string }[] = [];
  if (milestoneModalContext.milestoneModalState.modalType === ModalType.New) {
    momentInitValues = modernWorkSecurityEventCalendarMomentListForDetail.map(
      (m) => {
        return { key: m.Id, text: m.DisplayText };
      }
    );
  } else {
    if (
      milestone.CustomEventDateId !== null &&
      milestone.CustomEventDateId > 0 &&
      (milestone.ModernWorkSecurityEventCalendarMomentsId === null ||
        milestone.ModernWorkSecurityEventCalendarMomentsId < 0)
    ) {
      if (
        customEventDate &&
        modernWorkSecurityEventCalendarMomentListForDetail
          .map((m) => m.CMRDID)
          .includes(customEventDate.CustomEventDateId)
      ) {
        selectedMomentId =
          modernWorkSecurityEventCalendarMomentListForDetail.find(
            (m) => m.CMRDID === customEventDate.CustomEventDateId
          )?.Id || 0;
        momentInitValues =
          modernWorkSecurityEventCalendarMomentListForDetail.map((m) => {
            return { key: m.Id, text: m.DisplayText };
          });
        appDispatch(
          milestoneReducer.actions.changeMoment({
            MomentId: selectedMomentId,
          })
        );
      } else if (customEventDate) {
        selectedMomentId = -1;
        momentInitValues =
          modernWorkSecurityEventCalendarMomentListForDetail.map((m) => {
            return { key: m.Id, text: m.DisplayText };
          });
        momentInitValues.push({ key: -1, text: customEventDate.Text });
      }
    } else if (
      milestone.ModernWorkSecurityEventCalendarMomentsId !== null &&
      milestone.ModernWorkSecurityEventCalendarMomentsId >= 0
    ) {
      momentInitValues = modernWorkSecurityEventCalendarMomentListForDetail.map(
        (m) => {
          return { key: m.Id, text: m.DisplayText };
        }
      );
      if (
        customEventDate &&
        modernWorkSecurityEventCalendarMomentListForDetail
          .map((m) => m.CMRDID)
          .includes(customEventDate.CustomEventDateId)
      ) {
        selectedMomentId =
          modernWorkSecurityEventCalendarMomentListForDetail.find(
            (m) => m.CMRDID === customEventDate.CustomEventDateId
          )?.Id || 0;
        momentInitValues =
          modernWorkSecurityEventCalendarMomentListForDetail.map((m) => {
            return { key: m.Id, text: m.DisplayText };
          });
      } else if (customEventDate) {
        selectedMomentId = -1;
        momentInitValues =
          modernWorkSecurityEventCalendarMomentListForDetail.map((m) => {
            return { key: m.Id, text: m.DisplayText };
          });
        momentInitValues.push({ key: -1, text: customEventDate.Text });
      }
      if (
        modernWorkSecurityEventCalendarMomentForDetail &&
        modernWorkSecurityEventCalendarMomentForDetail.Id ===
          milestone.ModernWorkSecurityEventCalendarMomentsId &&
        momentInitValues.findIndex(
          (m) => m.key === modernWorkSecurityEventCalendarMomentForDetail.Id
        ) === -1
      ) {
        momentInitValues.unshift({
          key: modernWorkSecurityEventCalendarMomentForDetail.Id,
          text: modernWorkSecurityEventCalendarMomentForDetail.DisplayTextWithYear,
        });
      }
    } else {
      momentInitValues = modernWorkSecurityEventCalendarMomentListForDetail.map(
        (m) => {
          return { key: m.Id, text: m.DisplayText };
        }
      );
    }
  }
  momentInitValues.unshift({ key: 0, text: "None" });

  const { tooltips, isTooltipsLoading } = useSelector(
    (state: RootState) => state.tooltipReducer
  );
  const [query, setQuery] = useSearchParams();

  useEffect(() => {
    //Form Initialization
    if (
      Object.keys(tooltips).length === 0 &&
      isTooltipsLoading !== _LOADINGSTATE.pending
    ) {
      appDispatch(tooltipReducer.getTooltip());
    }
    if (productIdAdnNames.length === 0) {
      appDispatch(productReducer.getProductIdAndName());
    }
    if (
      milestoneTypes.length === 0 &&
      isMilestoneTypesLoading !== _LOADINGSTATE.pending
    ) {
      appDispatch(getMilestoneTypes());
    }
    if (
      milestoneTiers.length === 0 &&
      isMilestoneTiersLoading !== _LOADINGSTATE.pending
    ) {
      appDispatch(getMilestoneTiers());
    }
    if (audiences.length === 0) {
      appDispatch(audienceReducer.getAudiences());
    }
    if (milestoneModalContext.milestoneModalState.modalType !== ModalType.New) {
      appDispatch(
        milestoneReducer.getMilestone(
          milestoneModalContext.milestoneModalState.milestoneId
        )
      )
        .then(unwrapResult)
        .then((milestone: Milestone) => {
          if (
            milestoneModalContext.milestoneModalState.modalType ===
            ModalType.Copy
          ) {
            appDispatch(milestoneReducer.actions.resetMilestoneId());
          }
          //Get EventDate By Id
          if (
            milestoneModalContext.milestoneModalState.modalType ===
              ModalType.Edit ||
            milestoneModalContext.milestoneModalState.modalType ===
              ModalType.Copy ||
            milestoneModalContext.milestoneModalState.modalType ===
              ModalType.ReadOnly
          ) {
            appDispatch(productReducer.getProduct(milestone.ProductId));
            appDispatch(getCustomEventDateByMilestoneId(milestone.MilestoneId));
          }
          if (
            milestone.DisclosureStartDate !== null &&
            milestoneModalContext.milestoneModalState.modalType ===
              ModalType.Edit
          ) {
            milestoneModalContext.setMilestoneModalStateHandler({
              milestoneId:
                milestoneModalContext.milestoneModalState.milestoneId,
              disclosureStartDate: milestone.DisclosureStartDate,
              modalType: ModalType.Edit,
            });
          }
        })
        .catch(() => {
          milestoneModalContext.modalRef?.current?.click();
        });
    }
    if (
      milestoneModalContext.milestoneModalState.modalType === ModalType.Edit
      || milestoneModalContext.milestoneModalState.modalType === ModalType.ReadOnly
    ) {
      if (!query.has("milestoneId")) {
        query.append(
          "milestoneId",
          milestoneModalContext.milestoneModalState.milestoneId.toString()
        );
      }
      setQuery(query);
    }
    if (milestoneModalContext.milestoneModalState.modalType === ModalType.New) {
      appDispatch(
        milestoneReducer.actions.changeLoading(_LOADINGSTATE.fullfilled)
      );
    }
  }, []);

  const milestonetypeValidation = (
    milestonetypeId: number,
    featureSKUService: number,
    answer: string,
    closeDownDate: string | null,
    roadmapStartDate: string | null,
    disclosureStartDate: string | null
  ) => {
    if (milestonetypeId === 0 || milestonetypeId === MilestoneTypeId.Retired) {
      //Retired is not available for future choice
      return false;
    }
    if (
      milestonetypeId === MilestoneTypeId.GA_Retired ||
      milestonetypeId === MilestoneTypeId.Preview_Retired
    ) {
      return (
        closeDownDate &&
        closeDownDate.length > 0 &&
        featureSKUService >= 0 &&
        answer.trim().length > 0
      );
    } else {
      return (
        roadmapStartDate &&
        roadmapStartDate.length > 0 &&
        disclosureStartDate &&
        disclosureStartDate.length > 0
      );
    }
  };

  useEffect(() => {
    if (
      milestone.ProductId > 0 &&
      milestone.MilestoneName &&
      milestone.MilestoneName.trim().length > 0 &&
      milestone.MilestoneName.trim().length <= 100 &&
      milestone.MilestoneDescription &&
      milestone.MilestoneDescription.trim().length > 0 &&
      milestonetypeValidation(
        milestone.MilestoneTypeId,
        milestone.FeatureSKUService,
        milestone.Answer,
        milestone.CloseDownDate,
        milestone.RoadmapStartDate,
        milestone.DisclosureStartDate
      ) &&
      milestone.ContactName &&
      milestone.ContactName.trim().length > 0 &&
      milestone.MarketingLeadName &&
      milestone.MarketingLeadName.trim().length > 0 &&
      milestone.EngineeringContactName &&
      milestone.EngineeringContactName.trim().length > 0 &&
      milestone.TierId > 0 &&
      milestone.SelectedAudiences &&
      (JSON.parse(milestone.SelectedAudiences) as Array<number>)?.length > 0
    ) {
      appDispatch(milestoneReducer.actions.changeIsFormValid(true));
    } else {
      appDispatch(milestoneReducer.actions.changeIsFormValid(false));
    }
  }, [
    milestone.ProductId,
    milestone.MilestoneName,
    milestone.MilestoneDescription,
    milestone.MilestoneTypeId,
    milestone.FeatureSKUService,
    milestone.Answer,
    milestone.CloseDownDate,
    milestone.RoadmapStartDate,
    milestone.DisclosureStartDate,
    milestone.ContactName,
    milestone.MarketingLeadName,
    milestone.TierId,
    milestone.SelectedAudiences,
    milestone.EngineeringContactName,
  ]);
  const [appearErrorInfo, setAppearErrorInfo] = useState(false);
  const considerSubmitted = (condition: boolean) => {
    //we want error message to show after user click save edits button
    return appearErrorInfo && condition;
  };
  const readOnly =
    appContext.accessType !== _AUTHORIZATIONTYPE.admin &&
    appContext.accessType !== _AUTHORIZATIONTYPE.dri &&
    appContext.accessType !== _AUTHORIZATIONTYPE.editable;
  const tooltipMilestoneType = useRef<HTMLButtonElement>(null);
  return (
    <div
      className="milestone-form"
      style={
        milestoneModalContext.milestoneModalState.modalType === ModalType.New
          ? { borderTop: milestoneFormBorderTop }
          : {}
      }
    >
      {milestoneModalContext.milestoneModalState.modalType === ModalType.Edit ||
      milestoneModalContext.milestoneModalState.modalType ===
        ModalType.ReadOnly ? (
        ""
      ) : (
        <>
          <div className="row">
            <div>
              <div className="modal-detail-header">Milestone details</div>
            </div>
          </div>
          <div className="row">
            <div>
              <label className="modal-detail-label">Product *</label>
              <Select
                styles={
                  considerSubmitted(milestone.ProductId === 0)
                    ? { ...reactselectSearchableErrorStyleProps }
                    : { ...reactselectSearchableStyleProps }
                }
                isClearable={true}
                isSearchable={true}
                options={productInitValues}
                value={
                  productInitValues.filter(
                    (p) => p.value === milestone.ProductId.toString()
                  )[0]
                }
                onInputChange={(value) => {
                  setProductName(value || "");
                }}
                id="milestone_product"
                name="milestone_product"
                placeholder="Select an option"
                onChange={(value: any) => {
                  appDispatch(
                    milestoneReducer.actions.changeProductId(
                      Number(value?.value || 0)
                    )
                  );
                  props.setMilestoneFormChanged(true);
                  appDispatch(
                    productReducer.getProduct(Number(value?.value || 0))
                  );
                }}
                aria-label="Product, required field"
                isDisabled={readOnly}
              />
              {considerSubmitted(milestone.ProductId === 0) ? (
                <span className="modal-detail-error">Product is required</span>
              ) : (
                ""
              )}
            </div>
          </div>
        </>
      )}
      {milestoneModalContext.milestoneModalState.modalType === ModalType.Edit ||
      milestoneModalContext.milestoneModalState.modalType ===
        ModalType.ReadOnly ? (
        <div className="row">
          <div className="col-6">
            <TextField
              label="Product"
              value={
                productIdAdnNames.find((p) => {
                  return p.ProductId === milestone.ProductId;
                })?.ProductName
              }
              styles={textfieldStylesProps}
              ariaLabel="Product name"
              aria-labelledby="Product name"
              disabled={true}
            />
          </div>
          <div className="col-3">
            <TextField
              label="Product ID"
              value={milestone.ProductId.toString()}
              styles={textfieldStylesProps}
              ariaLabel="Product id"
              aria-labelledby="Product id"
              disabled={true}
            />
          </div>
          <div className="col-3">
            <TextField
              label="CSA"
              value={product?.ConversationsDisplayString}
              styles={textfieldStylesProps}
              ariaLabel="CSA"
              aria-labelledby="CSA"
              disabled={true}
            />
          </div>
        </div>
      ) : (
        ""
      )}

      <div className="row">
        <div>
          {/*<Tooltip dynamicContent={tooltips["RoadmapName"] || ""}>*/}
          <TextField
            label="Milestone title *"
            value={milestone.MilestoneName}
            onChange={(event) => {
              appDispatch(
                milestoneReducer.actions.changeMilestoneName(
                  event.currentTarget.value
                )
              );
              props.setMilestoneFormChanged(true);
            }}
            errorMessage={
              considerSubmitted(milestone.MilestoneName.trim().length === 0)
                ? "Milestone title is required."
                : considerSubmitted(milestone.MilestoneName.length > 100)
                ? `Milestone title exceeds 100 characters.`
                : ""
            }
            styles={textfieldStylesProps}
            ariaLabel="Milestone title, required field"
            aria-labelledby="Milestone title, required field"
            disabled={readOnly}
          />
          {/*</Tooltip>*/}
        </div>
      </div>
      <div className="row">
        <TextEditor
          id="milestone_description"
          label="Benefit description *"
          onChangeHandler={(event: any, editor: any) => {
            const data = editor.getData();
            appDispatch(
              milestoneReducer.debounceChange(
                milestoneReducer.actions.changeMilestoneDescription(data),
                500
              )
            );
          }}
          error={
            considerSubmitted(milestone.MilestoneDescription.length === 0)
              ? "Benefit description is required."
              : ""
          }
          value={milestone.MilestoneDescription}
          editor={ClassicEditor}
          disabled={readOnly}
          onBlurHandler={(event: any, editor: any) => {
            props.setMilestoneFormChanged(true);
          }}
        />
      </div>
      <div className="row">
        <div className="col-4">
          <div>
            {/*<Tooltip dynamicContent={tooltips["MilestoneTypeId"] || ""}>*/}
            <ModalTooltip
              refs={tooltipMilestoneType}
              title="Milestone type definitions:"
              subText=""
            >
              <div>
                <table className="milestone-popup">
                  <tr>
                    <th>Milestone type</th>
                    <th>Definition</th>
                  </tr>
                  <tr>
                    <td>Disclosure only</td>
                    <td>
                      Communication of a product or feature release and update
                      to the field and the public.
                    </td>
                  </tr>
                  <tr>
                    <td>GA</td>
                    <td>
                      The point in product or service development when all
                      necessary testing and commercialization activities are
                      complete and ready to be made available to all interested
                      customers.
                    </td>
                  </tr>
                  <tr>
                    <td>GA Retired</td>
                    <td>
                      Approved GA retirement means the Office of CTO and Azure
                      CTO have approved the GA retirement. If in doubt, check
                      with your program manager.
                    </td>
                  </tr>
                  <tr>
                    <td>Preview Retired</td>
                    <td>
                      Approved preview retirement means Azure retirements v-team
                      has reviewed the retirement and sent out customer emails.
                      If in doubt, check with your program manager.
                    </td>
                  </tr>
                  <tr>
                    <td>Private Preview</td>
                    <td>
                      A preview of a new product, version, or feature provided
                      to a limited audience.
                    </td>
                  </tr>
                  <tr>
                    <td>Public Preview</td>
                    <td>
                      Prerelease software intended to make the latest working
                      build of products available to customers, to excite and
                      involve the community in the development process and get
                      more prerelease feedback.
                    </td>
                  </tr>
                  <tr>
                    <td>RTM</td>
                    <td>
                      Release to Manufacturing. A product or product design with
                      full documentation ready to send to manufacturing for
                      product build and subsequent release.
                    </td>
                  </tr>
                </table>
              </div>
            </ModalTooltip>
            <label className="modal-detail-label">
              Milestone type * |{" "}
              <a
                href="#!"
                aria-label="More info of milestone type"
                onClick={(event) => {
                  event.preventDefault();
                  tooltipMilestoneType.current?.click();
                }}
              >
                More info
              </a>
            </label>
            <Dropdown
              options={milestoneTypesInitValues}
              id="MilstoneType"
              label=""
              selectedKey={milestone.MilestoneTypeId}
              placeholder="Select an option"
              onChange={(
                event: React.FormEvent<HTMLDivElement>,
                item?: IDropdownOption
              ) => {
                appDispatch(
                  milestoneReducer.actions.changeMilestoneType(
                    Number(item?.key)
                  )
                );
                props.setMilestoneFormChanged(true);
              }}
              errorMessage={
                milestone.MilestoneTypeId === MilestoneTypeId.Retired
                  ? "Milestone type can not be retired"
                  : considerSubmitted(milestone.MilestoneTypeId === 0)
                  ? "Milestone type is required."
                  : ""
              }
              styles={dropdownStylesProps}
              ariaLabel="Milestone type, required field"
              disabled={readOnly}
            />
            {/*</Tooltip>*/}
          </div>
          <GraphUsersAutoCompleteWithTeamsIcon
            label="Engineering owner *"
            error={considerSubmitted(true) ? "Engineering is required." : ""}
            value={milestone.EngineeringContactName}
            onChangeHandler={(value) => {
              appDispatch(
                milestoneReducer.actions.changeEngineeringContactName(
                  value?.value || ""
                )
              );
              props.setMilestoneFormChanged(true);
            }}
            microsoftUser={true}
            id="milestoneForm_Engineering_Owner"
            disabled={readOnly}
          />
          <MilestoneTypeRelated
            milestonetypeId={milestone.MilestoneTypeId}
            submitted={appearErrorInfo}
            tooltips={tooltips}
            disabled={readOnly}
            setMilestoneFormChanged={props.setMilestoneFormChanged}
          />
        </div>
        <div className="col-4">
          <Tooltip dynamicContent={tooltips["ContactName"] || ""}>
            <GraphUsersAutoCompleteWithTeamsIcon
              label="PMM *"
              error={considerSubmitted(true) ? "PMM is required." : ""}
              value={milestone.ContactName}
              onChangeHandler={(value) => {
                appDispatch(
                  milestoneReducer.actions.changeContactName(value?.value || "")
                );
                props.setMilestoneFormChanged(true);
              }}
              microsoftUser={true}
              id="milestoneForm_PMM"
              disabled={readOnly}
            ></GraphUsersAutoCompleteWithTeamsIcon>
          </Tooltip>
          <div>
            <Tooltip dynamicContent={tooltips["TierId"] || ""}>
              <>
                <label className="modal-detail-label">
                  Marketing tier * |{" "}
                  <a
                    href="https://aka.ms/cmtieringframework"
                    aria-label="More info of marketing tier"
                    target="_blank"
                  >
                    More info
                  </a>
                </label>
                <Dropdown
                  label=""
                  options={tiersInitValues}
                  id="MilstoneTier"
                  selectedKey={milestone.TierId}
                  placeholder="Select an option"
                  onChange={(
                    event: React.FormEvent<HTMLDivElement>,
                    item?: IDropdownOption
                  ) => {
                    appDispatch(
                      milestoneReducer.actions.changeTier(Number(item?.key))
                    );
                    props.setMilestoneFormChanged(true);
                  }}
                  errorMessage={
                    considerSubmitted(milestone.TierId === 0)
                      ? "Tier is required."
                      : ""
                  }
                  styles={dropdownStylesProps}
                  ariaLabel="Marketing tier, required field"
                  disabled={readOnly}
                />
              </>
            </Tooltip>
          </div>
          <div>
            <Tooltip dynamicContent={tooltips["CustomEventDateIdCMMT"] || ""}>
              <>
                <label className="modal-detail-label">
                  Aligned to marketing moment |{" "}
                  <a
                    href="https://microsoft.sharepoint.com/teams/CloudMarketingMoments/SitePages/Cloud-Marketing-moments-calendar-DRIs.aspx"
                    aria-label="More info of request a marketing moment"
                    target="_blank"
                  >
                    More info
                  </a>
                  {/*<Tooltip*/}
                  {/*  dynamicContent={*/}
                  {/*    tooltips["RequestMarketingMomentCMMT"] || ""*/}
                  {/*  }*/}
                  {/*>*/}
                  {/*  <i*/}
                  {/*    className="fa fa-info-circle"*/}
                  {/*    style={{ cursor: "hand" }}*/}
                  {/*    aria-hidden="true"*/}
                  {/*  ></i>*/}
                  {/*</Tooltip>*/}
                </label>
                <Dropdown
                  label=""
                  options={momentInitValues}
                  id="milestone_event"
                  selectedKey={
                    milestone.ModernWorkSecurityEventCalendarMomentsId !== null
                      ? milestone.ModernWorkSecurityEventCalendarMomentsId
                      : selectedMomentId
                  }
                  placeholder="Select an option"
                  onChange={(
                    event: React.FormEvent<HTMLDivElement>,
                    item?: IDropdownOption
                  ) => {
                    appDispatch(
                      milestoneReducer.actions.changeMoment({
                        MomentId: Number(item?.key),
                      })
                    );
                    props.setMilestoneFormChanged(true);
                  }}
                  onRenderOption={(
                    option: IDropdownOption | undefined
                  ): JSX.Element => {
                    return option !== undefined ? (
                      <span
                        className="ms-Dropdown-optionText"
                        style={{
                          overflow: `hidden`,
                          whiteSpace: `nowrap`,
                          textOverflow: `ellipsis`,
                          maxWidth: `100%`,
                          overflowWrap: `break-word`,
                          margin: 1,
                        }}
                        title={option?.text || ""}
                        dangerouslySetInnerHTML={{
                          __html: option?.text || "",
                        }}
                      ></span>
                    ) : (
                      <></>
                    );
                  }}
                  onRenderTitle={(
                    options: IDropdownOption[] | undefined
                  ): JSX.Element => {
                    const option = options ? options[0] : undefined;
                    return (
                      <span
                        title={option?.text}
                        dangerouslySetInnerHTML={{ __html: option?.text || "" }}
                      ></span>
                    );
                  }}
                  errorMessage=""
                  styles={dropdownStyles}
                  ariaLabel="Aligned to marketing moment"
                  disabled={readOnly}
                />
              </>
            </Tooltip>
          </div>
        </div>
        <div className="col-4">
          <Tooltip dynamicContent={tooltips["MarketingLeadNameCMMT"] || ""}>
            <GraphUsersAutoCompleteWithTeamsIcon
              label="Marketing team lead *"
              error={
                considerSubmitted(true)
                  ? "Marketing team lead is required."
                  : ""
              }
              value={milestone.MarketingLeadName}
              onChangeHandler={(value) => {
                appDispatch(
                  milestoneReducer.actions.changeMarketingLeadName(
                    value?.value || ""
                  )
                );
                props.setMilestoneFormChanged(true);
              }}
              microsoftUser={true}
              id="milestoneForm_MarketingLeadName"
              disabled={readOnly}
            ></GraphUsersAutoCompleteWithTeamsIcon>
          </Tooltip>
          <div>
            {/*<Tooltip dynamicContent={tooltips["MilestoneAudienceTags"] || ""}>*/}
            <Dropdown
              label="Target audience *"
              id="milestone_audience"
              options={audienceOptions}
              selectedKeys={
                JSON.parse(milestone.SelectedAudiences) as Array<number>
              }
              placeholder={"Select one or more options"}
              multiSelect
              onChange={(
                event: React.FormEvent<HTMLDivElement>,
                item: IDropdownOption | undefined
              ) => {
                let temp = [];
                if (item && item.selected) {
                  temp = JSON.parse(milestone.SelectedAudiences);
                  temp.push(item.key);
                } else if (item && !item.selected) {
                  temp = (
                    JSON.parse(milestone.SelectedAudiences) as Array<number>
                  ).filter((a) => {
                    return a !== item.key;
                  });
                }
                appDispatch(
                  milestoneReducer.actions.changeSelectedAudiences(
                    JSON.stringify(temp)
                  )
                );
                props.setMilestoneFormChanged(true);
              }}
              styles={dropdownStylesProps}
              errorMessage={
                considerSubmitted(
                  JSON.parse(milestone.SelectedAudiences).length === 0
                )
                  ? "Audience tag is required."
                  : ""
              }
              aria-label="Target Audience, required field"
              aria-labelledby=""
              disabled={readOnly}
            ></Dropdown>
            {/*</Tooltip>*/}
          </div>
          <div>
            <label className="modal-detail-label">Content restrictions</label>
            <Checkbox
              label="Do not share with public or field"
              checked={milestone.IsNotShare}
              onChange={(
                ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
                checked?: boolean
              ): void => {
                appDispatch(
                  milestoneReducer.actions.changeIsNotShare(checked || false)
                );
                props.setMilestoneFormChanged(true);
              }}
              disabled={readOnly}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12 mt-5 mb-4">
          <LastModifiedBy
            modifiedBy={
              isVoid(milestone.ModifiedBy) ? undefined : milestone.ModifiedBy
            }
            modifiedAt={
              milestone.ModifiedDate
                ? moment(new Date(milestone.ModifiedDate)).format(
                    "MM/DD/YYYY h:mm:ss a"
                  )
                : ""
            }
          />
          <DefaultButton
            disabled={
              milestone.__isLoading === _LOADINGSTATE.pending || readOnly
            }
            text="Save"
            ariaDescription="Save Milestone"
            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
              event.preventDefault();
              setAppearErrorInfo(true);

              if (milestone.__isFormValid) {
                if (
                  milestoneModalContext.milestoneModalState.modalType ===
                    ModalType.New ||
                  milestoneModalContext.milestoneModalState.modalType ===
                    ModalType.Copy
                ) {
                  appDispatch(milestoneReducer.postMilestone(milestone))
                    .then(unwrapResult)
                    .then(() => {
                      props.setMilestoneFormSubmited(true);
                      SuccessMessage.show(
                        "The milestone content has been saved successfully."
                      );
                      props.setMilestoneFormChanged(false);
                      setAppearErrorInfo(false);
                      milestoneModalContext.modalRef?.current?.click();
                    })
                    .catch(() => {
                      setAppearErrorInfo(false);
                    });
                }
                if (
                  milestoneModalContext.milestoneModalState.modalType ===
                  ModalType.Edit
                ) {
                  if (props.milestoneFormChanged === true) {
                    appDispatch(
                      milestoneReducer.updateMilestone({
                        Id: milestone.MilestoneId,
                        Milestone: milestone,
                      })
                    )
                      .then(unwrapResult)
                      .then(() => {
                        props.setMilestoneFormSubmited(true);
                        appDispatch(
                          azureUpdateDeckReducer.getAzureUpdateDeck(
                            milestone.MilestoneId
                          )
                        );
                        SuccessMessage.show(
                          "The milestone content has been saved successfully."
                        );
                        props.setMilestoneFormChanged(false);
                        setAppearErrorInfo(false);
                      })
                      .catch(() => {
                        setAppearErrorInfo(false);
                      });
                  }
                }
              }
              event.stopPropagation();
            }}
          />
          <span
            style={{
              display: "inline-block",
              width: 30,
              height: 19,
            }}
          >
            {milestone.__isLoading === _LOADINGSTATE.pending ? (
              <Spinner
                size={SpinnerSize.large}
                style={{
                  display: "inline",
                  marginLeft: "10px",
                  position: "absolute",
                }}
              />
            ) : (
              <></>
            )}
          </span>
          <span className="modal-detail-error mx-4">
            Be sure to click Save before leaving this window.
          </span>
          <p className="fw-bold mt-1">
            Submit marketing channel requests (i.e., blog, social, digital
            event, web updates, paid media, etc.) using the{" "}
            <a href="https://aka.ms/BADEIntake" target="_blank">
              marketing work intake
            </a>{" "}
            tool.
          </p>
        </div>
      </div>
    </div>
  );
};

export default MilestoneForm;
