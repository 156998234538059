import { FocusTrapZone, Icon, Spinner, SpinnerSize } from "@fluentui/react";
import moment from "moment";
import { useContext, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../../store";
import Accordion from "../../Shared/accordion";
import {
  getFeatureAndMilestoneByMomentId,
  MilestoneTierGroup,
} from "../../../slice/calendar";
import { isVoid } from "../../../utils/utils";

const MomentAssociationDetail = (props: {
  currentSelectedEventId: number | null;
}) => {
  const appDispatch = useAppDispatch();
  var updatereducer = useSelector(
    (state: RootState) => state.calendarUpdateReducer
  );
  const [isLoading, setIsLoading] = useState(true);
  const currentMoment = updatereducer.currentMoment;
  const [allMilestoneGroupByTierInfo, setallMilestoneGroupByTierInfo] =
    useState<MilestoneTierGroup[]>();
  useEffect(() => {
    if (currentMoment.Id) {
      if (!currentMoment.MilestoneTierGroups) {
        appDispatch(getFeatureAndMilestoneByMomentId(currentMoment.Id)).then(
          (response) => {
            const json = response.payload as {
              id: number;
              group: MilestoneTierGroup[];
            };
            setallMilestoneGroupByTierInfo(json.group);
            setIsLoading(false);
          }
        );
      } else {
        setallMilestoneGroupByTierInfo(currentMoment.MilestoneTierGroups);
        setIsLoading(false);
      }
    }
  }, [currentMoment.Id]);
  return (
    <FocusTrapZone isClickableOutsideFocusTrap>
      {isLoading ? (
        <Spinner size={SpinnerSize.small} ariaLive="polite" />
      ) : (
        <>
          {allMilestoneGroupByTierInfo?.map((p) => {
            return (
              <Accordion
                title={"Tier " + p.Tier + " Disclosures"}
                isDefaultOpen={true}
              >
                <>
                  {p.MilestonesGroup.map((d) => {
                    return (
                      <>
                        <h6 className="SubTitle">{d.Type}</h6>
                        <a>
                          {d.Milestones.map((m) => {
                            return (
                              <>
                                <div style={{ fontSize: "13px" }}>
                                  {isVoid(m.Date)
                                    ? ""
                                    : moment(new Date(m.Date))
                                        .utc()
                                        .format("MM/DD")}
                                  {` `}
                                  <a
                                    href={m.Link}
                                    onClick={(e) => {}}
                                    title={m.Name}
                                    target={"_blank"}
                                  >
                                    {m.Name}
                                  </a>
                                </div>
                              </>
                            );
                          })}
                        </a>
                      </>
                    );
                  })}
                </>
              </Accordion>
            );
          })}
        </>
      )}
    </FocusTrapZone>
  );
};

export default MomentAssociationDetail;
