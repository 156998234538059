import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Spinner, SpinnerSize } from "@fluentui/react";
import moment from "moment";
import { RootState, useAppDispatch } from "../../../store";
import "./index.scss";
import { _LOADINGSTATE } from "../../Shared/constants";
import * as productReducer from "../../../slice/product";
import { getNamePart } from "../../../utils/string";

const RenderProducts = () => {
  const appDispatch = useAppDispatch();
  const dataSource = useSelector(
    (state: RootState) => state.productReducer.productsInLastSixMonths
  );
  const isProductsWithoutMilestonesLoading = useSelector(
    (state: RootState) =>
      state.productReducer.isProductsWithoutMilestonesLoading
  );
  const [isHidden, setIsHidden] = useState(false);

  useEffect(() => {
    if (isProductsWithoutMilestonesLoading !== _LOADINGSTATE.fullfilled) {
      appDispatch(productReducer.getProductsWithoutMilestones());
    }
  }, [appDispatch, isProductsWithoutMilestonesLoading]);

  return isProductsWithoutMilestonesLoading === _LOADINGSTATE.pending ? (
    <div className="grid-item column-span-all">
      <Spinner
        size={SpinnerSize.large}
        label="Loading content"
        ariaLive="polite"
        labelPosition="top"
      />
    </div>
  ) : (
    <div className="grid-item-product">
      <div
        aria-label={isHidden ? "Expand all" : "Collapse all"}
        className="collapse-all"
        onClick={(e: React.MouseEvent<HTMLElement>) => {
          e.preventDefault();
          setIsHidden(() => !isHidden);
        }}
        onKeyDown={(e) => {
          if (e.keyCode === 13) {
            e.currentTarget.click();
          }
        }}
      >
        <div
          className="product-title"
          tabIndex={0}
          aria-label="Products without milestones"
          aria-expanded={isHidden ? "false" : "true"}
          role="status"
        >
          Products without milestones
        </div>
        <div className="product-title">
          <a href={void 0} className="collapse-all-text" tabIndex={0}>
            {isHidden ? "Expand all" : "Collapse all"}
          </a>
        </div>
      </div>
      <div className="bottom-line"></div>
      <div className={`${isHidden ? "d-none" : ""}`}>
        <div className="content-row-product-title d-flex">
          <div>
            <strong>Date created</strong>
          </div>
          <div>
            <strong>Product name</strong>
          </div>
          <div>
            <strong>Product ID</strong>
          </div>
          <div>
            <strong>CSA</strong>
          </div>
          <div>
            <strong>Product category</strong>
          </div>
          <div>
            <strong>Naming status</strong>
          </div>
          <div>
            <strong>Created by</strong>
          </div>
        </div>
        <div className="d-flex flex-wrap">
          {dataSource.map((item, index) => {
            return (
              <div>
                <div className="spacer"></div>
                <div className="content-row-product-widget">
                  <div className="content-row-product d-flex">
                    <div>
                      {item.ProductCreatedDate === undefined
                        ? ""
                        : moment(new Date(item.ProductCreatedDate))
                            .utc()
                            .format("MM/DD/YYYY")}
                    </div>
                    <div title={item.ProductName}>{item.ProductName}</div>
                    <div>{item.ProductId}</div>
                    <div title={item.ConversationsDisplayString}>
                      {item.ConversationsDisplayString}
                    </div>
                    <div title={item.ProductACOMCategoriesDisplayString}>
                      {item.ProductACOMCategoriesDisplayString}
                    </div>
                    <div>{item.ProductStateName}</div>
                    <div title={getNamePart(item.CreatedBy)}>
                      {getNamePart(item.CreatedBy)}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default RenderProducts;
