import { useContext } from "react";
import "../../../Shared/css/timelineGrid.scss";
import "../index.scss";
import { DirectionalHint } from "@fluentui/react";
import { TooltipHost, ITooltipHostStyles } from "@fluentui/react/lib/Tooltip";
import { useId } from "@fluentui/react-hooks";
import { formatCYDate, formatDate } from "../../../../utils/date";
import { isVoid } from "../../../../utils/utils";
import { ModalType, TimelineMode } from "../../../Shared/constants";
import { MilestoneStatusIcon } from "../../../AzureTimeline/NavBar";
import { FeatureUtil } from "../../../ModernWorkFeature/featureCommon";
import { modernWorkTimelineFeature } from "../../../../slice/modernWorkTimeline";
import { ModernWorkFeatureModalContext } from "../../index";
import { truncateText } from "../../../../utils/utils";
import FeatureSearchRow from "./featureSearchRow";
import moment from "moment";

const calloutProps = { gapSpace: 0 };
const hostStyles: Partial<ITooltipHostStyles> = {
  root: { display: "inline-block" },
};

const FeatureRow = (props: { feature: modernWorkTimelineFeature }) => {
  const modernWorkFeatureModalContext = useContext(
    ModernWorkFeatureModalContext
  )!;

  const tooltipId = useId("featureCardTooltip");

  const featureTitle = !isVoid(props.feature.MarketingTitle)
    ? props.feature.MarketingTitle
    : !isVoid(props.feature.PublicTitle)
    ? props.feature.PublicTitle
    : !isVoid(props.feature.Title)
    ? props.feature.Title
    : "";

  return (
    <TooltipHost
      content={props.feature.PublicRoadmapStatus}
      id={tooltipId}
      calloutProps={calloutProps}
      styles={hostStyles}
      directionalHint={DirectionalHint.bottomAutoEdge}
    >
      {(moment(props.feature.Created).weekday() < 3
        ? Math.abs(
            moment(new Date()).diff(moment(props.feature.Created)) /
              1000 /
              3600 /
              24 /
              5
          )
        : Math.abs(
            moment(new Date()).diff(moment(props.feature.Created)) /
              1000 /
              3600 /
              24 /
              3
          )) < 1 ? (
        <i
          className={"ms-Icon ms-Icon--Glimmer"}
          tabIndex={0}
          title="This is a new item"
          aria-hidden="true"
        />
      ) : (
        <div className="spacer" />
      )}
      <div
        tabIndex={0}
        aria-describedby={tooltipId}
        className="content-row-widget"
        key={props.feature.Id}
        role="button"
        onClick={(e) => {
          modernWorkFeatureModalContext.setModernWorkFeatureStateHandler({
            featureTitle: featureTitle,
            featureId: props.feature.Id,
            cchFeatureId: props.feature.CCHFeatureId,
            featureDisclosureDate: null,
            featurePublicDisclosureDate: null,
            deleteFeatureHistoryId: "",
            modalType: ModalType.Edit,
          });
          modernWorkFeatureModalContext.modalRef?.current?.click();
        }}
        onKeyDown={(e) => {
          if (e.keyCode === 13) {
            e.currentTarget.click();
          }
        }}
      >
        <div
          className="content-row d-flex"
          aria-label={
            "Feature title:" +
            featureTitle +
            " Feature status:" +
            props.feature.PublicRoadmapStatus
          }
          tabIndex={0}
        >
          <div
            className={`content-card-type ${FeatureUtil.Colors.feature(
              props.feature.PublicRoadmapStatus
            )}`}
          ></div>
          {modernWorkFeatureModalContext.timelineMode ===
          TimelineMode.Search ? (
            <FeatureSearchRow feature={props.feature} />
          ) : (
            <>
              <div>{props.feature.CCHFeatureId}</div>
              <div>{props.feature.RoadmapId}</div>
              <div title={featureTitle}>{truncateText(featureTitle, 75)}</div>
              <div>
                {props.feature.Tier === "1"
                  ? "Tier 1"
                  : props.feature.Tier === "2"
                  ? "Tier 2"
                  : props.feature.Tier === "3"
                  ? "Tier 3"
                  : ""}
              </div>
              <div>
                {!props.feature.PreviewDate
                  ? ""
                  : formatCYDate(props.feature.PreviewDate)}
              </div>
              <div>
                {!props.feature.GADate
                  ? ""
                  : formatCYDate(props.feature.GADate)}
              </div>
              <div>{formatDate(props.feature.PlannedProductionDate)}</div>
              <div>
                {!props.feature.PublicDisclosureDate ||
                formatDate(props.feature.PublicDisclosureDate) === "01/01/0001"
                  ? ""
                  : formatDate(props.feature.PublicDisclosureDate)}
              </div>
              <div>
                {props.feature.ModernWorkSecurityEventCalendarMomentsTitle}
              </div>
              <div title={props.feature.PMGContact}>
                {truncateText(props.feature.PMGContact, 69)}
              </div>
              <div>
                <span>
                  {
                    MilestoneStatusIcon[
                      props.feature.TierId && props.feature.TierId !== 0 ? 4 : 5
                    ]
                  }
                </span>
              </div>
            </>
          )}
        </div>
      </div>
    </TooltipHost>
  );
};

export default FeatureRow;
