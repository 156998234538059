import React, { useState, useContext, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import { unwrapResult } from "@reduxjs/toolkit";
import {
  ChoiceGroup,
  IChoiceGroupOption,
  TextField,
  PrimaryButton,
  Spinner,
  SpinnerSize,
} from "@fluentui/react";
import {
  convertMonthStringToDate,
  getFirstDayInTheMonth,
  getLastDayInTheMonth,
} from "../../../utils/date";
import "./index.scss";
import {
  milestoneFormBorderTop,
  ModalType,
  TimelineMode,
  _AUTHORIZATIONTYPE,
  _LOADINGSTATE,
} from "../../Shared/constants";
import { textareaStylesProps } from "../../Shared/styleProps";
import { SuccessMessage } from "../../Shared/messageBox";
import Accordion from "../../Shared/accordion";
import Tooltip from "../../Shared/FormInput/tooltip";
import ModalOverlay from "../../Shared/FormInput/modalOverlay";
import { RootState, useAppDispatch } from "../../../store";
import * as tooltipReducer from "../../../slice/tooltip";
import * as milestoneReducer from "../../../slice/milestone";
import * as azureTimelineReducer from "../../../slice/azureTimeline";
import MilestoneForm from "./MilestoneForm";
import { MilestoneModalContext } from "../../AzureTimeline";
import { AppContext } from "../../../App";

export const MilestoneModal = (props: {}) => {
  const [milestoneFormChanged, setMilestoneFormChanged] = useState(false);
  const [modalFormChanged, setModalFormChanged] = useState(false);
  const appContext = useContext(AppContext);
  const milestoneModalContext = useContext(MilestoneModalContext);
  const [milestoneFormSubmited, setMilestoneFormSubmited] = useState(false);
  const [statusSubmited, setStatusSubmited] = useState(false);
  const appDispatch = useAppDispatch();
  const options: IChoiceGroupOption[] = [
    { key: "4", text: "No action" },
    { key: "5", text: "Action needed" },
  ];

  const dataSource = useSelector(
    (state: RootState) => state.azureTimelineReducer.dataSet
  );

  const milestone = useSelector<RootState, milestoneReducer.Milestone>(
    (state: RootState) => {
      return state.milestoneReducer.SingleMilestone;
    }
  );

  const onChange = (
    ev: React.SyntheticEvent<HTMLElement>,
    option: IChoiceGroupOption
  ) => {
    appDispatch(
      milestoneReducer.updateMilestoneStatus({
        MilestoneId: milestone.MilestoneId,
        RoadmapMilestoneId: milestone.RoadmapMilestoneId as number,
        DisclosureMilestoneId: milestone.DisclosureMilestoneId as number,
        Status: Number(option.key),
      })
    )
      .then(unwrapResult)
      .then(() => {
        SuccessMessage.show("The Status has been saved successfully.");
        setStatusSubmited(true);
      })
      .catch();
  };
  const { tooltips, isTooltipsLoading } = useSelector(
    (state: RootState) => state.tooltipReducer
  );
  const readOnly =
    appContext.accessType !== _AUTHORIZATIONTYPE.admin &&
    appContext.accessType !== _AUTHORIZATIONTYPE.dri &&
    appContext.accessType !== _AUTHORIZATIONTYPE.editable;
  const [hideSpinner, setHideSpinner] = useState(false);
  useEffect(() => {
    if (milestone.__isLoading === _LOADINGSTATE.fullfilled) {
      setHideSpinner(true);
    }
    if (
      Object.keys(tooltips).length === 0 &&
      isTooltipsLoading !== _LOADINGSTATE.pending
    ) {
      appDispatch(tooltipReducer.getTooltip());
    }
  }, [milestone.__isLoading]);

  const confirmationShow = useRef<HTMLButtonElement>(null);
  const [showSpinner, setShowSpinner] = useState(false);

  return (
    <ModalOverlay
      refs={milestoneModalContext.modalRef}
      title={
        milestoneModalContext.milestoneModalState.modalType === ModalType.New
          ? "Create a new milestone"
          : "Milestone ID: " +
            milestoneModalContext.milestoneModalState.milestoneId +
            (milestoneModalContext.milestoneModalState.modalType ===
            ModalType.Copy
              ? " [duplicates]"
              : "")
      }
      formSubmited={milestoneFormSubmited || statusSubmited}
      setFormSubmited={[
        setMilestoneFormSubmited,
        setStatusSubmited,
        setHideSpinner,
        setMilestoneFormChanged,
        setModalFormChanged,
      ]}
      formSubmitedCallback={() => {
        if (
          milestoneModalContext.timelineMode === TimelineMode.Timeline ||
          milestoneModalContext.timelineMode === TimelineMode.TrainDate
        ) {
          if (
            milestone.DisclosureStartDate &&
            milestone.DisclosureStartDate !== null
          ) {
            if (
              dataSource.filter((data) => {
                return (
                  moment(convertMonthStringToDate(data.month))
                    .utc()
                    .format("MM/YYYY") ===
                  moment(milestone.DisclosureStartDate).utc().format("MM/YYYY")
                );
              }).length > 0 &&
              dataSource.filter((data) => {
                return (
                  moment(convertMonthStringToDate(data.month))
                    .utc()
                    .format("MM/YYYY") ===
                  moment(milestone.DisclosureStartDate).utc().format("MM/YYYY")
                );
              })[0].isLoading !== ""
            ) {
              appDispatch(
                azureTimelineReducer.getMilestoneDataSet(
                  getFirstDayInTheMonth(
                    moment(milestone.DisclosureStartDate)
                      .utc()
                      .format("MM/DD/YYYY")
                  ),
                  getLastDayInTheMonth(
                    moment(milestone.DisclosureStartDate)
                      .utc()
                      .format("MM/DD/YYYY")
                  )
                )
              );

              appDispatch(
                azureTimelineReducer.actions.setIsLoadedExportExcelMilestone(
                  false
                )
              );
            }
          }
          if (
            milestoneModalContext.milestoneModalState.modalType ===
              ModalType.Edit &&
            milestoneModalContext.milestoneModalState.disclosureStartDate &&
            milestoneModalContext.milestoneModalState.disclosureStartDate !==
              null &&
            milestone.DisclosureStartDate &&
            milestone.DisclosureStartDate !== null &&
            moment(
              getFirstDayInTheMonth(
                milestoneModalContext.milestoneModalState.disclosureStartDate
              )
            )
              .utc()
              .format("MM/YYYY") !==
              moment(getFirstDayInTheMonth(milestone.DisclosureStartDate))
                .utc()
                .format("MM/YYYY")
          ) {
            if (
              dataSource.filter((data) => {
                return (
                  moment(convertMonthStringToDate(data.month))
                    .utc()
                    .format("MM/YYYY") ===
                  moment(
                    milestoneModalContext.milestoneModalState
                      .disclosureStartDate
                  )
                    .utc()
                    .format("MM/YYYY")
                );
              }).length > 0 &&
              dataSource.filter((data) => {
                return (
                  moment(convertMonthStringToDate(data.month))
                    .utc()
                    .format("MM/YYYY") ===
                  moment(
                    milestoneModalContext.milestoneModalState
                      .disclosureStartDate
                  )
                    .utc()
                    .format("MM/YYYY")
                );
              })[0].isLoading !== ""
            ) {
              appDispatch(
                azureTimelineReducer.getMilestoneDataSet(
                  getFirstDayInTheMonth(
                    moment(
                      milestoneModalContext.milestoneModalState
                        .disclosureStartDate
                    )
                      .utc()
                      .format("MM/DD/YYYY")
                  ),
                  getLastDayInTheMonth(
                    moment(
                      milestoneModalContext.milestoneModalState
                        .disclosureStartDate
                    )
                      .utc()
                      .format("MM/DD/YYYY")
                  )
                )
              );

              appDispatch(
                azureTimelineReducer.actions.setIsLoadedExportExcelMilestone(
                  false
                )
              );
            }
          }
        } else {
          appDispatch(
            azureTimelineReducer.getSearchDataSet(
              milestoneModalContext.searchPhrase
            )
          );
        }
      }}
      confirmationShow={confirmationShow}
      formChanged={milestoneFormChanged || modalFormChanged}
      formResetCallback={() => {
        appDispatch(milestoneReducer.actions.reset());
      }}
    >
      {!hideSpinner &&
      (milestoneModalContext.milestoneModalState.modalType === ModalType.Edit ||
        milestoneModalContext.milestoneModalState.modalType ===
          ModalType.ReadOnly) ? (
        <Spinner
          label="Loading..."
          labelPosition="bottom"
          size={SpinnerSize.large}
        />
      ) : (
        ""
      )}
      <div
        className={
          !hideSpinner &&
          (milestoneModalContext.milestoneModalState.modalType ===
            ModalType.Edit ||
            milestoneModalContext.milestoneModalState.modalType ===
              ModalType.ReadOnly)
            ? "milestone-details d-none"
            : "milestone-details"
        }
      >
        {milestoneModalContext.milestoneModalState.modalType ===
          ModalType.Edit ||
        milestoneModalContext.milestoneModalState.modalType ===
          ModalType.ReadOnly ? (
          <>
            <Accordion title="Milestone details" isDefaultOpen={true}>
              <MilestoneForm
                setMilestoneFormSubmited={setMilestoneFormSubmited}
                milestoneFormSubmited={milestoneFormSubmited}
                milestoneFormChanged={milestoneFormChanged}
                setMilestoneFormChanged={setMilestoneFormChanged}
              />
            </Accordion>
            <div
              style={{
                borderTop: milestoneFormBorderTop,
              }}
              className="milestone-details-notes"
            >
              <label className="modal-detail-header">Azure Updates:</label>
              <p>
                Azure Updates Comms will no longer utilize the CMMC tool for
                submissions and will not participate in disclosure trains. Visit{" "}
                <a
                  href="https://dev.azure.com/azurecom/ACOM/_wiki/wikis/ACOM.wiki/5133/Onboarding-for-PMs-and-other"
                  target="_blank"
                >
                  Azure Updates Onboarding
                </a>{" "}
                and please enter your content directly into the CMS platform:{" "}
                <a href="https://blogs.azure.net/updates/Admin" target="_blank">
                  Welcome to Orchard
                </a>
                .
              </p>
              <label className="modal-detail-header">Azure Field Comms:</label>
              <p>
                Azure Field Comms will no longer utilize the CMMC tool for
                submissions and will not participate in disclosure trains. Field
                Comms will rely on the{" "}
                <a
                  href="https://forms.office.com/pages/responsepage.aspx?id=v4j5cvGGr0GRqy180BHbR9637a3EjUpHuoFwphLrV1FUOURPSjlZV1dDQkJVSUtKRzBBVlk0V0FWSi4u"
                  target="_blank"
                >
                  Azure Marketing Field Announcement form
                </a>{" "}
                to process all requests for announcements on the{" "}
                <a
                  href="https://gearup.microsoft.com/communications"
                  target="_blank"
                >
                  GearUp Comms Hub
                </a>
                . After completing the form, upload your announcement content to
                the GearUp Content Exchange:{" "}
                <a
                  href="https://microsoft.sharepoint.com/teams/AzureChecklists/Shared%20Documents/Forms/AllItems.aspx?csf=1&web=1&e=7Cspng&xsdata=MDV8MDF8di1qb2hucG9ydGVyQG1pY3Jvc29mdC5jb218YWQxZmRjYmFjY2IxNDBmMGMxZjIwOGRiNzI5NDQzZGR8NzJmOTg4YmY4NmYxNDFhZjkxYWIyZDdjZDAxMWRiNDd8MXwwfDYzODIyOTc1MzM5NTEwNTAyM3xVbmtub3dufFRXRnBiR1pzYjNkOGV5SldJam9pTUM0d0xqQXdNREFpTENKUUlqb2lWMmx1TXpJaUxDSkJUaUk2SWsxaGFXd2lMQ0pYVkNJNk1uMD18MzAwMHx8fA%3D%3D&sdata=NmFzRU1zSWhRaE1zcDBCYVpRQS9sVFFYMERUeGM0eWJ1WERDcW00RGlxOD0%3D&cid=5fa20751-2df4-48c1-92ca-507c0f1dbffc&RootFolder=%2fteams%2fAzureChecklists%2fShared%20Documents%2fGearUp%20Content%20Exchange%2fField%20Announcement%20Requests&FolderCTID=0x01200044EBFABD5D10F344B716668700165770"
                  target="_blank"
                >
                  Field Announcement Request
                </a>
                .
              </p>
            </div>
            <div
              style={{
                borderTop: milestoneFormBorderTop,
              }}
              className="milestone-details-notes"
            >
              <label className="modal-detail-header">Planning notes only</label>
              <ChoiceGroup
                style={{
                  paddingRight: "30px",
                  fontSize: "12px",
                  paddingBottom: "10px",
                }}
                selectedKey={milestone.Status.toString()}
                options={options}
                onChange={(ev, option) =>
                  onChange(
                    ev as React.SyntheticEvent<HTMLElement>,
                    option as IChoiceGroupOption
                  )
                }
                disabled={!milestone.__isFormValid || readOnly}
              />
              <Tooltip
                dynamicContent={
                  tooltips["InternalNotesForSubmissionStatusCMMT"] || ""
                }
              >
                <TextField
                  id="Notes"
                  label=""
                  multiline
                  rows={4}
                  value={milestone.Notes}
                  onChange={(event, newValue) => {
                    appDispatch(
                      milestoneReducer.actions.changeNotes(newValue || "")
                    );
                    setModalFormChanged(true);
                  }}
                  ariaLabel="Planning notes"
                  styles={textareaStylesProps}
                  errorMessage={
                    milestone.Notes.length > 500
                      ? "Notes exceeds 500 characters."
                      : ""
                  }
                  disabled={readOnly}
                  placeholder="Planning notes"
                />
              </Tooltip>
              <PrimaryButton
                className="my-4"
                disabled={
                  !milestone.__isFormValid ||
                  milestone.Notes.length > 500 ||
                  readOnly ||
                  showSpinner
                }
                onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                  event.preventDefault();
                  if (modalFormChanged) {
                    setShowSpinner(true);
                    appDispatch(
                      milestoneReducer.updateMilestoneNotes({
                        MilestoneId: milestone.MilestoneId,
                        Notes: milestone.Notes,
                        DisclosureMilestoneId:
                          milestone.DisclosureMilestoneId as number,
                        RoadmapMilestoneId:
                          milestone.RoadmapMilestoneId as number,
                      })
                    )
                      .then(unwrapResult)
                      .then(() => {
                        SuccessMessage.show(
                          "The Notes has been saved successfully."
                        );
                        setShowSpinner(false);
                        setModalFormChanged(false);
                      })
                      .catch(() => {
                        setShowSpinner(false);
                      });
                  }
                }}
              >
                Save
              </PrimaryButton>
              {showSpinner ? (
                <Spinner
                  size={SpinnerSize.large}
                  style={{
                    display: "inline",
                    marginLeft: "10px",
                    marginTop: "25px",
                    position: "absolute",
                  }}
                />
              ) : (
                <></>
              )}
            </div>

            <div className="col-xs-12 mt-4">
              <strong>Microsoft Learn TV</strong>
              <p>
                Highlight your release on Microsoft Learn TV in an episode of{" "}
                <a href="https://aka.ms/thelaunchspace" target="_blank">
                  The Launch Space
                </a>
                , a live broadcast for amplifying new Microsoft product and
                feature releases, assisting with calls-to-action, and
                providing feedback to product teams. Fill out{" "}
                <a href="https://aka.ms/thelaunchspaceproposal" target="_blank">
                  this form
                </a>{" "}
                to get started or{" "}
                <a href="mailto:thelaunchspace@microsoft.com" target="_blank">
                  email
                </a>{" "}
                with questions.
              </p>
            </div>
          </>
        ) : (
          <>
            <MilestoneForm
              setMilestoneFormSubmited={setMilestoneFormSubmited}
              milestoneFormSubmited={milestoneFormSubmited}
              milestoneFormChanged={milestoneFormChanged}
              setMilestoneFormChanged={setMilestoneFormChanged}
            />
            <div className="col-xs-12">
              <strong>Microsoft Learn TV</strong>
              <p>
                Highlight your release on Microsoft Learn TV in an episode of{" "}
                <a href="https://aka.ms/thelaunchspace" target="_blank">
                  The Launch Space
                </a>
                , a live broadcast for amplifying new  Microsoft  product and
                feature releases, assisting with calls-to-action, and
                providing feedback to product teams. Fill out{" "}
                <a href="https://aka.ms/thelaunchspaceproposal" target="_blank">
                  this form
                </a>{" "}
                to get started or{" "}
                <a href="mailto:thelaunchspace@microsoft.com" target="_blank">
                  email
                </a>{" "}
                with questions.
              </p>
            </div>
          </>
        )}
      </div>
    </ModalOverlay>
  );
};
