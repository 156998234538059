import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import * as securityMilestoneReducer from "../../slice/securityMilestone";
import * as securityAudienceReducer from "../../slice/securityAudience";
import * as securityProductServiceReducer from "../../slice/securityProductService";
import * as fieldBlockerReducer from "../../slice/fieldBlockReason";
import * as milestoneTierReducer from "../../slice/milestoneTier";
import * as securityPMMReducer from "../../slice/securityPMM";
import {
  DirectionalHint,
  Dropdown,
  TextField,
  TooltipHost,
} from "@fluentui/react";
import { RootState, useAppDispatch } from "../../store";
import { milestoneTier } from "../../slice/azureTimeline";
import { SecurityProductService } from "../../slice/securityProductService";
import * as CurrencyFormat from "react-currency-format";
import GraphUsersAutoCompleteWithTeamsIcon from "../Shared/FormInput/graphUsersAutoCompleteWithTeamsIcon";
import {
  dropdownStylesProps,
  OptionType,
  reactselectSingleStyleProps,
  textareaStylesProps,
} from "../Shared/styleProps";
import { getEmailPart, getNamePart } from "../../utils/string";
import { isVoid } from "../../utils/utils";
import Select, { ActionMeta, OnChangeValue } from "react-select";
const CurrencyFormatX: React.FC<any> = CurrencyFormat as any;

const ProductDetails = (props: {
  disabled: boolean;
  appearErrorInfo: boolean;
  setFormChanged: Function;
}) => {
  const appDispatch = useAppDispatch();
  const securityMilestoneInit = useSelector(
    (state: RootState) => state.securityMilestoneReducer
  );
  const milestone = securityMilestoneInit.SingleMilestone;
  const tiers = useSelector((state: RootState) => state.milestoneTierReducer);
  const tiersOptions = tiers.milestoneTiers.map((t: milestoneTier) => {
    return { key: t.TierId, text: "Tier " + t.Name };
  });

  const productServices = useSelector(
    (state: RootState) => state.securityProductServiceReducer
  );
  const fieldBlockerOption = useSelector(
    (state: RootState) => state.fieldBlockerReducer
  );
  let securityBlockerReason =
    securityMilestoneInit.SingleMilestone.BlockerReason;
  let securityFieldBlockerOptions = securityBlockerReason?.map((p) => {
    return { key: p.FieldBlockerId, text: p.FieldBlocker?.Reason };
  });
  let allFieldBlockerOptions = fieldBlockerOption.fieldBlockerReasons.map(
    (p) => {
      return { key: p.FieldBlockerId, text: p.Reason };
    }
  );
  const productServiceOptions = productServices.map(
    (p: SecurityProductService) => {
      return {
        key: p.SecurityProductServiceId,
        text: p.ProductServiceName ?? "",
        data: {
          ProductCategory: p.ProductCategory,
          ProductFamily: p.ProductFamily,
          SolutionPlay: p.SolutionPlay,
          MarketingTheme: p.MarketingTheme,
        },
      };
    }
  );

  const productServiceSelectOptions = productServices.map(
    (p: SecurityProductService) => {
      return {
        value: p.SecurityProductServiceId.toString(),
        label: p.ProductServiceName ?? "",
      };
    }
  );

  const securityAudiences = useSelector(
    (state: RootState) => state.securityAudienceReducer
  );
  const securityAudienceOptions = securityAudiences.map((a) => {
    return { key: a.SecurityAudienceId, text: a.Name ?? "" };
  });

  const securityPMMs = useSelector(
    (state: RootState) => state.securityPMMReducer
  );
  const considerSubmitted = (condition: boolean) => {
    //we want error message to show after user click save edits button
    return props.appearErrorInfo && condition;
  };
  useEffect(() => {
    if (securityPMMs.length === 0) {
      appDispatch(securityPMMReducer.getSecurityPMM());
    }
    if (securityAudiences.length === 0) {
      appDispatch(securityAudienceReducer.getSecurityAudience());
    }
    if (productServices.length === 0) {
      appDispatch(securityProductServiceReducer.getSecurityProductService());
    }
    if (tiers.milestoneTiers.length === 0) {
      appDispatch(milestoneTierReducer.getMilestoneTiers());
    }
    if (fieldBlockerOption.fieldBlockerReasons.length === 0) {
      appDispatch(fieldBlockerReducer.getFieldBlockers());
    }
  }, []);

  useEffect(() => {
    if (!isVoid(milestone.PMM)) {
      let displayName = getNamePart(milestone.PMM ?? "");
      let mail = getEmailPart(milestone.PMM ?? "");
      appDispatch(securityMilestoneReducer.getManager({ displayName, mail }));
    }
    if (
      securityPMMs &&
      securityPMMs.length > 0 &&
      securityPMMs.findIndex(
        (p) => p.PM === getNamePart(milestone.PMM ?? "")
      ) >= 0
    ) {
      var scigroup =
        securityPMMs.find((p) => p.PM === getNamePart(milestone.PMM ?? ""))
          ?.SCIGroup ?? "";
      appDispatch(securityMilestoneReducer.actions.changeSCIGroup(scigroup));
    } else {
      appDispatch(securityMilestoneReducer.actions.changeSCIGroup(""));
    }
  }, [milestone.PMM]);

  return (
    <div className="product-details">
      <div className="row">
        <div className="col-9">
          <TextField
            value={milestone.PublicTitle ?? undefined}
            onChange={(event, value) => {
              appDispatch(
                securityMilestoneReducer.actions.changePublicTitle(value)
              );
              props.setFormChanged(true);
            }}
            label="Public title *"
            styles={textareaStylesProps}
            disabled={
              props.disabled ||
              (milestone.ModernWorkSecurityMSETSFeaturesId !== null &&
                milestone.ModernWorkSecurityMSETSFeaturesId !== 0)
            }
            errorMessage={
              considerSubmitted(isVoid(milestone.PublicTitle?.trim()))
                ? "Public title is required"
                : (milestone.PublicTitle?.length ?? 0) > 400
                ? "Public title exceeds 400 characters"
                : undefined
            }
            id={"publicTitleField"}
          />
        </div>
        <div className="col-3" style={{ padding: "15px 12px 0 0" }}>
          {" "}
          <label className="modal-detail-label" style={{ marginTop: 0 }}>
            Tier *{" "}
            <a
              href="https://aka.ms/cmtieringframework"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="guidelines for tier"
            >
              (guidelines)
            </a>
          </label>
          <Dropdown
            selectedKey={milestone.TierId}
            options={tiersOptions}
            onChange={(event, option) => {
              if (option) {
                appDispatch(
                  securityMilestoneReducer.actions.changeTier(
                    option.key as number
                  )
                );
              }
              props.setFormChanged(true);
            }}
            styles={dropdownStylesProps}
            disabled={
              props.disabled ||
              (milestone.ModernWorkSecurityMSETSFeaturesId !== null &&
                milestone.ModernWorkSecurityMSETSFeaturesId !== 0)
            }
            errorMessage={
              considerSubmitted(milestone.TierId === 0)
                ? "Tier is required"
                : undefined
            }
            id="tierField"
          />
        </div>
      </div>
      <div className="row">
        <div className="col-3">
          <label className="modal-detail-label">Product/Service *</label>
          <Select
            aria-label="Product/Service *"
            isDisabled={
              props.disabled ||
              (milestone.ModernWorkSecurityMSETSFeaturesId !== null &&
                milestone.ModernWorkSecurityMSETSFeaturesId !== 0)
            }
            isClearable={true}
            isSearchable={true}
            options={productServiceSelectOptions}
            value={
              productServiceSelectOptions.filter((option: OptionType) => {
                return option.value === milestone.ProductServiceId?.toString();
              }) || []
            }
            onChange={(
              value: OnChangeValue<OptionType, false>,
              actionMeta: ActionMeta<OptionType>
            ) => {
              let productServiceId: number | null = null;
              switch (actionMeta.action) {
                case "clear":
                  productServiceId = null;
                  break;
                case "select-option":
                  if (value) {
                    productServiceId = parseInt(value.value);
                  }
                  break;
              }
              if (productServiceId !== null) {
                const selectOption = productServiceOptions.filter((option) => {
                  return option.key === productServiceId;
                })[0];

                appDispatch(
                  securityMilestoneReducer.actions.changeProductService({
                    id: productServiceId,
                    data: selectOption.data,
                  })
                );
              } else {
                appDispatch(
                  securityMilestoneReducer.actions.changeProductService({
                    id: 0,
                    data: {
                      ProductCategory: null,
                      ProductFamily: null,
                      SolutionPlay: null,
                      MarketingTheme: null,
                    },
                  })
                );
              }
              props.setFormChanged(true);
            }}
            styles={reactselectSingleStyleProps}
            placeholder="Select an option"
            id="productServiceField"
          />
          <span className="modal-detail-error">
            {considerSubmitted(
              !milestone.ProductServiceId || milestone.ProductServiceId === 0
            )
              ? "Product/Service is required"
              : ""}
          </span>
        </div>
        <div className="col-3">
          <GraphUsersAutoCompleteWithTeamsIcon
            label="PMM *"
            error={
              considerSubmitted(isVoid(milestone.PMM)) ? "PMM is required" : ""
            }
            value={milestone.PMM ?? undefined}
            onChangeHandler={(value) => {
              appDispatch(
                securityMilestoneReducer.actions.changePMM(value?.value)
              );
              props.setFormChanged(true);
            }}
            microsoftUser={true}
            id="securityProductServiceForm_PMM"
            disabled={
              props.disabled ||
              (milestone.ModernWorkSecurityMSETSFeaturesId !== null &&
                milestone.ModernWorkSecurityMSETSFeaturesId !== 0)
            }
          />
        </div>
        <div className="col-3">
          <GraphUsersAutoCompleteWithTeamsIcon
            label="PM"
            error={""}
            value={milestone.PM ?? undefined}
            onChangeHandler={(value) => {
              appDispatch(
                securityMilestoneReducer.actions.changePM(value?.value)
              );
              props.setFormChanged(true);
            }}
            microsoftUser={true}
            id="securityProductServiceForm_PM"
            disabled={props.disabled}
          />
        </div>
        <div className="col-3">
          <Dropdown
            selectedKeys={milestone.SecurityMilestoneAudiences?.filter(
              (a) => !a.IsDeleted
            ).map((a) => a.SecurityAudienceId)}
            options={securityAudienceOptions}
            onChange={(event, option) => {
              if (option) {
                appDispatch(
                  securityMilestoneReducer.actions.changeIsSecurityAudienceEdited(
                    true
                  )
                );
              }
              const orig = milestone.SecurityMilestoneAudiences.slice(0);
              if (option && option.selected) {
                const index = orig.findIndex(
                  (o) => o.SecurityAudienceId === option.key
                );
                if (index >= 0) {
                  orig[index] = {
                    ...orig[index],
                    IsAdded: true,
                    IsDeleted: false,
                  };
                } else {
                  orig.push({
                    SecurityAudienceId: option.key as number,
                    Name: option.text,
                    IsAdded: true,
                    IsDeleted: false,
                  });
                }
              } else if (option && !option.selected) {
                const index = orig.findIndex(
                  (o) => o.SecurityAudienceId === option.key
                );
                if (index >= 0) {
                  orig[index] = {
                    ...orig[index],
                    IsAdded: false,
                    IsDeleted: true,
                  };
                } else {
                  orig.push({
                    SecurityAudienceId: option.key as number,
                    Name: option.text,
                    IsAdded: false,
                    IsDeleted: true,
                  });
                }
              }
              appDispatch(
                securityMilestoneReducer.actions.changeAudiences(orig)
              );
              props.setFormChanged(true);
            }}
            multiSelect={true}
            label="Target audience *"
            styles={dropdownStylesProps}
            disabled={props.disabled}
            errorMessage={
              considerSubmitted(
                !milestone.SecurityMilestoneAudiences ||
                  milestone.SecurityMilestoneAudiences.filter((a) => {
                    return a.IsAdded || (!a.IsAdded && !a.IsDeleted);
                  }).length == 0
              )
                ? "Target audience is required"
                : undefined
            }
          />
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <TextField
            label="What's new or changing? *"
            multiline
            rows={4}
            value={milestone.NewOrChanging ?? undefined}
            errorMessage={
              considerSubmitted(isVoid(milestone.NewOrChanging?.trim()))
                ? "What's new or changing is required"
                : considerSubmitted(
                    (milestone.NewOrChanging?.trim().length ?? 0) > 2000
                  )
                ? "What's new or changing exceeds 2000 characters"
                : undefined
            }
            onChange={(event, newValue) => {
              appDispatch(
                securityMilestoneReducer.actions.changeNewOrChanging(
                  newValue || ""
                )
              );
              props.setFormChanged(true);
            }}
            styles={textareaStylesProps}
            ariaLabel="What's new or changing, required field"
            disabled={props.disabled}
            id="newOrChangingField"
          />
        </div>
        <div className="col-6">
          <TextField
            label="Customer benefits *"
            multiline
            rows={4}
            value={milestone.CustomerBenefits ?? undefined}
            errorMessage={
              considerSubmitted(isVoid(milestone.CustomerBenefits?.trim()))
                ? "Customer benefits is required"
                : considerSubmitted(
                    (milestone.CustomerBenefits?.trim().length ?? 0) > 2000
                  )
                ? "Customer benefits exceeds 2000 characters"
                : undefined
            }
            onChange={(event, newValue) => {
              appDispatch(
                securityMilestoneReducer.actions.changeCustomerBenefits(
                  newValue || ""
                )
              );
              props.setFormChanged(true);
            }}
            id="customerBenefitsField"
            styles={textareaStylesProps}
            ariaLabel="Customer benefits, required field"
            disabled={
              props.disabled ||
              (milestone.ModernWorkSecurityMSETSFeaturesId !== null &&
                milestone.ModernWorkSecurityMSETSFeaturesId !== 0)
            }
          />
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <TextField
            label="Announcement group (if applicable)"
            value={milestone.AnnouncementGroup ?? undefined}
            errorMessage={
              considerSubmitted(
                (milestone.AnnouncementGroup?.length ?? 0) > 400
              )
                ? "Annoucement group exceeds 400 characters"
                : undefined
            }
            onChange={(event, newValue) => {
              appDispatch(
                securityMilestoneReducer.actions.changeAnnouncementGroup(
                  newValue || ""
                )
              );
              props.setFormChanged(true);
            }}
            resizable={true}
            styles={textareaStylesProps}
            disabled={props.disabled}
          />
        </div>
        <div className="col-6">
          <div className="row" style={{ height: "80px" }}>
            <TooltipHost
              content="Has this feature been identified by MCAPS as blocking security purchases, renewals, or deployments?"
              directionalHint={DirectionalHint.bottomCenter}
              hostClassName="col-3"
            >
              <Dropdown
                selectedKey={Number(
                  securityMilestoneInit.SingleMilestone.HasBlocker
                )}
                options={[
                  { key: 1, text: "Yes", data: true },
                  { key: 0, text: "No", data: false },
                ]}
                label="Field blockers?"
                styles={dropdownStylesProps}
                onChange={(event, newValue) => {
                  if (newValue !== undefined) {
                    appDispatch(
                      securityMilestoneReducer.actions.changeHashBlockerFields(
                        newValue.data
                      )
                    );
                    if (newValue.data === false) {
                      appDispatch(
                        securityMilestoneReducer.actions.setFieldBlockerReason(
                          null
                        )
                      );
                      appDispatch(
                        securityMilestoneReducer.actions.editFieldBlockerAmount(
                          {
                            amount: null,
                          }
                        )
                      );
                    }
                    appDispatch(
                      securityMilestoneReducer.actions.changeHashBlockerFields(
                        newValue.data
                      )
                    );
                    props.setFormChanged(true);
                  }
                }}
              />
            </TooltipHost>
            <TooltipHost
              content="If MCAPS has identified this feature as a blocker, please select all elements that blocked: closing a deal; PUPM seat revenue, ACR revenue, or other."
              directionalHint={DirectionalHint.bottomCenter}
              hostClassName="col-5"
            >
              <Dropdown
                options={allFieldBlockerOptions}
                label={"Reasons *"}
                styles={{
                  ...dropdownStylesProps,
                  root: {
                    display: securityMilestoneInit.SingleMilestone.HasBlocker
                      ? "block"
                      : "none",
                  },
                }}
                selectedKeys={securityFieldBlockerOptions?.map((p) => p.key)}
                placeholder={"Select one or more reasons"}
                errorMessage={
                  considerSubmitted(milestone.BlockerReason?.length === 0)
                    ? "Reasons are required"
                    : undefined
                }
                onChange={(event, option) => {
                  if (option !== undefined) {
                    appDispatch(
                      securityMilestoneReducer.actions.setFieldBlockerReason({
                        fieldBlockerId: Number(option.key),
                        isAdd: option.selected ?? false,
                      })
                    );
                  }
                  props.setFormChanged(true);
                }}
                multiSelect={true}
                style={{
                  display: securityMilestoneInit.SingleMilestone.HasBlocker
                    ? "block"
                    : "none",
                }}
                id={"ReasonsDropdown"}
              />
            </TooltipHost>

            <TooltipHost
              content="If MCAPS has identified an amount of revenue blocked this FY, please enter here."
              directionalHint={DirectionalHint.bottomCenter}
              hostClassName="col-4"
            >
              <div
                style={{
                  display: securityMilestoneInit.SingleMilestone.HasBlocker
                    ? "block"
                    : "none",
                }}
              >
                <label className="currencyFormatLabel">
                  FY revenue at risk *
                </label>
                <CurrencyFormatX
                  value={
                    securityMilestoneInit.SingleMilestone.BlockerReasonAmount ??
                    ""
                  }
                  thousandSeparator={true}
                  className="currencyFormatInput col-12"
                  placeholder="e.g.,$10,000"
                  id={"riskField"}
                  onValueChange={(values: any) => {
                    if (
                      values !== null &&
                      values !== undefined &&
                      values.value !== ""
                    ) {
                      appDispatch(
                        securityMilestoneReducer.actions.editFieldBlockerAmount(
                          { amount: values.value }
                        )
                      );
                    } else {
                      appDispatch(
                        securityMilestoneReducer.actions.editFieldBlockerAmount(
                          { amount: null }
                        )
                      );
                    }
                    props.setFormChanged(true);
                  }}
                  prefix={"$"}
                />
                <div
                  role="alert"
                  className="errorMessage"
                  id="riskField-errorMessage"
                >
                  {" "}
                  {considerSubmitted(milestone.BlockerReasonAmount === null)
                    ? "Amount is required"
                    : ""}
                </div>
              </div>
            </TooltipHost>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductDetails;
