import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import savedDataFilterservice from "../services/savedDataFilters";
import { ErrorMessage } from "../components/Shared/messageBox";

interface savedDataFilterstate {
  savedDataFilter: savedDataFilter | null;
}

const initialState: savedDataFilterstate = {
  savedDataFilter: null,
};

export interface savedDataFilter {
  SavedDataFilterId?: number;
  Name: string;
  FilterData: string;
  FilterType: string;
}

export interface filter {
  filterName: string;
  filterType: string;
  filterValue: number | string | string[] | boolean | undefined;
}

export const calendarFilterList: filter[] = [
  { filterName: "SolutionArea", filterType: "multiselect", filterValue: [] },
  { filterName: "MultipleCSA", filterType: "multiselect", filterValue: [] },
  { filterName: "ProductFamily", filterType: "multiselect", filterValue: [] },
  { filterName: "MomentType", filterType: "multiselect", filterValue: [] },
  { filterName: "PRSupport", filterType: "multiselect", filterValue: [] },
];

export const milestoneFilterList: filter[] = [
  { filterName: "ConversationId", filterType: "multiselect", filterValue: [] },
  { filterName: "ProductName", filterType: "multiselect", filterValue: [] },
  { filterName: "ContactName", filterType: "text", filterValue: "" },
  {
    filterName: "MarketingLeadName",
    filterType: "text",
    filterValue: "",
  },
  { filterName: "CreatedBy", filterType: "multiselect", filterValue: [] },
  { filterName: "ModifiedBy", filterType: "multiselect", filterValue: [] },
  { filterName: "TierId", filterType: "multiselect", filterValue: [] },
  {
    filterName: "MomentId",
    filterType: "multiselect",
    filterValue: [],
  },
  { filterName: "Status", filterType: "multiselect", filterValue: [] },
  { filterName: "MilestoneTypeId", filterType: "multiselect", filterValue: [] },
  {
    filterName: "FilterMilestoneId",
    filterType: "number",
    filterValue: undefined,
  },
  //Don't implement now
  {
    filterName: "MilestoneTimelineType",
    filterType: "multiselect",
    filterValue: [],
  },
  {
    filterName: "MilestoneTimelineTypeName",
    filterType: "multiselect",
    filterValue: [],
  },
  {
    filterName: "OrgOwnerId",
    filterType: "multiselect",
    filterValue: [],
  },
  {
    filterName: "GroupId",
    filterType: "multiselect",
    filterValue: [],
  },
  {
    filterName: "ACOMCategoryId",
    filterType: "multiselect",
    filterValue: [],
  },
  {
    filterName: "ProductOrgOwnersWithEmailDisplayString",
    filterType: "text",
    filterValue: "",
  },
  {
    filterName: "MarketingOwnerName",
    filterType: "text",
    filterValue: "",
  },
  {
    filterName: "HasNoMilestones",
    filterType: "boolean",
    filterValue: undefined,
  },
  {
    filterName: "NeedOperationalChanges",
    filterType: "boolean",
    filterValue: undefined,
  },
  {
    filterName: "AlignedWithTheme",
    filterType: "boolean",
    filterValue: undefined,
  },
  {
    filterName: "AlignedWithGEP",
    filterType: "boolean",
    filterValue: undefined,
  },
  {
    filterName: "HasAzureUpdateContent",
    filterType: "boolean",
    filterValue: undefined,
  },
  {
    filterName: "HasFieldFacingContent",
    filterType: "boolean",
    filterValue: undefined,
  },
  {
    filterName: "HasSocialAmplificationContent",
    filterType: "boolean",
    filterValue: undefined,
  },
  {
    filterName: "IncludeBlogContent",
    filterType: "boolean",
    filterValue: undefined,
  },
  { filterName: "IsNotShare", filterType: "boolean", filterValue: undefined },
  {
    filterName: "IsNotShareInCalendar",
    filterType: "boolean",
    filterValue: undefined,
  },
];

export const featureFilterList: filter[] = [
  {
    filterName: "PMGContact",
    filterType: "text",
    filterValue: "",
  },
  {
    filterName: "PMContact",
    filterType: "text",
    filterValue: "",
  },
  { filterName: "Product", filterType: "multiselect", filterValue: [] },
  { filterName: "ProductAreas", filterType: "multiselect", filterValue: [] },
  {
    filterName: "RoadmapID",
    filterType: "text",
    filterValue: "",
  },
  {
    filterName: "SourceADOID",
    filterType: "text",
    filterValue: "",
  },
  { filterName: "CloudInstance", filterType: "text", filterValue: "" },
  {
    filterName: "PublicRoadmapStatus",
    filterType: "multiselect",
    filterValue: [],
  },

  { filterName: "TierId", filterType: "multiselect", filterValue: [] },
  {
    filterName: "MomentId",
    filterType: "multiselect",
    filterValue: [],
  },
  { filterName: "TriageTagId", filterType: "multiselect", filterValue: [] },
  {
    filterName: "Planning",
    filterType: "multiselect",
    filterValue: [],
  },
  {
    filterName: "OnlyShowWithNewToRoadmap",
    filterType: "text",
    filterValue: "",
  },
  {
    filterName: "SortByDate",
    filterType: "text",
    filterValue: "",
  },
  {
    filterName: "Action",
    filterType: "text",
    filterValue: "",
  },
];

export const securityMilestoneFilterList: filter[] = [
  { filterName: "ProductServices", filterType: "multiselect", filterValue: [] },
  { filterName: "ProductCategory", filterType: "multiselect", filterValue: [] },
  { filterName: "SolutionPlay", filterType: "multiselect", filterValue: [] },
  { filterName: "ProductFamily", filterType: "multiselect", filterValue: [] },
  {
    filterName: "PMM",
    filterType: "text",
    filterValue: "",
  },
  {
    filterName: "SecurityAudience",
    filterType: "multiselect",
    filterValue: [],
  },
  { filterName: "TierId", filterType: "multiselect", filterValue: [] },
  {
    filterName: "SecurityMilestoneTypeId",
    filterType: "multiselect",
    filterValue: [],
  },
  {
    filterName: "MomentId",
    filterType: "multiselect",
    filterValue: [],
  },
  { filterName: "FiscalYear", filterType: "multiselect", filterValue: [] },
  { filterName: "FiscalQuarter", filterType: "multiselect", filterValue: [] },
  { filterName: "FiscalMonth", filterType: "multiselect", filterValue: [] },
];

export const getSavedDataFilters = createAsyncThunk(
  "getSavedDataFilters",
  async (filterType: string) =>
    await savedDataFilterservice.getSavedDataFilters(filterType)
);

export const saveSavedDataFilter = createAsyncThunk(
  "saveSavedDataFilter",
  async (savedDataFilter: savedDataFilter) =>
    await savedDataFilterservice.saveSavedDataFilter(savedDataFilter)
);

export const deleteSavedDataFilter = createAsyncThunk(
  "deleteSavedDataFilter",
  async (savedDataFilterId: number) =>
    await savedDataFilterservice.deleteSavedDataFilter(savedDataFilterId)
);

const savedDataFiltersslice = createSlice({
  name: "savedDataFilters",
  initialState: initialState,
  reducers: {},
  extraReducers: {
    [saveSavedDataFilter.fulfilled.type]: (
      state: savedDataFilterstate,
      action: PayloadAction<savedDataFilter>
    ) => {
      state.savedDataFilter = action.payload;
    },
    [saveSavedDataFilter.rejected.type]: (state, { error }) => {
      ErrorMessage.show(
        "There was an error saving the data filter. Please refresh the page and try again. If the issue persists please contact the tool administrator."
      );
    },
  },
});

export const { actions, reducer } = savedDataFiltersslice;
