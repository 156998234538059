import { IDropdownStyles, ITextFieldStyles } from "@fluentui/react";
import { CSSProperties } from "react";
import { GroupBase, StylesConfig } from "react-select";
import {
  OptionType,
  dropdownStylesProps,
  reactselectStyleProps,
  textfieldStylesProps,
} from "./styleProps";

export const dropdownStyles: Partial<IDropdownStyles> = {
  ...dropdownStylesProps,
  dropdown: {
    width: 248,
  },
  subComponentStyles: {
    label: {
      root: {
        font: "normal normal normal 14px/20px Segoe UI",
        textAlign: "left",
        letterSpacing: "0px",
        color: "#333333",
        opacity: 1,
        marginTop: "5px",
        marginBottom: "5px",
        padding: 0,
      },
    },
    panel: {
      root: {},
    },
    multiSelectItem: {
      root: {},
    },
  },
};

export const labelStyles: CSSProperties = {
  font: "normal normal normal 14px/20px Segoe UI",
  marginTop: 5,
  marginBottom: 5,
};

export const inputFieldStyles: ITextFieldStyles = {
  ...textfieldStylesProps,
  wrapper: {
    selectors: {
      [".ms-Label"]: {
        font: "normal normal normal 14px/20px Segoe UI",
        marginTop: 5,
        marginBottom: 5,
        padding: 0,
      },
    },
  },
  root: { width: 248 },
};

export const graphUsersAutoCompleteStyles: StylesConfig<
  OptionType,
  boolean,
  GroupBase<OptionType>
> = {
  ...reactselectStyleProps,
  singleValue: (provided: any, state: any) => ({
    ...provided,
    maxWidth: 172,
  }),
  placeholder: (base, props) => {
    base.color = "#757575";
    return { ...base };
  },
};
export const graphUsersAutoCompleteAutoFitContentStyles: StylesConfig<
  OptionType,
  boolean,
  GroupBase<OptionType>
> = {
  ...reactselectStyleProps,
  valueContainer: (provided: any, state: any) => ({
    ...provided,
    height: "fit-content",
  }),
  singleValue: (provided: any, state: any) => ({
    ...provided,
    maxWidth: 172,
  }),
  placeholder: (base, props) => {
    base.color = "#757575";
    return { ...base };
  },
};

export const filterStyles: StylesConfig<
  OptionType,
  boolean,
  GroupBase<OptionType>
> = {
  ...reactselectStyleProps,
  container: (provided: any, state: any) => ({
    ...provided,
    maxWidth: "248px",
  }),
  valueContainer: (provided: any, state: any) => ({
    ...provided,
    lineHeight: "auto",
    height: "auto",
  }),
};
