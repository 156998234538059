import { TimelineType } from "../components/Shared/constants";
import httpClient from "./base";

const baseURL = "/SecurityMilestoneDateHistory";

const getSecurityMilestoneDateHistory = (id: number) => {
  let url = `${baseURL}?id=${id}&timelineType=${TimelineType.SCI}`;
  return httpClient.get(url);
};

const deleteSecurityMilestoneDateHistory = (
  securityMilestoneDateHistoryIds: number[]
) => {
  let url = `${baseURL}?securityMilestoneDateHistoryIds=${encodeURIComponent(
    securityMilestoneDateHistoryIds.join(",")
  )}`;
  return httpClient.del(url);
};

const securityMilestoneDateHistoryService = {
  getSecurityMilestoneDateHistory,
  deleteSecurityMilestoneDateHistory,
};

export default securityMilestoneDateHistoryService;
