import React, { useEffect, useContext, useMemo } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import "../../Shared/css/timelineGrid.scss";
import "./index.scss";
import { RootState } from "../../../store";
import { ContentShowMode } from "../../Shared/constants";
import { ModernWorkFeatureModalContext } from "../index";
import { convertMonthStringToDate } from "../../../utils/date";
import { speakText } from "../../../utils/utils";
import { TooltipHost } from "@fluentui/react";
import { useId } from "@fluentui/react-hooks";
const MovePrevious = () => {
  const modernWorkFeatureModalContext = useContext(
    ModernWorkFeatureModalContext
  );
  const showMonth = useMemo(() => {
    return modernWorkFeatureModalContext.contentShowMode ===
      ContentShowMode.Grid
      ? 3
      : 1;
  }, [modernWorkFeatureModalContext.contentShowMode]);
  const dataSource = useSelector(
    (state: RootState) => state.modernWorkTimelineReducer.dataSet
  );
  const filterDataSource = useSelector(
    (state: RootState) => state.modernWorkTimelineReducer.filterDataSet
  );

  const [canClickBtn, setCanClickBtn] = React.useState(false);
  const id = useId("tooltip");
  useEffect(() => {
    if (dataSource.length > showMonth) {
      setCanClickBtn(true);
      const currentFirstItem = filterDataSource[0];
      const index = dataSource.findIndex(
        (d) => d.month === currentFirstItem?.month
      );
      if (index === 0) {
        setCanClickBtn(false);
      }
    }

    if (filterDataSource.length < showMonth) {
      setCanClickBtn(false);
    }
  }, [dataSource, filterDataSource, showMonth]);

  const handleClick = () => {
    if (dataSource.length > showMonth) {
      const currentFirstItem = filterDataSource[0];
      const index = dataSource.findIndex(
        (d) => d.month === currentFirstItem?.month
      );
      if (index !== 0) {
        modernWorkFeatureModalContext.setStartDate(
          convertMonthStringToDate(dataSource[index - 1].month)
        );
        speakText("move to previous month " + dataSource[index - 1].month);
      }
    }
  };

  return (
    <div
      className={`grid-item item-arrow-left ${
        canClickBtn ? "click-arrow " : ""
      }`}
      style={{ margin: "auto 0" }}
    >
      <TooltipHost
        content="move to previous month"
        id={id}
        aria-role="tooltip"
        role="tooltip"
      >
        <div
          tabIndex={0}
          role="button"
          aria-label={"move to previous month"}
          onClick={handleClick}
          onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>) => {
            if (event.keyCode === 13) {
              handleClick();
            }
          }}
        >
          <i
            className="fa fa-angle-left fa-3x"
            aria-hidden="true"
            style={{ fontSize: "20px" }}
            alt-text="move to previous month"
          ></i>
        </div>
      </TooltipHost>
    </div>
  );
};

export default MovePrevious;
