import httpClient from "./base";

const getCustomEventDates = () => {
  let url = "CustomEventDate/Events";
  return httpClient.get(url);
};

const getCustomEventDateById = (id: number) => {
  let url = "CustomEventDate?id=" + id;
  return httpClient.get(url);
};

const getCustomEventDateByMilestoneId = (milestoneId: number) => {
  let url = "CustomEventDate/Milestone?milestoneId=" + milestoneId;
  return httpClient.get(url);
};

const getCustomEventDateForCalendar = () => {
  let url = "CustomEventDate/Calendar";
  return httpClient.get(url);
};

const customEventDateService = {
  getCustomEventDates,
  getCustomEventDateById,
  getCustomEventDateByMilestoneId,
  getCustomEventDateForCalendar,
};

export default customEventDateService;
