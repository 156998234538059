import React, { useState, useEffect } from "react";
import { FocusTrapZone, MessageBar, MessageBarType } from "@fluentui/react";
import ReactDOM from "react-dom";

export const SuccessMessage = (props: {
  message: string;
  width?: number | 500;
  marginLeft?: number | -250;
}) => {
  const [successMessage, setSuccessMessage] = useState(props.message);

  useEffect(() => {
    document.getElementById("successMessage")?.focus();
    setTimeout(() => {
      setSuccessMessage("");
    }, 2000);
  }, []);

  return successMessage !== "" ? (
    <div
      style={{
        left: "50%",
        top: "50%",
        position: "fixed",
        marginLeft: props.marginLeft ?? -250,
        width: props.width ?? 500,
        zIndex: Number.MAX_SAFE_INTEGER,
      }}
      tabIndex={0}
      id={"successMessage"}
    >
      <FocusTrapZone disabled={false}>
        <MessageBar
          onDismiss={() => {
            setSuccessMessage("");
          }}
          dismissButtonAriaLabel="Close"
          messageBarType={MessageBarType.success}
          isMultiline
          tabIndex={0}
        >
          {successMessage}
        </MessageBar>
      </FocusTrapZone>
    </div>
  ) : null;
};

SuccessMessage.show = (
  message: string,
  width?: number | 500,
  marginLeft?: number | -250
) => {
  let props = { message: message, width: width, marginLeft: marginLeft };
  let div = document.createElement("div");
  document.body.appendChild(div);
  ReactDOM.render(React.createElement(SuccessMessage, props), div);
  return {
    destroy() {
      ReactDOM.unmountComponentAtNode(div);
      document.body.removeChild(div);
    },
  };
};

export const ErrorMessage = (props: {
  message: string;
  width?: number | 500;
  marginLeft?: number | -250;
  action?: () => void;
}) => {
  const [errorMessage, setErrorMessage] = useState(props.message);

  useEffect(() => {
    if (props.action === undefined) {
      setTimeout(() => {
        setErrorMessage("");
      }, 10000);
    }
  }, []);

  return errorMessage !== "" ? (
    <div
      style={{
        left: "50%",
        top: "50%",
        position: "fixed",
        marginLeft: props.marginLeft ?? -250,
        width: props.width ?? 500,
        zIndex: Number.MAX_SAFE_INTEGER,
      }}
    >
      <FocusTrapZone disabled={false}>
        <MessageBar
          onDismiss={() => {
            setErrorMessage("");
            if (props.action !== undefined) {
              props.action();
            }
          }}
          dismissButtonAriaLabel="Close"
          messageBarType={MessageBarType.error}
          isMultiline
        >
          <div dangerouslySetInnerHTML={{ __html: errorMessage }}></div>
        </MessageBar>
      </FocusTrapZone>
    </div>
  ) : null;
};

ErrorMessage.show = (message: string, action?: () => void) => {
  let props = { message: message, action: action };
  let div = document.createElement("div");
  document.body.appendChild(div);
  ReactDOM.render(React.createElement(ErrorMessage, props), div);
  return {
    destroy() {
      ReactDOM.unmountComponentAtNode(div);
      document.body.removeChild(div);
    },
  };
};
