import React, { useContext } from "react";
import moment from "moment";
import {
  ContextualMenu,
  DirectionalHint,
  IContextualMenuItem,
} from "@fluentui/react/lib/ContextualMenu";
import { TooltipHost, ITooltipHostStyles } from "@fluentui/react/lib/Tooltip";
import { useId } from "@fluentui/react-hooks";
import "../../../Shared/css/timelineGrid.scss";
import "../index.scss";
import { ModalType, _AUTHORIZATIONTYPE } from "../../../Shared/constants";
import { azureTimelineMilestone as milestoneProps } from "../../../../slice/azureTimeline";
import { MilestoneUtil } from "../../../Milestone/milestoneCommon";
import { MilestoneStatusIcon } from "../../../AzureTimeline/NavBar";
import { MilestoneModalContext } from "../..";
import { AppContext } from "../../../../App";
import { truncateText } from "../../../../utils/utils";

const calloutProps = { gapSpace: 0 };
const hostStyles: Partial<ITooltipHostStyles> = {
  root: { display: "inline-block" },
};

const MilestoneCard = (props: { milestone: milestoneProps }) => {
  const appContext = useContext(AppContext);
  const milestoneModalContext = useContext(MilestoneModalContext);
  const menuItems: IContextualMenuItem[] = [
    {
      key: "edit",
      text: "Edit",
      onClick: () => {
        milestoneModalContext.setMilestoneModalStateHandler({
          milestoneId: props.milestone.MilestoneId,
          modalType: ModalType.Edit,
        });
        milestoneModalContext.modalRef?.current?.click();
      },
    },
    {
      key: "copy",
      text: "Copy",
      onClick: () => {
        milestoneModalContext.setMilestoneModalStateHandler({
          milestoneId: props.milestone.MilestoneId,
          modalType: ModalType.Copy,
        });
        milestoneModalContext.modalRef?.current?.click();
      },
    },
    {
      key: "delete",
      text: "Delete",
      onClick: () => {
        milestoneModalContext.setMilestoneModalStateHandler({
          milestoneId: props.milestone.MilestoneId,
          disclosureStartDate: props.milestone.DisclosureStartDate,
          modalType: ModalType.Delete,
        });
        milestoneModalContext.deleteDialogRef?.current?.click();
      },
    },
  ];

  const linkRef = React.useRef(null);
  const [showContextualMenu, setShowContextualMenu] = React.useState(false);
  const onShowContextualMenu = React.useCallback(
    (ev: React.MouseEvent<HTMLElement>) => {
      ev.stopPropagation();
      ev.preventDefault(); // don't navigate
      setShowContextualMenu(true);
    },
    []
  );
  const onHideContextualMenu = React.useCallback(
    () => setShowContextualMenu(false),
    []
  );

  const tooltipId = useId("milestoneCardTooltip");

  return (
    <TooltipHost
      content={props.milestone.MilestoneTypeName}
      id={tooltipId}
      calloutProps={calloutProps}
      styles={hostStyles}
      directionalHint={DirectionalHint.bottomAutoEdge}
    >
      {Math.abs(
        moment(new Date()).diff(moment(props.milestone.CreatedDate)) /
          1000 /
          3600 /
          24
      ) < 1 ? (
        <i
          className={"ms-Icon ms-Icon--Glimmer"}
          tabIndex={0}
          title="This is a new item"
          aria-hidden="true"
        />
      ) : (
        <div className="spacer" />
      )}
      <div
        aria-describedby={tooltipId}
        className="grid-content-card-widget"
        key={props.milestone.MilestoneId}
        onClick={(e) => {
          if (
            appContext.accessType === _AUTHORIZATIONTYPE.admin ||
            appContext.accessType === _AUTHORIZATIONTYPE.dri ||
            appContext.accessType === _AUTHORIZATIONTYPE.editable
          ) {
            milestoneModalContext.setMilestoneModalStateHandler({
              milestoneId: props.milestone.MilestoneId,
              disclosureStartDate: props.milestone.DisclosureStartDate,
              modalType: ModalType.Edit,
            });
          } else {
            milestoneModalContext.setMilestoneModalStateHandler({
              milestoneId: props.milestone.MilestoneId,
              modalType: ModalType.ReadOnly,
            });
          }
          milestoneModalContext.modalRef?.current?.click();
        }}
        onKeyDown={(e) => {
          if (e.keyCode === 13) {
            e.currentTarget.click();
          }
        }}
      >
        <div
          className="content-card"
          aria-label={
            "Milestone name:" +
            props.milestone.MilestoneName +
            " Milestone type:" +
            props.milestone.MilestoneTypeName
          }
          tabIndex={0}
        >
          <div title={props.milestone.MilestoneName}>
            <strong>
              {truncateText(props.milestone.MilestoneName, 49)}
              {` `}(
              {props.milestone.Tier === "1"
                ? "Tier 1"
                : props.milestone.Tier === "2"
                ? "Tier 2"
                : "Tier 3"}
              )
            </strong>
          </div>
          <div title={props.milestone.ContactName}>
            PMM:{` `}
            {truncateText(props.milestone.ContactName, 28)}
          </div>
          <div
            title={
              props.milestone.RoadmapStartDate === undefined
                ? ""
                : moment(new Date(props.milestone.RoadmapStartDate))
                    .utc()
                    .format("MM/DD/YYYY")
            }
          >
            Public:{` `}
            {props.milestone.RoadmapStartDate === undefined
              ? ""
              : moment(new Date(props.milestone.RoadmapStartDate))
                  .utc()
                  .format("MM/DD/YYYY")}
          </div>
          <div
            title={
              props.milestone.DisclosureStartDate === undefined
                ? ""
                : moment(new Date(props.milestone.DisclosureStartDate))
                    .utc()
                    .format("MM/DD/YYYY")
            }
          >
            Field:{` `}
            {props.milestone.DisclosureStartDate === undefined
              ? ""
              : moment(new Date(props.milestone.DisclosureStartDate))
                  .utc()
                  .format("MM/DD/YYYY")}
          </div>
          <div className="edit-icon d-flex justify-content-between">
            <span className="d-flex align-items-end">
              {MilestoneStatusIcon[Number(props.milestone.Status)]}
            </span>
            <span
              ref={linkRef}
              onClick={onShowContextualMenu}
              onKeyDown={(e) => {
                if (e.keyCode === 13) {
                  e.currentTarget.click();
                  e.stopPropagation();
                }
              }}
              aria-label="More options"
              role="button"
              tabIndex={0}
              className={
                appContext.accessType === _AUTHORIZATIONTYPE.admin ||
                appContext.accessType === _AUTHORIZATIONTYPE.dri ||
                appContext.accessType === _AUTHORIZATIONTYPE.editable
                  ? "d-flex align-items-end"
                  : "d-none"
              }
            >
              <i
                data-icon-name="Edit"
                aria-hidden="true"
                className="ms-Icon root-48"
              >
                
              </i>
            </span>
          </div>
        </div>
        <div
          className={`content-card-type ${MilestoneUtil.Colors.milestone(
            props.milestone.MilestoneTypeId
          )}`}
        ></div>
        {showContextualMenu ? (
          <ContextualMenu
            items={menuItems}
            hidden={false}
            target={linkRef}
            onItemClick={onHideContextualMenu}
            onDismiss={onHideContextualMenu}
          />
        ) : null}
      </div>
    </TooltipHost>
  );
};

export default MilestoneCard;
