import { useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import "./index.scss";
import { CalendarContext, CalendarData } from "./context";
import { RootState, useAppDispatch } from "../../store";
import { useUrlQueryParam } from "../../utils/url";
import { getFirstDayInTheMonth, getLastDayInTheMonth } from "../../utils/date";
import Header from "./Header";
import NavBar from "./NavBar";
import { getModernWorkSecurityEventCalendarMomentList } from "../../slice/modernWorkSecurityEventCalendarMoment";
import Filter from "./Filter";
import CMMCCalendar from "./Calendar";
import EventList from "./EventList";
import { CalendarEvent } from "./Calendar/calendarEvent";
import { createCalendarEvent } from "./utilities";
import { ConstantItems } from "./Filter/common";
import { modernWorkSecurityEventCalendarMoment } from "../../slice/modernWorkSecurityEventCalendarMoment";
import { calendarFilterList, filter } from "../../slice/savedDataFilters";
import { isVoid } from "../../utils/utils";
import useDocumentTitle from "../../utils/useDocumentTitle";
import MomentDetail from "./Calendar/MomentDetails";
import CalendarExcelDownload from "./Calendar/CalendarExcelDownload";

const Calendar = () => {
  const appDispatch = useAppDispatch();

  const startDate = getFirstDayInTheMonth(new Date().toString());
  const endDate = getLastDayInTheMonth(new Date().toString());
  const [currentDates, changeCurrentDates] = useState({
    currentStartDate: startDate,
    currentEndDate: endDate,
  });

  const [param, setParam] = useUrlQueryParam(
    calendarFilterList.map((f: filter) => {
      return f.filterName;
    })
  );
  const refresh = () => {
    appDispatch(
      getModernWorkSecurityEventCalendarMomentList({
        startDate: moment(currentDates.currentStartDate)
          .add(-7, "days")
          .format("MM/DD/YYYY"),
        endDate: moment(currentDates.currentEndDate)
          .add(7, "days")
          .format("MM/DD/YYYY"),
      })
    );
  };
  useEffect(() => {
    refresh();
  }, [appDispatch, currentDates]);

  const { modernWorkSecurityEventCalendarMomentList } = useSelector(
    (state: RootState) => state.modernWorkSecurityEventCalendarMomentReducer
  );

  const filterEvents: modernWorkSecurityEventCalendarMoment[] = useMemo(() => {
    let items: modernWorkSecurityEventCalendarMoment[] = [];
    modernWorkSecurityEventCalendarMomentList.forEach((moment) => {
      if (!isVoid(param.SolutionArea)) {
        if (
          decodeURIComponent(param.SolutionArea)
            .split(";|;")
            .filter(
              (item) =>
                item !== "" && moment.CategoryName.split(";").includes(item)
            ).length === 0
        ) {
          return true;
        }
      }
      if (!isVoid(param.MultipleCSA)) {
        if (
          decodeURIComponent(param.MultipleCSA)
            .split(";|;")
            .filter(
              (item) =>
                item !== "" && item.toLowerCase() === "show multi-csa moments"
            ).length !== 0
        ) {
          if (
            moment.CategoryName.split(";").filter((item) => item !== "")
              .length < 2
          ) {
            return true;
          }
        }
      }
      if (!isVoid(param.ProductFamily)) {
        if (
          decodeURIComponent(param.ProductFamily)
            .split(";|;")
            .filter(
              (item) =>
                item !== "" && moment.ProductFamily.split(";").includes(item)
            ).length === 0
        ) {
          return true;
        }
      }
      if (!isVoid(param.MomentType)) {
        if (
          decodeURIComponent(param.MomentType)
            .split(";|;")
            .filter(
              (item) =>
                item !== "" && moment.MomentType.split(";").includes(item)
            ).length === 0
        ) {
          return true;
        }
      }
      items.push(moment);
    });
    return items;
  }, [modernWorkSecurityEventCalendarMomentList, param]);

  const events: CalendarEvent[] = useMemo(() => {
    let items: CalendarEvent[] = [];
    filterEvents.forEach((momentItems) => {
      items.push(
        createCalendarEvent(
          moment(new Date(momentItems.StartDate)).utc().format("MM/DD/YYYY"),
          moment(new Date(momentItems.EndDate)).utc().format("MM/DD/YYYY"),
          momentItems.Id,
          momentItems.Title,
          ConstantItems.solutionAreaColor(momentItems.CategoryName),
          ConstantItems.solutionAreaTextColor(momentItems.CategoryName),
          momentItems.BIC_DateSelection
        )
      );
    });
    return items;
  }, [filterEvents]);
  const ref = useRef<HTMLButtonElement>(null);
  const calendarData: CalendarData = {
    currentDates: currentDates,
    changeCurrentDates: changeCurrentDates,
    getModernWorkSecurityEventCalendarMomentList: refresh,
    registeredEvents: events,
    currentSelectedEventId: null,
    isOpen: false,
    OpenDetail: () => {},
    modalRef: ref,
  };

  useDocumentTitle("Cloud Marketing moments calendar");

  return (
    <CalendarContext.Provider value={calendarData}>
      <Header />
      <Filter />
      <NavBar />
      <section className="calendar-panel">
        <section className="calendar-left">
          <CMMCCalendar />
        </section>
        <section className="calendar-right">
          <EventList />
        </section>
      </section>
      <MomentDetail />
      <CalendarExcelDownload />
    </CalendarContext.Provider>
  );
};

export default Calendar;
