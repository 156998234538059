import { useMemo } from "react";
import { URLSearchParamsInit, useSearchParams } from "react-router-dom";
import { cleanObject } from "./utils";

export const useUrlQueryParam = <K extends string>(keys: K[]) => {
  const [searchParams, setSearchParam] = useSearchParams();
  return [
    useMemo(
      () =>
        keys.reduce((prev, key) => {
          return { ...prev, [key]: searchParams.get(key) || "" };
        }, {} as { [key in K]: string }),
      [searchParams, keys]
    ),
    (params: Partial<{ [key in K]: unknown }>) => {
      const result = cleanObject({
        ...Object.fromEntries(searchParams),
        ...params,
      }) as URLSearchParamsInit;
      return setSearchParam(result);
    },
  ] as const;
};

export const readUrlHash = () => {
  let hashDictionary: { [key: string]: string } = {};
  window.location.hash
    .split("#")
    .filter((h) => {
      return h !== "";
    })
    .forEach((hp) => {
      let [key, value] = hp.split("=");
      hashDictionary[key] = value;
    });
  return hashDictionary;
};

export const concatUrlHashKeyValue = (key: string, value: string) => {
  window.location.hash += "#" + key + "=" + value;
};

export const renewUrlHashKeyValue = (key: string, value: string) => {
  window.location.hash = "#" + key + "=" + value;
};

export const clearUrlHashKeyValue = () => {
  window.history.pushState(
    null,
    "",
    window.location.pathname + window.location.search
  );
};
