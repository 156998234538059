import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { _LOADINGSTATE } from "../components/Shared/constants";
import productService from "../services/product";
import { RootState } from "../store";
import { convertDateTimeToFormatedDateString } from "../utils/date";
import { ErrorMessage } from "../components/Shared/messageBox";

export interface Product {
  ProductId: number;
  ConversationIds: number[];
  ConversationsDisplayString: string;
  AcomcategoryIds: number[];
  ProductACOMCategoriesDisplayString: string;
  ProductName: string;
  SelectedAudiences: number[];
  ProductState: number;
  ProductStateName: string;
  ProductCreatedDate: string;
  CreatedDate: string;
  CreatedBy: string;
  ModifiedDate: string;
  ModifiedBy: string;
  Description: string;
  __isFormValid: boolean;
  __isLoading: string;
}

const initialProductState: Product = {
  ProductId: 0,
  ConversationIds: [],
  ConversationsDisplayString: "",
  AcomcategoryIds: [],
  ProductACOMCategoriesDisplayString: "",
  ProductName: "",
  SelectedAudiences: [],
  ProductState: 1,
  ProductStateName: "",
  ProductCreatedDate: "",
  CreatedDate: new Date(
    convertDateTimeToFormatedDateString(new Date())
  ).toISOString(),
  CreatedBy: "",
  ModifiedDate: "",
  ModifiedBy: "",
  Description: "",
  __isFormValid: false,
  __isLoading: _LOADINGSTATE.pending,
};

export interface ProductState {
  singleProduct: Product;
  isProductIdAndNamesLoading: string;
  isProductsWithoutMilestonesLoading: string;
  productIdAndNames: Product[];
  productsInLastSixMonths: Product[];
}

const initialState: ProductState = {
  singleProduct: initialProductState,
  isProductIdAndNamesLoading: _LOADINGSTATE.pending,
  isProductsWithoutMilestonesLoading: _LOADINGSTATE.pending,
  productIdAndNames: [],
  productsInLastSixMonths: [],
};

export const getProduct = createAsyncThunk(
  "getProduct",
  async (id: number, { dispatch, getState }) => {
    let product = (await productService.getProduct(id)) as Product;
    return product;
  }
);

export const getProductIdAndName = createAsyncThunk(
  "getProductIdAndName",
  async () => await productService.getProductIdAndName()
);

export const getProductsWithoutMilestones = createAsyncThunk(
  "getProductsWithoutMilestones",
  async () => await productService.getProductsWithoutMilestones()
);

export const postProduct = createAsyncThunk(
  "postProduct",
  async (params: {}, { dispatch, getState }) => {
    const state: any = (getState() as RootState).productReducer;
    const payload = {
      ProductName: state.singleProduct.ProductName,
      Categories: state.singleProduct.AcomcategoryIds,
      SelectedAudiences: state.singleProduct.SelectedAudiences,
      ProductCreatedDate: state.singleProduct.CreatedDate,
      ProductDescription: state.singleProduct.Description,
    };
    const exist = await productService.getIsExistProduct({
      productName: state.singleProduct.ProductName,
    });
    if (!Boolean(exist)) {
      await productService.postProduct(payload);
    }
  }
);

export const getIsExistProduct = createAsyncThunk(
  "getIsExistProduct",
  async (productName: string) => {
    return await productService.getIsExistProduct({
      productName: productName,
    });
  }
);

const productSlice = createSlice({
  name: "product",
  initialState: initialState,
  reducers: {
    changeConversationIds: (
      state: ProductState,
      action: { type: string; payload: number[] }
    ) => {
      state.singleProduct.ConversationIds = action.payload;
      state.singleProduct.AcomcategoryIds = [];
    },
    changeACOMCategoryIds: (
      state: ProductState,
      action: { type: string; payload: number[] }
    ) => {
      state.singleProduct.AcomcategoryIds = action.payload;
    },
    changeProductName: (
      state: ProductState,
      action: { type: string; payload: string }
    ) => {
      state.singleProduct.ProductName = action.payload;
    },
    changeSelectedAudiences: (
      state: ProductState,
      action: { type: string; payload: number[] }
    ) => {
      state.singleProduct.SelectedAudiences = action.payload;
    },
    changeCreatedDate: (
      state: ProductState,
      action: { type: string; payload: string }
    ) => {
      state.singleProduct.CreatedDate = action.payload;
    },
    changeDescriptions: (
      state: ProductState,
      action: { type: string; payload: string }
    ) => {
      state.singleProduct.Description = action.payload;
    },
    changeIsFormValid: (
      state: ProductState,
      action: { type: string; payload: boolean }
    ) => {
      state.singleProduct.__isFormValid = action.payload;
    },
    reset: (state: ProductState, action: { type: string; payload: any }) => {
      state.singleProduct.ConversationIds = [];
      state.singleProduct.AcomcategoryIds = [];
      state.singleProduct.ProductName = "";
      state.singleProduct.SelectedAudiences = [];
      state.singleProduct.CreatedDate = new Date(
        convertDateTimeToFormatedDateString(new Date())
      ).toISOString();
      state.singleProduct.Description = "";
      state.singleProduct.__isFormValid = false;
      state.singleProduct.__isLoading = _LOADINGSTATE.fullfilled;
    },
  },
  extraReducers: {
    [getProduct.fulfilled.type]: (
      state: ProductState,
      action: PayloadAction<Product | string>
    ) => {
      if (typeof action.payload === "string") {
        state.singleProduct = initialProductState;
      } else {
        state.singleProduct = action.payload;
      }
      state.singleProduct.__isLoading = _LOADINGSTATE.fullfilled;
    },
    [getProduct.pending.type]: (state: ProductState) => {
      state.singleProduct.__isLoading = _LOADINGSTATE.pending;
    },
    [getProduct.rejected.type]: (state, { error }) => {
      state.singleProduct.__isLoading = _LOADINGSTATE.rejected;
      ErrorMessage.show(
        "There was an error getting product. Please refresh the page and try again. If the issue persists please contact the tool administrator."
      );
    },
    [getProductIdAndName.fulfilled.type]: (
      state: ProductState,
      action: PayloadAction<Product[] | string>
    ) => {
      if (typeof action.payload === "string") {
        state.productIdAndNames = [];
      } else {
        state.productIdAndNames = action.payload;
      }
      state.isProductIdAndNamesLoading = _LOADINGSTATE.fullfilled;
    },
    [getProductIdAndName.pending.type]: (state: ProductState) => {
      state.isProductIdAndNamesLoading = _LOADINGSTATE.pending;
    },
    [getProductIdAndName.rejected.type]: (state, { error }) => {
      state.isProductIdAndNamesLoading = _LOADINGSTATE.rejected;
      ErrorMessage.show(
        "There was an error getting product ID and name. Please refresh the page and try again. If the issue persists please contact the tool administrator."
      );
    },
    [getProductsWithoutMilestones.fulfilled.type]: (
      state: ProductState,
      action: PayloadAction<Product[] | string>
    ) => {
      if (typeof action.payload === "string") {
        state.productsInLastSixMonths = [];
      } else {
        state.productsInLastSixMonths = action.payload;
      }
      state.isProductsWithoutMilestonesLoading = _LOADINGSTATE.fullfilled;
    },
    [getProductsWithoutMilestones.pending.type]: (state: ProductState) => {
      state.isProductsWithoutMilestonesLoading = _LOADINGSTATE.pending;
    },
    [getProductsWithoutMilestones.rejected.type]: (state, { error }) => {
      state.isProductsWithoutMilestonesLoading = _LOADINGSTATE.rejected;
      ErrorMessage.show(
        "There was an error getting products in last six months. Please refresh the page and try again. If the issue persists please contact the tool administrator."
      );
    },
    [postProduct.fulfilled.type]: (state: ProductState, { payload }) => {
      state.singleProduct.__isLoading = _LOADINGSTATE.fullfilled;
    },
    [postProduct.pending.type]: (state: ProductState, { payload }) => {
      state.singleProduct.__isLoading = _LOADINGSTATE.pending;
    },
    [postProduct.rejected.type]: (state, { error }) => {
      state.singleProduct.__isLoading = _LOADINGSTATE.rejected;
      ErrorMessage.show(
        "There was an error posting the product. Please refresh the page and try again. If the issue persists please contact the tool administrator."
      );
    },
    [getIsExistProduct.fulfilled.type]: (state, { payload }) => {},
    [getIsExistProduct.rejected.type]: (state, { error }) => {
      ErrorMessage.show(
        "There was an error evaluating if the product exists. Please refresh the page and try again. If the issue persists please contact the tool administrator."
      );
    },
  },
});

export const { reducer, actions } = productSlice;
