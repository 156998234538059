import httpClient from "./base";

const baseURL = "/BAGFeature";

const getBagFeaturesByMomentId = (id: number) => {
  var url = `${baseURL}/GetBagFeaturesByMomentId?id=` + id;
  console.log("Get Bag by Moment Id");
  return httpClient.get(url);
};
export interface BagFeatures {
  RawDataId: string;
  FeatureName: string;
  DisclosureDate: string;
  SolutionArea: string;
  Tier: string;
  Link: string;
}

export interface bagFeaturesGroupByTier {
  tier: string;
  bagFeatures: BagFeatures[];
}
const bagFeatureService = {
  getBagFeaturesByMomentId,
};

export default bagFeatureService;
