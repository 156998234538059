import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import acomcategoryService from "../services/acomcategory";
import { _LOADINGSTATE } from "../components/Shared/constants";
import { ErrorMessage } from "../components/Shared/messageBox";

interface acomCategoryState {
  __isLoading: string;
  acomcategories: ACOMCategory[];
}

const initialState = {
  __isLoading: _LOADINGSTATE.pending,
  acomcategories: [],
};

export interface ACOMCategory {
  ACOMCategoryId: number;
  Name: string;
}

export const getACOMCategories = createAsyncThunk(
  "getACOMCategories",
  async (conversationIds: number[]) => {
    if (
      conversationIds === undefined ||
      conversationIds === null ||
      conversationIds.length === 0
    ) {
      return [];
    }
    return (await acomcategoryService.getACOMCategories(
      conversationIds
    )) as ACOMCategory[];
  }
);

const acomcategoryReducer = createSlice({
  name: "acomcategory",
  initialState: initialState,
  reducers: {},
  extraReducers: {
    [getACOMCategories.fulfilled.type]: (
      state: acomCategoryState,
      action: PayloadAction<ACOMCategory[] | string>
    ) => {
      if (typeof action.payload === "string") {
        state.acomcategories = [];
      } else {
        state.acomcategories = action.payload;
      }
      state.__isLoading = _LOADINGSTATE.fullfilled;
    },
    [getACOMCategories.rejected.type]: (state, { error }) => {
      ErrorMessage.show(
        "There was an error in getting the ACOM categories. Please refresh the page and try again. If the issue persists please contact the tool administrator."
      );
    },
    [getACOMCategories.pending.type]: (state) => {
      state.__isLoading = _LOADINGSTATE.pending;
    },
  },
});

export const { reducer, actions } = acomcategoryReducer;
