import httpClient from "./base";

const baseURL = "/Product";

const getProduct = (id: number) => {
  var url = `${baseURL}?id=` + id;
  return httpClient.get(url);
};

const getProductIdAndName = () => {
  var url = `${baseURL}/GetProductIdAndNames`;
  return httpClient.get(url);
};

const postProduct = (params: any) => {
  var url = `${baseURL}`;
  return httpClient.post(url, params);
};

const getIsExistProduct = (params: { productName: string }) => {
  var url = `${baseURL}/Exist`;
  return httpClient.get(url, params);
};

const getProductsWithoutMilestones = () => {
  var url = `${baseURL}/GetProductsWithoutMilestones`;
  return httpClient.get(url);
};

const getAzureProduct = () => {
  var url = `${baseURL}/ProductCategoryCSAMapping`;
  return httpClient.get(url);
};

const productService = {
  getProduct,
  getProductIdAndName,
  postProduct,
  getIsExistProduct,
  getProductsWithoutMilestones,
  getAzureProduct,
};

export default productService;
