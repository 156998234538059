import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { TimelineType, _LOADINGSTATE } from "../components/Shared/constants";
import { ErrorMessage } from "../components/Shared/messageBox";
import securityPMMService from "../services/securityPMM";

export interface SecurityPMM {
  SecurityPMMId: number;
  PM: string | null;
  SCIGroup: string | null;
}

const initialState = [] as SecurityPMM[];

export const getSecurityPMM = createAsyncThunk(
  "getSecurityPMM",
  async () => await securityPMMService.getSecurityPMM()
);

const securityPMMSlice = createSlice({
  name: "securityPMMService",
  initialState: initialState,
  reducers: {},
  extraReducers: {
    [getSecurityPMM.fulfilled.type]: (
      state: SecurityPMM[],
      action: PayloadAction<SecurityPMM[]>
    ) => {
      return action.payload;
    },
    [getSecurityPMM.rejected.type]: (state, { error }) => {
      ErrorMessage.show(
        "There was an error getting the security PMM. Please refresh the page and try again. If the issue persists please contact the tool administrator."
      );
    },
  },
});

export const { reducer, actions } = securityPMMSlice;
