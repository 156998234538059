import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import tooltipService from "../services/tooltip";
import { ErrorMessage } from "../components/Shared/messageBox";
import { _LOADINGSTATE } from "../components/Shared/constants";

export interface Tooltip {
  TooltipId: number;
  FieldName: string;
  Content: string;
}

export interface TooltipState {
  tooltips: { [key: string]: string };
  isTooltipsLoading: string;
}

export const getTooltip = createAsyncThunk("getTooltip", async () => {
  return await tooltipService.getTooltip();
});

const initialState: TooltipState = {
  tooltips: {},
  isTooltipsLoading: "",
};

const tooltipReducer = createSlice({
  name: "tooltip",
  initialState: initialState,
  reducers: {},
  extraReducers: {
    [getTooltip.fulfilled.type]: (state, action: { payload: Tooltip[] }) => {
      action.payload.forEach((t) => {
        state.tooltips[t.FieldName] = t.Content;
      });
      state.isTooltipsLoading = _LOADINGSTATE.fullfilled;
    },
    [getTooltip.rejected.type]: (state, { error }) => {
      ErrorMessage.show(
        "There was an error getting the tooltips. Please refresh the page and try again. If the issue persists please contact the tool administrator."
      );
    },
    [getTooltip.pending.type]: (state) => {
      state.isTooltipsLoading = _LOADINGSTATE.pending;
    },
  },
});

export const { reducer, actions } = tooltipReducer;
