import httpClient from "./base";

const getModernWorkFeatureProductAreas = () => {
  var url = "/ModernWorkFeatureProductArea";
  return httpClient.get(url);
};

const modernWorkFeatureProductAreaService = {
  getModernWorkFeatureProductAreas,
};

export default modernWorkFeatureProductAreaService;
