import { isVoid } from "../../utils/utils";

const LastModifiedBy = (props: {
  modifiedBy: string | undefined;
  modifiedAt: string;
}) => {
  if (!props.modifiedBy || isVoid(props.modifiedBy)) {
    return null;
  }
  if (props.modifiedBy.indexOf("[") < props.modifiedBy.indexOf("]")) {
    const emailIndex = props.modifiedBy.indexOf("[");
    const emailEnd = props.modifiedBy.indexOf("]");
    let email = props.modifiedBy.substring(emailIndex + 1, emailEnd);
    let name = props.modifiedBy.substring(0, emailIndex).trim();
    return (
      <div>
        <p>
          Last modified at {props.modifiedAt} by{" "}
          <a href={`mailto:${email}`} aria-label={`E-mail to ${name}`}>
            {name}
          </a>{" "}
        </p>
      </div>
    );
  }
  return (
    <div>
      <p>
        Last modified at {props.modifiedAt} by{" "}
        <a
          href={`mailto:${props.modifiedBy}`}
          aria-label={`E-mail to ${props.modifiedBy}`}
        >
          {props.modifiedBy}
        </a>{" "}
      </p>
    </div>
  );
};

export default LastModifiedBy;
