import React, { useState, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { Spinner, SpinnerSize } from "@fluentui/react";
import "./App.scss";
import { isVoid } from "./utils/utils";
import { _AUTHORIZATIONTYPE } from "./components/Shared/constants";
import SkipToContent from "./components/SkipToContent/index";
import ConfidentialNote from "./components/ConfidentialNote/index";
import NavHeader from "./components/NavHeader/index";
import Calendar from "./components/Calendar";
import AzureTimeline from "./components/AzureTimeline";
import ModernWorkTimeline from "./components/ModernWorkTimeline";
import SecurityTimeline from "./components/SecurityTimeline";
import RequestAccess from "./components/RequestAccess";
import PPTExport from "./components/PPTExport/pptExport";
import AzureProducts from "./components/AzureProducts/azureProducts";
import Footer from "./components/Footer/index";
import Login from "./login";

export const AppContext = React.createContext<{
  accessType: string;
}>({
  accessType: "",
});

const App = () => {
  const [accessToken, setAccessToken] = useState({
    token: localStorage.getItem("accessToken") || undefined,
    accessType: localStorage.getItem("accessType") || undefined,
    expire: localStorage.getItem("expire") || undefined,
  });

  const [showCopilotComponent, setShowCopilotComponent] = useState(false);
  const [copiloitBackendURL, setcopilotBackendURL] = useState("");
  const [showSpinner, setShowSpinner] = useState(true);
  const [showPowerBIComponent, setShowPowerBIComponent] = useState(false);

  // Fetch the API key from the backend
  useEffect(() => {
    fetch("/api/APIfetch/GetBackendURL")
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        if (data !== undefined) {
          const apiKey = data.backendAPI; // Access the BackendURL property
          setcopilotBackendURL(apiKey);

          // Store the API key in local storage
          localStorage.setItem("CopilotBackendURL", apiKey);
        }
      })
      .catch((error) => console.error("Error fetching API key:", error));
  }, []);

  //If showPowerBIComponent is true, fetch the configuration details
  const [powerBIConfig, setpowerBIConfig] = useState(null);

  useEffect(() => {
    // Fetch Power BI configuration when the component mounts
    fetch("/api/PowerBIConfiguration/GetPowerBIConfiguration")
      .then((response) => response.json())
      .then((data) => setpowerBIConfig(data))
      .catch((error) =>
        console.error("Error fetching Power BI configuration:", error)
      );
  }, []);
  // Fetch the feature flags from the backend
  useEffect(() => {
    if (copiloitBackendURL !== "") {
      fetch(`${copiloitBackendURL}/getCopilotfeature`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          return response.json();
        })
        .then((data) => {
          const features = data.features;
          const copilot = features.find(
            (feature: { FeatureName: string }) =>
              feature.FeatureName === "Copilot"
          );
          const powerbi = features.find(
            (feature: { FeatureName: string }) =>
              feature.FeatureName === "Power BI"
          );
          if (copilot && copilot.Flag === 1) {
            setShowCopilotComponent(true);
          } else {
            setShowCopilotComponent(false);
          }
          if (powerbi && powerbi.Flag === 1) {
            setShowPowerBIComponent(true);
          } else {
            setShowPowerBIComponent(false);
          }
        })
        .catch((error) => {
          console.error("Error fetching features", error);
          // Handle error or show an error message
        });
    }
  }, [copiloitBackendURL]); // Dependency array includes copiloitBackendURL

  return (
    <>
      <Login
        accessToken={accessToken}
        setAccessToken={setAccessToken}
        setShowSpinner={setShowSpinner}
      />
      {showSpinner ? (
        <div style={{ margin: "400px auto" }}>
          <Spinner
            label="Logging in..."
            size={SpinnerSize.large}
            labelPosition="bottom"
          />
        </div>
      ) : !isVoid(accessToken.token) ? (
        <>
          <SkipToContent />
          <ConfidentialNote />
          <NavHeader />
          <div
            aria-live="assertive"
            className="visually-hidden"
            id="ariaLiveReader"
            tabIndex={-1}
          ></div>
          <main id="maincontent" role="main" style={{ outline: "none" }}>
            {accessToken.accessType !== undefined &&
            accessToken.accessType !== _AUTHORIZATIONTYPE.access_denied ? (
              <AppContext.Provider
                value={{ accessType: accessToken.accessType }}
              >
                <Routes>
                  <Route path="/" element={<Calendar />} />
                  <Route
                    path="/AzureTimeline"
                    element={
                      <AzureTimeline
                        showCopilotComponent={showCopilotComponent}
                        showPowerBIComponent={showPowerBIComponent}
                        PowerBIconfig={powerBIConfig}
                      />
                    }
                  />
                  <Route
                    path="/ModernWorkTimeline"
                    element={
                      <ModernWorkTimeline
                        showCopilotComponent={showCopilotComponent}
                        showPowerBIComponent={showPowerBIComponent}
                        PowerBIconfig={powerBIConfig}
                      />
                    }
                  />
                  <Route
                    path="/SecurityTimeline"
                    element={
                      <SecurityTimeline
                        showCopilotComponent={showCopilotComponent}
                        showPowerBIComponent={showPowerBIComponent}
                        PowerBIconfig={powerBIConfig}
                      />
                    }
                  />
                  <Route path="/PPTExport" element={<PPTExport />} />
                  <Route path="/AzureProducts" element={<AzureProducts />} />
                  <Route path="*" element={<Calendar />} />
                </Routes>
              </AppContext.Provider>
            ) : (
              <RequestAccess />
            )}
          </main>
          <Footer />
        </>
      ) : null}
    </>
  );
};

export default App;
