import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as securityMilestoneReducer from "../../slice/securityMilestone";
import * as securityConfidenceReducer from "../../slice/securityConfidence";
import * as securityMilestoneTypeReducer from "../../slice/securityMilestoneType";
import { Dropdown, IDropdownOption } from "@fluentui/react";
import { RootState, useAppDispatch } from "../../store";
import {
  dropdownStylePropsWithoutLabelMargin,
  dropdownStylesProps,
} from "../Shared/styleProps";
import {
  milestoneFormBorderTop,
  SecurityMilestoneTypeId,
  TimelineType,
  _AUTHORIZATIONTYPE,
  _LOADINGSTATE,
} from "../Shared/constants";
import {
  getModernWorkSecurityEventCalendarMomentById,
  getModernWorkSecurityEventCalendarMomentListForDetail,
  modernWorkSecurityEventCalendarMoment,
} from "../../slice/modernWorkSecurityEventCalendarMoment";
import moment from "moment";
import DatePickerWithLabelAndError from "../Shared/FormInput/datepickerWithLabelAndError";
import {
  convertDateTimeToFormatedDateString,
  transferToUtcDate,
} from "../../utils/date";

const Timing = (props: {
  disabled: boolean;
  appearErrorInfo: boolean;
  setFormChanged: Function;
}) => {
  const dispatch = useDispatch();
  const appDispatch = useAppDispatch();
  const securityMilestoneInit = useSelector(
    (state: RootState) => state.securityMilestoneReducer
  );
  const milestone = securityMilestoneInit.SingleMilestone;
  const securityMilestoneTypes = useSelector(
    (state: RootState) => state.securityMilestoneTypeReducer
  );
  const securityMilestoneTypeOptions = securityMilestoneTypes.map((t) => {
    return { key: t.SecurityMilestoneTypeId, text: t.Name ?? "" };
  });
  const securityConfidences = useSelector(
    (state: RootState) => state.securityConfidenceReducer
  );
  const securityConfidenceOptions = securityConfidences.map((c) => {
    return { key: c.SecurityConfidenceId, text: c.Name ?? "" };
  });

  useEffect(() => {
    if (securityConfidences.length === 0) {
      appDispatch(securityConfidenceReducer.getSecurityConfidence());
    }
    if (securityMilestoneTypes.length === 0) {
      appDispatch(securityMilestoneTypeReducer.getSecurityMilestoneType());
    }
  }, []);

  const modernWorkSecurityEventCalendarMomentList = useSelector<
    RootState,
    modernWorkSecurityEventCalendarMoment[]
  >(
    (state: RootState) =>
      state.modernWorkSecurityEventCalendarMomentReducer
        .modernWorkSecurityEventCalendarMomentListForDetail
  );
  let momentInitValues: { key: number; text: string }[] = [];

  momentInitValues = modernWorkSecurityEventCalendarMomentList.map((m) => {
    return { key: m.Id, text: m.DisplayTextWithYear };
  });
  useEffect(() => {
    if (milestone.__isLoading === _LOADINGSTATE.fullfilled) {
      appDispatch(
        getModernWorkSecurityEventCalendarMomentListForDetail({
          timelineType: TimelineType.SCI,
          date: new Date(moment(new Date()).format("MM/DD/YYYY")),
        })
      );
    }
  }, [milestone.__isLoading]);

  useEffect(() => {
    if (
      milestone.ModernWorkSecurityEventCalendarMomentsId &&
      modernWorkSecurityEventCalendarMomentList.findIndex((l) => {
        return l.Id === milestone.ModernWorkSecurityEventCalendarMomentsId;
      }) === -1
    ) {
      dispatch(
        getModernWorkSecurityEventCalendarMomentById(
          milestone.ModernWorkSecurityEventCalendarMomentsId
        )
      );
    }
  }, [modernWorkSecurityEventCalendarMomentList]);

  const modernWorkSecurityEventCalendarMomentForDetail = useSelector<
    RootState,
    modernWorkSecurityEventCalendarMoment | null
  >(
    (state: RootState) =>
      state.modernWorkSecurityEventCalendarMomentReducer
        .modernWorkSecurityEventCalendarMoment
  );

  if (
    modernWorkSecurityEventCalendarMomentForDetail &&
    modernWorkSecurityEventCalendarMomentForDetail.Id ===
      milestone.ModernWorkSecurityEventCalendarMomentsId
  ) {
    momentInitValues.unshift({
      key: modernWorkSecurityEventCalendarMomentForDetail.Id,
      text: modernWorkSecurityEventCalendarMomentForDetail.DisplayTextWithYear,
    });
  }

  momentInitValues.unshift({ key: 0, text: "None" });
  return (
    <>
      <div
        style={{
          borderTop: milestoneFormBorderTop,
          marginTop: "20px",
        }}
      ></div>
      <div className="row">
        <div className="col-6">
          <div className="row">
            <div className="modal-detail-header">Timing</div>
          </div>
          <div className="row">
            <div className="col-6">
              <Dropdown
                selectedKey={milestone.SecurityMilestoneTypeId}
                options={securityMilestoneTypeOptions}
                onChange={(event, option) => {
                  if (option) {
                    dispatch(
                      securityMilestoneReducer.actions.changeMilestoneType(
                        option.key as number
                      )
                    );
                  }
                  props.setFormChanged(true);
                }}
                label="Milestone type *"
                styles={dropdownStylesProps}
                disabled={
                  props.disabled ||
                  (milestone.ModernWorkSecurityMSETSFeaturesId !== null &&
                    milestone.ModernWorkSecurityMSETSFeaturesId !== 0)
                }
                errorMessage={
                  props.appearErrorInfo
                    ? milestone.SecurityMilestoneTypeId &&
                      milestone.SecurityMilestoneTypeId > 0
                      ? undefined
                      : "Milestone type is required"
                    : undefined
                }
              />
            </div>
            <div className="col-6">
              <Dropdown
                selectedKey={milestone.SecurityConfidenceId}
                options={securityConfidenceOptions}
                onChange={(event, option) => {
                  if (option) {
                    dispatch(
                      securityMilestoneReducer.actions.changeSecurityConfidence(
                        option.key as number
                      )
                    );
                  }
                  props.setFormChanged(true);
                }}
                label="Confidence *"
                styles={dropdownStylesProps}
                disabled={props.disabled}
                errorMessage={
                  props.appearErrorInfo
                    ? milestone.SecurityConfidenceId &&
                      milestone.SecurityConfidenceId > 0
                      ? undefined
                      : "Confidence is required"
                    : undefined
                }
                id="confidenceField"
              />
            </div>
          </div>
        </div>
        <div className="col-6" style={{ marginTop: "20px" }}>
          <div
            className="row feature-detail disabled"
            style={{ margin: "0px" }}
          >
            <div
              className="col"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  textAlign: "center",
                  fontSize: "18px",
                  fontWeight: "bold",
                  padding: "0 30px 0 30px",
                }}
              >
                To be populated by SCI DRI
              </div>
            </div>
            <div className="col">
              <Dropdown
                label="Confirmed moment"
                disabled={
                  props.disabled ||
                  (milestone.ModernWorkSecurityMSETSFeaturesId !== null &&
                    milestone.ModernWorkSecurityMSETSFeaturesId !== 0)
                }
                options={momentInitValues}
                id="internalTaggedMoment"
                selectedKey={milestone.ModernWorkSecurityEventCalendarMomentsId}
                placeholder="None"
                onChange={(
                  event: React.FormEvent<HTMLDivElement>,
                  item?: IDropdownOption
                ) => {
                  let momentId = item ? Number(item?.key) : null;
                  if (momentId !== null && momentId == 0) {
                    momentId = null;
                  }
                  appDispatch(
                    securityMilestoneReducer.actions.changeMoment(momentId)
                  );
                  props.setFormChanged(true);
                }}
                onRenderOption={(
                  option: IDropdownOption | undefined
                ): JSX.Element => {
                  return option !== undefined ? (
                    <span
                      className="ms-Dropdown-optionText"
                      style={{
                        overflow: `hidden`,
                        whiteSpace: `nowrap`,
                        textOverflow: `ellipsis`,
                        maxWidth: `100%`,
                        overflowWrap: `break-word`,
                        margin: 1,
                      }}
                      title={option?.text || ""}
                      dangerouslySetInnerHTML={{
                        __html: option?.text || "",
                      }}
                    ></span>
                  ) : (
                    <></>
                  );
                }}
                onRenderTitle={(
                  options: IDropdownOption[] | undefined
                ): JSX.Element => {
                  const option = options ? options[0] : undefined;
                  return (
                    <span
                      title={option?.text}
                      dangerouslySetInnerHTML={{ __html: option?.text || "" }}
                    ></span>
                  );
                }}
                errorMessage={""}
                ariaLabel="Confirmed moment"
                styles={dropdownStylePropsWithoutLabelMargin}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-3">
          <DatePickerWithLabelAndError
            label={
              "Public disclosure date" +
              (milestone.SecurityMilestoneTypeId ===
              SecurityMilestoneTypeId.Public_Disclosure_Date
                ? "*"
                : "")
            }
            id="Public_disclosure_date"
            value={
              milestone.PublicDisclosureDate
                ? transferToUtcDate(new Date(milestone.PublicDisclosureDate))
                : undefined
            }
            onSelectDate={(date: Date | undefined | null) => {
              if (date && date != null) {
                dispatch(
                  securityMilestoneReducer.actions.changePublicDisclosureDate(
                    new Date(
                      convertDateTimeToFormatedDateString(date)
                    ).toISOString()
                  )
                );
              } else {
                dispatch(
                  securityMilestoneReducer.actions.changePublicDisclosureDate(
                    null
                  )
                );
              }
              props.setFormChanged(true);
            }}
            disabled={
              props.disabled ||
              (milestone.ModernWorkSecurityMSETSFeaturesId !== null &&
                milestone.ModernWorkSecurityMSETSFeaturesId !== 0)
            }
            error={
              props.appearErrorInfo &&
              milestone.SecurityMilestoneTypeId ===
                SecurityMilestoneTypeId.Public_Disclosure_Date
                ? milestone.PublicDisclosureDate != null
                  ? ""
                  : "Public disclosure date is required"
                : ""
            }
            timelineType={TimelineType.SCI}
          />
        </div>
        <div className="col-3">
          <DatePickerWithLabelAndError
            label={
              "Private preview date" +
              (milestone.SecurityMilestoneTypeId ===
              SecurityMilestoneTypeId.Private_Preview
                ? "*"
                : "")
            }
            id="Private_preview_date"
            value={
              milestone.PrivatePreviewDate
                ? transferToUtcDate(new Date(milestone.PrivatePreviewDate))
                : undefined
            }
            onSelectDate={(date: Date | undefined | null) => {
              if (date && date != null) {
                dispatch(
                  securityMilestoneReducer.actions.changePrivatePreviewDate(
                    new Date(
                      convertDateTimeToFormatedDateString(date)
                    ).toISOString()
                  )
                );
              } else {
                dispatch(
                  securityMilestoneReducer.actions.changePrivatePreviewDate(
                    null
                  )
                );
              }
              props.setFormChanged(true);
            }}
            error={
              props.appearErrorInfo &&
              milestone.SecurityMilestoneTypeId ===
                SecurityMilestoneTypeId.Private_Preview
                ? milestone.PrivatePreviewDate != null
                  ? ""
                  : "Private preview date is required"
                : ""
            }
            disabled={props.disabled}
            timelineType={TimelineType.SCI}
          />
        </div>
        <div className="col-3">
          <DatePickerWithLabelAndError
            label={
              "Public preview date" +
              (milestone.SecurityMilestoneTypeId ===
              SecurityMilestoneTypeId.Public_Preview
                ? "*"
                : "")
            }
            id="Public_preview_date"
            value={
              milestone.PublicPreviewDate
                ? transferToUtcDate(new Date(milestone.PublicPreviewDate))
                : undefined
            }
            onSelectDate={(date: Date | undefined | null) => {
              if (date && date != null) {
                dispatch(
                  securityMilestoneReducer.actions.changePublicPreviewDate(
                    new Date(
                      convertDateTimeToFormatedDateString(date)
                    ).toISOString()
                  )
                );
              } else {
                dispatch(
                  securityMilestoneReducer.actions.changePublicPreviewDate(null)
                );
              }
              props.setFormChanged(true);
            }}
            error={
              props.appearErrorInfo &&
              milestone.SecurityMilestoneTypeId ===
                SecurityMilestoneTypeId.Public_Preview
                ? milestone.PublicPreviewDate != null
                  ? ""
                  : "Public preview date is required"
                : ""
            }
            disabled={
              props.disabled ||
              (milestone.ModernWorkSecurityMSETSFeaturesId !== null &&
                milestone.ModernWorkSecurityMSETSFeaturesId !== 0)
            }
            timelineType={TimelineType.SCI}
          />
        </div>
        <div className="col-3">
          <DatePickerWithLabelAndError
            label={
              "General availability date" +
              (milestone.SecurityMilestoneTypeId ===
              SecurityMilestoneTypeId.General_Availability
                ? "*"
                : "")
            }
            id="General_availability_date"
            value={
              milestone.GeneralAvailabilityDate
                ? transferToUtcDate(new Date(milestone.GeneralAvailabilityDate))
                : undefined
            }
            onSelectDate={(date: Date | undefined | null) => {
              if (date && date != null) {
                dispatch(
                  securityMilestoneReducer.actions.changeGeneralAvailabilityDate(
                    new Date(
                      convertDateTimeToFormatedDateString(date)
                    ).toISOString()
                  )
                );
              } else {
                dispatch(
                  securityMilestoneReducer.actions.changeGeneralAvailabilityDate(
                    null
                  )
                );
              }
              props.setFormChanged(true);
            }}
            error={
              props.appearErrorInfo &&
              milestone.SecurityMilestoneTypeId ===
                SecurityMilestoneTypeId.General_Availability
                ? milestone.GeneralAvailabilityDate != null
                  ? ""
                  : "General availability date is required"
                : ""
            }
            disabled={
              props.disabled ||
              (milestone.ModernWorkSecurityMSETSFeaturesId !== null &&
                milestone.ModernWorkSecurityMSETSFeaturesId !== 0)
            }
            timelineType={TimelineType.SCI}
          />
        </div>
      </div>
    </>
  );
};

export default Timing;
