import { useContext, useEffect, useState } from "react";
import moment from "moment";
import { Callout, Icon, Spinner, SpinnerSize } from "@fluentui/react";
import { ModalType } from "../../Shared/constants";
import { useAppDispatch } from "../../../store";
import {
  modernWorkSecurityEventCalendarMoment,
  getModernWorkSecurityEventCalendarMomentById,
} from "../../../slice/modernWorkSecurityEventCalendarMoment";
import Accordion from "../../Shared/accordion";
import {
  getMilestoneByMomentId,
  milestoneGroupByTier,
} from "../../../slice/securityMilestone";
import { SecurityMilestoneModalContext } from "..";

const MomentTooltip = (tooltip: {
  id: number;
  target: string;
  dismiss: () => void;
}) => {
  const securityMilestoneModalContext = useContext(
    SecurityMilestoneModalContext
  );
  const appDispatch = useAppDispatch();
  const [
    modernWorkSecurityEventCalendarMomentInfo,
    setModernWorkSecurityEventCalendarMomentInfo,
  ] = useState<modernWorkSecurityEventCalendarMoment>();
  const [milestoneGroupByTierInfo, setMilestoneGroupByTierInfo] =
    useState<milestoneGroupByTier[]>();

  useEffect(() => {
    if (modernWorkSecurityEventCalendarMomentInfo === undefined) {
      appDispatch(
        getModernWorkSecurityEventCalendarMomentById(tooltip.id)
      ).then((response) => {
        setModernWorkSecurityEventCalendarMomentInfo(
          response.payload as modernWorkSecurityEventCalendarMoment
        );
      });
    } else if (milestoneGroupByTierInfo === undefined) {
      if (
        modernWorkSecurityEventCalendarMomentInfo.Id !== null &&
        modernWorkSecurityEventCalendarMomentInfo.Id !== undefined
      ) {
        appDispatch(
          getMilestoneByMomentId(modernWorkSecurityEventCalendarMomentInfo.Id)
        ).then((response) => {
          setMilestoneGroupByTierInfo(
            response.payload as milestoneGroupByTier[]
          );
        });
      }
    }
  }, [modernWorkSecurityEventCalendarMomentInfo]);

  return (
    <Callout
      style={{ maxHeight: 800, width: 500, padding: "20px 24px" }}
      target={`#${tooltip.target}`}
      onDismiss={tooltip.dismiss}
      role="dialog"
      aria-live="assertive"
    >
      {modernWorkSecurityEventCalendarMomentInfo === undefined ? (
        <Spinner size={SpinnerSize.small} ariaLive="polite" />
      ) : (
        <div style={{ lineHeight: "32px" }}>
          <div>
            {moment(
              new Date(modernWorkSecurityEventCalendarMomentInfo.StartDate)
            )
              .utc()
              .format("MM/DD/YYYY") ===
            moment(new Date(modernWorkSecurityEventCalendarMomentInfo.EndDate))
              .utc()
              .format("MM/DD/YYYY")
              ? moment(
                  new Date(modernWorkSecurityEventCalendarMomentInfo.StartDate)
                )
                  .utc()
                  .format("MM/DD/YYYY")
              : moment(
                  new Date(modernWorkSecurityEventCalendarMomentInfo.StartDate)
                )
                  .utc()
                  .format("MM/DD/YYYY") +
                " - " +
                moment(
                  new Date(modernWorkSecurityEventCalendarMomentInfo.EndDate)
                )
                  .utc()
                  .format("MM/DD/YYYY")}
          </div>
          <div>{modernWorkSecurityEventCalendarMomentInfo.Title}</div>
          <div>
            <Icon iconName="POISolid"></Icon>{" "}
            {modernWorkSecurityEventCalendarMomentInfo.Location}
          </div>
          <Accordion title="Milestones" isDefaultOpen={true}>
            {milestoneGroupByTierInfo === undefined ? (
              <Spinner size={SpinnerSize.small} ariaLive="polite" />
            ) : (
              milestoneGroupByTierInfo?.map(
                (milestoneGroupByTier: milestoneGroupByTier) => {
                  return (
                    <>
                      <div>
                        <strong>{"Tier " + milestoneGroupByTier.tier}</strong>
                      </div>
                      <div>
                        {milestoneGroupByTier.milestones.map((milestone) => {
                          return (
                            <div>
                              <a
                                href={
                                  window.location.origin +
                                  "/SecurityTimeline?milestoneId=" +
                                  milestone.SecurityMilestoneId
                                }
                                onClick={(e) => {
                                  e.preventDefault();
                                  securityMilestoneModalContext.setSecurityMilestoneModalStateHandler(
                                    {
                                      securityMilestoneId:
                                        milestone.SecurityMilestoneId,
                                      modalType: ModalType.Edit,
                                      deleteMilestoneDateHistoryId: "",
                                    }
                                  );
                                  securityMilestoneModalContext.modalRef?.current?.click();
                                }}
                                title={milestone.PublicTitle}
                              >
                                {milestone.PublicTitle}
                              </a>
                            </div>
                          );
                        })}
                      </div>
                    </>
                  );
                }
              )
            )}
          </Accordion>
        </div>
      )}
    </Callout>
  );
};

export default MomentTooltip;
