import { useEffect, useState } from "react";
import {
  Dropdown,
  IDropdownOption,
  MessageBar,
  MessageBarType,
  Spinner,
  SpinnerSize,
  TextField,
} from "@fluentui/react";
import { useSelector } from "react-redux";
import { PrimaryButton } from "@fluentui/react/lib/Button";
import { useMsal, useAccount } from "@azure/msal-react";
import { RootState, useAppDispatch } from "../../store";
import * as requestAccessReducer from "../../slice/requestAccess";
import "./index.scss";
import Header from "./Header";
import { _LOADINGSTATE } from "../Shared/constants";
import {
  dropdownStylesProps,
  textfieldStylesProps,
} from "../Shared/styleProps";
import { unwrapResult } from "@reduxjs/toolkit";
import { SuccessMessage } from "../Shared/messageBox";

const RequestAccess = () => {
  const appDispatch = useAppDispatch();
  const requestAccess = useSelector<
    RootState,
    requestAccessReducer.RequestAccess
  >((state: RootState) => {
    return state.requestAccessReducer;
  });

  const [appearErrorInfo, setAppearErrorInfo] = useState(false);
  const considerSubmitted = (condition: boolean) => {
    //we want error message to show after user click save edits button
    return appearErrorInfo && condition;
  };

  const { accounts } = useMsal();
  const account = useAccount(accounts[0] || {});

  useEffect(() => {
    appDispatch(
      requestAccessReducer.actions.changeRequestUserName(
        account === null ? "" : account.name === undefined ? "" : account.name
      )
    );
    appDispatch(
      requestAccessReducer.actions.changeEmail(
        account === null ? "" : account.username
      )
    );
  }, []);

  useEffect(() => {
    if (
      requestAccess.RequestUserName &&
      requestAccess.RequestUserName.trim().length > 0 &&
      requestAccess.RequestUserName.trim().length <= 100 &&
      requestAccess.Email &&
      requestAccess.Email.trim().length > 0 &&
      requestAccess.Email.trim().length <= 100 &&
      requestAccess.Role.trim().length <= 100 &&
      requestAccess.Orgowner &&
      requestAccess.Orgowner.trim().length > 0 &&
      requestAccess.LevelOfAccess &&
      requestAccess.LevelOfAccess.trim().length > 0 &&
      requestAccess.BusinessJustification.trim().length <= 500
    ) {
      appDispatch(requestAccessReducer.actions.changeIsFormValid(true));
    } else {
      appDispatch(requestAccessReducer.actions.changeIsFormValid(false));
    }
  }, [
    appDispatch,
    requestAccess.RequestUserName,
    requestAccess.Email,
    requestAccess.Role,
    requestAccess.Orgowner,
    requestAccess.LevelOfAccess,
    requestAccess.BusinessJustification,
  ]);

  return (
    <>
      <Header />
      <section className="request-access-panel">
        <div className="row">
          <div className="title">Access Request</div>
        </div>
        <div className="row">
          <div>
            Cloud Marketing Moments Calendar provides one easily consumable view
            of all upcoming moments and disclosures across Cloud Marketing used
            by CM PMMs and roadmap DRIs. Users who have access to the calendar
            will also gain access to the workspaces to link features and
            milestones to upcoming marketing moments.
            <br />
            <br />
            Please request Access if you fall into one of the below categories
            of users.
          </div>
        </div>
        <div>
          <div className="float-start col-5">
            {account?.username.toLowerCase().endsWith("@microsoft.com") ? (
              <>
                <div>
                  <TextField
                    label="Your name *"
                    value={requestAccess.RequestUserName}
                    onChange={(event) => {
                      appDispatch(
                        requestAccessReducer.actions.changeRequestUserName(
                          event.currentTarget.value
                        )
                      );
                    }}
                    errorMessage={
                      considerSubmitted(
                        requestAccess.RequestUserName.trim().length === 0
                      )
                        ? "Your name is required."
                        : considerSubmitted(
                            requestAccess.RequestUserName.length > 100
                          )
                        ? `Your name exceeds 100 characters.`
                        : ""
                    }
                    styles={textfieldStylesProps}
                    ariaLabel="Your name, required field"
                    aria-labelledby="Your name, required field"
                  />
                </div>
                <div>
                  <TextField
                    label="Email *"
                    value={requestAccess.Email}
                    onChange={(event) => {
                      appDispatch(
                        requestAccessReducer.actions.changeEmail(
                          event.currentTarget.value
                        )
                      );
                    }}
                    errorMessage={
                      considerSubmitted(requestAccess.Email.trim().length === 0)
                        ? "Email is required."
                        : considerSubmitted(requestAccess.Email.length > 100)
                        ? `Email exceeds 100 characters.`
                        : ""
                    }
                    styles={textfieldStylesProps}
                    ariaLabel="Email, required field"
                    aria-labelledby="Email, required field"
                  />
                </div>
                <div>
                  <TextField
                    label="Your title"
                    onChange={(event) => {
                      appDispatch(
                        requestAccessReducer.actions.changeRole(
                          event.currentTarget.value
                        )
                      );
                    }}
                    errorMessage={
                      considerSubmitted(requestAccess.Email.length > 100)
                        ? `Your title exceeds 100 characters.`
                        : ""
                    }
                    styles={textfieldStylesProps}
                    ariaLabel="Your title"
                    aria-labelledby="Your title"
                  />
                </div>
                <div>
                  <Dropdown
                    options={[
                      { key: 1, text: "GDC Marketing" },
                      { key: 2, text: "Engineering" },
                    ]}
                    id="Organization"
                    label="Organization"
                    placeholder="Select an option"
                    onChange={(
                      event: React.FormEvent<HTMLDivElement>,
                      item?: IDropdownOption
                    ) => {
                      if (item != null) {
                        appDispatch(
                          requestAccessReducer.actions.changeOrgowner(item.text)
                        );
                      }
                    }}
                    errorMessage={
                      considerSubmitted(
                        requestAccess.Orgowner.trim().length === 0
                      )
                        ? "Organization is required."
                        : ""
                    }
                    styles={dropdownStylesProps}
                    ariaLabel="Organization, required field"
                    aria-labelledby="Organization, required field"
                  />
                </div>
                <div>
                  <Dropdown
                    options={[
                      { key: 1, text: "Edit" },
                      { key: 2, text: "Read" },
                    ]}
                    id="AccessType"
                    label="Access type"
                    placeholder="Select an option"
                    onChange={(
                      event: React.FormEvent<HTMLDivElement>,
                      item?: IDropdownOption
                    ) => {
                      if (item != null) {
                        appDispatch(
                          requestAccessReducer.actions.changeLevelOfAccess(
                            item.text
                          )
                        );
                      }
                    }}
                    errorMessage={
                      considerSubmitted(
                        requestAccess.LevelOfAccess.trim().length === 0
                      )
                        ? "Access type is required."
                        : ""
                    }
                    styles={dropdownStylesProps}
                    ariaLabel="Access type, required field"
                  />
                </div>
                <div>
                  <TextField
                    label="Please provide a business justification below"
                    onChange={(event) => {
                      appDispatch(
                        requestAccessReducer.actions.changeBusinessJustification(
                          event.currentTarget.value
                        )
                      );
                    }}
                    errorMessage={
                      considerSubmitted(
                        requestAccess.BusinessJustification.length > 500
                      )
                        ? `Your title exceeds 500 characters.`
                        : ""
                    }
                    styles={textfieldStylesProps}
                    ariaLabel="Please provide a business justification below"
                    aria-labelledby="Please provide a business justification below"
                  />
                </div>
                <div>
                  <div className="col-12 mt-4">
                    <PrimaryButton
                      disabled={
                        requestAccess.__isLoading === _LOADINGSTATE.pending
                      }
                      text="Request"
                      ariaDescription="Request Access"
                      onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                        event.preventDefault();
                        setAppearErrorInfo(true);

                        if (requestAccess.__isFormValid) {
                          appDispatch(
                            requestAccessReducer.requestAccess(requestAccess)
                          )
                            .then(unwrapResult)
                            .then(() => {
                              SuccessMessage.show(
                                "Thank you. Your access request is pending approval."
                              );
                              setAppearErrorInfo(false);
                            })
                            .catch(() => {
                              setAppearErrorInfo(false);
                            });
                        }
                        event.stopPropagation();
                      }}
                    />
                    <span
                      style={{
                        display: "inline-block",
                        width: 30,
                        height: 19,
                      }}
                    >
                      {requestAccess.__isLoading === _LOADINGSTATE.pending ? (
                        <Spinner
                          size={SpinnerSize.small}
                          style={{
                            display: "inline",
                            marginLeft: "10px",
                            position: "absolute",
                          }}
                        />
                      ) : (
                        <></>
                      )}
                    </span>
                  </div>
                </div>
              </>
            ) : (
              <MessageBar messageBarType={MessageBarType.error}>
                You don't have access to visit!
              </MessageBar>
            )}
          </div>
          <div className="float-end col-7 pt-4 ps-4">
            <div>
              <table className="mt-3">
                <tr>
                  <td
                    style={{
                      width: "160px",
                      fontWeight: "700",
                      backgroundColor: "#e6e6e6",
                    }}
                  >
                    Moments Calendar
                  </td>
                  <td
                    colSpan={3}
                    style={{ fontWeight: "700", backgroundColor: "#e6e6e6" }}
                  >
                    PMM Workspace
                    <br />
                    (Azure, Modern Work, Security)
                  </td>
                </tr>
                <tr>
                  <td rowSpan={6} style={{ textAlign: "left" }}>
                    GDC Marketing under
                    <br />
                    CVP Takashi Numoto's
                    <br />
                    leadership; GDC council-
                    <br />
                    approved details to
                    <br />
                    come.
                  </td>
                </tr>
                <tr>
                  <td style={{ width: "110px", fontWeight: "700" }}>Org</td>
                  <td style={{ width: "90px", fontWeight: "700" }}>
                    Access Level
                  </td>
                  <td style={{ fontWeight: "700" }}>Access Criteria</td>
                </tr>
                <tr>
                  <td>GDC Marketing</td>
                  <td>Edit</td>
                  <td style={{ textAlign: "left" }}>
                    If you are a PMM or DRI and have an @microsoft account under
                    CVP Takeshi Numoto's Org. Please select{" "}
                    <strong>Edit</strong> Access.
                  </td>
                </tr>
                <tr>
                  <td>GDC Marketing</td>
                  <td>Edit</td>
                  <td style={{ textAlign: "left" }}>
                    If you support a PMM or DRI, and do not have an @microsoft
                    account or a "v-" account for your 6 month break. Please
                    select <strong>Read</strong> or <strong>Edit</strong> access
                    as needed.
                  </td>
                </tr>
                <tr>
                  <td>Engineering</td>
                  <td>Read</td>
                  <td style={{ textAlign: "left" }}>
                    If you are an engineer with an @microsoft.com account under
                    CVP Charlie Bell and Scott Guthrie, please select{" "}
                    <strong>Read</strong> access only.
                  </td>
                </tr>
                <tr>
                  <td>Field</td>
                  <td>Declined</td>
                  <td style={{ textAlign: "left" }}>
                    All others will not be granted access as information should
                    be communicated by the PMM's to the field and general
                    audiences.
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default RequestAccess;
