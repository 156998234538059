import React, { ReactNode, useEffect, useState } from "react";
import "./modalOverlay.scss";
import {
  DirectionalHint,
  IIconProps,
  IStyle,
  KeyCodes,
  mergeStyleSets,
  Modal,
  TooltipHost,
} from "@fluentui/react";
import { useBoolean, useId } from "@fluentui/react-hooks";
import { IconButton } from "@fluentui/react/lib/Button";
import { useSearchParams } from "react-router-dom";
import ConfirmationDialog from "../confirmationDialog";
import { title } from "process";

const cancelIcon: IIconProps = { iconName: "Cancel" };

const ModalOverlay = (props: {
  children: ReactNode;
  refs: React.RefObject<HTMLButtonElement> | null;
  title: string;
  formSubmited: boolean;
  formSubmitedCallback?: (() => any) | undefined;
  setFormSubmited: Function[];
  confirmationShow?: React.RefObject<HTMLButtonElement> | null;
  formChanged?: boolean;
  formResetCallback?: (() => any) | undefined;
  mainStyle?: IStyle;
  className?: string;
}) => {
  const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);
  const titleId = useId("modal_Id");

  const [query, setQuery] = useSearchParams();

  const handleHeader = () => {
    let modalPane = document.querySelector(".ms-Dialog-main");
    if (modalPane && modalPane !== null) {
      let width = modalPane.getBoundingClientRect().width;
      let stickyHead = document.querySelector(".modalOverlay-header");
      if (
        stickyHead &&
        stickyHead !== null &&
        window &&
        window !== null &&
        window.visualViewport &&
        window.visualViewport !== null
      ) {
        let viewPortWidth = window.visualViewport.width;
        let headerWidth = viewPortWidth < width ? viewPortWidth : width;

        (stickyHead as HTMLElement).style.width = headerWidth - 25 + "px";
      }
    }
  };

  const closeModal = () => {
    if (hideDialog) {
      return;
    }

    if (props.formChanged) {
      props.confirmationShow?.current?.click();
    } else {
      toggleHideDialog();
      let btnNewMilestone = document.getElementById(
        "btnNewMilestone"
      ) as HTMLButtonElement;
      if (btnNewMilestone) {
        btnNewMilestone.focus();
      }
    }
  };

  let scrollContent = document.querySelector(".ms-Modal-scrollableContent");
  useEffect(() => {
    if (!hideDialog) {
      let scrollContent = document.querySelector(".ms-Modal-scrollableContent");
      if (scrollContent) {
        scrollContent.removeEventListener("scroll", handleHeader);
        scrollContent.addEventListener("scroll", handleHeader);
      }
      handleHeader();
    }
  }, [scrollContent]);

  useEffect(() => {
    if (hideDialog) {
      document.body.removeEventListener("keyup", handleKeyDown);
    } else {
      document.body.addEventListener("keyup", handleKeyDown);
    }
  }, [hideDialog]);

  return (
    <>
      <button
        className="hide d-none"
        onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
          event.preventDefault();
          toggleHideDialog();
          event.stopPropagation();
        }}
        ref={props.refs}
      ></button>

      <Modal
        forceFocusInsideTrap={false}
        titleAriaId={titleId}
        isOpen={!hideDialog}
        onDismissed={() => {
          if (hideDialog && props.formSubmited && props.formSubmitedCallback) {
            props.formSubmitedCallback();
          }
          if (props.formResetCallback) {
            props.formResetCallback();
          }
          //We need to reset submited condition to avoid refresh on modal re-open
          props.setFormSubmited.forEach((f) => {
            f(false);
          });
          if (query.has("milestoneId")) {
            query.delete("milestoneId");
          }
          if (query.has("featureId")) {
            query.delete("featureId");
          }
          setQuery(query);
        }}
        styles={{
          main: props.mainStyle
            ? props.mainStyle
            : {
                maxHeight: "inherit",
                minWidth: "1174px",
                maxWidth: "1174px",
              },
        }}
      >
        <div className={`product-form modalOverlay-header ${props.className}`}>
          <div className="product-form modalOverlay-wrapper">
            <h2 className="headerToSpanStyle" id={titleId}>
              {props.title}
            </h2>
            <TooltipHost
              content="Close"
              directionalHint={DirectionalHint.rightCenter}
            >
              <IconButton
                id="closeIconButton"
                iconProps={cancelIcon}
                ariaLabel="Close popup modal"
                tabIndex={0}
                autoFocus={false}
                onClick={closeModal}
                onKeyDown={(e) => {
                  if (e.keyCode == KeyCodes.escape) closeModal();
                }}
              />
            </TooltipHost>
          </div>
        </div>
        <div
          className="product-form modal-body"
          style={{
            padding:
              document.querySelector(".modalOverlay-header") &&
              (document.querySelector(".modalOverlay-header") as HTMLDivElement)
                .offsetHeight &&
              (document.querySelector(".modalOverlay-header") as HTMLDivElement)
                .offsetHeight > 114
                ? "158px 56px 70px 56px"
                : document.querySelector(".modalOverlay-header") &&
                  (
                    document.querySelector(
                      ".modalOverlay-header"
                    ) as HTMLDivElement
                  ).offsetHeight &&
                  (
                    document.querySelector(
                      ".modalOverlay-header"
                    ) as HTMLDivElement
                  ).offsetHeight > 70
                ? "114px 56px 70px 56px"
                : "70px 56px",
          }}
        >
          {props.children}
        </div>

        <ConfirmationDialog
          refs={props.confirmationShow || null}
          onclickCallBack={() => {
            return new Promise((resolve, reject) => {
              toggleHideDialog();
            });
          }}
          confirmButtonString="Discard Changes"
          cancelButtonString="Cancel"
          title="Confirmation"
          subText="You have unsaved changes. Discard them?"
        />
      </Modal>
    </>
  );
};

const handleKeyDown = (e: KeyboardEvent) => {
  if (e.keyCode === KeyCodes.escape) {
    document.getElementById("closeIconButton")?.click();
  }
};

export default ModalOverlay;
