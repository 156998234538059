import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { _LOADINGSTATE } from "../components/Shared/constants";
import azureupdateDescriptionHistoryService from "../services/azureupdateDescriptionHistory";
import { ErrorMessage } from "../components/Shared/messageBox";

export interface AzureUpdateDescriptionHistory {
  AzureUpdateCommentHistoryId: number;
  AzureUpdateId: number;
  Comment: string;
  ModifiedDate: string;
  ModifiedBy: string;
  Revision: number;
}

export interface TextComparison {
  CurrentVersion: AzureUpdateDescriptionHistory;
  ComparedResult: string;
}

export const getAzureUpdateDescriptionHistory = createAsyncThunk(
  "getAzureUpdateDescriptionHistory",
  async (azureupdateId: number) => {
    return (await azureupdateDescriptionHistoryService.getAzureUpdateDescriptionHistory(
      azureupdateId
    )) as AzureUpdateDescriptionHistory[];
  }
);

export const getAzureUpdateDescriptionComparison = createAsyncThunk(
  "getAzureUpdateDescriptionComparison",
  async (params: { azureupdateId: number; revision: number }) => {
    return (await azureupdateDescriptionHistoryService.getAzureUpdateDescriptionComparison(
      params.azureupdateId,
      params.revision
    )) as TextComparison;
  }
);

export interface InitialState {
  AzureUpdateDescriptionHistory: AzureUpdateDescriptionHistory[];
  TextComparison: TextComparison | undefined;
  __isHistoryLoading: string;
  __isComparisonLoading: string;
}

const initialState: InitialState = {
  AzureUpdateDescriptionHistory: [],
  TextComparison: undefined,
  __isHistoryLoading: _LOADINGSTATE.pending,
  __isComparisonLoading: _LOADINGSTATE.pending,
};

const azureupdateDescriptionHistory = createSlice({
  name: "azureupdateDescriptionHistory",
  initialState: initialState,
  reducers: {
    reset: (state: InitialState) => {
      state.AzureUpdateDescriptionHistory = [];
      state.TextComparison = undefined;
      state.__isHistoryLoading = _LOADINGSTATE.pending;
      state.__isComparisonLoading = _LOADINGSTATE.pending;
    },
  },
  extraReducers: {
    [getAzureUpdateDescriptionHistory.fulfilled.type]: (
      state: InitialState,
      { payload }: { payload: AzureUpdateDescriptionHistory[] }
    ) => {
      state.AzureUpdateDescriptionHistory = payload.slice(0);
      state.__isHistoryLoading = _LOADINGSTATE.fullfilled;
    },
    [getAzureUpdateDescriptionHistory.rejected.type]: (state: InitialState) => {
      state.__isHistoryLoading = _LOADINGSTATE.pending;
      ErrorMessage.show(
        "There was an error getting description history. Please refresh the page and try again. If the issue persists please contact the tool administrator."
      );
    },
    [getAzureUpdateDescriptionHistory.pending.type]: (state: InitialState) => {
      state.__isHistoryLoading = _LOADINGSTATE.pending;
    },
    [getAzureUpdateDescriptionComparison.fulfilled.type]: (
      state: InitialState,
      { payload }: { payload: TextComparison }
    ) => {
      state.TextComparison = payload;
      state.__isComparisonLoading = _LOADINGSTATE.fullfilled;
    },
    [getAzureUpdateDescriptionComparison.rejected.type]: (
      state: InitialState
    ) => {
      state.__isComparisonLoading = _LOADINGSTATE.pending;
      ErrorMessage.show(
        "There was an error getting description comparison. Please refresh the page and try again. If the issue persists please contact the tool administrator."
      );
    },
    [getAzureUpdateDescriptionComparison.pending.type]: (
      state: InitialState
    ) => {
      state.__isComparisonLoading = _LOADINGSTATE.pending;
    },
  },
});

export const { reducer, actions } = azureupdateDescriptionHistory;
