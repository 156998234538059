import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { _LOADINGSTATE } from "../components/Shared/constants";
import milestoneTypeService from "../services/milestoneType";
import { ErrorMessage } from "../components/Shared/messageBox";

interface milestoneTypeState {
  isMilestoneTypesLoading: string;
  milestoneTypes: milestoneType[];
}

const initialState: milestoneTypeState = {
  isMilestoneTypesLoading: "",
  milestoneTypes: [],
};

export interface milestoneType {
  MilestoneTypeId: number;
  Name: string;
}

export const getMilestoneTypes = createAsyncThunk(
  "getMilestoneTypes",
  async () => await milestoneTypeService.getMilestoneTypes()
);

const milestoneTypeSlice = createSlice({
  name: "milestoneType",
  initialState: initialState,
  reducers: {},
  extraReducers: {
    [getMilestoneTypes.fulfilled.type]: (
      state: milestoneTypeState,
      action: PayloadAction<milestoneType[] | string>
    ) => {
      if (typeof action.payload === "string") {
        state.milestoneTypes = [];
      } else {
        state.milestoneTypes = action.payload;
      }
      state.isMilestoneTypesLoading = _LOADINGSTATE.fullfilled;
    },
    [getMilestoneTypes.pending.type]: (state: milestoneTypeState) => {
      state.isMilestoneTypesLoading = _LOADINGSTATE.pending;
    },
    [getMilestoneTypes.rejected.type]: (state, { error }) => {
      ErrorMessage.show(
        "There was an error getting the milestone types. Please refresh the page and try again. If the issue persists please contact the tool administrator."
      );
    },
  },
});

export const { reducer, actions } = milestoneTypeSlice;
