import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { TimelineType, _LOADINGSTATE } from "../components/Shared/constants";
import { ErrorMessage } from "../components/Shared/messageBox";
import securityMilestoneTypeService from "../services/securityMilestoneType";

export interface SecurityMilestoneType {
  SecurityMilestoneTypeId: number;
  Name: string | null;
}

const initialState = [] as SecurityMilestoneType[];

export const getSecurityMilestoneType = createAsyncThunk(
  "getSecurityMilestoneType",
  async () => await securityMilestoneTypeService.getSecurityMilestoneType()
);

const securityMilestoneTypeSlice = createSlice({
  name: "securityMilestoneTypeService",
  initialState: initialState,
  reducers: {},
  extraReducers: {
    [getSecurityMilestoneType.fulfilled.type]: (
      state: SecurityMilestoneType[],
      action: PayloadAction<SecurityMilestoneType[]>
    ) => {
      return action.payload;
    },
    [getSecurityMilestoneType.rejected.type]: (state, { error }) => {
      ErrorMessage.show(
        "There was an error getting the security milestone type. Please refresh the page and try again. If the issue persists please contact the tool administrator."
      );
    },
  },
});

export const { reducer, actions } = securityMilestoneTypeSlice;
