import { useContext, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { useId } from "@fluentui/react-hooks";
import Select, { ActionMeta, GroupBase, OnChangeValue } from "react-select";
import { unwrapResult } from "@reduxjs/toolkit";
import {
  Checkbox,
  DefaultButton,
  Dropdown,
  Icon,
  IDropdownOption,
  Spinner,
  SpinnerSize,
  TextField,
} from "@fluentui/react";
//@ts-ignore
import { CKEditor } from "@ckeditor/ckeditor5-react";
//@ts-ignore
import ClassicEditor from "ckeditor5-custom-build/build/ckeditor";
import moment from "moment";
import "./index.scss";
import "../Shared/css/modal.scss";
import {
  convertDateTimeToFormatedDateString,
  formatCYDate,
  formatDate,
  transferToUtcDate,
} from "../../utils/date";
import { isVoid } from "../../utils/utils";
import {
  dropdownStylesProps,
  OptionType,
  reactselectSingleErrorStyleProps,
  reactselectSingleStyleProps,
  textfieldStylesProps,
} from "../Shared/styleProps";
import {
  TimelineType,
  _AUTHORIZATIONTYPE,
  _LOADINGSTATE,
} from "../Shared/constants";
import { SuccessMessage } from "../Shared/messageBox";
import DatePickerWithLabelAndError from "../Shared/FormInput/datepickerWithLabelAndError";
import GraphUsersAutoCompleteWithTeamsIcon from "../Shared/FormInput/graphUsersAutoCompleteWithTeamsIcon";
import Tooltip from "../Shared/FormInput/tooltip";
import LastModifiedBy from "../Shared/lastModifiedBy";
import { GraphUser, getGraphUsers } from "../../slice/graphProxy";
import * as modernWorkFeatureProductAreaReducer from "../../slice/modernWorkFeatureProductArea";
import * as modernWorkFeatureHistoryReducer from "../../slice/modernWorkFeatureHistory";
import * as triageTagReducer from "../../slice/triageTag";
import * as tierReducer from "../../slice/milestoneTier";
import * as featureReducer from "../../slice/modernWorkFeature";
import * as tooltipReducer from "../../slice/tooltip";
import { RootState, useAppDispatch } from "../../store";
import { ModernWorkFeatureModalContext } from "../ModernWorkTimeline";
import {
  getModernWorkSecurityEventCalendarMomentListForDetail,
  getModernWorkSecurityEventCalendarMomentById,
  modernWorkSecurityEventCalendarMoment,
} from "../../slice/modernWorkSecurityEventCalendarMoment";
import {
  getEmailPart,
  getNamePart,
  splitStringToArray,
} from "../../utils/string";
import "../Shared/FormInput/modalOverlay.scss";
import { dropdownStyles } from "../Shared/timelineFilterStyleProps";
import { AppContext } from "../../App";

const Details = (props: {
  submited: boolean;
  setSubmited: Function;
  appearErrorInfo: boolean;
  setAppearErrorInfo: Function;
  confirmationShow?: React.RefObject<HTMLButtonElement> | null;
  detailFormChanged: boolean;
  setDetailFormChanged: Function;
  disabled: boolean;
}) => {
  const appDispatch = useAppDispatch();
  const modernWorkFeatureModalContext = useContext(
    ModernWorkFeatureModalContext
  );
  const modernWorkFeatureProductAreas = useSelector<
    RootState,
    modernWorkFeatureProductAreaReducer.modernWorkFeatureProductArea[]
  >(
    (state) =>
      state.modernWorkFeatureProductAreaReducer.modernWorkFeatureProductAreas
  );

  let modernWorkFeatureHistory = useSelector<
    RootState,
    modernWorkFeatureHistoryReducer.ModernWorkFeatureHistory[]
  >((state) => state.modernWorkFeatureHistoryReducer.modernWorkFeatureHistory);

  const modernWorkFeatureProductAreasSelectOptions = useMemo(() => {
    let items: any[] = [];
    modernWorkFeatureProductAreas.forEach((a) => {
      let group: {
        value?: number;
        label?: string;
        options: { value: string; label: string; group: string }[];
      } = { options: [] };
      group.value = a.ModernWorkFeatureProductAreaId;
      group.label = a.Name;
      if (
        !isVoid(a.ModernWorkFeatureProductAreas) &&
        a.ModernWorkFeatureProductAreas.length > 0
      ) {
        a.ModernWorkFeatureProductAreas.forEach((aa) => {
          group.options.push({
            value: aa.ModernWorkFeatureProductAreaId.toString(),
            label: aa.Name,
            group: a.Name,
          });
        });
      }
      items.push(group);
    });
    return items;
  }, [modernWorkFeatureProductAreas]);

  const triageTags = useSelector<RootState, triageTagReducer.TriageTag[]>(
    (state) => state.triageTagReducer.triageTags
  );

  const tiers = useSelector<RootState, tierReducer.milestoneTier[]>(
    (state) => state.milestoneTierReducer.milestoneTiers
  );

  const tiersOptions = useMemo(() => {
    let items: any[] = [];
    tiers.forEach((a) => {
      items.push({
        key: a.TierId,
        text: "Tier " + a.Name,
      });
    });
    items.push({
      key: 4,
      text: "Non-marketable",
    });
    return items;
  }, [tiers]);

  const modernWorkSecurityEventCalendarMomentList = useSelector<
    RootState,
    modernWorkSecurityEventCalendarMoment[]
  >(
    (state: RootState) =>
      state.modernWorkSecurityEventCalendarMomentReducer
        .modernWorkSecurityEventCalendarMomentListForDetail
  );
  let momentInitValues: { key: number; text: string }[] = [];

  momentInitValues = modernWorkSecurityEventCalendarMomentList.map((m) => {
    return { key: m.Id, text: m.DisplayTextWithYearAndTBD };
  });

  const modernWorkFeature = useSelector<
    RootState,
    featureReducer.initialStateProps
  >((state) => state.modernWorkFeatureReducer);

  useEffect(() => {
    if (
      modernWorkFeature.modernWorkFeature.__isLoading ===
      _LOADINGSTATE.fullfilled
    ) {
      appDispatch(
        getModernWorkSecurityEventCalendarMomentListForDetail({
          timelineType: TimelineType.MW,
          date: new Date(
            moment(new Date()).add(-1, "months").format("MM/DD/YYYY")
          ),
        })
      );
    }
  }, [modernWorkFeature.modernWorkFeature.__isLoading]);

  useEffect(() => {
    if (
      modernWorkFeature.modernWorkFeature
        .ModernWorkSecurityEventCalendarMomentsId &&
      modernWorkSecurityEventCalendarMomentList.findIndex((l) => {
        return (
          l.Id ===
          modernWorkFeature.modernWorkFeature
            .ModernWorkSecurityEventCalendarMomentsId
        );
      }) === -1
    ) {
      appDispatch(
        getModernWorkSecurityEventCalendarMomentById(
          modernWorkFeature.modernWorkFeature
            .ModernWorkSecurityEventCalendarMomentsId
        )
      );
    }
  }, [modernWorkSecurityEventCalendarMomentList]);
  const [previewDelayed, setPreviewDelayed] = useState(false);
  const [gaDelayed, setGADelayed] = useState<Boolean | undefined>(false);
  const isDelayedOneMonth = (newDate: Date, oldDate: Date) => {
    let newDateMonth = newDate.getUTCMonth();
    let newDateYear = newDate.getUTCFullYear();

    let oldDateMonth = oldDate.getUTCMonth();
    let oldDateYear = oldDate.getUTCFullYear();

    return newDateMonth > oldDateMonth || newDateYear > oldDateYear;
  };
  useEffect(() => {
    if (modernWorkFeatureHistory.length > 0) {
      let nowMonth = moment.utc().month();
      let gaDelayed = false;
      let previewDelayed = false;
      let lastGADate: Date | null = null;
      let lastPreviewDate: Date | undefined = undefined;

      let currentGADate = moment(modernWorkFeature.modernWorkFeature.GADate);
      let currentPreviewDate = moment(
        modernWorkFeature.modernWorkFeature.PreviewDate
      );
      let histories = [...modernWorkFeatureHistory];
      histories.sort((p, b) => {
        if (p.modifiedTime !== null && b.modifiedTime !== null) {
          return p.modifiedTime > b.modifiedTime ? 1 : -1;
        }
        return 1;
      })
        .forEach((history) => {
          if (
            history.gADate != null &&
            (history.gADate.getUTCMonth() < currentGADate.utc().month() ||
              history.gADate.getUTCFullYear() < currentGADate.utc().year())
          ) {
            gaDelayed = true;
          }

          if (
            history.previewDate != null &&
            (history.previewDate.getUTCMonth() <
              currentPreviewDate.utc().month() ||
              history.previewDate.getUTCFullYear() <
                currentPreviewDate.utc().year())
          ) {
            previewDelayed = true;
          }
          //let modifiedTime: moment.Moment = moment(history.modifiedTime);
          //let nowDate = new Date(Date.now());
          //if (history.gADate != null && gaDelayed === false)
          //{
          //  if (lastGADate == null) {
          //    gaDelayed = false;
          //  }
          //  else {
          //    gaDelayed = isDelayedOneMonth(history.gADate, lastGADate);
          //  }
          //  lastGADate = history.gADate;
          //  if (modifiedTime.month() < nowDate.getUTCMonth()) {
          //    gaDelayed = false;
          //  }
          //}

          //if (history.previewDate != null && previewDelayed === false) {
          //  if (lastPreviewDate == null) {
          //    previewDelayed = false;
          //  }
          //  else {
          //    previewDelayed = isDelayedOneMonth(history.previewDate, lastPreviewDate);
          //  }
          //  lastPreviewDate = history.previewDate
          //  if (modifiedTime.month() < nowDate.getUTCMonth()) {
          //    previewDelayed = false;
          //  }
          //}
        });

      setGADelayed(gaDelayed);
      setPreviewDelayed(previewDelayed);
    }
  }, [modernWorkFeatureHistory]);
  const modernWorkSecurityEventCalendarMomentForDetail = useSelector<
    RootState,
    modernWorkSecurityEventCalendarMoment | null
  >(
    (state: RootState) =>
      state.modernWorkSecurityEventCalendarMomentReducer
        .modernWorkSecurityEventCalendarMoment
  );

  if (
    modernWorkSecurityEventCalendarMomentForDetail &&
    modernWorkSecurityEventCalendarMomentForDetail.Id ===
      modernWorkFeature.modernWorkFeature
        .ModernWorkSecurityEventCalendarMomentsId
  ) {
    momentInitValues.unshift({
      key: modernWorkSecurityEventCalendarMomentForDetail.Id,
      text: modernWorkSecurityEventCalendarMomentForDetail.DisplayTextWithYearAndTBD,
    });
  }

  momentInitValues.unshift({ key: 0, text: "None" });
  const tooltips = useSelector<RootState, { [key: string]: string }>(
    (state) => state.tooltipReducer.tooltips
  );

  const [query, setQuery] = useSearchParams();
  useEffect(() => {
    if (Object.keys(tooltips).length === 0) {
      appDispatch(tooltipReducer.getTooltip());
    }
    if (
      modernWorkFeatureProductAreas &&
      modernWorkFeatureProductAreas.length === 0
    ) {
      appDispatch(
        modernWorkFeatureProductAreaReducer.getModernWorkFeatureProductAreas()
      );
    }
    if (triageTags && triageTags.length === 0) {
      appDispatch(triageTagReducer.getTriageTag());
    }
    if (tiers && tiers.length === 0) {
      appDispatch(tierReducer.getMilestoneTiers());
    }

    if (!query.has("featureId")) {
      query.append(
        "featureId",
        modernWorkFeatureModalContext.modernWorkFeatureModalState.featureId.toString()
      );
    }
    setQuery(query);
  }, []);

  useEffect(() => {
    if (
      ((splitStringToArray(
        modernWorkFeature.modernWorkFeature.Product.toLocaleLowerCase()
      ).indexOf("microsoft teams") === -1 &&
        splitStringToArray(
          modernWorkFeature.modernWorkFeature.Product.toLocaleLowerCase()
        ).indexOf("microsoft viva") === -1) ||
        modernWorkFeature.modernWorkFeature.ModernWorkFeatureProductAreaId) &&
      modernWorkFeature.modernWorkFeature.TierId &&
      (modernWorkFeature.modernWorkFeature.TierId === 3 ||
        modernWorkFeature.modernWorkFeature.TierId === 4 ||
        (modernWorkFeature.modernWorkFeature.PublicDisclosureDate &&
          modernWorkFeature.modernWorkFeature.PublicDisclosureDate.length > 0 &&
          formatDate(
            modernWorkFeature.modernWorkFeature.PublicDisclosureDate
          ) !== "01/01/0001"))
    ) {
      appDispatch(featureReducer.actions.changeIsFormValid(true));
    } else {
      appDispatch(featureReducer.actions.changeIsFormValid(false));
    }
    setTriageTagsSelectOptions(
      triageTags.map((a) => {
        return {
          key: a.Id,
          text: a.Name,
          isSelected:
            modernWorkFeature.modernWorkFeature.TriageTagIds != null &&
            modernWorkFeature.modernWorkFeature.TriageTagIds?.indexOf(a.Id) !=
              -1,
        };
      })
    );
  }, [
    modernWorkFeature.modernWorkFeature.ModernWorkFeatureProductAreaId,
    modernWorkFeature.modernWorkFeature.FeatureTheme,
    modernWorkFeature.modernWorkFeature.TierId,
    modernWorkFeature.modernWorkFeature
      .ModernWorkSecurityEventCalendarMomentsId,
    modernWorkFeature.modernWorkFeature.MarketingTitle,
    modernWorkFeature.modernWorkFeature.MarketingDescription,
    modernWorkFeature.modernWorkFeature.TriageTagId,
    modernWorkFeature.modernWorkFeature.PublicDisclosureDate,
    modernWorkFeature.modernWorkFeature.TriageDescription,
    modernWorkFeature.modernWorkFeature.PMGContact,
    modernWorkFeature.modernWorkFeature.PMGContactEmail,
    modernWorkFeature.modernWorkFeature.TriageTagIds,
  ]);

  const considerSubmitted = (condition: boolean) => {
    //we want error message to show after user click save edits button
    return props.appearErrorInfo && condition;
  };

  const [tooltipIds, setmarketingDesTooltipId] = useState({
    desId: useId("tooltip"),
    themeId: useId("tooltip"),
    commentId: useId("tooltip"),
  });

  const formatGroupLabel = (data: GroupBase<OptionType>) => (
    <div style={{ fontWeight: "bold", color: "#0078D4", fontSize: "14px" }}>
      <span>{data.label}</span>
    </div>
  );

  const InitTags = triageTags.map((a) => {
    return {
      key: a.Id,
      text: a.Name,
      isSelected:
        modernWorkFeature.modernWorkFeature.TriageTagIds != null &&
        modernWorkFeature.modernWorkFeature.TriageTagIds?.indexOf(a.Id) != -1,
    };
  });

  const [triageTagsSelectOptions, setTriageTagsSelectOptions] =
    useState(InitTags);

  const findValue = () => {
    let findOption: any = null;
    modernWorkFeatureProductAreasSelectOptions.forEach((group: any) => {
      if (findOption !== null && findOption !== undefined) return;

      findOption = group.options.find((option: any) => {
        return (
          option.value ===
          modernWorkFeature.modernWorkFeature.ModernWorkFeatureProductAreaId?.toString()
        );
      });
      if (findOption !== null && findOption !== undefined) {
        return findOption;
      }
    });
    return findOption;
  };

  const getFeedItems = (queryText: string) => {
    return new Promise((resolve) => {
      appDispatch(
        getGraphUsers({
          filter: queryText,
          select: "",
          top: "10",
          microsoftUser: false,
        })
      ).then((response) => {
        var users = response.payload as GraphUser[];
        const itemsToDisplay = users.map((u) => {
          return {
            id: "@" + u.DisplayName,
            mail: u.Mail,
          };
        });
        resolve(itemsToDisplay);
      });
    });
  };

  const MentionCustomization = (editor: any) => {
    editor.conversion.for("upcast").elementToAttribute({
      view: {
        name: "span",
        key: "data-mention",
        classes: "mention",
        attributes: {
          "data-mail": true,
        },
      },
      model: {
        key: "mention",
        value: (viewItem: any) => {
          const mentionAttribute = editor.plugins
            .get("Mention")
            .toMentionAttribute(viewItem, {
              mail: viewItem.getAttribute("data-mail"),
            });
          return mentionAttribute;
        },
      },
      converterPriority: "high",
    });

    editor.conversion.for("downcast").attributeToElement({
      model: "mention",
      view: (modelAttributeValue: any, { writer }: any) => {
        if (!modelAttributeValue) {
          return;
        }
        return writer.createAttributeElement(
          "span",
          {
            class: "mention",
            "data-mention": modelAttributeValue.id,
            "data-mail": modelAttributeValue.mail,
          },
          {
            priority: 20,
            id: modelAttributeValue.uid,
          }
        );
      },
      converterPriority: "high",
    });
  };

  const customItemRenderer = (item: any) => {
    const itemElement = document.createElement("span");

    itemElement.classList.add("custom-item");
    itemElement.style.display = "inline-block";
    itemElement.style.padding = "4px 10px";
    itemElement.textContent = item.id;
    itemElement.appendChild(document.createElement("br"));
    itemElement.append(item.mail);

    return itemElement;
  };

  return (
    <div className="internal-detail">
      <div className="row">
        <div className="col-12">
          <div
            className="modal-detail-label"
            style={{ marginTop: 0, marginBottom: 0 }}
          >
            <label style={{ font: "normal normal bold 18px/44px Segoe UI" }}>
              Feature details
            </label>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-3">
          <div className="modal-detail-label" style={{ marginTop: 0 }}>
            <label>
              Product area
              {splitStringToArray(
                modernWorkFeature.modernWorkFeature.Product.toLocaleLowerCase()
              ).indexOf("microsoft teams") !== -1 ||
              splitStringToArray(
                modernWorkFeature.modernWorkFeature.Product.toLocaleLowerCase()
              ).indexOf("microsoft viva") !== -1
                ? " *"
                : ""}
            </label>
          </div>
          <Select
            styles={
              considerSubmitted(
                (splitStringToArray(
                  modernWorkFeature.modernWorkFeature.Product.toLocaleLowerCase()
                ).indexOf("microsoft teams") !== -1 ||
                  splitStringToArray(
                    modernWorkFeature.modernWorkFeature.Product.toLocaleLowerCase()
                  ).indexOf("microsoft viva") !== -1) &&
                  !modernWorkFeature.modernWorkFeature
                    .ModernWorkFeatureProductAreaId
              )
                ? { ...reactselectSingleErrorStyleProps }
                : { ...reactselectSingleStyleProps }
            }
            aria-label="Product area"
            isDisabled={props.disabled}
            isClearable={true}
            isSearchable={true}
            options={modernWorkFeatureProductAreasSelectOptions}
            value={findValue()}
            ariaLiveMessages={{
              onFocus: (e) => {
                return `Option, Under ${e.focused.group}, ${e.label} ,${
                  e.isSelected ? "Selected" : "Non-Selected"
                }`;
              },
            }}
            onChange={(
              value: OnChangeValue<OptionType, false>,
              actionMeta: ActionMeta<OptionType>
            ) => {
              let modernWorkFeatureProductAreaId = null;
              switch (actionMeta.action) {
                case "clear":
                  modernWorkFeatureProductAreaId = null;
                  break;
                case "select-option":
                  if (value) {
                    modernWorkFeatureProductAreaId = parseInt(value.value);
                  }
                  break;
              }

              appDispatch(
                featureReducer.actions.changeProductAreaId(
                  modernWorkFeatureProductAreaId
                )
              );
              props.setDetailFormChanged(true);
            }}
            placeholder="Select an option"
            className="react-select-container"
            classNamePrefix="react-select"
            formatGroupLabel={formatGroupLabel}
          />
          <div className="modal-detail-error">
            {considerSubmitted(
              (splitStringToArray(
                modernWorkFeature.modernWorkFeature.Product.toLocaleLowerCase()
              ).indexOf("microsoft teams") !== -1 ||
                splitStringToArray(
                  modernWorkFeature.modernWorkFeature.Product.toLocaleLowerCase()
                ).indexOf("microsoft viva") !== -1) &&
                !modernWorkFeature.modernWorkFeature
                  .ModernWorkFeatureProductAreaId
            )
              ? "Product area is required."
              : ""}
          </div>
        </div>
        <div className="col-3">
          <Tooltip
            dynamicContent={tooltips["FeatureTheme"] || ""}
            hostId={tooltipIds.themeId}
          >
            <>
              <div
                className="modal-detail-label"
                style={{ marginTop: 0 }}
                aria-describedby={tooltipIds.themeId}
                tabIndex={0}
              >
                <label aria-describedby={tooltipIds.themeId}>
                  Feature theme
                </label>
              </div>
              <TextField
                aria-describedby={tooltipIds.themeId}
                value={modernWorkFeature.modernWorkFeature.FeatureTheme}
                onChange={(event: any) => {
                  appDispatch(
                    featureReducer.actions.changeFeatureTheme(
                      event.currentTarget.value
                    )
                  );
                  props.setDetailFormChanged(true);
                }}
                styles={textfieldStylesProps}
                ariaLabel="Feature theme"
                aria-labelledby="Feature theme"
                disabled={props.disabled}
                placeholder="Type here"
              />
            </>
          </Tooltip>
        </div>
        <div className="col-3">
          <Tooltip
            dynamicContent={tooltips["ModernWorkTierId"] || ""}
            hostId={"FeaturetierTooltip"}
          >
            <>
              <label
                className="modal-detail-label"
                style={{ marginTop: 0 }}
                tabIndex={0}
                aria-describedby={"FeaturetierTooltip"}
              >
                Feature tier
                <label style={{ fontFamily: "serif" }}>&ensp;{"*"}&ensp;</label>
                <a
                  aria-describedby={"FeaturetierTooltip"}
                  href="https://aka.ms/cmtieringframework"
                  target="_blank"
                  aria-label="Feature Tier guidelines"
                >
                  (guidelines)
                </a>
              </label>
              <Dropdown
                aria-describedby={"FeaturetierTooltip"}
                aria-required={true}
                options={tiersOptions}
                id="internalFeatureTier"
                selectedKey={modernWorkFeature.modernWorkFeature.TierId}
                placeholder="Select an option"
                onChange={(
                  event: React.FormEvent<HTMLDivElement>,
                  item?: IDropdownOption
                ) => {
                  appDispatch(
                    featureReducer.actions.changeTierId(
                      item ? Number(item?.key) : null
                    )
                  );
                  props.setDetailFormChanged(true);
                }}
                errorMessage={
                  considerSubmitted(
                    !modernWorkFeature.modernWorkFeature.TierId ||
                      modernWorkFeature.modernWorkFeature.TierId === 0
                  )
                    ? "Feature tier is required."
                    : ""
                }
                ariaLabel="Feature tier"
                styles={dropdownStylesProps}
                disabled={props.disabled}
              />
            </>
          </Tooltip>
        </div>
        <div className="col-3">
          <label className="modal-detail-label" style={{ marginTop: 0 }}>
            Moment alignment |{" "}
            <a
              href="https://microsoft.sharepoint.com/teams/CloudMarketingMoments/SitePages/Cloud-Marketing-moments-calendar-DRIs.aspx"
              aria-label="More info of moment alignment"
              target="_blank"
            >
              More info
            </a>
          </label>
          <Dropdown
            label=""
            disabled={
              modernWorkFeature.modernWorkFeature.TierId === undefined ||
              modernWorkFeature.modernWorkFeature.TierId === null ||
              modernWorkFeature.modernWorkFeature.TierId <= 0 ||
              props.disabled
            }
            options={momentInitValues}
            id="internalTaggedMoment"
            selectedKey={
              modernWorkFeature.modernWorkFeature
                .ModernWorkSecurityEventCalendarMomentsId
            }
            placeholder="None"
            onChange={(
              event: React.FormEvent<HTMLDivElement>,
              item?: IDropdownOption
            ) => {
              let momentId = item ? Number(item?.key) : null;
              if (momentId !== null && momentId === 0) {
                momentId = null;
              }
              appDispatch(featureReducer.actions.changeMomentId(momentId));

              if (
                momentId &&
                modernWorkSecurityEventCalendarMomentList.findIndex((l) => {
                  return l.Id === momentId;
                }) !== -1
              ) {
                appDispatch(
                  featureReducer.actions.changePublicDisclosureDate(
                    new Date(
                      moment(
                        new Date(
                          modernWorkSecurityEventCalendarMomentList.filter(
                            (l) => {
                              return l.Id === momentId;
                            }
                          )[0].StartDate
                        )
                      )
                        .utc()
                        .format("MM/DD/YYYY")
                    ).toISOString()
                  )
                );
              }

              props.setDetailFormChanged(true);
            }}
            onRenderOption={(
              option: IDropdownOption | undefined
            ): JSX.Element => {
              return option !== undefined ? (
                <span
                  className="ms-Dropdown-optionText"
                  style={{
                    overflow: `hidden`,
                    whiteSpace: `nowrap`,
                    textOverflow: `ellipsis`,
                    maxWidth: `100%`,
                    overflowWrap: `break-word`,
                    margin: 1,
                  }}
                  title={option?.text || ""}
                  dangerouslySetInnerHTML={{
                    __html: option?.text || "",
                  }}
                ></span>
              ) : (
                <></>
              );
            }}
            onRenderTitle={(
              options: IDropdownOption[] | undefined
            ): JSX.Element => {
              const option = options ? options[0] : undefined;
              return (
                <span
                  title={option?.text}
                  dangerouslySetInnerHTML={{ __html: option?.text || "" }}
                ></span>
              );
            }}
            errorMessage={""}
            ariaLabel="Moment alignment"
            styles={dropdownStylesProps}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <Tooltip
            dynamicContent={tooltips["MarketingTitle"] || ""}
            hostId={"MarketingTitleTooltip"}
          >
            <label
              className="modal-detail-label"
              aria-describedby={"MarketingTitleTooltip"}
              tabIndex={0}
            >
              Marketing title{" "}
              <a
                aria-describedby={"MarketingTitleTooltip"}
                href="https://microsoft.sharepoint.com/teams/Microsoft365RoadmapPMG/Shared%20Documents/Forms/AllItems.aspx?id=%2fteams%2fMicrosoft365RoadmapPMG%2fShared%20Documents%2fGeneral%2fTools%20and%20Processes%2fRoadmap%20Comms%2fGuidelines%20for%20writing%20feature%20titles%20and%20descriptions%205.4.22%20.pdf&parent=%2fteams%2fMicrosoft365RoadmapPMG%2fShared%20Documents%2fGeneral%2fTools%20and%20Processes%2fRoadmap%20Comms&p=true&ct=1651869112554&or=Outlook-Body&cid=149DB2E4-B468-430F-AAD2-4C45BF48E35D&ga=1"
                target="_blank"
                aria-label="Marketing Title guidelines"
              >
                (guidelines)
              </a>
            </label>
          </Tooltip>
          <Tooltip content="Check this box if you have reviewed and approved the Marketing Title">
            <Checkbox
              disabled={props.disabled}
              styles={{
                root: { display: "inline-block", marginLeft: "5px" },
              }}
              checked={
                modernWorkFeature.modernWorkFeature.MarketingTitleApproved
              }
              onChange={(ev, checked) => {
                appDispatch(
                  featureReducer.actions.changeMarketingTitleApproved(
                    checked ?? false
                  )
                );
                props.setDetailFormChanged(true);
              }}
            ></Checkbox>
          </Tooltip>
          <label style={{ fontSize: "9px", fontWeight: "bold", color: "red" }}>
            Check this box if you have reviewed and approved the Marketing Title
          </label>
          <TextField
            value={modernWorkFeature.modernWorkFeature.MarketingTitle}
            onChange={(event: any) => {
              appDispatch(
                featureReducer.actions.changeMarketingTitle(
                  event.currentTarget.value
                )
              );
              appDispatch(
                featureReducer.actions.changeMarketingTitleApproved(false)
              );
              props.setDetailFormChanged(true);
            }}
            styles={textfieldStylesProps}
            ariaLabel="Marketing title"
            aria-labelledby="Marketing title"
            disabled={props.disabled}
            placeholder="Type here"
          />
        </div>
        <div className="col-3">
          <GraphUsersAutoCompleteWithTeamsIcon
            label="PMM"
            error=""
            value={
              modernWorkFeature.modernWorkFeature.PMGContactEmail
                ? modernWorkFeature.modernWorkFeature.PMGContact +
                  " [" +
                  modernWorkFeature.modernWorkFeature.PMGContactEmail +
                  "]"
                : modernWorkFeature.modernWorkFeature.PMGContact
            }
            onChangeHandler={(value) => {
              appDispatch(
                featureReducer.actions.changePMGContact(
                  getNamePart(value?.value || "")
                )
              );
              appDispatch(
                featureReducer.actions.changePMGContactEmail(
                  getEmailPart(value?.value || "")
                )
              );
              props.setDetailFormChanged(true);
            }}
            microsoftUser={true}
            id="modernWorkSecurityMSETSFeatureForm_PMM"
            disabled={props.disabled}
          />
        </div>
        <div className="col-3">
          <label className="modal-detail-label"> Marketing tags </label>
          <Dropdown
            placeholder="Select an option"
            multiSelect={true}
            options={triageTagsSelectOptions}
            defaultSelectedKeys={triageTagsSelectOptions
              .filter((v) => v.isSelected)
              .map((p) => p.key)}
            onChange={(event: any, item: any, index?: number) => {
              if (item && index != null && index >= 0) {
                triageTagsSelectOptions[index].isSelected =
                  !triageTagsSelectOptions[index].isSelected;
                appDispatch(
                  featureReducer.actions.changeTiageTagId(
                    triageTagsSelectOptions
                      .filter((p) => p.isSelected)
                      .map((p) => p.key)
                  )
                );
                props.setDetailFormChanged(true);
              }
            }}
            styles={dropdownStyles}
            disabled={props.disabled}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <Tooltip
            dynamicContent={tooltips["MarketingDescription"] || ""}
            hostId={tooltipIds.desId}
          >
            <label
              className="modal-detail-label"
              tabIndex={0}
              aria-describedby={tooltipIds.desId}
            >
              Marketing description{" "}
              <a
                aria-describedby={tooltipIds.desId}
                tabIndex={0}
                href="https://microsoft.sharepoint.com/teams/Microsoft365RoadmapPMG/Shared%20Documents/Forms/AllItems.aspx?id=%2fteams%2fMicrosoft365RoadmapPMG%2fShared%20Documents%2fGeneral%2fTools%20and%20Processes%2fRoadmap%20Comms%2fGuidelines%20for%20writing%20feature%20titles%20and%20descriptions%205.4.22%20.pdf&parent=%2fteams%2fMicrosoft365RoadmapPMG%2fShared%20Documents%2fGeneral%2fTools%20and%20Processes%2fRoadmap%20Comms&p=true&ct=1651869112554&or=Outlook-Body&cid=149DB2E4-B468-430F-AAD2-4C45BF48E35D&ga=1"
                target="_blank"
                aria-label="Marketing description guidelines"
              >
                (guidelines)
              </a>
            </label>
          </Tooltip>
          <Tooltip content="Check this box if you have reviewed and approved the Marketing Description">
            <Checkbox
              disabled={props.disabled}
              styles={{
                root: { display: "inline-block", marginLeft: "5px" },
              }}
              checked={
                modernWorkFeature.modernWorkFeature.MarketingDescriptionApproved
              }
              onChange={(ev, checked) => {
                appDispatch(
                  featureReducer.actions.changeMarketingDescriptionApproved(
                    checked ?? false
                  )
                );
                props.setDetailFormChanged(true);
              }}
            ></Checkbox>
          </Tooltip>
          <label style={{ fontSize: "9px", fontWeight: "bold", color: "red" }}>
            Check this box if you have reviewed and approved the Marketing
            Description
          </label>
          <TextField
            value={modernWorkFeature.modernWorkFeature.MarketingDescription}
            onChange={(event: any) => {
              appDispatch(
                featureReducer.actions.changeMarketingDescription(
                  event.currentTarget.value
                )
              );
              appDispatch(
                featureReducer.actions.changeMarketingDescriptionApproved(false)
              );
              props.setDetailFormChanged(true);
            }}
            styles={textfieldStylesProps}
            multiline
            rows={2}
            ariaLabel="Marketing description"
            aria-labelledby="Marketing description"
            disabled={props.disabled}
            placeholder="Type here"
          />
        </div>
      </div>
      <div className="row">
        <div className="col-3">
          <Tooltip
            dynamicContent={tooltips["ModernWorkFeaturePublicDisclosure"] || ""}
            hostId={"PublicdisclosureTooltip"}
          >
            <DatePickerWithLabelAndError
              describedby={"PublicdisclosureTooltip"}
              label={
                !!modernWorkFeature.modernWorkFeature.TierId &&
                (modernWorkFeature.modernWorkFeature.TierId === 1 ||
                  modernWorkFeature.modernWorkFeature.TierId === 2)
                  ? "Public disclosure *"
                  : "Public disclosure"
              }
              id="PublicDisclosureDate"
              value={
                modernWorkFeature.modernWorkFeature.PublicDisclosureDate
                  ? formatDate(
                      modernWorkFeature.modernWorkFeature.PublicDisclosureDate
                    ) === "01/01/0001"
                    ? undefined
                    : transferToUtcDate(
                        new Date(
                          modernWorkFeature.modernWorkFeature.PublicDisclosureDate
                        )
                      )
                  : undefined
              }
              onSelectDate={(date: Date | undefined | null) => {
                if (date && date != null) {
                  appDispatch(
                    featureReducer.actions.changePublicDisclosureDate(
                      new Date(
                        convertDateTimeToFormatedDateString(date)
                      ).toISOString()
                    )
                  );
                } else {
                  appDispatch(
                    featureReducer.actions.changePublicDisclosureDate(null)
                  );
                }
                props.setDetailFormChanged(true);
              }}
              error={
                considerSubmitted(
                  !!modernWorkFeature.modernWorkFeature.TierId &&
                    (modernWorkFeature.modernWorkFeature.TierId === 1 ||
                      modernWorkFeature.modernWorkFeature.TierId === 2) &&
                    !(
                      modernWorkFeature.modernWorkFeature
                        .PublicDisclosureDate !== null &&
                      modernWorkFeature.modernWorkFeature.PublicDisclosureDate
                        .length > 0 &&
                      formatDate(
                        modernWorkFeature.modernWorkFeature.PublicDisclosureDate
                      ) !== "01/01/0001"
                    )
                )
                  ? "Public disclosure date is required."
                  : ""
              }
              disabled={props.disabled}
              timelineType={TimelineType.MW}
            />
          </Tooltip>
        </div>
        <div className="col-3">
          <TextField
            onRenderLabel={(e) => {
              return (
                <>
                  <label className="ms-Label">{e?.label}</label>
                  <Icon
                    iconName="Flag"
                    className="delayedIcon"
                    hidden={!previewDelayed}
                  />
                </>
              );
            }}
            label="Preview"
            value={formatCYDate(
              modernWorkFeature.modernWorkFeature.PreviewDate
            )}
            styles={textfieldStylesProps}
            ariaLabel="Preview"
            aria-labelledby="Preview"
            disabled={true}
          />
        </div>
        <div className="col-3">
          <Tooltip
            dynamicContent={tooltips["ModernWorkFeatureGA"] || ""}
            hostId={"GATooltip"}
          >
            <TextField
              aria-describedby={"GATooltip"}
              onRenderLabel={(e) => {
                return (
                  <>
                    <label className="ms-Label">{e?.label}</label>
                    <Icon
                      iconName="Flag"
                      className="delayedIcon"
                      hidden={!gaDelayed}
                    />
                  </>
                );
              }}
              label="GA"
              value={formatCYDate(modernWorkFeature.modernWorkFeature.GADate)}
              styles={textfieldStylesProps}
              ariaLabel="GA"
              aria-labelledby="GA"
              disabled={true}
            />
          </Tooltip>
        </div>
        <div className="col-3">
          <Tooltip
            hostId={"PlannedProduction"}
            dynamicContent={
              tooltips["ModernWorkFeaturePlannedProduction"] || ""
            }
          >
            <TextField
              aria-describedby={"PlannedProduction"}
              label="Planned production"
              value={formatDate(
                modernWorkFeature.modernWorkFeature.PlannedProductionDate
              )}
              styles={textfieldStylesProps}
              ariaLabel="Planned production"
              aria-labelledby="Planned production"
              disabled={true}
            />
          </Tooltip>
        </div>
      </div>
      <div className="row feature-comments">
        <div>
          <Tooltip
            dynamicContent={tooltips["ModernWorkFeatureCommentsMention"] || ""}
            hostId={tooltipIds.commentId}
          >
            <label
              className="modal-detail-label"
              htmlFor={"Comments"}
              aria-describedby={tooltipIds.commentId}
            >
              Comments/@mention
            </label>
          </Tooltip>
          <CKEditor
            editor={ClassicEditor}
            onReady={(editor: any) => {
              MentionCustomization(editor);
            }}
            config={{
              width: "100%",
              toolbar: [],
              mention: {
                dropdownLimit: 10,
                feeds: [
                  {
                    marker: "@",
                    feed: getFeedItems,
                    itemRenderer: customItemRenderer,
                    minimumCharacters: 1,
                  },
                ],
              },
            }}
            onChange={(event: any, editor: any) => {
              const data = editor.getData();
              appDispatch(
                featureReducer.actions.changeTriageDescription(data ?? "")
              );
            }}
            data={modernWorkFeature.modernWorkFeature.TriageDescription ?? ""}
            id={"Comments"}
            name={"Comments"}
            disabled={props.disabled}
            onBlur={(event: any, editor: any) => {
              props.setDetailFormChanged(true);
            }}
          />
        </div>
      </div>
      <div className="row">
        <div className="my-4">
          <LastModifiedBy
            modifiedBy={
              isVoid(modernWorkFeature.modernWorkFeature.ModifiedBy)
                ? undefined
                : modernWorkFeature.modernWorkFeature.ModifiedBy +
                  " [" +
                  modernWorkFeature.modernWorkFeature.ModifiedByEmail +
                  "]"
            }
            modifiedAt={
              modernWorkFeature.modernWorkFeature.Modified
                ? moment(
                    new Date(modernWorkFeature.modernWorkFeature.Modified)
                  ).format("MM/DD/YYYY h:mm:ss a")
                : ""
            }
          />
          <DefaultButton
            disabled={
              modernWorkFeature.modernWorkFeature.__isLoading ===
                _LOADINGSTATE.pending || props.disabled
            }
            text="Save"
            ariaDescription="Save"
            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
              event.preventDefault();
              props.setAppearErrorInfo(true);

              if (modernWorkFeature.modernWorkFeature.__isFormValid) {
                if (
                  modernWorkFeatureModalContext.modernWorkFeatureModalState
                    .deleteFeatureHistoryId != ""
                ) {
                  props.setAppearErrorInfo(true);
                  appDispatch(
                    modernWorkFeatureHistoryReducer.deleteModernWorkFeatureHistory(
                      modernWorkFeatureModalContext.modernWorkFeatureModalState.deleteFeatureHistoryId
                        .split(",")
                        .filter((id) => {
                          return id != "";
                        })
                        .map((id) => {
                          return parseInt(id);
                        })
                    )
                  )
                    .then(() => {
                      if (props.detailFormChanged) {
                        appDispatch(
                          featureReducer.updateFeature(
                            modernWorkFeature.modernWorkFeature
                          )
                        )
                          .then(unwrapResult)
                          .then(() => {
                            props.setSubmited(true);
                            SuccessMessage.show(
                              "The feature content has been saved successfully."
                            );
                            props.setDetailFormChanged(false);
                            props.setAppearErrorInfo(false);

                            modernWorkFeatureModalContext.modalRef?.current?.click();
                          })
                          .catch(() => {
                            props.setAppearErrorInfo(false);
                          });
                      } else {
                        props.setAppearErrorInfo(false);

                        modernWorkFeatureModalContext.modalRef?.current?.click();
                      }
                    })
                    .catch(() => {
                      props.setAppearErrorInfo(false);
                    });
                } else {
                  if (props.detailFormChanged) {
                    props.setAppearErrorInfo(true);
                    appDispatch(
                      featureReducer.updateFeature(
                        modernWorkFeature.modernWorkFeature
                      )
                    )
                      .then(unwrapResult)
                      .then(() => {
                        props.setSubmited(true);
                        SuccessMessage.show(
                          "The feature content has been saved successfully."
                        );
                        props.setDetailFormChanged(false);
                        props.setAppearErrorInfo(false);

                        const featureTitle = !isVoid(
                          modernWorkFeature.modernWorkFeature.MarketingTitle
                        )
                          ? modernWorkFeature.modernWorkFeature.MarketingTitle
                          : !isVoid(
                              modernWorkFeature.modernWorkFeature.PublicTitle
                            )
                          ? modernWorkFeature.modernWorkFeature.PublicTitle
                          : !isVoid(modernWorkFeature.modernWorkFeature.Title)
                          ? modernWorkFeature.modernWorkFeature.Title
                          : "";

                        modernWorkFeatureModalContext.setModernWorkFeatureStateHandler(
                          {
                            ...modernWorkFeatureModalContext.modernWorkFeatureModalState,
                            featureTitle: featureTitle,
                          }
                        );

                        modernWorkFeatureModalContext.modalRef?.current?.click();
                      })
                      .catch(() => {
                        props.setAppearErrorInfo(false);
                      });
                  }
                }
              } else {
                if (
                  modernWorkFeature.modernWorkFeature.TierId === null ||
                  modernWorkFeature.modernWorkFeature.TierId === undefined
                ) {
                  (
                    document.body.querySelector(
                      "#internalFeatureTier"
                    ) as HTMLElement
                  ).focus();
                } else if (
                  modernWorkFeature.modernWorkFeature.PublicDisclosureDate ===
                    null ||
                  modernWorkFeature.modernWorkFeature.PublicDisclosureDate ===
                    undefined
                ) {
                  (
                    document.body.querySelector(
                      "#PublicDisclosureDate-label"
                    ) as HTMLElement
                  ).focus();
                }
              }
              event.stopPropagation();
            }}
          />
          <span
            style={{
              display: "inline-block",
              width: 30,
              height: 19,
            }}
          >
            {modernWorkFeature.modernWorkFeature.__isLoading ===
            _LOADINGSTATE.pending ? (
              <Spinner
                size={SpinnerSize.large}
                style={{
                  display: "inline",
                  marginLeft: "10px",
                  position: "absolute",
                }}
              />
            ) : (
              <></>
            )}
          </span>
          <span className="modal-detail-error mx-4">
            Be sure to click Save before leaving this window.
          </span>
          <p className="fw-bold mt-1">
            Submit marketing channel requests (i.e., blog, social, digital
            event, web updates, paid media, etc.) using the{" "}
            <a href="https://aka.ms/BADEIntake" target="_blank">
              marketing work intake
            </a>{" "}
            tool.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Details;
