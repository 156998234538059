import { TooltipHost, DirectionalHint } from "@fluentui/react";

const Tooltip = (props: {
  content?: string;
  tooltipProps?: {};
  children?: JSX.Element;
  dynamicContent?: string;
  hostId?: string;
}) => {
  const tooltipId = props.hostId;
  var parser = new DOMParser();
  var doc = parser.parseFromString(props.dynamicContent ?? "", "text/html");
  return (
    <TooltipHost
      content={
        props.content ? props.content : doc.documentElement.textContent ?? ""
      }
      id={tooltipId}
      role={"tooltip"}
      tooltipProps={
        props.tooltipProps
          ? props.tooltipProps
          : props.dynamicContent
          ? {
              onRenderContent: () => {
                return (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: props.dynamicContent || "",
                    }}
                  ></div>
                );
              },
            }
          : undefined
      }
      closeDelay={500}
      calloutProps={{ directionalHint: DirectionalHint.rightCenter }}
    >
      {props.children}
    </TooltipHost>
  );
};

export default Tooltip;
