import { useContext, useState } from "react";
import { DatePicker } from "@fluentui/react";
import { IconButton, PrimaryButton } from "@fluentui/react/lib/Button";
import { useBoolean } from "@fluentui/react-hooks";
import "./index.scss";
import { buttonStyles } from "../../Shared/styleProps";
import * as CalendarUpdateReducer from "../../../slice/CalendarSlices/calendarUpdate";
import {
  getFirstDayInTheMonth,
  getLastDayInTheMonth,
  getNextMonthDate,
} from "../../../utils/date";
import { CalendarContext } from "./../context";
import MonthCalendar from "./monthCalendar";
import NewMomentPopup from "../Calendar/NewMomentPopup";
import { useSelector } from "react-redux";
import { toExcel } from "../../../utils/utils";
import { RootState, useAppDispatch } from "../../../store";
import { getFeatureAndMilestoneByMomentId } from "../../../slice/calendar";
import { _AUTHORIZATIONTYPE } from "../../Shared/constants";
import { AppContext } from "../../../App";

const NavBar = () => {
  const appContext = useContext(AppContext);
  const appDispatch = useAppDispatch();
  const [isShowCalendar, { setTrue: showCalendar, setFalse: hideCalendar }] =
    useBoolean(true);
  const [showShare, setShowShare] = useState(false);
  const [showLinkCopied, setShowLinkCopied] = useState(false);
  const momentList = useSelector(
    (state: RootState) => state.calendarUpdateReducer.momentList
  );
  let readOnly = true;
  if (
    appContext.accessType === _AUTHORIZATIONTYPE.admin ||
    appContext.accessType === _AUTHORIZATIONTYPE.dri
  ) {
    readOnly = false;
  }

  const [readOnlyButton, setReadOnlyButton] = useState(readOnly);
  const data = useContext(CalendarContext);
  const [downLoadButtonDisable, { toggle: ToggleButtion }] = useBoolean(false);
  const clickHandler = (toPrevious: Boolean) => {
    data.changeCurrentDates({
      currentStartDate: getFirstDayInTheMonth(
        getNextMonthDate(
          data.currentDates.currentStartDate,
          toPrevious
        ).toString()
      ),
      currentEndDate: getLastDayInTheMonth(
        getNextMonthDate(
          data.currentDates.currentEndDate,
          toPrevious
        ).toString()
      ),
    });
  };

  return (
    <div className="nav-container calander-nav-container">
      <div className="container-navbar">
        <div className="row" style={{ margin: "0px" }}>
          <div className="moment-legends-title">Solution area key:</div>
          <div className="moment-legends vertical-align mt-2">
            <div className="row-align">
              <div
                className="legend-item-title legend-item-business-applications"
                title="Business Applications (BA)"
              >
                Business Applications (BA)
              </div>
              <div
                className="legend-item-title legend-item-data-ai"
                title="Data & AI (DA)"
              >
                Data & AI (DA)
              </div>
              <div
                className="legend-item-title legend-item-digital-application-innovation"
                title="Digital & Application Innovation (DI)"
              >
                Digital & Application Innovation (DI)
              </div>
              <div
                className="legend-item-title legend-item-infrastructure"
                title="Infrastructure (IF)"
              >
                Infrastructure (IF)
              </div>
              <div
                className="legend-item-title legend-item-modern-work"
                title="Modern Work (MW)"
              >
                Modern Work (MW)
              </div>
              <div
                className="legend-item-title legend-item-security"
                title="Security (SE)"
              >
                Security (SE)
              </div>
            </div>
            <div className="row-align">
              <div
                className="legend-item-title legend-item-github"
                title="GitHub (GH)"
              >
                GitHub (GH)
              </div>
              <div
                className="legend-item-title legend-item-non-solution-area-aligned"
                title="Non-Solution Area Aligned (NS)"
              >
                Non-Solution Area Aligned (NS)
              </div>
              <div
                className="legend-item-title legend-item-cross-microsoft"
                title="Cross-Microsoft (XM)"
              >
                Cross-Microsoft (XM)
              </div>
              <div
                className="legend-item-title legend-item-social-religious-holidays"
                title="Social & religious holidays (SR)"
              >
                Social & religious holidays (SR)
              </div>
              <div
                className="legend-item-title legend-item-moratorium"
                title="Moratorium (MO)"
              >
                Moratorium (MO)
              </div>
            </div>
          </div>
        </div>
        <div className="row" style={{ margin: "10px 0px 0px 0px " }}>
          <div
            className="col-4 monthpicker-container section-wrap"
            style={{ display: "flex", width: "330px" }}
          >
            <PrimaryButton
              text="Go to today"
              allowDisabledFocus
              onClick={() => {
                data.changeCurrentDates({
                  currentStartDate: getFirstDayInTheMonth(
                    new Date().toString()
                  ),
                  currentEndDate: getLastDayInTheMonth(new Date().toString()),
                });
              }}
              styles={buttonStyles}
            />
            <div
              className="monthpicker-arrow-left ml-10"
              onClick={() => {
                clickHandler(true);
              }}
            ></div>
            <div className="monthpicker-title-container">
              <DatePicker
                value={data.currentDates.currentStartDate}
                formatDate={(date: Date | undefined): string => {
                  return `${date!.toLocaleString("default", {
                    month: "long",
                  })} ${date!.getFullYear()}`;
                }}
                styles={{
                  root: {
                    selectors: {
                      ".fieldGroup-185": {
                        border: "none",
                        borderImageWidth: 0,
                      },
                      ".fieldGroup-226": {
                        border: "none",
                      },
                      ".fieldGroup-226::after": {
                        border: "none",
                      },
                    },
                  },
                }}
                placeholder="Select a date..."
                ariaLabel="Select a date..."
                onMouseLeave={hideCalendar}
                onMouseEnter={showCalendar}
                calendarAs={() => {
                  return (
                    <MonthCalendar
                      isShowCalendar={isShowCalendar}
                      hideCalendar={hideCalendar}
                    />
                  );
                }}
              />
            </div>
            <div
              className="monthpicker-arrow-right"
              onClick={() => {
                clickHandler(false);
              }}
            ></div>
          </div>
          <div
            className="col-8"
            style={{
              display: "flex",
              alignItems: "center",
              position: "relative",
            }}
          >
            <PrimaryButton
              disabled={readOnlyButton}
              hidden={readOnlyButton}
              className="ml-10"
              styles={buttonStyles}
              onClick={() => {
                data.modalRef?.current?.click();
                appDispatch(
                  CalendarUpdateReducer.actions.changeEditPanelOpen(true)
                );
                appDispatch(
                  CalendarUpdateReducer.actions.changeEditPanelState(
                    CalendarUpdateReducer.Status.Add
                  )
                );
              }}
            >
              <span className="icon">
                <i
                  className="fa fa-plus"
                  aria-hidden="true"
                  aria-label="Add new marketing moment"
                ></i>
              </span>
              Add new marketing moment
            </PrimaryButton>
            <IconButton
              id="downloadExcel"
              aria-label="Excel, Export to Excel"
              disabled={downLoadButtonDisable}
              style={{ marginLeft: "20px", marginRight: "20px" }}
              iconProps={{
                iconName: "Download",
                style: { fontSize: "25px", color: "#212529" },
              }}
              ariaLabel="Download Excel"
              onClick={async () => {
                ToggleButtion();
                for (var p of momentList) {
                  if (!p.MilestoneTierGroups) {
                    await appDispatch(
                      getFeatureAndMilestoneByMomentId(p.Id ?? 0)
                    );
                  }
                }
                const table = document.getElementById(
                  "calendar-table-excel-download"
                )?.outerHTML;
                const result = table || "";
                if (result !== "") {
                  toExcel(`calendar-timeline-excel`, result);
                }
                ToggleButtion();
              }}
            />
            <IconButton
              id="Share"
              aria-label="Share"
              iconProps={{
                iconName: "Share",
                styles: { root: { fontSize: "25px", color: "#212529" } },
              }}
              onClick={() => {
                setShowLinkCopied(false);
                setShowShare(true);
              }}
            ></IconButton>
            {showShare ? (
              <div
                className="share-link-view"
                style={{ left: "500px", top: "20px" }}
              >
                {showLinkCopied ? (
                  <>
                    <i
                      className="ms-Icon ms-Icon--Completed completedView"
                      title="Completed"
                      aria-hidden="true"
                    ></i>
                    <div className="copyLinkText">Link Copied</div>
                  </>
                ) : null}
                <i
                  className="ms-Icon ms-Icon--ChromeClose font-size-16 closeShareView"
                  title="Close"
                  aria-label="Close share view"
                  aria-hidden="true"
                  onClick={() => {
                    setShowLinkCopied(false);
                    setShowShare(false);
                  }}
                />
                <div
                  className="link-wrap additional-pl15"
                  style={{ marginLeft: "10px" }}
                >
                  <div>
                    <label
                      className="control-label"
                      style={{ marginTop: 5, marginBottom: 5 }}
                    >
                      The view share link
                    </label>
                  </div>
                  <div>
                    <input
                      className="linkInputBox"
                      type="text"
                      aria-label="Share view link"
                      value={window.location.href}
                    />
                    <button
                      className="btn btn-sm btn-primary copyButton"
                      tabIndex={0}
                      onClick={() => {
                        navigator.clipboard.writeText(window.location.href);
                        setShowLinkCopied(true);
                      }}
                    >
                      Copy
                    </button>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
        <NewMomentPopup />
      </div>
    </div>
  );
};

export default NavBar;
