import httpClient from "./base";

const baseURL = "/AzureTimeline";

const getTimelineMilestone = (startDate?: string, endDate?: string) => {
  var url = `${baseURL}?startDate=${startDate}&endDate=${endDate}`;
  return httpClient.get(url);
};

const getTimelineExcelDownloadMilestone = (
  startDate?: string,
  endDate?: string
) => {
  var url = `${baseURL}/ExcelDownload?startDate=${startDate}&endDate=${endDate}`;
  return httpClient.get(url);
};

const getFilters = () => {
  var url = `${baseURL}/Filters`;
  return httpClient.get(url);
};

const getTimelineMilestoneSearch = (searchPhrase?: string) => {
  var url = `${baseURL}/Search?searchPhrase=${
    searchPhrase === undefined ? "" : searchPhrase
  }`;
  return httpClient.get(url);
};

const getTimelineExcelDownloadSearchMilestone = (searchPhrase?: string) => {
  var url = `${baseURL}/SearchExcelDownload?searchPhrase=${
    searchPhrase === undefined ? "" : searchPhrase
  }`;
  return httpClient.get(url);
};

const azureTimelineService = {
  getTimelineMilestone,
  getTimelineExcelDownloadMilestone,
  getFilters,
  getTimelineMilestoneSearch,
  getTimelineExcelDownloadSearchMilestone,
};

export default azureTimelineService;
