import httpClient from "./base";
import { ModernWorkFeature } from "../slice/modernWorkFeature";

const baseURL = "/ModernWorkFeature";

const getFeature = (id: number) => {
  let url = `${baseURL}?id=${id}&timelineType=MW`;
  return httpClient.get(url);
};

const getFeatureByMomentId = (id: number) => {
  let url = `${baseURL}/GetByMomentId?id=` + id;
  return httpClient.get(url);
};

const updateFeature = (viewModel: ModernWorkFeature) => {
  let url = `${baseURL}`;
  return httpClient.put(url, viewModel);
};

const getMSETSFeatureMonths = (timelineType: string) => {
  let url = `${baseURL}/GetMonths?timelineType=${timelineType}`;
  return httpClient.get(url);
};

const modernWorkSecurityMSETSFeatureService = {
  getFeature,
  getFeatureByMomentId,
  updateFeature,
  getMSETSFeatureMonths,
};

export default modernWorkSecurityMSETSFeatureService;
