import { useCallback, useState } from "react";
import { useCookies } from "react-cookie";
import { Link, MessageBar, MessageBarType } from "@fluentui/react";
import "./index.scss";

export const ConfidentialNote = () => {
  const [cookies, setCookie] = useCookies(["confidentialNoticeHide"]);
  const [visible, setVisible] = useState<boolean>(
    cookies.confidentialNoticeHide === undefined
  );
  const resetVisible = useCallback(() => {
    setCookie("confidentialNoticeHide", false, {
      expires: new Date(new Date().getTime() + 7 * 24 * 3600 * 1000),
    });
    setVisible(false);
  }, [setCookie]);

  return visible === true ? (
    <MessageBar
      onDismiss={resetVisible}
      dismissButtonAriaLabel="Close"
      className="confidentialNote"
      messageBarType={MessageBarType.warning}
    >
      <b>MICROSOFT CONFIDENTIAL – FOR INTERNAL USE ONLY</b>
      <br />
      <span>
        This tool and its content are intended for Microsoft internal audience
        only. Information contained herein should not be shared with anyone who
        does not have a business need to know. To ensure compliance, please
        review the
        <Link
          href="https://microsoft.sharepoint.com/sites/mspolicy/SitePages/PolicyProcedure.aspx?policyprocedureid=MSPOLICY-804079558-11"
          target="_blank"
          rel="noopener noreferrer"
          id="policyLink"
          onFocus={() => {
            const element = document.getElementById("policyLink");
            element?.scrollIntoView({ block: "end" });
          }}
        >
          confidential information policy
        </Link>
        .
      </span>
    </MessageBar>
  ) : null;
};

export default ConfidentialNote;
