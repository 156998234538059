import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { _LOADINGSTATE } from "../components/Shared/constants";
import conversationService from "../services/conversation";
import { ErrorMessage } from "../components/Shared/messageBox";

interface conversationState {
  isConversationsLoading: string;
  conversations: Conversation[];
}

const initialState: conversationState = {
  isConversationsLoading: _LOADINGSTATE.pending,
  conversations: [],
};

export interface Conversation {
  ConversationId: number;
  Name: string;
}

export const getConversation = createAsyncThunk(
  "getConversation",
  async () => await conversationService.getConversation()
);

const conversationSlice = createSlice({
  name: "conversation",
  initialState: initialState,
  reducers: {},
  extraReducers: {
    [getConversation.fulfilled.type]: (
      state: conversationState,
      action: PayloadAction<Conversation[] | string>
    ) => {
      if (typeof action.payload === "string") {
        state.conversations = [];
      } else {
        state.conversations = action.payload;
      }
      state.isConversationsLoading = _LOADINGSTATE.fullfilled;
    },
    [getConversation.pending.type]: (state: conversationState) => {
      state.isConversationsLoading = _LOADINGSTATE.pending;
    },
    [getConversation.rejected.type]: (state, { error }) => {
      ErrorMessage.show(
        "There was an error in getting the conversations. Please refresh the page and try again. If the issue persists please contact the tool administrator."
      );
    },
  },
});

export const { actions, reducer } = conversationSlice;
