import httpClient from "./base";

const baseURL = "/AzureUpdateDescriptionHistory";

const getAzureUpdateDescriptionHistory = (azureupdateId: number) => {
  return httpClient.get(baseURL, { azureupdateId: azureupdateId });
};

const getAzureUpdateDescriptionComparison = (
  azureupdateId: number,
  revision: number
) => {
  var url = `${baseURL}/Comparison`;
  return httpClient.get(url, {
    azureupdateId: azureupdateId,
    revision: revision,
  });
};

const azureupdateService = {
  getAzureUpdateDescriptionHistory,
  getAzureUpdateDescriptionComparison,
};

export default azureupdateService;
