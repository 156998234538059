import { useContext, useEffect, useRef, useState } from "react";
import moment from "moment";
import { useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { Spinner, SpinnerSize } from "@fluentui/react";
import { FiscalInfoHelper } from "../../utils/utils";
import {
  convertMonthStringToDate,
  getFirstDayInTheMonth,
  getLastDayInTheMonth,
  transferToUtcDate,
  isVoidDateTime,
} from "../../utils/date";
import "../Shared/css/modal.scss";
import {
  milestoneFormBorderTop,
  SecurityMilestoneTypeId,
  ModalType,
  TimelineType,
  _AUTHORIZATIONTYPE,
  _LOADINGSTATE,
  TimelineMode,
} from "../Shared/constants";
import * as tooltipReducer from "../../slice/tooltip";
import Accordion from "../Shared/accordion";
import ModalOverlay from "../Shared/FormInput/modalOverlay";
import { RootState, useAppDispatch } from "../../store";
import * as modernWorkFeatureReducer from "../../slice/modernWorkFeature";
import * as securityMilestoneReducer from "../../slice/securityMilestone";
import { getModernWorkSecurityEventCalendarMomentListByTimelineType } from "../../slice/modernWorkSecurityEventCalendarMoment";
import { AppContext } from "../../App";
import { SecurityMilestoneModalContext } from "../SecurityTimeline";
import ProductDetails from "./productDetails";
import MoreInformation from "./moreInformation";
import Timing from "./timing";
import { SecurityMilestone } from "../../slice/securityMilestone";
import { getNamePart } from "../../utils/string";
import * as securityTimelineReducer from "../../slice/securityTimeline";
import DetailsFromMSETS from "../ModernWorkFeature/detailsFromMSETS";
import { ErrorMessage } from "../Shared/messageBox";
import { DetailsHistory } from "./detailsHistory";

const SecurityMilestoneModal = () => {
  const appContext = useContext(AppContext);
  const appDispatch = useAppDispatch();

  const [securityMilestoneSubmited, setSecurityMilestoneSubmited] =
    useState(false);
  const securityMilestoneModalContext = useContext(
    SecurityMilestoneModalContext
  );

  const dataSource = useSelector(
    (state: RootState) => state.securityTimelineReducer.dataSet
  );

  const milestone = useSelector<
    RootState,
    securityMilestoneReducer.SecurityMilestone
  >((state: RootState) => {
    return state.securityMilestoneReducer.SingleMilestone;
  });

  const modernWorkFeature = useSelector<
    RootState,
    modernWorkFeatureReducer.ModernWorkFeature
  >((state: RootState) => state.modernWorkFeatureReducer.modernWorkFeature);

  useEffect(() => {
    if (milestone.__isLoading === _LOADINGSTATE.fullfilled) {
      appDispatch(
        getModernWorkSecurityEventCalendarMomentListByTimelineType({
          timelineType: TimelineType.SCI,
          date: new Date(moment(new Date()).format("MM/DD/YYYY")),
        })
      );
    }

    if (
      Object.keys(tooltips).length === 0 &&
      isTooltipsLoading !== _LOADINGSTATE.pending
    ) {
      appDispatch(tooltipReducer.getTooltip());
    }
  }, [milestone.__isLoading]);

  const [query, setQuery] = useSearchParams();

  useEffect(() => {
    if (
      securityMilestoneModalContext.securityMilestoneModalState
        .securityMilestoneId > 0
    ) {
      appDispatch(modernWorkFeatureReducer.actions.reset());
      appDispatch(
        securityMilestoneReducer.getSecurityMilestone(
          securityMilestoneModalContext.securityMilestoneModalState
            .securityMilestoneId
        )
      )
        .then(unwrapResult)
        .then((milestone: SecurityMilestone) => {
          if (
            securityMilestoneModalContext.securityMilestoneModalState
              .modalType === ModalType.Edit
          ) {
            securityMilestoneModalContext.setSecurityMilestoneModalStateHandler(
              {
                securityMilestoneId: milestone.SecurityMilestoneId,
                groupDate:
                  milestone.GroupDate === null
                    ? undefined
                    : milestone.GroupDate,
                modalType: ModalType.Edit,
                deleteMilestoneDateHistoryId: "",
              }
            );
            if (
              milestone.ModernWorkSecurityMSETSFeaturesId !== null &&
              milestone.ModernWorkSecurityMSETSFeaturesId !== 0
            ) {
              appDispatch(
                modernWorkFeatureReducer.getFeature(
                  milestone.ModernWorkSecurityMSETSFeaturesId
                )
              )
                .unwrap()
                .catch(() => {
                  ErrorMessage.show(
                    "There was an error getting the details from CCH. Please refresh the page and try again. If the issue persists please contact the tool administrator."
                  );
                });
            }
            setHideSpinner(true);
          }
        })
        .catch(() => {
          securityMilestoneModalContext.modalRef?.current?.click();
        });
    }

    if (
      securityMilestoneModalContext.securityMilestoneModalState.modalType ===
        ModalType.Edit ||
        securityMilestoneModalContext.securityMilestoneModalState.modalType ===
        ModalType.ReadOnly
    ) {
      if (!query.has("milestoneId")) {
        query.append(
          "milestoneId",
          securityMilestoneModalContext.securityMilestoneModalState.securityMilestoneId.toString()
        );
      }
      setQuery(query);
    }
    if (
      securityMilestoneModalContext.securityMilestoneModalState.modalType ===
      ModalType.New
    ) {
      appDispatch(
        securityMilestoneReducer.actions.changeLoading(_LOADINGSTATE.fullfilled)
      );
    }
  }, [
    securityMilestoneModalContext.securityMilestoneModalState
      .securityMilestoneId,
  ]);

  useEffect(() => {
    if (
      milestone.PublicTitle &&
      milestone.PublicTitle.trim().length > 0 &&
      milestone.PublicTitle.length <= 400 &&
      milestone.TierId &&
      milestone.TierId > 0 &&
      milestone.ProductServiceId &&
      milestone.ProductServiceId > 0 &&
      milestone.PMM &&
      milestone.PMM.length > 0 &&
      milestone.SecurityMilestoneAudiences &&
      milestone.SecurityMilestoneAudiences.filter((a) => {
        return a.IsAdded || (!a.IsAdded && !a.IsDeleted);
      }).length > 0 &&
      milestone.NewOrChanging &&
      milestone.NewOrChanging.trim().length > 0 &&
      milestone.NewOrChanging.length <= 2000 &&
      milestone.CustomerBenefits &&
      milestone.CustomerBenefits.trim().length > 0 &&
      milestone.CustomerBenefits.length <= 2000 &&
      milestone.SecurityMilestoneTypeId &&
      milestone.SecurityMilestoneTypeId > 0 &&
      milestone.SecurityConfidenceId &&
      milestone.SecurityConfidenceId > 0 &&
      (milestone.AnnouncementGroup
        ? milestone.AnnouncementGroup.length < 400
        : true) &&
      (milestone.Comment ? milestone.Comment.length < 2000 : true) &&
      ((milestone.SecurityMilestoneTypeId ===
        SecurityMilestoneTypeId.Public_Disclosure_Date &&
        milestone.PublicDisclosureDate !== null) ||
        (milestone.SecurityMilestoneTypeId ===
          SecurityMilestoneTypeId.Private_Preview &&
          milestone.PrivatePreviewDate !== null) ||
        (milestone.SecurityMilestoneTypeId ===
          SecurityMilestoneTypeId.Public_Preview &&
          milestone.PublicPreviewDate !== null) ||
        (milestone.SecurityMilestoneTypeId ===
          SecurityMilestoneTypeId.General_Availability &&
          milestone.GeneralAvailabilityDate !== null)) &&
      milestone.HasBlocker === (milestone.BlockerReason?.length != 0) &&
      milestone.HasBlocker === (milestone.BlockerReasonAmount != null)
    ) {
      appDispatch(securityMilestoneReducer.actions.changeIsFormValid(true));
    } else {
      appDispatch(securityMilestoneReducer.actions.changeIsFormValid(false));
    }
  }, [
    milestone.PublicTitle,
    milestone.TierId,
    milestone.ProductServiceId,
    milestone.PMM,
    milestone.SecurityMilestoneAudiences,
    milestone.NewOrChanging,
    milestone.CustomerBenefits,
    milestone.SecurityMilestoneTypeId,
    milestone.SecurityConfidenceId,
    milestone.AnnouncementGroup,
    milestone.Comment,
    milestone.PublicDisclosureDate,
    milestone.PrivatePreviewDate,
    milestone.PublicPreviewDate,
    milestone.GeneralAvailabilityDate,
    milestone.BlockerReason,
    milestone.BlockerReasonAmount,
    milestone.HasBlocker,
  ]);

  const { tooltips, isTooltipsLoading } = useSelector(
    (state: RootState) => state.tooltipReducer
  );
  const readOnly =
    appContext.accessType !== _AUTHORIZATIONTYPE.admin &&
    appContext.accessType !== _AUTHORIZATIONTYPE.dri &&
    appContext.accessType !== _AUTHORIZATIONTYPE.editable;

  const [formChanged, setFormChanged] = useState(false);
  const [appearErrorInfo, setAppearErrorInfo] = useState(false);
  const confirmationShow = useRef<HTMLButtonElement>(null);
  const [hideSpinner, setHideSpinner] = useState(false);

  return (
    <ModalOverlay
      title={
        securityMilestoneModalContext.securityMilestoneModalState.modalType ===
        ModalType.New
          ? "Create a new milestone"
          : "Milestone ID: " +
            securityMilestoneModalContext.securityMilestoneModalState
              .securityMilestoneId +
            (securityMilestoneModalContext.securityMilestoneModalState
              .modalType === ModalType.Copy
              ? " - " + " [duplicates]"
              : "")
      }
      refs={securityMilestoneModalContext.modalRef}
      formSubmited={securityMilestoneSubmited}
      formSubmitedCallback={() => {
        if (
          securityMilestoneModalContext.timelineMode === TimelineMode.Timeline
        ) {
          if (milestone.GroupDate && !isVoidDateTime(milestone.GroupDate)) {
            if (
              dataSource.filter((data) => {
                return (
                  moment(convertMonthStringToDate(data.month))
                    .utc()
                    .format("MM/YYYY") ===
                  moment(milestone.GroupDate).utc().format("MM/YYYY")
                );
              }).length > 0 &&
              dataSource.filter((data) => {
                return (
                  moment(convertMonthStringToDate(data.month))
                    .utc()
                    .format("MM/YYYY") ===
                  moment(milestone.GroupDate).utc().format("MM/YYYY")
                );
              })[0].isLoading !== ""
            ) {
              appDispatch(
                securityTimelineReducer.getMilestoneDataSet(
                  getFirstDayInTheMonth(
                    moment(milestone.GroupDate).utc().format("MM/DD/YYYY")
                  ),
                  getLastDayInTheMonth(
                    moment(milestone.GroupDate).utc().format("MM/DD/YYYY")
                  )
                )
              );

              appDispatch(
                securityTimelineReducer.actions.setIsLoadedExportExcelMilestone(
                  false
                )
              );
            }
          }
          if (
            securityMilestoneModalContext.securityMilestoneModalState
              .modalType === ModalType.Edit &&
            securityMilestoneModalContext.securityMilestoneModalState
              .groupDate &&
            securityMilestoneModalContext.securityMilestoneModalState
              .groupDate !== null &&
            milestone.GroupDate &&
            milestone.GroupDate !== null &&
            moment(
              getFirstDayInTheMonth(
                moment(
                  securityMilestoneModalContext.securityMilestoneModalState
                    .groupDate
                )
                  .utc()
                  .format("MM/DD/YYYY")
              )
            )
              .utc()
              .format("MM/YYYY") !==
              moment(
                getFirstDayInTheMonth(
                  moment(milestone.GroupDate).utc().format("MM/DD/YYYY")
                )
              )
                .utc()
                .format("MM/YYYY")
          ) {
            if (
              dataSource.filter((data) => {
                return (
                  moment(convertMonthStringToDate(data.month))
                    .utc()
                    .format("MM/YYYY") ===
                  moment(
                    securityMilestoneModalContext.securityMilestoneModalState
                      .groupDate
                  )
                    .utc()
                    .format("MM/YYYY")
                );
              }).length > 0 &&
              dataSource.filter((data) => {
                return (
                  moment(convertMonthStringToDate(data.month))
                    .utc()
                    .format("MM/YYYY") ===
                  moment(
                    securityMilestoneModalContext.securityMilestoneModalState
                      .groupDate
                  )
                    .utc()
                    .format("MM/YYYY")
                );
              })[0].isLoading !== ""
            ) {
              appDispatch(
                securityTimelineReducer.getMilestoneDataSet(
                  getFirstDayInTheMonth(
                    moment(
                      securityMilestoneModalContext.securityMilestoneModalState
                        .groupDate
                    )
                      .utc()
                      .format("MM/DD/YYYY")
                  ),
                  getLastDayInTheMonth(
                    moment(
                      securityMilestoneModalContext.securityMilestoneModalState
                        .groupDate
                    )
                      .utc()
                      .format("MM/DD/YYYY")
                  )
                )
              );

              appDispatch(
                securityTimelineReducer.actions.setIsLoadedExportExcelMilestone(
                  false
                )
              );
            }
          }
        } else {
          appDispatch(
            securityTimelineReducer.getSearchDataSet(
              securityMilestoneModalContext.searchPhrase
            )
          );
        }
      }}
      setFormSubmited={[
        setHideSpinner,
        setFormChanged,
        setSecurityMilestoneSubmited,
        setAppearErrorInfo,
      ]}
      confirmationShow={confirmationShow}
      formChanged={
        formChanged ||
        securityMilestoneModalContext.securityMilestoneModalState
          .deleteMilestoneDateHistoryId != ""
      }
      formResetCallback={() => {
        appDispatch(securityMilestoneReducer.actions.reset());
        securityMilestoneModalContext.setSecurityMilestoneModalStateHandler({
          securityMilestoneId: 0,
          modalType: ModalType.New,
          deleteMilestoneDateHistoryId: "",
        });
      }}
    >
      {!hideSpinner &&
      (securityMilestoneModalContext.securityMilestoneModalState.modalType ===
        ModalType.Edit ||
        securityMilestoneModalContext.securityMilestoneModalState.modalType ===
          ModalType.ReadOnly) ? (
        <Spinner
          label="Loading..."
          labelPosition="bottom"
          size={SpinnerSize.large}
        />
      ) : (
        ""
      )}

      <div
        className={
          !hideSpinner &&
          (securityMilestoneModalContext.securityMilestoneModalState
            .modalType === ModalType.Edit ||
            securityMilestoneModalContext.securityMilestoneModalState
              .modalType === ModalType.ReadOnly)
            ? "milestone-details d-none"
            : "milestone-details"
        }
      >
        <Accordion isDefaultOpen={true} title={"Product details"}>
          <ProductDetails
            disabled={readOnly}
            appearErrorInfo={appearErrorInfo}
            setFormChanged={setFormChanged}
          />
        </Accordion>
        <Timing
          disabled={readOnly}
          appearErrorInfo={appearErrorInfo}
          setFormChanged={setFormChanged}
        />
        <MoreInformation
          disabled={readOnly}
          appearErrorInfo={appearErrorInfo}
          setAppearErrorInfo={setAppearErrorInfo}
          milestoneFormSubmited={securityMilestoneSubmited}
          setMilestoneFormSubmited={setSecurityMilestoneSubmited}
          setFormChanged={setFormChanged}
          FormChange={formChanged}
        />
        <DetailsHistory appearErrorInfo={appearErrorInfo} disabled={readOnly} />
        <div
          style={{
            marginTop: "25px",
          }}
          className="modal-detail-header"
        >
          Additional resources
        </div>
        <div className="row">
          <div className="col-4">
            <p>
              <a
                href="https://microsoft.sharepoint.com/:p:/r/teams/ModernWorkSecurityroadmap/Shared%20Documents/Training%20and%20guides/Modern_Work_SCI_L%26D_checklist.pptx?d=w965d608c1cb14d35b1b90793d6840987&csf=1&web=1"
                target="_blank"
                rel="noopener noreferrer"
              >
                Launch and disclosure checklist
              </a>
            </p>
          </div>
          <div className="col-4">
            <p>
              <a
                href="https://microsoft.sharepoint.com/teams/BrandCentral/Pages/Guidelines.aspx"
                target="_blank"
                rel="noopener noreferrer"
              >
                Brand guidelines
              </a>
            </p>
          </div>
          <div className="col-4">
            <p>
              <a
                href="https://microsoft.sharepoint.com/teams/CloudMarketingMoments/SitePages/Cloud-Marketing-moments-calendar-DRIs.aspx"
                target="_blank"
                rel="noopener noreferrer"
              >
                Contact security calendar DRI(s)
              </a>
            </p>
          </div>
        </div>
        <div
          style={{
            borderTop: milestoneFormBorderTop,
          }}
          className="modal-detail-header"
        >
          SNJ fields
        </div>
        <div
          className="row feature-detail disabled"
          style={{ marginLeft: 0, marginRight: 0 }}
        >
          <div className="row">
            <div className="col-4">
              <strong>Product family: </strong> {milestone.ProductCategory}
            </div>

            <div className="col-4">
              <strong>Product group: </strong>
              {milestone.ProductFamily}
            </div>

            <div className="col-4">
              <strong>Marketing team lead: </strong>
              {getNamePart(milestone.MarketingLead ?? "")}
            </div>
          </div>
          <div className="row">
            <div className="col-4">
              <strong>Marketing theme: </strong> {milestone.MarketingTheme}
            </div>

            <div className="col-4">
              <strong>Solution play: </strong> {milestone.SolutionPlay}
            </div>

            <div className="col-4">
              <strong>SCI group: </strong> {milestone.SCIGroup}
            </div>
          </div>

          <div className="row">
            <div className="col-4">
              <strong>Milestone fiscal year: </strong>
              {"FY" +
                FiscalInfoHelper.getFiscalInfo(
                  milestone.GroupDate
                    ? transferToUtcDate(new Date(milestone.GroupDate))
                    : new Date(),
                  7
                )
                  .fiscalYear.toString()
                  .substring(2)}
            </div>
            <div className="col-4">
              <strong>Milestone fiscal quarter: </strong>{" "}
              {"Q" +
                FiscalInfoHelper.getFiscalInfo(
                  milestone.GroupDate
                    ? transferToUtcDate(new Date(milestone.GroupDate))
                    : new Date(),
                  7
                ).fiscalQuarter}
            </div>
            <div className="col-4">
              <strong>Milestone month: </strong>{" "}
              {milestone.GroupDate
                ? moment(
                    transferToUtcDate(new Date(milestone.GroupDate))
                  ).format("MMMM")
                : moment(new Date()).format("MMMM")}
            </div>
          </div>
        </div>

        <div
          className={`row ${
            milestone.ModernWorkSecurityMSETSFeaturesId !== null &&
            milestone.ModernWorkSecurityMSETSFeaturesId !== 0 &&
            modernWorkFeature != null &&
            modernWorkFeature.Id !== 0
              ? ""
              : "d-none"
          }`}
        >
          <div className="modal-detail-header">Details from MSETS</div>
        </div>
        <div
          className={`${
            milestone.ModernWorkSecurityMSETSFeaturesId !== null &&
            milestone.ModernWorkSecurityMSETSFeaturesId !== 0 &&
            modernWorkFeature != null &&
            modernWorkFeature.Id !== 0
              ? ""
              : "d-none"
          }`}
        >
          <DetailsFromMSETS />
        </div>
      </div>
    </ModalOverlay>
  );
};

export default SecurityMilestoneModal;
