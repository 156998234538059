import { TimelineType } from "../components/Shared/constants";
import httpClient from "./base";

const getCalendarDates = (timelineType: TimelineType) => {
  let url = "Calendar/Lookup?timelineType=" + timelineType.toString();
  return httpClient.get(url);
};

const calendarService = {
  getCalendarDates,
};

export default calendarService;
