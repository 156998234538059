import { TimelineType } from "../components/Shared/constants";
import httpClient from "./base";

const baseURL = "/ModernWorkTimeline";

const getTimelineMSETSFeature = (startDate?: string, endDate?: string) => {
  var url = `${baseURL}?startDate=${
    startDate === undefined ? "" : startDate
  }&endDate=${endDate === undefined ? "" : endDate}&timelineType=${
    TimelineType.MW
  }`;
  return httpClient.get(url);
};

const getTimelineExcelDownloadMSETSFeature = (
  startDate?: string,
  endDate?: string
) => {
  var url = `${baseURL}/ExcelDownload?startDate=${
    startDate === undefined ? "" : startDate
  }&endDate=${endDate === undefined ? "" : endDate}&timelineType=${
    TimelineType.MW
  }`;
  return httpClient.get(url);
};

const getFilters = (timelineType: string) => {
  let url = `${baseURL}/Filters?timelineType=${timelineType}`;
  return httpClient.get(url);
};

const getTimelineMSETSFeatureSearch = (searchPhrase?: string) => {
  var url = `${baseURL}/Search?searchPhrase=${
    searchPhrase === undefined ? "" : searchPhrase
  }&timelineType=${TimelineType.MW}`;
  return httpClient.get(url);
};

const getTimelineExcelDownloadSearchMSETSFeature = (searchPhrase?: string) => {
  var url = `${baseURL}/SearchExcelDownload?searchPhrase=${
    searchPhrase === undefined ? "" : searchPhrase
  }`;
  return httpClient.get(url);
};

const modernWorkTimelineService = {
  getTimelineMSETSFeature,
  getTimelineExcelDownloadMSETSFeature,
  getFilters,
  getTimelineMSETSFeatureSearch,
  getTimelineExcelDownloadSearchMSETSFeature,
};

export default modernWorkTimelineService;
