import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import customTrainDateService from "../services/customTrainDate";
import { ErrorMessage } from "../components/Shared/messageBox";

export interface CustomTrainDate {
  CustomTrainDateId: number;
  TrainDate?: Date;
  CallforContentDateTime?: Date;
  ContentDueDateTime?: Date;
}

const initialCustomTrainDate: CustomTrainDate = {
  CustomTrainDateId: 0,
  TrainDate: undefined,
  CallforContentDateTime: undefined,
  ContentDueDateTime: undefined,
};

interface StateProps {
  SingleCustomTrainDate: CustomTrainDate;
  CustomTrainDateList: CustomTrainDate[];
}

const initialState: StateProps = {
  SingleCustomTrainDate: initialCustomTrainDate,
  CustomTrainDateList: [],
};

let timer: number;
export const debounceChange = (action: any, delay: number) => {
  return (dispatch: any) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      dispatch(action);
    }, delay) as unknown as number;
  };
};

export const getCustomTrainList = createAsyncThunk(
  "getCustomTrainList",
  async () =>
    (await customTrainDateService.getCustomTrainDateList()) as CustomTrainDate[]
);

const customTrainDateSlice = createSlice({
  name: "customTrainDate",
  initialState: initialState,
  reducers: {},
  extraReducers: {
    [getCustomTrainList.fulfilled.type]: (
      state: StateProps,
      action: { payload: CustomTrainDate[]; type: string }
    ) => {
      state.CustomTrainDateList = action.payload;
    },
    [getCustomTrainList.rejected.type]: (state, { error }) => {
      ErrorMessage.show(
        "There was an error getting custom train date list. Please refresh the page and try again. If the issue persists please contact the tool administrator."
      );
    },
  },
});

export const { reducer, actions } = customTrainDateSlice;
