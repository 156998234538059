import { combineReducers, configureStore } from "@reduxjs/toolkit";
import * as conversationReducer from "./slice/conversation";
import * as azureupdateReducer from "./slice/azureupdate";
import * as audienceReducer from "./slice/audience";
import * as milestoneReducer from "./slice/milestone";
import * as milestoneTierReducer from "./slice/milestoneTier";
import * as milestoneTypeReducer from "./slice/milestoneType";
import * as productReducer from "./slice/product";
import * as azureupdatedeckReducer from "./slice/azureupdatedeck";
import * as savedDataFiltersReducer from "./slice/savedDataFilters";
import * as traincontentReducer from "./slice/traincontent";
import * as acomcategoryReducer from "./slice/acomcategory";
import * as azureTimelineReducer from "./slice/azureTimeline";
import * as customEventDateReducer from "./slice/customEventDate";
import * as graphReducer from "./slice/graphProxy";
import * as customTrainDateReducer from "./slice/customTrainDate";
import * as tooltipReducer from "./slice/tooltip";
import * as modernWorkTimelineReducer from "./slice/modernWorkTimeline";
import * as modernWorkSecurityEventCalendarMomentReducer from "./slice/modernWorkSecurityEventCalendarMoment";
import * as modernWorkSecurityMSETSFeatureProductReducer from "./slice/modernWorkSecurityMSETSFeatureProduct";
import * as triageTagReducer from "./slice/triageTag";
import * as calendarReducer from "./slice/calendar";
import * as calendarUpdateReducer from "./slice/CalendarSlices/calendarUpdate";
import * as calendarFieldOptionsReducer from "./slice/CalendarSlices/calendarDateSelectionOptions";
import * as azureupdateDescriptionHistoryReducer from "./slice/azureupdateDescriptionHistory";
import * as requestAccessReducer from "./slice/requestAccess";
import * as modernWorkFeatureReducer from "./slice/modernWorkFeature";
import * as modernWorkFeatureProductAreaReducer from "./slice/modernWorkFeatureProductArea";
import * as securityTimelineReducer from "./slice/securityTimeline";
import * as modernWorkFeatureHistoryReducer from "./slice/modernWorkFeatureHistory";
import * as securityMilestoneReducer from "./slice/securityMilestone";
import * as securityProductServiceReducer from "./slice/securityProductService";
import * as securityAudienceReducer from "./slice/securityAudience";
import * as securityMilestoneTypeReducer from "./slice/securityMilestoneType";
import * as securityConfidenceReducer from "./slice/securityConfidence";
import * as securityPMMReducer from "./slice/securityPMM";
import * as securityMilestoneDateHistoryReducer from "./slice/securityMilestoneDateHistory";
import * as fieldBlockersReducer from "./slice/fieldBlockReason";
import * as azureProductReducer from "./slice/azureProduct";
import * as pmmDueDateInfo from "./slice/pmmDueDate";
import { useDispatch } from "react-redux";

const reducer = combineReducers({
  conversationReducer: conversationReducer.reducer,
  azureupdateReducer: azureupdateReducer.reducer,
  audienceReducer: audienceReducer.reducer,
  milestoneReducer: milestoneReducer.reducer,
  milestoneTierReducer: milestoneTierReducer.reducer,
  milestoneTypeReducer: milestoneTypeReducer.reducer,
  productReducer: productReducer.reducer,
  azureupdatedeckReducer: azureupdatedeckReducer.reducer,
  savedDataFiltersReducer: savedDataFiltersReducer.reducer,
  traincontentReducer: traincontentReducer.reducer,
  acomcategoryReducer: acomcategoryReducer.reducer,
  azureTimelineReducer: azureTimelineReducer.reducer,
  customEventDateReducer: customEventDateReducer.reducer,
  graphReducer: graphReducer.reducer,
  customTrainDateReducer: customTrainDateReducer.reducer,
  tooltipReducer: tooltipReducer.reducer,
  modernWorkTimelineReducer: modernWorkTimelineReducer.reducer,
  modernWorkSecurityEventCalendarMomentReducer:
    modernWorkSecurityEventCalendarMomentReducer.reducer,
  modernWorkSecurityMSETSFeatureProductReducer:
    modernWorkSecurityMSETSFeatureProductReducer.reducer,
  triageTagReducer: triageTagReducer.reducer,
  calendarReducer: calendarReducer.reducer,
  calendarUpdateReducer: calendarUpdateReducer.reducer,
  calendarFieldOptionsReducer: calendarFieldOptionsReducer.reducer,
  azureupdateDescriptionHistoryReducer:
    azureupdateDescriptionHistoryReducer.reducer,
  requestAccessReducer: requestAccessReducer.reducer,
  modernWorkFeatureReducer: modernWorkFeatureReducer.reducer,
  modernWorkFeatureProductAreaReducer:
    modernWorkFeatureProductAreaReducer.reducer,
  securityTimelineReducer: securityTimelineReducer.reducer,
  modernWorkFeatureHistoryReducer: modernWorkFeatureHistoryReducer.reducer,
  securityMilestoneReducer: securityMilestoneReducer.reducer,
  securityProductServiceReducer: securityProductServiceReducer.reducer,
  securityAudienceReducer: securityAudienceReducer.reducer,
  securityMilestoneTypeReducer: securityMilestoneTypeReducer.reducer,
  securityConfidenceReducer: securityConfidenceReducer.reducer,
  securityPMMReducer: securityPMMReducer.reducer,
  securityMilestoneDateHistoryReducer:
    securityMilestoneDateHistoryReducer.reducer,
  fieldBlockerReducer: fieldBlockersReducer.reducer,
  azureProductReducer: azureProductReducer.reducer,
  pmmDueDateInfo: pmmDueDateInfo.reducer,
});

const store = configureStore({
  reducer: reducer,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export default store;
