import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { _LOADINGSTATE } from "../components/Shared/constants";
import modernWorkFeatureProductAreaService from "../services/modernWorkFeatureProductArea";
import { ErrorMessage } from "../components/Shared/messageBox";

interface modernWorkFeatureProductAreaState {
  isModernWorkFeatureProductAreasLoading: string;
  modernWorkFeatureProductAreas: modernWorkFeatureProductArea[];
}

const initialState: modernWorkFeatureProductAreaState = {
  isModernWorkFeatureProductAreasLoading: "",
  modernWorkFeatureProductAreas: [],
};

export interface modernWorkFeatureProductArea {
  ModernWorkFeatureProductAreaId: number;
  Name: string;
  ParentId: number;
  ModernWorkFeatureProductAreas: modernWorkFeatureProductArea[];
}

export const getModernWorkFeatureProductAreas = createAsyncThunk(
  "getModernWorkFeatureProductAreas",
  async () =>
    await modernWorkFeatureProductAreaService.getModernWorkFeatureProductAreas()
);

const modernWorkFeatureProductAreaSlice = createSlice({
  name: "modernWorkFeatureProductArea",
  initialState: initialState,
  reducers: {},
  extraReducers: {
    [getModernWorkFeatureProductAreas.fulfilled.type]: (
      state: modernWorkFeatureProductAreaState,
      action: PayloadAction<modernWorkFeatureProductArea[] | string>
    ) => {
      if (typeof action.payload === "string") {
        state.modernWorkFeatureProductAreas = [];
      } else {
        state.modernWorkFeatureProductAreas = action.payload;
      }
      state.isModernWorkFeatureProductAreasLoading = _LOADINGSTATE.fullfilled;
    },
    [getModernWorkFeatureProductAreas.pending.type]: (
      state: modernWorkFeatureProductAreaState
    ) => {
      state.isModernWorkFeatureProductAreasLoading = _LOADINGSTATE.pending;
    },
    [getModernWorkFeatureProductAreas.rejected.type]: (state, { error }) => {
      ErrorMessage.show(
        "There was an error getting the product areas. Please refresh the page and try again. If the issue persists please contact the tool administrator."
      );
    },
  },
});

export const { reducer, actions } = modernWorkFeatureProductAreaSlice;
