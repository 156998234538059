import "./index.scss";

const SkipToContent = () => {
  return (
    <div>
      <a href="#maincontent" className="m-skip-to-main" tabIndex={0}>
        Skip to main content
      </a>
    </div>
  );
};

export default SkipToContent;
