import httpClient from "./base";
import { SecurityAudience } from "../slice/securityAudience";

const baseURL = "/SecurityAudience";
const getSecurityAudience = () => {
  return httpClient.get(baseURL);
};

const SecurityAudienceService = {
  getSecurityAudience,
};

export default SecurityAudienceService;
