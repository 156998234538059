import "../../Shared/css/header.scss";
import "./index.scss";

const Header = () => {
  return (
    <header className="main-header calander-main-header">
      <div className="header-container">
        <h2
          className="main-header-title"
          style={{
            padding: "12px 0px",
            width: "800px",
            display: "flex",
            float: "left",
          }}
        >
          Welcome to the Cloud Marketing moments calendar
        </h2>
      </div>
    </header>
  );
};

export default Header;
