import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import requestAccessService from "../services/requestAccess";
import { ErrorMessage } from "../components/Shared/messageBox";
import { _LOADINGSTATE } from "../components/Shared/constants";

export interface RequestAccess {
  RequestUserName: string;
  Email: string;
  Role: string;
  Orgowner: string;
  LevelOfAccess: string;
  BusinessJustification: string;
  __isFormValid: boolean;
  __isLoading: string;
}

const initialState: RequestAccess = {
  RequestUserName: "",
  Email: "",
  Role: "",
  Orgowner: "",
  LevelOfAccess: "",
  BusinessJustification: "",
  __isFormValid: false,
  __isLoading: "",
};

export const requestAccess = createAsyncThunk(
  "requestAccess",
  async (params: RequestAccess, { dispatch, getState }) => {
    return await requestAccessService.requestAccess(params);
  }
);

const requestAccessReducer = createSlice({
  name: "requestAccess",
  initialState: initialState,
  reducers: {
    changeRequestUserName: (
      state: RequestAccess,
      action: { payload: string; type: string }
    ) => {
      state.RequestUserName = action.payload;
    },
    changeEmail: (
      state: RequestAccess,
      action: { payload: string; type: string }
    ) => {
      state.Email = action.payload;
    },
    changeRole: (
      state: RequestAccess,
      action: { payload: string; type: string }
    ) => {
      state.Role = action.payload;
    },
    changeOrgowner: (
      state: RequestAccess,
      action: { payload: string; type: string }
    ) => {
      state.Orgowner = action.payload;
    },
    changeLevelOfAccess: (
      state: RequestAccess,
      action: { payload: string; type: string }
    ) => {
      state.LevelOfAccess = action.payload;
    },
    changeBusinessJustification: (
      state: RequestAccess,
      action: { payload: string; type: string }
    ) => {
      state.BusinessJustification = action.payload;
    },
    changeIsFormValid: (
      state: RequestAccess,
      action: { payload: boolean; type: string }
    ) => {
      state.__isFormValid = action.payload;
    },
  },
  extraReducers: {
    [requestAccess.pending.type]: (state: RequestAccess) => {
      state.__isLoading = _LOADINGSTATE.pending;
    },
    [requestAccess.fulfilled.type]: (state: RequestAccess, { payload }) => {
      state.__isLoading = _LOADINGSTATE.fullfilled;
    },
    [requestAccess.rejected.type]: (state, { error }) => {
      state.__isLoading = _LOADINGSTATE.rejected;
      ErrorMessage.show(
        "There was an error request access. Please refresh the page and try again. If the issue persists please contact the tool administrator."
      );
    },
  },
});

export const { reducer, actions } = requestAccessReducer;
