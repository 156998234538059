import React, { createRef, useContext } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useBoolean } from "@fluentui/react-hooks";
import {
  TextField,
  PrimaryButton,
  Breadcrumb,
  IBreadcrumbItem,
  ITextFieldStyles,
  Dialog,
  IModalStyles,
  IModalProps,
  DialogType,
} from "@fluentui/react";
import { breadcrumbStyles, buttonStyles } from "../../Shared/styleProps";
import "../../Shared/css/header.scss";
import {
  _LOADINGSTATE,
  ContentShowMode,
  TimelineType,
  TimelineMode,
} from "../../Shared/constants";
import { inputFieldStyles } from "../../Shared/timelineFilterStyleProps";
import { RootState, useAppDispatch } from "../../../store";
import { ModernWorkFeatureModalContext } from "..";
import * as modernWorkTimelineReducer from "../../../slice/modernWorkTimeline";

export const inputStyles: Partial<ITextFieldStyles> = {
  ...inputFieldStyles,
  root: { width: 348 },
};

const Header = () => {
  const modernWorkFeatureModalContext = useContext(
    ModernWorkFeatureModalContext
  );

  const appDispatch = useAppDispatch();

  const requestMarketingWorkLink = createRef<HTMLAnchorElement>();
  const requestMarketingMomentLink = createRef<HTMLAnchorElement>();
  const helpResourcesLink = createRef<HTMLAnchorElement>();

  const isMonthLoading = useSelector(
    (state: RootState) => state.modernWorkTimelineReducer.isMonthLoading
  );

  const getData = (currentSearchPhrase: string) => {
    if (isMonthLoading === _LOADINGSTATE.pending) {
      return;
    }

    modernWorkFeatureModalContext.setSearchHighlightPhrase(
      currentSearchPhrase.trim()
    );
    if (!currentSearchPhrase || currentSearchPhrase.trim().length === 0) {
      modernWorkFeatureModalContext.setTimelineMode(TimelineMode.Timeline);
      modernWorkFeatureModalContext.setContentShowMode(ContentShowMode.Grid);
      appDispatch(
        modernWorkTimelineReducer.initModernWorkTimelineDataSet(TimelineType.MW)
      );
    } else {
      modernWorkFeatureModalContext.setTimelineMode(TimelineMode.Search);
      modernWorkFeatureModalContext.setContentShowMode(ContentShowMode.Column);
      appDispatch(
        modernWorkTimelineReducer.getSearchDataSet(currentSearchPhrase.trim())
      );
    }
  };

  const modalPropsStyles: Partial<IModalStyles> = {
    main: {
      width: "720px!important",
      minWidth: "720px!important",
      maxWidth: "720px!important",
    },
  };
  const modalProps: IModalProps = {
    isBlocking: true,
    styles: modalPropsStyles,
    dragOptions: undefined,
  };
  const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);

  const dialogContentProps = {
    type: DialogType.normal,
    title: "Modern Work resources",
    subText: "",
  };

  const navigate = useNavigate();

  const itemsWithHref: IBreadcrumbItem[] = [
    {
      text: " < Cloud Marketing moments calendar",
      key: "f1",
      onClick: () => {
        navigate("/");
      },
    },
    { text: "Modern Work", key: "f2", href: "#!", isCurrentItem: true },
  ];

  return (
    <header className="main-header">
      <Breadcrumb
        role="heading"
        className="main-breadcrumb"
        items={itemsWithHref}
        maxDisplayedItems={3}
        ariaLabel="Breadcrumb"
        aria-level={2}
        overflowAriaLabel="More links"
        styles={breadcrumbStyles}
        dividerAs={() => {
          return <span> - </span>;
        }}
      />
      <h2 className="main-header-title">Modern Work</h2>
      <nav
        role="navigation"
        className="main-nav nav nav-pills"
        style={{ width: "1125px" }}
      >
        <ul className="noStyleList">
          <li className="nav-item">
            <a
              href="https://badeintake.azurewebsites.net/"
              target="_blank"
              className="d-none"
              ref={requestMarketingWorkLink}
              rel="noreferrer"
              aria-label="Request marketing work"
            >
              Request marketing work
            </a>
            <PrimaryButton
              className="nav-link"
              onClick={() => {
                requestMarketingWorkLink.current?.click();
              }}
              role="link"
            >
              <span className="icon">
                <i
                  className="fa fa-file-text-o"
                  aria-hidden="true"
                  aria-label="Request marketing work"
                ></i>
              </span>
              Request marketing work
            </PrimaryButton>
          </li>
          <li className="nav-item">
            <a
              href="https://microsoft.sharepoint.com/teams/CloudMarketingMoments/SitePages/Cloud-Marketing-moments-calendar-DRIs.aspx"
              target="_blank"
              rel="noreferrer"
              className="d-none"
              ref={requestMarketingMomentLink}
              aria-label="Request a marketing moment"
            >
              Request a marketing moment
            </a>
            <PrimaryButton
              className="nav-link"
              onClick={() => {
                requestMarketingMomentLink.current?.click();
              }}
              role="link"
            >
              <span className="icon">
                <i
                  className="fa fa-file-text-o"
                  aria-hidden="true"
                  aria-label="Request a marketing moment"
                ></i>
              </span>
              Request a marketing moment
            </PrimaryButton>
          </li>
          <li className="nav-item">
            <Dialog
              hidden={hideDialog}
              onDismiss={toggleHideDialog}
              dialogContentProps={dialogContentProps}
              modalProps={modalProps}
            >
              <div>
                <ul
                  style={{
                    borderTop: "3px solid rgb(242, 242, 242)",
                    paddingTop: "20px",
                  }}
                >
                  <li>
                    <a
                      href="https://microsoft.sharepoint.com/:p:/r/teams/ModernWorkplaceMarketing/_layouts/15/Doc.aspx?sourcedoc=%7B2CCFE13F-B90B-4C49-ACE6-08B03C7542B7%7D&file=MW%20Workspace%20Quick%20Start%20Guide.pptx&_DSL=2&action=edit&mobileredirect=true"
                      target="_blank"
                      rel="noreferrer"
                      aria-label="Quick Start guide (new)"
                    >
                      Quick Start guide
                    </a>{" "}
                    (new)
                  </li>
                  <li>
                    <a
                      href="https://microsoft.sharepoint.com/teams/ModernWorkplaceMarketing/_layouts/15/stream.aspx?id=%2Fteams%2FModernWorkplaceMarketing%2FShared%20Documents%2FGeneral%2FMW%20Hub%20%2D%20Roadmap%2FMW%20Roadmap%20PMM%20Resources%2FMW%20PMM%20Workspace%20Resources%2FMW%20Workspace%20Instructional%20Video%2Emp4"
                      target="_blank"
                      rel="noreferrer"
                      aria-label="Instruction0.5al Video (new)"
                    >
                      Instructional video
                    </a>{" "}
                    (new)
                  </li>
                  <li>
                    <a
                      href="https://microsoft.sharepoint.com/:w:/r/teams/ModernWorkplaceMarketing/Shared%20Documents/General/MW%20Hub%20-%20Roadmap/MW%20Roadmap%20PMM%20Resources/PMM%20Workspace%20Resources/MW%20Workspace%20FAQ.docx?d=w7111d4863add45889b19896743410395&csf=1&web=1&e=tffjiZ"
                      target="_blank"
                      rel="noreferrer"
                      aria-label="FAQ"
                    >
                      FAQ
                    </a>{" "}
                    (new)
                  </li>
                  <li>
                    <a
                      href="https://microsoft.sharepoint.com/teams/BrandCentral/Pages/Guidelines.aspx"
                      target="_blank"
                      rel="noreferrer"
                      aria-label="Microsoft brand guidelines"
                    >
                      Microsoft brand guidelines
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://microsoft.sharepoint.com/:p:/r/teams/ModernWorkSecurityroadmap/Shared%20Documents/Training%20and%20guides/Modern_Work_SCI_L%26D_checklist.pptx?d=w965d608c1cb14d35b1b90793d6840987&csf=1&web=1"
                      target="_blank"
                      rel="noreferrer"
                      aria-label="Launch and disclosure checklist"
                    >
                      Launch and disclosure checklist
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://microsoft.sharepoint.com/teams/ModernWorkplaceMarketing/Shared%20Documents/Forms/AllItems.aspx?OR=Teams-HL&CT=1663360027094&clickparams=eyJBcHBOYW1lIjoiVGVhbXMtRGVza3RvcCIsIkFwcFZlcnNpb24iOiIyNy8yMjA5MDQwMDcwNyIsIkhhc0ZlZGVyYXRlZFVzZXIiOmZhbHNlfQ%3d%3d&id=%2fteams%2fModernWorkplaceMarketing%2fShared+Documents%2fLaunch+in+a+Box&viewid=6531fef7-5747-49af-9e11-48c871a138c3&view=0"
                      target="_blank"
                      rel="noreferrer"
                      aria-label="Launch and disclosure checklist"
                    >
                      Feature launch marketing plan
                    </a>{" "}
                    (new)
                  </li>
                  <li>
                    <a
                      href="https://microsoft.sharepoint.com/teams/M365ChangeCommunicationsSite/SitePages/How-do-I-.aspx?OR=Teams-HL&CT=1633129403664"
                      target="_blank"
                      rel="noreferrer"
                      aria-label="Request to add or edit feature information (sent to CxP)"
                    >
                      Request to add or edit feature information (sent to CxP)
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://microsoft.sharepoint.com/teams/Microsoft365RoadmapPMG/Shared%20Documents/Forms/AllItems.aspx?id=%2fteams%2fMicrosoft365RoadmapPMG%2fShared%20Documents%2fGeneral%2fTools%20and%20Processes%2fRoadmap%20Comms%2fGuidelines%20for%20writing%20feature%20titles%20and%20descriptions%205.4.22%20.pdf&parent=%2fteams%2fMicrosoft365RoadmapPMG%2fShared%20Documents%2fGeneral%2fTools%20and%20Processes%2fRoadmap%20Comms&p=true&ct=1651869112554&or=Outlook-Body&cid=149DB2E4-B468-430F-AAD2-4C45BF48E35D&ga=1"
                      target="_blank"
                      rel="noreferrer"
                      aria-label="Guidelines for writing feature titles and descriptions"
                    >
                      Guidelines for writing feature titles and descriptions
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://microsoft.sharepoint.com/:b:/t/ModernWorkplaceMarketing/Ebe9p4APtqdLmdSP74W20f0BAcmW8pYH4mQ6f3toBfbTqw?e=YtudPo"
                      target="_blank"
                      rel="noreferrer"
                      aria-label="Marketing roadmap definitions and tiering framework"
                    >
                      Marketing roadmap definitions and tiering framework
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://microsoft.sharepoint.com/teams/office_rdx/office_releases/SitePages/PMG%20guidance.aspx"
                      target="_blank"
                      rel="noreferrer"
                      aria-label="Definition of news"
                    >
                      Definition of news
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://microsoft.sharepoint.com/teams/modernworkrobcalendar"
                      target="_blank"
                      rel="noreferrer"
                      aria-label="Roadmap ROB Calendar"
                    >
                      Roadmap ROB Calendar
                    </a>
                  </li>
                </ul>
                <PrimaryButton
                  style={{
                    backgroundColor: "#0078d4",
                    borderColor: "#0078d4",
                    borderRadius: "0px",
                    color: "#FFF",
                    fontSize: "16px",
                    height: "40px",
                    marginRight: "15px",
                  }}
                  onClick={() => {
                    toggleHideDialog();
                  }}
                  role="link"
                >
                  Close
                </PrimaryButton>
              </div>
            </Dialog>
            <PrimaryButton
              className="nav-link"
              onClick={() => {
                toggleHideDialog();
              }}
              role="link"
            >
              <span className="icon">
                <i className="fa fa-question" aria-hidden="true"></i>
              </span>
              Modern Work resources
            </PrimaryButton>
          </li>
          <li className="nav-item">
            <a
              href="https://microsoft.sharepoint.com/teams/CloudMarketingMoments/SitePages/Cloud-Marketing-moments-help.aspx"
              target="_blank"
              rel="noreferrer"
              className="d-none"
              ref={helpResourcesLink}
              aria-label="Calendar/workspace help & resources"
            >
              Calendar/workspace help & resources
            </a>
            <PrimaryButton
              className="nav-link"
              onClick={() => {
                helpResourcesLink.current?.click();
              }}
              role="link"
            >
              <span className="icon">
                <i
                  className="fa fa-question"
                  aria-hidden="true"
                  aria-label="Calendar/workspace help & resources"
                ></i>
              </span>
              Calendar/workspace help & resources
            </PrimaryButton>
          </li>
        </ul>
      </nav>
      <div role="search" className="search">
        <TextField
          placeholder="Search"
          styles={inputStyles}
          value={modernWorkFeatureModalContext.searchPhrase}
          onChange={(event, item) => {
            modernWorkFeatureModalContext.setSearchPhrase(item || "");
            if (item === undefined || item.trim().length === 0) {
              getData(item || "");
            }
          }}
          onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
            if (event.keyCode === 13) {
              getData(modernWorkFeatureModalContext.searchPhrase);
            }
          }}
        />
        <PrimaryButton
          style={{ minWidth: 55, width: 55 }}
          ariaDescription="Search"
          ariaLabel="Search"
          text="Go"
          allowDisabledFocus
          onClick={() => {
            document.body.querySelector("#ariaLiveReader")!.innerHTML = "";
            getData(modernWorkFeatureModalContext.searchPhrase);
          }}
          styles={buttonStyles}
        />
      </div>
    </header>
  );
};

export default Header;
