import { ReactNode } from "react";
import { useBoolean } from "@fluentui/react-hooks";
import "./accordion.scss";

const Accordion = (props: {
  isDefaultOpen: boolean;
  title: string;
  children: ReactNode;
}) => {
  const [isOpen, { toggle: toggleOpen }] = useBoolean(
    props.isDefaultOpen || false
  );
  return (
    <div className="title-arrow">
      <div
        className="title-arrow-wrapper"
        onClick={(e) => {
          toggleOpen();
        }}
      >
        <div className="title-arrow-title">{props.title}</div>
        {props.children !== null &&
        (props.children as Array<ReactNode>).length > 0 ? (
          <div className="title-arrow-arrow">
            <button
              className="title-arrow-button"
              aria-label={props.title}
              aria-expanded={isOpen}
              role="button"
              tabIndex={0}
            >
              <i
                className={`fa ${
                  isOpen ? "fa-angle-down" : "fa-angle-right"
                } fa-2x`}
                tabIndex={-1}
              ></i>
            </button>
          </div>
        ) : null}
      </div>
      <div className={`title-arrow-content ${isOpen ? "" : "d-none"}`}>
        {props.children}
      </div>
    </div>
  );
};

export default Accordion;
