import React, { useContext } from "react";
import {
  ContextualMenu,
  IContextualMenuItem,
} from "@fluentui/react/lib/ContextualMenu";
import "../../../Shared/css/timelineGrid.scss";
import "../index.scss";

import { DirectionalHint } from "@fluentui/react";
import { TooltipHost, ITooltipHostStyles } from "@fluentui/react/lib/Tooltip";
import { useId } from "@fluentui/react-hooks";
import { ModalType, _AUTHORIZATIONTYPE } from "../../../Shared/constants";
import { SecurityMilestoneUtil } from "../../../Milestone/milestoneCommon";
import { securityTimelineMilestone as milestoneProps } from "../../../../slice/securityTimeline";
import { AppContext } from "../../../../App";
import { SecurityMilestoneModalContext } from "../..";
import { truncateText } from "../../../../utils/utils";
import moment from "moment";

const calloutProps = { gapSpace: 0 };
const hostStyles: Partial<ITooltipHostStyles> = {
  root: { display: "inline-block" },
};

const MilestoneCard = (props: { milestone: milestoneProps }) => {
  const appContext = useContext(AppContext);
  const securityMilestoneModalContext = useContext(
    SecurityMilestoneModalContext
  );

  const menuItems: IContextualMenuItem[] = [
    {
      key: "edit",
      text: "Edit",
      onClick: () => {
        securityMilestoneModalContext.setSecurityMilestoneModalStateHandler({
          securityMilestoneId: props.milestone.SecurityMilestoneId,
          modalType: ModalType.Edit,
          deleteMilestoneDateHistoryId: "",
        });
        securityMilestoneModalContext.modalRef?.current?.click();
      },
    },
    {
      key: "copy",
      text: "Copy",
      onClick: () => {
        securityMilestoneModalContext.setSecurityMilestoneModalStateHandler({
          securityMilestoneId: props.milestone.SecurityMilestoneId,
          modalType: ModalType.Copy,
          deleteMilestoneDateHistoryId: "",
        });
        securityMilestoneModalContext.modalRef?.current?.click();
      },
    },
    {
      key: "delete",
      text: "Delete",
      onClick: () => {
        securityMilestoneModalContext.setSecurityMilestoneModalStateHandler({
          securityMilestoneId: props.milestone.SecurityMilestoneId,
          groupDate: props.milestone.GroupDate,
          modalType: ModalType.Delete,
          deleteMilestoneDateHistoryId: "",
        });
        securityMilestoneModalContext.deleteDialogRef?.current?.click();
      },
    },
  ];

  const linkRef = React.useRef(null);
  const [showContextualMenu, setShowContextualMenu] = React.useState(false);
  const onShowContextualMenu = React.useCallback(
    (ev: React.MouseEvent<HTMLElement>) => {
      ev.stopPropagation();
      ev.preventDefault(); // don't navigate
      setShowContextualMenu(true);
    },
    []
  );
  const onHideContextualMenu = React.useCallback(
    () => setShowContextualMenu(false),
    []
  );
  const tooltipId = useId("milestoneCardTooltip");

  return (
    <TooltipHost
      content={props.milestone.PublicTitle}
      id={tooltipId}
      calloutProps={calloutProps}
      styles={hostStyles}
      directionalHint={DirectionalHint.bottomAutoEdge}
    >
      {Math.abs(
        moment(new Date()).diff(moment(props.milestone.CreatedDate)) /
          1000 /
          3600 /
          24
      ) < 1 ? (
        <i className={"ms-Icon ms-Icon--Glimmer"} title="This is a new item" />
      ) : (
        <div className="spacer" />
      )}
      <div
        className="grid-content-card-widget"
        role="row"
        key={props.milestone.SecurityMilestoneId}
        onClick={(e) => {
          if (
            appContext.accessType === _AUTHORIZATIONTYPE.admin ||
            appContext.accessType === _AUTHORIZATIONTYPE.dri ||
            appContext.accessType === _AUTHORIZATIONTYPE.editable
          ) {
            securityMilestoneModalContext.setSecurityMilestoneModalStateHandler(
              {
                securityMilestoneId: props.milestone.SecurityMilestoneId,
                groupDate: props.milestone.GroupDate,
                modalType: ModalType.Edit,
                deleteMilestoneDateHistoryId: "",
              }
            );
          } else {
            securityMilestoneModalContext.setSecurityMilestoneModalStateHandler(
              {
                securityMilestoneId: props.milestone.SecurityMilestoneId,
                modalType: ModalType.ReadOnly,
                deleteMilestoneDateHistoryId: "",
              }
            );
          }
          securityMilestoneModalContext.modalRef?.current?.click();
        }}
        onKeyDown={(e) => {
          if (e.keyCode === 13) {
            e.currentTarget.click();
          }
        }}
      >
        <div
          className="content-card"
          aria-label={"Public title:" + props.milestone.PublicTitle}
          tabIndex={0}
        >
          <div>
            <span title={props.milestone.PublicTitle}>
              <strong>
                {truncateText(props.milestone.PublicTitle, 52)}
                {` `}(
                {props.milestone.TierId === 1
                  ? "Tier 1"
                  : props.milestone.TierId === 2
                  ? "Tier 2"
                  : props.milestone.TierId === 3
                  ? "Tier 3"
                  : ""}
                )
              </strong>
            </span>
          </div>
          <div title={props.milestone.PMM} role="cell">
            PMM:{` `}
            {truncateText(props.milestone.PMM, 23)}
          </div>
          <div
            role="cell"
            title={
              props.milestone.PrivatePreviewDate === undefined ||
              props.milestone.PrivatePreviewDate === null
                ? ""
                : moment(new Date(props.milestone.PrivatePreviewDate))
                    .utc()
                    .format("MM/DD/YYYY")
            }
          >
            Private:{` `}
            {props.milestone.PrivatePreviewDate === undefined ||
            props.milestone.PrivatePreviewDate === null
              ? ""
              : moment(new Date(props.milestone.PrivatePreviewDate))
                  .utc()
                  .format("MM/DD/YYYY")}
          </div>
          <div
            role="cell"
            title={
              props.milestone.PublicPreviewDate === undefined ||
              props.milestone.PublicPreviewDate === null
                ? ""
                : moment(new Date(props.milestone.PublicPreviewDate))
                    .utc()
                    .format("MM/DD/YYYY")
            }
          >
            Public:{` `}
            {props.milestone.PublicPreviewDate === undefined ||
            props.milestone.PublicPreviewDate === null
              ? ""
              : moment(new Date(props.milestone.PublicPreviewDate))
                  .utc()
                  .format("MM/DD/YYYY")}
          </div>
          <div
            role="cell"
            title={
              props.milestone.GeneralAvailabilityDate === undefined ||
              props.milestone.GeneralAvailabilityDate === null
                ? ""
                : moment(new Date(props.milestone.GeneralAvailabilityDate))
                    .utc()
                    .format("MM/DD/YYYY")
            }
          >
            GA:{` `}
            {props.milestone.GeneralAvailabilityDate === undefined ||
            props.milestone.GeneralAvailabilityDate === null
              ? ""
              : moment(new Date(props.milestone.GeneralAvailabilityDate))
                  .utc()
                  .format("MM/DD/YYYY")}
          </div>
          <div className="edit-icon d-flex justify-content-between">
            <span
              ref={linkRef}
              onClick={onShowContextualMenu}
              onKeyDown={(e) => {
                if (e.keyCode === 13) {
                  e.currentTarget.click();
                  e.stopPropagation();
                }
              }}
              aria-label="More options"
              role="button"
              tabIndex={0}
              className={
                appContext.accessType === _AUTHORIZATIONTYPE.admin ||
                appContext.accessType === _AUTHORIZATIONTYPE.dri ||
                appContext.accessType === _AUTHORIZATIONTYPE.editable
                  ? "d-flex align-items-end"
                  : "d-none"
              }
            >
              <i
                data-icon-name="Edit"
                aria-hidden="true"
                className="ms-Icon root-48"
              >
                
              </i>
            </span>
          </div>
        </div>
        <div
          className={`content-card-type ${SecurityMilestoneUtil.Colors.milestone(
            props.milestone.SecurityMilestoneTypeId
          )}`}
        ></div>
        {showContextualMenu ? (
          <ContextualMenu
            items={menuItems}
            hidden={false}
            target={linkRef}
            onItemClick={onHideContextualMenu}
            onDismiss={onHideContextualMenu}
          />
        ) : null}
      </div>
    </TooltipHost>
  );
};

export default MilestoneCard;
