import React, { useContext } from "react";
import {
  ContextualMenu,
  DirectionalHint,
  IContextualMenuItem,
} from "@fluentui/react/lib/ContextualMenu";
import { TooltipHost, ITooltipHostStyles } from "@fluentui/react/lib/Tooltip";
import { useId } from "@fluentui/react-hooks";
import moment from "moment";
import striptags from "striptags";
import "../../../Shared/css/timelineGrid.scss";
import "../index.scss";
import {
  ModalType,
  TimelineMode,
  _AUTHORIZATIONTYPE,
} from "../../../Shared/constants";
import { azureTimelineMilestone } from "../../../../slice/azureTimeline";
import { MilestoneUtil } from "../../../Milestone/milestoneCommon";
import { MilestoneModalContext } from "../..";
import { AppContext } from "../../../../App";
import { truncateText } from "../../../../utils/utils";
import MilestoneSearchRow from "./milestoneSearchRow";

const calloutProps = { gapSpace: 0 };
const hostStyles: Partial<ITooltipHostStyles> = {
  root: { display: "inline-block" },
};

const MilestoneRow = (props: {
  CSA: string;
  milestone: azureTimelineMilestone;
}) => {
  const appContext = useContext(AppContext);
  const milestoneModalContext = useContext(MilestoneModalContext);
  const menuItems: IContextualMenuItem[] = [
    {
      key: "edit",
      text: "Edit",
      role: "button",
      ariaLabel: "Edit",
      onClick: () => {
        milestoneModalContext.setMilestoneModalStateHandler({
          milestoneId: props.milestone.MilestoneId,
          disclosureStartDate: props.milestone.DisclosureStartDate,
          modalType: ModalType.Edit,
        });
        milestoneModalContext.modalRef?.current?.click();
      },
    },
    {
      key: "copy",
      text: "Copy",
      role: "button",
      ariaLabel: "Copy",
      onClick: () => {
        milestoneModalContext.setMilestoneModalStateHandler({
          milestoneId: props.milestone.MilestoneId,
          disclosureStartDate: props.milestone.DisclosureStartDate,
          modalType: ModalType.Copy,
        });
        milestoneModalContext.modalRef?.current?.click();
      },
    },
    {
      key: "delete",
      text: "Delete",
      role: "button",
      ariaLabel: "Delete",
      onClick: () => {
        milestoneModalContext.setMilestoneModalStateHandler({
          milestoneId: props.milestone.MilestoneId,
          disclosureStartDate: props.milestone.DisclosureStartDate,
          modalType: ModalType.Delete,
        });
        milestoneModalContext.deleteDialogRef?.current?.click();
      },
    },
  ];

  const linkRef = React.useRef(null);
  const [showContextualMenu, setShowContextualMenu] = React.useState(false);
  const onShowContextualMenu = React.useCallback(
    (ev: React.MouseEvent<HTMLElement>) => {
      ev.stopPropagation();
      ev.preventDefault(); // don't navigate
      setShowContextualMenu(true);
    },
    []
  );
  const onHideContextualMenu = React.useCallback(
    () => setShowContextualMenu(false),
    []
  );

  const tooltipId = useId("milestoneCardTooltip");

  return (
    <TooltipHost
      content={props.milestone.MilestoneTypeName}
      id={tooltipId}
      calloutProps={calloutProps}
      styles={hostStyles}
      directionalHint={DirectionalHint.bottomAutoEdge}
    >
      {Math.abs(
        moment(new Date()).diff(moment(props.milestone.CreatedDate)) /
          1000 /
          3600 /
          24
      ) < 1 ? (
        <i
          className={"ms-Icon ms-Icon--Glimmer"}
          tabIndex={0}
          title="This is a new item"
          aria-hidden="true"
        />
      ) : (
        <div className="spacer" />
      )}
      <div
        aria-describedby={tooltipId}
        className="content-row-widget"
        key={props.milestone.MilestoneId}
        onClick={(e) => {
          if (
            appContext.accessType === _AUTHORIZATIONTYPE.admin ||
            appContext.accessType === _AUTHORIZATIONTYPE.dri ||
            appContext.accessType === _AUTHORIZATIONTYPE.editable
          ) {
            milestoneModalContext.setMilestoneModalStateHandler({
              milestoneId: props.milestone.MilestoneId,
              disclosureStartDate: props.milestone.DisclosureStartDate,
              modalType: ModalType.Edit,
            });
          } else {
            milestoneModalContext.setMilestoneModalStateHandler({
              milestoneId: props.milestone.MilestoneId,
              modalType: ModalType.ReadOnly,
            });
          }
          milestoneModalContext.modalRef?.current?.click();
        }}
        onKeyDown={(e) => {
          if (e.keyCode === 13) {
            e.currentTarget.click();
          }
        }}
      >
        <div
          className="content-row d-flex"
          aria-label={
            "Milestone name:" +
            props.milestone.MilestoneName +
            " Milestone type:" +
            props.milestone.MilestoneTypeName
          }
        >
          <div
            className={`content-card-type ${MilestoneUtil.Colors.milestone(
              props.milestone.MilestoneTypeId
            )}`}
          ></div>
          {milestoneModalContext.timelineMode === TimelineMode.Search ? (
            <MilestoneSearchRow
              CSA={props.CSA}
              milestone={props.milestone}
              key={props.milestone.MilestoneId}
            />
          ) : (
            <>
              <div>{props.CSA}</div>
              <div>{props.milestone.MilestoneId}</div>
              <div title={props.milestone.MilestoneName}>
                {truncateText(props.milestone.MilestoneName, 65)}
              </div>
              <div title={striptags(props.milestone.MilestoneDescription)}>
                {truncateText(
                  striptags(props.milestone.MilestoneDescription),
                  100
                )}
              </div>
              <div>
                {props.milestone.RoadmapStartDate === undefined
                  ? ""
                  : moment(new Date(props.milestone.RoadmapStartDate))
                      .utc()
                      .format("MM/DD/YYYY")}
              </div>
              <div>
                {props.milestone.DisclosureStartDate === undefined
                  ? ""
                  : moment(new Date(props.milestone.DisclosureStartDate))
                      .utc()
                      .format("MM/DD/YYYY")}
              </div>
              <div>
                {props.milestone.Tier === "1"
                  ? "Tier 1"
                  : props.milestone.Tier === "2"
                  ? "Tier 2"
                  : "Tier 3"}
              </div>
              <div>{props.milestone.MilestoneTypeName}</div>
              <div title={props.milestone.ContactName}>
                {truncateText(props.milestone.ContactName, 50)}
              </div>
              <div title={props.milestone.MarketingLeadName}>
                {truncateText(props.milestone.MarketingLeadName, 50)}
              </div>
              <div>{props.milestone.AlignedMomentName}</div>
            </>
          )}
          <div className="edit-icon d-flex justify-content-between">
            <span
              ref={linkRef}
              onClick={onShowContextualMenu}
              onKeyDown={(e) => {
                if (e.keyCode === 13) {
                  e.currentTarget.click();
                }
              }}
              role="button"
              aria-label="More options"
              tabIndex={0}
              className={
                appContext.accessType === _AUTHORIZATIONTYPE.admin ||
                appContext.accessType === _AUTHORIZATIONTYPE.dri ||
                appContext.accessType === _AUTHORIZATIONTYPE.editable
                  ? ""
                  : "d-none"
              }
            >
              <i
                data-icon-name="Edit"
                aria-hidden="true"
                className="ms-Icon root-48"
              >
                
              </i>
            </span>
          </div>
          {showContextualMenu ? (
            <ContextualMenu
              items={menuItems}
              hidden={false}
              target={linkRef}
              onItemClick={onHideContextualMenu}
              onDismiss={onHideContextualMenu}
            />
          ) : null}
        </div>
      </div>
    </TooltipHost>
  );
};

export default MilestoneRow;
