import React, { useState, useEffect, useContext } from "react";
import { useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import {
  DefaultButton,
  Dropdown,
  IDropdownOption,
  PrimaryButton,
  TextField,
} from "@fluentui/react";
import {
  convertDateTimeToFormatedDateString,
  transferToUtcDate,
} from "../../utils/date";
import useDocumentTitle from "../../utils/useDocumentTitle";
import "./productForm.scss";
import "../Shared/css/modal.scss";
import { RootState, useAppDispatch } from "../../store";
import * as productReducer from "../../slice/product";
import * as conversationReducer from "../../slice/conversation";
import * as acomcategoryReducer from "../../slice/acomcategory";
import * as tooltipReducer from "../../slice/tooltip";
import * as audienceReducer from "../../slice/audience";
import { _AUTHORIZATIONTYPE, _LOADINGSTATE } from "../Shared/constants";
import Tooltip from "../Shared/FormInput/tooltip";
import {
  dropdownStylesProps,
  textfieldStylesProps,
  textareaStylesProps,
} from "../Shared/styleProps";
import { SuccessMessage } from "../Shared/messageBox";
import moment from "moment";
import DatePickerWithCustomStyles from "../Shared/FormInput/datepickerWithCustomStyles";
import { AppContext } from "../../App";

const onFormatDate = (date?: Date): string => {
  return !date ? "" : moment(date).format("MM/DD/YYYY");
};
const ProductForm = (props: {
  refs: React.RefObject<HTMLButtonElement>;
  productFormSubmited: boolean;
  setProductFormSubmited: Function;
  productFormChanged: boolean;
  setProductFormChanged: Function;
  confirmationShow: React.RefObject<HTMLButtonElement> | null;
}) => {
  const appDispatch = useAppDispatch();
  const conversationState = useSelector<
    RootState,
    conversationReducer.Conversation[]
  >((state) => state.conversationReducer.conversations);
  const { singleProduct } = useSelector<RootState, productReducer.ProductState>(
    (state) => state.productReducer
  );

  const acomcategories = useSelector<
    RootState,
    acomcategoryReducer.ACOMCategory[]
  >((state: RootState) => state.acomcategoryReducer.acomcategories);
  const conversationOptions: IDropdownOption<number>[] = conversationState.map(
    (c) => {
      return { text: c.Name, key: c.ConversationId };
    }
  );
  const acomcategoryOptions: IDropdownOption<number>[] = acomcategories.map(
    (a) => {
      return { text: a.Name, key: a.ACOMCategoryId };
    }
  );
  const audienceState = useSelector<RootState, audienceReducer.Audience[]>(
    (state) => state.audienceReducer.audiences
  );
  const audienceOptions: IDropdownOption<number>[] = audienceState.map((a) => {
    return { text: a.Name, key: Number(a.AudienceId) };
  });
  const tooltips = useSelector<RootState, { [key: string]: string }>(
    (state) => state.tooltipReducer.tooltips
  );
  useEffect(() => {
    if (conversationState.length === 0) {
      appDispatch(conversationReducer.getConversation());
    }
    if (audienceState.length === 0) {
      appDispatch(audienceReducer.getAudiences());
    }
    if (Object.keys(tooltips).length === 0) {
      appDispatch(tooltipReducer.getTooltip());
    }
  }, []);
  useEffect(() => {
    if (
      singleProduct.ConversationIds &&
      singleProduct.ConversationIds.length > 0 &&
      singleProduct.AcomcategoryIds &&
      singleProduct.AcomcategoryIds.length > 0 &&
      singleProduct.ProductName.length > 0 &&
      singleProduct.CreatedDate.length > 0 &&
      singleProduct.Description.length > 0 &&
      singleProduct.Description.length < 2000
    ) {
      appDispatch(productReducer.actions.changeIsFormValid(true));
    } else {
      appDispatch(productReducer.actions.changeIsFormValid(false));
    }
  }, [
    singleProduct.ConversationIds,
    singleProduct.AcomcategoryIds,
    singleProduct.ProductName,
    singleProduct.CreatedDate,
    singleProduct.Description,
  ]);

  const [productNameError, setProductNameError] = useState("");

  const considerTouched = (params: boolean) => {
    return props.productFormSubmited && params;
  };

  useDocumentTitle("Create Product - Cloud Marketing moments calendar", false);

  const appContext = useContext(AppContext);
  const readOnly =
    appContext.accessType !== _AUTHORIZATIONTYPE.admin &&
    appContext.accessType !== _AUTHORIZATIONTYPE.dri &&
    appContext.accessType !== _AUTHORIZATIONTYPE.editable;
  const considerAdmin = appContext.accessType !== _AUTHORIZATIONTYPE.admin;
  return (
    <form>
      <div className="product-form">
        <div className="product-form-header modal-detail-header">
          Basic Product Information
        </div>
        <div className="row">
          <div className="col-6">
            <Tooltip dynamicContent={tooltips["ConversationId"] || ""}>
              <Dropdown
                options={conversationOptions}
                id="productformConversations"
                label="CSA *"
                placeholder="Select one ore more CSA"
                onChange={(
                  event: React.FormEvent<HTMLDivElement>,
                  item?: IDropdownOption<number> | undefined
                ) => {
                  let temp: number[] = [];

                  if (item && item.selected) {
                    temp = singleProduct.ConversationIds.slice(0);
                    temp.push(item.key as number);

                    appDispatch(
                      productReducer.actions.changeConversationIds(temp)
                    );
                  } else if (item && !item.selected) {
                    temp = singleProduct.ConversationIds.filter((c) => {
                      return c !== (item.key as number);
                    });
                    appDispatch(
                      productReducer.actions.changeConversationIds(temp)
                    );
                  }
                  appDispatch(acomcategoryReducer.getACOMCategories(temp));
                  props.setProductFormChanged(true);
                }}
                multiSelect
                selectedKeys={singleProduct.ConversationIds}
                styles={dropdownStylesProps}
                errorMessage={
                  considerTouched(singleProduct.ConversationIds.length === 0)
                    ? "Please Select CSA."
                    : ""
                }
                disabled={readOnly}
              />
            </Tooltip>
            <Dropdown
              options={acomcategoryOptions}
              id="productformACOMCategories"
              label="Category *"
              placeholder="Select an option"
              onChange={(
                event: React.FormEvent<HTMLDivElement>,
                item?: IDropdownOption<number> | undefined
              ) => {
                if (singleProduct.ConversationIds.length !== 0) {
                  let temp: number[] = [];

                  if (item && item.selected) {
                    temp = singleProduct.AcomcategoryIds.slice(0);
                    temp.push(item.key as number);

                    appDispatch(
                      productReducer.actions.changeACOMCategoryIds(temp)
                    );
                  } else if (item && !item.selected) {
                    temp = singleProduct.AcomcategoryIds.filter((c) => {
                      return c !== (item.key as number);
                    });
                    appDispatch(
                      productReducer.actions.changeACOMCategoryIds(temp)
                    );
                  }
                }
                props.setProductFormChanged(true);
              }}
              multiSelect
              selectedKeys={singleProduct.AcomcategoryIds}
              styles={dropdownStylesProps}
              errorMessage={
                considerTouched(singleProduct.ConversationIds.length === 0)
                  ? "Please select the CSA first."
                  : considerTouched(singleProduct.AcomcategoryIds.length === 0)
                  ? "Please Select Category."
                  : ""
              }
              disabled={readOnly}
            />
            <Tooltip dynamicContent={tooltips["ProductName"] || ""}>
              <TextField
                label="Pending product name *"
                value={singleProduct.ProductName}
                placeholder=""
                type="text"
                onChange={(
                  event: React.FormEvent<
                    HTMLInputElement | HTMLTextAreaElement
                  >,
                  newValue?: string | undefined
                ) => {
                  appDispatch(
                    productReducer.actions.changeProductName(newValue || "")
                  );
                  props.setProductFormChanged(true);
                }}
                styles={textfieldStylesProps}
                errorMessage={
                  productNameError.length > 0
                    ? productNameError
                    : considerTouched(singleProduct.ProductName.length === 0)
                    ? "Product Name is required."
                    : ""
                }
                disabled={readOnly}
              />
            </Tooltip>
            <Tooltip dynamicContent={tooltips["ProductAudienceTags"] || ""}>
              <Dropdown
                options={audienceOptions}
                id="productformAudiences"
                label="Audience tagging"
                placeholder="Select an option"
                multiSelect
                onChange={(
                  event: React.FormEvent<HTMLDivElement>,
                  item?: IDropdownOption<number> | undefined
                ) => {
                  let temp: number[] = [];
                  if (item && item.selected) {
                    temp = singleProduct.SelectedAudiences.slice(0);
                    temp.push(item.key as number);

                    appDispatch(
                      productReducer.actions.changeSelectedAudiences(temp)
                    );
                  } else if (item && !item.selected) {
                    temp = singleProduct.SelectedAudiences.filter((c) => {
                      return c !== (item.key as number);
                    });
                    appDispatch(
                      productReducer.actions.changeSelectedAudiences(temp)
                    );
                  }
                  props.setProductFormChanged(true);
                }}
                selectedKeys={singleProduct.SelectedAudiences}
                styles={dropdownStylesProps}
                disabled={readOnly}
              />
            </Tooltip>
          </div>
          <div className="col-6">
            <label className="modal-detail-label">Created date *</label>
            <DatePickerWithCustomStyles
              minDate={considerAdmin ? new Date() : undefined}
              placeholder="Select a date..."
              ariaLabel="Select a created date"
              value={
                singleProduct.CreatedDate
                  ? transferToUtcDate(new Date(singleProduct.CreatedDate))
                  : new Date()
              }
              onChangeHandler={(date: Date | null | undefined) => {
                if (date && date != null) {
                  appDispatch(
                    productReducer.actions.changeCreatedDate(
                      new Date(
                        convertDateTimeToFormatedDateString(date)
                      ).toISOString()
                    )
                  );
                }
                props.setProductFormChanged(true);
              }}
              formatDate={onFormatDate}
              disabled={readOnly}
            />
            <Tooltip dynamicContent={tooltips["ProductDescription"] || ""}>
              <TextField
                label="Short product description *"
                value={singleProduct.Description}
                onChange={(
                  event: React.FormEvent<
                    HTMLInputElement | HTMLTextAreaElement
                  >,
                  newValue?: string | undefined
                ) => {
                  appDispatch(
                    productReducer.actions.changeDescriptions(newValue || "")
                  );
                  props.setProductFormChanged(true);
                }}
                multiline
                rows={7}
                errorMessage={
                  considerTouched(singleProduct.Description.length === 0)
                    ? "Product Description is required."
                    : singleProduct.Description.length > 2000
                    ? "Product Description exceeds max size(2000)"
                    : ""
                }
                styles={textareaStylesProps}
                disabled={readOnly}
              />
            </Tooltip>
            <p>
              Please submit your request for product name approval using
              the&nbsp;
              <a
                href="https://aka.ms/BADEIntake"
                target="_blank"
                rel="noopener noreferrer"
              >
                marketing work intake
              </a>
              . You can start creating milestones while the product name is
              pending. Upon naming council approval, the final product name will
              be updated in the Azure workspace.
            </p>
          </div>
        </div>
        <div className="my-4 d-flex justify-content-start">
          <DefaultButton
            text="Cancel"
            onClick={(event: React.MouseEvent<HTMLSpanElement>) => {
              event.preventDefault();
              if (props.productFormChanged) {
                props.confirmationShow?.current?.click();
              } else {
                props.refs.current?.click();
              }
            }}
          />
          <PrimaryButton
            text="Create Product"
            disabled={
              singleProduct.__isLoading !== _LOADINGSTATE.fullfilled || readOnly
            }
            onClick={(event: React.MouseEvent<HTMLSpanElement>) => {
              event.preventDefault();
              props.setProductFormSubmited(true);
              if (singleProduct.__isFormValid) {
                appDispatch(
                  productReducer.getIsExistProduct(singleProduct.ProductName)
                )
                  .then(unwrapResult)
                  .then((data) => {
                    if (!Boolean(data)) {
                      appDispatch(productReducer.postProduct({}))
                        .then(unwrapResult)
                        .then(() => {
                          SuccessMessage.show(
                            "Your new product has been successfully created."
                          );
                          props.setProductFormChanged(false);
                          props.refs.current?.click();
                        })
                        .catch();
                      setProductNameError("");
                    } else {
                      setProductNameError("Product Name already exists");
                    }
                  })
                  .catch();
              }
            }}
          />
        </div>
      </div>
    </form>
  );
};

export default ProductForm;
