import httpClient from "./base";

const baseURL = "/ModernWorkSecurityMSETSFeatureProduct";

const getTimelineMSETSFeatureFilterProducts = (timelineType: string) => {
  let url = `${baseURL}?category=${timelineType}`;
  return httpClient.get(url);
};

const modernWorkSecurityMSETSFeatureProductService = {
  getTimelineMSETSFeatureFilterProducts,
};

export default modernWorkSecurityMSETSFeatureProductService;
