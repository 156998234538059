import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { _LOADINGSTATE } from "../components/Shared/constants";
import triageTagService from "../services/triageTag";
import { ErrorMessage } from "../components/Shared/messageBox";

export const getTriageTag = createAsyncThunk("getTriageTag", async () => {
  return await triageTagService.getTriageTag();
});

export const postTriageTag = createAsyncThunk(
  "postTriageTag",
  async (name: string) => {
    return await triageTagService.postTriageTag(name);
  }
);

export interface TriageTag {
  Id: number;
  Name: string;
}

interface TriageTagState {
  triageTags: TriageTag[];
  isLoadingTriageTag: string;
}

const initialState = {
  triageTags: [] as TriageTag[],
  isLoadingTriageTag: _LOADINGSTATE.pending,
};

const triageTagSlice = createSlice({
  name: "triageTag",
  initialState: initialState,
  reducers: {},
  extraReducers: {
    [getTriageTag.fulfilled.type]: (
      state: TriageTagState,
      action: { payload: TriageTag[]; type: string }
    ) => {
      state.triageTags = action.payload;
      state.isLoadingTriageTag = _LOADINGSTATE.fullfilled;
    },
    [getTriageTag.rejected.type]: (state, { error }) => {
      ErrorMessage.show(
        "There was an error getting the triage tags. Please refresh the page and try again. If the issue persists please contact the tool administrator."
      );
    },
    [postTriageTag.fulfilled.type]: () => {},
    [postTriageTag.rejected.type]: (state, { error }) => {
      ErrorMessage.show(
        "There was an error posting the triage tags. Please refresh the page and try again. If the issue persists please contact the tool administrator."
      );
    },
  },
});

export const { reducer, actions } = triageTagSlice;
