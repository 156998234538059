import httpClient from "./base";

const getACOMCategories = (conversationIds: number[]) => {
  const url = "AcomCategory";
  return httpClient.get(url, {
    conversationIds: encodeURIComponent(conversationIds.join(",")),
  });
};

const acomcateogryService = {
  getACOMCategories,
};

export default acomcateogryService;
