import React, {
  useEffect,
  useMemo,
  useState,
  CSSProperties,
  FunctionComponent,
} from "react";
import { useSelector } from "react-redux";
import Select, {
  PropsValue,
  GroupBase,
  StylesConfig,
  ClearIndicatorProps,
  SelectInstance,
  OnChangeValue,
  ActionMeta,
} from "react-select";
import { RootState, useAppDispatch } from "../../../store";
import { GraphUser, getGraphUsers } from "../../../slice/graphProxy";
import { useDebounce } from "../../../utils/utils";
import "./modalOverlay.scss";

type OptionType = {
  value: string;
  label: string;
};

interface Props {
  onChangeHandler: (
    newValue: OnChangeValue<any, boolean>,
    actionMeta: ActionMeta<any>
  ) => void;
  selectedKey?: string | undefined;
  selectedKeys?: string[] | undefined;
  placeholder?: string;
  selectStyle?: StylesConfig<OptionType, boolean, GroupBase<OptionType>>;
  id: string;
  ariaLabel: string | undefined;
  disabled?: boolean;
  microsoftUser?: boolean;
  isMulti?: true | undefined;
}

const GraphUsersAutoComplete = (props: Props) => {
  const [userFilter, setUserFilter] = useState(
    props.selectedKey === undefined || props.selectedKey === null
      ? ""
      : props.selectedKey.indexOf("[") > -1
      ? props.selectedKey.substring(0, props.selectedKey.indexOf("[")).trim()
      : props.selectedKey.trim()
  );
  const debouncedUserFilter = useDebounce(userFilter, 500);
  const appDispatch = useAppDispatch();

  const graphUsersState = useSelector<RootState, GraphUser[]>(
    (state) => state.graphReducer.graphUsers
  );

  const selectedKeys: PropsValue<OptionType> =
    props.selectedKeys === undefined || props.selectedKeys === null
      ? null
      : props.selectedKeys.map((p) => {
          return { value: p, label: p };
        });

  const selectedKey: PropsValue<OptionType> =
    props.selectedKey === undefined || props.selectedKey === ""
      ? null
      : {
          value: props.selectedKey,
          label: props.selectedKey,
        };

  const graphUsers = useMemo(() => {
    const tempUsers =
      graphUsersState.length > 0
        ? graphUsersState.map((u: GraphUser) => {
            return {
              value: `${u.DisplayName} [${u.Mail}]`,
              label: `${u.DisplayName} [${u.Mail}]`,
            };
          })
        : undefined;

    if (
      selectedKey &&
      tempUsers?.findIndex((p) => p.label == selectedKey.label) === -1
    ) {
      tempUsers?.splice(0, 0, selectedKey);
    }
    return tempUsers;
  }, [graphUsersState]);

  useEffect(() => {
    appDispatch(
      getGraphUsers({
        filter: debouncedUserFilter,
        select: "",
        top: "14",
        microsoftUser: props.microsoftUser ?? false,
      })
    );
  }, [debouncedUserFilter]);

  let select: SelectInstance<OptionType, true, GroupBase<OptionType>> | null;
  const CustomClearText: FunctionComponent = () => <strong>✖</strong>;
  const ClearIndicator = (props: ClearIndicatorProps<OptionType, true>) => {
    const {
      children = <CustomClearText />,
      getStyles,
      innerProps: { ref, ...restInnerProps },
    } = props;
    return (
      <div
        {...restInnerProps}
        ref={ref}
        style={getStyles("clearIndicator", props) as CSSProperties}
        onKeyDown={(e) => {
          if (e.keyCode === 13) {
            select?.clearValue();
            select?.focus();
          }
        }}
      >
        <div tabIndex={0} style={{ padding: "0px 5px" }}>
          {children}
        </div>
      </div>
    );
  };
  return (
    <Select
      ref={(ref) => {
        select = ref;
      }}
      aria-label={props.ariaLabel}
      placeholder={props.placeholder || "Enter name"}
      className="react-select-container"
      classNamePrefix="react-select"
      name={props.id}
      id={props.id}
      options={graphUsers}
      value={props.isMulti ? selectedKeys : selectedKey}
      styles={props.selectStyle}
      onChange={props.onChangeHandler}
      onInputChange={(value) => {
        setUserFilter(value);
      }}
      isMulti={props.isMulti}
      isSearchable={true}
      isClearable
      isDisabled={props.disabled}
      aria-hidden={false}
      components={{ ClearIndicator }}
      tabSelectsValue={false}
    ></Select>
  );
};

export default GraphUsersAutoComplete;
