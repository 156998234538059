import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { _LOADINGSTATE } from "../components/Shared/constants";
import traincontentService from "../services/traincontent";
import { ErrorMessage } from "../components/Shared/messageBox";

export interface TrainContent {
  TrainContentId: number;
  MilestoneId: number;
  FAQ: string;
  FieldDisclosureAnnouncement: string;
  __isLoading: string;
}

const initialState: TrainContent = {
  TrainContentId: 0,
  MilestoneId: 0,
  FAQ: "",
  FieldDisclosureAnnouncement: "",
  __isLoading: "",
};

let timer: number;
export const debounceChange = (action: any, delay: number) => {
  return (dispatch: any) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      dispatch(action);
    }, delay) as unknown as number;
  };
};

export const getTrainContent = createAsyncThunk(
  "getTrainContent",
  async (Id: number) => {
    return (await traincontentService.getTrainContent(Id)) as TrainContent;
  }
);

export const postTrainContent = createAsyncThunk(
  "postTrainContent",
  async (params: TrainContent) => {
    return await traincontentService.postTrainContent(params);
  }
);

export const putTrainContent = createAsyncThunk(
  "putTrainContent",
  async (params: TrainContent) => {
    return await traincontentService.putTrainContent(params);
  }
);

const traincontentSlice = createSlice({
  name: "traincontent",
  initialState: initialState,
  reducers: {
    reset: (state: TrainContent) => {
      state = initialState;
    },
    changeId: (
      state: TrainContent,
      action: { payload: number; type: string }
    ) => {
      state.TrainContentId = action.payload;
    },
    changeFAQ: (
      state: TrainContent,
      action: { payload: string; type: string }
    ) => {
      state.FAQ = action.payload;
    },
    changeFieldDisclosure: (
      state: TrainContent,
      action: { payload: string; type: string }
    ) => {
      state.FieldDisclosureAnnouncement = action.payload;
    },
    changeLoading: (
      state: TrainContent,
      action: { payload: string; type: string }
    ) => {
      state.__isLoading = action.payload;
    },
  },
  extraReducers: {
    [getTrainContent.fulfilled.type]: (state: TrainContent, { payload }) => {
      state.TrainContentId = payload.TrainContentId;
      state.MilestoneId = payload.MilestoneId;
      state.FAQ = payload.FAQ;
      state.FieldDisclosureAnnouncement = payload.FieldDisclosureAnnouncement;
      state.__isLoading = _LOADINGSTATE.fullfilled;
    },
    [getTrainContent.pending.type]: (state: TrainContent) => {
      state.__isLoading = _LOADINGSTATE.pending;
    },
    [getTrainContent.rejected.type]: (state, { error }) => {
      state.__isLoading = _LOADINGSTATE.rejected;
      ErrorMessage.show(
        "There was an error getting train content. Please refresh the page and try again. If the issue persists please contact the tool administrator."
      );
    },
    [postTrainContent.fulfilled.type]: (state: TrainContent, { payload }) => {
      state.TrainContentId = payload.TrainContentId;
      state.__isLoading = _LOADINGSTATE.fullfilled;
    },
    [postTrainContent.pending.type]: (state: TrainContent) => {
      state.__isLoading = _LOADINGSTATE.pending;
    },
    [postTrainContent.rejected.type]: (state, { error }) => {
      state.__isLoading = _LOADINGSTATE.rejected;
      ErrorMessage.show(
        "There was an error posting train content. Please refresh the page and try again. If the issue persists please contact the tool administrator."
      );
    },
    [putTrainContent.fulfilled.type]: (state: TrainContent) => {
      state.__isLoading = _LOADINGSTATE.fullfilled;
    },
    [putTrainContent.pending.type]: (state: TrainContent) => {
      state.__isLoading = _LOADINGSTATE.pending;
    },
    [putTrainContent.rejected.type]: (state, { error }) => {
      state.__isLoading = _LOADINGSTATE.rejected;
      ErrorMessage.show(
        "There was an error putting train content. Please refresh the page and try again. If the issue persists please contact the tool administrator."
      );
    },
  },
});

export const { reducer, actions } = traincontentSlice;
