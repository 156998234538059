import React, { useEffect, useContext, useMemo } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import "../../Shared/css/timelineGrid.scss";
import "./index.scss";
import { convertMonthStringToDate } from "../../../utils/date";
import { speakText } from "../../../utils/utils";
import { ContentShowMode } from "../../Shared/constants";
import { RootState } from "../../../store";
import { MilestoneModalContext } from "..";
import { useId } from "@fluentui/react-hooks";
import { TooltipHost } from "@fluentui/react";

const MoveNext = () => {
  const id = useId("tooltip");
  const milestoneModalContext = useContext(MilestoneModalContext);
  const showMonth = useMemo(() => {
    return milestoneModalContext.contentShowMode === ContentShowMode.Grid
      ? 3
      : 1;
  }, [milestoneModalContext.contentShowMode]);
  const dataSource = useSelector(
    (state: RootState) => state.azureTimelineReducer.dataSet
  );
  const filterDataSource = useSelector(
    (state: RootState) => state.azureTimelineReducer.filterDataSet
  );

  const [canClickBtn, setCanClickBtn] = React.useState(false);

  useEffect(() => {
    if (dataSource.length > showMonth) {
      setCanClickBtn(true);
    }

    const currentLastItem = filterDataSource.slice(-1)[0];
    const index = dataSource.findIndex(
      (d) => d.month === currentLastItem?.month
    );
    if (index === dataSource.length - 1) {
      setCanClickBtn(false);
    }

    if (filterDataSource.length < showMonth) {
      setCanClickBtn(false);
    }
  }, [dataSource, filterDataSource, showMonth]);

  const handleClick = () => {
    if (dataSource.length > showMonth) {
      const currentLastItem = filterDataSource[filterDataSource.length - 1];
      const index = dataSource.findIndex(
        (d) => d.month === currentLastItem.month
      );

      if (index !== dataSource.length - 1) {
        milestoneModalContext.setStartDate(
          convertMonthStringToDate(dataSource[index - showMonth + 2].month)
        );
        speakText(
          "move to next month " + dataSource[index - showMonth + 2].month
        );
      }
    }
  };

  return (
    <div
      className={`grid-item item-arrow-right ${
        canClickBtn ? "click-arrow " : ""
      }`}
    >
      <TooltipHost
        content="move to next month"
        id={id}
        aria-role="tooltip"
        role="tooltip"
      >
        <div
          tabIndex={0}
          role="button"
          aria-label="move to next month"
          onClick={handleClick}
          onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>) => {
            if (event.keyCode === 13) {
              handleClick();
            }
          }}
        >
          <i
            className="fa fa-angle-right fa-3x"
            aria-hidden="true"
            style={{ fontSize: "20px" }}
            alt-text="move to next month"
          ></i>
        </div>
      </TooltipHost>
    </div>
  );
};

export default MoveNext;
