export const msalConfig = {
  auth: {
    authority: "https://login.microsoftonline.com/organizations",
    clientId: "ce291a6b-6cf3-4c09-bd45-1b46b1de40bd",
    redirectUri: window.location.href.replace(window.location.search, ""),
  },
  cache: {
    cacheLocation: "localStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: true, // Set this to "true" if you are having issues on IE11 or Edge
  },
};
