import React, { useContext } from "react";
import moment from "moment";
import "../index.scss";
import { useUrlQueryParam } from "../../../../utils/url";
import { ContentShowMode } from "../../../Shared/constants";
import { SecurityMilestoneModalContext } from "../../";
import { filter, featureFilterList } from "../../../../slice/savedDataFilters";
import { securityTimelineMilestone as milestoneProps } from "../../../../slice/securityTimeline";
import MilestoneCard from "./milestoneCard";
import MilestoneRow from "./milestoneRow";

export const Milestone = (productFeatures: {
  milestones: milestoneProps[];
}) => {
  const securityMilestoneModalContext = useContext(
    SecurityMilestoneModalContext
  );
  const [param] = useUrlQueryParam(
    featureFilterList.map((f: filter) => {
      return f.filterName;
    })
  );
  const productMilestoneDom = productFeatures.milestones.map((item) => {
    return securityMilestoneModalContext.contentShowMode ===
      ContentShowMode.Grid ? (
      <MilestoneCard milestone={item} key={item.SecurityMilestoneId} />
    ) : (
      <MilestoneRow milestone={item} key={item.SecurityMilestoneId} />
    );
  });

  return (
    <div className="d-flex flex-wrap" role="rowgroup">
      {" "}
      {productMilestoneDom}{" "}
    </div>
  );
};
