import { useCallback, useContext, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import "../../Shared/css/timelineGrid.scss";
import "./index.scss";
import { Spinner, SpinnerSize } from "@fluentui/react";
import moment from "moment";
import {
  convertMonthStringToDate,
  getFirstDayInTheMonth,
  getLastDayInTheMonth,
} from "../../../utils/date";
import { RootState, useAppDispatch } from "../../../store";
import * as modernWorkTimelineReducer from "../../../slice/modernWorkTimeline";
import {
  ContentShowMode,
  ModalType,
  TimelineMode,
  TimelineType,
  _AUTHORIZATIONTYPE,
  _LOADINGSTATE,
} from "../../Shared/constants";
import { AppContext } from "../../../App";
import { ModernWorkFeatureModalContext } from "../index";
import RenderMoments from "./moments";
import RenderMonth from "./month";
import MovePre from "./movePrevious";
import MoveNext from "./moveNext";
import FeatureModal from "../../ModernWorkFeature/featureModal";
import RenderFeature from "./TimelineFeature/index";
import { useLocation, useSearchParams } from "react-router-dom";

const TimelineGrid = () => {
  const appContext = useContext(AppContext);
  const modernWorkFeatureModalContext = useContext(
    ModernWorkFeatureModalContext
  );

  const showMonth = useMemo(() => {
    return modernWorkFeatureModalContext.contentShowMode ===
      ContentShowMode.Grid
      ? 3
      : 1;
  }, [modernWorkFeatureModalContext.contentShowMode]);

  const dataSource = useSelector(
    (state: RootState) => state.modernWorkTimelineReducer.dataSet
  );

  const dataSet = useSelector(
    (state: RootState) => state.modernWorkTimelineReducer.filterDataSet
  );

  const isMonthLoading = useSelector(
    (state: RootState) => state.modernWorkTimelineReducer.isMonthLoading
  );

  const isLoadingSearchResult = useSelector(
    (state: RootState) => state.modernWorkTimelineReducer.isLoadingSearchResult
  );

  const appDispatch = useAppDispatch();

  const location = useLocation();
  useEffect(() => {
    appDispatch(
      modernWorkTimelineReducer.initModernWorkTimelineDataSet(TimelineType.MW)
    );
  }, [appDispatch, location.pathname]);

  useEffect(() => {
    //Check to see if we are using search, otherwise load data normally
    if (modernWorkFeatureModalContext.timelineMode === TimelineMode.Search) {
      appDispatch(
        modernWorkTimelineReducer.actions.setFilterDataSet(dataSource)
      );
    } else {
      if (isMonthLoading === _LOADINGSTATE.fullfilled) {
        var startDate: moment.Moment | undefined;
        var endDate: moment.Moment | undefined;
        //Load all data when the length of data source less than showMonth
        if (dataSource.length > showMonth) {
          //Get the index of the first appear month in the data source
          const dataSourceIndex = dataSource.findIndex((d) => {
            return (
              moment(convertMonthStringToDate(d.month)) >=
              moment(
                getFirstDayInTheMonth(
                  moment(modernWorkFeatureModalContext.startDate).format(
                    "MM/DD/YYYY"
                  )
                )
              )
            );
          });
          appDispatch(
            modernWorkTimelineReducer.actions.setFilterDataSet(
              dataSource.slice(dataSourceIndex, dataSourceIndex + showMonth)
            )
          );
          //Get the start and end index of appear months in the data source, and need
          //add 1 more month of the start month or end month
          var startIndex = dataSourceIndex - (dataSourceIndex > 1 ? 1 : 0);
          var endIndex =
            dataSourceIndex + showMonth < dataSource.length
              ? dataSourceIndex + showMonth
              : dataSource.length - 1;
          //Get the start and end month by start and end index
          for (var i = startIndex; i <= endIndex; i++) {
            if (dataSource[i].isLoading === "") {
              if (startDate === undefined) {
                startDate = moment(
                  convertMonthStringToDate(dataSource[i].month)
                );
              }
              endDate = moment(convertMonthStringToDate(dataSource[i].month));
            }
          }
        } else {
          appDispatch(
            modernWorkTimelineReducer.actions.setFilterDataSet(dataSource)
          );
          startDate = moment(convertMonthStringToDate(dataSource[0].month));
          endDate = moment(
            convertMonthStringToDate(dataSource[dataSource.length - 1].month)
          );
        }
        if (startDate && endDate) {
          appDispatch(
            modernWorkTimelineReducer.getDataSet(
              startDate.toDate(),
              getLastDayInTheMonth(endDate.format("MM/DD/YYYY")),
              TimelineType.MW
            )
          );
        }
      }
    }
  }, [
    appDispatch,
    dataSource,
    showMonth,
    modernWorkFeatureModalContext.startDate,
    modernWorkFeatureModalContext.timelineMode,
    isMonthLoading,
  ]);

  const [query] = useSearchParams();

  useEffect(() => {
    if (query.has("featureId") && appContext.accessType !== "") {
      if (
        appContext.accessType === _AUTHORIZATIONTYPE.admin ||
        appContext.accessType === _AUTHORIZATIONTYPE.dri ||
        appContext.accessType === _AUTHORIZATIONTYPE.editable
      ) {
        modernWorkFeatureModalContext.setModernWorkFeatureStateHandler({
          featureTitle: "",
          featureId: Number(query.get("featureId")),
          cchFeatureId: 0,
          featureDisclosureDate: null,
          featurePublicDisclosureDate: null,
          deleteFeatureHistoryId: "",
          modalType: ModalType.Edit,
        });
      } else {
        modernWorkFeatureModalContext.setModernWorkFeatureStateHandler({
          featureTitle: "",
          featureId: Number(query.get("featureId")),
          cchFeatureId: 0,
          featureDisclosureDate: null,
          featurePublicDisclosureDate: null,
          deleteFeatureHistoryId: "",
          modalType: ModalType.ReadOnly,
        });
      }
      modernWorkFeatureModalContext.modalRef?.current?.click();
    }
  }, [appContext.accessType]);

  const handleScroll = useCallback(() => {
    let gridContent = document.querySelector(".grid-content");
    if (gridContent && gridContent !== null) {
      let top = gridContent.getBoundingClientRect().top;
      let left = gridContent.getBoundingClientRect().left;
      let stickyHead = document.querySelector(".grid-content-stickyhead");
      if (stickyHead && stickyHead !== null) {
        if (top < 100) {
          if (
            stickyHead.className !==
            `grid-content ${
              "grid-container-" + dataSet.length
            } grid-content-stickyhead`
          ) {
            stickyHead.className = `grid-content ${
              "grid-container-" + dataSet.length
            } grid-content-stickyhead`;
          }
        } else {
          if (
            stickyHead.className !==
            `grid-content ${
              "grid-container-" + dataSet.length
            } grid-content-stickyhead d-none`
          ) {
            stickyHead.className = `grid-content ${
              "grid-container-" + dataSet.length
            } grid-content-stickyhead d-none`;
          }
        }
        if (stickyHead.getBoundingClientRect().left !== left) {
          (stickyHead as HTMLElement).style.left = left + "px";
        }
      }
    }
  }, [dataSet]);

  useEffect(() => {
    window.removeEventListener("scroll", handleScroll);
    window.addEventListener("scroll", handleScroll);
    handleScroll();
  }, [dataSet, handleScroll]);

  return (
    <div className="timeline-content-container modernWorkTimeline-content-container">
      <FeatureModal />
      <div className={`grid-content grid-container-${dataSet.length}`}>
        {isMonthLoading === _LOADINGSTATE.pending || isLoadingSearchResult ? (
          <div className={"grid-item column-span-all"}>
            <Spinner
              size={SpinnerSize.large}
              label="Loading content"
              ariaLive="polite"
              labelPosition="top"
            />
          </div>
        ) : (
          <>
            {modernWorkFeatureModalContext.timelineMode !==
            TimelineMode.Search ? (
              <>
                <div
                  className={`grid-content ${
                    "grid-container-" + dataSet.length
                  } grid-content-stickyhead d-none`}
                >
                  <MovePre />
                  <RenderMonth dataSet={dataSet} />
                  <MoveNext />
                </div>
                <MovePre />
                <RenderMonth dataSet={dataSet} />
                <MoveNext />
                <RenderMoments dataSet={dataSet} />
              </>
            ) : null}
            <RenderFeature dataSet={dataSet} />
          </>
        )}
      </div>
    </div>
  );
};

export default TimelineGrid;
