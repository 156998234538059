import {
  createSlice,
  createAsyncThunk,
  combineReducers,
  PayloadAction,
} from "@reduxjs/toolkit";
import { IDropdownOption } from "@fluentui/react";
import moment from "moment";
import { convertDateTimeToFormatedDateString } from "../../utils/date";
import modernWorkSecurityEventCalendarMomentService from "../../services/modernWorkSecurityEventCalendarMoment";
import { modernWorkSecurityEventCalendarMoment } from "../modernWorkSecurityEventCalendarMoment";
import * as modernWorkSecurityEventCalendarMomentReducer from "../../slice/modernWorkSecurityEventCalendarMoment";
import {
  getFeatureAndMilestoneByAllMomentId,
  MilestoneTierGroup,
} from "../../slice/calendar";
import { ErrorMessage } from "../../components/Shared/messageBox";
export enum Status {
  Add,
  Edit,
  None,
}

export interface Details {
  MomentId: string;
  StartDate: string;
  EndDate: string;
  SolutionArea: string;
  ProductFamily: string;
  MomentType: string;
  Contacts: string;
  TierDisclosures: string;
}
export interface CalendarUpdateState {
  currentMoment: CalendarMarketingMoment;
  updateStatus: Status;
  currentDetail: Details;
  editPanelOpen: boolean;
  detailPanelOpen: boolean;
  momentList: CalendarMarketingMoment[];
  excelDownloading: boolean;
}

export interface CalendarMarketingMoment {
  Title: string;
  DateSelection: string | null;
  StartDate: string | null;
  EndDate: string | null;
  SolutionArea: string[];
  ProductFamily: string[];
  MomentType: string[];
  Description?: string;
  Contacts?: string[] | undefined;
  PRSupport?: number;
  MainDisclosureChannel?: string[];
  GeoLocation?: string;
  TargetAudience?: string[];
  NewsZone: boolean;
  SolutionAreaNewsZone?: string;
  PriorityItem?: string;
  BarriersToSuccess?: string;
  StartDateMonth?: string;
  EndDateMonth?: string;
  StartDateYear?: string;
  EndDateYear?: string;
  StartFiscalYear?: string;
  StartQuarter?: string;
  TBCCategory?: string;
  Id?: number;
  MilestoneTierGroups?: MilestoneTierGroup[];
  MultipleCSA: string;
  NewsZoneContent?: NewsZoneContent[];
}
export interface NewsZoneContent {
  MainMessage?: string;
  NewsItem?: string;
  Selected: boolean;
  SolutionArea?: string;
  Theme?: string;
  PriorityItem?: string;
  BarriersToSuccess?: string;
}

export const addCalendar = createAsyncThunk(
  "addCalendarFields",
  async (canlendarModel: CalendarMarketingMoment | null) => {
    if (canlendarModel != null)
      return await modernWorkSecurityEventCalendarMomentService.addNewMoment(
        canlendarModel
      );
  }
);

export const putMoment = createAsyncThunk(
  "putMoment",
  async (canlendarModel: CalendarMarketingMoment | null) => {
    if (canlendarModel != null)
      return await modernWorkSecurityEventCalendarMomentService.putMoment(
        canlendarModel
      );
  }
);

export const deleteMoment = createAsyncThunk(
  "deleteMoment",
  async (momentId: number | null) => {
    if (momentId != null)
      return await modernWorkSecurityEventCalendarMomentService.deleteMoment(
        momentId
      );
  }
);

export const getAllTierGroupBeforeDownload = createAsyncThunk(
  "getAllTierGroupBeforeDownload",
  async ({}) => {
    //if (momentId != null)
    //  return (await calendarService.deleteMoment(
    //    momentId
    //  ));
  }
);

const defaltStartDate = () => {
  let now = moment().toDate();
  now.setHours(0, 0, 0);
  now.setFullYear(2024);
  now.setMonth(now.getMonth());
  return convertDateTimeToFormatedDateString(now);
};
const defaltEndDate = () => {
  let now = moment().toDate();
  now.setHours(23, 59, 59);
  now.setFullYear(2024);
  now.setMonth(now.getMonth());
  return convertDateTimeToFormatedDateString(now);
};

const initDefaultMoment: CalendarMarketingMoment = {
  Title: "",
  DateSelection: null,
  StartDate: defaltStartDate(),
  EndDate: defaltEndDate(),
  SolutionArea: [],
  ProductFamily: [],
  MomentType: [],
  MainDisclosureChannel: [],
  NewsZone: false,
  TargetAudience: [],
  Contacts: undefined,
  StartDateMonth: "January",
  EndDateMonth: "January",
  StartDateYear: "2024",
  EndDateYear: "2024",
  MultipleCSA: "",
};
const initDetails: Details = {
  MomentId: "",
  StartDate: "",
  EndDate: "",
  SolutionArea: "",
  ProductFamily: "",
  MomentType: "",
  Contacts: "",
  TierDisclosures: "",
};
const initialState: CalendarUpdateState = {
  currentMoment: initDefaultMoment,
  updateStatus: Status.None,
  currentDetail: initDetails,
  editPanelOpen: false,
  detailPanelOpen: false,
  momentList: [],
  excelDownloading: false,
};
export interface CalendarMarketingField {
  DateSelection: IDropdownOption[];
  SolutionArea: IDropdownOption[];
  ProductFamily: IDropdownOption[];
  MomentType: IDropdownOption[];
  PRSupport: IDropdownOption[];
  MainDisclosureChannel: IDropdownOption[];
  TargetAudience: IDropdownOption[];
}
const calendarUpdateSlice = createSlice({
  name: "calendarUpdateSlice",
  initialState: initialState,
  reducers: {
    addMoment: (state) => {
      console.log("add moment ", state);
    },
    changeTitle: (state, action: { payload: string; type: string }) => {
      state.currentMoment!.Title = action.payload;
    },
    changeDateSelection: (state, action: { payload: string; type: string }) => {
      state.currentMoment!.DateSelection = action.payload;
      if (action.payload === "TBC - Month") {
        let now = moment();
        let endDay = now.daysInMonth();
        state.currentMoment.StartDate = now.date(1).format("YYYY-MM-DD");
        state.currentMoment.EndDate = now.date(endDay).format("YYYY-MM-DD");
      }
      if (action.payload === "Watch Day") {
        let now = moment();
        state.currentMoment.StartDate = now
          .date(1)
          .hour(0)
          .minute(0)
          .second(0)
          .format("YYYY-MM-DD");
        state.currentMoment.EndDate = now
          .date(1)
          .hour(23)
          .minute(59)
          .second(59)
          .format("YYYY-MM-DD");
      }
    },
    editMomentType: (
      state,
      action: { payload: { index: string; selected: boolean }; type: string }
    ) => {
      if (action.payload.selected)
        state.currentMoment?.MomentType?.push(action.payload.index);
      else
        state.currentMoment!.MomentType =
          state.currentMoment!.MomentType?.filter(
            (p) => p !== action.payload.index
          );
    },
    editProductFamily: (
      state,
      action: { payload: { index: string; selected: boolean }; type: string }
    ) => {
      if (action.payload.selected)
        state.currentMoment!.ProductFamily?.push(action.payload.index);
      else
        state.currentMoment!.ProductFamily =
          state.currentMoment!.ProductFamily?.filter(
            (p) => p !== action.payload.index
          );
    },
    editMainDisclosureChannel: (
      state,
      action: { payload: { index: string; selected: boolean }; type: string }
    ) => {
      if (action.payload.selected)
        state.currentMoment!.MainDisclosureChannel?.push(action.payload.index);
      else
        state.currentMoment!.MainDisclosureChannel =
          state.currentMoment!.MainDisclosureChannel?.filter(
            (p) => p !== action.payload.index
          );
    },
    editTargetAudience: (
      state,
      action: { payload: { index: string; selected: boolean }; type: string }
    ) => {
      if (action.payload.selected)
        state.currentMoment!.TargetAudience?.push(action.payload.index);
      else
        state.currentMoment!.TargetAudience =
          state.currentMoment!.TargetAudience?.filter(
            (p) => p !== action.payload.index
          );
    },
    editSolutionArea: (
      state,
      action: { payload: { index: string; selected: boolean }; type: string }
    ) => {
      if (action.payload.selected)
        state.currentMoment!.SolutionArea?.push(action.payload.index);
      else
        state.currentMoment!.SolutionArea =
          state.currentMoment!.SolutionArea?.filter(
            (p) => p !== action.payload.index
          );
    },
    editContacts: (
      state,
      action: { payload: { index: string[]; selected: boolean }; type: string }
    ) => {
      if (action.payload.selected)
        state.currentMoment!.Contacts = action.payload.index;
    },
    editNewsZoneSolutionArea: (
      state,
      action: { payload: string | null; type: string }
    ) => {
      if (action.payload != null)
        state.currentMoment!.SolutionAreaNewsZone = action.payload;
    },
    editPRSupport: (state, action: { payload: number; type: string }) => {
      state.currentMoment!.PRSupport = action.payload;
    },
    changeGeoLocation: (state, action: { payload: string; type: string }) => {
      state.currentMoment!.GeoLocation = action.payload;
    },
    changeNewsZone: (state, action: { payload: boolean; type: string }) => {
      state.currentMoment!.NewsZone = action.payload;
    },
    changePriorityItem: (state, action: { payload: string; type: string }) => {
      state.currentMoment!.PriorityItem = action.payload;
    },
    changeBarriersToSuccess: (
      state,
      action: { payload: string; type: string }
    ) => {
      state.currentMoment!.BarriersToSuccess = action.payload;
    },
    changeStartDate: (state, action: { payload: string; type: string }) => {
      state.currentMoment!.StartDate = action.payload;
    },
    changeEndDate: (state, action: { payload: string; type: string }) => {
      state.currentMoment!.EndDate = action.payload;
    },
    changeTBCMonthDate: (
      state,
      action: { payload: { date: number; dateType: string }; type: string }
    ) => {
      if (action.payload.dateType === "StartYear")
        state.currentMoment!.StartDate = moment(state.currentMoment!.StartDate)
          .year(action.payload.date)
          .format("YYYY-MM-DD");
      if (action.payload.dateType === "EndYear")
        state.currentMoment!.EndDate = moment(state.currentMoment!.EndDate)
          .year(action.payload.date)
          .format("YYYY-MM-DD");
      if (action.payload.dateType === "StartDateMonth")
        state.currentMoment!.StartDate = moment(state.currentMoment!.StartDate)
          .month(action.payload.date - 1)
          .date(1)
          .format("YYYY-MM-DD");
      if (action.payload.dateType === "EndDateMonth") {
        let generateDate = moment(state.currentMoment!.EndDate).month(
          action.payload.date - 1
        );
        let dayInMonth = generateDate.daysInMonth();
        state.currentMoment!.EndDate = generateDate
          .date(dayInMonth)
          .format("YYYY-MM-DD");
      }
    },
    changeTBCQuarter: (
      state,
      action: {
        payload: { fisicalYear: number; quarter: number };
        type: string;
      }
    ) => {
      const quarter = action.payload.quarter;
      const fy = action.payload.fisicalYear;
      switch (quarter) {
        case 1:
          state.currentMoment!.StartDate = moment()
            .year(fy)
            .month(6)
            .date(1)
            .format("YYYY-MM-DD");
          state.currentMoment!.EndDate = moment()
            .year(fy)
            .month(8)
            .date(30)
            .format("YYYY-MM-DD");
          break;
        case 2:
          state.currentMoment!.StartDate = moment()
            .year(fy)
            .month(9)
            .date(1)
            .format("YYYY-MM-DD");
          state.currentMoment!.EndDate = moment()
            .year(fy)
            .month(11)
            .date(31)
            .format("YYYY-MM-DD");
          break;
        case 3:
          state.currentMoment!.StartDate = moment()
            .year(fy + 1)
            .month(0)
            .date(1)
            .format("YYYY-MM-DD");
          state.currentMoment!.EndDate = moment()
            .year(fy + 1)
            .month(2)
            .date(31)
            .format("YYYY-MM-DD");
          break;
        case 4:
          state.currentMoment!.StartDate = moment()
            .year(fy + 1)
            .month(3)
            .date(1)
            .format("YYYY-MM-DD");
          state.currentMoment!.EndDate = moment()
            .year(fy + 1)
            .month(5)
            .date(30)
            .format("YYYY-MM-DD");
          break;
        default:
      }
    },
    changeEditPanelOpen: (
      state,
      action: { payload: boolean; type: string }
    ) => {
      state.editPanelOpen = action.payload;
    },
    changeDetailPanelOpen: (
      state,
      action: { payload: boolean; type: string }
    ) => {
      state.detailPanelOpen = action.payload;
    },
    changeEditPanelState: (
      state,
      action: { payload: Status; type: string }
    ) => {
      state.updateStatus = action.payload;
      if (state.updateStatus === Status.Add) {
        state.currentMoment = initDefaultMoment;
      }
    },
    switchCurrentMoment: (state, action: { payload: number; type: string }) => {
      const getMoment = state.momentList.find((p) => p.Id === action.payload);
      if (getMoment != undefined && getMoment != null) {
        state.currentMoment = getMoment;
        if (state.currentMoment.NewsZone) {
          //getMoment.NewsZone
        }
      }
    },
    changeDescription: (state, action: { payload: string; type: string }) => {
      state.currentMoment!.Description = action.payload;
    },
    switchExcelDownloadStatus: (
      state,
      action: { payload: boolean; type: string }
    ) => {
      state.excelDownloading = action.payload;
    },
  },
  extraReducers: {
    [addCalendar.fulfilled.type]: (state, { error }) => {
      console.log("newMomentAdded");
    },
    [addCalendar.rejected.type]: (state, { error }) => {
      ErrorMessage.show(
        "There was an error adding new moment. Please refresh the page and try again. If the issue persists please contact the tool administrator."
      );
      console.log(error);
    },
    [putMoment.fulfilled.type]: (state, { error }) => {
      console.log("moment put");
    },
    [putMoment.rejected.type]: (state, { error }) => {
      ErrorMessage.show(
        "There was an error editing new moment. Please refresh the page and try again. If the issue persists please contact the tool administrator."
      );

      console.log(error);
    },
    [modernWorkSecurityEventCalendarMomentReducer
      .getModernWorkSecurityEventCalendarMomentList.fulfilled.type]: (
      state,
      action: PayloadAction<modernWorkSecurityEventCalendarMoment[]>
    ) => {
      const tempMomentList: CalendarMarketingMoment[] = [];
      action.payload.forEach((getMoment) => {
        let newsZoneContent: NewsZoneContent[] | undefined = undefined;
        let prioriItem = "";
        let barriersToSuccess = "";
        let solutionAreaNewsZone: string | undefined = "";
        if (getMoment.NewsZone.toString() == "1") {
          newsZoneContent = JSON.parse(
            getMoment.NewsZoneContent
          ) as NewsZoneContent[];

          if (newsZoneContent != undefined) {
            prioriItem = newsZoneContent.at(0)?.PriorityItem ?? "";
            barriersToSuccess = newsZoneContent.at(0)?.BarriersToSuccess ?? "";
            solutionAreaNewsZone = newsZoneContent?.find(
              (p: NewsZoneContent) => p.Selected === true
            )?.SolutionArea;
          }
        }
        tempMomentList.push({
          Title: getMoment.Title,
          DateSelection: getMoment.BIC_DateSelection,
          TBCCategory: getMoment.CategoryName,
          StartDate: moment(getMoment.StartDate).toISOString(),
          EndDate: moment(getMoment.EndDate).toISOString(),
          Id: getMoment.Id as number,
          SolutionArea: (getMoment.CategoryName as string)?.split(";"),
          ProductFamily: (getMoment.ProductFamily as string)?.split(";"),
          MomentType: (getMoment.MomentType as string)?.split(";"),
          TargetAudience: getMoment.TargetAudience
            ? (getMoment.TargetAudience as string).trim().split(";")
            : [],
          GeoLocation: getMoment.Location,
          MainDisclosureChannel: (
            getMoment.MainDisclosureChannel as string
          )?.split(";"),
          Description: getMoment.Description,
          NewsZone: getMoment.NewsZone.toString() == "1" ? true : false,
          MultipleCSA: getMoment.MultipleCSA,
          NewsZoneContent: newsZoneContent,
          PriorityItem: prioriItem,
          BarriersToSuccess: barriersToSuccess,
          SolutionAreaNewsZone: solutionAreaNewsZone,
          Contacts:
            getMoment.BICContact == ""
              ? undefined
              : getMoment.BICContact?.split(";"),
        });
      });

      state.momentList = tempMomentList;
    },
    [getFeatureAndMilestoneByAllMomentId.fulfilled.type]: (
      state,
      action: {
        payload: { id: number; group: MilestoneTierGroup[] };
        type: string;
      }
    ) => {
      if (action.payload === undefined) {
        console.log("undefined action ", action);
      }
      if (action.payload.group === undefined) {
        console.log("undefined action ", action);
      }
      const group = action.payload.group;
      const index = state.momentList.findIndex(
        (p) => p.Id === action.payload.id
      );
      if (index != -1) {
        const tempMoment = state.momentList[index];
        tempMoment.MilestoneTierGroups = group;
        state.momentList = [
          ...state.momentList.slice(0, index),
          tempMoment,
          ...state.momentList.slice(index + 1),
        ];
      }
    },
    [getFeatureAndMilestoneByAllMomentId.rejected.type]: (state, { error }) => {
      ErrorMessage.show(
        "There was an error getting moments. Please refresh the page and try again. If the issue persists please contact the tool administrator."
      );
      console.log(error);
    },
  },
});

export const { reducer, actions } = calendarUpdateSlice;
