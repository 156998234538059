import { isVoid } from "../../utils/utils";

export namespace FeatureUtil {
  interface ColorProp {
    [key: string]: string;
  }

  export namespace Colors {
    const featureClassNames: ColorProp = {
      normal: "",
      published: "legend-item-published",
      notpublished: "legend-item-notpublished",
    };

    export const feature = (status: string) => {
      if (!isVoid(status)) {
        let statu = status.replace(/\s*/g, "").toLocaleLowerCase();
        if (Object.keys(featureClassNames).indexOf(statu) > -1) {
          return featureClassNames[statu];
        }
      }
      return featureClassNames["normal"];
    };
  }
}
