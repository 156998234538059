import httpClient from "./base";

const getTooltip = () => {
  const url = "Tooltip";
  return httpClient.get(url);
};

const tooltipService = {
  getTooltip,
};

export default tooltipService;
