import httpClient from "./base";

const getMoments = (
  startDate?: string,
  endDate?: string,
  timelineType?: string
) => {
  return httpClient.get(
    `Timeline/Moments?startDate=${startDate}&endDate=${endDate}&timelineType=${timelineType}`
  );
};

const timelineService = {
  getMoments,
};

export default timelineService;
